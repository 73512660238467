import isEmpty from "lodash/isEmpty"
// USE WITH CAUTION!!! Errors are usually useful
// Define any pesky console errors here that are nice to silence as they are not actionable, this will make debugging real issues much easier
const CONSOLE_ERROR_BLACK_LIST_REGEX = [
  /https:\/\/fb\.me\/rules-of-hooks?.+/,
  /OAuthError: The client specified not to prompt, but the user is not logged in\./,
  /getWithoutPrompt failed with error/,
  /Warning: unstable_flushDiscreteUpdates: Cannot flush updates when React is already rendering\./g, // this is from the react-player third party library
  /Warning: Do not call Hooks/,
  /Warning: React has detected a change in the order of Hooks/,
  /Warning: React does not recognize the .+ prop on a DOM element/,
  /Warning: Can't perform a React state update on an unmounted component/,
  // these are useful for SSR env
  /Component not mapped for resourcetype/,
  /Warning: Prop .+ did not match\. Server/,
]

// disabled for test env
const JEST_CONSOLE_ERROR_BLACK_LIST_REGEX = [
  ...CONSOLE_ERROR_BLACK_LIST_REGEX,
  // /Warning.+/, // silence all warnings for debugging
  // /Error.+/, // silence all errors for debugging
  /Warning: Failed prop type: Invalid prop `inputRef` supplied to `GeosuggestInput`, expected a ReactNode/,
  /Error: Error: connect ECONNREFUSED 127.0.0.1:80/,
  /Warning: Failed prop type: Invalid prop `variant`/,
  /Warning: React does not recognize the .+ prop on a DOM element./,
]

/**
 * If we're in test mode, return the JEST_CONSOLE_ERROR_BLACK_LIST_REGEX, otherwise return the
 * CONSOLE_ERROR_BLACK_LIST_REGEX
 */
const getBlackList = () =>
  process.env.NODE_ENV === `test`
    ? JEST_CONSOLE_ERROR_BLACK_LIST_REGEX
    : CONSOLE_ERROR_BLACK_LIST_REGEX

/**
 * Custom console error function that filters out messages based on a blacklist.
 *
 * @param {string} error - The error message to log.
 * @param {...any} rest - Additional arguments to log.
 * @returns {null|void} Returns null if the message is filtered out, otherwise returns the result of console.nativeError.
 */
export const customConsoleError = (error, ...rest) => {
  const blackList = getBlackList()

  // https://javascript.plainenglish.io/lets-extend-console-log-8641bda035c3
  let args = [error, ...rest]

  for (let i = 0; i < blackList.length; i++) {
    // The first arg is the error message, this is the only one we care about
    const [error] = args
    const regex = blackList[i]

    if (error?.match(regex)) {
      args = []

      break
    }
  }

  // eslint-disable-next-line prefer-spread
  if (!isEmpty(args)) return console.nativeError.apply(console, args)

  return null
}
