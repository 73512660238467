import get from "lodash/get"
import * as ActionTypes from "constants-lib/actionTypes"
import { userType } from "constants-lib/authentication"

const initialState = {
  renderMyWMOptions: false,
  selectedCustomerId: ``,
  useMaskedCustomerID: false,
  usePaymentCustomerID: false,
  eligibilityCode: {
    eligibleForMyServices: ``,
    eligibleForBillPay: ``,
    eligibleForCommPrefs: ``,
  },
  numLinkedAccounts: 0,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.SET_ELIGIBILITY_FLAGS:
      return {
        ...state,
        eligibilityCode: {
          eligibleForMyServices: action.payload.eligibleForMyServices,
          eligibleForBillPay: action.payload.eligibleForBillPay,
          eligibleForCommPrefs: action.payload.eligibleForCommPrefs,
        },
      }

    case ActionTypes.SET_MY_WM_CUSTOMER_ID:
      return {
        ...state,
        selectedCustomerId: action.payload,
      }

    case ActionTypes.SET_RENDER_MY_WM_OPTIONS:
      return {
        ...state,
        renderMyWMOptions: action.payload,
      }

    case ActionTypes.SET_USE_MASKED_CUSTOMER_ID:
      return {
        ...state,
        useMaskedCustomerID: action.payload,
      }

    case ActionTypes.SET_USE_PAYMENT_CUSTOMER_ID:
      return {
        ...state,
        usePaymentCustomerID: action.payload,
      }

    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
      return {
        ...initialState,
      }

    case ActionTypes.GET_LINKED_ACCOUNTS_FULFILLED: {
      return {
        ...state,
        numLinkedAccounts: get(action.payload, `linkedAccounts`, []).length,
      }
    }

    case ActionTypes.SET_LINKED_ACCOUNTS_FULFILLED: {
      return {
        ...state,
        numLinkedAccounts: action.payload.numLinkedAccounts,
      }
    }

    case ActionTypes.SET_UNLINKED_ACCOUNTS_FULFILLED: {
      return {
        ...state,
        numLinkedAccounts: action.payload.numLinkedAccounts,
      }
    }

    case ActionTypes.SET_USER_STATE: {
      /* Clear state after logging in. */
      if (action.payload === userType.LOGGED_IN) {
        return {
          ...initialState,
        }
      }
      return {
        ...state,
      }
    }

    default:
      return state
  }
}
