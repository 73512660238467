import get from "lodash/get"
import axios from "axios"
import {
  GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_PENDING,
  GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_FAILED,
  GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_FULFILLED,
  GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_DUPLICATE,
  CLEAR_DELETE_SCHEDULED_PAYMENT_STATUS,
  CLEAR_CREATE_SCHEDULED_PAYMENT_STATUS,
  PAID_OUTSTANDING_BALANCE_WHILE_ENROLLING,
} from "constants-lib/actionTypes"
import { PAYMENT_METHOD_VALUES } from "constants-lib/paymentMethods"
import dayjs from "utils-lib/date"
import { getTokenAction } from "actions-lib/getToken"
import { getDeviceChannel } from "utils-lib/getDeviceChannel"
import { isACHPaymentMethod } from "utils-lib/paymentMethods/checkPaymentMethodTypes"
import { logRocketTrackwithObject } from "utils-lib/logRocket"
import { urls } from "utils-lib/builds"
import { getPaddedCustomerId } from "utils-lib/strings"

export const pendingAC = () => ({
  type: GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_PENDING,
})

export const failedAC = (data) => {
  logRocketTrackwithObject("payment-failed", data)

  return {
    type: GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_FAILED,
    payload: data,
  }
}

export const fulfilledAC = (data) => {
  logRocketTrackwithObject("payment-success", data)

  return {
    type: GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_FULFILLED,
    payload: data,
  }
}

export const changeDuplicatePayment = (data) => ({
  type: GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_DUPLICATE,
  payload: data,
})
export const clearDeleteScheduledPaymentStatus = () => ({
  type: CLEAR_DELETE_SCHEDULED_PAYMENT_STATUS,
})
export const clearCreateScheduledPaymentStatus = () => ({
  type: CLEAR_CREATE_SCHEDULED_PAYMENT_STATUS,
})

export const payOutstandingBalanceAndEnrollAutopay = (data) => ({
  type: PAID_OUTSTANDING_BALANCE_WHILE_ENROLLING,
  payload: data,
})

/**
 * Action for posting a process sale request.
 * @function postProcessSaleRequest
 * @param {Object} data - The data to include in the API call.
 * @param {string} onSuccessRedirect - The URL to redirect to on successful completion of the API call.
 * @param {function} enableSubmitCallback - The callback function to enable the submit button.
 * @param {boolean} [requireLogin=false] - Whether login is required for the API call.
 * @param {boolean} [isMultiAccount=false] - Whether the API call is for multiple accounts.
 * @param {boolean} [enrollAndPay=false] - Whether to enroll and pay in a single API call.
 * @returns {function} dispatches actions to update the store with the results of the API call.
 */
export const postProcessSaleRequest =
  (
    data,
    onSuccessRedirect,
    enableSubmitCallback,
    requireLogin = false,
    isMultiAccount = false,
    enrollAndPay = false,
    token = "",
  ) =>
  (dispatch, getState) => {
    dispatch(pendingAC())
    const state = getState()
    const api = isMultiAccount
      ? `PROCESS_MULTI_SALE_REQUEST`
      : `PROCESS_SALE_REQUEST`
    const urlObj = urls.url[api]

    if (requireLogin) {
      return getTokenAction(dispatch, getState).then((token) => {
        const config = {
          headers: {
            token: token.accessToken,
          },
          params: {
            userId: get(state, `userAccount.userDetails.userId`, ``),
          },
        }

        const apiKey = get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }

        return postProcessSaleRequestHelper(
          api,
          data,
          config,
          onSuccessRedirect,
          enableSubmitCallback,
          isMultiAccount,
          enrollAndPay,
          dispatch,
          getState,
        )
      })
    }

    const { myPaymentVerification, customerSelections, guestContainerRepair } =
      state

    const config = {
      headers: {
        token:
          token ||
          get(myPaymentVerification, `verificationToken`, ``) ||
          get(customerSelections, `cityBilledEligibility.token`, ``) ||
          get(guestContainerRepair, `eligibilityData.token`, ``),
        ...get(urlObj, `guest.headers`, {}),
      },
    }

    const apiKey = get(urls, `apiKey.GUEST[${api}]`, ``)

    if (apiKey !== ``) {
      config.headers.apiKey = apiKey
    }

    const customerId = getPaddedCustomerId(
      get(myPaymentVerification, `verificationData.customerId`, ``) ||
        get(myPaymentVerification, `verificationDetails.customerId`, ``) ||
        get(guestContainerRepair, `eligibilityData.customerId`, ``) ||
        get(customerSelections, `cityBilledEligibility.customerId`, ``),
    )

    return postProcessSaleRequestHelper(
      api,
      {
        payOnAccount: customerId,
        ...data,
      },
      config,
      onSuccessRedirect,
      enableSubmitCallback,
      isMultiAccount,
      enrollAndPay,
      dispatch,
      getState,
    )
  }

/**
 * Helper function for posting a process sale request.
 * @function postProcessSaleRequestHelper
 * @param {string} api - The API to call.
 * @param {Object} data - The data to include in the API call.
 * @param {Object} config - The configuration for the API call.
 * @param {string} onSuccessRedirect - The URL to redirect to on successful completion of the API call.
 * @param {function} enableSubmitCallback - The callback function to enable the submit button.
 * @param {boolean} isMultiAccount - Whether the API call is for multiple accounts.
 * @param {boolean} enrollAndPay - Whether to enroll and pay in a single API call.
 * @param {function} dispatch - The redux dispatch function.
 * @param {function} getState - The redux getState function.
 */
export const postProcessSaleRequestHelper = (
  api,
  data,
  config,
  onSuccessRedirect,
  enableSubmitCallback,
  isMultiAccount,
  enrollAndPay,
  dispatch,
  getState,
) => {
  const invoiceNo = get(data, `payOnInvoice`, ``).replace(/[\s-]/g, ``)
  const state = getState()
  const payMethod = get(data, "paymentMethod", "")
  const isAch =
    isACHPaymentMethod(payMethod) || payMethod === PAYMENT_METHOD_VALUES.ACH
  const convenienceFee =
    state.processingFee?.processingFeeDetails?.convenienceFee

  const url = urls.url[api]

  return axios
    .create(config)
    .post(url, {
      ...{
        deviceChannel: getDeviceChannel(),
        payFee: `0.00`,
        paySubmitDate: dayjs().format(),
        payChannel: `O`,
        paySubmittedBy: ``,
        paySubmittedFor: ``,
        payMemo: ``,
        payType: invoiceNo !== `` ? `I` : `A`,
        locale: get(getState(), `siteLanguage.language`, `en_CA`),
        ...data,
      },
      ...(!isAch && { convenienceFee }),
    })
    .then((response) => {
      // response.data = { "statusCode":200, "status":`Declined`, "errorMsg":``, "data":{ "code":`102`, "message":`REJECT`, "confirmationNumber":69759109168 } }
      const statusCode = get(response, `status`, ``)
      if (enrollAndPay) {
        dispatch(
          payOutstandingBalanceAndEnrollAutopay({
            statusCode,
            paymentStatus: get(response, `data.status`, ``),
            confirmationNumber: get(
              response,
              `data.data.confirmationNumber`,
              ``,
            ),
          }),
        )
      } else {
        dispatch(
          fulfilledAC({
            // saleResponse: response.data, // TODO remove this object and just pull out needed fields.
            statusCode,
            paymentStatus: get(response, `data.status`, ``), // `Declined`, `Success`
            confirmationNumber: get(
              response,
              `data.data.confirmationNumber`,
              ``,
            ),
            lastPaymentAmount: data.payAmount,
          }),
        )
      }

      return response
    })
    .then((response) => {
      if (response.data.status === `Success`) {
        /* Do not enable submit button on success. */
        onSuccessRedirect(response)
      } else if (
        response.data.status === `Failed` &&
        response.status === 406 &&
        response.data.errorMsg &&
        response.data.errorMsg.msg === `duplicate payment`
      ) {
        enableSubmitCallback(response.response)
        dispatch(changeDuplicatePayment(true))
      } else if (response.data.status === `Declined`) {
        enableSubmitCallback(response.data)
        onSuccessRedirect(response)
      }
      return response
    })
    .catch((response) => {
      enableSubmitCallback(response.response)
      const statusCode = get(response, `response.status`, ``)
      const status = get(response, `response.data.status`, ``)
      const msg = get(response, `response.data.errorMsg.msg`, ``)
      if (
        statusCode === 406 &&
        status === `Failed` &&
        msg === `duplicate payment`
      ) {
        dispatch(
          fulfilledAC({
            // saleResponse: response.response.data,
            paymentStatus: get(response, `response.data.status`, ``), // `Declined`, `Success`
            statusCode,
            lastPaymentAmount: data.payAmount,
          }),
        )
        dispatch(changeDuplicatePayment(true))
      } else {
        let errorMessage = get(response, `response.data.errorMsg`, ``)
        if (errorMessage === ``) {
          errorMessage = get(response, `response.data.message`, ``)
        }
        dispatch(
          failedAC({
            statusCode,
            errorMessage,
          }),
        )
      }
    })
}
