import * as ActionTypes from "constants-lib/actionTypes"
import { userType } from "constants-lib/authentication"
import { logRocketIdentify } from "utils-lib/logRocket"

const initialState = {
  changePasswordDetails: {},
  changePasswordState: ``,
  createUserDetails: {
    login: ``,
  },
  isPasswordUpdated: false,
  resetPasswordState: ``,
  resendEmailState: ``,
  sessionIntervalId: null,
  updateUserState: ``,
  updateUserDetails: {
    errorMsg: ``,
    statusCode: ``,
    data: {},
  },
  userDetails: {
    userId: ``,
    firstName: ``,
    lastName: ``,
    login: ``,
    cookiedLogin: ``,
    newLogin: ``,
    isLinkExpired: `YES`,
    isUnconfirmed: true,
    isFirstTimeUser: false,
    // rawLoginData: {},
  },
  getUserDetailsState: ``,
  userState: 0,
  statusCode: {
    createUser: ``,
    resendEmail: ``,
    resetPassword: ``,
    updateUser: ``,
  },
  token: null,
  sessionToken: null,
  userAddress: {},
  // expiresAt: null,
}

/* User States:
    0 - Anonymous
    1 - Guest
    2 - Logging in
    3 - Logged in
  Widget States:
    0 - Register
    1 - Login
    2 - Forgot Password
*/

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        changePasswordState: `pending`,
      }

    case ActionTypes.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changePasswordState: `failed`,
        changePasswordDetails: action.payload,
      }

    case ActionTypes.CHANGE_PASSWORD_FULFILLED:
      return {
        ...state,
        changePasswordState: `fulfilled`,
        changePasswordDetails: action.payload,
      }

    case ActionTypes.CREATE_USER_WITH_CREDENTIALS_PENDING:
      return {
        ...state,
        createUserDetails: {
          login: ``,
        },
      }

    case ActionTypes.CREATE_USER_WITH_CREDENTIALS_FAILED:
      return {
        ...state,
        createUserDetails: {
          login: action.payload.login,
        },
      }

    case ActionTypes.CREATE_USER_WITH_CREDENTIALS_FULFILLED:
      return {
        ...state,
        createUserDetails: {
          login: action.payload.login,
        },
      }

    case ActionTypes.GET_USER_DETAILS_PENDING:
      return {
        ...state,
        getUserDetailsState: `pending`,
      }

    case ActionTypes.GET_USER_DETAILS_FAILED:
      return {
        ...state,
        getUserDetailsState: `failed`,
      }

    case ActionTypes.GET_USER_DETAILS_FULFILLED: {
      const { firstName, lastName, login } = action.payload

      logRocketIdentify({
        name: `${firstName} ${lastName}`,
        email: login,
      })

      return {
        ...state,
        getUserDetailsState: `fulfilled`,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      }
    }

    case ActionTypes.RESET_USER_ACCOUNT: {
      let userState = action.payload

      if (
        /* Leaving this commented out for now, this was causing issues with setting existing users to cookied on refresh */
        // state.userState === userType.EXISTING ||
        (state.userState === userType.COOKIED ||
          state.userState === userType.LOGGED_IN) &&
        (action.payload === userType.ANONYMOUS ||
          action.payload === userType.PROSPECTIVE)
      ) {
        userState = userType.COOKIED
      }

      return {
        ...initialState,
        userState,
        sessionIntervalId: state.sessionIntervalId,
        userDetails: {
          ...initialState.userDetails,
          cookiedLogin: state.userDetails.cookiedLogin,
        },
        userAddress: state.userAddress,
      }
    }

    case ActionTypes.CLEAR_FIRST_TIME_USER: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isFirstTimeUser: false,
        },
      }
    }

    case ActionTypes.RESEND_EMAIL_PENDING:
      return {
        ...state,
        resendEmailState: `pending`,
      }

    case ActionTypes.RESEND_EMAIL_FAILED:
      return {
        ...state,
        resendEmailState: `failed`,
        statusCode: {
          ...state.statusCode,
          resendEmail: action.payload,
        },
      }

    case ActionTypes.RESEND_EMAIL_FULFILLED:
      return {
        ...state,
        resendEmailState: `fulfilled`,
        statusCode: {
          ...state.statusCode,
          resendEmail: ``,
        },
      }

    case ActionTypes.RESET_PASSWORD_PENDING:
      return {
        ...state,
        resetPasswordState: `pending`,
      }

    case ActionTypes.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPasswordState: `failed`,
        statusCode: {
          ...state.statusCode,
          resetPassword: action.payload,
        },
      }

    case ActionTypes.RESET_PASSWORD_FULFILLED:
      return {
        ...state,
        resetPasswordState: `fulfilled`,
        statusCode: {
          ...state.statusCode,
          resetPassword: ``,
        },
      }

    case ActionTypes.SET_IS_PASSWORD_UPDATED:
      return {
        ...state,
        isPasswordUpdated: action.payload,
      }

    case ActionTypes.SET_SESSION_INTERVAL_ID:
      return {
        ...state,
        sessionIntervalId: action.payload,
      }

    case ActionTypes.SET_USER_SESSION_TOKEN:
      return {
        ...state,
        sessionToken: action.payload,
      }

    case ActionTypes.SET_TOKEN:
      return {
        ...state,
        // expiresAt: action.payload.expiresAt,
        // token: action.payload.token,
        token: action.payload,
      }

    case ActionTypes.SET_USER_DETAILS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      }
    }

    case ActionTypes.SET_USER_STATE: {
      const updates = {}
      if (
        action.payload === userType.LOGGING_IN ||
        action.payload === userType.LOGGED_OUT
      ) {
        updates.token = null
      }
      return {
        ...state,
        ...updates,
        userState: action.payload,
      }
    }

    case ActionTypes.UPDATE_USER_PROFILE_PENDING:
      return {
        ...state,
        updateUserState: `pending`,
        updateUserDetails: {
          errorMsg: ``,
          statusCode: ``,
          data: {},
        },
      }

    case ActionTypes.UPDATE_USER_PROFILE_FAILED:
      return {
        ...state,
        updateUserState: `failed`,
        updateUserDetails: {
          errorMsg: action.payload.errorMsg,
          statusCode: action.payload.statusCode,
          data: {},
        },
        statusCode: {
          ...state.statusCode,
          updateUser: action.payload.errorCode,
        },
      }

    case ActionTypes.UPDATE_USER_PROFILE_FULFILLED:
      return {
        ...state,
        updateUserState: `fulfilled`,
        updateUserDetails: {
          errorMsg: action.payload.errorMsg,
          statusCode: action.payload.statusCode,
          data: action.payload.data,
        },
        userDetails: {
          ...state.userDetails,
          ...action.payload.data,
        },
        statusCode: {
          ...state.statusCode,
          updateUser: ``,
        },
      }

    case ActionTypes.SET_CUSTOMER_ADDRESS:
      return {
        ...state,
        userAddress:
          state.userState === userType.LOGGED_IN ? action.payload : {},
        userState:
          !action.payload &&
          state.userState !== userType.LOGGED_IN &&
          state.userState !== userType.COOKIED
            ? userType.ANONYMOUS
            : state.userState,
      }

    case ActionTypes.SET_GEOCODE_CUSTOMER_ADDRESS:
      return {
        ...state,
        userAddress:
          state.userState === userType.LOGGED_IN ? action.payload : {},
      }

    default:
      return state
  }
}
