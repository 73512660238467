import split from "lodash/split"
import get from "lodash/get"
import replace from "lodash/replace"
import filter from "lodash/filter"
import toLower from "lodash/toLower"
import toArray from "lodash/toArray"
import mapValues from "lodash/mapValues"
import map from "lodash/map"
import each from "lodash/each"
import isEmpty from "lodash/isEmpty"
import uniq from "lodash/uniq"
import toString from "lodash/toString"
import set from "lodash/set"
import join from "lodash/join"
import concat from "lodash/concat"
import getLanguageRoute from "utils-lib/getLanguageRoute"
import { getDeviceChannel } from "utils-lib/getDeviceChannel"
import { userType } from "constants-lib/authentication"
import { localStorageKey } from "constants-lib/localStorage"
import { getWindow } from "utils-lib/getWindow"
import { getSessionStorage } from "./isBot"

const window = getWindow()

/* page structure */
// const page = {
//   site_section: ``,
//   pagename: ``,
//   pagename_concat: ``, // site_section|pagename
//   previous_page_name: ``, // previous pagename_concat
//   country_language: ``,
//   device: ``,
//   base_URL: `wm.com`,
// }

/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */

/**
 * Returns the site section based on the current URL pathname and language.
 * @param {string} language - The language code for the current page.
 * @returns {string} - The site section name.
 */
function getSiteSection(language) {
  const { pathname } = window.location
  const languageRoute = getLanguageRoute(language)

  let site_section = ``

  if (pathname.indexOf(languageRoute) === -1 || pathname === languageRoute) {
    site_section = `homepage`
  } else {
    const splitBySlash = split(window.location.pathname, `/`)
    site_section =
      splitBySlash.length < 4 ? `blank` : get(splitBySlash, `[3]`, ``)
  }

  return replace(site_section, `-`, `_`)
}

/**
 * Returns the page name based on the current URL and language.
 * @param {string} language - The language code for the current page.
 * @returns {string} - The page name.
 */
function getPagename(language) {
  const { pathname } = window.location
  const languageRoute = getLanguageRoute(language)

  let pagename = ``

  if (pathname.indexOf(languageRoute) === -1 || pathname === languageRoute) {
    pagename = `homepage`
  } else {
    const splitBySlash = split(window.location.pathname, `/`)
    const length = get(splitBySlash, `length`, 0)
    pagename =
      splitBySlash.length === 0
        ? `blank`
        : get(splitBySlash, `[${length - 1}]`, ``)
  }

  return replace(pagename, `-`, `_`)
}

/**
 * Gets the value of a URL parameter from the current page's URL.
 * @function
 * @param {string} paramName - The name of the URL parameter to get the value of.
 * @returns {string} The value of the URL parameter or an empty string if the parameter is not found.
 */
export const getDLVarFromParam = (paramName) =>
  split(
    filter(
      split(window.location.search, `&`),
      (str) => str.indexOf(paramName) > -1,
    )[0],
    `=`,
  )[1] || ``

/**
 * Updates the page object in the global `_dl` object.
 * @function
 * @param {Object} params - An object containing the following properties:
 * @param {string} params.language - The language of the page.
 * @param {string} [params.previous_page_name=""] - The name of the previous page.
 * @param {string} [params.templateName=""] - The name of the template used for the page.
 * @param {string} [params.lobType=""] - The type of line of business for the page.
 * @param {string} [params.gisIDs=""] - The GIS IDs for the page.
 */
export const updatePageDL = ({
  language,
  previous_page_name = ``,
  templateName = ``,
  lobType = ``,
  gisIDs = ``,
}) => {
  if (!window._dl) {
    resetDataLayer()
  } else if (!window._dl.page) {
    resetDataLayer([`page`])
  }

  const site_section = getSiteSection(language)
  const pagename = getPagename(language)
  const defaultConsent = {
    "banner-available": true,
    "Non-Essential": false,
    "Session Replay": false,
  }
  const defaultNoConsent = {
    ...defaultConsent,
    "banner-available": false,
  }
  const availableConsent = JSON?.parse(
    window?.localStorage?.getItem("consents-lib"),
  )
  const updatedConsent = {
    ...availableConsent,
    "banner-available": true,
  }
  const isQuebec = window.document.getElementsByClassName("cmp-loader")

  // update local storage of current page name for cross subdomain updates
  window.localStorage.setItem(localStorageKey.DL_PAGE_NAME, pagename)

  window._dl.page = {
    site_section,
    pagename,
    pagename_concat: `${site_section}|${pagename}`,
    previous_page_name,
    country_language: toLower(language),
    device: toLower(getDeviceChannel()),
    base_URL: window.location.origin,
    templateName,
    lobType,
    gisIDs,
    consents: availableConsent
      ? updatedConsent
      : isQuebec?.length > 0
      ? defaultConsent
      : defaultNoConsent,
  }

  updateExperienceCloudID()
}

/* clicks item structure */
// const clicks = {
//   ui_element: ``,
//   object_content: ``,
// }

/**
 * Updates the clicks object in the global `_dl` object.
 * @function
 * @param {Object} params - An object containing the following properties:
 * @param {string} params.ui_element - The UI element that was clicked.
 * @param {object} params.object_content - The content of the clicked object.
 * @param {object} params[...rest] - Any additional click objects requested for specific analytics.
 */
export const updateClicksDL = ({ ui_element, object_content, ...rest }) => {
  if (!window._dl) {
    resetDataLayer()
  } else if (!window._dl.clicks) {
    resetDataLayer([`clicks`])
  }

  // update local storage of current page name for cross subdomain updates
  window.localStorage.setItem(localStorageKey.DL_UI_ELEMENT, ui_element)
  window.localStorage.setItem(localStorageKey.DL_OBJECT_CONTENT, object_content)

  window._dl.clicks = {
    ui_element,
    object_content,
    ...rest,
  }
}

/* visitor structure */
// const visitor = {
//   experience_cloud_id: ``, // window.s.marketingCloudVisitorID
//   target_id: ``, // window.s.supplementalDataID
//   wm_user_id: ``, // user profile id
//   wm_customer_id: [], // customer id list
//   is_logged_in: ``,
//   is_repeat: ``,
//   customer_type: ``, // TRO, SMB, OMR, LN
//   lob: ``, // ROLLOFF, COMMERCIAL, RESIDENTIAL, LANDFILL
//   customer_list: []
// }

/**
 * Returns an array of customer account IDs linked to the given user's account.
 *
 * @param {Object} userManageAccount - The user's account object.
 * @returns {Array} - An array of customer account IDs.
 */
function getCustomerIds(userManageAccount) {
  return get(userManageAccount, `linkedAccounts`, ``)
    ? toArray(
        mapValues(
          get(userManageAccount, `linkedAccounts`, ``),
          `custAccountId`,
        ),
      )
    : []
}

/**
 * Returns an array of customer types based on the provided list of lines of business.
 * @param {string[]} lobList - The list of lines of business to map to customer types.
 * @returns {string[]} An array of customer types.
 */
function getCustomerType(lobList) {
  return map(lobList, (lob) => {
    switch (lob) {
      case `landfill`:
        return `ln`
      case `rolloff`:
        return `tro`
      case `commercial`:
        return `smb`
      case `residential`:
        return `omr`
      default:
    }
  })
}

/**
 * Returns an array of unique and sorted LOBs (Line of Business) associated with the given user's managed account.
 * @param {Object} userManageAccount - The user's managed account object.
 * @returns {(string[]|string)} - An array of unique and sorted LOBs or an empty string if no LOBs are found.
 */
function getLOB(userManageAccount) {
  const lobs = []
  each(get(userManageAccount, `linkedAccounts`, ``), (acct) => {
    lobs.push(toLower(get(acct, `lob`)))
  })

  return !isEmpty(lobs) ? uniq(lobs).sort() : ``
}

/**
 * Groups services by account.
 *
 * @param {Object} myServices - The services object to group by account.
 * @returns {Object} An object with services grouped by account.
 */
const pivotServicesByAccount = (myServices) => {
  const servicesByAccount = {}

  each(get(myServices, `services`, []), (serviceGroup) => {
    const lineOfBusiness = get(serviceGroup, `lineOfBusiness`, ``)
    const services = []
    each(get(serviceGroup, `services`, []), (service) => {
      services.push({
        serviceId: get(service, `serviceId`, ``),
        lineOfBusiness,
        rollOffType: get(service, `rollOffType`, `N/A`),
      })
    })
    servicesByAccount[get(serviceGroup, `ezpayId`, ``)] = services
  })
  return servicesByAccount
}

/* customer item structure */
// customer_list: {
//   [customer_id]: {
//     category: ``,
//     status: ``,
//     autopay: ``,
//     paperless: ``,
//     services: [
//       lineOfBusiness: ``,
//       rollOffType: ``,
//     ]
//   }, ...
// }

/**
 * Updates the customer list in the global `_dl.visitor.customer_list` object.
 * @function
 * @param {Object} params - An object containing the following properties:
 * @param {Object} params.userManageAccount - The user manage account object.
 * @param {Object} params.customerAutoPayPaperless - The customer auto pay paperless object.
 * @param {Object} params.myServices - The my services object.
 * @param {boolean} [shouldUpdate=true] - A flag indicating whether to update the global `_dl.visitor.customer_list` object.
 * @returns {Object} The updated customer list object.
 */
export const updateCustomerListDL = (
  { userManageAccount, customerAutoPayPaperless, myServices },
  shouldUpdate = true,
) => {
  const customer_list = {}
  let ap_pl = {}
  let lob_perm_temp = {}

  if (!isEmpty(customerAutoPayPaperless)) {
    ap_pl = get(customerAutoPayPaperless, `attributesDetailsByAccount`, {})
  }

  if (!isEmpty(myServices)) {
    lob_perm_temp = pivotServicesByAccount(myServices)
  }

  if (!isEmpty(ap_pl) || !isEmpty(lob_perm_temp)) {
    each(userManageAccount.userAccountDetails, (cust) => {
      const id = toString(get(cust, `custAccountId`, ``))
      // autopay paperless
      const appl = get(ap_pl, id, {})
      const autopay =
        get(appl, `autopayStatus`, ``) ||
        get(window, `_dl.visitor.customer_list[${id}].autopay`, ``)
      const paperless =
        get(appl, `paperlessStatus`, ``) ||
        get(window, `_dl.visitor.customer_list[${id}].autopay`, ``)

      // services
      const services = get(lob_perm_temp, id, [])
      customer_list[id] = {
        category: get(cust, `category`, ``),
        status: get(cust, `status`, ``),
        autopay,
        paperless,
        services: !isEmpty(services)
          ? services
          : get(window, `_dl.visitor.customer_list[${id}].services`, []),
      }
    })

    shouldUpdate && set(window, `_dl.visitor.customer_list`, customer_list)

    return get(window, `_dl.visitor.customer_list`, customer_list)
  }

  return get(window, `_dl.visitor.customer_list`, {})
}

/**
 * Updates the visitor information in the data layer.
 * @param {Object} params - The parameters for the function.
 * @param {Object} params.userAccount - The user account information.
 * @param {Object} params.userManageAccount - The user manage account information.
 * @param {Object} [params.customerAutoPayPaperless={}] - The customer auto pay and paperless information.
 * @param {Object} [params.myServices={}] - The user's services information.
 * @param {Object} [params.customers={}] - The customer information.
 */
export const updateVisitorDL = ({
  userAccount,
  userManageAccount,
  customerAutoPayPaperless = {},
  myServices = {},
  customers = {},
  accountAttributesApi = {},
  pickupInfoApi = {},
  serviceDetailsApi = {},
  eligibilityApi = {},
  customerSearchApi = {},
  extraPickupEligibility = {},
}) => {
  if (!window._dl) {
    resetDataLayer()
  } else if (!window._dl.visitor) {
    resetDataLayer([`visitor`])
  }

  const reduxUserState = get(userAccount, `userState`, ``)
  const localStorageUserState = get(
    window,
    `localStorage.${localStorageKey.USER_STATE}`,
  )

  const lob = getLOB(userManageAccount)
  const botdResult = JSON.parse(getSessionStorage("botdResult"))
  const getId = getSessionStorage("ID")
  const sessionId = isEmpty(getId) ? "" : getId

  const customer_type = getCustomerType(lob)
  window._dl.visitor = {
    target_id: get(window, `s.supplementalDataID`, ``),
    wm_user_id: get(userAccount, `userDetails.userId`, ``), // user profile id
    wm_customer_id: getCustomerIds(userManageAccount), // customer id list
    is_logged_in:
      reduxUserState === userType.LOGGED_IN ||
      localStorageUserState === userType.LOGGED_IN
        ? `yes`
        : `no`,
    is_repeat:
      reduxUserState === userType.COOKIED ||
      localStorageUserState === userType.COOKIED
        ? `yes`
        : `no`,
    customer_type: join(customer_type, `|`),
    lob: join(lob, `|`),
    customer_list: updateCustomerListDL(
      { userManageAccount, customerAutoPayPaperless, myServices },
      false,
    ),
    is_bot: get(botdResult, "bot", ``),
    customers,
    sessionId,
    accountAttributesApi,
    pickupInfoApi,
    serviceDetailsApi,
    eligibilityApi,
    customerSearchApi,
    extraPickupEligibility,
  }

  updateExperienceCloudID()
}

/**
 * Updates the experience cloud ID in the data layer based on the marketing cloud visitor ID.
 *
 * @returns {void}
 */
const updateExperienceCloudID = () => {
  const dl_ecid = get(window, `_dl.visitor.experience_cloud_id`, ``)
  const ecid = get(window, `s.marketingCloudVisitorID`, ``)
  // if datalayer ecid is not empty
  //  update to ecid
  // else if datalayer ecid is not empty AND ecid is not empty
  //  if ecid and datalayer ecid are different
  //    update to ecid
  //  else
  //    do not update
  if (!isEmpty(dl_ecid) || (!isEmpty(ecid) && dl_ecid !== ecid)) {
    set(window, `_dl.visitor.experience_cloud_id`, ecid)
  }
}

/* cart structure */
// const blankCart = [{
//   lob: ``, // categoryId
//   product_name: ``, // productName
//   product_sku: ``, // productId
//   product_lob: `tro`, // AEM only handles TRO
//   quantity: `1`, // must be string, always 1 for TRO
//   price: ``, // must be string
//   currency: ``, // USD, CAD
// }, ...]

/**
 * This function updates the cart in the data layer.
 * @param {Object} cartObj - The cart object to be added to the data layer.
 * @param {boolean} [saveToCart=false] - A flag indicating whether to save the cart object to the data layer.
 */
export const updateCartDL = (cartObj, saveToCart = false) => {
  if (!window._dl) {
    resetDataLayer()
  } else if (!window._dl.visitor) {
    resetDataLayer([`cart`])
  }

  const newCart = get(window._dl, `cart`, [])
  if (!saveToCart) {
    newCart.pop()
  }
  window._dl.cart = concat([], newCart, [{ ...cartObj, quantity: "1" }])
}

/**
 * An object representing a blank data layer.
 * @typedef {Object} blankDL
 * @property {Object} page - Contains properties related to the current page.
 * @property {Object} clicks - Contains properties related to user clicks.
 * @property {Object} visitor - Contains properties related to the visitor.
 * @property {Array} cart - An array representing the visitor's cart.
 */
export const blankDL = {
  page: {
    site_section: ``,
    pagename: ``,
    pagename_concat: ``, // site_section|pagename
    previous_page_name: ``, // previous pagename
    country_language: ``,
    device: ``,
    base_URL: `wm.com`,
  },
  clicks: {
    ui_element: ``,
    object_content: ``,
  },
  visitor: {
    experience_cloud_id: ``,
    wm_customer_id: ``,
    target_id: ``,
    is_logged_in: ``,
    is_repeat: ``,
    customer_type: ``,
    lob: ``,
    customer_list: {},
    customers: {},
    is_bot: ``,
    accountAttributesApi: {},
    pickupInfoApi: {},
    serviceDetailsApi: {},
    eligibilityApi: {},
    customerSearchApi: {},
    extraPickupEligibility: {},
  },
  cart: [],
}

/**
 * Updates the data layer with various parameters.
 * @param {Object} options - The options for updating the data layer.
 * @param {string} options.previous_page_name - The name of the previous page.
 * @param {string} options.language - The language code for the current page.
 * @param {Object} options.userAccount - User account information.
 * @param {Object} options.userManageAccount - User manage account information.
 * @param {Object} options.customerAutoPayPaperless - Customer auto pay and paperless information.
 * @param {Object} options.myServices - Information about the user's services.
 * @param {Object} options.clicks - Information about the user's clicks.
 * @param {string} options.templateName - The name of the current template.
 * @param {string} options.lobType - The type of line of business.
 * @param {Array} options.gisIDs - An array of GIS IDs.
 * @param {Array} options.customers - An array of customer information.
 * @param {Object} options.accountAttributesApi - Information from the account attributes API.
 * @param {Object} options.pickupInfoApi - Information from the pickupInfoApi API.
 * @param {Object} options.serviceDetailsApi - Information from the details API.
 * @param {Object} options.eligibilityApi - Information from the eligibility API.
 * @param {Object} options.customerSearchApi - Information from the customer search API.
 * @param {Object} options.extraPickupEligibility - Information about extra pickup eligibility.
 */
export const updateDataLayer = ({
  previous_page_name = ``,
  language,
  userAccount,
  userManageAccount,
  customerAutoPayPaperless,
  myServices,
  clicks,
  templateName,
  lobType,
  gisIDs,
  customers,
  accountAttributesApi,
  pickupInfoApi,
  serviceDetailsApi,
  eligibilityApi,
  customerSearchApi,
  extraPickupEligibility,
}) => {
  updatePageDL({ language, previous_page_name, templateName, lobType, gisIDs })
  updateVisitorDL({
    userAccount,
    userManageAccount,
    customerAutoPayPaperless,
    myServices,
    customers,
    accountAttributesApi,
    pickupInfoApi,
    serviceDetailsApi,
    eligibilityApi,
    customerSearchApi,
    extraPickupEligibility,
  })
  !isEmpty(clicks) && updateClicksDL(clicks)
}

/**
 * Resets the data layer to its default state or specific sections.
 * @param {string[]} [sections=[]] - The sections of the data layer to reset.
 */
export const resetDataLayer = (sections = []) => {
  // page, clicks, visitor, cart
  if (isEmpty(sections)) {
    window._dl = blankDL
  } else {
    each(sections, (section) => {
      window._dl[section] = blankDL[section]
    })
  }
}
