import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  processingFeeDetails: {},
  processingFeeState: ``,
  errorMessage: ``,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PROCESSING_FEE_PENDING:
      return {
        ...state,
        processingFeeState: `pending`,
      }
    case ActionTypes.GET_PROCESSING_FEE_FULFILLED:
      return {
        ...state,
        processingFeeDetails: action.payload,
        processingFeeState: `fulfilled`,
      }
    case ActionTypes.GET_PROCESSING_FEE_FAILED:
      return {
        ...state,
        ...initialState,
        errorMessage: action.payload.errorMessage,
        processingFeeState: `failed`,
      }
    case ActionTypes.RESET_PROCESSING_FEE:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
