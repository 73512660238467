import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { generateUniqueID } from "utils-lib/requests"
import { urls } from "utils-lib/builds"
import {
  CHANGE_WASTE_TYPE,
  GET_ONLINE_PRODUCTS_PENDING,
  GET_ONLINE_PRODUCTS_FULFILLED,
  GET_ONLINE_PRODUCTS_FAILED,
  SET_ONLINE_SELECTED_PRODUCT,
  GET_ECOMMERCE_PRICING_PENDING,
  GET_ECOMMERCE_PRICING_FULFILLED,
  GET_ECOMMERCE_PRICING_FAILED,
  SET_ONLINE_PRODUCTS_USER_SELECTION,
  CLEAR_ONLINE_PRODUCTS_USER_SELECTION,
  RESET_ECOM_PRICING,
  INITIALIZE_PRODUCT_RESULTS,
  CHANGE_TERM_PLAN,
} from "constants-lib/actionTypes"
import { handleErrorResponse } from "../getProductUrl"
import { getServletDetails } from "../getServletDetails"
import { getProductsUpdatedResponse } from "./getOnlineProductsUtil"
import { getOutages } from "../getOutages"

export const pendingAC = (locale) => ({
  type: GET_ONLINE_PRODUCTS_PENDING,
  payload: locale,
})

export const failedAC = (data) => ({
  type: GET_ONLINE_PRODUCTS_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: GET_ONLINE_PRODUCTS_FULFILLED,
  payload: data,
})

export const pendingPricing = () => ({
  type: GET_ECOMMERCE_PRICING_PENDING,
})

export const fulfilledPricing = (data) => ({
  type: GET_ECOMMERCE_PRICING_FULFILLED,
  payload: data,
})

export const failedPricing = (data) => ({
  type: GET_ECOMMERCE_PRICING_FAILED,
  payload: data,
})

export const changeWasteType = (id) => (dispatch) => {
  dispatch({
    type: CHANGE_WASTE_TYPE,
    payload: id,
  })
}

export const changeTermPlan = (term) => (dispatch) => {
  dispatch({
    type: CHANGE_TERM_PLAN,
    payload: term,
  })
}

export const changeSelectedProduct = (data) => (dispatch) => {
  dispatch({
    type: SET_ONLINE_SELECTED_PRODUCT,
    payload: data,
  })
}

/**
 * It makes an API call to get the online products
 * @returns a function.
 */
export const getOnlineProducts = () => (dispatch, getState) => {
  const { customerSelections, siteLanguage, onlineProducts } = getState()

  const serviceTypeId =
    get(onlineProducts, "term") === "short" ? "shortTerm" : "longTerm"

  const latitude = encodeURI(
    customerSelections.customerSearchedAddress.lat || ``,
  )
  const longitude = encodeURI(
    customerSelections.customerSearchedAddress.lng || ``,
  )
  const city = encodeURI(customerSelections.customerSearchedAddress.city || ``)
  const state = encodeURI(
    customerSelections.customerSearchedAddress.state || ``,
  )
  const street =
    `${customerSelections.customerSearchedAddress.streetNumber} ${customerSelections.customerSearchedAddress.streetName}` ||
    ``
  const zipcode = customerSelections.customerSearchedAddress.postalCode || ``
  const country = encodeURI(
    customerSelections.customerSearchedAddress.country || ``,
  )
  const locale = encodeURI(siteLanguage.language || ``)
  // eslint-disable-next-line camelcase
  const google_placeId = encodeURI(
    customerSelections.customerSearchedAddress.gPlaceId || ``,
  )
  const product = `temporary_bin`
  const serviceType = serviceTypeId
  const api = `ONLINE_PRODUCTS`
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    latitude,
    longitude,
    city,
    state,
    street,
    zipcode,
    country,
    locale,
    serviceType,
    product,
    variant: "longTerm",
    google_placeId,
  })
  const shortTermUrl = template.expand({
    latitude,
    longitude,
    city,
    state,
    street,
    zipcode,
    country,
    locale,
    serviceType,
    product,
    variant: "shortTerm",
    google_placeId,
  })

  const config = {
    url,
    method: `get`,
    headers: {
      "Content-Type": `application/json`,
      ClientId: urls.cloudCraze.headers.ClientId,
      "Request-Tracking-Id": generateUniqueID(),
    },
  }
  const shortTermConfig = {
    url: shortTermUrl,
    method: `get`,
    headers: {
      "Content-Type": `application/json`,
      ClientId: urls.cloudCraze.headers.ClientId,
      "Request-Tracking-Id": generateUniqueID(),
    },
  }
  dispatch(pendingAC(locale))
  return axios(config)
    .then((response) => {
      let finalResponse
      return axios(shortTermConfig).then((shorttermResponse) => {
        finalResponse = getProductsUpdatedResponse(
          response.data,
          shorttermResponse.data,
        )
        if (serviceTypeId === "longTerm") {
          const products = get(finalResponse, "products", [])
          // eslint-disable-next-line no-unused-vars
          let hasService = false
          products.forEach((item) => {
            const longTerm = item.attributes.find(
              (s) => s.service_type === "longTerm",
            )
            if (longTerm?.materials?.length > 0) {
              hasService = true
            }
          })
        }

        const zones = get(finalResponse, "zones", [])
        let siteId = get(finalResponse, "zones[0].site_id", "")
        let z = zones?.find(
          (s) =>
            s?.type_indicator_code === 1 &&
            s?.contract_type_code === 1 &&
            s?.line_of_business === "ROLLMSW",
        )
        if (z) {
          siteId = z?.site_id
        } else {
          z = zones?.find(
            (s) =>
              s?.type_indicator_code === 1 &&
              s?.contract_type_code === 1 &&
              s?.line_of_business === "ROLLCONST",
          )
          if (z) {
            siteId = z?.site_id
          } else {
            z = zones?.find(
              (s) =>
                s?.type_indicator_code === 1 &&
                s?.contract_type_code === 1 &&
                s?.line_of_business === "ROLLREC",
            )
            if (z) {
              siteId = z?.site_id
            }
          }
        }

        dispatch(getOutages(siteId))
        dispatch(fulfilledAC(finalResponse))
        if (finalResponse.products && finalResponse.products.length > 0) {
          return finalResponse
        }
        return handleErrorResponse({ data: finalResponse }, dispatch, getState)
      })
      // return null
    })
    .catch((error) => {
      dispatch(failedAC(error.response?.status))
      return null
    })
}

export const clearOnlineProductsUserSelection =
  (clearSelection) => (dispatch) => {
    dispatch({
      type: CLEAR_ONLINE_PRODUCTS_USER_SELECTION,
      payload: clearSelection,
    })
  }

export const intializeProductResults = () => (dispatch) => {
  dispatch({
    type: INITIALIZE_PRODUCT_RESULTS,
  })
}

export const setOnlineProductsUserSelection = (userSelection) => (dispatch) => {
  dispatch({
    type: SET_ONLINE_PRODUCTS_USER_SELECTION,
    payload: userSelection,
  })
}

/**
 * Fetches ecommerce pricing based on the number of days selected by the user.
 * @param {number} days - The number of days selected by the user.
 * @returns A promise.
 */
export const getEcommercePricing = (days) => (dispatch, getState) => {
  const { onlineProducts } = getState()

  const freeDays = get(
    onlineProducts.onlineEcommercePricing,
    `products[0].free_days_included`,
    0,
  )

  if (freeDays < days + 1) {
    const shortTermPricingEngineId = encodeURI(
      get(
        onlineProducts,
        `onlineEcommercePricing.products[0].attributes[1].pricing_engine_request_id`,
        0,
      ),
    )
    const datesCount = days

    const api = `ECOMMERCE_PRICING`
    const template = URITemplate(urls.url[api])
    const url = template.expand({
      shortTermPricingEngineId,
      datesCount: datesCount + 1,
    })

    const config = {
      url,
      method: `get`,
      headers: {
        "Content-Type": `application/json`,
        ClientId: urls.cloudCraze.headers.ClientId,
        "Request-Tracking-Id": generateUniqueID(),
      },
    }
    dispatch(pendingPricing())
    return axios(config)
      .then((response) => {
        dispatch(fulfilledPricing(response.data))
        dispatch(getServletDetails())
        if (response.data.products && response.data.products.length > 0) {
          //  fulfilledPricing(response.data.products)
          return response.data
        }
        return handleErrorResponse(response, dispatch, getState)
        // return null
      })
      .catch((error) => {
        dispatch(failedPricing(error.response.status))
        return null
        // return response
      })
  }

  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve) => {
    dispatch({ type: RESET_ECOM_PRICING })
    dispatch(getServletDetails())
    resolve(true)
  })
}
