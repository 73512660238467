import PropTypes from "prop-types"
import { withLocalization } from "hoc-lib/localization"
import { GenericModal } from "../../index"

export const strings = {
  en_CA: {
    MODAL_EXPIRED_SESSION_MAIN_TEXT: `Your session is about to end.`,
    MODAL_EXPIRED_SESSION_HELP_TEXT: `For your security, we'll automatically log you out of your account soon.`,
    LOG_OUT: `Log out`,
    STAY_LOGGED_IN: `Stay Logged in`,
  },
  fr_CA: {
    MODAL_EXPIRED_SESSION_MAIN_TEXT: `Votre session est sur le point de prendre fin.`,
    MODAL_EXPIRED_SESSION_HELP_TEXT: `Pour votre sécurité, nous vous déconnecterons automatiquement de votre compte bientôt.`,
    LOG_OUT: `Fermer la session`,
    STAY_LOGGED_IN: `Rester connecté`,
  },
}

const SessionExpirationModal = ({
  isVisible,
  logOut,
  onStayLoggedInClick,
  strings,
  onClose,
}) => (
  <GenericModal
    open={isVisible}
    onClose={onClose}
    icon="/content/dam/mywm/src-img/my-services/icon_time_out.svg"
    title={strings.MODAL_EXPIRED_SESSION_MAIN_TEXT}
    body={strings.MODAL_EXPIRED_SESSION_HELP_TEXT}
    primaryCTA={strings.LOG_OUT}
    primaryOnClick={logOut}
    secondaryCTA={strings.STAY_LOGGED_IN}
    secondaryOnClick={onStayLoggedInClick}
    modalTestingId="SessionExpirationModal"
    primaryTestingId="SessionExpirationModal-logout-button"
    secondaryTestingId="SessionExpirationModal-stay-logged-in-button"
  />
)

SessionExpirationModal.propTypes = {
  isVisible: PropTypes.bool,
  logOut: PropTypes.func.isRequired,
  onStayLoggedInClick: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired,
  onClose: PropTypes.func,
}

SessionExpirationModal.defaultProps = {
  isVisible: true,
  onClose: () => {},
}

export default withLocalization(strings)(SessionExpirationModal)
