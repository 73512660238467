import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import getLanguageRoute from "utils-lib/getLanguageRoute"
import { serviceTypes, YES, NO } from "constants-lib/common"
import {
  GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_FAILED,
  GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_FULFILLED,
  GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_PENDING,
  RESET_GUEST_CONTAINER_REPAIR,
  SET_GUEST_CONTAINER_REPAIR_SELECTION,
} from "constants-lib/actionTypes"

export const pendingAC = (productType) => ({
  type: GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_PENDING,
  productType,
})

export const failedAC = (fetchCode) => ({
  type: GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_FULFILLED,
  payload: data,
})

export const resetContainerRepair = () => ({
  type: RESET_GUEST_CONTAINER_REPAIR,
})

export const setContainerRepairSelection = (data) => ({
  type: SET_GUEST_CONTAINER_REPAIR_SELECTION,
  payload: data,
})

export const getGuestContainerRepairEligibility =
  (customAddress = null) =>
  (dispatch, getState) => {
    dispatch(pendingAC(serviceTypes.CITY_BILLED))
    const { customerSelections } = getState()
    const street =
      `${customerSelections.customerAddress.streetNumber} ${customerSelections.customerAddress.streetName}` ||
      ``
    if (street.trim() === ``) {
      dispatch(fulfilledAC({ invalidAddress: true }))
      return null
    }

    return getEligibility(customAddress, dispatch, getState).then((res) => {
      if (res) {
        if (res.url) {
          if (res.url === "URL") {
            dispatch(fulfilledAC(res))
          } else {
            window.open(res.url, `_self`)
            dispatch(fulfilledAC(res))
          }
        } else if (res.serviceUnavailable) {
          dispatch(failedAC({ serviceUnavailable: true, response: res }))
        } else if (res.disclaimerText) {
          dispatch(
            failedAC({
              serviceUnavailable: true,
              disclaimerText: res.miscInstructions,
              response: res,
            }),
          )
        } else {
          dispatch(failedAC({ searchFailed: true, response: res }))
        }
      }
    })
  }

export const getEligibility = (customAddress, dispatch, getState) => {
  const { customerSelections, siteLanguage } = getState()
  const street = customAddress
    ? customAddress.street
    : `${customerSelections.customerAddress.streetNumber} ${customerSelections.customerAddress.streetName}` ||
      ``
  const city = encodeURI(
    customAddress
      ? customAddress.city
      : customerSelections.customerAddress.city || ``,
  )
  const state = encodeURI(
    customAddress
      ? customAddress.state
      : customerSelections.customerAddress.state || ``,
  )
  const label = _.get(customerSelections, `customerAddress.label`, ``)
  const country = encodeURI(
    customAddress
      ? customAddress.country
      : customerSelections.customerAddress.country || ``,
  )
  const googlePlaceId = encodeURI(
    customAddress
      ? customAddress.gPlaceId
      : customerSelections.customerAddress.gPlaceId || ``,
  )
  const zipcode = customAddress
    ? customAddress.zipCode
    : customerSelections.customerAddress.postalCode || ``
  const latitude = encodeURI(customerSelections.customerAddress.lat || ``)
  const longitude = encodeURI(customerSelections.customerAddress.lng || ``)
  const locale = encodeURI(siteLanguage.language || ``)

  const api = `GUEST_CONTAINER_REPAIR_ELIGIBLE`
  const apiKey = _.get(urls, `apiKey.GUEST[${api}]`, ``)
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    zipcode,
    locale,
    street,
    label,
    city,
    state,
    country,
    latitude,
    longitude,
    googlePlaceId,
  })

  const config = {
    url,
    method: `get`,
    headers: {
      apiKey,
      "Content-Type": `application/json`,
    },
  }
  return axios(config)
    .then((response) => {
      const data = _.get(response, "data.data")
      const requestTrackingId = _.get(response, "data.requestTrackingId", ``)

      if (_.has(data, "customerId") && _.get(data, "customerId")) {
        data.customerId = data.customerId.replaceAll("-", "")
      }
      const miscInstructions = _.get(data, "miscInstructions", ``)
      if (data) {
        data.requestTrackingId = requestTrackingId
        const { isEligible, reason } = data
        if (
          isEligible === YES &&
          (reason === "cityBilledAndFranchise" || reason === "HOAEligible")
        ) {
          const { token } = response.headers
          data.token = token
          return {
            ...data,
            url: "URL",
          }
        }

        if (reason === "loggedInEligible") {
          const languageRoute = getLanguageRoute(siteLanguage)
          return {
            ...data,
            url: `${languageRoute}/us/en/mywm/user/dashboard`,
          }
        }

        if (isEligible === NO && miscInstructions !== "") {
          return {
            ...data,
            disclaimerText: true,
            miscInstructions,
          }
        }

        return {
          ...data,
          serviceUnavailable: true,
        }
      }

      return null
    })
    .catch((error) => {
      dispatch(failedAC(error.response?.status))
      return { searchFailed: true }
    })
}
