/* eslint-disable space-before-blocks */
import { lazy, Suspense, Component } from "react"
import PropTypes from "prop-types"
import PubSub from "pubsub-js"
import * as pubSubTopics from "constants/pubSubTopics"
import LogOutModal from "component-lib/Base/components/LogOutModal/LogOutModal"
import SessionExpirationModal from "component-lib/Base/components/SessionExpirationModal/SessionExpirationModal"
import _ from "lodash"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import { wmTheme } from "themes-lib/wmTheme"
import { authClient } from "constants-lib/okta"
import { getLanguageFromRoute, getBrowserLanguage } from "utils-lib/route"
import { LanguageContext } from "contexts-lib/LanguageContext"
import { AuthenticationContext } from "contexts-lib/AuthenticationContext"
import { getTimeLeftSec } from "utils-lib/time"
import { userType } from "constants-lib/authentication"
import { logRocketIdentify } from "utils-lib/logRocket"

const Header = lazy(() => import("component-lib/Header/HeaderContainer"))

/* Constants to help with development testing. */
const DEV_DISABLE_AUTO_REFRESH = false
const DEV_SHOW_EXPIRATION_WARNING = false // See expiration warning.
const DEV_ENABLE_QUICK_AUTO_LOGOUT = false // Auto logs out after 1 minutes.

const MS_PER_SEC = 1000
const SECONDS_PER_MINUTE = 60

/* The interval wait time between checking for mouse/keyboard activity.
   Check activity every 5 seconds. */
const TIME_BETWEEN_CHECKING_ACTIVITY = MS_PER_SEC * 5

/* The interval wait time between checking expiration status of session.
   Check session expiration status every 5 seconds. */
const TIME_BETWEEN_SESSION_STATUS = MS_PER_SEC * 5

/* The amount of idle time allowed before auto-renewing an expiring token.
   Idle time allowed: 5 minute. */
const IDLE_TIME_SECONDS = DEV_DISABLE_AUTO_REFRESH ? 10 : SECONDS_PER_MINUTE * 5
const IDLE_TIME_ALLOWED_BEFORE_AUTO_REFRESH = MS_PER_SEC * IDLE_TIME_SECONDS

/* How much time left before showing the token expiration warning.
   Okta gives 15 minutes in dev and in QA.
   Time left: 5 minutes. */
const TIME_LEFT_BEFORE_SHOWING_EXPIRATION_WARNING_SEC =
  DEV_SHOW_EXPIRATION_WARNING
    ? SECONDS_PER_MINUTE * 15 - 10
    : SECONDS_PER_MINUTE * 5

/* How much time left before automatically logging the user out.
   We'll auto log out after 2 minutes of showing the expiration warning.
   Time left: 3 minutes, which means expiration warning will show for 2 minutes. */
const TIME_LEFT_BEFORE_AUTO_LOGOUT_SEC = DEV_ENABLE_QUICK_AUTO_LOGOUT
  ? SECONDS_PER_MINUTE * 14 + 30
  : SECONDS_PER_MINUTE * 3

class HeaderAppContainer extends Component {
  debounceSetLastActivityTime = _.debounce(() => {
    // this method gets annoying during development so turning it off
    // on local environment only
    this.props.reduxActions.setLastActivityTime()
  }, TIME_BETWEEN_CHECKING_ACTIVITY)

  constructor(props) {
    super(props)

    this.state = {
      checkingUserState: true,
      closeExpirationModal: false,
      showExpirationWarning: false,
      showLogOutModal: false,
    }
  }

  /* This code gets called the first time the HeaderApp (website) is loaded (refreshed). */
  componentDidMount() {
    this.updateLanguageIfNeeded()
    this.props.reduxActions.setViewport()
    window.addEventListener(`resize`, this.setViewport)

    /* Check if the user is logged in. */
    if (
      this.props.reduxState.userAccount.userState === userType.LOGGED_IN ||
      this.props.reduxState.userAccount.userState === userType.LOGGING_IN
    ) {
      this.setState({
        checkingUserState: false,
      })
      /* https://github.com/okta/okta-auth-js#sessionget */
      authClient.session
        .get()
        .then((session) => {
          if (session) {
            /* The user is logged in. */
            this.props.reduxActions.getToken().then((accessToken) => {
              this.getTokenCallback(accessToken)
            })
          } else {
            /* The user is NOT logged in. */
            this.props.reduxActions.resetUserAccount(userType.LOGGING_OUT)
          }
        })
        .catch((err) => {
          /* Failed to determine if user is logged in or not. */
          this.props.reduxActions.resetUserAccount(userType.LOGGING_OUT)
          console.warn(`Error fetching session.`, err)
        })
    } else {
      /* If user is in logged out state, check if there's an active session that may have started
         from another HeaderApp (e.g. CloudCraze). */
      this.checkIfLoggedInElsewhere()
    }
  }

  componentWillUnmount() {
    window.removeEventListener(`resize`, this.setViewport)
    this.debounceSetLastActivityTime.cancel()
    this.stopTrackingAuthenticationStatus()
  }

  setViewport = () => {
    this.props.reduxActions.setViewport()
  }

  onRootClick = (event) => {
    PubSub.publish(pubSubTopics.ON_ROOT_CLICK, event)
  }

  startTrackingAuthenticationStatus = () => {
    const sessionIntervalId = setInterval(
      this.checkAuthenticationStatus,
      TIME_BETWEEN_SESSION_STATUS,
    )
    this.props.reduxActions.setSessionIntervalId(sessionIntervalId)
  }

  stopTrackingAuthenticationStatus = () => {
    const sessionIntervalId = _.get(
      this.props.reduxState.userAccount,
      `sessionIntervalId`,
      null,
    )

    if (sessionIntervalId !== null) {
      clearInterval(sessionIntervalId)
      this.props.reduxActions.setSessionIntervalId(null)
    }
  }

  checkIfLoggedInElsewhere = () => {
    /* Try to get an active session. Could be active from another HeaderApp such as CloudCraze. */
    authClient.session.exists().then((exists) => {
      /* Check if an active session exists before calling the Okta auth session.get().  */
      if (exists) {
        authClient.session.get().then((session) => {
          /* Make sure use is logged in. */
          authClient.token
            .getWithoutPrompt({
              responseType: `token`,
            })
            .then((token) => {
              // Okta token object has changed the structure, but to be safe we are checking the old structure as well in case this changes again
              const accessToken = !_.isEmpty(token?.accessToken)
                ? token
                : token?.tokens?.accessToken

              /* User is logged in from an active session from another HeaderApp). */
              this.props.reduxActions.setToken(accessToken)

              this.getTokenCallback(accessToken)

              /* Save user details. */
              const userId = _.get(session, `userId`, ``)
              const login = _.get(session, `login`, ``)
              const data = {
                userId,
                login,
              }
              this.props.reduxActions.setUserDetails(data)

              /* Invalidate/Reset linked accounts status. */
              this.props.reduxActions
                .getLinkedAccounts({
                  token: accessToken,
                  userId,
                })
                .then(() => {
                  /* User id will be passed over to dashboard and other routes which will avoid Unnecessary redirections within the HeaderApp.
              /* Get user details - first name and last name do not come with the session object. */
                  this.props.reduxActions.getUserDetails({
                    token: accessToken,
                    userId,
                  })
                  this.setState({
                    checkingUserState: false,
                  })
                })
            })
            .catch(() => {
              if (
                this.props.reduxState.userAccount.userState !== userType.COOKIED
              ) {
                /* Maintain guest state, if user is currently a guest */
                this.props.reduxActions.resetUserAccount(userType.LOGGING_OUT)
              }
              this.setState({
                checkingUserState: false,
              })
            })
        })
      } else {
        /* The user is NOT logged in. */
        this.checkLoggedOutState()
        this.setState({
          checkingUserState: false,
        })
      }
    })
  }

  /*
    Determine which logged out state we're in.
    This method should only be called once we've checked okta session to
    determine that we're not logged in (from any HeaderApp).
  */
  checkLoggedOutState = () => {
    const currentUserState = this.props.reduxState.userAccount.userState
    /*
      If user is cookied, don't change the state, because the only way we know
      if a user is cookied is if they're already set as cookied in userAccount.userState.

      A user is cookied if:
      1. An address is saved in redux.
      2. User state has been marked as userType.COOKIED.
         userAccount.userState is set to userType.COOKIED on logout,
         so if we see that the current userAccount.userState is userType.COOKIED
         then we know the user was logged in previously.

      Check if user is PROSPECTIVE.
      A user is a prospective user if:
      1. An address is saved in redux.
      2. Current user state in redux is not userType.COOKIED.
    */
    if (this.hasAddress()) {
      if (currentUserState !== userType.COOKIED) {
        this.props.reduxActions.resetUserAccount(userType.PROSPECTIVE)
      }
      /*
        The user is cookied if we're here in the code. Don't change user state
        since it's already set to cookied.
      */
    } else {
      /* No address found in redux, so set the user as ANONYMOUS. */
      this.props.reduxActions.resetUserAccount(userType.ANONYMOUS)
    }
  }

  getTokenCallback = (token) => {
    if (token !== null) {
      const { firstName, lastName, login } =
        this.props.reduxState.userAccount.userDetails

      logRocketIdentify({
        name: `${firstName} ${lastName}`,
        email: login,
      })

      // this.props.reduxActions.getESBTokens()
      if (this.props.reduxState.userAccount.userState !== userType.LOGGED_IN) {
        /* Update the user redux state (from LOGGING_IN to LOGGED_IN). */
        this.props.reduxActions.setUserState(userType.LOGGED_IN)
      }

      /* Start tracking user activity to see how long they're idle for. */
      this.debounceSetLastActivityTime()
      document.onclick = this.debounceSetLastActivityTime
      document.onmousemove = this.debounceSetLastActivityTime
      document.onkeypress = this.debounceSetLastActivityTime

      /* Start tracking user's authentication status. */
      this.startTrackingAuthenticationStatus()
    }
  }

  checkAuthenticationStatus = () => {
    const { userAccount } = this.props.reduxState

    const expiresAt = _.get(userAccount, `token.expiresAt`, null)
    if (userAccount.userState === userType.LOGGED_IN && expiresAt !== null) {
      const accessTokenTimeLeftSec = getTimeLeftSec(expiresAt)
      console.warn(
        `${_.round(accessTokenTimeLeftSec)} seconds (~${_.round(
          accessTokenTimeLeftSec / 60,
        )} min) left`,
      )

      /* Display modal when session is about to expire. */
      if (
        accessTokenTimeLeftSec <=
        TIME_LEFT_BEFORE_SHOWING_EXPIRATION_WARNING_SEC
      ) {
        /* If there has been user activity, then auto-refresh the token, otherwise show warning. */
        const idleTime =
          _.now() - this.props.reduxState.authentication.lastActivityTime

        console.warn(`Idle for ${_.round(idleTime / 1000)} seconds`)
        /*
          Do not auto refresh once expiration warning is displayed.
        */

        if (
          idleTime <= IDLE_TIME_ALLOWED_BEFORE_AUTO_REFRESH &&
          !this.state.showExpirationWarning
        ) {
          this.refreshToken()
        } else {
          /*
            There was no activity during the allowed idle time before auto-refresh hHeaderAppens,
            so either auto log the user out or show the expiration warning.
          */
          // eslint-disable-next-line no-lonely-if
          if (accessTokenTimeLeftSec <= TIME_LEFT_BEFORE_AUTO_LOGOUT_SEC) {
            this.logOut(true) /* Show log out modal after successful logout. */
          } else {
            /* Display expiration warning. */
            this.showExpirationWarning()
          }
        }
      }
    }
  }

  logOutHelper = () => {
    /* User is logging out. Set userType to LOGGING_OUT so that HeaderApplicable redux states are cleared and
       Authenticated Routes redirect to the home page instead of the login page.  */
    this.props.reduxActions.resetUserAccount(userType.LOGGING_OUT)

    authClient
      .signOut()
      .then(() => {
        /* Okta signout completed. Set userType to GUEST so that Authenticated, Unauthenticated, Guest,
      and Router routes redirect HeaderAppropriately. */
        this.props.reduxActions.resetUserAccount(userType.COOKIED)
      })
      .fail((e) => {
        console.error(e)
      })
  }

  logOut = (showLogOutModal = false) => {
    /* User is not logged in. Stop the timer because there is no need to keep checking
    if the session is active. */
    this.debounceSetLastActivityTime.cancel()
    this.stopTrackingAuthenticationStatus()
    this.setState({
      closeExpirationModal: false,
      showExpirationWarning: false,
      showLogOutModal: showLogOutModal === true,
    })

    this.props.reduxActions
      .revokeToken()
      .then(() => {
        this.logOutHelper()
      })
      .catch(() => {
        this.logOutHelper()
      })
  }

  refreshToken = () => {
    this.hideExpirationWarning()

    /* Returns a new token if the Okta session is still valid. */
    this.props.reduxActions.refreshToken(() => {
      // this.props.reduxActions.getESBTokens()
      this.stopTrackingAuthenticationStatus()
      this.startTrackingAuthenticationStatus()
    })
  }

  showExpirationWarning = () => {
    /* Only set to true if user didn't close out of the expiration modal to prevent the modal from
    displaying again in the checkAuthenticationStatus call after the user already closed the window. */
    if (!this.state.closeExpirationModal) {
      this.setState({ showExpirationWarning: true })
    }
  }

  hideExpirationWarning = () => {
    this.setState({
      closeExpirationModal: false,
      showExpirationWarning: false,
    })
  }

  /* Check if an address is saved in redux. */
  hasAddress() {
    return (
      _.get(
        this.props.reduxState.customerSelections,
        `customerAddress.formattedAddress`,
        ``,
      ) !== ``
    )
  }

  /*
    If the url's locale does not match the saved language in the app, update app language to match
    the one in the url.

    If the app language has not been set, update it to the browser language.
  */
  updateLanguageIfNeeded() {
    const appLanguage = _.get(
      this.props.reduxState.siteLanguage,
      `language`,
      ``,
    )
    let urlLanguage = getLanguageFromRoute()
    urlLanguage = _.isEmpty(urlLanguage) ? getBrowserLanguage() : urlLanguage

    if (!_.isEmpty(urlLanguage) && urlLanguage !== appLanguage) {
      this.props.reduxActions.switchLanguage(urlLanguage, true)
    } else if (_.isEmpty(appLanguage)) {
      this.props.reduxActions.switchLanguage(urlLanguage)
    }

    this.props.reduxActions.getAEMJSON(urlLanguage)
  }

  render() {
    if (this.state.checkingUserState === true) {
      return null
    }

    const { reduxState } = this.props

    const { userAccount, siteLanguage } = reduxState

    const { userState } = userAccount

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={wmTheme}>
          <LanguageContext.Provider
            value={{
              language: siteLanguage.language,
              setLanguage: (language) => {
                this.props.reduxActions.switchLanguage(language)
              },
            }}
          >
            <AuthenticationContext.Provider
              value={{
                userState,
                logOut: this.logOut,
                isLoggedIn: userState === userType.LOGGED_IN,
                userDetails: userAccount.userDetails,
              }}
            >
              <div
                data-testid="HeaderApp"
                className="exportedHeaderApp"
                onClick={this.onRootClick}
                style={{ height: `100%` }}
              >
                <Suspense fallback={null}>
                  <Header />
                </Suspense>
                {this.state.showExpirationWarning ? (
                  <SessionExpirationModal
                    language={siteLanguage.language}
                    isVisible={this.state.showExpirationWarning}
                    logOut={this.logOut}
                    onStayLoggedInClick={() => {
                      this.refreshToken()
                      this.setState({
                        closeExpirationModal: false,
                        showExpirationWarning: false,
                      })
                    }}
                    onClose={() => {
                      this.setState({
                        closeExpirationModal: true,
                        showExpirationWarning: false,
                      })
                    }}
                  />
                ) : null}
                {this.state.showLogOutModal ? (
                  <LogOutModal
                    isVisible={this.state.showLogOutModal}
                    siteLanguage={this.props.reduxState.siteLanguage}
                    onClose={() => {
                      this.setState({
                        showLogOutModal: false,
                      })
                    }}
                  />
                ) : null}
                <div className="dropdown-container" />
              </div>
            </AuthenticationContext.Provider>
          </LanguageContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }
}

HeaderAppContainer.propTypes = {
  reduxActions: PropTypes.shape({
    getAEMJSON: PropTypes.func.isRequired,
    getLinkedAccounts: PropTypes.func.isRequired,
    getToken: PropTypes.func.isRequired,
    getUserDetails: PropTypes.func.isRequired,
    refreshToken: PropTypes.func.isRequired,
    resetUserAccount: PropTypes.func.isRequired,
    revokeToken: PropTypes.func.isRequired,
    setLastActivityTime: PropTypes.func.isRequired,
    setMyServicesMain: PropTypes.func.isRequired,
    setRenderDashboard: PropTypes.func.isRequired,
    setSessionIntervalId: PropTypes.func.isRequired,
    setToken: PropTypes.func.isRequired,
    setUserDetails: PropTypes.func.isRequired,
    setUserState: PropTypes.func.isRequired,
    setViewport: PropTypes.func.isRequired,
    switchLanguage: PropTypes.func.isRequired,
  }).isRequired,
  reduxState: PropTypes.shape({
    authentication: PropTypes.object.isRequired,
    customerSelections: PropTypes.object.isRequired,
    siteLanguage: PropTypes.object.isRequired,
    userAccount: PropTypes.object.isRequired,
  }).isRequired,
}

export default HeaderAppContainer
