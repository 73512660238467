import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"
import { MOCK_PAPERELESS_PREFERENCE_SET } from "utils-lib/mocks"
import { getPaperlessPreferences } from "./getPaperlessPreferences"

export const pendingAC = () => ({
  type: ActionTypes.SET_PAPERLESS_PREFERENCES_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.SET_PAPERLESS_PREFERENCES_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.SET_PAPERLESS_PREFERENCES_FULFILLED,
  payload: data,
})

export const setPaperlessPreferences =
  (data, customerIdToEnroll = ``) =>
  (dispatch, getState) => {
    const state = getState()
    const api = `PAPERLESS_PREFERENCES`
    dispatch(pendingAC())

    const template = URITemplate(urls.url[api]) // `/account/{customerId}/paperlessPreferences?userId={userId}&lang={lang}`,
    const customerId =
      customerIdToEnroll === ``
        ? _.get(
            state.customerAutoPayPaperless,
            `autoPaymentAndPaperlessAccount`,
            ``,
          )
        : customerIdToEnroll
    const userId = _.get(state, `userAccount.userDetails.userId`, ``)
    const lang = _.get(state, `siteLanguage.language`, `en_CA`)
    const url = MOCK_PAPERELESS_PREFERENCE_SET
      ? `${window.origin}/mock/paperless-preferences-set-response.json`
      : template.expand({ customerId, userId })

    return getTokenAction(dispatch, getState)
      .then((token) => {
        const config = {
          headers: {
            oktaToken: token.accessToken,
          },
          params: {
            lang,
          },
        }

        const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }

        return axios
          .create(config)
          .post(url, data)
          .then(async (response) => {
            const paperlessPreferences = await getPaperlessPreferences()(
              dispatch,
              getState,
            )

            dispatch(fulfilledAC(paperlessPreferences))
            return response
          })
      })
      .catch((response) => {
        dispatch(
          failedAC({
            statusCode: _.get(response, `response.status`, ``),
            errorMessage: _.get(response, `response.data.Error`, ``),
          }),
        )
      })
  }

export const clearSetPaperlessPreferences = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_SET_PAPERLESS_PREFERENCES_STATUS,
  })
}
