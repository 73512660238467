import {
  GET_AEM_PAGE_JSON_PENDING,
  GET_AEM_PAGE_JSON_FAILED,
  GET_AEM_PAGE_JSON_FULFILLED,
} from "constants-lib/actionTypes"

const initialState = {
  pageData: {},
  getJSONStatus: ``,
}

export default function aemPageData(state = initialState, { type, payload }) {
  switch (type) {
    case GET_AEM_PAGE_JSON_PENDING:
      return {
        ...state,
        getJSONStatus: `pending`,
      }

    case GET_AEM_PAGE_JSON_FAILED:
      return {
        ...state,
        getJSONStatus: `failed`,
      }

    case GET_AEM_PAGE_JSON_FULFILLED:
      const updatedPageData = { ...state.pageData, ...payload[0] }

      return {
        pageData: updatedPageData,
        getJSONStatus: `fulfilled`,
      }

    default:
      return state
  }
}
