export const SET_APPLE_PAY_CONFIRM = `SET_APPLE_PAY_CONFIRM`

export const setApplePayConfirm = (
  confirmationNumber,
  paymentMethod,
  isPayToday = true,
  isApplePayAutoPayEnrolled = false,
) => ({
  type: SET_APPLE_PAY_CONFIRM,
  payload: {
    isApplePayConfirm: true,
    isApplePayScheduled: !isPayToday,
    scheduledConfirmationNumber: !isPayToday ? confirmationNumber : ``,
    confirmationNumber,
    paymentMethod,
    isApplePayAutoPayEnrolled,
  },
})
