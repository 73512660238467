import * as ActionTypes from "constants-lib/actionTypes"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import get from "lodash/get"
import { urls } from "utils-lib/builds"
import { getTokenAction } from "../getToken"

export const pendingAC = () => ({
  type: ActionTypes.GET_REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.GET_REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY_FULFILLED,
  payload: data,
})

//! This action was built to support single accounts only. To implement multiple accounts, some changes will be required.
export const getRedesignedDashboardServiceEligibility =
  () => (dispatch, getState) => {
    const state = getState()
    const api = `REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY`
    const lang = state.siteLanguage?.language ? state.siteLanguage.language : ``
    const template = URITemplate(urls.url[api])
    const userId = state.userAccount?.userDetails?.userId

    const url = template.expand({
      lang,
      userId,
    })

    const config = {
      headers: {},
    }

    return getTokenAction(dispatch, getState).then((token) => {
      const state = getState()
      const customerId =
        state.userManageAccount?.linkedAccounts[0]?.custAccountId

      config.headers = {
        token: token.accessToken,
        apiKey: get(urls, `apiKey.USER[${api}]`, ``),
      }

      return getRedesignedDashboardServiceEligibilityHelper(
        url,
        config,
        dispatch,
        customerId,
      )
    })
  }

/**
 * Fetches eligibility data for redesigned dashboard services for a specific customer and updates the Redux store.
 * @param {string} url - The endpoint URL to fetch the service eligibility data.
 * @param {Object} config - The Axios configuration object for the request.
 * @param {Function} dispatch - The Redux dispatch function used to dispatch actions based on the request outcome.
 * @param {string} customerId - The unique identifier of the customer for whom the eligibility data is being fetched.
 */
export const getRedesignedDashboardServiceEligibilityHelper = (
  url,
  config,
  dispatch,
  customerId,
) => {
  dispatch(pendingAC())

  const data = {
    customerIds: [customerId],
  }

  return axios
    .create(config)
    .post(url, data)
    .then((response) => {
      const statusCode = get(response, `status`, ``)
      dispatch(
        fulfilledAC({
          redesignedDashboardServiceEligibilityData:
            response.data?.data?.customerDetails[0],
          statusCode,
          actualResponse: response.data,
        }),
      )
      return response.data?.data?.customerDetails[0]
    })
    .catch((error) => {
      dispatch(failedAC(get(error, `response.status`, ``)))
    })
}
