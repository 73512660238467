import {
  PAYMENT_METHODS,
  PAYMENT_METHOD_VALUES,
} from "constants-lib/paymentMethods"

/**
 * Checks if a payment method is an ACH payment method.
 * @param {string} paymentMethodLabel - The label of the payment method.
 * @returns {boolean} - Returns true if the payment method is an ACH payment method, false otherwise.
 */
export const isACHPaymentMethod = (paymentMethodLabel) => {
  const paymentMethodType = paymentMethodLabel
    ?.split(/[*x]/)?.[0]
    ?.replace(/%20|\s+/g, "")
    ?.trim()
  return (
    paymentMethodType?.toUpperCase() ===
      PAYMENT_METHODS.SAVINGS.toUpperCase() ||
    paymentMethodType?.toUpperCase() ===
      PAYMENT_METHODS.CHECKINGS.toUpperCase() ||
    paymentMethodType?.toUpperCase() === PAYMENT_METHODS.CHEQUING.toUpperCase()
  )
}

/**
 * Checks if a payment method is a digital payment method.
 * @param {string} paymentMethodLabel - The label of the payment method.
 * @returns {boolean} - Returns true if the payment method is a digital payment method, otherwise false.
 */
export const isDigitalPaymentMethod = (paymentMethodLabel) => {
  const paymentMethodType = paymentMethodLabel
    ?.split("*")?.[0]
    ?.replace(/%20|\s+/g, "")
    ?.trim()
  return (
    paymentMethodType?.toUpperCase() === PAYMENT_METHOD_VALUES.GP ||
    paymentMethodType?.toUpperCase() === PAYMENT_METHOD_VALUES.AP ||
    paymentMethodType?.toUpperCase() ===
      `${PAYMENT_METHOD_VALUES.PAYPAL_ACCOUNT}-` ||
    paymentMethodType?.toUpperCase() ===
      `${PAYMENT_METHOD_VALUES.PAYPAL_ACCOUNT}`
  )
}
