import get from "lodash/get"
import { logRocketIdentify } from "utils-lib/logRocket"
import {
  SET_CUSTOMER_LOB,
  SET_CHECK_AVAILABILITY_LOB,
  SET_PRIMARY_ADDRESS,
  SET_CUSTOMER_ADDRESS,
  SET_ERROR_ON_SUGGEST,
  SET_ERROR_ON_SUGGEST_SCHEDULE,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_BUSINESS_TYPE,
  SET_APARTMENT_NUMBER,
  RESET_CUSTOMER_SERVICE_TYPE,
  SET_GEOCODE_CUSTOMER_ADDRESS,
  SET_HAS_GEOSUGGESTION,
  SET_CUSTOMER_SERVICE_TYPE,
  SET_CUSTOMER_CONTACT_US_METHOD,
  SET_CUSTOMER_EMAIL,
  SET_CUSTOMER_ID,
  SET_CUSTOMER_PHONE,
  SET_CUSTOMER_PRODUCT,
  SET_RENDER_DASHBOARD,
  SET_GUIDED_FLOW_SELECTIONS,
  SET_BUISNESS_HELPMECHOOSE,
} from "constants-lib/actionTypes"

export const resetCustomerServiceType = () => ({
  type: RESET_CUSTOMER_SERVICE_TYPE,
})

export const setPrimaryAddress = (data) => ({
  type: SET_PRIMARY_ADDRESS,
  payload: data,
})

export const setCustomerLob = (data) => ({
  type: SET_CUSTOMER_LOB,
  payload: data,
})

export const setCheckAvailabilityLob = (checkAvailabilityLob) => ({
  type: SET_CHECK_AVAILABILITY_LOB,
  payload: {
    checkAvailabilityLob,
  },
})

export const onSuggestNoResults = (suggestions, value, forceError = false) => {
  if (
    (suggestions && suggestions.length === 0 && value.length > 2) ||
    forceError
  ) {
    return {
      type: SET_ERROR_ON_SUGGEST,
      payload: true,
    }
  }
  return {
    type: SET_ERROR_ON_SUGGEST,
    payload: false,
  }
}

export const onSuggestNoResultsSchedule = (suggestions, value) => {
  if (suggestions && suggestions.length === 0 && value.length > 2) {
    return {
      type: SET_ERROR_ON_SUGGEST_SCHEDULE,
      payload: true,
    }
  }
  return {
    type: SET_ERROR_ON_SUGGEST_SCHEDULE,
    payload: false,
  }
}

export const setCustomerAddress = (data) => {
  logRocketIdentify({
    address: get(data, `formattedAddress`, ``),
  })

  return {
    type: SET_CUSTOMER_ADDRESS,
    payload: data,
  }
}

export const setFirstName = (data) => ({
  type: SET_FIRST_NAME,
  payload: data,
})

export const setLastName = (data) => ({
  type: SET_LAST_NAME,
  payload: data,
})
export const setBusinessType = (data) => ({
  type: SET_BUSINESS_TYPE,
  payload: data,
})

export const setApartmentNumber = (data) => ({
  type: SET_APARTMENT_NUMBER,
  payload: data,
})

export const setGeocodeCustomerAddress = (data) => ({
  type: SET_GEOCODE_CUSTOMER_ADDRESS,
  payload: data,
})

export const setHasGeosuggestion = (data) => ({
  type: SET_HAS_GEOSUGGESTION,
  payload: data,
})

export const setCustomerServiceType = (data) => ({
  type: SET_CUSTOMER_SERVICE_TYPE,
  payload: data,
})

export const setCustomerContactUsMethod = (data) => ({
  type: SET_CUSTOMER_CONTACT_US_METHOD,
  payload: data,
})

export const setCustomerEmail = (data) => ({
  type: SET_CUSTOMER_EMAIL,
  payload: data,
})

export const setCustomerId = (data) => ({
  type: SET_CUSTOMER_ID,
  payload: data,
})

export const setCustomerPhone = (data) => ({
  type: SET_CUSTOMER_PHONE,
  payload: data,
})

export const setCustomerProduct = (data) => ({
  type: SET_CUSTOMER_PRODUCT,
  payload: data,
})

export const setRenderDashboard = (data) => ({
  type: SET_RENDER_DASHBOARD,
  payload: data,
})

export const setGuidedFlowSelections = (data) => ({
  type: SET_GUIDED_FLOW_SELECTIONS,
  payload: data,
})

export const setBusinessHelpMeChoose = (data) => ({
  type: SET_BUISNESS_HELPMECHOOSE,
  payload: data,
})
