import PropTypes from "prop-types"
import Toggle from "react-toggle"
import { handleEnterPress } from "utils-lib/handleEnterPress"
import { makeStyles } from "tss-react/mui"
import { white, trueGreen } from "constants-lib/colors"
import "react-toggle/style.css"

const useStyles = makeStyles()(() => ({
  root: {
    "& .switchLabel": {
      fontSize: `1.125rem`,
      lineHeight: `1.5`,
      "&:active": {
        outline: `0 !important`,
      },
      "&.selected": {
        outline: `0 !important`,
      },
    },

    "& .react-toggle": {
      touchAction: `pan-x`,
      display: `inline-block`,
      position: `relative`,
      cursor: `pointer`,
      backgroundColor: `transparent`,
      border: 0,
      padding: 0,
      userSelect: `none`,
      "-webkit-tap-highlight-color": `transparent`,

      "& .react-toggle-track": {
        height: `2.125rem`,
        width: `4.25rem`,
        backgroundColor: `${white} !important`,
        border: `2px solid ${trueGreen}`,
      },

      "& .react-toggle-thumb": {
        marginTop: `.25rem`,
        marginLeft: `.5rem`,
        height: `1.5rem`,
        width: `1.5rem`,
        backgroundColor: trueGreen,
        border: `none`,
        boxShadow: `none !important`,
      },
    },
    "& .react-toggle-screenreader-only": {
      order: 0,
      clip: `rect(0 0 0 0)`,
      height: `1px`,
      margin: `-1px`,
      overflow: `hidden`,
      padding: 0,
      position: `absolute`,
      width: `1px`,
    },
  },
}))

const AEMSwitch = ({
  leftChoice,
  rightChoice,
  checked,
  onToggle,
  className,
  style,
  hide,
  leftAriaLabel,
  rightAriaLabel,
}) => {
  const { classes } = useStyles()
  return (
    <div
      data-testid="Switch"
      className={`Switch ${
        classes.root
      } py-1 py-lg-0 d-flex w-auto align-items-center justify-content-start ${className} ${
        hide ? `hide-toggle` : ``
      }`}
      style={style}
    >
      <div
        data-testid="Switch - leftChoice"
        className={`switchLabel ${
          !checked
            ? `maax-font-weight-bold selected`
            : `wm-cursor-pointer maax-font-weight-regular`
        }`}
        onClick={() => {
          if (checked) {
            onToggle(false)
          }
        }}
        onKeyDown={(e) =>
          handleEnterPress(e, () => {
            if (checked) {
              onToggle(false)
            }
          })
        }
        aria-label={leftAriaLabel}
        tabIndex="0"
        role="button"
      >
        {leftChoice}
      </div>
      <Toggle
        checked={checked}
        icons={false}
        onChange={(e) => {
          onToggle(e.target.checked)
        }}
        className={`mx-2 ${hide ? `d-none` : ``}`}
      />
      <div
        data-testid="Switch - rightChoice"
        className={`switchLabel ${
          checked
            ? `maax-font-weight-bold selected`
            : `wm-cursor-pointer maax-font-weight-regular`
        }`}
        onClick={() => {
          if (!checked) {
            onToggle(true)
          }
        }}
        onKeyDown={(e) =>
          handleEnterPress(e, () => {
            if (!checked) {
              onToggle(true)
            }
          })
        }
        aria-label={rightAriaLabel}
        tabIndex="0"
        role="button"
      >
        {rightChoice}
      </div>
    </div>
  )
}

AEMSwitch.propTypes = {
  leftChoice: PropTypes.string.isRequired,
  rightChoice: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  hide: PropTypes.bool,
  leftAriaLabel: PropTypes.string,
  rightAriaLabel: PropTypes.string,
}

AEMSwitch.defaultProps = {
  hide: false,
  className: ``,
  style: {},
  leftAriaLabel: ``,
  rightAriaLabel: ``,
}

export default AEMSwitch
