import isNaN from "lodash/isNaN"
import get from "lodash/get"
import find from "lodash/find"
import { getFormattedCustomerId } from "./getFormattedCustomerId"
import {
  getPaddedCustomerId,
  getTrimmedCustomerId,
} from "./getPaddedCustomerId"

export const D_RE = /\d/
export { getRawCustomerId } from "./getRawCustomerId"
export { getFormattedCustomerId } from "./getFormattedCustomerId"
export {
  getPaddedCustomerId,
  getTrimmedCustomerId,
} from "./getPaddedCustomerId"
// export { maskCustomerIDForGuestUsers } from "./maskCustomerID"
// export { maskInvoice } from "./maskInvoice"
export { removeNonNumericCharacters } from "./removeNonNumericCharacters"
export { getFormattedAddress } from "./getFormattedAddress"
export { removeSpecialCharacters } from "./removeSpecialCharacters"
export { removeAllSpecialCharacters } from "./removeAllSpecialCharacters"
export { padValidationForCheckout } from "./padValidationForCheckout"
export { getPadCheckingAcc } from "./getPadCheckingAcc"
export { isNewCanadianAch } from "./getPadCheckingAcc"
export { getStringLength } from "./getStringLength"
export { removeTrailingZeros } from "./removeTrailingZeros"
export { getFormattedAmount } from "./getFormattedAmount"
export { getFormattedNumber } from "./getFormattedPhone"
export { isValidCADPostalCode } from "./isValidCADPostalCode"
export { getFormattedAddressAutoPayPaperless } from "./getFormattedAddressAutoPayPaperless"
// export { normalize } from "./normalize"
export { getFormattedExpirationDate } from "./getFormattedExpirationDate"
export {
  formatCalendarDate,
  formatCalendarDateString,
} from "./formatCalendarDate"

export { formatPhoneNumber } from "./formatPhoneNumber"
export { convertToInitialCase } from "./convertToInitialCase"

/**
 * Takes a customerId, pads it with zeros, formats it, and then trims it.
 *
 * @function
 * @param {string} customerId - The customer ID that you want to format.
 * @returns {string} The trimmed and formatted customer ID.
 */
export const getFormattedBackendCustomerId = (customerId) => {
  const paddedRawCustomerId = getPaddedCustomerId(customerId)
  const formattedPaddedCustomerIdObject =
    getFormattedCustomerId(paddedRawCustomerId).customerId
  return getTrimmedCustomerId(formattedPaddedCustomerIdObject)
}

/**
 * If the last four characters of the account number are not a number, return
 * the account number with a dash in front of it. Otherwise, return the last
 * four characters of the account number with asterisks in front of it.
 *
 * @function
 * @param {string} accountNumber - The account number of the payment account.
 * @returns {string} A string that is the last 4 digits of the account number.
 */
export const getFormattedPaymentAccountNumber = (accountNumber) => {
  const shortenedAccountNumber = accountNumber.substr(accountNumber.length - 4)

  if (isNaN(parseInt(shortenedAccountNumber, 10))) {
    return ` - ${accountNumber}`
  }

  return `****${shortenedAccountNumber}`
}

/**
 * If the selectedPayMethodAccountNo is not empty, get the label from the
 * returned query param.
 *
 * @function
 * @param {object} checkout - The checkout object.
 * @param {array} methods - An array of payment methods.
 * @param {string} guestPayMethodAccountNo - The payment method account number that was just added.
 * @returns {string} The label of the selected payment method.
 */
export const getPaymentMethodLabel = (
  checkout,
  methods,
  guestPayMethodAccountNo,
) => {
  let selectedPayMethodAccountNo

  if (guestPayMethodAccountNo) {
    selectedPayMethodAccountNo = guestPayMethodAccountNo
  } else {
    selectedPayMethodAccountNo = get(checkout, `selectedPayMethodAccountNo`, ``)
  }

  const selectedPayMethodId = get(checkout, `selectedPayMethodId`, ``)

  /*
     If coming from an added payment method (selectedPayMethodAccountNo is not 
     empty), get the label from the returned query param.
     Sample: MC%20xxxxxxxxxxxx4444
  */
  let subType = ``
  let accountNumber = ``
  if (selectedPayMethodAccountNo !== ``) {
    const index =
      selectedPayMethodAccountNo.indexOf("x") === -1
        ? selectedPayMethodAccountNo.indexOf("*")
        : selectedPayMethodAccountNo.indexOf("x")
    subType = selectedPayMethodAccountNo
      .substring(0, index)
      .replace(/%20|\s+/g, "")
    accountNumber = selectedPayMethodAccountNo.substring(index)
  } else {
    const method = find(methods, [`payMethodId`, selectedPayMethodId])
    subType = get(method, `methodSubType`, ``)
    accountNumber = get(method, `methodAcctNo`, ``)
  }
  const label = `${subType} ${getFormattedPaymentAccountNumber(accountNumber)}`

  return label
}

/**
 * Takes a subType and a formattedAccountNo and returns a formattedSavedPaymentMethodLabel.
 *
 * @function
 * @param {string} subType - The type of payment method. For example, Visa, Mastercard, etc.
 * @param {string} formattedAccountNo - The account number of the saved payment method.
 * @returns {string} A string that is the subType and formattedAccountNo.
 */
export const getFormattedSavedPaymentMethodLabel = (
  subType,
  formattedAccountNo,
) => {
  const formattedSavedPaymentMethodLabel = `${subType} ${formattedAccountNo}`
  return formattedSavedPaymentMethodLabel
}

/**
 * Takes a string and replaces any URLs in it with HTML anchor tags.
 *
 * @function
 * @param {string} text - The text to be converted.
 * @returns {string} A string with all the URLs replaced with anchor tags.
 */
export const urlify = (text) => {
  const urlRegex = /((?=[^[(/])).(((https?:\/\/)|(www\.))[^\s]+)/g
  return text.replace(urlRegex, (url, b, c) => {
    const url2 = c === `www.` ? `http://${url}` : url
    return `<a href="${url2}" target="_blank" rel="noopener noreferrer">${url.replace(
      "http://",
      "https://",
    )}</a>`
  })
}

/**
 * Takes a string of text, and replaces any markdown links with HTML links.
 *
 * @function
 * @param {string} text - The text to be parsed.
 * @returns {string} A string with all the markdown links replaced with HTML links.
 */
export const markdownUrlify = (text) => {
  const exp = /\[([^\]\n]+)\]\(([^)\n]+)\)/g
  return text.replace(/http:/g, "https:").replace(exp, `<a href='$2'>$1</a>`)
}
/**
 * A function that extracts the YouTube video ID from a string.
 * @param {string} text - The string to extract the YouTube video ID from.
 * @returns {string|null} - The extracted YouTube video ID, or null if no ID was found.
 */
export const getYoutubeID = (text) => {
  const exp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/
  const match = exp.exec(text)
  return match ? match[2] : null
}

/**
 * `defaultToSpace` is a function that takes a string as an argument and
 * returns that string if it's not empty, otherwise it returns a space.
 *
 * @function
 * @param {string} inText - The text to be checked.
 * @returns {string} The input string if it's not empty, otherwise a space.
 */
export const defaultToSpace = (inText) => {
  if (typeof inText === `string` && inText.length > 0) {
    return inText
  }
  return ` `
}

/**
 * Takes a phone number as a string, removes all non-numeric characters,
 * and returns a formatted phone number as a string.
 *
 * @function
 * @param {string} phone - The phone number to format.
 * @returns {string|null} A formatted phone number, or null if the input is invalid.
 */
export const getFormattedCustomerSupportPhoneNumber = (phone) => {
  const s2 = `${phone}`.replace(/\D/g, ``)
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/)
  return !m ? null : `(${m[1]}) ${m[2]}-${m[3]}`
}

/**
 * If the string is not empty and has a length of 10, return the string
 * with dashes inserted at the 3rd and 6th characters.
 *
 * @function
 * @param {string} s - The phone number to format.
 * @returns {string} A formatted phone number with dashes in the appropriate places, or the original string if it's not a valid phone number.
 */
export const formatPhoneNumberWithDashes = (s) => {
  if (!!s && s.length === 10) {
    return `${s.slice(0, 3)}-${s.slice(3, 6)}-${s.slice(6)}`
  }
  return s
}
