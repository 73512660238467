import { lazy, Suspense } from "react"

const Footer = lazy(() => import("component-lib/Footer/FooterContainer"))

const FooterApp = (props) => (
  <div data-testid="FooterApp">
    <Suspense fallback={null}>
      <Footer {...props} />
    </Suspense>
  </div>
)

export default FooterApp
