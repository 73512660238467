/**
 * A function that takes in a language code and returns a language route.
 *
 * @param {string} languageCode - The language code to get the language route for.
 * @returns {string} - The language route for the given language code.
 */
export default (languageCode) => {
  let languageRoute = ``
  if (languageCode === `en_US`) {
    languageRoute = `/us/en`
  } else if (languageCode === `en_CA`) {
    languageRoute = `/ca/en`
  } else if (languageCode === `fr_CA`) {
    languageRoute = `/ca/fr`
  } else if (languageCode === `es_US`) {
    languageRoute = `/us/es`
  }
  return languageRoute
}
