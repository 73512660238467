import { isValidInvoiceNumber } from "./isValidInvoiceNumber"

/**
 * Determines whether a specified invoice number is valid or an empty string.
 *
 * @function
 * @param {string} num - The invoice number to validate.
 * @returns {boolean} A boolean value indicating whether the specified invoice number is valid or an empty string.
 */
export const isValidInvoiceFormFieldInput = (num) =>
  num === `` || isValidInvoiceNumber(num)
