import { createSelector } from "reselect"

/* Selects the customer's formatted address from redux. */
export const addressSelector = (state) =>
  typeof state.customerSelections === `object` &&
  typeof state.customerSelections.customerAddress === `object` &&
  typeof state.customerSelections.customerAddress.formattedAddress === `string`
    ? state.customerSelections.customerAddress.formattedAddress
    : ``

/* Selector returning true if the customer has a saved address, false otherwise. */
export const hasAddress = createSelector(
  addressSelector,
  (address) => address !== ``,
)
