import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { generateUniqueID } from "utils-lib/requests"
import { urls } from "utils-lib/builds"
import {
  GET_CART_DETAILS_PENDING,
  GET_CART_DETAILS_FAILED,
  GET_CART_DETAILS_FULFILLED,
  RESET_CART_DETAILS,
  RESET_CART_STATUS,
} from "constants-lib/actionTypes"

export const pendingAC = () => ({
  type: GET_CART_DETAILS_PENDING,
})

export const failedAC = (data) => ({
  type: GET_CART_DETAILS_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: GET_CART_DETAILS_FULFILLED,
  payload: data,
})

export const resetCartDetails = () => ({
  type: RESET_CART_DETAILS,
})

export const resetCartStatus = () => ({
  type: RESET_CART_STATUS,
})

/**
 * @returns The return value is the URL of the cart.
 */
export const getCartDetails = () => (dispatch, getState) => {
  const {
    customerSelections,
    siteLanguage,
    ecommerceCart,
    onlineProducts,
    userAccount,
  } = getState()
  const { onlineEcommercePricing = {} } = onlineProducts
  const { products = [] } = onlineEcommercePricing
  const cartId = encodeURI(ecommerceCart.id || ``)
  const state = encodeURI(
    customerSelections.customerSearchedAddress.state || ``,
  )
  const street =
    `${customerSelections.customerSearchedAddress.streetNumber} ${customerSelections.customerSearchedAddress.streetName}` ||
    ``
  const city = encodeURI(
    customerSelections.customerSearchedAddress.city ||
      localStorage.getItem("cityName"),
  )
  const country = encodeURI(
    customerSelections.customerSearchedAddress.country || ``,
  )
  const zipcode =
    customerSelections.customerSearchedAddress.postalCode ||
    localStorage.getItem("zipCode")
  const locale = encodeURI(siteLanguage.language || ``)
  const userId = encodeURI(userAccount.userDetails.userId || ``)
  const categoryId = encodeURI(
    !isEmpty(products[0])
      ? products[0]?.category_id || ``
      : get(customerSelections, `selectedCatalogs[0].id`, ``),
  )

  if (!categoryId) return

  const api = userId ? `FETCH_CART_LOGGED_IN` : `FETCH_CART`
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    cartId,
    state,
    street,
    city,
    country,
    categoryId,
    userId,
    zipcode,
    locale,
  })

  const config = {
    url,
    method: `get`,
    headers: {
      "Content-Type": `application/json`,
      ClientId: urls.cloudCraze.headers.ClientId,
      "Request-Tracking-Id": generateUniqueID(),
    },
  }
  dispatch(pendingAC())
  // eslint-disable-next-line consistent-return
  return axios(config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      return response.data.cart.url
    })
    .catch((response) => {
      dispatch(failedAC(response.data))

      return null
      // return response
    })
}
