export const CLEAR_SELECTED_FACILITY_ID = `CLEAR_SELECTED_FACILITY_ID`
export const SELECT_FACILITY = `SELECT_FACILITY`
export const SET_CUSTOMER_ACTION = `SET_CUSTOMER_ACTION`
export const SET_CUSTOMER_ADDRESS = `SET_CUSTOMER_ADDRESS`
export const SET_HAS_GEOSUGGESTION = `SET_HAS_GEOSUGGESTION`
export const SET_SEARCH_TEXT = `SET_SEARCH_TEXT`
export const CLEAR_HOVERED_MAP_MARKER_ID = `CLEAR_HOVERED_MAP_MARKER_ID`
export const SET_HOVERED_MAP_MARKER_ID = `SET_HOVERED_MAP_MARKER_ID`
export const UPDATE_GEOSUGGEST_LOCATION = `UPDATE_GEOSUGGEST_LOCATION`
export const TRANSLATE_FACILITY = `TRANSLATE_FACILITY`
export const UPDATE_ACCOUNT_SETTINGS = `UPDATE_ACCOUNT_SETTINGS`

export const GET_LOCATIONS_PENDING = `GET_LOCATIONS_PENDING`
export const GET_LOCATIONS_FULFILLED = `GET_LOCATIONS_FULFILLED`
export const GET_LOCATIONS_REJECTED = `GET_LOCATIONS_REJECTED`

export const GET_FACILITY_ALERTS_PENDING = `GET_FACILITY_ALERTS_PENDING`
export const GET_FACILITY_ALERTS_FULFILLED = `GET_FACILITY_ALERTS_FULFILLED`
export const GET_FACILITY_ALERTS_FAILED = `GET_FACILITY_ALERTS_FAILED`
