import PropTypes from "prop-types"

export const H5 = ({ children, className = "", ...rest }) => (
  <div
    data-testid="H5"
    className={`H5 maax-font-weight-bold ${className}`}
    style={{
      fontSize: `18px`,
      lineHeight: `1.28`,
      color: `#67696d`,
    }}
    {...rest}
  >
    {children}
  </div>
)

H5.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

H5.defaultProps = {
  children: null,
  className: "",
}
