import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.FETCH_AUTOPAYMENT_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.FETCH_AUTOPAYMENT_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.FETCH_AUTOPAYMENT_FULFILLED,
  payload: data,
})

export const fetchAutoPayment = () => (dispatch, getState) => {
  const state = getState()
  const { customerAutoPayPaperless } = state
  const api = `AUTO_PAYMENT`
  dispatch(pendingAC())

  const template = URITemplate(urls.url[api])
  const customerId = _.get(
    customerAutoPayPaperless,
    `autoPaymentAndPaperlessAccount`,
    ``,
  )
  const url = template.expand({ customerId })

  return getTokenAction(dispatch, getState)
    .then((token) => {
      const state = getState()
      const config = {
        headers: {
          oktaToken: token.accessToken,
        },
        params: {
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
          lang: state.siteLanguage.language,
        },
      }

      const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      if (apiKey !== ``) {
        config.headers.apiKey = apiKey
      }

      return axios
        .create(config)
        .get(url)
        .then((response) => {
          // const data = _.get(response, `data.data`, {})
          const autopayDetailsByAccount = {}
          autopayDetailsByAccount[customerId] = _.get(response, `data.data`, {})
          dispatch(fulfilledAC(autopayDetailsByAccount))
          return response
        })
    })
    .catch((response) => {
      dispatch(
        failedAC({
          statusCode: _.get(response, `response.status`, ``),
          errorMessage: _.get(response, `response.data.Error`, ``),
        }),
      )
    })
}

export const manageAutopaymentDetailsMethod = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.MANAGE_AUTOPAY_DETAILS_BY_ACCOUNT,
    payload: data,
  })
}

export const manageAutopaymentDetailsByAccount = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_MANAGE_AUTOPAY_DETAILS_OF_ACCOUNT,
    payload: data,
  })
}
