import { connect } from "react-redux"
import { getEmailCleanse } from "actions-lib/getEmailCleanse"
import { asyncComponent } from "hoc-lib/asyncComponent"

const EmailInput = asyncComponent(() => import(`./AEMEmailInput`))

export const mapStateToProps = (state) => ({
  userAuthenticationRequestStatuses: state.userAuthenticationRequestStatuses,
})

export const mapDispatchToProps = {
  getEmailCleanse,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailInput)
