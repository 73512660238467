import { localStorageKey } from "constants-lib/localStorage"
import { SET_USER_STATE } from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

/**
 * Stores user state data in localStorage and returns a Redux action to update the user state.
 * @param {Object} data - The user state data to be stored. Must be a serializable object.
 * @returns {Object} An action object with `type` SET_USER_STATE and `payload` containing the user state data.
 */
export const setUserState = (data) => {
  window.localStorage.setItem(localStorageKey.USER_STATE, data)
  return {
    type: SET_USER_STATE,
    payload: data,
  }
}
