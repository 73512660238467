/* eslint-disable @typescript-eslint/ban-types */
import { FC } from "react"
import { makeStyles } from "tss-react/mui"
import { Grid } from "@mui/material"

const useStyles = makeStyles()((theme) => ({
  root: {
    display: `flex`,
  },
  leftColumn: {
    marginBottom: `1rem`,
    // @ts-ignore
    [theme.breakpoints.up(`lg`)]: {
      marginBottom: 0,
    },
  },
  rightColumn: {},
}))

interface ThreeNineLayoutProps {
  renderLeftComponent: Function
  renderRightComponent: Function
}

const ThreeNineLayout: FC<ThreeNineLayoutProps> = ({
  renderLeftComponent,
  renderRightComponent,
}) => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} lg={3} className={classes.leftColumn}>
          {renderLeftComponent()}
        </Grid>
        <Grid item xs={12} lg={9}>
          {renderRightComponent()}
        </Grid>
      </Grid>
    </div>
  )
}

export default ThreeNineLayout
