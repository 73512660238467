import * as ActionTypes from "constants-lib/actionTypes"

// Must be a pure function that requires the state
// and the action being dispatched.
const initialState = {
  components: {
    "MyServicesAddressBar.jsx": {
      hasShownOverlay: false,
    },
    "SubHeaderCustomerIDSelectorWidget.jsx": {
      hasShownOverlay: false,
    },
    "SubHeaderLocationSelectorWidget.jsx": {
      hasShownOverlay: false,
    },
    "SubHeaderServiceSelectorWidget.jsx": {
      hasShownOverlay: false,
    },
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    // NEVER mutate the state, always return a new state.
    case ActionTypes.UPDATE_ACCOUNT_SETTINGS: {
      const newState = {
        ...state,
      }
      newState.components[action.payload.componentName][
        action.payload.functionality
      ] = action.payload.value
      return newState
    }

    default:
      return state
  }
}
