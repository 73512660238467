import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { urls } from "utils-lib/builds"

// Action creators
export const pendingAC = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_VERIFICATION_PENDING,
  payload: data,
})

export const failedAC = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_VERIFICATION_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_VERIFICATION_FULFILLED,
  payload: data,
})

export const getTextToPayDetails =
  ({ id }) =>
  (dispatch) => {
    const api = `TEXT_FOR_BILL_PAY`
    const template = URITemplate(urls.url[api])

    const url = template.expand({
      id,
    })

    dispatch(pendingAC({ id, isSMSFlow: true }))

    return axios
      .create({
        headers: {
          apiKey: _.get(urls, `apiKey.GUEST[${api}]`, ``),
        },
      })
      .get(url)
      .then((response) => {
        const statusCode = _.get(response, `status`, ``)
        const verificationToken = _.get(response, `headers.token`, ``)

        // Normalizing the data so variable names are consistent with existing flows
        const normalizedData = {
          ...response.data.data,
          customerId: response.data.data.accountNumber,
          postalcode: response.data.data.zipCode,
        }

        dispatch(
          fulfilledAC({
            verificationData: normalizedData,
            verificationDetails: normalizedData,
            verificationToken,
            statusCode,
          }),
        )

        return normalizedData
      })
      .catch((error) => {
        const statusCode = _.get(error, `response.status`, 204) // default to 204 status, the API returns 204 but this causes errors in the then above
        const errorMessage = _.get(error, `response.data.data.Error`, ``)
        const verificationDetails = _.get(error, `response.data`)
        dispatch(
          failedAC({
            statusCode,
            errorMessage,
            verificationDetails,
          }),
        )

        return { errorCode: statusCode }
      })
  }
