/* eslint-disable max-len */
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export default ({ origin, apiKeys, okta }) => ({
  /* Assign keys to backend services. */
  apiKey: {
    GUEST: {
      // Chatbot
      LIVE_CHAT: apiKeys.GUEST.LIVE_CHAT,
      // Payment
      GENERATE_CYBS_SIGNATURE: apiKeys.GUEST.PAYMENT,
      PROCESS_SALE_REQUEST: apiKeys.GUEST.PAYMENT,
      SCHEDULE_PAYMENT: apiKeys.GUEST.PAYMENT,
      PAYMENTUS_SIGNATURE: apiKeys.GUEST.PAYMENT,
      PROCESSING_FEE: apiKeys.GUEST.PROCESSING_FEE,
      // Rolloff
      CREATE_ROLLOFF_REQUEST: apiKeys.GUEST.ROLLOFF,
      UPDATE_ROLLOFF_REQUEST: apiKeys.GUEST.ROLLOFF,
      CANCEL_ROLLOFF_REQUEST: apiKeys.GUEST.ROLLOFF,
      ROLLOFF_REQUEST: apiKeys.GUEST.ROLLOFF,
      ROLLOFF_REQUESTS: apiKeys.GUEST.ROLLOFF,
      ROLLOFF_DATES: apiKeys.GUEST.ROLLOFF,
      SE_CAPACITY_PLANNER: apiKeys.GUEST.ROLLOFF,
      // My Services
      CUSTOMER_SERVICES: apiKeys.GUEST.CUSTOMER_SERVICES,
      CUSTOMER_SEARCH: apiKeys.GUEST.CUSTOMER_SERVICES,
      CUSTOMER_SEARCH_GUEST: apiKeys.GUEST.CUSTOMER_SERVICES,
      CUSTOMER_VERIFICATION: apiKeys.GUEST.CUSTOMER_SERVICES,
      PICKUP_INFO: apiKeys.GUEST.CUSTOMER_SERVICES,
      // Balance
      BALANCE: apiKeys.GUEST.BALANCE,
      // Support/Service request
      USER_REQUEST_SERVICE: apiKeys.GUEST.USER_REQUEST_SERVICE,
      ALERTS: apiKeys.GUEST.ALERTS,
      ADS_ACCOUNTS: apiKeys.GUEST.ADS_ACCOUNTS,
      APPLE_PAY: apiKeys.GUEST.APPLE_PAY,
      APPLE_PAY_SCHEDULED: apiKeys.GUEST.APPLE_PAY_SCHEDULED,
      APPLE_PAY_PROCESS: apiKeys.GUEST.APPLE_PAY_PROCESS,
      // AWS Translate API key
      AWS_TRANSLATE: apiKeys.GUEST.AWS_TRANSLATE,
      ALGOLIA_API_KEY: apiKeys.GUEST.ALGOLIA_API_KEY,
      ALGOLIA_APP_ID: apiKeys.GUEST.ALGOLIA_APP_ID,
      // Bulk
      BULK_PICKUP_DATES: apiKeys.GUEST.BULK_PICKUP_DATES,
      SUBMIT_BULK_PICKUP: apiKeys.GUEST.SUBMIT_BULK_PICKUP,
      GUEST_BULK_PICKUP_GUIDELINES: apiKeys.GUEST.GUEST_BULK_PICKUP_GUIDELINES,
      GUEST_BULK_PICKUP_DATES: apiKeys.GUEST.GUEST_BULK_PICKUP_DATES,
      SUBMIT_GUEST_BULK_PICKUP: apiKeys.GUEST.SUBMIT_GUEST_BULK_PICKUP,
      GUEST_BULK_PICKUP_PRICING: apiKeys.GUEST.GUEST_BULK_PICKUP_PRICING,
      // New Holiday Schedule
      HOLIDAYS_GUEST_BY_CUSTOMER_ID:
        apiKeys.GUEST.HOLIDAYS_GUEST_BY_CUSTOMER_ID,
      HOLIDAYS_GUEST_BY_ADDRESS: apiKeys.GUEST.HOLIDAYS_GUEST_BY_ADDRESS,
      // New Contact Info
      CONTACT_INFO_GUEST_BY_CUSTOMER_ID:
        apiKeys.GUEST.CONTACT_INFO_GUEST_BY_CUSTOMER_ID,
      CONTACT_INFO_GUEST_BY_ADDRESS:
        apiKeys.GUEST.CONTACT_INFO_GUEST_BY_ADDRESS,
      COMMERCIAL_CHAT: apiKeys.GUEST.COMMERCIAL_CHAT,
      GET_CITY_BILLED: apiKeys.GUEST.GET_CITY_BILLED,
      TEXT_FOR_CITY_BILLED_BULK: apiKeys.GUEST.TEXT_FOR_CITY_BILLED_BULK,
      PAD_POST: apiKeys.GUEST.PAYMENT,
      USER_DETAILS: apiKeys.GUEST.USER_DETAILS,
      CREATE_USER: apiKeys.GUEST.CREATE_USER,
      ENROLL_AUTOPAY_PAPERLESS_GUEST:
        apiKeys.GUEST.ENROLL_AUTOPAY_PAPERLESS_GUEST,
      TEXT_FOR_BILL_PAY: apiKeys.GUEST.TEXT_FOR_BILL_PAY,
      TEXT_FOR_VIEW_ETA: apiKeys.GUEST.TEXT_FOR_VIEW_ETA,
      REPORT_MISSED_PICKUP_GUEST: apiKeys.GUEST.REPORT_MISSED_PICKUP_GUEST,
      // Guest Container issue
      GUEST_CONTAINER_REPAIR_DATES: apiKeys.GUEST.GUEST_CONTAINER_REPAIR,
      GUEST_CONTAINER_REPAIR_ELIGIBLE: apiKeys.GUEST.GUEST_CONTAINER_REPAIR,
      GUEST_CONTAINER_REPAIR_SERVICES: apiKeys.GUEST.GUEST_CONTAINER_REPAIR,
      GUEST_CONTAINER_REPAIR_PRICING: apiKeys.GUEST.GUEST_CONTAINER_REPAIR,
      GUEST_CONTAINER_REPAIR_CREATE_TICKET:
        apiKeys.GUEST.GUEST_CONTAINER_REPAIR,
      ENERGY_SURCHARGE_CALCULATOR: apiKeys.GUEST.ENERGY_SURCHARGE_CALCULATOR,
    },
    USER: {
      PAYMENTUS_SIGNATURE: apiKeys.USER.PAYMENT,
      PROCESSING_FEE: apiKeys.USER.PROCESSING_FEE,
      // Payment
      GENERATE_CYBS_SIGNATURE: apiKeys.USER.PAYMENT,
      PROCESS_SALE_REQUEST: apiKeys.USER.PAYMENT,
      SCHEDULE_PAYMENT: apiKeys.USER.PAYMENT,
      AUTO_PAYMENT: apiKeys.USER.BALANCE_INVOICE,
      PAPERLESS_BILLING: apiKeys.USER.BALANCE_INVOICE,
      AUTOPAY_PAPERLESS_ATTRIBUTES: apiKeys.USER.BALANCE_INVOICE,
      BULK_AUTOPAY_PAPERLESS_ATTRIBUTES: apiKeys.USER.BALANCE_INVOICE,
      PROCESS_MULTI_SALE_REQUEST: apiKeys.USER.PAYMENT,
      PAYMENT_HISTORY: apiKeys.USER.PAYMENT,
      PAYMENT_METHODS: apiKeys.USER.PAYMENT,
      PAYMENT_METHOD: apiKeys.USER.PAYMENT,
      SAVE_PAYMENT_METHOD: apiKeys.USER.PAYMENT,
      // Broker Account (50+ linked accounts)
      BROKER_ACCOUNT_BALANCE: apiKeys.USER.AUTHENTICATION,
      // Account
      USER_ACCOUNTS: apiKeys.USER.USER_ACCOUNTS,
      // Rolloff
      CREATE_ROLLOFF_REQUEST: apiKeys.USER.ROLLOFF,
      UPDATE_ROLLOFF_REQUEST: apiKeys.USER.ROLLOFF,
      CANCEL_ROLLOFF_REQUEST: apiKeys.USER.ROLLOFF,
      ROLLOFF_REQUEST: apiKeys.USER.ROLLOFF,
      ROLLOFF_REQUESTS: apiKeys.USER.ROLLOFF,
      ROLLOFF_DATES: apiKeys.USER.ROLLOFF,
      // My Services
      CUSTOMER_SERVICES: apiKeys.USER.CUSTOMER_SERVICES,
      CUSTOMER_SEARCH: apiKeys.USER.CUSTOMER_SERVICES,
      BULK_CUSTOMER_SEARCH: apiKeys.USER.USER_ACCOUNTS,
      CUSTOMER_SEARCH_GUEST: apiKeys.USER.CUSTOMER_SERVICES,
      CUSTOMER_VERIFICATION: apiKeys.USER.CUSTOMER_SERVICES,
      PICKUP_INFO: apiKeys.USER.CUSTOMER_SERVICES,
      REDESIGNED_DASHBOARD_PICKUP_INFO:
        apiKeys.USER.REDESIGNED_DASHBOARD_PICKUP_INFO,
      REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY:
        apiKeys.USER.REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY,
      REDESIGNED_DASHBOARD_SERVICES_DETAILS:
        apiKeys.USER.REDESIGNED_DASHBOARD_SERVICES_DETAILS,
      // Container Not Out
      CONTAINER_NOT_OUT_ELIGIBILITY: apiKeys.USER.CAN_NOT_OUT,
      CONTAINER_NOT_OUT_SUBMISSION: apiKeys.USER.CAN_NOT_OUT,
      // Invoice,
      INVOICE: apiKeys.USER.BALANCE_INVOICE,
      BULK_INVOICE: apiKeys.USER.BALANCE_INVOICE,
      // Authentication
      AUTHENTICATE_USER: apiKeys.USER.AUTHENTICATION,
      CREATE_USER: apiKeys.USER.AUTHENTICATION,
      RESEND_EMAIL: apiKeys.USER.AUTHENTICATION,
      ACTIVATE_NEW_EMAIL: apiKeys.USER.AUTHENTICATION,
      ACTIVATE_USER: apiKeys.USER.AUTHENTICATION,
      FORGOT_PASSWORD: apiKeys.USER.AUTHENTICATION,
      RESET_PASSWORD: apiKeys.USER.AUTHENTICATION,
      COMPLETE_REGISTRATION: apiKeys.USER.AUTHENTICATION,
      USER_DETAILS: apiKeys.USER.AUTHENTICATION,
      USER_PROFILE: apiKeys.USER.AUTHENTICATION,
      REVOKE_TOKEN: apiKeys.USER.AUTHENTICATION,
      // Support/Service request
      USER_REQUEST_SERVICE: apiKeys.USER.USER_REQUEST_SERVICE, // TODO: Not used anywhere currently,
      CHAT_LOGGED_IN: apiKeys.USER.CHAT_LOGGED_IN,
      CHAT_LOGGED_IN_MOBILE: apiKeys.USER.CHAT_LOGGED_IN_MOBILE,
      ALERTS: apiKeys.USER.ALERTS,
      COMMUNICATION_PREFERENCES: apiKeys.USER.BALANCE_INVOICE,
      INCIDENTS_BY_USER_ID: apiKeys.USER.INCIDENTS_BY_USER_ID,
      APPLE_PAY: apiKeys.USER.APPLE_PAY,
      APPLE_PAY_SCHEDULED: apiKeys.USER.APPLE_PAY_SCHEDULED,
      APPLE_PAY_AUTOPAY: apiKeys.USER.APPLE_PAY_AUTOPAY,
      APPLE_PAY_PROCESS: apiKeys.USER.APPLE_PAY_PROCESS,
      // New Holiday Schedule
      HOLIDAYS_USER_BY_CUSTOMER_ID: apiKeys.USER.HOLIDAYS_USER_BY_CUSTOMER_ID,
      HOLIDAYS_USER_BY_ADDRESS: apiKeys.USER.HOLIDAYS_USER_BY_ADDRESS,
      // New Contact Info
      CONTACT_INFO_USER_BY_CUSTOMER_ID:
        apiKeys.USER.CONTACT_INFO_USER_BY_CUSTOMER_ID,
      CONTACT_INFO_USER_BY_ADDRESS: apiKeys.USER.CONTACT_INFO_USER_BY_ADDRESS,
      BULK_AUTO_PAYMENT: apiKeys.USER.BULK_AUTOPAY_PAPERLESS_ENROLL,
      BULK_PAPERLESS_BILLING: apiKeys.USER.BULK_AUTOPAY_PAPERLESS_ENROLL,
      PAD_POST: apiKeys.USER.PAYMENT,
      // Paperless Preferences
      PAPERLESS_PREFERENCES: apiKeys.USER.PAPERLESS_PREFERENCES,
      // Broker Account (50+ linked accounts)
      BROKER_ACCOUNT_BALANCE_CONTACT: apiKeys.USER.AUTHENTICATION,
      BROKER_ACCOUNT_SALE_REQUEST: apiKeys.USER.AUTHENTICATION,
      // Account
      USER_ACCOUNTS_MULTI_LINK: apiKeys.USER.USER_ACCOUNTS,
      CONTACT_UPDATE: apiKeys.USER.USER_ACCOUNTS,
      // Repair
      REPAIR_CONTAINER_DATES: apiKeys.USER.REPAIR,
      REQUEST_REPAIR: apiKeys.USER.REPAIR,
      CANCEL_REPAIR: apiKeys.USER.REPAIR,
      REPAIR_PRICING: apiKeys.USER.REPAIR,
      ROLLOFF_PRICING: apiKeys.USER.ROLLOFF,
      REPAIR_ELIGIBILITY: apiKeys.USER.REPAIR,
      // My Services
      AUTOPAY_CONSENT: apiKeys.USER.CUSTOMER_SERVICES,
      TICKET_PICKUP_INFO: apiKeys.USER.TICKET_PICKUP_INFO,
      RECYCLING_PICKUP: apiKeys.USER.RECYCLING_PICKUP,
      RECYCLING_PICKUP_DATE: apiKeys.USER.RECYCLING_PICKUP,
      RECYCLING_PICKUP_SUBMIT: apiKeys.USER.RECYCLING_PICKUP,
      CANCEL_RECYCLING_PICKUP: apiKeys.USER.RECYCLING_PICKUP,
      // Support/Service request
      INCIDENTS_BY_CUSTOMER_ID: apiKeys.USER.INCIDENTS_BY_CUSTOMER_ID,
      // Service Change
      SERVICE_CHANGE_ELIGIBILITY: apiKeys.USER.SERVICE_CHANGE,
      SERVICE_CHANGE_RECOMMENDATIONS: apiKeys.USER.SERVICE_CHANGE,
      SERVICE_CHANGE_PRICE: apiKeys.USER.SERVICE_CHANGE,
      SERVICE_CHANGE_SUBMIT: apiKeys.USER.SERVICE_CHANGE,
      SERVICE_CHANGE_HISTORY: apiKeys.USER.SERVICE_CHANGE,
      SERVICE_CHANGE_CANCEL: apiKeys.USER.SERVICE_CHANGE,
      SERVICE_CHANGE_DATES: apiKeys.USER.SERVICE_CHANGE,
      // Extra Pickup
      EXTRA_PICKUP_ELIGIBILITY: apiKeys.USER.EXTRA_PICKUP,
      EXTRA_PICKUP_DATES: apiKeys.USER.EXTRA_PICKUP,
      EXTRA_PICKUP_PRICING: apiKeys.USER.EXTRA_PICKUP,
      EXTRA_PICKUP_CREATE: apiKeys.USER.EXTRA_PICKUP,
      EXTRA_PICKUP_CANCEL: apiKeys.USER.EXTRA_PICKUP,
      GET_EXTRA_PICKUP_DETAILS: apiKeys.USER.EXTRA_PICKUP,
      UPDATE_EXTRA_PICKUP: apiKeys.USER.EXTRA_PICKUP,
      // EXTRA_PICKUP_UPDATE: apiKeys.USER.EXTRA_PICKUP,
      // EXTRA_PICKUP_CANCEL: apiKeys.USER.EXTRA_PICKUP,
      EXTRA_PICKUP_HISTORY: apiKeys.USER.EXTRA_PICKUP,
      // Bulk Pickup
      BULK_PICKUP_ELIGIBILITY: apiKeys.USER.BULK_PICKUP,
      BULK_PICKUP_DATES: apiKeys.USER.BULK_PICKUP,
      BULK_PICKUP_DATES_ORIG: apiKeys.USER.BULK_PICKUP,
      BULK_PICKUP_GUIDELINES: apiKeys.USER.BULK_PICKUP,
      BULK_PICKUP_PRICING: apiKeys.USER.BULK_PICKUP,
      BULK_PICKUP_CREATE: apiKeys.USER.BULK_PICKUP,
      BULK_PICKUP_CANCEL: apiKeys.USER.BULK_PICKUP,
      // Report Missed Pickup
      REPORT_MISSED_PICKUP: apiKeys.USER.REPORT_MISSED_PICKUP,
      REPORT_MISSED_PICKUP_CANCEL: apiKeys.USER.REPORT_MISSED_PICKUP,
      // Order History
      COMBINED_ORDER_HISTORY: apiKeys.USER.COMBINED_ORDER_HISTORY,
      COMBINED_ORDER_HISTORY_BY_CUSTOMER: apiKeys.USER.COMBINED_ORDER_HISTORY,
      // SERVICE HISTORY, SERVICE VISUALS
      SUBMIT_PAUSE_SERVICE: apiKeys.USER.SERVICE_CHANGE,
      CANCEL_PAUSE_SERVICE: apiKeys.USER.PAUSE_SERVICE,
      HISTORY_VISUALS: apiKeys.USER.HISTORY_VISUALS,
      // PENDING CHARGES
      PENDING_CHARGES: apiKeys.USER.PENDING_CHARGES,
      // SURVEY QUESTIONS
      START_SURVEY: apiKeys.USER.SURVEY,
      PROCESS_SURVEY: apiKeys.USER.SURVEY,
      START_SUPPORT_SURVEY: apiKeys.USER.SUPPORT_SURVEY,
      PROCESS_SUPPORT_SURVEY: apiKeys.USER.SUPPORT_SURVEY,
      USER_BILLING_DETAILS: apiKeys.USER.USER_BILLING_DETAILS,
      TEXT_FOR_BULK: apiKeys.USER.TEXT_FOR_BULK,
      TEXT_FOR_ROLLOFF: apiKeys.USER.TEXT_FOR_ROLLOFF,
      TEXT_FOR_XPU: apiKeys.USER.TEXT_FOR_XPU,
      TEXT_FOR_CONTAINER_REPAIR: apiKeys.USER.TEXT_FOR_CONTAINER_REPAIR,
    },
    ESB: {
      REFRESH_TOKEN: apiKeys.ESB.REFRESH_TOKEN,
      ORDER_SUMMARY: apiKeys.ESB.ORDERS,
      ORDER_DETAILS: apiKeys.ESB.ORDERS,
      GET_OUTAGES: apiKeys.ESB.GET_OUTAGES,
    },
  },
  okta: {
    redirectUri: window.location.origin,
    tokenFieldKey: `oktaToken`,
    ...okta,
  },
  loginRedirect: `${window.location.origin}__LANGROUTE__`,
  // TODO check if redirectUrls are still being used.
  redirectUrls: {
    home: `${window.location.origin}`,
    register: `${window.location.origin}/__LANGROUTE__/user/register`,
    login: `${window.location.origin}/__LANGROUTE__/user/login`,
    customerSupport: `${window.location.origin}/__LANGROUTE__/support`,
  },
  url: {
    LIVE_CHAT: `${origin.EBIZ}/contactPref/Chat`,
    GET_GENESYS_MESSAGES: `${origin.GENESYS}/chats/{chatId}/messages`,
    PROCESSING_FEE: `${origin.EBIZ}/payment/getProcessingFee`,
    // Payment
    PAYMENTUS_SIGNATURE: `${origin.EBIZ}/payment/generateAuthorization`,
    GENERATE_CYBS_SIGNATURE: `${origin.EBIZ}/payment/generateCybsSignature`,
    PROCESS_SALE_REQUEST: `${origin.EBIZ}/payment/processSaleRequest`,
    SCHEDULE_PAYMENT: `${origin.EBIZ}/payment/account/{customerId}/schedulepayment`,
    AUTO_PAYMENT: `${origin.EBIZ}/account/{customerId}/autopay`,
    PROCESS_MULTI_SALE_REQUEST: `${origin.EBIZ}/payment/processSaleMultiRequest`,
    PAYMENT_HISTORY: `${origin.EBIZ}/payment/account/{customerId}/paymentHistory`,
    PAYMENT_METHODS: `${origin.EBIZ}/payment/paymethod`,
    PAYMENT_METHOD: `${origin.EBIZ}/payment/paymethod/{payMethodId}`,
    SAVE_PAYMENT_METHOD: `${origin.EBIZ}/payment/paymethod/{payMethodId}/savePaymentMethod`,
    // Apple Pay
    APPLE_PAY_GUEST: `${origin.EBIZ}/digitalPayment/applePaySession?validationURL={validationURL}&ezpayId={ezpayId}`,
    APPLE_PAY_LOGGED_IN: `${origin.EBIZ}/digitalPayment/applePaySession?validationURL={validationURL}&ezpayId={ezpayId}&userId={userId}`,
    APPLE_PAY_AUTOPAY: `${origin.EBIZ}/digitalPayment/account/{ezpayId}/autopay?userId={userId}&lang={lang}`,
    APPLE_PAY_PROCESS: `${origin.EBIZ}/digitalPayment/processApplePayPayment?userId={userId}&lang={lang}`,
    APPLE_PAY_SCHEDULE: `${origin.EBIZ}/digitalPayment/account/{ezpayId}/schedulepayment?userId={userId}&lang={lang}`,
    CREATE_USER_AUTOPAY_PAPERLESS: `${origin.EBIZ}/user/guest/createUser?lang={lang}`,
    SEND_VERIFICATION_CODE: `${origin.EBIZ}/user/sendVerificationCode?lang={lang}&customerId={customerId}`,
    VALIDATE_VERIFICATION_CODE: `${origin.EBIZ}/user/validateVerificationCode`,
    ENROLL_AUTOPAY_PAPERLESS_GUEST: `${origin.EBIZ}/account/{customerId}/enrollAutopayPaperless?lang={lang}&userId={userId}`,
    // Account
    USER_ACCOUNTS: `${origin.EBIZ}/authorize/user/{userId}/accounts`,
    // Alert
    STATE_ALERT: `${origin.EBIZ}/serviceAlerts?stateCode={state}`,
    // Rolloff
    CREATE_ROLLOFF_REQUEST: `${origin.EBIZ}/account/{customerId}/services/rolloff?lang={locale}&mode={mode}`,
    UPDATE_ROLLOFF_REQUEST: `${origin.EBIZ}/account/{customerId}/services/rolloff/{rollOffRequestId}?lang={lang}`,
    CANCEL_ROLLOFF_REQUEST: `${origin.EBIZ}/account/{customerId}/services/rolloff/{rollOffRequestId}?lang={language}`,
    ROLLOFF_REQUEST: `${origin.EBIZ}/account/{customerId}/services/rolloff/{rollOffRequestId}?token={token}`,
    ROLLOFF_REQUESTS: `${origin.EBIZ}/account/{customerId}/services/rolloff/requests?serviceId={serviceId}&lang={locale}`,
    ROLLOFF_DATES: `${origin.EBIZ}/account/{customerId}/services/rolloff/dates/fromDate/{fromDate}/toDate/{toDate}/?mode={mode}&lang={lang}&serviceId={serviceId}`,
    // Balance and invoice
    BALANCE: `${origin.EBIZ}/account/{customerId}/balance`,
    INVOICE: `${origin.EBIZ}/account/{customerId}/invoice`,
    BULK_INVOICE: `${origin.EBIZ}/account/invoices?lang={lang}&fromDate={fromDate}&toDate={toDate}&userId={userId}`,
    // Broker account (50+ linked accounts scenario)
    BROKER_ACCOUNT_BALANCE: `${origin.EBIZ}/user/{userId}/balance`,
    // My Services
    CUSTOMER_SERVICES: `${origin.EBIZ}/account/{customerId}/services`,
    // OLD PICKUP INFO
    PICKUP_INFO: `${origin.EBIZ}/account/{ezpayId}/service/{serviceId}/pickupinfo?lang={locale}&checkAlerts=Y`,
    CUSTOMER_SEARCH: `${origin.EBIZ}/account/search?ezpayId={ezpayId}&lang={lang}{&userId}`,
    BULK_CUSTOMER_SEARCH: `${origin.EBIZ}/authorize/user/{userId}/search?lang={lang}`,
    CUSTOMER_SEARCH_GUEST: `${origin.EBIZ}/account/search`,
    CUSTOMER_VERIFICATION: `${origin.EBIZ}/account/{customerId}/verify`,
    // User Authentication
    AUTHENTICATE_USER: `${origin.EBIZ}/user/authenticate`,
    CREATE_USER: `${origin.EBIZ}/user/createUser`,
    RESEND_EMAIL: `${origin.EBIZ}/user/sendVerificationEmail`,
    SEND_REGISTRATION_EMAIL: `/content/wm/servlets/sendUserRegistrationData?firstName={firstName}&lastName={lastName}&companyName={companyName}&emailAddress={emailAddress}`,
    ACTIVATE_NEW_EMAIL: `${origin.EBIZ}/user/updateLogin`,
    ACTIVATE_USER: `${origin.EBIZ}/user/activate`,
    FORGOT_PASSWORD: `${origin.EBIZ}/user/forgotPassword`,
    RESET_PASSWORD: `${origin.EBIZ}/user/setPassword`,
    COMPLETE_REGISTRATION: `${origin.EBIZ}/user/completeRegistration`,
    CHANGE_PASSWORD: `${origin.EBIZ}/user/{userId}/changePassword`,
    USER_DETAILS: `${origin.EBIZ}/user/userDetails`,
    USER_PROFILE: `${origin.EBIZ}/user/{userId}/updateProfile`,
    REVOKE_TOKEN: `${origin.EBIZ}/user/{userId}/revokeToken`,
    // Support/Service request
    USER_REQUEST_SERVICE: `${origin.EBIZ}/user-request-service`,
    // Paperless billing
    PAPERLESS_BILLING: `${origin.EBIZ}/account/{customerId}/paperless`,
    AUTOPAY_PAPERLESS_ATTRIBUTES: `${origin.EBIZ}/account/{customerId}/attributes`,
    BULK_AUTOPAY_PAPERLESS_ATTRIBUTES: `${origin.EBIZ}/account/attributes?lang={lang}&userId={userId}`,
    // CloudCraze
    REFRESH_TOKEN: `${origin.ESB}/token`,
    GET_PRODUCTS: `${origin.ESB}/products?zipcode={zipcode}&locale={locale}&product_type={product}&street={street}&city={city}&county={county}&state={state}&country={country}&address_indicator={lob}&first_name={fname}&last_name={lname}&user_id={userid}&customer_first_name={customer_first_name}&customer_last_name={customer_last_name}&naics_code={naics_code}&session_token={sessiontoken}&access_token={accessToken}&latitude={latitude}&longitude={longitude}&refresh_token={refreshToken}`,
    GET_COMBINED_PRODUCTS: `${origin.ESB}/get-me-started?zipcode={zipcode}&locale={locale}&street={street}&city={city}&county={county}&address={label}&state={state}&country={country}&address_indicator={lob}&first_name={fname}&last_name={lname}&user_id={userid}&session_token={sessiontoken}&access_token={accessToken}&latitude={latitude}&longitude={longitude}&refresh_token={refreshToken}&naics_code={naics_code}&businessType={businessType}&productType={productType}&google_placeId={google_placeId}`,
    // City billed eligibility
    GET_CITY_BILLED: `${origin.EBIZ}/account/services/bulkPickup/eligibility?street={street}&city={city}&state={state}&country={country}&latitude={latitude}&longitude={longitude}&postalCode={zipcode}&lang={locale}&googlePlaceId={googlePlaceId}&address={label}`,
    ORDER_SUMMARY: `${origin.ESB}/users/{userId}/orders`,
    ORDER_DETAILS: `${origin.ESB}/users/{userId}/orders/{orderId}`,
    // Energy Surcharge Calculator
    ENERGY_SURCHARGE_CALCULATOR: `${origin.EBIZ}/account/getEnergySurcharge?lang={lang}`,
    // Redesigned Dashboard
    REDESIGNED_DASHBOARD_PICKUP_INFO: `${origin.EBIZ}/user/{userId}/services/pickupinfo?lang={lang}&checkAlerts=Y`,
    REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY: `${origin.EBIZ}/user/{userId}/services/eligibility?lang={lang}`,
    REDESIGNED_DASHBOARD_SERVICES_DETAILS: `${origin.EBIZ}/user/{userId}/services/details?lang={lang}`,
    // Container Not Out
    CONTAINER_NOT_OUT_ELIGIBILITY: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/canNotOut/eligibility?lang={lang}&serviceId={serviceId}&cnoEligibilityId={cnoEligibilityId}`,
    CONTAINER_NOT_OUT_SUBMISSION: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/canNotOut?lang={lang}`,
    GUEST_PICKUP_INFO: `${origin.EBIZ}/account/{customerId}/services/{serviceId}/pickupinfo?lang={lang}`,
    // E-Commerce
    ONLINE_PRODUCTS: `${origin.ESB}/catalogs/products?latitude={latitude}&longitude={longitude}&city={city}&state={state}&street={street}&zipcode={zipcode}&country={country}&locale={locale}&product_type={product}&service_type={variant}&number_of_days=0`,

    ECOMMERCE_PRICING: `${origin.ESB}/catalogs/products/pricing?pricing_engine_request_id={shortTermPricingEngineId}&service_type=shortTerm&number_of_days={datesCount}&locale=en_US`,
    AEM_SERVLET_DETAILS: `${window.location.origin}/content/wm/servlets/ecom/productDetails?productIds={productIds}&locale={locale}&country={country}`,
    // AEM_SERVLET_DETAILS: `https://wmqa2.wm.com/content/wm/servlets/ecom/productDetails?productIds={productIds}&locale={locale}&country={country}`, // for local testing
    FETCH_CART: `${origin.ESB}/cart/{cartId}?state={state}&street={street}&city={city}&country={country}&categoryId={categoryId}&zipcode={zipcode}&locale={locale}`,
    FETCH_CART_LOGGED_IN: `${origin.ESB}/users/{userId}/cart?state={state}&street={street}&city={city}&country={country}&categoryId={categoryId}&zipcode={zipcode}&locale={locale}`,
    SET_CART: `${origin.ESB}/cart`,
    SE_CAPACITY_PLANNER: `${origin.EBIZ}/account/services/rolloff/dates/fromDate/{fromDate}/toDate/{toDate}?siteId={siteId}&lob={lob}&mode={mode}&lang={lang}`,
    AEM_ERROR_MESSAGE_SERVLET: `${window.location.origin}/content/wm/servlets/exceptionAddressBar`,
    // AEM_ERROR_MESSAGE_SERVLET: `https://wmqa2.wm.com/content/wm/servlets/exceptionAddressBar`,
    AEM_RESIERROR_MESSAGE_SERVLET: `${window.location.origin}/content/wm/servlets/exceptionAddressBar?locale={locale}&serviceType={serviceType}`,
    AEM_BULKERROR_MESSAGE_SERVLET: `${window.location.origin}/content/wm/servlets/exceptionAddressBar?locale={locale}&serviceType={serviceType}`,
    // AEM_BULKERROR_MESSAGE_SERVLET: `https://wmqa2.wm.com/content/wm/servlets/exceptionAddressBar?local=en_US&serviceType=Bulk`,
    // AEM_RESIERROR_MESSAGE_SERVLET: `https://wmqa2.wm.com/content/wm/servlets/exceptionAddressBar?local=en_US&serviceType=Resi`,
    // AEM_BULKERROR_MESSAGE_SERVLET: `https://wmqa3.wm.com/content/wm/servlets/exceptionAddressBar?local=en_US&serviceType=Bulk`,
    // AEM_RESIERROR_MESSAGE_SERVLET: `https://wmqa3.wm.com/content/wm/servlets/exceptionAddressBar?local=en_US&serviceType=Resi`,

    // Logged in chat
    CHAT_LOGGED_IN: `${origin.EBIZ}/contactPref/Chat/users`,
    // Business E-Commerce
    GET_CLOUDCRAZE_SERVICE_DAYS: `${origin.ESB}/locations/service-days?zip={zipcode}&site_id={siteId}&waste_stream={wasteStream}&line_of_business=C&city={city}&street={street}&latitude={latitude}&longitude={longitude}&state={region}&country={country}`,
    GET_CLOUDCRAZE_DELIVERY_SCHEDULE: `${origin.ESB}/locations/delivery-schedules?zip={zipcode}&line_of_business={lineOfBusiness}&city={city}&street={street}&latitude={latitude}&state={state}&longitude={longitude}&country={country}`,
    COMMERCIAL_SERVLET_AEM: `${window.location.origin}/content/wm/servlets/ecom/productDetails?productType={productType}&productIds={productIds}&locale={locale}&country={country}`,
    GET_OUTAGES: `${origin.ESB}/sites/{siteId}/outages`,
    GENESYS_CHAT_POPUP: `{url}lob={segment}&ma={genesysMA}&requestTrackingId={requestTrackingId}`,
    // Facilities
    // Facility search URL with search radius option.
    // 29.752554/-95.37040089999999?distance=100
    FACILITY_SEARCH: `${origin.EBIZ}/getFacilityService?latitude={lat}&longitude={lng}&distance={distance}&lang={lang}&country={country}`,
    OAKLAND_BULK_PICKUP_FACILITY_SEARCH: `${origin.EBIZ}/getFacilityService?street={street}&city={city}&state={state}&country={country}&zipcode={zipcode}&distance={distance}&lang={lang}`,
    // URL for getting facility localized content.
    FACILITY_LOCALIZED_CONTENT: `${
      origin.ESB_LOCAL ? origin.ESB_LOCAL : origin.ESB
    }/localsites/nonps/zipcode/`,
    TRIGGER_FORM_EMAIL: `${window.location.origin}/content/wm/servlets/triggerFormEmail`,
    // NOTIFICATIONS
    NOTIFICATIONS_BY_ADDRESS: `${origin.EBIZ}/alerts?lang={lang}&street={street}&city={city}&address={label}&googlePlaceId={googlePlaceId}&state={state}&country={country}&postalCode={postalCode}`,
    NOTIFICATIONS_BY_CUSTOMER_ID: `${origin.EBIZ}/user/{userId}/account/{customerId}/alerts?lang={lang}`,
    NOTIFICATIONS_BY_USER_ID: `${origin.EBIZ}/user/{userId}/alerts?lang={lang}`,
    GLOBAL_ALERTS: `${origin.EBIZ}/alerts?type=global&lang={lang}`,
    FACILITY_ALERTS: `${origin.EBIZ}/alerts?facilityId={facilityId}&lang={lang}`,
    INCIDENTS_BY_CUSTOMER_ID: `${origin.EBIZ}/user/{userId}/account/{customerId}/incidents?&fromDate={fromDate}&toDate={toDate}&lang={lang}`,
    INCIDENTS_BY_USER_ID: `${origin.EBIZ}/user/{userId}/incidents?fromDate={fromDate}&toDate={toDate}&lang={lang}`,

    // ADS
    ADS_ACCOUNTS: `${origin.EBIZ}/account/ads/search?adsAccountId={adsAccountId}&siteId={siteId}&postalCode={postalCode}`,

    // RATINGS_AND_REVIEWS
    RATINGS_AND_REVIEWS: `${window.location.origin}/content/wm/servlets/cors?tk=0016dd36598&start={start}`,
    // RATINGS_AND_REVIEWS: `http://wmqa2.wm.com/content/wm/servlets/cors?tk=0016dd36598&start={start}`,
    // SUPPORT CONTENT FRAGMENTS
    SUPPORT_ARTICLES: `${origin.SERVLET}/wm/support`,
    SUPPORT_NAV: `${origin.SERVLET}/wm/supportnav`,
    COMMUNICATION_PREFERENCES_GET: `${origin.MULESOFT}/v1/customers/{customerId}/contacts/communication-preferences{?user_id}`,
    COMMUNICATION_PREFERENCES_PUT: `${origin.MULESOFT}/v1/customers/{customerId}/contacts/{contactId}/communication-preferences`,
    MANAGE_CONTACTS: `${origin.MULESOFT}/v1/customers/{customerId}/contacts`,
    UNSUBSCRIBE_FEEDBACK: `${origin.MULESOFT}/v1/contacts/notifications/unsubscribe`,

    // Bulk
    // Common Dates API - updated for use within Contact Back flow
    BULK_PICKUP_DATES: `${origin.EBIZ}/account/services/bulkPickup/dates?toDate={toDate}&fromDate={fromDate}&street={street}&city={city}&state={state}&country={country}&postalCode={postalCode}&lang={lang}&pickupType={pickupType}`,
    // Dates API - handles original Bulk Pickup Date request
    BULK_PICKUP_DATES_ORIG: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/bulkPickup/dates?toDate={toDate}&fromDate={fromDate}&lang={lang}`,
    SUBMIT_BULK_PICKUP: `${origin.EBIZ}/user-request-service?userId={userId}&lang={lang}`,
    GUEST_BULK_PICKUP_GUIDELINES: `${origin.EBIZ}/account/{customerId}/services/bulkPickup/guidelines?lang={lang}&street={street}&city={city}&state={state}&postalCode={postalCode}&country={country}`,
    GUEST_BULK_PICKUP_DATES: `${origin.EBIZ}/account/{customerId}/services/bulkPickup/dates?toDate={toDate}&fromDate={fromDate}&lang={lang}`,
    GUEST_BULK_PICKUP_PRICING: `${origin.EBIZ}/account/{customerId}/services/bulkPickup/pricing?lang={lang}`,
    SUBMIT_GUEST_BULK_PICKUP: `${origin.EBIZ}/account/{customerId}/services/bulkPickup?lang={lang}`,

    // New Holiday Schedule
    HOLIDAYS_GUEST_BY_CUSTOMER_ID: `${origin.EBIZ}/account/{customerId}/holidays?lang={locale}&type={type}`,
    HOLIDAYS_GUEST_BY_ADDRESS: `${origin.EBIZ}/holidays?lang={locale}&street={street}&city={city}&state={state}&postalCode={zipcode}&country={country}&type={type}`,
    HOLIDAYS_USER_BY_CUSTOMER_ID: `${origin.EBIZ}/user/{userId}/account/{customerId}/holidays?lang={locale}&type={type}`,
    HOLIDAYS_USER_BY_ADDRESS: `${origin.EBIZ}/user/{userId}/holidays?lang={locale}&street={street}&city={city}&state={state}&postalCode={zipcode}&country={country}&type={type}`,

    // New Contact Info (previously: customerSupportContact)
    CONTACT_INFO_GUEST_BY_CUSTOMER_ID: `${origin.EBIZ}/account/{customerId}/facility/contactInfo?lang={locale}`,
    CONTACT_INFO_GUEST_BY_ADDRESS: `${origin.EBIZ}/facility/contactInfo?lang={locale}&street={street}&city={city}&state={state}&postalCode={zipcode}&country={country}`,
    CONTACT_INFO_USER_BY_CUSTOMER_ID: `${origin.EBIZ}/user/{userId}/account/{customerId}/facility/contactInfo?lang={locale}`,
    CONTACT_INFO_USER_BY_ADDRESS: `${origin.EBIZ}/user/{userId}/facility/contactInfo?lang={locale}&street={street}&city={city}&state={state}&postalCode={zipcode}&country={country}`,

    // New support flow
    SUBMIT_CALL_BACK_REQUEST: `${origin.MULESOFT}/v1/callback`,

    // SMS triggered Flows
    TEXT_FOR_CITY_BILLED_BULK: `${origin.EBIZ}/account/text?id={id}&requestType=bulkycity`,

    // Contact Back Eligibility
    // CONTACT_BACK_ATTRIBUTES: `https://webapiqat.wm.com/v1/contact-back/attributes?category={category}&sub_category={subCategory}&line_of_business={lineOfBusiness}&latitude={latitude}&longitude={longitude}`,
    // CONTACT_BACK_CREATE_CASE: `https://webapiqat.wm.com/v1/contact-back/cases`,
    CONTACT_BACK_ATTRIBUTES: `${origin.ESB}/contact-back/attributes?category={category}&sub_category={subCategory}&line_of_business={lineOfBusiness}&latitude={latitude}&longitude={longitude}&street={street}&city={city}&state={state}&country={country}&zip_code={postalCode}`,
    CONTACT_BACK_CREATE_CASE: `${origin.ESB}/contact-back/cases`,
    // Hard coded urls for login popup
    MYWM_DASHBOARD: `mywm/user/dashboard`,
    MYWM_USER_MYPAYMENT_CHECKOUT: `mywm/user/my-payment/checkout`,
    MYWM_USER_MYPAYMENT_BILLING: `mywm/user/my-payment/billing`,
    MYWM_MYPAYMENT_VERIFY: `mywm/my-payment/verify`,
    MYWM_USER_MYSERVICES: `mywm/user/my-services`,
    MYWM_MANAGE_ACCOUNTS: `mywm/user/accounts`,
    MYWM_PAYMENT_METHODS: `mywm/user/my-payment/method`,
    MYWM_MY_PREFERENCES: `mywm/user/preferences/search`,
    MYWM_PROFILE: `mywm/user/profile`,
    LOGIN: `user/login`,
    ROLLOFF_REQUEST_LINK: `mywm/user/my-services/rolloff-request`,
    // Survey Enpoits
    START_SURVEY: `${origin.EBIZ}/user/{userId}/startSurvey`,
    PROCESS_SURVEY: `${origin.EBIZ}/user/{userId}/processSurvey`,
    // Interactive Survey Endpoints
    START_SUPPORT_SURVEY: `${origin.EBIZ}/user/startSupportSurvey`,
    PROCESS_SUPPORT_SURVEY: `${origin.EBIZ}/user/processSupportSurvey`,
    // Payment
    BULK_AUTO_PAYMENT: `${origin.EBIZ}/account/multiAccountAutopay?`,
    PAD_POST: `${origin.EBIZ}/payment/createPadAgreement?userId={userId}`,
    // Account
    USER_ACCOUNTS_MULTI_LINK: `${origin.EBIZ}/authorize/user/{userId}/multiAccounts?lang={lang}`,
    CONTACT_UPDATE: `${origin.EBIZ}/authorize/user/{userId}/contactUpdateEvent?lang={lang}`,
    // Permalink
    PERMA_LINK: `${origin.EBIZ}/localsites/state/{state}/site/{site}/lob/{lob}`,
    ERROR_404: `${origin.SUPPORT}/Error404.html`,
    ROLLOFF_PRICING: `${origin.EBIZ}/account/{customerId}/services/rolloff/pricing?lang={lang}&userId={userId}`,
    // Repair Container
    REPAIR_CONTAINER_DATES: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/containerRepair/dates?serviceId={serviceId}&lang={lang}&eligibilityId={eligibilityId}`,
    REQUEST_REPAIR: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/containerRepair?lang={lang}`,
    REPAIR_PRICING: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/containerRepair/pricing?lang={lang}`,
    CANCEL_REPAIR: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/containerRepair/{orderId}?lang={lang}`,
    REPAIR_ELIGIBILITY: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/containerRepair/eligibility?serviceId={serviceId}&lang={lang}`,
    // Broker account (50+ linked accounts scenario)
    BROKER_ACCOUNT_BALANCE_CONTACT: `${origin.EBIZ}/user/{userId}/balanceContact`,
    BROKER_ACCOUNT_SALE_REQUEST: `${origin.EBIZ}/user/{userId}/payment/processSaleMultiRequest`,
    // My Services
    AUTOPAY_CONSENT: `${origin.EBIZ}/account/{customerId}/autopay-consent?userId={userId}&login={login}&lang={lang}`,
    RECYCLING_PICKUP: `${origin.EBIZ}/account/{customerId}/services/ondemandpickup/ondemandPickupEligibility?userId={userId}&lang={lang}&serviceId={serviceId}`,
    RECYCLING_PICKUP_DATE: `${origin.EBIZ}/account/{customerId}/services/ondemandpickup/dates?lang={lang}&userId={userId}&serviceId={serviceId}`,
    RECYCLING_PICKUP_SUBMIT: `${origin.EBIZ}/account/{customerId}/services/{serviceId}/ondemandpickup?lang={lang}&userId={userId}&serviceId={serviceId}`,
    CANCEL_RECYCLING_PICKUP: `${origin.EBIZ}/account/{customerId}/services/{serviceId}/ondemandpickup/{ondemandPickupRequestId}?lang={lang}&userId={userId}`,
    TICKET_PICKUP_INFO: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/ticketPickupInfo?lang={lang}`,
    BULK_PAPERLESS_BILLING: `${origin.EBIZ}/account/multiAccountPaperless`,
    // Paperless Preferences
    PAPERLESS_PREFERENCES: `${origin.EBIZ}/user/{userId}/account/{customerId}/paperlessPreferences`,
    // Billing
    USER_BILLING_DETAILS: `${origin.EBIZ}/user/{userId}/account/{accountId}/billing`,
    // NOTIFICATIONS
    NOTIFICATIONS_BY_CUSTOMER_ID_GUEST: `${origin.EBIZ}/account/{customerId}/alerts?lang={lang}`,
    FACILITY_SEARCH_BY_ADDRESS: `${origin.EBIZ}/getFacilityService?street={street}&city={city}&state={state}&zipcode={zipcode}&Country={country}`,
    // Service Change
    SERVICE_CHANGE_ELIGIBILITY: `${origin.EBIZ}/user/{userId}/account/{customerId}/serviceChangeEligibility?lang={lang}`,
    SERVICE_CHANGE_RECOMMENDATIONS: `${origin.EBIZ}/user/{userId}/account/{customerId}/serviceChangeCriteria?lang={lang}`,
    SERVICE_CHANGE_PRICE: `${origin.EBIZ}/user/{userId}/account/{customerId}/calculatePrice?lang={lang}`,
    SERVICE_CHANGE_SUBMIT: `${origin.EBIZ}/user/{userId}/account/{customerId}/serviceChanges?lang={lang}`,
    SERVICE_CHANGE_HISTORY: `${origin.EBIZ}/user/{userId}/account/{customerId}/serviceChanges?lang={lang}`,
    SERVICE_CHANGE_CANCEL: `${origin.EBIZ}/user/{userId}/account/{customerId}/serviceChanges?orderId={orderId}&lang={lang}`,
    SERVICE_CHANGE_DATES: `${origin.EBIZ}/user/{userId}/account/{customerId}/serviceChanges/dates?lang={lang}`,
    // Extra Pickup
    EXTRA_PICKUP_ELIGIBILITY: `${origin.EBIZ}/account/{customerId}/services/extrapickup/extraPickupEligibility?lang={lang}&userId={userId}&serviceId={serviceId}`,
    EXTRA_PICKUP_DATES: `${origin.EBIZ}/account/{customerId}/services/extrapickup/dates?lang={lang}&userId={userId}&serviceId={serviceId}`,
    EXTRA_PICKUP_PRICING: `${origin.EBIZ}/account/{customerId}/services/extrapickup/pricing?lang={lang}&userId={userId}`,
    EXTRA_PICKUP_CREATE: `${origin.EBIZ}/account/{customerId}/services/{serviceId}/extrapickup?lang={lang}&userId={userId}`,
    EXTRA_PICKUP_CANCEL: `${origin.EBIZ}/account/{customerId}/services/{serviceId}/extrapickup/{extraPickupRequestId}?lang={lang}&userId={userId}`,
    EXTRA_PICKUP_HISTORY: `${origin.EBIZ}/account/{customerId}/services/extrapickup/extrapickupdetails?userId={userId}&lang={lang}`,
    GET_EXTRA_PICKUP_DETAILS: `${origin.EBIZ}/account/{customerId}/services/{serviceId}/extrapickup/{extraPickupRequestId}?lang={lang}&userId={userId}`,
    UPDATE_EXTRA_PICKUP: `${origin.EBIZ}/account/{customerId}/services/{serviceId}/extrapickup/{extraPickupRequestId}?lang={lang}&userId={userId}`,
    BULK_PICKUP_ELIGIBILITY: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/bulkPickup/bulkPickupEligibility?lang={lang}`,
    BULK_PICKUP_GUIDELINES: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/bulkPickup/guidelines?lang={lang}`,
    BULK_PICKUP_PRICING: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/bulkPickup/pricing?lang={lang}`,
    BULK_PICKUP_CREATE: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/bulkPickup?lang={lang}`,
    BULK_PICKUP_CANCEL: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/bulkPickup/{bulkPickupRequestId}?lang={lang}`,
    // Report Missed Pickup
    REPORT_MISSED_PICKUP: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/missedPickup?lang={lang}`,
    REPORT_MISSED_PICKUP_GUEST: `${origin.EBIZ}/account/{customerId}/services/missedPickup?lang={lang}`,
    REPORT_MISSED_PICKUP_CANCEL: `${origin.EBIZ}/user/{userId}/account/{customerId}/services/missedPickup/{etaMpuRequestId}?lang=en_US`,
    // Pause Service
    CANCEL_PAUSE_SERVICE: `${origin.EBIZ}/user/{userId}/account/{customerId}/servicePause?lang={lang}`,
    SUBMIT_PAUSE_SERVICE: `${origin.EBIZ}/user/{userId}/account/{customerId}/servicePause?lang={lang}`,
    // SERVICE HISTORY
    GET_SERVICE_HISTORY: `${origin.EBIZ}/account/{customerId}/service/{serviceId}/history?numOfDays={numOfDays}&lang={lang}&userId={userId}`,
    GET_SERVICE_VISUALS: `${origin.EBIZ}/account/{customerId}/service/{serviceId}/visuals?serviceDate={serviceDate}&lang={lang}&userId={userId}`,
    // ORDER HISTORY
    COMBINED_ORDER_HISTORY: `${origin.EBIZ}/user/{userId}/orders?lang=en_US&fromDate={fromDate}&toDate={toDate}`,
    COMBINED_ORDER_HISTORY_BY_CUSTOMER: `${origin.EBIZ}/user/{userId}/orders?lang=en_US&fromDate={fromDate}&toDate={toDate}`,
    // PENDING CHARGES
    PENDING_CHARGES: `${origin.EBIZ}/account/{customerId}/pendingCharges?lang=en_US&userId={userId}`,

    customerSupportContact: {
      url: `${origin.EBIZ}/contactPref/getContactPrefByZip/zip/`,
    },
    // SMS triggered Flows
    TEXT_FOR_BULK: `${origin.EBIZ}/account/text/bulkPickup?id={id}`,
    TEXT_FOR_BILL_PAY: `${origin.EBIZ}/payment/text/pay?id={id}`,
    TEXT_FOR_ROLLOFF: `${origin.EBIZ}/account/text?id={id}&requestType=rolloff`,
    TEXT_FOR_XPU: `${origin.EBIZ}/account/text?id={id}&requestType=extrapickup`,
    TEXT_FOR_CONTAINER_REPAIR: `${origin.EBIZ}/account/text?id={id}&requestType=container`,
    TEXT_FOR_VIEW_ETA: `${origin.EBIZ}/account/text?id={id}&requestType=eta`,
    // Guest Container Issue
    GUEST_CONTAINER_REPAIR_DATES: `${origin.EBIZ}/account/{customerId}/services/containerRepair/dates?serviceId={serviceId}&lang={language}&eligibilityId={eligibilityId}`,
    GUEST_CONTAINER_REPAIR_ELIGIBLE: `${origin.EBIZ}/account/services/containerRepair/eligibility?street={street}&city={city}&state={state}&address={label}&country={country}&postalCode={zipcode}&latitude={latitude}&longitude={longitude}&lang={locale}&googlePlaceId={googlePlaceId}`,
    GUEST_CONTAINER_REPAIR_SERVICES: `${origin.EBIZ}/account/{customerId}/services/containerRepair/services?lang={language}&eligibilityId={eligibilityId}`,
    GUEST_CONTAINER_REPAIR_PRICING: `${origin.EBIZ}/account/{customerId}/services/containerRepair/pricing?lang={language}`,
    GUEST_CONTAINER_REPAIR_CREATE_TICKET: `${origin.EBIZ}/account/{customerId}/services/containerRepair?lang={language}`,
  },
  aem: {
    // URL for Cross site header & Footer on AEM landing pages
    AEM_JSON_EN_US: `${origin.CDN}/us/en/datapage.model.json`,
    AEM_JSON_EN_CA: `${origin.CDN}/ca/en/datapage.model.json`,
    AEM_JSON_FR_CA: `${origin.CDN}/ca/fr/datapage.model.json`,
    AEM_JSON_ES_US: `${origin.CDN}/us/es/datapage.model.json`,
    ROOT_AEM_SERVLET_PATH: origin.ROOT_AEM_SERVLET_PATH,
  },
})
