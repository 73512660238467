import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import get from "lodash/get"
import { getTokenAction } from "actions-lib/getToken"
import { isBrokerAccountSelector } from "selectors-lib/linkedAccounts"
import { urls } from "utils-lib/builds"
import { hideCardPaymentMethod } from "utils-lib/validation"
import { userType } from "constants-lib/authentication"
import { NO, YES } from "constants-lib/common"

export const pendingProcessingFee = () => ({
  type: ActionTypes.GET_PROCESSING_FEE_PENDING,
})

export const failedProcessingFee = (data) => ({
  type: ActionTypes.GET_PROCESSING_FEE_FAILED,
  payload: data,
})

export const fulfilledProcessingFee = (data) => ({
  type: ActionTypes.GET_PROCESSING_FEE_FULFILLED,
  payload: data,
})

export const resetProcessingFee = () => ({
  type: ActionTypes.RESET_PROCESSING_FEE,
})

/**
 * Filters and maps accounts based on the presence of their customer IDs in the myPaymentCheckout object.
 * @param {Array<Object>} accounts - An array of account objects to be filtered and mapped.
 * @param {Object} myPaymentCheckout - An object containing customerIDs as keys for lookup.
 * @param {string} accounts[].customerId - The customer ID associated with an account.
 * @param {number} accounts[].amount - The payment amount associated with an account.
 * @returns {Array<Object>} An array of objects containing customerId and amount for accounts present in myPaymentCheckout.
 */
const getAccounts = (accounts, myPaymentCheckout) =>
  accounts
    ?.filter((account) =>
      get(myPaymentCheckout, `customerIDs[${account.customerId}]`),
    )
    ?.map(({ customerId, amount }) => ({ customerId, amount })) || []

/**
 * Retrieves the payload for a logged-in user.
 * @param {Object} state - The current state of the application.
 * @param {Object} state.myPaymentCheckout - The current state of the payment checkout.
 * @param {Object} state.userBilling - The current state of the user billing.
 * @param {Object} state.myPayment - The current state of the payment.
 * @returns {Array} An array of account objects, each containing an `id` and `amount`.
 */
const getLoggedInPayload = (state) => {
  const { myPaymentCheckout, userBilling, myPayment } = state
  const isBrokerAccount = isBrokerAccountSelector(state)
  let accounts = []
  if (isBrokerAccount) {
    accounts = getAccounts(
      userBilling.sortedBrokerAccounts,
      myPaymentCheckout,
    )?.map((item) => ({
      id: item.customerId,
      amount: item.amount?.toString(),
    }))
  } else if (Object.keys(myPaymentCheckout?.customerIDs).length === 1) {
    const singleAccountByAmount =
      Object.keys(myPayment?.amountBalanceByAccount)?.map((item) => ({
        ...myPayment?.amountBalanceByAccount[item],
        customerId: item,
      })) || []
    accounts = getAccounts(singleAccountByAmount, myPaymentCheckout)?.map(
      (item) => ({
        id: item.customerId,
        amount: item.amount?.toString(),
      }),
    )
  } else {
    const accountByAmount =
      Object.keys(myPaymentCheckout?.amountBalanceByAccount)?.map((item) => ({
        ...myPaymentCheckout?.amountBalanceByAccount[item],
        customerId: item,
      })) || []
    accounts = getAccounts(accountByAmount, myPaymentCheckout)?.map((item) => ({
      id: item.customerId,
      amount: item.amount?.toString(),
    }))
  }
  return accounts
}

/**
 * Constructs the payload for processing a payment fee based on user login status, payment amount, and account details.
 * @param {Object} state - The current state object from the Redux store.
 * @param {boolean} isLoggedIn - Indicates if the user is currently logged in.
 * @param {string|number} paymentAmount - The amount to be paid, as a string or number. Commas in the string will be removed.
 * @param {Array<Object>} accounts - An array of account objects. Each account object must contain account-specific properties.
 * @returns {Object} The constructed payload object for processing the payment fee.
 */
const getProcessingFeePayload = (
  state,
  isLoggedIn,
  paymentAmount,
  accounts,
) => {
  const isBroker = isBrokerAccountSelector(state)
  const paymentAmountWithoutCommas = paymentAmount
    ?.toString()
    ?.replace(/,/g, "")
  let payload = {
    //  Based on the discussion.
    // WM will hardcode the Payment type to Credit card with Sub type Visa to fetch the convenience fee from Paymentus.
    // Since the Fee is a flat rate for resi/non-resi and is not based on any other attribute, this approach will work for now.
    payMethod: "VISA",
    payAmount: paymentAmountWithoutCommas,
  }
  payload = { ...payload, isBroker }
  // Single account RO, Bulk, Conatainer prepay
  if (isLoggedIn && accounts?.length) {
    payload = { ...payload, accounts }
  }

  if (isLoggedIn && !accounts?.length) {
    payload = { ...payload, accounts: getLoggedInPayload(state) }
  }
  return payload
}

/**
 * Determines if the exempt banner should be shown based on the accounts' fee applicability.
 * @param {Array<Object>} accounts - An array of account objects to be evaluated.
 * @param {string} accounts[].isFeeApplicable - Indicates if a fee is applicable to the account. Expected values: 'YES' or 'NO'.
 * @returns {boolean} True if at least one account is fee applicable and at least one account is exempt; otherwise, false.
 */
const showExemptBanner = (accounts) =>
  accounts?.some((account) => account.isFeeApplicable.toUpperCase() === YES) &&
  accounts?.some((account) => account.isFeeApplicable.toUpperCase() === NO)

/**
 * Checks if all accounts are exempt from fees.
 * @param {Array<Object>} accounts - An array of account objects.
 * @param {string} accounts[].isFeeApplicable - Indicates if a fee is applicable to the account. Expected values: 'YES' or 'NO'.
 * @returns {boolean} True if all accounts are exempt from fees; otherwise, false.
 */
const isExempt = (accounts) =>
  accounts?.every((account) => account.isFeeApplicable.toUpperCase() === NO)

/**
 * Handles the process of fetching and dispatching the processing fee data.
 * @param {Object} config - Axios configuration object for creating an instance.
 * @param {string} url - The URL to send the POST request to.
 * @param {Object} data - The data to be sent with the POST request.
 */
const getProcessingFeeHelper = (config, url, data, dispatch) =>
  axios
    .create(config)
    .post(url, data)
    .then((response) => {
      dispatch(
        fulfilledProcessingFee({
          ...response.data.data,
          showPartialExemptBanner: showExemptBanner(
            response.data.data.accounts,
          ),
          isExempt: isExempt(response.data.data.accounts),
        }),
      )
    })
    .catch((error) => {
      const statusCode = get(error, `response.status`, `failed`)
      const errorMessage = get(error, `response.data.Error`, ``)
      !axios.isCancel(error) &&
        dispatch(
          failedProcessingFee({
            statusCode,
            errorMessage,
          }),
        )
    })

/**
 * Initiates the process to fetch the processing fee based on the payment amount and account details.
 * @param {number} paymentAmount - The amount for which the processing fee needs to be calculated.
 * @param {Array} [accounts=[]] - An array of user's accounts.
 * @param {Function} getState - The Redux getState function used to access the current state.
 * @returns {Function} A function that, when executed, dispatches actions to handle the processing fee fetching process.
 */
export const getProcessingFee =
  (paymentAmount, accounts = []) =>
  (dispatch, getState) => {
    if (hideCardPaymentMethod(paymentAmount)) {
      return dispatch(resetProcessingFee()) // Reset processingFee if amount > 50K
    }
    dispatch(pendingProcessingFee())
    const state = getState()
    const isLoggedIn = state.userAccount.userState === userType.LOGGED_IN
    const api = `PROCESSING_FEE`
    const url = urls.url[api]
    const config = {
      headers: {},
    }
    const data = getProcessingFeePayload(
      state,
      isLoggedIn,
      paymentAmount,
      accounts,
    )
    if (isLoggedIn) {
      return getTokenAction(dispatch, getState).then((token) => {
        config.headers.token = token.accessToken
        config.params = {
          userId: get(state, `userAccount.userDetails.userId`, ``),
        }
        config.headers.apiKey = get(urls, `apiKey.USER[${api}]`, ``)
        return getProcessingFeeHelper(config, url, data, dispatch)
      })
    }
    config.headers.token =
      get(state, `myPaymentVerification.verificationToken`, ``) ||
      get(state, `guestContainerRepair.eligibilityData.token`) ||
      get(state, `customerSelections.cityBilledEligibility.token`, ``)
    config.headers.apiKey = get(urls, `apiKey.GUEST[${api}]`, ``)
    const customerId =
      get(state, `myPaymentVerification.verificationData.customerId`, ``) ||
      get(state, `myPaymentVerification.verificationDetails.customerId`, ``) ||
      get(state, `guestContainerRepair.eligibilityData.customerId`, ``) ||
      get(state, `customerSelections.cityBilledEligibility.customerId`, ``)

    return getProcessingFeeHelper(
      config,
      `${url}/${customerId}`,
      data,
      dispatch,
    )
  }
