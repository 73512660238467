/* eslint-disable max-len */
import { getWindow } from "utils-lib/getWindow"
import getBackendAPIs from "../getBackendAPIs"
import getMicrositeData from "../getMicrositeData"

const window = getWindow()

const origin = {
  EBIZ: `https://qa2-rest-api.wm.com`,
  ESB: `https://webapiqat.wm.com/v1`, // Goutam, Rahul
  SERVLET: `${window.location.origin}/bin`,
  CDN: "https://wastemgt-waf-qa-65.adobecqms.net",
  MULESOFT: `https://cloudwebapiqa.wm.com`,
  ESB_LOCAL: `https://d3qtt900u9ulb5.cloudfront.net/v1`,
  ROOT_AEM_SERVLET_PATH: `${window.location.origin}/content/wm/servlets`,
  UI: `https://wmqa2.wm.com`,
  SUPPORT: `https://qa.support.wm.com`,
  GENESYS: `https://wmchatint.wm.com/webapi/api/v2`,
}

const apiKeys = {
  /* Unique API keys for various groups of guest flow backend services. */
  GUEST: {
    LIVE_CHAT: `5057CE1FF767AE17B600`,
    PAYMENT: `917A6D3074587D37ED8C`,
    ROLLOFF: `162FB898B410D88ABC7E`,
    BALANCE: `19A524A913A4B64B903F`,
    CUSTOMER_SERVICES: `60BE3BD12DF2FE4E9198`,
    USER_REQUEST_SERVICE: `58D6263A43C0782A248F`,
    ALERTS: `CA77E32F1CEE943E7559`,
    APPLE_PAY: `E8DD87462F191170BE22`,
    APPLE_PAY_SCHEDULED: `7B61C10CE36424608009`,
    APPLE_PAY_PROCESS: `72B232CFF9763BFAB8B5`,
    ADS_ACCOUNTS: `19A524A913A4B64B903F`,
    AWS_TRANSLATE: `us-east-1:30d30e7c-e59d-4826-8871-2e252cfa9a09`,
    ALGOLIA_API_KEY: `a879e0c540e46c2a18869bb329c46fb3`,
    ALGOLIA_APP_ID: `1W4TGL0C8E`,
    BULK_PICKUP_DATES: `19A524A913A4B64B903F`,
    SUBMIT_BULK_PICKUP: `58D6263A43C0782A248F`,
    HOLIDAYS_GUEST_BY_CUSTOMER_ID: `19A524A913A4B64B903F`,
    CONTACT_INFO_GUEST_BY_CUSTOMER_ID: `19A524A913A4B64B903F`,
    HOLIDAYS_GUEST_BY_ADDRESS: `B0474318FB620EB1C044`,
    CONTACT_INFO_GUEST_BY_ADDRESS: `B0474318FB620EB1C044`,
    ENERGY_SURCHARGE_CALCULATOR: `19A524A913A4B64B903F`,
    GUEST_BULK_PICKUP_GUIDELINES: `19A524A913A4B64B903F`,
    GUEST_BULK_PICKUP_DATES: `19A524A913A4B64B903F`,
    SUBMIT_GUEST_BULK_PICKUP: `19A524A913A4B64B903F`,
    GUEST_BULK_PICKUP_PRICING: `19A524A913A4B64B903F`,
    GET_CITY_BILLED: `19A524A913A4B64B903F`,
    TEXT_FOR_CITY_BILLED_BULK: `19A524A913A4B64B903F`,
    USER_DETAILS: `5CA8C6B74EC100626933`,
    CREATE_USER: `05551547421F44F29671`,
    ENROLL_AUTOPAY_PAPERLESS_GUEST: `19A524A913A4B64B903F`,
    TEXT_FOR_BILL_PAY: `917A6D3074587D37ED8C`,
    TEXT_FOR_VIEW_ETA: `19A524A913A4B64B903F`,
    REPORT_MISSED_PICKUP_GUEST: `19A524A913A4B64B903F`,
    GUEST_CONTAINER_REPAIR: `19A524A913A4B64B903F`,
    PROCESSING_FEE: `917A6D3074587D37ED8C`,
  },
  /* Unique API keys for various groups of logged in user flow backend services. */
  USER: {
    PAYMENT: `6F06AF0FCFBCB762B2EC`,
    ROLLOFF: `97B25A4C1698C60E7757`,
    AUTHENTICATION: `59A2C3BDBA3160A042A3`,
    BALANCE_INVOICE: `B2104C2D169C9154EC70`,
    CUSTOMER_SERVICES: `E14AD4D5238E636B414B`,
    USER_ACCOUNTS: `3A4738BC2D3E71C5DE95`,
    USER_REQUEST_SERVICE: `B4941CA732AF920AD9DD`,
    CHAT_LOGGED_IN: `5057CE1FF767AE17B600`,
    CHAT_LOGGED_IN_MOBILE: `AD1684D1D982B92DC9F7`,
    ALERTS: `048ED0C2146550DC5EDC`,
    INCIDENTS_BY_USER_ID: `59A2C3BDBA3160A042A3`,
    HOLIDAYS_USER_BY_CUSTOMER_ID: `59A2C3BDBA3160A042A3`,
    HOLIDAYS_USER_BY_ADDRESS: `59A2C3BDBA3160A042A3`,
    CONTACT_INFO_USER_BY_CUSTOMER_ID: `59A2C3BDBA3160A042A3`,
    CONTACT_INFO_USER_BY_ADDRESS: `59A2C3BDBA3160A042A3`,
    REPAIR: `59A2C3BDBA3160A042A3`,
    APPLE_PAY: `7C4058CF1283AC1F318D`,
    APPLE_PAY_SCHEDULED: `BECEA3730E9A4483A367`,
    APPLE_PAY_AUTOPAY: `68894829E580AFAFDC60`,
    APPLE_PAY_PROCESS: `33A1C4CDB87C9DF5BC49`,
    BULK_AUTOPAY_PAPERLESS_ENROLL: `B2104C2D169C9154EC70`,
    SERVICE_CHANGE: `C28F01A383061CAC787F`,
    PAUSE_SERVICE: `C28F01A383061CAC787F`, // uses same API key as service change APIs
    EXTRA_PICKUP: `B2104C2D169C9154EC70`,
    RECYCLING_PICKUP: `B2104C2D169C9154EC70`, // uses same API key as Extra pickup APIs
    BULK_PICKUP: `59A2C3BDBA3160A042A3`,
    HISTORY_VISUALS: `B2104C2D169C9154EC70`,
    COMBINED_ORDER_HISTORY: `59A2C3BDBA3160A042A3`,
    PENDING_CHARGES: `B2104C2D169C9154EC70`,
    SURVEY: `5CA8C6B74EC100626933`,
    SUPPORT_SURVEY: `05551547421F44F29671`,
    USER_BILLING_DETAILS: `59A2C3BDBA3160A042A3`,
    PAPERLESS_PREFERENCES: `59A2C3BDBA3160A042A3`,
    TEXT_FOR_BULK: `19A524A913A4B64B903F`,
    TEXT_FOR_ROLLOFF: `19A524A913A4B64B903F`,
    TEXT_FOR_XPU: `19A524A913A4B64B903F`,
    TEXT_FOR_CONTAINER_REPAIR: `19A524A913A4B64B903F`,
    REPORT_MISSED_PICKUP: `59A2C3BDBA3160A042A3`,
    TICKET_PICKUP_INFO: `59A2C3BDBA3160A042A3`,
    REDESIGNED_DASHBOARD_PICKUP_INFO: `59A2C3BDBA3160A042A3`,
    REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY: `59A2C3BDBA3160A042A3`,
    REDESIGNED_DASHBOARD_SERVICES_DETAILS: `59A2C3BDBA3160A042A3`,
    CAN_NOT_OUT: `59A2C3BDBA3160A042A3`,
    PROCESSING_FEE: `6F06AF0FCFBCB762B2EC`,
  },
  ESB: {
    REFRESH_TOKEN: `L1x8j7GvLVj5_bO1v8l85K97-P1UPn1lp0VQey3vU2s`,
    ORDERS: `E5E2902D4E578F37CNQ3`,
    GET_OUTAGES: `7YGCO464FF79AZ0Q44WI`,
  },
}

const okta = {
  clientId: `0oajsh2g196u1KP5F0h7`,
  issuer: `https://ssologinqa.wm.com/oauth2/auseqvqfxn0Y66ppa0h7`,
  url: `https://ssologinqa.wm.com`,
}

export default {
  ...getBackendAPIs({ origin, apiKeys, okta }),
  ...getMicrositeData(),
  chatbot: {
    awsConfig: {
      region: `us-east-1`,
      identityPoolId: `us-east-1:ceb4f6de-d943-45fe-911f-9afdecae2331`,
    },
    chatbotConfig: {
      botAlias: `qa`,
      botName: `WMBotQA`,
      lexUserIdPrefix: `chatbot-wm`,
    },
    host: {
      domain: `https://dqocrrt6dia9u.cloudfront.net`,
      subdirectory: `/config.json`,
    },
  },
  cloudfront: {
    melissaData: {
      emailCleanse: {
        url: `${origin.ESB}/emails/{emailId}/cleanse`,
      },
    },
    myPreferences: {
      getOptions: {
        url: `${origin.ESB}/customers/{customerId}/communication-preferences?locale={lang}`,
      },
      putOptions: {
        url: `${origin.ESB}/customers/{customerId}/communication-preferences?locale={lang}`,
      },
      putResendConfirmation: {
        url: `${origin.ESB}/customers/{customerId}/communication-preferences?resend={resend}&locale={lang}`,
      },
      contacts: {
        // url: `${cloudfrontHostName}/customer/{customerId}/contactInfo`,
        url: `${origin.ESB}/customers/{ezpayId}/contacts`,
      },
    },
    // My services customer account info
    alerts: {
      url: `${origin.EBIZ}/alerts/alerts.json`,
    },
    customerSupportLiveChat: {
      url: `${origin.EBIZ}/contactPref/getChatByAddress/zip/`,
    },
    paymentUrl: {
      url: `https://testsecureacceptance.cybersource.com/embedded/token/create`,
    },
    paymentusIframeURL: {
      url: `https://secure5.paymentus.com/xotp/pm/wmpt?authToken=`,
    },
    // My services customer account info
    getStartedNavEn: {
      url: `${origin.EBIZ}/nav/wm-canada-nav_en_CA.json`,
    },
    getStartedNavFr: {
      url: `${origin.EBIZ}/nav/wm-canada-nav_fr_CA.json`,
    },
    productListEn: {
      url: `${origin.EBIZ}/products/product-list-wmca_en_CA.json`,
    },
    productListFr: {
      url: `${origin.EBIZ}/products/product-list-wmca_fr_CA.json`,
    },
    // Facility search URL with search radius option.
    // 29.752554/-95.37040089999999?distance=100
    facilitySearch: {
      url: `${origin.EBIZ}/getFacilityService?latitude={lat}&longitude={lng}&distance={distance}&lang={lang}&country={country}`,
    },
    // URL for getting facility localized content.
    facilityLocalizedContent: {
      url: `${origin.ESB_LOCAL}/localsites/nonps/zipcode/`,
    },
    getLocalsite: {
      url: `${origin.ESB_LOCAL}/localsites?city={city}&state={state}&street={street}&zipcode={zipcode}&latitude={latitude}&longitude={longitude}`,
    },
  },
  cloudCraze: {
    headers: {
      ClientId: `0oa7tcatpmBbWBUII1t7`,
      ClientSecret: `C6IAlN_frO2jrYQ2s1nh_eKzFM17P4x5m0tP0oFd`,
    },
  },
  wm: {
    headers: {
      Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJiZ29sbGFAd20uY29tIiwic3ViIjoiV2FzdGUgbWFuYWdlbWVudCAgdGVhbSIsImp0aSI6IjMxOEQ1QUFEN0E0MTQ5OENFRDI2Iiwic2NvcGUiOlsic2VsZiIsImFsbCJdLCJpYXQiOjE1MDUzMTQxNzYsImV4cCI6MTU2ODM4NjE3Nn0.DlrES5SVfBBrkmf9rIYhT_pTn8eWKh4vpcLz7stL63M`,
      ClientId: `318D5AAD7A41498CED26`,
      EsbClientId: `BN0TPYNSUYUZJ31`,
    },
  },
  commercialChat: {
    liveChatKey: `5738a000000PG4r`,
    liveChatApi: `https://d.la4-c1cs-ia4.salesforceliveagent.com/chat`,
    liveChatHelper: `00D8I0000000MYd`,
    businessLiveChatkey: `5738a000000PG4r`,
    businessLiveChatApi: `https://d.la5-c1cs-ia5.salesforceliveagent.com/chat`,
    businessLiveChatHelper: `00D8D000000qyUT`,
  },
}
