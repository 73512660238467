import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  fetchCode: 0,
  fetchState: ``,
  errorMessage: ``,
  jwtPreference: ``,
  verificationToken: ``,
  data: {
    customerVerified: ``,
  },
  customerId: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_MY_WM_PREFERENCE_VERIFICATION_FAILED:
      return {
        data: {
          customerVerified: action.payload.customerVerified,
        },
        errorMessage: action.payload.errorMessage,
        fetchCode: action.payload.fetchCode,
        fetchState: `failed`,
      }

    case ActionTypes.GET_MY_WM_PREFERENCE_VERIFICATION_PENDING:
      return {
        ...initialState,
        data: {},
        fetchState: `pending`,
        customerId: action.payload,
      }

    case ActionTypes.GET_MY_WM_PREFERENCE_VERIFICATION_FULFILLED:
      return {
        ...state,
        fetchState: `fulfilled`,
        ...action.payload,
      }

    case ActionTypes.GET_MY_WM_PREFERENCE_VERIFICATION_STORE_JWT:
      return {
        ...state,
        verificationToken: action.payload.jwtPreference,
      }

    // case ActionTypes.GET_CUSTOMER_ACCOUNT_PENDING:
    case ActionTypes.GET_CUSTOMER_VERIFICATION_PENDING:
    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
    case ActionTypes.RESET_MYSERVICES_DASHBOARD:
    case ActionTypes.RESET_CUSTOMER_CONTACTS:
      return {
        ...initialState,
        data: {},
      }

    default:
      return state
  }
}
