import PropTypes from "prop-types"
import getContentUrl from "utils-lib/getContentUrl"
import { wmColor } from "themes-lib/wmTheme"
import { Link } from "../Link/LinkContainer"

export const ButtonArrow = ({
  analytics_key,
  className,
  alignment,
  justification,
  href,
  onClick,
  onKeyDown,
  children,
  shouldNotTrackInComponent,
  showGreenIcon,
  isExternalLink,
  disabled,
  isBackgroundGreen,
  isLinkYellow,
  arrowDirection,
  ariaLabel,
  useWhiteLink,
  useUnderlineHover,
  isSCA,
  elementType,
  target,
  tabIndex,
  linkStyle,
  useYellowCaret,
  testid,
  ...props
}) => {
  const arrowColor = disabled
    ? `gray`
    : showGreenIcon || !isBackgroundGreen
    ? useYellowCaret
      ? `yellow`
      : `green`
    : `white`

  const textStyle = {}

  if (isBackgroundGreen) {
    textStyle.color = isLinkYellow ? wmColor.primaryYellow : wmColor.white
  }
  const linkstylewitharrows = {}
  if (className.toLowerCase() === "linkstylewitharrows") {
    linkstylewitharrows.color = isLinkYellow
      ? wmColor.primaryYellow
      : wmColor.green2
  }

  return (
    <Link
      testid={testid}
      analytics_key={
        analytics_key !== ``
          ? analytics_key
          : children
          ? children.toString()
          : href
      }
      tabIndex={tabIndex}
      href={href}
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={disabled}
      className={`ButtonArrow maax-font-weight-bold d-flex ${alignment} ${justification} ${className}`}
      shouldNotTrackInComponent={shouldNotTrackInComponent}
      isExternalLink={isExternalLink}
      ariaLabel={ariaLabel}
      useWhiteLink={useWhiteLink}
      useUnderlineHover={useUnderlineHover}
      isSCA={isSCA}
      elementType={elementType}
      target={target}
      {...props}
    >
      {arrowDirection === `left` ? (
        <>
          <img
            src={getContentUrl(
              `/content/dam/wm/icons/controls/20x20/${arrowColor}/material-ui/arrow-${
                useYellowCaret ? `down.svg` : `open.svg`
              }`,
            )}
            style={{
              display: linkStyle ? "none" : `flex`,
              ...(arrowDirection === `left`
                ? {
                    marginRight: `0.25rem`,
                    transform: `rotate(90deg)`,
                  }
                : {
                    marginLeft: `0.25rem`,
                    transform: `rotate(${
                      arrowDirection === `up`
                        ? 180
                        : arrowDirection === `down`
                        ? 0
                        : -90
                    }deg)`,
                  }),
            }}
            alt=""
            className="arrow"
          />
          <div
            data-testid="ButtonArrow__text"
            className="ButtonArrow__text"
            style={{
              lineHeight: `17px`,
              ...textStyle,
              ...linkstylewitharrows,
            }}
          >
            {children}
          </div>
        </>
      ) : (
        <>
          <div
            data-testid="ButtonArrow__text"
            className="ButtonArrow__text"
            style={{
              lineHeight: `17px`,
              ...textStyle,
              ...linkstylewitharrows,
            }}
          >
            {children}
          </div>
          <img
            src={getContentUrl(
              `/content/dam/wm/icons/controls/20x20/${arrowColor}/material-ui/arrow-${
                useYellowCaret ? `down.svg` : `open.svg`
              }`,
            )}
            style={{
              display: linkStyle === "linkStyle" ? "none" : `flex`,
              ...(arrowDirection === `left`
                ? {
                    marginRight: `0.25rem`,
                    transform: `rotate(90deg)`,
                  }
                : {
                    marginLeft: `0.25rem`,
                    height: "20px",
                    width: "20px",
                    transform: `rotate(${
                      arrowDirection === `up`
                        ? 180
                        : arrowDirection === `down`
                        ? 0
                        : -90
                    }deg)`,
                  }),
            }}
            alt=""
            className="arrow"
          />
        </>
      )}
    </Link>
  )
}

ButtonArrow.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  alignment: PropTypes.string,
  justification: PropTypes.string,
  analytics_key: PropTypes.string,
  shouldNotTrackInComponent: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  disabled: PropTypes.bool,
  showGreenIcon: PropTypes.bool,
  isBackgroundGreen: PropTypes.bool,
  isLinkYellow: PropTypes.bool,
  arrowDirection: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ariaLabel: PropTypes.string,
  useWhiteLink: PropTypes.bool,
  useUnderlineHover: PropTypes.bool,
  isSCA: PropTypes.bool,
  elementType: PropTypes.string,
  testid: PropTypes.string,
}

ButtonArrow.defaultProps = {
  className: ``,
  alignment: `align-items-center`,
  justification: `justify-content-center justify-content-md-start`,
  href: ``,
  onClick: () => {},
  onKeyDown: () => {},
  analytics_key: ``,
  shouldNotTrackInComponent: false,
  disabled: false,
  children: ``,
  showGreenIcon: false,
  isBackgroundGreen: false,
  isLinkYellow: false,
  arrowDirection: `right`,
  ariaLabel: ``,
  useWhiteLink: false,
  useUnderlineHover: false,
  isSCA: false,
  elementType: `ButtonArrow`,
  tabIndex: `0`,
  testid: "ButtonArrow",
  isExternalLink: false,
}
