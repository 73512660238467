/* eslint-disable new-cap */
import isEmpty from "lodash/isEmpty"
import IMask from "imask"

/**
 * A mask pattern for customer IDs.
 * @type {IMask.Masked}
 * These pattern strings are used by react-imask for input validation and
   masking. react-imask replaces the now unmaintained react-text-mask and the
   <MaskedInput> component we used to use. Please use the <MaskedTextField>
   component instead whenever masking is needed. <MaskedTextField> is a wrapper
   combining MUI's <TextField> and react-imask's <IMaskInput>.

  IMPORTANT NOTE: If you do not need masking, use <TextField>, not
   <MaskedTextField>

  (see: https://github.com/uNmAnNeR/imaskjs/tree/master/packages/react-imask
   and https://github.com/uNmAnNeR/imaskjs)
 */
export const customerIdPattern = new IMask.createMask({
  mask: [
    { mask: `00000` },
    { mask: `0-00000` },
    { mask: `00-00000` },
    { mask: `000-00000` },
    { mask: `0000-00000` },
    { mask: `00000-00000` },
    { mask: `0-00000-00000` },
    { mask: `00-00000-00000` },
    { mask: `000-00000-00000` },
    { mask: `0000-00000-00000` },
    { mask: `00000-00000-00000` },
  ],
})

/**
 * A mask pattern for long customer IDs.
 * @type {IMask.Masked}
 */
export const longCustomerIdPattern = new IMask.createMask({
  mask: [
    { mask: `00000` },
    { mask: `0-00000` },
    { mask: `00-00000` },
    { mask: `000-00000` },
    { mask: `0000-00000` },
    { mask: `00000-00000` },
    { mask: `0-00000-00000` },
    { mask: `00-00000-00000` },
    { mask: `000-00000-00000` },
    { mask: `0000-00000-00000` },
    { mask: `00000-00000-00000` },
    { mask: `00000-00000-00000-00000` },
  ],
})

/**
 * A mask pattern for phone numbers.
 * @type {string}
 */
export const phonePattern = `(000) 000-0000`

/**
 * A mask pattern for invoice numbers.
 * @type {string}
 */
export const invoiceNumberPattern = `0000000-0000-0`

/**
 * A regular expression that matches any string that contains only letters.
 * @type {RegExp}
 */
export const namePattern = /^[a-zA-Z]+$/
export const fullNamePattern = /^[a-zA-Z\s'-]+$/

/**
 * A function that formats a number as currency.
 * @param {number} num - The number to be formatted as currency.
 * @returns {string} - The formatted currency string.
 */
export const currencyPattern = (num) => {
  let value = num?.toString()
  if (!isEmpty(value)) {
    if (value.length === 3 || value.length === 1) {
      value = `0${value}`
    }
    value = parseFloat(value.replace(/[^\d]/g, "").replace(/(\d\d?)$/, ".$1"))
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    return value
  }
  return "0.00"
}

/**
 * A function that adds a zero after the decimal point if there is only one digit after it.
 * @param {number} num - The number to be formatted.
 * @returns {string} - The formatted string.
 */
export const addZeroAfterDecimal = (num) => {
  const value = num?.toString()
  const index = value?.indexOf(".")

  if (index === -1) {
    return `${num}.00`
  }

  if (value?.length - index - 1 < 2) {
    // adding zero in the end of number if we have one digit after decimal
    return `${num}0`
  }
  return num
}
