import get from "lodash/get"
import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.CREATE_USER_WITH_CREDENTIALS_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.CREATE_USER_WITH_CREDENTIALS_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.CREATE_USER_WITH_CREDENTIALS_FULFILLED,
  payload: data,
})

export const createUserWithCredentials = (data) => (dispatch) => {
  const api = `CREATE_USER`
  const url = urls.url[api]
  dispatch(pendingAC())

  const config = {
    headers: {},
  }

  const apiKey = get(urls, `apiKey.USER[${api}]`, ``)
  if (apiKey !== ``) {
    config.headers.apiKey = apiKey
  }

  return axios
    .create(config)
    .post(url, data)
    .then((response) => {
      const login = get(response, `data.data.profile.login`, ``)
      const errorMsg = get(response, `data.errorMsg`, ``)
      const statusCode = get(response, `data.statusCode`, ``)
      dispatch(fulfilledAC({ login, errorMsg, statusCode }))
      return response
    })
    .catch((error) => {
      const errorCode = get(error, `response.status`, ``)
      const errorMsg = get(error, `response.data.errorMsg`, ``)
      const statusCode = get(error, `response.data.statusCode`, ``)
      dispatch(failedAC({ errorCode, errorMsg, statusCode }))
      return error
    })
}
