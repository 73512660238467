import { isMyWMApp } from "utils-lib/isMyWMApp"
import get from "lodash/get"
import axios from "axios"
import { urls } from "utils-lib/builds"
import {
  pendingAC as aemPendingAC,
  getCustomerAccountByCustomerId as aemGetCustomerAccountByCustomerId,
  getCustomerByAddress as aemGetCustomerByAddress,
} from "./aemGetCustomerAccountJson"
import {
  pendingAC as mywmPendingAC,
  getCustomerAccountByCustomerId as mywmGetCustomerAccountByCustomerId,
  getCustomerByAddress as mywmGetCustomerByAddress,
} from "./mywmGetCustomerAccountJson"
import { fulfilledAC, failedAC } from "./actionsCreators"

const api = `CUSTOMER_SEARCH_GUEST`

export const pendingAC = () => {
  if (isMyWMApp) {
    return mywmPendingAC
  }

  return aemPendingAC
}

export const getCustomerAccountByCustomerId = (data, lang, callback) => {
  if (isMyWMApp) {
    return mywmGetCustomerAccountByCustomerId(data, lang, callback)
  }

  return aemGetCustomerAccountByCustomerId(data, lang, callback)
}

/**
 * Action for getting customer account information by email.
 * @function getCustomerAccountByEmail
 * @param {Object} data - The data to include in the API call.
 * @param {string} lang - The language to use for the API call.
 * @param {function} [onComplete=() => {}] - Optional callback function to be called after the API call is completed.
 * @returns {function} dispatches actions to update the store with the results of the API call.
 */
export const getCustomerAccountByEmail =
  (data, lang, onComplete = () => {}) =>
  (dispatch) => {
    const api = `CUSTOMER_SEARCH_GUEST`

    dispatch(pendingAC())
    const getOptions = {
      url: `${urls.url[api]}?email=${data}&lang=${lang}`,
    }

    return axios
      .create({
        headers: {
          apiKey: get(urls, `apiKey.GUEST[${api}]`, ``),
        },
      })
      .get(getOptions.url)
      .then((response) => response)
      .then((response) => {
        dispatch(fulfilledAC(response.data || {}))
        onComplete(response.data || {})
        return response
      })
      .catch((e) => {
        dispatch(failedAC(get(e, `response.status`, 403)))
        onComplete()
      })
  }

export const getCustomerByAddress = (data, onComplete, ...args) => {
  if (isMyWMApp) {
    return mywmGetCustomerByAddress(data, onComplete, ...args)
  }

  return aemGetCustomerByAddress(data, onComplete, ...args)
}

/**
 * Action for getting customer account information by phone number.
 * @function getCustomerAccountByPhone
 * @param {Object} data - The data to include in the API call.
 * @param {string} lang - The language to use for the API call.
 * @param {function} [onComplete=() => {}] - Optional callback function to be called after the API call is completed.
 * @returns {function} dispatches actions to update the store with the results of the API call.
 */
export const getCustomerAccountByPhone =
  (data, lang, onComplete = () => {}) =>
  (dispatch) => {
    dispatch(pendingAC())
    const getOptions = {
      url: `${urls.url[api]}?phoneNumber=${data}&lang=${lang}`,
    }

    return axios
      .create({
        headers: {
          apiKey: get(urls, `apiKey.GUEST[${api}]`, ``),
        },
      })
      .get(getOptions.url, {
        // validateStatus: () => true,
      })
      .then((response) => {
        dispatch(fulfilledAC(response.data || {}))
        onComplete(response.data || {})
        return response
      })
      .catch((e) => {
        dispatch(failedAC(get(e, `response.status`, 403)))
        onComplete()
      })
  }
