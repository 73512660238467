import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import _ from "lodash"
import * as ActionTypes from "constants-lib/actionTypes"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

// Action creators
export const pendingAC = () => ({
  type: ActionTypes.CONFIRM_ROLLOFF_TICKET_CANCEL_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.CONFIRM_ROLLOFF_TICKET_CANCEL_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.CONFIRM_ROLLOFF_TICKET_CANCEL_FULFILLED,
  payload: data,
})

export const confirmRollOffTicketCancel =
  (
    customerId,
    rollOffRequestId,
    verificationAttributes,
    language,
    requireLogin = false,
  ) =>
  (dispatch, getState) => {
    const { myServices, customerVerification } = getState()

    const api = `CANCEL_ROLLOFF_REQUEST`
    const template = URITemplate(urls.url[api])
    const url = template.expand({
      customerId,
      rollOffRequestId,
      language,
    })

    if (requireLogin) {
      return getTokenAction(dispatch, getState).then((token) => {
        const config = {
          headers: {
            [urls.okta.tokenFieldKey]: token.accessToken,
            apiKey: _.get(urls, `apiKey.USER[${api}]`, ``),
          },
          params: {
            userId: _.get(getState(), `userAccount.userDetails.userId`, ``),
          },
        }

        return confirmRollOffTicketCancelHelper(
          url,
          config,
          rollOffRequestId,
          dispatch,
        )
      })
    }

    const config = {
      headers: {
        apiKey: _.get(urls, `apiKey.GUEST[${api}]`, ``),
      },
      params: {
        token: customerVerification.token,
      },
    }

    if (
      verificationAttributes.email !== undefined &&
      verificationAttributes.email !== ``
    ) {
      config.params.verificationEmail = verificationAttributes.email
    }

    if (
      verificationAttributes.phoneNumber !== undefined &&
      verificationAttributes.phoneNumber !== ``
    ) {
      config.params.verificationPhoneNumber = verificationAttributes.phoneNumber
    }

    if (
      verificationAttributes.customerId !== undefined &&
      verificationAttributes.customerId !== ``
    ) {
      config.params.verificationCustomerId = verificationAttributes.customerId
    }

    if (
      _.isEmpty(config.params.verificationAttributes) &&
      myServices.locateServicesMethodType === `customerId`
    ) {
      config.params.verificationCustomerId = myServices.locateServicesInputValue
    }

    return confirmRollOffTicketCancelHelper(
      url,
      config,
      rollOffRequestId,
      dispatch,
    )
  }

export const confirmRollOffTicketCancelHelper = (
  url,
  config,
  rollOffRequestId,
  dispatch,
) => {
  dispatch(pendingAC())

  // const mockURL = `/mock/cancel-ro-ticket-response.json`

  return axios
    .delete(url, config)
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      const payloadData = {
        response: response.data,
        rollOffRequestId,
        statusCode,
      }

      dispatch(fulfilledAC(payloadData))
      return response
    })
    .catch((error) => {
      dispatch(failedAC(_.get(error, `response.status`, ``)))
    })
}
