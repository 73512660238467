import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import URITemplate from "urijs/src/URITemplate"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"
import _ from "lodash"

export const pendingAC = () => ({
  type: ActionTypes.SURVEY_START_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.SURVEY_START_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.SURVEY_START_FULFILLED,
  payload: data,
})

export const processSurveyPendingAC = () => ({
  type: ActionTypes.PROCESS_SURVEY_PENDING,
})

export const processSurveyFailedAC = (data) => ({
  type: ActionTypes.PROCESS_SURVEY_FAILED,
  payload: data,
})

export const processSurveyFulfilledAC = (data) => ({
  type: ActionTypes.PROCESS_SURVEY_FULFILLED,
  payload: data,
})

export const startSurvey = () => (dispatch, getState) => {
  const api = `START_SURVEY`
  const template = URITemplate(urls.url[api])
  dispatch(pendingAC())
  return getTokenAction(dispatch, getState)
    .then((res) => {
      const state = getState()
      const userId = _.get(state, `userAccount.userDetails.userId`, ``)
      const url = template.expand({ userId })
      const config = {
        headers: {
          oktatoken: res.accessToken,
        },
      }

      const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      if (apiKey !== ``) {
        config.headers.apiKey = apiKey
      }

      return axios
        .create(config)
        .get(url)
        .then((response) => {
          dispatch(fulfilledAC(response.data))
          return response.data
        })
    })
    .catch((error) => {
      dispatch(failedAC(error.response.status))
    })
}

export const processSurvey =
  (pageSource, customerId, surveyContact = {}) =>
  (dispatch, getState) => {
    const api = `PROCESS_SURVEY`
    const template = URITemplate(urls.url[api])
    const state = getState()
    const sessionGUID = _.get(
      state,
      `surveyQuestions.surveyResponse.SessionGuid`,
      ``,
    )

    const userId = _.get(state, `userAccount.userDetails.userId`, ``)
    const answerRating = _.get(state, `surveyQuestions.rating`, ``)
    const surveyExperience = _.get(
      state,
      `surveyQuestions.surveyExperience`,
      ``,
    )
    const data = {
      SessionGuid: sessionGUID,
      QuestionAndSubQuestionResponses: [
        {
          QuestionFullName: "QCUSIDS",
          AnswerInputs: [{ Answer: customerId, OtherData: "" }],
        },
        {
          QuestionFullName: "QUSRID",
          AnswerInputs: [{ Answer: userId, OtherData: "" }],
        },
        {
          QuestionFullName: "QROCKID",
          AnswerInputs: [
            {
              Answer: _.get(window, `sessionStorage.ID`, `logrocket id`),
              OtherData: "",
            },
          ],
        },
        {
          QuestionFullName: "QPGSRCE",
          AnswerInputs: [{ Answer: pageSource, OtherData: "" }],
        },
        {
          QuestionFullName: "Q2",
          AnswerInputs: [{ Answer: `_${answerRating}`, OtherData: "" }],
        },
        {
          QuestionFullName: "Q2a",
          AnswerInputs: [{ Answer: surveyExperience, OtherData: "" }],
        },
        {
          QuestionFullName: "CIQ1a",
          AnswerInputs: [
            {
              Answer: _.get(surveyContact, `shouldContact`, false),
              OtherData: "",
            },
          ],
        },
        {
          QuestionFullName: "CIQ1a1",
          AnswerInputs: [
            {
              Answer: _.get(surveyContact, `mustContact`, false),
              OtherData: "",
            },
          ],
        },
        {
          QuestionFullName: "QNAME",
          AnswerInputs: [
            { Answer: _.get(surveyContact, `name`, ``), OtherData: "" },
          ],
        },
        {
          QuestionFullName: "QEMAIL",
          AnswerInputs: [
            { Answer: _.get(surveyContact, `email`, ``) || ``, OtherData: "" },
          ],
        },

        {
          QuestionFullName: "QPHONE",
          AnswerInputs: [
            { Answer: _.get(surveyContact, `phone`, ``) || ``, OtherData: "" },
          ],
        },
      ],
    }

    dispatch(processSurveyPendingAC())
    return getTokenAction(dispatch, getState)
      .then((res) => {
        const userId = _.get(state, `userAccount.userDetails.userId`, ``)
        const surveyToken = _.get(
          state,
          `surveyQuestions.surveyResponse.surveyToken`,
          ``,
        )
        const url = template.expand({ userId })
        const config = {
          headers: {
            oktatoken: res.accessToken,
            surveyToken,
          },
        }

        const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }

        return axios
          .create(config)
          .post(url, data)
          .then((response) => {
            dispatch(processSurveyFulfilledAC(response.data))
            return response.data
          })
      })
      .catch((error) => {
        dispatch(processSurveyFailedAC(error.response.status))
      })
  }

export const updateRating = (data) => ({
  type: ActionTypes.SURVEY_RATING,
  payload: data,
})

export const setSurveyExperience = (data) => ({
  type: ActionTypes.SURVEY_EXPERIENCE,
  payload: data,
})

export const isSurveyHidden = (data) => ({
  type: ActionTypes.IS_SURVEY_HIDDEN,
  payload: data,
})

export const clearSurveyData = () => ({
  type: ActionTypes.CLEAR_SURVEY_DATA,
})
