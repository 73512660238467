import get from "lodash/get"
import axios from "axios"
import { v4 as uuid } from "uuid"
import { URL_CUSTOMER_SUPPORT_LIVE_CHAT } from "constants-lib/urls"
import { userType } from "constants-lib/authentication"
import isMobile from "utils-lib/isMobile"
import { urls } from "utils-lib/builds"
import {
  GET_LIVE_CHAT_FAILED,
  GET_LIVE_CHAT_FULFILLED,
  GET_LIVE_CHAT_PENDING,
  CHANGE_CONTACT_SELECTION,
  CLEAR_CONTACT_SELECTION,
} from "constants-lib/actionTypes"
import { getTokenAction } from "../getToken"

export const myActionCreator = (data) => ({
  type: GET_LIVE_CHAT_FULFILLED,
  payload: data,
})

export const changeContactSelection = (data) => ({
  type: CHANGE_CONTACT_SELECTION,
  payload: data,
})

export const clearSupportData = () => ({
  type: CLEAR_CONTACT_SELECTION,
})

/**
 * Action for getting live chat information.
 * @function getLiveChat
 * @param {string} zipCode - The zip code to use for the API call.
 * @returns {function} dispatches actions to update the store with the results of the API call.
 */
export const getLiveChat = (zipCode) => (dispatch, getState) => {
  const { customerSelections, siteLanguage, userAccount } = getState()

  const userId = get(userAccount, `userDetails.userId`, ``)
  const userState = get(userAccount, `userState`, ``)

  if (zipCode === ``) {
    return ``
  }

  const lang = get(siteLanguage, `language`, `en_CA`)
  const street =
    `${customerSelections.customerAddress.streetNumber} ${customerSelections.customerAddress.streetName}` ||
    ``
  const city = encodeURI(customerSelections.customerAddress.city || ``)
  const state = encodeURI(customerSelections.customerAddress.state || ``)
  const country = encodeURI(customerSelections.customerAddress.country || ``)
  const reqTrckId = uuid()

  const getOptions = {
    url: `${URL_CUSTOMER_SUPPORT_LIVE_CHAT}${zipCode}?state=${state}&country=${country}&postalCode=${zipCode}&lang=${lang}&street=${street}&city=${city}`,
  }

  dispatch({
    type: GET_LIVE_CHAT_PENDING,
  })

  if (userState !== userType.LOGGED_IN) {
    return axios(getOptions)
      .then((response) => dispatch(myActionCreator(response.data)))
      .catch((error) => {
        dispatch({
          type: GET_LIVE_CHAT_FAILED,
        })
        console.error(error)
      })
  }

  return getTokenAction(dispatch, getState).then((token) => {
    if (!zipCode) {
      dispatch({
        type: GET_LIVE_CHAT_FAILED,
      })
      return null
    }
    const api = `CHAT_LOGGED_IN`

    const url = `${urls.url[api]}/${userId}?reqTrckId=${reqTrckId}&zip=${zipCode}&lang=${lang}`

    const getOptions2 = {
      url,
      headers: {
        apiKey: isMobile()
          ? get(urls, `apiKey.USER.CHAT_LOGGED_IN_MOBILE`, ``)
          : get(urls, `apiKey.USER.CHAT_LOGGED_IN`, ``),
        oktaToken: token.accessToken,
      },
    }
    return axios(getOptions2)
      .then((response) => dispatch(myActionCreator(response.data)))
      .catch(() => {
        dispatch({
          type: GET_LIVE_CHAT_FAILED,
        })
      })
  })
}
