import * as ActionTypes from "constants-lib/actionTypes"

export const initialState = {
  bulkFormSelections: {},
  bulkFormHasError: false,
  submitBulkFormStatus: ``,
  bulkPickupLocationData: {},
  getBulkPickupLocationStatus: ``,
  bulkPickupDatesData: [],
  getBulkPickupDatesStatus: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.SET_BULK_FORM_SELECTIONS:
      return {
        ...state,
        bulkFormSelections: action.payload,
      }

    case ActionTypes.SET_BULK_FORM_HAS_ERROR:
      return {
        ...state,
        bulkFormHasError: action.payload,
      }

    case ActionTypes.SUBMIT_BULK_FORM_SELECTIONS_PENDING:
      return {
        ...state,
        submitBulkFormStatus: `pending`,
      }

    case ActionTypes.SUBMIT_BULK_FORM_SELECTIONS_FULFILLED:
      return {
        ...state,
        submitBulkFormStatus: `fulfilled`,
      }

    case ActionTypes.SUBMIT_BULK_FORM_SELECTIONS_FAILED:
      return {
        ...state,
        submitBulkFormStatus: `failed`,
      }

    case ActionTypes.GET_BULK_PICKUP_DATES_PENDING:
      return {
        ...state,
        getBulkPickupDatesStatus: `pending`,
      }

    case ActionTypes.GET_BULK_PICKUP_DATES_FULFILLED:
      return {
        ...state,
        bulkPickupDatesData: action.payload,
        getBulkPickupDatesStatus: `fulfilled`,
      }

    case ActionTypes.GET_BULK_PICKUP_DATES_FAILED:
      return {
        ...state,
        getBulkPickupDatesStatus: `failed`,
      }

    case ActionTypes.GET_BULK_PICKUP_LOCATIONS_PENDING:
      return {
        ...state,
        getBulkPickupLocationStatus: `pending`,
      }

    case ActionTypes.GET_BULK_PICKUP_LOCATIONS_FULFILLED:
      return {
        ...state,
        bulkPickupLocationData: action.payload,
        getBulkPickupLocationStatus: `fulfilled`,
      }

    case ActionTypes.GET_BULK_PICKUP_LOCATIONS_FAILED:
      return {
        ...state,
        getBulkPickupLocationStatus: `failed`,
      }

    default:
      return state
  }
}
