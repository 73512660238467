import {
  PAYMENT_METHODS,
  SAVED_PAYMENT_METHODS,
} from "constants-lib/paymentMethods"

const CARD_CAPPING_AMOUNT = 50000.0
const ACH_CAPPING_AMOUNT = 1000000.0

/**
 * Checks if the provided payment amount is valid.
 * @param {string|number} paymentAmount - The payment amount to be validated.
 * @returns {boolean} - Returns true if the payment amount is valid, otherwise false.
 */
export const isValidPaymentAmount = (paymentAmount) => {
  const paymentAmountWithoutCommas = String(paymentAmount)?.replace(/,/g, "")
  const floatAmount = parseFloat(paymentAmountWithoutCommas)
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(floatAmount) && floatAmount >= 1 && floatAmount <= 999999
}

/**
 * Checks if the entered payment amount is valid for a saved payment.
 *
 * @param {number} enteredAmount - The entered payment amount.
 * @param {string} paymentMethod - The payment method.
 * @param {string} paymentSubtype - The payment subtype.
 * @returns {boolean} - Returns true if the entered payment amount is valid for a saved payment, false otherwise.
 */
export const inValidSavedPaymentAmount = (
  enteredAmount,
  paymentMethod,
  paymentSubtype,
) =>
  parseFloat(enteredAmount?.toString()?.replace(/,/g, "")) >
    CARD_CAPPING_AMOUNT &&
  paymentMethod === SAVED_PAYMENT_METHODS.SAVED.value &&
  paymentSubtype !== PAYMENT_METHODS.ACH

/**
 * Checks if the entered payment amount is valid for card payment method.
 * @param {number} enteredAmount - The entered payment amount.
 * @returns {boolean} - Returns true if the entered amount is valid for card payment method, false otherwise.
 */
export const hideCardPaymentMethod = (enteredAmount) =>
  parseFloat(enteredAmount?.toString()?.replace(/,/g, "")) > CARD_CAPPING_AMOUNT

/**
 * Checks if the entered payment amount is greater than or equal to the ACH capping amount.
 * @param {number|string} enteredAmount - The entered payment amount.
 * @returns {boolean} - Returns true if the entered amount is greater than or equal to the ACH capping amount, otherwise false.
 */
export const amountGreaterThan1M = (enteredAmount) =>
  parseFloat(enteredAmount?.toString()?.replace(/,/g, "")) >= ACH_CAPPING_AMOUNT
