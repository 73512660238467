import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import each from "lodash/each"
import { LOCALE_PATH_MAP } from "constants-lib/common"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

const urls = {
  GUEST_RO_STATUS: `mywm/my-services/status`,
  USER_FORGOT_PASSWORD: `user/forgot-password`,
  USER_REGISTER: `user/register`,
  USER_RO_STATUS: `mywm/user/my-services/status`,
  MYWM_DASHBOARD: `mywm/user/dashboard`,
  MYWM_USER_MYPAYMENT_CHECKOUT: `mywm/user/my-payment/checkout`,
  MYWM_USER_MYPAYMENT_BILLING: `mywm/user/my-payment/billing`,
  MYWM_MYPAYMENT_VERIFY: `mywm/my-payment/verify`,
  MYWM_USER_MYSERVICES: `mywm/user/my-services`,
  MYWM_MANAGE_ACCOUNTS: `mywm/user/accounts`,
  MYWM_PAYMENT_METHODS: `mywm/user/my-payment/method`,
  MYWM_MY_PREFERENCES: `mywm/user/preferences/search`,
  MYWM_PROFILE: `mywm/user/profile`,
  LOGIN: `user/login`,
  ROLLOFF_REQUEST_LINK: `mywm/user/my-services/rolloff-request`,
}

export default (hostName) => {
  const fullUrls = {}
  /*
    Adding current site language to
    urls because when we are redirecting from current web site to legacy website
    same laguguage translations should be displayed on legacy website
  */
  let currentLanguage = window.location.pathname.substr(1, 5)

  /* 
    Because our homepage can load without a language path, we need to determine 
    it based on the browser language where needed so routing to mywm pages 
    doesn't break. If the browser language is something that we don't support, 
    default to /us/en.
  */
  if (isEmpty(currentLanguage)) {
    const browserLanguage = window.navigator.language.replace(`-`, `_`)
    currentLanguage = get(LOCALE_PATH_MAP, browserLanguage, `us/en`).replace(
      /^\//, // remove the leading "/" if present so redirection works
      ``,
    )
  }

  each(urls, (value, key) => {
    if (currentLanguage) {
      fullUrls[key] = `${hostName}/${currentLanguage}/${value}`
    } else {
      fullUrls[key] = `${hostName}/${value}`
    }
  })

  return fullUrls
}
