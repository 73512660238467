import get from "lodash/get"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { LanguageContext } from "contexts-lib/LanguageContext"

export const baseLanguage = `en_CA`

/**
 * Wraps a component with language context, providing it access to the current language and a function to change it.
 * @param {React.ComponentType} WrappedComponent - The component to be enhanced with language context.
 * @returns {React.FunctionComponent} A higher-order component passing through any additional props received.
 */
// eslint-disable-next-line react/display-name
export const withLanguage = (WrappedComponent) => (props) =>
  (
    // ... and renders the wrapped component with the context language!
    // Notice that we pass through any additional props as well
    <LanguageContext.Consumer>
      {({ language, setLanguage }) => (
        <WrappedComponent
          {...props}
          language={language}
          setLanguage={setLanguage}
        />
      )}
    </LanguageContext.Consumer>
  )

/**
 * Provides localization support to a React component by injecting language-specific strings as props.
 * @param {Object} strings - An object containing key-value pairs of language codes and their corresponding translation strings.
 * @returns {Function} A higher-order component (HOC) providing it with localized strings based on the current language setting.
 */
export const withLocalization =
  (strings = {}) =>
  (WrappedComponent) => {
    class WrappedComponentWithLocalization extends Component {
      constructor(props) {
        super(props)

        this.state = {
          // eslint-disable-next-line react/no-unused-state
          component: null,
          // eslint-disable-next-line react/no-unused-state
          currentLanguage: props.language,
          allStrings: strings,
          baseLanguage,
        }
      }

      /* Get localized strings. */
      get getStrings() {
        return {
          /* Load base strings using the base language. */
          ...get(this.state.allStrings, this.state.baseLanguage, {}),
          /* Update base strings with the strings for the selected language. */
          ...get(this.state.allStrings, this.props.language, {}),
        }
      }

      render() {
        return <WrappedComponent strings={this.getStrings} {...this.props} />
      }
    }

    WrappedComponentWithLocalization.propTypes = {
      language: PropTypes.string /* en_CA or fr_CA */,
    }

    WrappedComponentWithLocalization.defaultProps = {
      language: `en_CA`,
    }

    return withLanguage(WrappedComponentWithLocalization)
  }
