import { runMode, getRunMode } from "utils-lib/builds"

/**
 * The `contentUrl` function returns a URL based on the current run mode.
 * @returns The function `contentUrl` returns a URL based on the current run mode.
 */
export const contentUrl = () => {
  switch (getRunMode()) {
    case runMode.LOCAL: {
      return "https://wastemgt-waf-qa-65.adobecqms.net"
    }
    case runMode.QA1: {
      return "https://wmqa1.wm.com"
    }
    case runMode.QA: {
      return "https://wastemgt-waf-qa-65.adobecqms.net"
    }
    case runMode.QA3: {
      return "https://wastemgt-waf-uat-65c.adobecqms.net"
    }
    case runMode.DEV: {
      return "https://wmdev2.wm.com"
    }
    case runMode.DEV1: {
      return "https://wmdev1.wm.com"
    }
    case runMode.DEV3: {
      return "https://wmdev3.wm.com"
    }
    case runMode.CPV: {
      return "https://stage-cdn.wm.com"
    }
    case runMode.UAT: {
      return "https://wastemgt-waf-uat-65c.adobecqms.net"
    }
    case runMode.TEST: {
      return "https://wastemgt-waf-qa-65.adobecqms.net"
    }
    case runMode.PROD: {
      return "https://cdn.wm.com"
    }
    default: {
      return ""
    }
  }
}

export default (url) => contentUrl() + url
