import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import _ from "lodash"
import { urls } from "utils-lib/builds"
import { getTokenAction } from "actions"
import { MOCK_SERVICE_HISTORY } from "utils-lib/mocks"
import { localStorageKey } from "constants-lib/localStorage"
import {
  GET_SERVICE_HISTORY_PENDING,
  GET_SERVICE_HISTORY_FULFILLED,
  GET_SERVICE_HISTORY_FAILED,
} from "constants-lib/actionTypes"

const NUM_OF_DAYS = 180

function handlePending() {
  return {
    type: GET_SERVICE_HISTORY_PENDING,
  }
}

function handleFailed() {
  return {
    type: GET_SERVICE_HISTORY_FAILED,
  }
}

function handleFulfilled(payload) {
  return {
    type: GET_SERVICE_HISTORY_FULFILLED,
    payload,
  }
}

export const getServiceHistory =
  (numOfDays = NUM_OF_DAYS) =>
  (dispatch, getState) => {
    dispatch(handlePending(GET_SERVICE_HISTORY_PENDING))

    const api = `GET_SERVICE_HISTORY`
    const apiKey = _.get(urls, `apiKey.USER.HISTORY_VISUALS`, ``)

    const template = URITemplate(urls.url[api])

    const state = getState()

    const lang = _.get(state, `siteLanguage.language`, ``)
    const userId = _.get(state, `userAccount.userDetails.userId`, ``)

    if (!userId) {
      handleFailed()
      return null
    }

    const serviceKey = _.get(state, `myServices.selectedServiceKey`, ``)

    let chatBotCustomerId = window.localStorage.getItem(
      localStorageKey.SERVICE_HISTORY_CUSTOMER_ID,
    )

    if (chatBotCustomerId) {
      chatBotCustomerId = chatBotCustomerId.replace(/-/g, "")
    }

    let customerId = chatBotCustomerId || serviceKey.split(" ")[0] || ""

    let serviceId =
      window.localStorage.getItem(localStorageKey.SERVICE_HISTORY_SERVICE_ID) ||
      serviceKey.split(" ")[1] ||
      ""

    if (!customerId) return null

    if (!serviceId) {
      const services = _.get(state, `myServices.services`, [])
      const foundService = _.find(
        services,
        (item) => item.ezpayId.indexOf(chatBotCustomerId) !== -1,
      )

      if (foundService) {
        serviceId = _.get(foundService, `services[0].serviceId`, ``)
      }
    }

    while (customerId.length < 15) {
      customerId = `0${customerId}`
    }

    while (serviceId.length < 9) {
      serviceId = `0${serviceId}`
    }

    const url = MOCK_SERVICE_HISTORY
      ? `${window.origin}/mock/serviceHistory.json`
      : template.expand({
          userId,
          lang,
          customerId,
          serviceId,
          numOfDays,
        })

    return getTokenAction(dispatch, getState).then((token) => {
      const config = {
        headers: {
          apiKey,
          oktaToken: token.accessToken,
        },
      }

      return axios
        .create(config)
        .get(url)
        .then((response) => {
          const data = _.get(response, `data.data`, [])

          dispatch(handleFulfilled(data))
          return response
        })
        .catch(() => {
          dispatch(handleFailed(GET_SERVICE_HISTORY_FAILED))
        })
    })
  }
