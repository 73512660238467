import noop from "lodash/noop"
import get from "lodash/get"
import PropTypes from "prop-types"
import {
  getAdobeLaunchAttribute,
  getAdobeLaunchClassName,
} from "utils-lib/analytics/launch"
import "./CustomRadioButton.scss"

export const CustomRadioButton = ({
  className,
  label,
  labelClassName,
  name,
  isChecked,
  onClick,
  size,
  analytics_key,
  children,
  testid,
  disabled,
}) => {
  let clickLabel = ``
  if (typeof analytics_key === `string` && analytics_key !== ``) {
    clickLabel = analytics_key
  } else if (typeof label === `string` || typeof name === `string`) {
    if (name && label) {
      clickLabel = `${name}|${label}`
    } else {
      clickLabel = `${name || label}`
    }
  } else if (typeof children === `string`) {
    clickLabel = children
  } else {
    const child = get(children, `[0]`, ``)
    if (typeof child === `string`) {
      clickLabel = child
    }
  }

  const analyticsClass = getAdobeLaunchClassName({
    disabled: false,
    isSCA: false,
  })

  return (
    <label
      className={`CustomRadioButton ${className} ${size} ${analyticsClass}`}
      {...getAdobeLaunchAttribute({
        disabled: false,
        value: clickLabel,
      })}
      data-testid="CustomRadioButton-Label"
    >
      <input
        data-testid={testid}
        type="radio"
        name={name || `radio`}
        checked={isChecked}
        onChange={noop}
        onClick={onClick}
        tabIndex={disabled ? -1 : 0}
      />
      <span className="radiomark" />
      <span
        data-testid="CustomRadioButton-label"
        className={`CustomRadioButton__label ${labelClassName}`}
      >
        {label}
      </span>
    </label>
  )
}

CustomRadioButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  analytics_key: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  testid: PropTypes.string,
  disabled: PropTypes.bool,
}

CustomRadioButton.defaultProps = {
  className: ``,
  label: ``,
  labelClassName: ``,
  name: ``,
  isChecked: false,
  onClick: () => {},
  size: ``,
  analytics_key: ``,
  testid: "CustomRadioButton",
  children: "",
  disabled: false,
}
