import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  CANCEL_REPAIR_REQUEST_PENDING,
  CANCEL_REPAIR_REQUEST_FAILED,
  CANCEL_REPAIR_REQUEST_FULFILLED,
} from "constants-lib/actionTypes"
import { getTokenAction } from "actions"
import { updateOrderHistoryCache } from "actions/updateOrderHistoryCache"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: CANCEL_REPAIR_REQUEST_PENDING,
})

export const failedAC = () => ({
  type: CANCEL_REPAIR_REQUEST_FAILED,
})

export const fulfilledAC = () => ({
  type: CANCEL_REPAIR_REQUEST_FULFILLED,
})

export const cancelRepairRequest =
  (userId, customerId, orderId, lang) => (dispatch, getState) => {
    const api = `CANCEL_REPAIR`
    const template = URITemplate(urls.url[api])

    const url = template.expand({
      userId,
      customerId,
      orderId,
      lang,
    })

    const config = {
      method: `DELETE`,
      headers: {},
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return cancelRepairRequestHelper(url, config, dispatch)
    })
  }

export const cancelRepairRequestHelper = (url, config, dispatch) => {
  dispatch(pendingAC())

  return axios(url, config)
    .then((response) => {
      dispatch(updateOrderHistoryCache(response))
      dispatch(fulfilledAC())
      return response
    })
    .catch(() => {
      dispatch(failedAC())
    })
}
