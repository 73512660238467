import { MC_SET_SELECTED_ACCOUNT } from "constants-lib/actionTypes"

const initialState = {}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case MC_SET_SELECTED_ACCOUNT: {
      const { customerId } = action.payload
      return {
        ...state,
        selectedCustomerId: customerId,
      }
    }
    default:
      return state
  }
}
