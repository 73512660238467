import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { handleEnterPress } from "utils-lib/handleEnterPress"
import {
  getAdobeLaunchAttribute,
  getAdobeLaunchClassName,
} from "utils-lib/analytics/launch"
import { updateClicksDL } from "utils-lib/analytics/dataLayer"
import { isPdfUrl } from "utils-lib/validation/validateHashTagUrl"
import "./Link.scss"
import singletonRouter from "next/router"
import { isNextApp } from "utils-lib/isNextApp"
import { Link as ReactRouterLink } from "react-router-dom"

export const Link = ({
  analytics_key,
  onClick,
  onKeyDown,
  href,
  children,
  backgroundClass,
  className,
  overrideClass,
  style,
  shouldNotTrackInComponent,
  isExternalLink,
  isDownloadLink,
  isOpenInNewTab,
  disabled,
  tabIndex,
  ariaLabel,
  useWhiteLink,
  useUnderlineHover,
  isSCA,
  scaValues,
  removeUpperCase,
  setSCAValues,
  elementType,
  target,
  testid,
  forceHideUnderline,
  ...rest
}) => {
  const [showUnderline, setShowUnderline] = useState(false)
  const nextLinkRef = useRef(null)

  const hasHash = href?.indexOf("#") !== -1

  const cleanedHref = href?.replace("/content/wm", "")

  useEffect(() => {
    const nextLink = nextLinkRef.current
    if (nextLink) {
      nextLink.addEventListener("click", handleNavigation)
      nextLink.addEventListener("keydown", handleNextKeyDown)
      nextLink.addEventListener("mouseenter", handleHover)
      nextLink.addEventListener("focus", handleHover)
    }
  }, [nextLinkRef])

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    // cleanup event listeners
    return () => {
      const nextLink = nextLinkRef.current

      if (nextLink) {
        nextLink.removeEventListener("click", handleNavigation)
        nextLink.removeEventListener("keydown", handleNextKeyDown)
        nextLink.removeEventListener("mouseenter", handleNavigation)
        nextLink.removeEventListener("click", handleNavigation)
      }
    }
  }, [])

  let clickLabel = ``
  if (typeof analytics_key === `string` && analytics_key !== ``) {
    clickLabel = analytics_key
  } else if (typeof children === `string`) {
    clickLabel = children
  } else {
    const child = get(children, `[0]`, ``)
    if (typeof child === `string`) {
      clickLabel = child
    }
  }

  const analyticsClass = getAdobeLaunchClassName({
    disabled: shouldNotTrackInComponent,
    isSCA,
  })

  const linkProps = {
    "data-testid": testid,
    className: overrideClass
      ? `${overrideClass} ${analyticsClass} ${backgroundClass}`
      : `Link ${analyticsClass} btn ${
          useWhiteLink ? `white-link` : `wm-link`
        } ${backgroundClass} ${className} ${
          useUnderlineHover ? `underline-hover` : ``
        }`,
    style: {
      ...style,
      ...{ decoration: forceHideUnderline ? `none !important` : `` },
    },
    disabled,
    tabIndex,
    ...(useUnderlineHover
      ? {
          onMouseOver: () => {
            setShowUnderline(true)
          },
          onMouseOut: () => {
            setShowUnderline(false)
          },
        }
      : {}),
    ...getAdobeLaunchAttribute({
      disabled: shouldNotTrackInComponent,
      value: clickLabel,
    }),
    onKeyUp: (e) => {
      handleEnterPress(e, () => {
        if (!shouldNotTrackInComponent) {
          updateClicksDL({
            ui_element: elementType,
            object_content: clickLabel,
          })

          if (isSCA) {
            setSCAValues(scaValues)
          }
        }
        onClick(e)
      })
    },
    onClick: (e) => {
      /* Tag the link click. */
      if (!shouldNotTrackInComponent) {
        updateClicksDL({
          ui_element: elementType,
          object_name: ``,
          object_content: clickLabel,
        })

        if (isSCA) {
          setSCAValues(scaValues)
        }
      }
      onClick(e)
    },
    ...(ariaLabel !== `` ? { "aria-label": ariaLabel } : {}),
    ...rest,
  }

  async function handleNextKeyDown(e) {
    handleEnterPress(e, () => {
      handleNavigation(e)
      linkProps.onKeyUp()
    })
  }

  async function handleNavigation(e) {
    if (!hasHash) {
      e.preventDefault()

      await singletonRouter.push(cleanedHref)

      // this is a hack, but there is an infrequent and hard to reproduce edge case with next router that I cannot find a root cause for which causes links to go "dead" on click
      setTimeout(() => {
        // the window.location.pathname hasn't been updated after router.push, this link is now "dead", trigger a refresh
        if (cleanedHref !== window.location.pathname) {
          // added this error log so we can track if we are actually hitting this edge case after my fix, I don't think we are but added this as a failsafe just incase
          console.error(
            `Router edge case occurred navigating to route: ${cleanedHref}`,
          )
          window.location.pathname = cleanedHref
        }
      }, 200)

      // handles closing the meganav
      linkProps.onClick()
    } else {
      await singletonRouter.push(cleanedHref)
    }
  }

  async function handleHover() {
    singletonRouter.prefetch(cleanedHref)
  }

  // This allows us to support next js apps without stuffing react router into them
  const nextCompatLinkProps = {
    ...linkProps,
    ...getAdobeLaunchAttribute({
      disabled: shouldNotTrackInComponent,
      value: clickLabel,
    }),
    onKeyDown,
    target: target !== `_self` ? `_blank` : target,
  }

  let linkComponent = null
  if (isNextApp && !isEmpty(href)) {
    if (!isExternalLink) {
      linkComponent = (
        <a href={cleanedHref} ref={nextLinkRef} {...nextCompatLinkProps}>
          {children}
        </a>
      )
    } else {
      linkComponent = (
        <a href={cleanedHref} {...nextCompatLinkProps}>
          {children}
        </a>
      )
    }
  } else {
    nextCompatLinkProps.to = href

    linkComponent = href ? (
      isExternalLink === false ? (
        <ReactRouterLink {...nextCompatLinkProps}>{children}</ReactRouterLink>
      ) : (
        <a
          {...linkProps}
          download={isDownloadLink}
          href={href}
          rel="noopener noreferrer"
          target={isPdfUrl(href) || target !== `_self` ? `_blank` : target}
          {...(isOpenInNewTab && {
            target: `_blank`,
            rel: "noopener noreferrer",
          })}
        >
          {children}
        </a>
      )
    ) : (
      <button {...linkProps} type="button">
        {children}
      </button>
    )
  }

  return useUnderlineHover ? (
    <div>
      {linkComponent}
      {showUnderline && <div className="LinkHoverUnderline" />}
    </div>
  ) : (
    linkComponent
  )
}

Link.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  href: PropTypes.string,
  isExternalLink: PropTypes.bool,
  isDownloadLink: PropTypes.bool,
  isOpenInNewTab: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  overrideClass: PropTypes.string,
  backgroundClass: PropTypes.string,
  analytics_key: PropTypes.string,
  shouldNotTrackInComponent: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
  ariaLabel: PropTypes.string,
  useWhiteLink: PropTypes.bool,
  useUnderlineHover: PropTypes.bool,
  isSCA: PropTypes.bool,
  scaValues: PropTypes.object,
  removeUpperCase: PropTypes.bool,
  setSCAValues: PropTypes.func.isRequired,
  forceHideUnderline: PropTypes.bool,
  elementType: PropTypes.string,
  target: PropTypes.string,
  testid: PropTypes.string,
}

Link.defaultProps = {
  children: "",
  style: {},
  onClick: () => {},
  onKeyDown: () => {},
  href: ``,
  isExternalLink: false,
  isDownloadLink: false,
  isOpenInNewTab: false,
  className: ``,
  overrideClass: ``,
  backgroundClass: `bg-transparent`,
  analytics_key: ``,
  shouldNotTrackInComponent: false,
  disabled: false,
  tabIndex: `0`,
  ariaLabel: ``,
  useWhiteLink: false,
  useUnderlineHover: false,
  isSCA: false,
  scaValues: {},
  removeUpperCase: false,
  elementType: `Link`,
  target: "_self",
  testid: "Link",
  forceHideUnderline: false,
}

export default Link
