import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.RESET_PASSWORD_PENDING,
})

export const failedAC = (errorCode) => ({
  type: ActionTypes.RESET_PASSWORD_FAILED,
  payload: errorCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.RESET_PASSWORD_FULFILLED,
  payload: data,
})

export const resetPassword =
  (token, data, updateType) => (dispatch /* ,  getState */) => {
    let api = `RESET_PASSWORD`
    if (updateType === `completeRegistration`) {
      api = `COMPLETE_REGISTRATION`
    }
    const resetPasswordUrl = urls.url[api]
    dispatch(pendingAC())

    const config = {
      headers: { token },
    }

    const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
    if (apiKey !== ``) {
      config.headers.apiKey = apiKey
    }

    return axios
      .create(config)
      .post(resetPasswordUrl, data)
      .then((response) => {
        dispatch(fulfilledAC(response.data))
        return response
      })
      .catch((error) => {
        dispatch(failedAC(_.get(error, `response.status`, ``)))
      })
  }
