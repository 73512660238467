import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import { getTokenAction } from "actions"
import _ from "lodash"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.GET_BILLING_DETAILS_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.GET_BILLING_DETAILS_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_BILLING_DETAILS_FULFILLED,
  payload: data,
})

export const getBillingDetails = () => (dispatch, getState) => {
  const state = getState()
  dispatch(pendingAC())
  const config = {
    params: {
      lang: _.get(state, `siteLanguage.language`, `en_CA`),
    },
  }

  const api = `USER_BILLING_DETAILS`
  const template = URITemplate(urls.url[api])

  const url = template.expand({
    userId: _.get(state, `userAccount.userDetails.userId`, ``),
    accountId: _.get(
      state,
      `userManageAccount.userAccountDetails[0].custAccountId`,
      ``,
    ),
  })

  const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
  return getTokenAction(dispatch, getState).then((token) => {
    if (apiKey !== ``) {
      config.headers = {
        apikey: apiKey,
        oktatoken: token.accessToken,
      }
    }

    return axios
      .create(config)
      .get(url)
      .then((response) => {
        dispatch(fulfilledAC(response.data))
        return response.data
      })
      .catch((error) => {
        dispatch(failedAC(_.get(error, `response.status`, ``)))
        return error
      })
  })
}
