import * as ActionTypes from "constants-lib/actionTypes"

export const makeMinorAlertVisible = () => ({
  type: ActionTypes.MINOR_ALERT_VISIBLE,
})

export const minorAlertNotVisible = (title) => ({
  type: ActionTypes.MINOR_ALERT_NOT_VISIBLE,
  payload: {
    title,
  },
})
