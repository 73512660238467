import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"
import { updateCustomerListDL } from "utils-lib/analytics"
import { YES } from "constants-lib/common"

export const pendingAC = () => ({
  type: ActionTypes.FETCH_AUTOPAY_PAPERLESS_ATTRIBUTES_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.FETCH_AUTOPAY_PAPERLESS_ATTRIBUTES_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.FETCH_AUTOPAY_PAPERLESS_ATTRIBUTES_FULFILLED,
  payload: data,
})

export const singleAccountFulfilledAC = (data) => ({
  type: ActionTypes.FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT_FULFILLED,
  payload: data,
})

export const singleAccountFailedAC = (data) => ({
  type: ActionTypes.FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT_FAILED,
  payload: data,
})

export const singleAccountPendingAC = (data) => ({
  type: ActionTypes.FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT_PENDING,
  payload: data,
})

export const translateResponses = (accounts) => {
  const attributesDetailsByAccount = {}
  accounts?.forEach((account) => {
    attributesDetailsByAccount[account.ezpayId] = {
      autopayStatus: account.autopay === YES,
      paperlessStatus: account.paperless === YES,
      billingFrequency: account.billingFrequency,
      invoiceAttachment: account.invoiceAttachment,
    }
  })

  return {
    attributesDetailsByAccount,
  }
}

export const fetchAutopayAndPaperlessAttributes =
  (isGuest) => (dispatch, getState) => {
    dispatch(pendingAC())

    const state = getState()
    const lang = _.get(state, `siteLanguage.language`, `en_CA`)

    if (isGuest) {
      const api = `AUTOPAY_PAPERLESS_ATTRIBUTES`
      const template = URITemplate(urls.url[api])

      const token = _.get(state, `myPaymentVerification.verificationToken`, ``)
      const customerId = _.get(state, `customerSelections.customerId`, ``)

      const url = template.expand({ customerId })

      const config = {
        headers: {
          token,
          apiKey: _.get(urls, `apiKey.USER[${api}]`, ``),
        },
        params: {
          lang,
          // Prevents IE caching
          uniqueId: _.uniqueId(),
        },
      }

      return axios.create(config).get(url)
    }

    const userId = _.get(state, `userAccount.userDetails.userId`, ``)
    return getTokenAction(dispatch, getState)
      .then((res) =>
        fetchAutopayAndPaperlessAttributesHelper(
          {
            token: res.accessToken,
            userId,
            lang,
          },
          dispatch,
          getState,
        ),
      )
      .catch((res) => {
        dispatch(failedAC())
        return res
      })
  }

export const fetchAutopayAndPaperlessAttributesHelper = (
  { token, userId, lang },
  dispatch,
  getState,
) => {
  const api = `BULK_AUTOPAY_PAPERLESS_ATTRIBUTES`
  const template = URITemplate(urls.url[api])

  const url = template.expand({ userId, lang })
  const config = {
    headers: {
      oktaToken: token,
      apiKey: _.get(urls, `apiKey.USER[${api}]`, ``),
    },
    params: {
      // Prevents IE caching
      uniqueId: _.uniqueId(),
    },
  }

  return axios
    .create(config)
    .get(url)
    .then((response) => {
      const data = response.data.data
      const { attributesDetailsByAccount } = translateResponses(data)
      dispatch(
        fulfilledAC({
          attributesDetailsByAccount,
          actualResponse: response.data,
        }),
      )
      updateCustomerListDL({
        userManageAccount: _.get(getState(), `userManageAccount`, {}),
        customerAutoPayPaperless: _.get(
          getState(),
          `customerAutoPayPaperless`,
          {},
        ),
      })

      return attributesDetailsByAccount
    })
    .catch(() => {
      dispatch(failedAC())
      return false
    })
}

export const fetchAttributesForSingleAccount =
  (customerId) => (dispatch, getState) => {
    const api = `AUTOPAY_PAPERLESS_ATTRIBUTES`
    dispatch(singleAccountPendingAC())

    const template = URITemplate(urls.url[api])
    const url = template.expand({ customerId })

    return getTokenAction(dispatch, getState)
      .then((token) => {
        const state = getState()
        const config = {
          headers: {
            oktaToken: token.accessToken,
          },
          params: {
            userId: _.get(state, `userAccount.userDetails.userId`, ``),
            lang: state.siteLanguage.language,
          },
        }

        const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }

        return axios
          .create(config)
          .get(url)
          .then((response) => {
            const attributesDetailsByAccount = {}
            const attributesForAccount = _.get(response, `data.data`, {})
            attributesDetailsByAccount[customerId] = {
              autopayStatus: attributesForAccount.autopay === YES,
              paperlessStatus: attributesForAccount.paperless === YES,
              billingFrequency: attributesForAccount.billingFrequency,
              invoiceAttachment: attributesForAccount.invoiceAttachment,
            }
            dispatch(singleAccountFulfilledAC(attributesDetailsByAccount))
            return response
          })
      })
      .catch((response) => {
        dispatch(
          singleAccountFailedAC({
            statusCode: _.get(response, `response.status`, ``),
            errorMessage: _.get(response, `response.data.Error`, ``),
          }),
        )

        return response
      })
  }
