import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import _ from "lodash"
import { urls } from "utils-lib/builds"
import { getTokenAction } from "actions"
import { MOCK_EXTRA_PICKUP_INFORMATION } from "utils-lib/mocks"
import {
  GET_EXTRA_PICKUP_DATES_PENDING,
  GET_EXTRA_PICKUP_DATES_FULFILLED,
  GET_EXTRA_PICKUP_DATES_FAILED,
} from "constants-lib/actionTypes"

export const pendingAC = () => ({
  type: GET_EXTRA_PICKUP_DATES_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: GET_EXTRA_PICKUP_DATES_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: GET_EXTRA_PICKUP_DATES_FULFILLED,
  payload: data,
})

export const getExtraPickupDates =
  (customerId, serviceId) => (dispatch, getState) => {
    dispatch(pendingAC(GET_EXTRA_PICKUP_DATES_PENDING))

    const api = `EXTRA_PICKUP_DATES`
    const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

    const template = URITemplate(urls.url[api])

    const state = getState()

    const url = MOCK_EXTRA_PICKUP_INFORMATION
      ? `${window.origin}/mock/extra-pickup-dates-response.json`
      : template.expand({
          lang: _.get(state, `siteLanguage.language`, ``),
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
          customerId,
          serviceId,
        })

    return getTokenAction(dispatch, getState).then((token) => {
      const config = {
        headers: {
          apiKey,
          oktaToken: token.accessToken,
        },
      }

      return axios
        .create(config)
        .get(url)
        .then((response) => {
          // handle pickup dates
          dispatch(fulfilledAC(response.data))
          return response.data
        })
        .catch(() => {
          dispatch(failedAC(GET_EXTRA_PICKUP_DATES_FAILED))
        })
    })
  }
