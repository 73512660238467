import _ from "lodash"
import * as ActionTypes from "constants-lib/localSites"
import { lob as LOB, LINE_OF_BUSINESS } from "constants-lib/common"

// Must be a pure function that requires the state
// and the action being dispatched.
export const initialState = {
  currentFetchState: ``,
  lob: ``,
}

export default (state = initialState, action) => {
  switch (action.type) {
    // case ActionTypes.INIT_STATE:
    //   return initialState

    // case ActionTypes.RESET_MAS_DATA_STATE:
    //   return initialState

    case ActionTypes.GET_LOCALSITES_MAS_REJECTED:
      return {
        currentFetchState: `rejected`,
        error: action.payload,
      }

    case ActionTypes.GET_LOCALSITES_MAS_PENDING:
      return {
        currentFetchState: `pending`,
      }

    case ActionTypes.GET_LOCALSITES_MAS_FULFILLED: {
      const masLob = _.get(action, `payload.customers[0].line_of_business`, ``)
      let lob = ``
      if (masLob === LINE_OF_BUSINESS.RESIDENTIAL) {
        lob = LOB.HOME
      } else if (masLob !== ``) {
        lob = LOB.BUSINESS
      }

      return {
        currentFetchState: `fulfilled`,
        ...action.payload,
        lob,
      }
    }

    // case ActionTypes.LOGGED_IN:
    //   return initialState

    default:
      return state
  }
}
