import axios from "axios"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { urls } from "utils-lib/builds"
import { resendEmail } from "actions-lib/resendEmail"
import { userType } from "constants-lib/authentication"
import {
  getSessionTokenFromData,
  getLoginStatus,
  getExceptionLoginStatus,
  getUserDetailsFromData,
  loginStatusCode,
  logRocketIdentifyFailedLoggedInUser,
  logRocketIdentifyLoggedInUser,
  redirectLoggedInUser,
} from "utils-lib/userAccount/login"
import { tagLoginFailure, tagLoginSuccess } from "utils-lib/analytics/login"
import { setToken } from "actions-lib/setToken"
import { setUserState } from "actions-lib/setUserState"
import { setUserDetails } from "actions-lib/setUserDetails"
import { clearNotifications } from "actions-lib/clearNotifications"
import { authClient } from "constants-lib/okta"
import {
  AUTHENTICATE_USER_PENDING,
  AUTHENTICATE_USER_FULFILLED,
  AUTHENTICATE_USER_FAILED,
} from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export const pendingAC = () => ({
  type: AUTHENTICATE_USER_PENDING,
})

export const failedAC = (status) => ({
  type: AUTHENTICATE_USER_FAILED,
  payload: status,
})

export const fulfilledAC = (data) => ({
  type: AUTHENTICATE_USER_FULFILLED,
  payload: data,
})

/*
  Verifies that the username (email) and password are valid.
  If so, pass the returned session token to getWithoutPrompt to:
    1. Obtain an access token, and
    2. Begin a session in the browser (session cookie will get generated)
  After starting the session, redirect the user to the authenticated page.
*/
/**
 * Authenticates a user and dispatches actions based on the response.
 * @param {Object} options - Options for the authentication process.
 * @param {Function} [options.callback=() => {}] - Function to be called after authentication, defaults to a no-op function.
 * @param {boolean} [options.disablePostLoginRedirect=false] - Flag to disable redirection after login, defaults to false.
 * @param {string} [options.redirectUrl=""] - URL to redirect to after login, defaults to an empty string.
 * @param {string} options.username - Username for authentication.
 * @param {string} options.password - Password for authentication.
 * @param {Function} [options.beforeRedirect] - Function to be called before redirection, if redirection is enabled.
 * @returns {Promise<Object>} A promise that resolves to an object containing the authentication outcome.
 */
export const authenticateUser =
  ({
    callback = () => {},
    disablePostLoginRedirect = false,
    redirectUrl = ``,
    ...options
  }) =>
  async (dispatch, getState) => {
    dispatch(pendingAC())

    const api = `AUTHENTICATE_USER`
    const url = urls.url[api]
    const apiKey = get(urls, `apiKey.USER[${api}]`, ``)
    const state = getState()
    const locale = get(state, `siteLanguage.language`, `en_US`)

    const headers = {
      "Content-Type": `application/json`,
      apiKey,
    }

    let response = {}
    try {
      /* 
      This has been refactored to use async/await because of syncronous issues with existing code. 
      An edge case scenario that users were running into is that they were receiving the "technical difficulties"
      error message when the auth request succeeded with a 200 because the status code was === UNKNOWN_LOGIN_STATUS: 154.
      To fix this, we await the response, and then we await the loginStatus before we do anything with it.
    */
      response = await axios.post(
        url,
        {
          username: options.username,
          password: options.password,
          locale,
        },
        { headers },
      )
    } catch (error) {
      const loginStatus = getExceptionLoginStatus(error)
      tagLoginFailure(loginStatus, options.username, error)

      dispatch(failedAC(loginStatus))
      callback(loginStatus)
      return loginStatus
    }

    await dispatch(fulfilledAC(response.data))

    const loginStatus = await getLoginStatus(response)

    if (loginStatusCode.SUCCESS === loginStatus) {
      await logRocketIdentifyLoggedInUser(response)

      /* User entered valid credentials, so start Okta session by getting an access token. */
      const sessionToken = await getSessionTokenFromData(response)

      try {
        const token = await authClient.token.getWithoutPrompt({
          responseType: `token`,
          sessionToken,
        })

        // Okta token object has changed the structure, but to be safe we are checking the old structure as well in case this changes again
        const accessToken = !isEmpty(token?.accessToken)
          ? token
          : token?.tokens?.accessToken

        await tagLoginSuccess(loginStatus, response)

        await dispatch(setToken(accessToken))
        await dispatch(
          setUserDetails(getUserDetailsFromData(response, getState())),
        )
        await dispatch(setUserState(userType.LOGGING_IN))

        await dispatch(clearNotifications())

        /* Todo - update app to handle logged in state without relying on a page reload. */
        if (disablePostLoginRedirect) {
          const isPrefCenterOrManageContacts =
            /.+\/mywm\/user\/(contacts\/verify|preferences\/search)/.test(
              window.location.pathname,
            )
          // behavior is different for pref center and manage contacts login, so make sure we are able to fetch linked accounts without a page refresh
          if (isPrefCenterOrManageContacts) {
            await dispatch(setUserState(userType.LOGGED_IN))
          } else {
            window.location.reload()
          }
        } else if (options.beforeRedirect) {
          await options.beforeRedirect()
          redirectLoggedInUser(locale, redirectUrl)
        } else {
          redirectLoggedInUser(locale, redirectUrl)
        }
      } catch (error) {
        await tagLoginFailure(
          loginStatusCode.GET_WITHOUT_PROMPT_FAIL,
          options.username,
          error,
        )
        callback(loginStatusCode.GET_WITHOUT_PROMPT_FAIL)
      }
    } else {
      await logRocketIdentifyFailedLoggedInUser(options.username)

      if (loginStatusCode.UNCONFIRMED_EMAIL === loginStatus) {
        /* User hasn't confirmed their email, so resend the email.
          User Authenticate API returns this status whether or not the user entered PW is correct.
          Do not resend the email if user is just toggling the language setting. */
        await dispatch(
          resendEmail({
            profile: {
              login: options.username,
            },
            locale,
          }),
        )
      }

      await callback(loginStatus)
    }

    return loginStatus
  }
