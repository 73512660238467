import get from "lodash/get"
import * as ActionTypes from "constants-lib/actionTypes"
import { userType } from "constants-lib/authentication"

const initialState = {
  /* Payment capture */
  postStatus: ``,
  saleResponseErrorMessage: ``,
  isApplePayAutoPayEnrolled: false,
  isApplePayConfirm: false,
  isApplePayScheduled: false,
  isDuplicatePayment: false,
  paymentMethod: ``,
  paymentStatus: ``,
  confirmationNumber: ``,
  lastPaymentAmount: `0.00`,
  /* Schedule payment capture */
  createScheduledPaymentStatus: ``,
  scheduledState: ``,
  scheduledConfirmationNumber: ``,
  deleteScheduledPaymentStatus: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    /* Payment capture */
    case ActionTypes.GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_PENDING:
      return {
        ...state,
        postStatus: `pending`,
        myPaymentConfirm: {},
      }

    case ActionTypes.GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_FULFILLED:
    case ActionTypes.GET_MY_WM_BROKER_PAYMENT_CAPTURE_FULFILLED:
      return {
        ...state,
        ...action.payload,
        postStatus: `fulfilled`,
      }
    case ActionTypes.GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_FAILED:
      return {
        ...state,
        saleResponseErrorMessage: action.payload.errorMessage,
        postStatus: `failed`,
      }
    /* Schedule Payment Capture */
    case ActionTypes.CREATE_SCHEDULE_PAYMENT_PENDING:
      return {
        ...state,
        createScheduledPaymentStatus: `pending`,
      }
    case ActionTypes.CREATE_SCHEDULE_PAYMENT_FULFILLED:
      return {
        scheduledConfirmationNumber: get(
          action.payload,
          `scheduledConfirmationNumber`,
          ``,
        ),
        createScheduledPaymentStatus: `fulfilled`,
        scheduledState: `SCHEDULED`,
      }
    case ActionTypes.CREATE_SCHEDULE_PAYMENT_FAILED:
      return {
        ...state,
        saleResponseErrorMessage: action.payload.errorMessage,
        createScheduledPaymentStatus: `failed`,
      }

    case ActionTypes.DELETE_SCHEDULED_PAYMENT_PENDING:
      return {
        ...state,
        deleteScheduledPaymentStatus: `pending`,
      }
    case ActionTypes.CLEAR_DELETE_SCHEDULED_PAYMENT_STATUS:
      return {
        ...state,
        deleteScheduledPaymentStatus: ``,
      }
    case ActionTypes.DELETE_SCHEDULED_PAYMENT_FULFILLED:
      return {
        scheduledConfirmationNumber: get(
          action.payload,
          `data.data.confirmationNumber`,
          ``,
        ),
        createScheduledPaymentStatus: ``,
        deleteScheduledPaymentStatus: `fulfilled`,
        scheduledState: `DELETED`,
      }
    case ActionTypes.DELETE_SCHEDULED_PAYMENT_FAILED:
      return {
        ...state,
        saleResponseErrorMessage: action.payload.errorMessage,
        deleteScheduledPaymentStatus: `failed`,
      }
    case ActionTypes.CLEAR_CREATE_SCHEDULED_PAYMENT_STATUS:
      return {
        ...state,
        createScheduledPaymentStatus: ``,
        postStatus: ``,
      }

    /* Payment duplicate */
    case ActionTypes.GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_DUPLICATE:
      return {
        ...state,
        isDuplicatePayment: action.payload,
      }

    /* Initialize state */
    case ActionTypes.GET_MY_WM_PAYMENT_SERVICES_PENDING:
    case ActionTypes.GET_MY_WM_PAYMENT_SIGNATURE_PENDING:
    case ActionTypes.GET_MY_WM_PAYMENT_VERIFICATION_PENDING:
    case ActionTypes.GET_MY_WM_PAYMENT_CANCEL_TRANSACTION:
    case ActionTypes.RESET_MY_WM_PAYMENT_INFO:
    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
    case ActionTypes.GET_USER_PAYMENT_METHODS_PENDING:
      return initialState

    case ActionTypes.SET_USER_STATE: {
      /* Clear state after logging in. */
      if (action.payload === userType.LOGGED_IN) {
        return {
          ...initialState,
        }
      }
      return {
        ...state,
      }
    }

    case ActionTypes.SET_APPLE_PAY_CONFIRM:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
