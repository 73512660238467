import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

/**
 * Determines whether a URL is external if it contains a hash tag and is not a top-level page.
 *
 * @function
 * @param {string} url - The URL to be validated.
 * @returns {boolean} A boolean value indicating whether the specified URL is external.
 */
export const validateHashTagUrl = (url) => {
  let isExternalLinking = false
  const index = url.indexOf(`#`)
  if (index !== -1) {
    // #tag is there in the url
    if (index !== 0) {
      // #tag with AEM external url
      isExternalLinking = true
    } else if (window.location.pathname.split(`/`).length > 4) {
      /* if length is > 4 means it a L2 or L3 page url
      L2&L3 url are not supported with #tags hence reloading the page */
      isExternalLinking = true
    }
  }
  return isExternalLinking
}

/**
 * Determines whether a URL is a PDF file.
 *
 * @function
 * @param {string} url - The URL of the file to check.
 * @returns {boolean} A boolean value indicating whether the specified URL is a PDF file.
 */
export const isPdfUrl = (url) => {
  const fileExt = url?.split(`.`).pop()
  return fileExt === `pdf`
}
