import getLanguageRoute from "utils-lib/getLanguageRoute"
import * as ActionTypes from "constants-lib/actionTypes"
import {
  resetRollOffTicketDetails,
  setMyServicesMain,
  setMyWMCustomerID,
  setMyWMDropdownItem,
  setUseMaskedCustomerID,
  setUsePaymentCustomerID,
  setCustomerAccountSource,
} from "actions"

export const resetAC = () => ({
  type: ActionTypes.RESET_MYSERVICES_DASHBOARD,
})

export const resetMyServicesDashboard =
  (history, requireLogin = false) =>
  (dispatch, getState) => {
    dispatch(resetAC())
    dispatch(resetRollOffTicketDetails())
    dispatch(setMyWMDropdownItem(``))
    dispatch(setMyWMCustomerID(``))
    dispatch(setUseMaskedCustomerID(false))
    dispatch(setUsePaymentCustomerID(false))
    dispatch(setMyServicesMain(`GettingStarted`))
    dispatch(setCustomerAccountSource(``))

    // Redirect to Locate page.
    const state = getState()
    const activeLanguage = state.siteLanguage.language
    const languageRoute = getLanguageRoute(activeLanguage)
    const url = requireLogin
      ? `${languageRoute}/mywm/user/locate`
      : `${languageRoute}/mywm/locate`
    history.push(url)
  }
