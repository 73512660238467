import * as ActionTypes from "constants-lib/actionTypes"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import _ from "lodash"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.GET_PICKUP_INFO_PENDING,
})

export const failedAC = () => ({
  type: ActionTypes.GET_PICKUP_INFO_FAILED,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_PICKUP_INFO_FULFILLED,
  payload: data,
})

export const getPickupInfo = (data, locale) => (dispatch) => {
  const api = `PICKUP_INFO`
  const template = URITemplate(urls.url[api])

  const splitData = data?.split(` `)
  const ezpayId = splitData[0]
  const serviceId = splitData[1]
  const customerPickupInfoUrl = template.expand({
    ezpayId,
    serviceId,
    locale,
  })
  dispatch(pendingAC())
  return axios
    .create({
      headers: {
        apiKey: _.get(urls, `apiKey.GUEST[${api}]`, ``),
      },
    })
    .get(customerPickupInfoUrl)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      return response
    })
    .catch(() => {
      dispatch(failedAC())
    })
}
