import { SET_NO_FRENCH_CONTENT } from "constants-lib/actionTypes"

export function setNoFrenchContent(noFrenchContent) {
  return {
    type: SET_NO_FRENCH_CONTENT,
    payload: {
      noFrenchContent,
    },
  }
}
