import PropTypes from "prop-types"
import { withLocalization } from "hoc-lib/localization"
import withRouter from "hoc-lib/router"
import getLanguageRoute from "utils-lib/getLanguageRoute"
import { GenericModal } from "../../index"

export const strings = {
  en_CA: {
    LOG_OUT_MODAL_EXPIRED_SESSION_MAIN_TEXT: `You have been logged out.`,
    LOG_OUT_MODAL_EXPIRED_SESSION_HELP_TEXT: `For your security, we have logged you out due to inactivity.`,
    CLOSE: `Close`,
    LOG_BACK: `Log Back in`,
  },
  fr_CA: {
    LOG_OUT_MODAL_EXPIRED_SESSION_MAIN_TEXT: `Vous avez été déconnecté`,
    LOG_OUT_MODAL_EXPIRED_SESSION_HELP_TEXT: `Pour votre sécurité, nous vous avons déconnecté pour cause d'inactivité.`,
    CLOSE: `Fermer`,
    LOG_BACK: `Se reconnecter`,
  },
}

export const LogOutModal = ({
  router,
  language,
  strings,
  onClose,
  isVisible,
}) => {
  const onLogBackInClick = () => {
    onClose()
    router.push(`${getLanguageRoute(language)}/user/login`)
  }

  return (
    <GenericModal
      open={isVisible}
      onClose={onClose}
      icon="/content/dam/mywm/src-img/my-services/icon_time_out.svg"
      title={strings.LOG_OUT_MODAL_EXPIRED_SESSION_MAIN_TEXT}
      body={strings.LOG_OUT_MODAL_EXPIRED_SESSION_HELP_TEXT}
      primaryCTA={strings.CLOSE}
      primaryOnClick={onClose}
      secondaryCTA={strings.LOG_BACK}
      secondaryOnClick={onLogBackInClick}
      modalTestingId="LogOutModal"
      secondaryTestingId="LogOutModal-log-back-in-button"
    />
  )
}

LogOutModal.propTypes = {
  router: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
}

LogOutModal.defaultProps = {
  isVisible: true,
}

export default withRouter(withLocalization(strings)(LogOutModal))
