import omit from "lodash/omit"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import PropTypes from "prop-types"
import {
  getAdobeLaunchAttribute,
  getAdobeLaunchClassName,
} from "utils-lib/analytics/launch"
import { updateClicksDL } from "utils-lib/analytics/dataLayer"

export const Button = (
  {
    analytics_key,
    children,
    className,
    disabled,
    id,
    name,
    onClick,
    onKeyDown,
    onMouseOver,
    onMouseLeave,
    style,
    type,
    href,
    fontClass,
    overrideClass,
    shouldNotTrackInComponent,
    ariaLabel,
    ariaHasPopup,
    ariaPressed,
    ariaDescribedBy,
    ariaExpanded,
    useDiv,
    useAnchor,
    isSCA,
    scaValues,
    setSCAValues,
    elementType,
    testid,
  },
  ...rest
) => {
  const additionalProps = {}
  if (id !== ``) {
    additionalProps.id = id
  }

  let clickLabel = ``
  if (typeof analytics_key === `string` && analytics_key !== ``) {
    clickLabel = analytics_key
  } else if (typeof children === `string`) {
    clickLabel = children
  } else {
    const child = get(children, `[0]`, ``)
    if (typeof child === `string`) {
      clickLabel = child
    }
  }

  const analyticsClass = getAdobeLaunchClassName({
    disabled: shouldNotTrackInComponent,
    isSCA,
  })

  let spreadRest = []
  // eslint-disable-next-line no-empty
  if (!isEmpty(rest) || (rest.length === 1 && isEmpty(rest[0]))) {
  } else {
    spreadRest = [...rest]
  }

  const buttonProps = {
    className:
      overrideClass !== ``
        ? `${overrideClass} ${fontClass} ${analyticsClass}`
        : `Button ${analyticsClass} btn ${fontClass} rounded-0 d-flex flex-column align-items-center justify-content-center ${className}`,
    name,
    style,
    type,
    onClick: (e) => {
      if (!shouldNotTrackInComponent) {
        updateClicksDL({
          ui_element: elementType,
          object_name: ``,
          object_content: clickLabel,
        })

        if (isSCA) {
          setSCAValues(scaValues)
        }
      }

      /* Call the onClick handler. */
      onClick(e)
    },
    onKeyDown,
    onMouseOver,
    onMouseLeave,
    ...getAdobeLaunchAttribute({
      disabled: shouldNotTrackInComponent,
      value: clickLabel,
    }),
    "aria-label": ariaLabel,
    "aria-haspopup": ariaHasPopup,
    "aria-pressed": ariaPressed,
    "aria-describedby": ariaDescribedBy,
    "aria-expanded": ariaExpanded,
    disabled,
    ...additionalProps,
    children,
    ...spreadRest,
  }

  if (testid) {
    buttonProps[`data-testid`] = testid
  }

  const divProps = omit(buttonProps, `type`, `aria-label`, `name`)
  const anchorProps = { ...buttonProps, href }
  return useDiv ? (
    <div {...divProps} />
  ) : useAnchor ? (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a {...anchorProps} />
  ) : (
    // eslint-disable-next-line react/button-has-type
    <button {...buttonProps} />
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  fontClass: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  href: PropTypes.string,
  analytics_key: PropTypes.string,
  overrideClass: PropTypes.string,
  shouldNotTrackInComponent: PropTypes.bool,
  ariaLabel: PropTypes.string,
  ariaHasPopup: PropTypes.string,
  ariaPressed: PropTypes.string,
  ariaDescribedBy: PropTypes.string,
  ariaExpanded: PropTypes.string,
  useDiv: PropTypes.bool,
  useAnchor: PropTypes.bool,
  isSCA: PropTypes.bool,
  scaValues: PropTypes.object,
  setSCAValues: PropTypes.func.isRequired,
  elementType: PropTypes.string,
  testid: PropTypes.string,
}

Button.defaultProps = {
  children: null,
  onClick: () => {},
  onKeyDown: () => {},
  onMouseOver: () => {},
  onMouseLeave: () => {},
  className: ``,
  fontClass: `maax-font-weight-bold`,
  disabled: false,
  id: ``,
  name: ``,
  style: {},
  type: `button`,
  href: ``,
  overrideClass: ``,
  analytics_key: ``,
  shouldNotTrackInComponent: false,
  ariaLabel: ``,
  ariaHasPopup: ``,
  ariaPressed: ``,
  ariaDescribedBy: ``,
  ariaExpanded: ``,
  useDiv: false,
  useAnchor: false,
  isSCA: false,
  scaValues: {},
  elementType: `Button`,
  testid: "Button",
}

export default Button
