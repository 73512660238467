import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  minorAlertIsvisible: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.STATE_ALERT_PENDING: {
      return {
        ...state,
        fetched: `pending`,
      }
    }

    case ActionTypes.STATE_ALERT_FULFILLED: {
      if (!action.payload.url) {
        // eslint-disable-next-line no-param-reassign
        action.payload.url = ``
      }
      return {
        ...state,
        ...action.payload,
        fetched: `fulfilled`,
      }
    }

    case ActionTypes.STATE_ALERT_FAILED: {
      return {
        ...initialState,
        fetched: `failed`,
      }
    }

    case ActionTypes.MINOR_ALERT_VISIBLE: {
      return {
        fetched: state.fetched,
        stateCode: state.stateCode,
        url: state.url,
        stateName: state.stateName,
        minorAlertIsvisible: true,
      }
    }

    case ActionTypes.MINOR_ALERT_NOT_VISIBLE: {
      return {
        ...state,
        [`hide_${action.payload.title}`]: true,
      }
    }

    default:
      return state
  }
}
