import axios from "axios"
import moment from "moment"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  GET_EXTRA_PICKUP_HISTORY_PENDING,
  GET_EXTRA_PICKUP_HISTORY_FAILED,
  GET_EXTRA_PICKUP_HISTORY_FULFILLED,
} from "constants-lib/actionTypes"
import { getTokenAction, setDataCache } from "actions"
import { urls } from "utils-lib/builds"
import { CACHE_KEYS } from "constants-lib/dataCache"

export const pendingAC = () => ({
  type: GET_EXTRA_PICKUP_HISTORY_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: GET_EXTRA_PICKUP_HISTORY_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: GET_EXTRA_PICKUP_HISTORY_FULFILLED,
  payload: data,
})

export const getExtraPickupHistory =
  ({ worker = null, customerIds, userId, lang }) =>
  (dispatch, getState) => {
    const api = `EXTRA_PICKUP_HISTORY`
    const template = URITemplate(urls.url[api])

    return getTokenAction(dispatch, getState).then(async (res) => {
      const config = {
        method: `GET`,
        headers: {},
      }

      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

      const promises = _.map(customerIds, (customerId) => {
        const url = template.expand({
          customerId,
          userId,
          lang,
        })

        const fromDate = moment().subtract(`3`, `months`).format("YYYY-MM-DD")
        const toDate = moment().format("YYYY-MM-DD")
        const urlWithParams = `${url}&fromDate=${fromDate}&toDate=${toDate}`

        return worker
          ? [urlWithParams, config]
          : axios.create(config).get(urlWithParams)
      })

      return worker
        ? getExtraPickupHistoryWorkerHelper(
            worker,
            promises,
            customerIds,
            dispatch,
          )
        : getExtraPickupHistoryHelper(promises, customerIds, dispatch)
    })
  }

export const getExtraPickupHistoryWorkerHelper = (
  worker,
  promises,
  customerIds,
  dispatch,
) => {
  dispatch(pendingAC())

  const workName = `EXTRA_PICKUP_HISTORY`
  worker.addEventListener(`message`, (e) => onMessageHandler(e))
  worker.postMessage({ work: promises, workName })

  /* onMessage handler for web worker */
  const onMessageHandler = (e) => {
    if (e.data.workName === workName) {
      if (e.data.responses === 500) {
        dispatch(failedAC())
        dispatch(
          setDataCache({
            field: CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA,
            /* cache results as an array of 1 500 status item if there are no results, this helps with tracking completion on the CombinedOrderHistory page  */
            data: [500],
          }),
        )

        /* cleanup after worker and mark status as ready */
        worker.removeEventListener(`message`, this)
      } else {
        const responses = _.filter(e.data.responses, (res) => !_.isNull(res))

        const res = _.flatten(
          _.map(responses, (response, i) =>
            _.map(_.get(response, `data.tickets`, {}), (order) => ({
              ...order,
              orderType: `Extra Pickup`,
              customerId: customerIds[i],
            })),
          ),
        )

        dispatch(
          setDataCache({
            field: CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA,
            /* cache results as an array of 1 null item if there are no results, this helps with tracking completion on the CombinedOrderHistory page  */
            data: res.length ? res : [null],
          }),
        )

        dispatch(fulfilledAC([]))

        /* cleanup after worker and mark status as ready */
        worker.removeEventListener(`message`, this)
      }
    }
  }
}

export const getExtraPickupHistoryHelper = (
  promises,
  customerIds,
  dispatch,
) => {
  dispatch(pendingAC())

  return axios
    .all(promises)
    .then(
      axios.spread((...responses) => {
        const res = _.map(responses, (response, i) =>
          _.map(_.get(response, `data.data.tickets`, {}), (order) => ({
            ...order,
            orderType: `Extra Pickup`,
            customerId: customerIds[i],
          })),
        )

        dispatch(fulfilledAC(_.flatten(res)))
        return _.flatten(res)
      }),
    )
    .catch(() => {
      dispatch(failedAC())
    })
}
