/**
 * Formats a number as a currency string.
 *
 * @function
 * @param {number} inputAmount - The amount to be formatted.
 * @returns {string} The formatted amount as a string, with a leading dollar sign and two decimal places.
 *
 * @example
 * const amount = 12345;
 * const formattedAmount = getFormattedAmount(amount);
 * console.log(formattedAmount); // logs "$12,345.00"
 */
export const getFormattedAmount = (inputAmount, withDollarSign = true) => {
  const amount = parseFloat(inputAmount)
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(amount)) {
    return ``
  }
  const formattedAmount = parseFloat(amount.toFixed(2)).toLocaleString(
    undefined,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  )
  if (amount >= 0) {
    return withDollarSign ? `$${formattedAmount} ` : `${formattedAmount} `
  }
  const formattedNegativeAmount = parseFloat(
    (amount * -1).toFixed(2),
  ).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return withDollarSign
    ? `($${formattedNegativeAmount}) `
    : `(${formattedNegativeAmount}) `
}
