export const SCREEN_SM_MAX = 767
export const SCREEN_MD_MAX = 991
export const BROKER_ACCOUNTS_PICKER_MAX = 992
export const SMOOTH_SCROLL_TOP_PADDING = 150
export const CURRENCY_AMOUNT_XS = 0
export const CURRENCY_AMOUNT_SM = 1
export const CURRENCY_AMOUNT_MD = 2
export const CURRENCY_AMOUNT_LG = 3
export const CURRENCY_AMOUNT_XL = 4

export const IPAD_LANDSCAPE = 1024
export const IPAD_PORTRAIT = 768

export const IPHONE_X = 375
export const IPHONE_7_MAX = 375
export const BOOTSTRAP_GRID_XS_MAX = 575
export const BOOTSTRAP_GRID_SM_MIN = 576
export const BOOTSTRAP_GRID_SM_MAX = 767
export const BOOTSTRAP_GRID_MD_MIN = 768
export const BOOTSTRAP_GRID_MD_MAX = 991
export const BOOTSTRAP_GRID_LG_MIN = 992
export const BOOTSTRAP_GRID_IPAD_PRO_MAX = 1024
export const BOOTSTRAP_GRID_LG_MAX = 1199
export const BOOTSTRAP_GRID_XL_MIN = 1200

export const MEDIA_QUERY_XS_MAX = `(max-width: ${BOOTSTRAP_GRID_XS_MAX}px)`
export const MEDIA_QUERY_SM_MIN = `(min-width: ${BOOTSTRAP_GRID_SM_MIN}px)`
export const MEDIA_QUERY_MD_MIN = `(min-width: ${BOOTSTRAP_GRID_MD_MIN}px)`
export const MEDIA_QUERY_LG_MIN = `(min-width: ${BOOTSTRAP_GRID_LG_MIN}px)`
export const MEDIA_QUERY_XL_MIN = `(min-width: ${BOOTSTRAP_GRID_XL_MIN}px)`

export const VIEWPORT_SIZE_LABEL_XS = `xs`
export const VIEWPORT_SIZE_LABEL_SM = `sm`
export const VIEWPORT_SIZE_LABEL_MD = `md`
export const VIEWPORT_SIZE_LABEL_LG = `lg`
export const VIEWPORT_SIZE_LABEL_XL = `xl`
