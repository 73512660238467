import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  GET_SERVICE_CHANGE_RECOMMENDATIONS_PENDING,
  GET_SERVICE_CHANGE_RECOMMENDATIONS_FAILED,
  GET_SERVICE_CHANGE_RECOMMENDATIONS_FULFILLED,
} from "constants-lib/actionTypes"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"
import { MOCK_SERVICE_CHANGE_INFORMATION } from "utils-lib/mocks"

const CLOUDCRAZE_REFRESH_TOKEN = `_khEtXW82qn8LZVAzSQD1vnE7fmy86uBD0yBI41i7_k`

export const pendingAC = () => ({
  type: GET_SERVICE_CHANGE_RECOMMENDATIONS_PENDING,
})

export const failedAC = () => ({
  type: GET_SERVICE_CHANGE_RECOMMENDATIONS_FAILED,
})

export const fulfilledAC = (data) => ({
  type: GET_SERVICE_CHANGE_RECOMMENDATIONS_FULFILLED,
  payload: data,
})

export const getServiceChangeRecommendations =
  ({ customerId, userId, lang, payload }) =>
  (dispatch, getState) => {
    const api = `SERVICE_CHANGE_RECOMMENDATIONS`
    const template = URITemplate(urls.url[api])

    const url = MOCK_SERVICE_CHANGE_INFORMATION
      ? `${window.origin}/mock/service-change-recommendations-response.json`
      : template.expand({
          customerId,
          userId,
          lang,
        })

    const data = {
      ...payload,
    }

    const changeType = _.upperCase(payload.changeType)
    if (changeType) {
      data.changeType = changeType
    }

    const config = {
      method: MOCK_SERVICE_CHANGE_INFORMATION ? `GET` : `POST`,
      headers: {},
      data,
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      config.headers.refreshToken = CLOUDCRAZE_REFRESH_TOKEN
      return getServiceChangeRecommendationsHelper(url, config, dispatch)
    })
  }

export const getServiceChangeRecommendationsHelper = (
  url,
  config,
  dispatch,
) => {
  dispatch(pendingAC())

  return axios(url, config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      return response.data
    })
    .catch(() => {
      dispatch(failedAC())
    })
}
