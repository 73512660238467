import {
  SET_IS_RECYCLE_QUIZ_PAGE,
  SET_IS_QUIZ_COMPLETED,
} from "constants-lib/actionTypes"

const initialState = {
  isRecycleQuizPage: false,
  isQuizCompleted: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_RECYCLE_QUIZ_PAGE:
      return {
        ...state,
        isRecycleQuizPage: action.payload.isRecycleQuizPage,
      }
    case SET_IS_QUIZ_COMPLETED:
      return {
        ...state,
        isQuizCompleted: action.payload.isQuizCompleted,
      }
    default:
      return state
  }
}
