import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  SUBMIT_BULK_PICKUP_PENDING,
  SUBMIT_BULK_PICKUP_FAILED,
  SUBMIT_BULK_PICKUP_FULFILLED,
} from "constants-lib/actionTypes"
import { MOCK_BULK_PICKUP_INFORMATION } from "utils-lib/mocks"
import { getTokenAction, clearDataCache } from "actions"
import { urls } from "utils-lib/builds"
import { CACHE_KEYS } from "constants-lib/dataCache"

export const pendingAC = () => ({
  type: SUBMIT_BULK_PICKUP_PENDING,
})

export const failedAC = () => ({
  type: SUBMIT_BULK_PICKUP_FAILED,
})

export const fulfilledAC = (data) => ({
  type: SUBMIT_BULK_PICKUP_FULFILLED,
  payload: data,
})

export const submitBulkPickup =
  ({ customerId, payload, isAch = false }) =>
  (dispatch, getState) => {
    const api = `BULK_PICKUP_CREATE`

    const template = URITemplate(urls.url[api])

    const state = getState()
    const convenienceFee =
      state.processingFee?.processingFeeDetails?.convenienceFee

    const url = MOCK_BULK_PICKUP_INFORMATION
      ? `${window.origin}/mock/bulk-pickup-submit-response.json`
      : template.expand({
          customerId,
          lang: _.get(state, `siteLanguage.language`, ``),
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
        })

    const config = {
      method: MOCK_BULK_PICKUP_INFORMATION ? `GET` : `POST`,
      headers: {},
      data: {
        ...payload,
        ...(!isAch && { convenienceFee }),
      },
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return submitBulkPickupHelper(url, config, dispatch)
    })
  }

export const submitBulkPickupHelper = (url, config, dispatch) => {
  dispatch(pendingAC())
  dispatch(clearDataCache(CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA))

  return axios(url, config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))

      return response.data
    })
    .catch(() => {
      dispatch(failedAC())
    })
}
