export const GET_TICKET_PICKUP_INFO_FULFILLED = `GET_TICKET_PICKUP_INFO_FULFILLED`
export const GET_TICKET_PICKUP_INFO_FAILED = `GET_TICKET_PICKUP_INFO_FAILED`
export const GET_TICKET_PICKUP_INFO_PENDING = `GET_TICKET_PICKUP_INFO_PENDING`
export const CLEAR_TICKET_PICKUP_INFO = `CLEAR_TICKET_PICKUP_INFO`

export const ADD_REMOVE_CATALOG = `ADD_REMOVE_CATALOG`
export const RESET_CATALOG = `RESET_CATALOG`
export const SELECTED_DELIVERY_DATE = `SELECTED_DELIVERY_DATE`
export const MOVING_IN = `MOVING_IN`
export const SET_BUISNESS_HELPMECHOOSE = `SET_BUISNESS_HELPMECHOOSE`

/* Outages details */
export const GET_OUTAGES_PENDING = `GET_OUTAGES_PENDING`
export const GET_OUTAGES_FULFILLED = `GET_OUTAGES_FULFILLED`
export const GET_OUTAGES_FAILED = `GET_OUTAGES_FAILED`

/* Migrated constants that were declared in aem-spa actions, please declare all action types here in this constants file */
export const GET_ADS_CUSTOMER_ACCOUNTS_PENDING = `GET_ADS_CUSTOMER_ACCOUNTS_PENDING`
export const GET_ADS_CUSTOMER_ACCOUNTS_FULFILLED = `GET_ADS_CUSTOMER_ACCOUNTS_FULFILLED`
export const GET_ADS_CUSTOMER_ACCOUNTS_FAILED = `GET_ADS_CUSTOMER_ACCOUNTS_FAILED`
export const RESET_ADS_CUSTOMER_ACCOUNTS = `RESET_ADS_CUSTOMER_ACCOUNTS`
export const AEM_JSON_FULFILLED = `AEM_JSON_FULFILLED`
export const AEM_JSON_FAILED = `AEM_JSON_FAILED`
export const SET_LOCALSITE_VISIT_FLAG = `SET_LOCALSITE_VISIT_FLAG`
export const RESET_COMMUNICATION_PREFERENCES_DATA = `RESET_COMMUNICATION_PREFERENCES_DATA`
export const GET_NOTIFICATION_PREFERENCES_FAILED = `GET_NOTIFICATION_PREFERENCES_FAILED`
export const GET_NOTIFICATION_PREFERENCES_FULFILLED = `GET_NOTIFICATION_PREFERENCES_FULFILLED`
export const GET_NOTIFICATION_PREFERENCES_PENDING = `GET_NOTIFICATION_PREFERENCES_PENDING`
export const SET_SELECTED_ACCOUNT = `SET_SELECTED_ACCOUNT`
export const SET_NOTIFICATION_PREFERENCES_FAILED = `SET_NOTIFICATION_PREFERENCES_FAILED`
export const SET_NOTIFICATION_PREFERENCES_FULFILLED = `SET_NOTIFICATION_PREFERENCES_FULFILLED`
export const SET_NOTIFICATION_PREFERENCES_PENDING = `SET_NOTIFICATION_PREFERENCES_PENDING`
export const SET_CP_CATEGORY = `SET_CP_CATEGORY`
export const UPDATE_CURRENT_NEIGHBORHOOD = `UPDATE_CURRENT_NEIGHBORHOOD`
export const GET_PRODUCTS_CLEAR_STATE = `GET_PRODUCTS_CLEAR_STATE`
export const GET_COMBINED_PRODUCT_URL_PENDING = `GET_COMBINED_PRODUCT_URL_PENDING`
export const GET_COMBINED_PRODUCT_URL_FAILED = `GET_COMBINED_PRODUCT_URL_FAILED`
export const GET_COMBINED_PRODUCT_URL_FULFILLED = `GET_COMBINED_PRODUCT_URL_FULFILLED`
export const SHOW_COMBINED_PRODUCT_STATE = `SHOW_COMBINED_PRODUCT_STATE`
export const HIDE_COMBINED_PRODUCT_STATE = `HIDE_COMBINED_PRODUCT_STATE`
export const SET_ERROR_ON_SUGGEST = `SET_ERROR_ON_SUGGEST`
export const SET_ERROR_ON_SUGGEST_SCHEDULE = `SET_ERROR_ON_SUGGEST_SCHEDULE`
export const SET_CHECK_AVAILABILITY_LOB = `SET_CHECK_AVAILABILITY_LOB`
export const GET_CITY_BILLED_ELIGIBILITY_URL_PENDING = `GET_CITY_BILLED_ELIGIBILITY_URL_PENDING`
export const GET_CITY_BILLED_ELIGIBILITY_URL_FAILED = `GET_CITY_BILLED_ELIGIBILITY_URL_FAILED`
export const SET_MODAL_ID = `SET_MODAL_ID`
export const SET_MENU_OPEN = `SET_MENU_OPEN`
export const GET_ALERTS_ADDRESS_FULFILLED = `GET_ALERTS_ADDRESS_FULFILLED`
export const GET_ALERTS_CUSTOMER_ID_FULFILLED = `GET_ALERTS_CUSTOMER_ID_FULFILLED`
export const GET_ALERTS_USER_ID_FULFILLED = `GET_ALERTS_USER_ID_FULFILLED`
export const GET_INCIDENTS_BY_USER_ID_PENDING = `GET_INCIDENTS_BY_USER_ID_PENDING`
export const GET_INCIDENTS_BY_USER_ID_FAILED = `GET_INCIDENTS_BY_USER_ID_FAILED`
export const GET_INCIDENTS_BY_USER_ID_FULFILLED = `GET_INCIDENTS_BY_USER_ID_FULFILLED`
export const SET_SHOW_MOBILE_SEARCH = `SET_SHOW_MOBILE_SEARCH`
export const SET_IS_SUPPORT_TOPIC = `SET_IS_SUPPORT_TOPIC`
export const SET_NO_FRENCH_CONTENT = `SET_NO_FRENCH_CONTENT`
export const SET_SELECT_AREA_MODAL_CLOSED = `SET_SELECT_AREA_MODAL_CLOSED`
export const SET_FORM_SUBMITTED = `SET_FORM_SUBMITTED`
export const LINE_OF_BUSINESS = `LINE_OF_BUSINESS`
export const API_STATUSES = `API_STATUSES`
export const SET_ALERTS_READ = `SET_ALERTS_READ`
export const CHANGE_CONTACT_SELECTION = `CHANGE_CONTACT_SELECTION`
export const CLEAR_CONTACT_SELECTION = `CLEAR_CONTACT_SELECTION`
export const GET_RATINGS_AND_REVIEWS_PENDING = `GET_RATINGS_AND_REVIEWS_PENDING`
export const GET_RATINGS_AND_REVIEWS_FAILED = `GET_RATINGS_AND_REVIEWS_FAILED`
export const GET_RATINGS_AND_REVIEWS_FULFILLED = `GET_RATINGS_AND_REVIEWS_FULFILLED`
export const RESET_RATINGS_AND_REVIEWS = `RESET_RATINGS_AND_REVIEWS`
export const GET_LOCATIONS_PENDING = `GET_LOCATIONS_PENDING`
export const GET_LOCATIONS_FULFILLED = `GET_LOCATIONS_FULFILLED`
export const GET_LOCATIONS_REJECTED = `GET_LOCATIONS_REJECTED`
export const SET_SEARCH_TEXT = `SET_SEARCH_TEXT`
export const SET_CUSTOMER_ACTION = `SET_CUSTOMER_ACTION`
export const CLEAR_HOVERED_MAP_MARKER_ID = `CLEAR_HOVERED_MAP_MARKER_ID`
export const SET_HOVERED_MAP_MARKER_ID = `SET_HOVERED_MAP_MARKER_ID`
export const CLEAR_SELECTED_FACILITY_ID = `CLEAR_SELECTED_FACILITY_ID`
export const SELECT_FACILITY = `SELECT_FACILITY`
export const UPDATE_GEOSUGGEST_LOCATION = `UPDATE_GEOSUGGEST_LOCATION`
export const TRANSLATE_FACILITY = `TRANSLATE_FACILITY`
export const FETCH_DATA_LOCAL_SITE_FULFILLED = `FETCH_DATA_LOCAL_SITE_FULFILLED`
export const FETCH_DATA_LOCAL_SITE_FAILED = `FETCH_DATA_LOCAL_SITE_FAILED`
export const MC_SET_SELECTED_ACCOUNT = `MC_SET_SELECTED_ACCOUNT`
export const GET_LOCALSITES_MAS_REJECTED = `GET_LOCALSITES_MAS_REJECTED`
export const GET_LOCALSITES_MAS_PENDING = `GET_LOCALSITES_MAS_PENDING`
export const GET_LOCALSITES_MAS_FULFILLED = `GET_LOCALSITES_MAS_FULFILLED`
export const SET_APPLE_PAY_CONFIRM = `SET_APPLE_PAY_CONFIRM`
export const SET_SELECTED_SERVICE_OBJECT = `SET_SELECTED_SERVICE_OBJECT`
export const GET_ALERTS_ADDRESS_PENDING = `GET_ALERTS_ADDRESS_PENDING`
export const GET_ALERTS_ADDRESS_FAILED = `GET_ALERTS_ADDRESS_FAILED`
export const GET_ALERTS_CUSTOMER_ID_PENDING = `GET_ALERTS_CUSTOMER_ID_PENDING`
export const GET_ALERTS_CUSTOMER_ID_FAILED = `GET_ALERTS_CUSTOMER_ID_FAILED`
export const GET_ALERTS_USER_ID_PENDING = `GET_ALERTS_USER_ID_PENDING`
export const GET_ALERTS_USER_ID_FAILED = `GET_ALERTS_USER_ID_FAILED`
export const GET_GLOBAL_ALERTS_PENDING = `GET_GLOBAL_ALERTS_PENDING`
export const GET_GLOBAL_ALERTS_FAILED = `GET_GLOBAL_ALERTS_FAILED`
export const GET_GLOBAL_ALERTS_FULFILLED = `GET_GLOBAL_ALERTS_FULFILLED`
export const CLEAR_NOTIFICATIONS = `CLEAR_NOTIFICATIONS`
export const CHANGE_TERM_PLAN = `CHANGE_TERM_PLAN`
export const SET_SUPPORT_FORM_VALUE = `SET_SUPPORT_FORM_VALUE`
export const RESET_SUPPORT_FORM_VALUE = `RESET_SUPPORT_FORM_VALUE`
export const SET_LOB_PRODUCT_TOGGLE = `SET_LOB_PRODUCT_TOGGLE`
export const UPDATE_UNSUBSCRIBE_FEEDBACK_PENDING = `UPDATE_UNSUBSCRIBE_FEEDBACK_PENDING`
export const UPDATE_UNSUBSCRIBE_FEEDBACK_FAILED = `UPDATE_UNSUBSCRIBE_FEEDBACK_FAILED`
export const UPDATE_UNSUBSCRIBE_FEEDBACK_FULFILLED = `UPDATE_UNSUBSCRIBE_FEEDBACK_FULFILLED`
export const AUTHENTICATE_USER_PENDING = `AUTHENTICATE_USER_PENDING`
export const AUTHENTICATE_USER_FULFILLED = `AUTHENTICATE_USER_FULFILLED`
export const AUTHENTICATE_USER_FAILED = `AUTHENTICATE_USER_FAILED`
export const TRIGGER_FORM_EMAIL_PENDING = `TRIGGER_FORM_EMAIL_PENDING`
export const TRIGGER_FORM_EMAIL_FAILED = `TRIGGER_FORM_EMAIL_FAILED`
export const TRIGGER_FORM_EMAIL_FULFILLED = `TRIGGER_FORM_EMAIL_FULFILLED`
export const GET_FACILITY_ALERTS_PENDING = `GET_FACILITY_ALERTS_PENDING`
export const GET_FACILITY_ALERTS_FULFILLED = `GET_FACILITY_ALERTS_FULFILLED`
export const GET_FACILITY_ALERTS_FAILED = `GET_FACILITY_ALERTS_FAILED`
export const GET_REVERSE_GEOCODE_INFO_PENDING = `GET_REVERSE_GEOCODE_INFO_PENDING`
export const GET_REVERSE_GEOCODE_INFO_FULFILLED = `GET_REVERSE_GEOCODE_INFO_FULFILLED`
export const GET_REVERSE_GEOCODE_INFO_FAILED = `constGET_REVERSE_GEOCODE_INFO_FAILED`

/* fulfillment action for bulk enrollment request */
export const BULK_ENROLL_AUTOPAYMENT_PENDING = `BULK_ENROLL_AUTOPAYMENT_PENDING`
export const BULK_ENROLL_AUTOPAYMENT_FAILED = `BULK_ENROLL_AUTOPAYMENT_FAILED`
export const BULK_ENROLL_PAPERLESS_BILLING_PENDING = `BULK_ENROLL_PAPERLESS_BILLING_PENDING`
export const BULK_ENROLL_PAPERLESS_BILLING_FAILED = `BULK_ENROLL_PAPERLESS_BILLING_FAILED`

/* Migrated constants that were declared in mymw actions, please declare all action types here in this constants file */
export const CHANGE_PASSWORD_PENDING = `CHANGE_PASSWORD_PENDING`
export const CHANGE_PASSWORD_FULFILLED = `CHANGE_PASSWORD_FULFILLED`
export const CHANGE_PASSWORD_FAILED = `CHANGE_PASSWORD_FAILED`
export const CLEAR_GET_NOTIFICATION_PREFERENCES_STATUS = `CLEAR_GET_NOTIFICATION_PREFERENCES_STATUS`
export const CLEAR_GUEST_DATA = `CLEAR_GUEST_DATA`
export const CLEAR_RO_SUCCESS_DATA = `CLEAR_RO_SUCCESS_DATA`
export const CLEAR_SET_NOTIFICATION_PREFERENCES_STATUS = `CLEAR_SET_NOTIFICATION_PREFERENCES_STATUS`
export const DONT_SEE_AREA_CLICKED = `DONT_SEE_AREA_CLICKED`
export const GET_COMBINED_PRODUCT_URL_CLEAR_STATE = `GET_COMBINED_PRODUCT_URL_CLEAR_STATE`
export const MAKE_ANOTHER_PAYMENT = `MAKE_ANOTHER_PAYMENT`
export const SET_ALERTS_SELECTED_ACCOUNT = `SET_ALERTS_SELECTED_ACCOUNT`
export const SET_INCIDENTS_DATE = `SET_INCIDENTS_DATE`
export const SET_IS_SEARCH_AGAIN = `SET_IS_SEARCH_AGAIN`
export const SET_SITE_CONTACT = `SET_SITE_CONTACT`
export const UPDATE_CURRENT_LOCALSITE = `UPDATE_CURRENT_LOCALSITE`
export const AUTOPAY_CONSENT_PENDING = `AUTOPAY_CONSENT_PENDING`
export const AUTOPAY_CONSENT_FULFILLED = `AUTOPAY_CONSENT_FULFILLED`
export const AUTOPAY_CONSENT_FAILED = `AUTOPAY_CONSENT_FAILED`
export const GET_SERVICE_HISTORY_PENDING = `GET_SERVICE_HISTORY_PENDING`
export const GET_SERVICE_HISTORY_FULFILLED = `GET_SERVICE_HISTORY_FULFILLED`
export const GET_SERVICE_HISTORY_FAILED = `GET_SERVICE_HISTORY_FAILED`
export const GET_SERVICE_VISUALS_PENDING = `GET_SERVICE_VISUALS_PENDING`
export const GET_SERVICE_VISUALS_FULFILLED = `GET_SERVICE_VISUALS_FULFILLED`
export const GET_SERVICE_VISUALS_FAILED = `GET_SERVICE_VISUALS_FAILED`
export const CLEAR_SERVICE_VISUALS = `CLEAR_SERVICE_VISUALS`
export const CREATE_USER_AUTOPAY_PAPERLESS_PENDING = `CREATE_USER_AUTOPAY_PAPERLESS_PENDING`
export const CREATE_USER_AUTOPAY_PAPERLESS_FAILED = `CREATE_USER_AUTOPAY_PAPERLESS_FAILED`
export const CREATE_USER_AUTOPAY_PAPERLESS_FULFILLED = `CREATE_USER_AUTOPAY_PAPERLESS_FULFILLED`
export const ENROLL_AUTOPAY_PAPERLESS_GUEST_PENDING = `ENROLL_AUTOPAY_PAPERLESS_GUEST_FAILED`
export const ENROLL_AUTOPAY_PAPERLESS_GUEST_FAILED = `ENROLL_AUTOPAY_PAPERLESS_GUEST_FAILED`
export const ENROLL_AUTOPAY_PAPERLESS_GUEST_FULFILLED = `ENROLL_AUTOPAY_PAPERLESS_GUEST_FAILED`
export const SEND_VERIFICATION_CODE_PENDING = `SEND_VERIFICATION_CODE_FAILED`
export const SEND_VERIFICATION_CODE_FAILED = `SEND_VERIFICATION_CODE_FAILED`
export const SEND_VERIFICATION_CODE_FULFILLED = `SEND_VERIFICATION_CODE_FAILED`
export const VALIDATE_VERIFICATION_CODE_PENDING = `VALIDATE_VERIFICATION_CODE_FAILED`
export const VALIDATE_VERIFICATION_CODE_FAILED = `VALIDATE_VERIFICATION_CODE_FAILED`
export const VALIDATE_VERIFICATION_CODE_FULFILLED = `VALIDATE_VERIFICATION_CODE_FAILED`

/* Support Page Guided Flow & Contact Back */
export const SET_GUIDED_FLOW_SELECTIONS = `SET_GUIDED_FLOW_SELECTIONS`
export const GET_CONTACT_BACK_ATTRIBUTES_FULFILLED = `GET_CONTACT_BACK_ATTRIBUTES_FULFILLED`
export const GET_CONTACT_BACK_ATTRIBUTES_FAILED = `GET_CONTACT_BACK_ATTRIBUTES_FAILED`
export const GET_CONTACT_BACK_ATTRIBUTES_PENDING = `GET_CONTACT_BACK_ATTRIBUTES_PENDING`
export const CONTACT_BACK_CREATE_CASE_PENDING = `CONTACT_BACK_CREATE_CASE_PENDING`
export const CONTACT_BACK_CREATE_CASE_FULFILLED = `CONTACT_BACK_CREATE_CASE_FULFILLED`
export const CONTACT_BACK_CREATE_CASE_FAILED = `CONTACT_BACK_CREATE_CASE_FAILED`

//------------------------------------------------------------------------------------------------------

export const LOB_CLICKED = `LOB_CLICKED`
export const RESET_CUSTOMER_SERVICE_TYPE = `RESET_CUSTOMER_SERVICE_TYPE`
export const SET_CUSTOMER_ADDRESS = `SET_CUSTOMER_ADDRESS`
export const SET_GEOCODE_CUSTOMER_ADDRESS = `SET_GEOCODE_CUSTOMER_ADDRESS`
export const SET_PRIMARY_ADDRESS = `SET_PRIMARY_ADDRESS`
export const SET_CUSTOMER_CONTACT_US_METHOD = `SET_CUSTOMER_CONTACT_US_METHOD`
export const SET_CUSTOMER_EMAIL = `SET_CUSTOMER_EMAIL`
export const SET_CUSTOMER_PHONE = `SET_CUSTOMER_PHONE`
export const SET_CUSTOMER_ID = `SET_CUSTOMER_ID`
export const SET_CUSTOMER_LOB = `SET_CUSTOMER_LOB`
export const SET_RENDER_DASHBOARD = `SET_RENDER_DASHBOARD`
export const SET_CUSTOMER_PRODUCT = `SET_CUSTOMER_PRODUCT`
export const RESET_CUSTOMER_SUPPORT_METHOD = `RESET_CUSTOMER_SUPPORT_METHOD`
export const RESET_CUSTOMER_SUPPORT_OPTION = `RESET_CUSTOMER_SUPPORT_OPTION`
export const SET_CUSTOMER_SUPPORT_METHOD = `SET_CUSTOMER_SUPPORT_METHOD`
export const SET_CUSTOMER_SUPPORT_OPTION = `SET_CUSTOMER_SUPPORT_OPTION`
export const SET_CUSTOMER_SERVICE_TYPE = `SET_CUSTOMER_SERVICE_TYPE`
export const SET_LANGUAGE = `SET_LANGUAGE`
export const SET_ROLL_OFF_SERVICE_PENDING = `SET_ROLL_OFF_SERVICE_PENDING`
export const SET_ROLL_OFF_SERVICE_FAILED = `SET_ROLL_OFF_SERVICE_FAILED`
export const SET_ROLL_OFF_SERVICE_FULFILLED = `SET_ROLL_OFF_SERVICE_FULFILLED`
export const SET_REGISTRATION_STATE = `SET_REGISTRATION_STATE`
export const RESET_CART_DETAILS = `RESET_CART_DETAILS`
export const RESET_CART_STATUS = `RESET_CART_STATUS`
export const SUBMIT_SERVICE_REQUEST_FORM_FULFILLED = `SUBMIT_SERVICE_REQUEST_FORM_FULFILLED`
export const SUBMIT_SERVICE_REQUEST_FORM_PENDING = `SUBMIT_SERVICE_REQUEST_FORM_PENDING`
export const HIDE_NATURAL_LANGUAGE_DROPDOWN = `HIDE_NATURAL_LANGUAGE_DROPDOWN`
export const SHOW_NATURAL_LANGUAGE_DROPDOWN = `SHOW_NATURAL_LANGUAGE_DROPDOWN`
export const GET_CUSTOMER_SUPPORT_CONTACT_PENDING = `GET_CUSTOMER_SUPPORT_CONTACT_PENDING`
export const GET_CUSTOMER_SUPPORT_CONTACT_FULFILLLED = `GET_CUSTOMER_SUPPORT_CONTACT_FULFILLLED`
export const GET_CUSTOMER_SUPPORT_CONTACT_FAILED = `GET_CUSTOMER_SUPPORT_CONTACT_FAILED`
export const INIT_SERVICE_REQUEST = `INIT_SERVICE_REQUEST`
export const INVALIDATE_LANGUAGE = `INVALIDATE_LANGUAGE`
export const GET_PRODUCT_LIST_JSON_PENDING = `GET_PRODUCT_LIST_JSON_PENDING`
export const GET_PRODUCT_LIST_JSON_FULFILLED = `GET_PRODUCT_LIST_JSON_FULFILLED`
export const GET_LIVE_CHAT_PENDING = `GET_LIVE_CHAT_PENDING`
export const GET_LIVE_CHAT_FULFILLED = `GET_LIVE_CHAT_FULFILLED`
export const GET_LIVE_CHAT_FAILED = `GET_LIVE_CHAT_FAILED`
export const SET_OPTIONS_COUNT = `SET_OPTIONS_COUNT`
export const SET_LAST_WIDGET_VIEWED = `SET_LAST_WIDGET_VIEWED`
export const GET_CUSTOMER_ACCOUNT_PENDING = `GET_CUSTOMER_ACCOUNT_PENDING`
export const GET_CUSTOMER_ACCOUNT_FULFILLED = `GET_CUSTOMER_ACCOUNT_FULFILLED`
export const GET_CUSTOMER_ACCOUNT_FAILED = `GET_CUSTOMER_ACCOUNT_FAILED`
export const GET_CUSTOMER_SERVICES_FULFILLED = `GET_CUSTOMER_SERVICES_FULFILLED`
export const GET_CUSTOMER_SERVICES_PENDING = `GET_CUSTOMER_SERVICES_PENDING`
export const GET_CUSTOMER_SERVICES_FAILED = `GET_CUSTOMER_SERVICES_FAILED`
export const GET_HOLIDAY_SCHEDULE_PENDING = `GET_HOLIDAY_SCHEDULE_PENDING`
export const GET_HOLIDAY_SCHEDULE_FULFILLED = `GET_HOLIDAY_SCHEDULE_FULFILLED`
export const GET_HOLIDAY_SCHEDULE_FAILED = `GET_HOLIDAY_SCHEDULE_FAILED`
export const SET_DASHBOARD_SERVICE_ADDRESS = `SET_DASHBOARD_SERVICE_ADDRESS`
export const SET_DASHBOARD_CUSTOMER_ID = `SET_DASHBOARD_CUSTOMER_ID`
export const SET_DASHBOARD_SERVICES = `SET_DASHBOARD_SERVICES`
export const CLEAR_CUSTOMER_SERVICES = `CLEAR_CUSTOMER_SERVICES`
export const GET_PICKUP_INFO_FULFILLED = `GET_PICKUP_INFO_FULFILLED`
export const GET_PICKUP_INFO_FAILED = `GET_PICKUP_INFO_FAILED`
export const GET_PICKUP_INFO_PENDING = `GET_PICKUP_INFO_PENDING`
export const GET_REDESIGNED_DASHBOARD_PICKUP_INFO_FULFILLED = `GET_REDESIGNED_DASHBOARD_PICKUP_INFO_FULFILLED`
export const GET_REDESIGNED_DASHBOARD_PICKUP_INFO_FAILED = `GET_REDESIGNED_DASHBOARD_PICKUP_INFO_FAILED`
export const GET_REDESIGNED_DASHBOARD_PICKUP_INFO_PENDING = `GET_REDESIGNED_DASHBOARD_PICKUP_INFO_PENDING`
export const GET_REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY_FULFILLED = `GET_REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY_FULFILLED`
export const GET_REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY_FAILED = `GET_REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY_FAILED`
export const GET_REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY_PENDING = `GET_REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY_PENDING`
export const GET_REDESIGNED_DASHBOARD_SERVICES_DETAILS_PENDING = `GET_REDESIGNED_DASHBOARD_SERVICES_DETAILS_PENDING`
export const GET_REDESIGNED_DASHBOARD_SERVICES_DETAILS_FAILED = `GET_REDESIGNED_DASHBOARD_SERVICES_DETAILS_FAILED`
export const GET_REDESIGNED_DASHBOARD_SERVICES_DETAILS_FULFILLED = `GET_REDESIGNED_DASHBOARD_SERVICES_DETAILS_FULFILLED`
export const GET_CONTAINER_NOT_OUT_ELIGIBILITY_FULFILLED = `GET_CONTAINER_NOT_OUT_ELIGIBILITY_FULFILLED`
export const GET_CONTAINER_NOT_OUT_ELIGIBILITY_FAILED = `GET_CONTAINER_NOT_OUT_ELIGIBILITY_FAILED`
export const GET_CONTAINER_NOT_OUT_ELIGIBILITY_PENDING = `GET_CONTAINER_NOT_OUT_ELIGIBILITY_PENDING`
export const CLEAR_CONTAINER_NOT_OUT_ELIGIBILITY = `CLEAR_CONTAINER_NOT_OUT_ELIGIBILITY`
export const SUBMIT_CONTAINER_NOT_OUT_REQUEST_FULFILLED = `SUBMIT_CONTAINER_NOT_OUT_REQUEST_FULFILLED`
export const SUBMIT_CONTAINER_NOT_OUT_REQUEST_FAILED = `SUBMIT_CONTAINER_NOT_OUT_REQUEST_FAILED`
export const SUBMIT_CONTAINER_NOT_OUT_REQUEST_PENDING = `SUBMIT_CONTAINER_NOT_OUT_REQUEST_PENDING`
export const CLEAR_SUBMIT_CONTAINER_NOT_OUT_REQUEST = `CLEAR_SUBMIT_CONTAINER_NOT_OUT_REQUEST`
export const GET_REDESIGNED_DASHBOARD_GUEST_PICKUP_INFO_PENDING = `GET_REDESIGNED_DASHBOARD_GUEST_PICKUP_INFO_PENDING`
export const GET_REDESIGNED_DASHBOARD_GUEST_PICKUP_INFO_FAILED = `GET_REDESIGNED_DASHBOARD_GUEST_PICKUP_INFO_FAILED`
export const GET_REDESIGNED_DASHBOARD_GUEST_PICKUP_INFO_FULFILLED = `GET_REDESIGNED_DASHBOARD_GUEST_PICKUP_INFO_FULFILLED`
export const SERVICE_DASHBOARD_PICKUP_INFO_FULFILLED = `SERVICE_DASHBOARD_PICKUP_INFO_FULFILLED`
export const INCREMENT_COMPONENTS_LOADED = `INCREMENT_COMPONENTS_LOADED`
export const RESET_FIRST_COMPONENT_LOADED = `RESET_FIRST_COMPONENT_LOADED`
export const SET_FIRST_COMPONENT_LOADED = `SET_FIRST_COMPONENT_LOADED`
export const RESET_MYSERVICES_DASHBOARD = `RESET_MYSERVICES_DASHBOARD`
export const RESET_DASHBOARD_FROM_GETTING_STARTED = `RESET_DASHBOARD_FROM_GETTING_STARTED`
export const GET_GEOCODE_FULFILLED = `GET_GEOCODE_FULFILLED`
export const SET_MY_SERVICES_MAIN = `SET_MY_SERVICES_MAIN`
export const SET_SUPPORT_FORM = `SET_SUPPORT_FORM`
export const GET_ROLLOFF_TICKETS_PENDING = `GET_ROLLOFF_TICKETS_PENDING`
export const GET_ROLLOFF_TICKETS_FULFILLED = `GET_ROLLOFF_TICKETS_FULFILLED`
export const GET_ROLLOFF_TICKETS_FAILED = `GET_ROLLOFF_TICKETS_FAILED`
export const SET_RO_STATUS_DROPDOWN_STATUS = `SET_RO_STATUS_DROPDOWN_STATUS`
export const SET_RO_STATUS_DROPDOWN_VIEW_BY = `SET_RO_STATUS_DROPDOWN_VIEW_BY`
export const INIT_ROLLOFF_TICKET_CANCEL = `INIT_ROLLOFF_TICKET_CANCEL`
export const RESET_ROLLOFF_TICKET_CANCEL = `RESET_ROLLOFF_TICKET_CANCEL`
export const CONFIRM_ROLLOFF_TICKET_CANCEL_PENDING = `CONFIRM_ROLLOFF_TICKET_CANCEL_PENDING`
export const CONFIRM_ROLLOFF_TICKET_CANCEL_FULFILLED = `CONFIRM_ROLLOFF_TICKET_CANCEL_FULFILLED`
export const CONFIRM_ROLLOFF_TICKET_CANCEL_FAILED = `CONFIRM_ROLLOFF_TICKET_CANCEL_FAILED`
export const SET_RO_TICKET_CANCEL_FLAG = `SET_RO_TICKET_CANCEL_FLAG`
export const GET_ROLLOFF_TICKET_DETAILS_PENDING = `GET_ROLLOFF_TICKET_DETAILS_PENDING`
export const GET_ROLLOFF_TICKET_DETAILS_FULFILLED = `GET_ROLLOFF_TICKET_DETAILS_FULFILLED`
export const GET_ROLLOFF_TICKET_DETAILS_FAILED = `GET_ROLLOFF_TICKET_DETAILS_FAILED`
export const UPDATE_ROLLOFF_SERVICE_REQUEST_PENDING = `UPDATE_ROLLOFF_SERVICE_REQUEST_PENDING`
export const UPDATE_ROLLOFF_SERVICE_REQUEST_FULFILLED = `UPDATE_ROLLOFF_SERVICE_REQUEST_FULFILLED`
export const UPDATE_ROLLOFF_SERVICE_REQUEST_FAILED = `UPDATE_ROLLOFF_SERVICE_REQUEST_FAILED`
export const RESET_ROLLOFF_TICKET_DETAILS = `RESET_ROLLOFF_TICKET_DETAILS`
export const SET_ROLLOFF_DISPLAY_METHOD = `SET_ROLLOFF_DISPLAY_METHOD`
export const SET_SKIP_VERIFICATION = `SET_SKIP_VERIFICATION`
export const GET_CUSTOMER_VERIFICATION_PENDING = `GET_CUSTOMER_VERIFICATION_PENDING`
export const GET_CUSTOMER_VERIFICATION_FULFILLED = `GET_CUSTOMER_VERIFICATION_FULFILLED`
export const GET_CUSTOMER_VERIFICATION_FAILED = `GET_CUSTOMER_VERIFICATION_FAILED`
export const GET_ROLL_OFF_AVAILABLE_DATES_FULFILLED = `GET_ROLL_OFF_AVAILABLE_DATES_FULFILLED`
export const GET_ROLL_OFF_AVAILABLE_DATES_FAILED = `GET_ROLL_OFF_AVAILABLE_DATES_FAILED`
export const GET_ROLL_OFF_AVAILABLE_DATES_PENDING = `GET_ROLL_OFF_AVAILABLE_DATES_PENDING`
export const RESET_PREF_VERIFICATION = `RESET_PREF_VERIFICATION`

export const RESEND_EMAIL_PENDING = `RESEND_EMAIL_PENDING`
export const RESEND_EMAIL_FAILED = `RESEND_EMAIL_FAILED`
export const RESEND_EMAIL_FULFILLED = `RESEND_EMAIL_FULFILLED`
export const SEND_REGISTRATION_EMAIL_PENDING = `SEND_REGISTRATION_EMAIL_PENDING`
export const SEND_REGISTRATION_EMAIL_FULFILLED = `SEND_REGISTRATION_EMAIL_FULFILLED`
export const SEND_REGISTRATION_EMAIL_FAILED = `SEND_REGISTRATION_EMAIL_FAILED`
export const ACTIVATE_USER_PENDING = `ACTIVATE_USER_PENDING`
export const ACTIVATE_USER_FAILED = `ACTIVATE_USER_FAILED`
export const ACTIVATE_USER_FULFILLED = `ACTIVATE_USER_FULFILLED`
export const FORGOT_PASSWORD_PENDING = `FORGOT_PASSWORD_PENDING`
export const FORGOT_PASSWORD_FAILED = `FORGOT_PASSWORD_FAILED`
export const FORGOT_PASSWORD_FULFILLED = `FORGOT_PASSWORD_FULFILLED`
export const RESET_PASSWORD_PENDING = `RESET_PASSWORD_PENDING`
export const RESET_PASSWORD_FAILED = `RESET_PASSWORD_FAILED`
export const RESET_PASSWORD_FULFILLED = `RESET_PASSWORD_FULFILLED`
export const SET_IS_PASSWORD_UPDATED = `SET_IS_PASSWORD_UPDATED`
export const SET_USER_DETAILS = `SET_USER_DETAILS`
export const SET_USER_LICENSING_AGREEMENT_STATE = `SET_USER_LICENSING_AGREEMENT_STATE`
export const GET_USER_STATUS_PENDING = `GET_USER_STATUS_PENDING`
export const GET_USER_STATUS_FAILED = `GET_USER_STATUS_FAILED`
export const GET_USER_STATUS_FULFILLED = `GET_USER_STATUS_FULFILLED`
export const GET_USER_DETAILS_PENDING = `GET_USER_DETAILS_PENDING`
export const GET_USER_DETAILS_FAILED = `GET_USER_DETAILS_FAILED`
export const GET_USER_DETAILS_FULFILLED = `GET_USER_DETAILS_FULFILLED`
export const SET_TOKEN = `SET_TOKEN`
export const HIDE_MANAGE_ACCOUNT_MESSAGE = `HIDE_MANAGE_ACCOUNT_MESSAGE`
export const GET_LINKED_ACCOUNTS_PENDING = `GET_LINKED_ACCOUNTS_PENDING`
export const GET_LINKED_ACCOUNTS_FAILED = `GET_LINKED_ACCOUNTS_FAILED`
export const GET_LINKED_ACCOUNTS_FULFILLED = `GET_LINKED_ACCOUNTS_FULFILLED`
export const SET_LINKED_ACCOUNTS_PENDING = `SET_LINKED_ACCOUNTS_PENDING`
export const SET_LINKED_ACCOUNTS_FAILED = `SET_LINKED_ACCOUNTS_FAILED`
export const SET_LINKED_ACCOUNTS_FULFILLED = `SET_LINKED_ACCOUNTS_FULFILLED`
export const UPDATE_LINKED_ACCOUNTS_PENDING = `UPDATE_LINKED_ACCOUNTS_PENDING`
export const UPDATE_LINKED_ACCOUNTS_FAILED = `UPDATE_LINKED_ACCOUNTS_FAILED`
export const UPDATE_LINKED_ACCOUNTS_FULFILLED = `UPDATE_LINKED_ACCOUNTS_FULFILLED`
export const SET_UNLINKED_ACCOUNTS_PENDING = `SET_UNLINKED_ACCOUNTS_PENDING`
export const SET_UNLINKED_ACCOUNTS_FAILED = `SET_UNLINKED_ACCOUNTS_FAILED`
export const SET_UNLINKED_ACCOUNTS_FULFILLED = `SET_UNLINKED_ACCOUNTS_FULFILLED`
export const GET_CUSTOMER_ACCOUNT_BY_EMAIL_PENDING = `GET_CUSTOMER_ACCOUNT_BY_EMAIL_PENDING`
export const GET_CUSTOMER_ACCOUNT_BY_EMAIL_FULFILLED = `GET_CUSTOMER_ACCOUNT_BY_EMAIL_FULFILLED`
export const GET_CUSTOMER_ACCOUNT_BY_EMAIL_FAILED = `GET_CUSTOMER_ACCOUNT_BY_EMAIL_FAILED`

/* Get Cloudcraze token */
export const GET_COMMERCIAL_SERVLET_FAILED = `GET_COMMERCIAL_SERVLET_FAILED`
export const GET_COMMERCIAL_SERVLET_FULFILLED = `GET_COMMERCIAL_SERVLET_FULFILLED`
export const GET_COMMERCIAL_SERVLET_PENDING = `GET_COMMERCIAL_SERVLET_PENDING`

/* Get Cloudcraze service days */
export const GET_CLOUDCRAZE_SERVICE_DAYS_PENDING = `GET_CLOUDCRAZE_SERVICE_DAYS_PENDING`
export const GET_CLOUDCRAZE_SERVICE_DAYS_FULFILLED = `GET_CLOUDCRAZE_SERVICE_DAYS_FULFILLED`
export const GET_CLOUDCRAZE_SERVICE_DAYS_FAILED = `GET_CLOUDCRAZE_SERVICE_DAYS_FAILED`

/* Get Cloudcraze delivery schedule */
export const GET_CLOUDCRAZE_DELIVERY_SCHEDULE_PENDING = `GET_CLOUDCRAZE_DELIVERY_SCHEDULE_PENDING`
export const GET_CLOUDCRAZE_DELIVERY_SCHEDULE_FULFILLED = `GET_CLOUDCRAZE_DELIVERY_SCHEDULE_FULFILLED`
export const GET_CLOUDCRAZE_DELIVERY_SCHEDULE_FAILED = `GET_CLOUDCRAZE_DELIVERY_SCHEDULE_FAILED`

/* Get Online Products */
export const RESET_ECOM_PRICING = `RESET_ECOM_PRICING`

export const GET_SE_CAPACITY_PLANNER_FULFILLED = `GET_SE_CAPACITY_PLANNER_FULFILLED`
export const GET_SE_CAPACITY_PLANNER_FAILED = `GET_SE_CAPACITY_PLANNER_FAILED`
export const GET_SE_CAPACITY_PLANNER_PENDING = `GET_SE_CAPACITY_PLANNER_PENDING`

export const CLEAR_ONLINE_PRODUCTS_USER_SELECTION = `CLEAR_ONLINE_PRODUCTS_USER_SELECTION`

/* Get Servlet Details */
export const GET_SERVLET_DETAILS_FAILED = `GET_SERVLET_DETAILS_FAILED`
export const GET_SERVLET_DETAILS_FULFILLED = `GET_SERVLET_DETAILS_FULFILLED`
export const GET_SERVLET_DETAILS_PENDING = `GET_SERVLET_DETAILS_PENDING`

/* Get Error Message Servlet */
export const GET_ERROR_MESSAGE_SERVLET_PENDING = `GET_ERROR_MESSAGE_SERVLET_PENDING`
export const GET_ERROR_MESSAGE_SERVLET_FAILED = `GET_ERROR_MESSAGE_SERVLET_FAILED`
export const GET_ERROR_MESSAGE_SERVLET_FULFILLED = `GET_ERROR_MESSAGE_SERVLET_FULFILLED`

/* Set Cart */
export const SET_CART_DETAILS_PENDING = `SET_CART_DETAILS_PENDING`
export const SET_CART_DETAILS_FULFILLED = `SET_CART_DETAILS_FULFILLED`
export const SET_CART_DETAILS_FAILED = `SET_CART_DETAILS_FAILED`

/* set crosspollination contact number */
export const SET_CP_CONTACT_NUMBER = `SET_CP_CONTACT_NUMBER`

/* set AEM model */
export const SET_AEM_MODEL_DATA = `SET_AEM_MODEL_DATA`

/* Oakland Bulk */
export const SET_BULK_FORM_SELECTIONS = `SET_BULK_FORM_SELECTIONS`
export const SET_BULK_FORM_HAS_ERROR = `SET_BULK_FORM_HAS_ERROR`
export const SUBMIT_BULK_FORM_SELECTIONS_PENDING = `SUBMIT_BULK_FORM_SELECTIONS_PENDING`
export const SUBMIT_BULK_FORM_SELECTIONS_FULFILLED = `SUBMIT_BULK_FORM_SELECTIONS_FULFILLED`
export const SUBMIT_BULK_FORM_SELECTIONS_FAILED = `SUBMIT_BULK_FORM_SELECTIONS_FAILED`
export const GET_BULK_PICKUP_LOCATIONS_PENDING = `GET_BULK_PICKUP_LOCATIONS_PENDING`
export const GET_BULK_PICKUP_LOCATIONS_FULFILLED = `GET_BULK_PICKUP_LOCATIONS_FULFILLED`
export const GET_BULK_PICKUP_LOCATIONS_FAILED = `GET_BULK_PICKUP_LOCATIONS_FAILED`

/* AEM page JSON */
export const GET_AEM_PAGE_JSON_PENDING = `GET_AEM_PAGE_JSON_PENDING`
export const GET_AEM_PAGE_JSON_FAILED = `GET_AEM_PAGE_JSON_FAILED`
export const GET_AEM_PAGE_JSON_FULFILLED = `GET_AEM_PAGE_JSON_FULFILLED`

/* Guest Bulk */
export const GET_GUEST_BULK_PICKUP_GUIDELINES_PENDING = `GET_GUEST_BULK_PICKUP_GUIDELINES_PENDING`
export const GET_GUEST_BULK_PICKUP_GUIDELINES_FAILED = `GET_GUEST_BULK_PICKUP_GUIDELINES_FAILED`
export const GET_GUEST_BULK_PICKUP_GUIDELINES_FULFILLED = `GET_GUEST_BULK_PICKUP_GUIDELINES_FULFILLED`
export const SET_GUEST_BULK_PICKUP_INCLUDED_CATEGORIES = `SET_GUEST_BULK_PICKUP_INCLUDED_CATEGORIES`
export const GUEST_BULK_PICKUP_CREATE_TICKET_FULFILLED = `GUEST_BULK_PICKUP_CREATE_TICKET_FULFILLED`
export const GUEST_BULK_PICKUP_CREATE_TICKET_PENDING = `GUEST_BULK_PICKUP_CREATE_TICKET_PENDING`
export const GUEST_BULK_PICKUP_CREATE_TICKET_FAILED = `GUEST_BULK_PICKUP_CREATE_TICKET_FAILED`
export const GET_GUEST_BULK_PICKUP_DATES_FULFILLED = `GET_GUEST_BULK_PICKUP_DATES_FULFILLED`
export const GET_GUEST_BULK_PICKUP_DATES_PENDING = `GET_GUEST_BULK_PICKUP_DATES_PENDING`
export const GET_GUEST_BULK_PICKUP_DATES_FAILED = `GET_GUEST_BULK_PICKUP_DATES_FAILED`
export const GUEST_BULK_PICKUP_PRICING_FULFILLED = `GUEST_BULK_PICKUP_PRICING_FULFILLED`
export const GUEST_BULK_PICKUP_PRICING_PENDING = `GUEST_BULK_PICKUP_PRICING_PENDING`
export const GUEST_BULK_PICKUP_PRICING_FAILED = `GUEST_BULK_PICKUP_PRICING_FAILED`

/* Customer Support */
export const SET_SHOW_CALL_BACK_MODAL = `SET_SHOW_CALL_BACK_MODAL`
export const SET_HIDE_CALL_BACK_MODAL = `SET_HIDE_CALL_BACK_MODAL`
export const SET_SHOW_CONTACT_MODAL = `SET_SHOW_CONTACT_MODAL`
export const SET_HIDE_CONTACT_MODAL = `SET_HIDE_CONTACT_MODAL`
export const SET_TOP_SUPPORT_TOPIC_DATA = `SET_TOP_SUPPORT_TOPIC_DATA`
export const SUBMIT_CALL_BACK_REQUEST_PENDING = `SUBMIT_CALL_BACK_REQUEST_PENDING`
export const SUBMIT_CALL_BACK_REQUEST_FULFILLED = `SUBMIT_CALL_BACK_REQUEST_FULFILLED`
export const SUBMIT_CALL_BACK_REQUEST_FAILED = `SUBMIT_CALL_BACK_REQUEST_FAILED`
export const SUBMIT_SERVICE_REQUEST_FORM_FAILED = `SUBMIT_SERVICE_REQUEST_FORM_FAILED`

/* SMS Self Service Flows */
export const GET_TEXT_FOR_CITY_BILLED_BULK_PENDING = `GET_TEXT_FOR_CITY_BILLED_BULK_PENDING`
export const GET_TEXT_FOR_CITY_BILLED_BULK_FAILED = `GET_TEXT_FOR_CITY_BILLED_BULK_FAILED`
export const GET_TEXT_FOR_CITY_BILLED_BULK_FULFILLED = `GET_TEXT_FOR_CITY_BILLED_BULK_FULFILLED`

/* Recycle Quiz Flow */
export const SET_IS_RECYCLE_QUIZ_PAGE = `SET_IS_RECYCLE_QUIZ_PAGE`
export const SET_IS_QUIZ_COMPLETED = `SET_IS_QUIZ_COMPLETED`

export const SET_BUSINESS_TYPE = `SET_BUSINESS_TYPE`
export const SET_FIRST_NAME = `SET_FIRST_NAME`
export const SET_LAST_NAME = `SET_LAST_NAME`
export const SET_APARTMENT_NUMBER = `SET_APARTMENT_NUMBER`
export const GET_CITY_BILLED_ELIGIBILITY_URL_FULFILLED = `GET_CITY_BILLED_ELIGIBILITY_URL_FULFILLED`
export const GET_EXTRA_PICKUP_TICKET_DETAILS_PENDING = `GET_EXTRA_PICKUP_TICKET_DETAILS_PENDING`
export const GET_EXTRA_PICKUP_TICKET_DETAILS_FULFILLED = `GET_EXTRA_PICKUP_TICKET_DETAILS_FULFILLED`
export const GET_EXTRA_PICKUP_TICKET_DETAILS_FAILED = `GET_EXTRA_PICKUP_TICKET_DETAILS_FAILED`
export const GET_ROLLOFF_PRICE_PENDING = `GET_ROLLOFF_PRICE_PENDING`
export const GET_ROLLOFF_PRICE_FAILED = `GET_ROLLOFF_PRICE_FAILED`
export const GET_ROLLOFF_PRICE_FULFILLED = `GET_ROLLOFF_PRICE_FULFILLED`
export const SET_ROLLOFF_PRICE_DATA = `SET_ROLLOFF_PRICE_DATA`
export const GET_REPAIR_AVAILABLE_DATES_FULFILLED = `GET_REPAIR_AVAILABLE_DATES_FULFILLED`
export const GET_REPAIR_AVAILABLE_DATES_FAILED = `GET_REPAIR_AVAILABLE_DATES_FAILED`
export const GET_REPAIR_AVAILABLE_DATES_PENDING = `GET_REPAIR_AVAILABLE_DATES_PENDING`
export const GET_RECYLING_PICKUP_ELIGIBILITY_PENDING = `GET_RECYLING_PICKUP_ELIGIBILITY_PENDING`
export const GET_RECYLING_PICKUP_ELIGIBILITY_FULFILLED = `GET_RECYLING_PICKUP_ELIGIBILITY_FULFILLED`
export const GET_RECYLING_PICKUP_ELIGIBILITY_FAILED = `GET_RECYLING_PICKUP_ELIGIBILITY_FAILED`
export const GET_RECYLING_PICKUP_DATE_PENDING = `GET_RECYLING_PICKUP_DATE_PENDING`
export const GET_RECYLING_PICKUP_DATE_FULFILLED = `GET_RECYLING_PICKUP_DATE_FULFILLED`
export const GET_RECYLING_PICKUP_DATE_FAILED = `GET_RECYLING_PICKUP_DATE_FAILED`
export const GET_RECYLING_PICKUP_SUBMIT_PENDING = `GET_RECYLING_PICKUP_SUBMIT_PENDING`
export const GET_RECYLING_PICKUP_SUBMIT_FULFILLED = `GET_RECYLING_PICKUP_SUBMIT_FULFILLED`
export const GET_RECYLING_PICKUP_SUBMIT_FAILED = `GET_RECYLING_PICKUP_SUBMIT_FAILED`
export const SET_PAD_DATA_SUBMIT_PENDING = `SET_PAD_DATA_SUBMIT_PENDING`
export const SET_PAD_DATA_SUBMIT_FULFILLED = `SET_PAD_DATA_SUBMIT_FULFILLED`
export const SET_PAD_DATA_SUBMIT_FAILED = `SET_PAD_DATA_SUBMIT_FAILED`
export const GET_REPAIR_REQUEST_FULFILLED = `GET_REPAIR_REQUEST_FULFILLED`
export const GET_REPAIR_REQUEST_FAILED = `GET_REPAIR_REQUEST_FAILED`
export const GET_REPAIR_REQUEST_PENDING = `GET_REPAIR_REQUEST_PENDING`
export const GET_REPAIR_PRICING_FULFILLED = `GET_REPAIR_PRICING_FULFILLED`
export const GET_REPAIR_PRICING_FAILED = `GET_REPAIR_PRICING_FAILED`
export const GET_REPAIR_PRICING_PENDING = `GET_REPAIR_PRICING_PENDING`
export const CANCEL_REPAIR_REQUEST_FULFILLED = `CANCEL_REPAIR_REQUEST_FULFILLED`
export const CANCEL_REPAIR_REQUEST_FAILED = `CANCEL_REPAIR_REQUEST_FAILED`
export const CANCEL_REPAIR_REQUEST_PENDING = `CANCEL_REPAIR_REQUEST_PENDING`
export const CANCEL_RECYCLING_PICKUP_FULFILLED = `CANCEL_RECYCLING_PICKUP_FULFILLED`
export const CANCEL_RECYCLING_PICKUP_FAILED = `CANCEL_RECYCLING_PICKUP_FAILED`
export const CANCEL_RECYCLING_PICKUP_PENDING = `CANCEL_RECYCLING_PICKUP_PENDING`
export const SET_CONTAINER_REPLACE_REPAIR = `SET_CONTAINER_REPLACE_REPAIR`
export const GET_REPAIR_ELIGIBILITY_PENDING = `GET_REPAIR_ELIGIBILITY_PENDING`
export const GET_REPAIR_ELIGIBILITY_FAILED = `GET_REPAIR_ELIGIBILITY_FAILED`
export const GET_REPAIR_ELIGIBILITY_FULFILLED = `GET_REPAIR_ELIGIBILITY_FULFILLED`

export const SET_DUPLICATE_ROLL_OFF_REQUEST = `SET_DUPLICATE_ROLL_OFF_REQUEST`
export const RESET_DUPLICATE_ROLL_OFF_REQUEST = `RESET_DUPLICATE_ROLL_OFF_REQUEST`
export const SET_LOCATE_SERVICES_METHOD_TYPE = `SET_LOCATE_SERVICES_METHOD_TYPE`
export const SET_HAS_GEOSUGGESTION = `SET_HAS_GEOSUGGESTION`
export const UPDATE_ACCOUNT_SETTINGS = `UPDATE_ACCOUNT_SETTINGS`
export const SET_ROLLOFF_TICKET_DETAILS_INFO = `SET_ROLLOFF_TICKET_DETAILS_INFO`
export const SET_MY_WM_DROPDOWN_ITEM = `SET_MY_WM_DROPDOWN_ITEM`
export const SET_RENDER_MY_WM_OPTIONS = `SET_RENDER_MY_WM_OPTIONS`
export const SET_USE_MASKED_CUSTOMER_ID = `SET_USE_MASKED_CUSTOMER_ID`
export const SET_USE_PAYMENT_CUSTOMER_ID = `SET_USE_PAYMENT_CUSTOMER_ID`
export const SET_MY_WM_CUSTOMER_ID = `SET_MY_WM_CUSTOMER_ID`
export const SET_CUSTOMER_SELECTED_LANGUAGE = `SET_CUSTOMER_SELECTED_LANGUAGE`
export const GET_MY_WM_PAYMENT_VERIFICATION_FULFILLED = `GET_MY_WM_PAYMENT_VERIFICATION_FULFILLED`
export const GET_MY_WM_PAYMENT_VERIFICATION_FAILED = `GET_MY_WM_PAYMENT_VERIFICATION_FAILED`
export const GET_MY_WM_PAYMENT_VERIFICATION_PENDING = `GET_MY_WM_PAYMENT_VERIFICATION_PENDING`
export const GET_MY_WM_PAYMENT_SIGNATURE_FULFILLED = `GET_MY_WM_PAYMENT_SIGNATURE_FULFILLED`
export const GET_MY_WM_PAYMENT_SIGNATURE_FAILED = `GET_MY_WM_PAYMENT_SIGNATURE_FAILED`
export const GET_MY_WM_PAYMENT_SIGNATURE_PENDING = `GET_MY_WM_PAYMENT_SIGNATURE_PENDING`
export const RESET_MY_WM_PAYMENT_VERIFICATION = `RESET_MY_WM_PAYMENT_VERIFICATION`
export const RESET_MY_WM_PAYMENT_INFO = `RESET_MY_WM_PAYMENT_INFO`
export const SET_ELIGIBILITY_FLAGS = `SET_ELIGIBILITY_FLAGS`
export const SET_RO_REQUEST_SUCCESS_MESSAGE_SHOWN = `SET_RO_REQUEST_SUCCESS_MESSAGE_SHOWN`
export const GET_MY_WM_PAYMENT_BALANCE_FULFILLED = `GET_MY_WM_PAYMENT_BALANCE_FULFILLED`
export const GET_MY_WM_PAYMENT_BALANCE_FAILED = `GET_MY_WM_PAYMENT_BALANCE_FAILED`
export const GET_MY_WM_PAYMENT_BALANCE_PENDING = `GET_MY_WM_PAYMENT_BALANCE_PENDING`
export const GET_MY_WM_PAYMENT_EMAIL_FULFILLED = `GET_MY_WM_PAYMENT_EMAIL_FULFILLED`
export const GET_MY_WM_PAYMENT_EMAIL_FAILED = `GET_MY_WM_PAYMENT_EMAIL_FAILED`
export const GET_MY_WM_PAYMENT_EMAIL_PENDING = `GET_MY_WM_PAYMENT_EMAIL_PENDING`
export const GET_MY_WM_PAYMENT_SERVICES_FULFILLED = `GET_MY_WM_PAYMENT_SERVICES_FULFILLED`
export const GET_MY_WM_PAYMENT_SERVICES_FAILED = `GET_MY_WM_PAYMENT_SERVICES_FAILED`
export const GET_MY_WM_PAYMENT_SERVICES_PENDING = `GET_MY_WM_PAYMENT_SERVICES_PENDING`
export const GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_FULFILLED = `GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_FULFILLED`
export const GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_FAILED = `GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_FAILED`
export const GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_PENDING = `GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_PENDING`
export const CLEAR_DELETE_SCHEDULED_PAYMENT_STATUS = `CLEAR_DELETE_SCHEDULED_PAYMENT_STATUS`
export const CLEAR_CREATE_SCHEDULED_PAYMENT_STATUS = `CLEAR_CREATE_SCHEDULED_PAYMENT_STATUS`
export const SET_MY_WM_PAYMENT_REVIEW_DETAILS = `SET_MY_WM_PAYMENT_REVIEW_DETAILS`
export const UPDATE_MY_WM_PAYMENT_INFO_DETAILS = `UPDATE_MY_WM_PAYMENT_INFO_DETAILS`
export const GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_DUPLICATE = `GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_DUPLICATE`
export const SET_CUSTOMER_ACCOUNT_SOURCE = `SET_CUSTOMER_ACCOUNT_SOURCE`
export const GET_MY_WM_PAYMENT_CANCEL_TRANSACTION = `GET_MY_WM_PAYMENT_CANCEL_TRANSACTION`
export const SET_SUBMENU_SHOWN = `SET_SUBMENU_SHOWN`
export const GET_MY_WM_PREFERENCE_VERIFICATION_FULFILLED = `GET_MY_WM_PREFERENCE_VERIFICATION_FULFILLED`
export const GET_MY_WM_PREFERENCE_VERIFICATION_FAILED = `GET_MY_WM_PREFERENCE_VERIFICATION_FAILED`
export const GET_MY_WM_PREFERENCE_VERIFICATION_PENDING = `GET_MY_WM_PREFERENCE_VERIFICATION_PENDING`
export const GET_CUSTOMER_ACCOUNT_TYPE_FULFILLED = `GET_CUSTOMER_ACCOUNT_TYPE_FULFILLED`
export const GET_CUSTOMER_ACCOUNT_TYPE_FAILED = `GET_CUSTOMER_ACCOUNT_TYPE_FAILED`
export const GET_CUSTOMER_ACCOUNT_TYPE_PENDING = `GET_CUSTOMER_ACCOUNT_TYPE_PENDING`
export const GET_MY_WM_PREFERENCE_VERIFICATION_STORE_JWT = `GET_MY_WM_PREFERENCE_VERIFICATION_STORE_JWT`
export const RESET_CUSTOMER_CONTACTS = `RESET_CUSTOMER_CONTACTS`

export const GET_CUSTOMER_CONTACTS_PENDING = `GET_CUSTOMER_CONTACTS_PENDING`
export const GET_CUSTOMER_CONTACTS_FAILED = `GET_CUSTOMER_CONTACTS_FAILED`
export const GET_CUSTOMER_CONTACTS_FULFILLED = `GET_CUSTOMER_CONTACTS_FULFILLED`
export const GET_CUSTOMER_CONTACTS_STORE_JWT = `GET_CUSTOMER_CONTACTS_STORE_JWT`
export const SET_CUSTOMER_CONTACTS_PENDING = `SET_CUSTOMER_CONTACTS_PENDING`
export const SET_CUSTOMER_CONTACTS_FAILED = `SET_CUSTOMER_CONTACTS_FAILED`
export const SET_CUSTOMER_CONTACTS_FULFILLED = `SET_CUSTOMER_CONTACTS_FULFILLED`
export const UPDATE_CUSTOMER_CONTACTS_PREFERENCES = `UPDATE_CUSTOMER_CONTACTS_PREFERENCES`
export const GET_CONTACT_OPTIONS_PENDING = `GET_CONTACT_OPTIONS_PENDING`
export const GET_CONTACT_OPTIONS_FAILED = `GET_CONTACT_OPTIONS_FAILED`
export const GET_CONTACT_OPTIONS_FULFILLED = `GET_CONTACT_OPTIONS_FULFILLED`
export const SET_CONTACT_OPTIONS_FAILED = `SET_CONTACT_OPTIONS_FAILED`
export const SET_CONTACT_OPTIONS_PENDING = `SET_CONTACT_OPTIONS_PENDING`
export const SET_CONTACT_OPTIONS_FULFILLED = `SET_CONTACT_OPTIONS_FULFILLED`
export const SEND_CONFIRMATION_TEXT_FAILED = `SEND_CONFIRMATION_TEXT_FAILED`
export const SEND_CONFIRMATION_TEXT_PENDING = `SEND_CONFIRMATION_TEXT_PENDING`
export const SEND_CONFIRMATION_TEXT_FULFILLED = `SEND_CONFIRMATION_TEXT_FULFILLED`
export const SET_CUSTOMER_INFO_EDITABLE_STATE = `SET_CUSTOMER_INFO_EDITABLE_STATE`
export const SET_VIEWPORT = `SET_VIEWPORT`
export const SET_EDIT_CONTACT_TYPE = `SET_EDIT_CONTACT_TYPE`
export const SET_USER_STATE = `SET_USER_STATE`
export const SET_USER_SESSION_TOKEN = `SET_USER_SESSION_TOKEN`
export const RESET_SELECTED_ADRRESS_MY_SERVICES = `RESET_SELECTED_ADRRESS_MY_SERVICES`
export const SET_SESSION_INTERVAL_ID = `SET_SESSION_INTERVAL_ID`
export const CREATE_USER_WITH_CREDENTIALS_PENDING = `CREATE_USER_WITH_CREDENTIALS_PENDING`
export const CREATE_USER_WITH_CREDENTIALS_FAILED = `CREATE_USER_WITH_CREDENTIALS_FAILED`
export const CREATE_USER_WITH_CREDENTIALS_FULFILLED = `CREATE_USER_WITH_CREDENTIALS_FULFILLED`
export const GET_EMAIL_CLEANSE_PENDING = `GET_EMAIL_CLEANSE_PENDING`
export const GET_EMAIL_CLEANSE_FULFILLED = `GET_EMAIL_CLEANSE_FULFILLED`
export const GET_EMAIL_CLEANSE_FAILED = `GET_EMAIL_CLEANSE_FAILED`
export const CLEAR_EMAIL_CLEANSE = `CLEAR_EMAIL_CLEANSE`

export const CLEAR_FIRST_TIME_USER = `CLEAR_FIRST_TIME_USER`
export const SET_CONTACT_UPDATE_PENDING = `SET_CONTACT_UPDATE_PENDING`
export const SET_CONTACT_UPDATE_FAILED = `SET_CONTACT_UPDATE_FAILED`
export const SET_CONTACT_UPDATE_FULFILLED = `SET_CONTACT_UPDATE_FULFILLED`
export const CLEAR_DASHBOARD_TOUR = `CLEAR_DASHBOARD_TOUR`
export const UPDATE_USER_PROFILE_PENDING = `UPDATE_USER_PROFILE_PENDING`
export const UPDATE_USER_PROFILE_FAILED = `UPDATE_USER_PROFILE_FAILED`
export const UPDATE_USER_PROFILE_FULFILLED = `UPDATE_USER_PROFILE_FULFILLED`
export const RESET_USER_ACCOUNT = `RESET_USER_ACCOUNT`
export const SET_SELECTED_CURRENCY = `SET_SELECTED_CURRENCY`
export const GET_USER_PAYMENT_PENDING = `GET_USER_PAYMENT_PENDING`
export const GET_USER_PAYMENT_FAILED = `GET_USER_PAYMENT_FAILED`
export const GET_USER_PAYMENT_FULFILLED = `GET_USER_PAYMENT_FULFILLED`
export const GET_USER_PAYMENT_STATUS_PENDING = `GET_USER_PAYMENT_STATUS_PENDING`
export const GET_USER_PAYMENT_STATUS_FAILED = `GET_USER_PAYMENT_STATUS_FAILED`
export const GET_USER_PAYMENT_STATUS_FULFILLED = `GET_USER_PAYMENT_STATUS_FULFILLED`
export const SET_LAST_ACTIVITY_TIME = `SET_LAST_ACTIVITY_TIME`
export const GET_USER_PAYMENT_METHODS_PENDING = `GET_USER_PAYMENT_METHODS_PENDING`
export const GET_USER_PAYMENT_METHODS_FULFILLED = `GET_USER_PAYMENT_METHODS_FULFILLED`
export const SET_SELECTED_PAYMENT_METHOD = `SET_SELECTED_PAYMENT_METHOD`
export const GET_USER_PAYMENT_METHODS_FAILED = `GET_USER_PAYMENT_METHODS_FAILED`
export const GET_PAYMENT_HISTORY_PENDING = `GET_PAYMENT_HISTORY_PENDING`
export const GET_PAYMENT_HISTORY_FULFILLED = `GET_PAYMENT_HISTORY_FULFILLED`
export const CLEAR_PAYMENT_HISTORY = `CLEAR_PAYMENT_HISTORY`
export const GET_PAYMENT_HISTORY_FAILED = `GET_PAYMENT_HISTORY_FAILED`
export const GET_SCHEDULED_PAYMENT_PENDING = `GET_SCHEDULED_PAYMENT_PENDING`
export const GET_SCHEDULED_PAYMENT_FAILED = `GET_SCHEDULED_PAYMENT_FAILED`
export const GET_SCHEDULED_PAYMENT_FULFILLED = `GET_SCHEDULED_PAYMENT_FULFILLED`
export const GET_USER_SELECTED_SCHEDULE_PAYMENTS = `GET_USER_SELECTED_SCHEDULE_PAYMENTS`
export const GET_ALL_SCHEDULED_PAYMENT_PENDING = `GET_ALL_SCHEDULED_PAYMENT_PENDING`
export const GET_ALL_SCHEDULED_PAYMENT_FAILED = `GET_ALL_SCHEDULED_PAYMENT_FAILED`
export const GET_ALL_SCHEDULED_PAYMENT_FULFILLED = `GET_ALL_SCHEDULED_PAYMENT_FULFILLED`
export const SET_BILLING_DETAILS_ACCOUNT = `SET_BILLING_DETAILS_ACCOUNT`
export const SET_BILLING_DETAILS_ADDRESS = `SET_BILLING_DETAILS_ADDRESS`
export const SET_BILLING_DETAILS_DISPLAY_CONTENT = `SET_BILLING_DETAILS_DISPLAY_CONTENT`
export const SET_VIEW_MORE_INVOICES = `SET_VIEW_MORE_INVOICES`
export const SET_VIEW_MORE_PAYMENTS = `SET_VIEW_MORE_PAYMENTS`
export const SET_VIEW_AGED_BALANCE = `SET_VIEW_AGED_BALANCE`
export const DELETE_PAYMENT_METHOD_PENDING = `DELETE_PAYMENT_METHOD_PENDING`
export const DELETE_PAYMENT_METHOD_FULFILLED = `DELETE_PAYMENT_METHOD_FULFILLED`
export const DELETE_PAYMENT_METHOD_FAILED = `DELETE_PAYMENT_METHOD_FAILED`
export const UPDATE_USER_PAYMENT_METHOD_PENDING = `UPDATE_USER_PAYMENT_METHOD_PENDING`
export const UPDATE_USER_PAYMENT_METHOD_FULFILLED = `UPDATE_USER_PAYMENT_METHOD_FULFILLED`
export const UPDATE_USER_PAYMENT_METHOD_FAILED = `UPDATE_USER_PAYMENT_METHOD_FAILED`
export const SAVE_PAYMENT_METHOD_PENDING = `SAVE_PAYMENT_METHOD_PENDING`
export const SAVE_PAYMENT_METHOD_FULFILLED = `SAVE_PAYMENT_METHOD_FULFILLED`
export const SAVE_PAYMENT_METHOD_FAILED = `SAVE_PAYMENT_METHOD_FAILED`
export const SET_GUEST_PAYMENT_METHOD = `SET_GUEST_PAYMENT_METHOD`
export const SET_GUEST_PAYMENT_METHOD_SUBMITTED = `SET_GUEST_PAYMENT_METHOD_SUBMITTED`
export const SET_USER_ADD_PAYMENT_METHOD = `SET_USER_ADD_PAYMENT_METHOD`
export const SET_OVERVIEW_PAYMENT_AMOUNT = `SET_OVERVIEW_PAYMENT_AMOUNT`
export const REVOKE_TOKEN_PENDING = `REVOKE_TOKEN_PENDING`
export const REVOKE_TOKEN_FULFILLED = `REVOKE_TOKEN_FULFILLED`
export const REVOKE_TOKEN_FAILED = `REVOKE_TOKEN_FAILED`
export const REFRESH_TOKEN_PENDING = `REFRESH_TOKEN_PENDING`
export const REFRESH_TOKEN_FULFILLED = `REFRESH_TOKEN_FULFILLED`
export const REFRESH_TOKEN_FAILED = `REFRESH_TOKEN_FAILED`
export const SET_CUSTOMCALENDAR_INPUT_VALUE = `SET_CUSTOMCALENDAR_INPUT_VALUE`
export const SET_CUSTOMCALENDAR_DATE_VALID = `SET_CUSTOMCALENDAR_DATE_VALID`
export const GET_PRODUCTS_URL_PENDING = `GET_PRODUCTS_URL_PENDING`
export const GET_PRODUCTS_URL_FULFILLED = `GET_PRODUCTS_URL_FULFILLED`
export const GET_PRODUCTS_URL_FAILED = `GET_PRODUCTS_URL_FAILED`
export const GET_ESB_TOKENS_PENDING = `GET_ESB_TOKENS_PENDING`
export const GET_ESB_TOKENS_FULFILLED = `GET_ESB_TOKENS_FULFILLED`
export const GET_ESB_TOKENS_FAILED = `GET_ESB_TOKENS_FAILED`
export const GET_ORDER_SUMMARY_PENDING = `GET_ORDER_SUMMARY_PENDING`
export const GET_ORDER_SUMMARY_FULFILLED = `GET_ORDER_SUMMARY_FULFILLED`
export const GET_ORDER_SUMMARY_FAILED = `GET_ORDER_SUMMARY_FAILED`
export const GET_ORDER_DETAILS_PENDING = `GET_ORDER_DETAILS_PENDING`
export const GET_ORDER_DETAILS_FULFILLED = `GET_ORDER_DETAILS_FULFILLED`
export const GET_ORDER_DETAILS_FAILED = `GET_ORDER_DETAILS_FAILED`
export const SET_ORDER_DISPLAY_METHOD = `SET_ORDER_DISPLAY_METHOD`

/* Schedule payment */
export const CREATE_SCHEDULE_PAYMENT_PENDING = `CREATE_SCHEDULE_PAYMENT_PENDING`
export const CREATE_SCHEDULE_PAYMENT_FAILED = `CREATE_SCHEDULE_PAYMENT_FAILED`
export const CREATE_SCHEDULE_PAYMENT_FULFILLED = `CREATE_SCHEDULE_PAYMENT_FULFILLED`
export const DELETE_SCHEDULED_PAYMENT_PENDING = `CANCEL_SCHEDULED_PAYMENT_PENDING`
export const DELETE_SCHEDULED_PAYMENT_FAILED = `CANCEL_SCHEDULED_PAYMENT_FAILED`
export const DELETE_SCHEDULED_PAYMENT_FULFILLED = `CANCEL_SCHEDULED_PAYMENT_FULFILLED`

/* Paperless Billing */
export const ENROLL_PAPERLESS_BILLING_PENDING = `ENROLL_PAPERLESS_BILLING_PENDING`
export const ENROLL_PAPERLESS_BILLING_FAILED = `ENROLL_PAPERLESS_BILLING_FAILED`
export const ENROLL_PAPERLESS_BILLING_FULFILLED = `ENROLL_PAPERLESS_BILLING_FULFILLED`
/* Paperless Preferences */
export const GET_PAPERLESS_PREFERENCES_PENDING = `GET_PAPERLESS_PREFERENCES_PENDING`
export const GET_PAPERLESS_PREFERENCES_FAILED = `GET_PAPERLESS_PREFERENCES_FAILED`
export const GET_PAPERLESS_PREFERENCES_FULFILLED = `GET_PAPERLESS_PREFERENCES_FULFILLED`
export const CLEAR_GET_PAPERLESS_PREFERENCES_STATUS = `CLEAR_GET_PAPERLESS_PREFERENCES_STATUS`
export const SET_PAPERLESS_PREFERENCES_PENDING = `SET_PAPERLESS_PREFERENCES_PENDING`
export const SET_PAPERLESS_PREFERENCES_FAILED = `SET_PAPERLESS_PREFERENCES_FAILED`
export const SET_PAPERLESS_PREFERENCES_FULFILLED = `SET_PAPERLESS_PREFERENCES_FULFILLED`
export const CLEAR_SET_PAPERLESS_PREFERENCES_STATUS = `CLEAR_SET_PAPERLESS_PREFERENCES_STATUS`

/* fulfillment action for bulk enrollment request */
export const BULK_ENROLL_PAPERLESS_BILLING_FULFILLED = `BULK_ENROLL_PAPERLESS_BILLING_FULFILLED`
export const CLEAR_PAPERLESS_BULK_ENROLL_STATUS_MAP = `CLEAR_PAPERLESS_BULK_ENROLL_STATUS_MAP`
export const CLEAR_ENROLL_PAPERLESS_STATUS = `CLEAR_ENROLL_PAPERLESS_STATUS`
export const UN_ENROLL_PAPERLESS_BILLING_PENDING = `UN_ENROLL_PAPERLESS_BILLING_PENDING`
export const UN_ENROLL_PAPERLESS_BILLING_FAILED = `UN_ENROLL_PAPERLESS_BILLING_FAILED`
export const UN_ENROLL_PAPERLESS_BILLING_FULFILLED = `UN_ENROLL_PAPERLESS_BILLING_FULFILLED`
export const CLEAR_UNENROLL_PAPERLESS_STATUS = `CLEAR_UNENROLL_PAPERLESS_STATUS`
export const FETCH_AUTOPAY_PAPERLESS_ATTRIBUTES_PENDING = `FETCH_AUTOPAY_PAPERLESS_ATTRIBUTES_PENDING`
export const FETCH_AUTOPAY_PAPERLESS_ATTRIBUTES_FAILED = `FETCH_AUTOPAY_PAPERLESS_ATTRIBUTES_FAILED`
export const FETCH_AUTOPAY_PAPERLESS_ATTRIBUTES_FULFILLED = `FETCH_AUTOPAY_PAPERLESS_ATTRIBUTES_FULFILLED`
export const FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT = `FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT`
export const FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT_FULFILLED = `FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT_FULFILLED`
export const FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT_FAILED = `FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT_FAILED`
export const FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT_PENDING = `FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT_PENDING`
export const SET_AUTOPAY_PAPERLESS_ACCOUNT_DETAILS = `SET_AUTOPAY_PAPERLESS_ACCOUNT_DETAILS`
export const SET_AUTOPAY_PAPERLESS_ACCOUNT_ADDRESS = `SET_AUTOPAY_PAPERLESS_ACCOUNT_ADDRESS`

/* Autopayment */
export const ENROLL_AUTOPAYMENT_PENDING = `ENROLL_AUTOPAYMENT_PENDING`
export const ENROLL_AUTOPAYMENT_FAILED = `ENROLL_AUTOPAYMENT_FAILED`
export const ENROLL_AUTOPAYMENT_FULFILLED = `ENROLL_AUTOPAYMENT_FULFILLED`
export const UPDATE_AUTOPAYMENT_PENDING = `UPDATE_AUTOPAYMENT_PENDING`
export const UPDATE_AUTOPAYMENT_FAILED = `UPDATE_AUTOPAYMENT_FAILED`
export const UPDATE_AUTOPAYMENT_FULFILLED = `UPDATE_AUTOPAYMENT_FULFILLED`
export const FETCH_AUTOPAYMENT_PENDING = `FETCH_AUTOPAYMENTS_PENDING`
export const FETCH_AUTOPAYMENT_FAILED = `FETCH_AUTOPAYMENTS_FAILED`
export const FETCH_AUTOPAYMENT_FULFILLED = `FETCH_AUTOPAYMENTS_FULFILLED`
export const UNENROLL_AUTOPAYMENT_PENDING = `UNENROLL_AUTOPAYMENT_PENDING`
export const UNENROLL_AUTOPAYMENT_FAILED = `UNENROLL_AUTOPAYMENT_FAILED`
export const UNENROLL_AUTOPAYMENT_FULFILLED = `UNENROLL_AUTOPAYMENT_FULFILLED`
/* fulfillment action for bulk enrollment request */
export const BULK_ENROLL_AUTOPAYMENT_FULFILLED = `BULK_ENROLL_AUTOPAYMENT_FULFILLED`
export const CLEAR_AUTOPAY_BULK_ENROLL_STATUS_MAP = `CLEAR_AUTOPAY_BULK_ENROLL_STATUS_MAP`
export const SET_AUTOPAYMENT_ACCOUNT_DETAILS = `SET_AUTOPAYMENT_ACCOUNT_DETAILS`
export const SET_AUTOPAYMENT_ACCOUNT_ADDRESS = `SET_AUTOPAYMENT_ACCOUNT_ADDRESS`
export const UPDATE_ENROLL_AUTOPAY_PAPERLESS_DETAILS_BY_ACCOUNT = `UPDATE_ENROLL_AUTOPAY_PAPERLESS_DETAILS_BY_ACCOUNT`
export const UPDATE_PAYMENT_METHOD_ID_AUTOPAY = `UPDATE_PAYMENT_METHOD_ID_AUTOPAY`
export const UPDATE_AUTOPAY_ENROLLMENT_BY_ACCOUNT = `UPDATE_AUTOPAY_ENROLLMENT_BY_ACCOUNT`
export const CLEAR_AUTOPAY_ENROLL_DETAILS = `CLEAR_AUTOPAY_ENROLL_DETAILS`
export const CLEAR_AUTOPAY_ENROLL_AND_UNENROLL_STATUS = `CLEAR_AUTOPAY_ENROLL_AND_UNENROLL_STATUS`
export const CLEAR_AUTOPAY_UNENROLL_STATUS = `CLEAR_AUTOPAY_UNENROLL_STATUS`
export const MANAGE_AUTOPAY_DETAILS_BY_ACCOUNT = `MANAGE_AUTOPAY_DETAILS_BY_ACCOUNT`
export const UPDATE_MANAGE_AUTOPAY_DETAILS_OF_ACCOUNT = `UPDATE_MANAGE_AUTOPAY_DETAILS_OF_ACCOUNT`
export const PAID_OUTSTANDING_BALANCE_WHILE_ENROLLING = `PAID_OUTSTANDING_BALANCE_WHILE_ENROLLING`
export const STATE_ALERT_PENDING = `STATE_ALERT_PENDING`
export const STATE_ALERT_FULFILLED = `STATE_ALERT_FULFILLED`
export const STATE_ALERT_FAILED = `STATE_ALERT_FAILED`
export const MINOR_ALERT_VISIBLE = `MINOR_ALERT_VISIBLE`
export const MINOR_ALERT_NOT_VISIBLE = `MINOR_ALERT_NOT_VISIBLE`

/* Get the list of holidays from AEM */
export const GET_HOLIDAY_JSON_PENDING = `GET_HOLIDAY_JSON_PENDING`
export const GET_HOLIDAY_JSON_FULFILLED = `GET_HOLIDAY_JSON_FULFILLED`
export const GET_HOLIDAY_JSON_FAILED = `GET_HOLIDAY_JSON_FAILED`

/* Get Online Products */
export const GET_ONLINE_PRODUCTS_PENDING = `GET_ONLINE_PRODUCTS_PENDING`
export const GET_ONLINE_PRODUCTS_FULFILLED = `GET_ONLINE_PRODUCTS_FULFILLED`
export const GET_ONLINE_PRODUCTS_FAILED = `GET_ONLINE_PRODUCTS_FAILED`
export const SET_ONLINE_SELECTED_PRODUCT = `SET_ONLINE_SELECTED_PRODUCT`
export const CHANGE_WASTE_TYPE = `CHANGE_WASTE_TYPE`

/* Get E-commerce Pricing details */
export const GET_ECOMMERCE_PRICING_PENDING = `GET_ECOMMERCE_PRICING_PENDING`
export const GET_ECOMMERCE_PRICING_FULFILLED = `GET_ECOMMERCE_PRICING_FULFILLED`
export const GET_ECOMMERCE_PRICING_FAILED = `GET_ECOMMERCE_PRICING_FAILED`
export const INITIALIZE_PRODUCT_RESULTS = `INITIALIZE_PRODUCT_RESULTS`

export const SET_ONLINE_PRODUCTS_USER_SELECTION = `SET_ONLINE_PRODUCTS_USER_SELECTION`

/* Fetch Cart */
export const GET_CART_DETAILS_PENDING = `GET_CART_DETAILS_PENDING`
export const GET_CART_DETAILS_FULFILLED = `GET_CART_DETAILS_FULFILLED`
export const GET_CART_DETAILS_FAILED = `GET_CART_DETAILS_FAILED`

/* Broker Account (50+ linked accounts) */
export const SET_BROKER_ACCOUNT_SORT_PREFERENCE = `SET_BROKER_ACCOUNT_SORT_PREFERENCE`
export const SET_LAST_SELECTED_BROKER_ACCOUNT = `SET_LAST_SELECTED_BROKER_ACCOUNT`
export const GET_USER_ACCOUNT_BALANCE_PENDING = `GET_USER_ACCOUNT_BALANCE_PENDING`
export const GET_USER_ACCOUNT_BALANCE_FAILED = `GET_USER_ACCOUNT_BALANCE_FAILED`
export const GET_USER_ACCOUNT_BALANCE_FULFILLED = `GET_USER_ACCOUNT_BALANCE_FULFILLED`
export const GET_BROKER_ACCOUNT_BALANCE_AND_DETAILS_PENDING = `GET_BROKER_ACCOUNT_BALANCE_AND_DETAILS_PENDING`
export const GET_BROKER_ACCOUNT_BALANCE_AND_DETAILS_FAILED = `GET_BROKER_ACCOUNT_BALANCE_AND_DETAILS_FAILED`
export const GET_BROKER_ACCOUNT_BALANCE_AND_DETAILS_FULFILLED = `GET_BROKER_ACCOUNT_BALANCE_AND_DETAILS_FULFILLED`
export const UPDATE_BROKER_ACCOUNT_NICKNAME = `UPDATE_BROKER_ACCOUNT_NICKNAME`
export const GET_SINGLE_USER_PAYMENT_PENDING = `GET_SINGLE_USER_PAYMENT_PENDING`
export const GET_SINGLE_USER_PAYMENT_FAILED = `GET_SINGLE_USER_PAYMENT_FAILED`
export const GET_SINGLE_USER_PAYMENT_FULFILLED = `GET_SINGLE_USER_PAYMENT_FULFILLED`
export const GET_MY_WM_BROKER_PAYMENT_CAPTURE_PENDING = `GET_MY_WM_BROKER_PAYMENT_CAPTURE_PENDING`
export const GET_MY_WM_BROKER_PAYMENT_CAPTURE_FAILED = `GET_MY_WM_BROKER_PAYMENT_CAPTURE_FAILED`
export const GET_MY_WM_BROKER_PAYMENT_CAPTURE_FULFILLED = `GET_MY_WM_BROKER_PAYMENT_CAPTURE_FULFILLED`
export const SET_TOTAL_USER_BALANCE = `SET_TOTAL_USER_BALANCE`

/* location */
export const SET_LAST_VISITED_LOCATION = `SET_LAST_VISITED_LOCATION`

/* app initialization */
export const SET_GUIDED_GUEST_FLOW_INITIALIZED = `SET_GUIDED_GUEST_FLOW_INITIALIZED`

/* Service Change */
export const GET_SERVICE_CHANGE_ELIGIBILITY_PENDING = `GET_SERVICE_CHANGE_ELIGIBILITY_PENDING`
export const GET_SERVICE_CHANGE_ELIGIBILITY_FAILED = `GET_SERVICE_CHANGE_ELIGIBILITY_FAILED`
export const GET_SERVICE_CHANGE_ELIGIBILITY_FULFILLED = `GET_SERVICE_CHANGE_ELIGIBILITY_FULFILLED`
export const GET_SERVICE_CHANGE_RECOMMENDATIONS_PENDING = `GET_SERVICE_CHANGE_RECOMMENDATIONS_PENDING`
export const GET_SERVICE_CHANGE_RECOMMENDATIONS_FAILED = `GET_SERVICE_CHANGE_RECOMMENDATIONS_FAILED`
export const GET_SERVICE_CHANGE_RECOMMENDATIONS_FULFILLED = `GET_SERVICE_CHANGE_RECOMMENDATIONS_FULFILLED`
export const GET_SERVICE_CHANGE_PRICE_PENDING = `GET_SERVICE_CHANGE_PRICE_PENDING`
export const GET_SERVICE_CHANGE_PRICE_FAILED = `GET_SERVICE_CHANGE_PRICE_FAILED`
export const GET_SERVICE_CHANGE_PRICE_FULFILLED = `GET_SERVICE_CHANGE_PRICE_FULFILLED`
export const SUBMIT_SERVICE_CHANGE_PRICE_PENDING = `SUBMIT_SERVICE_CHANGE_PRICE_PENDING`
export const SUBMIT_SERVICE_CHANGE_PRICE_FAILED = `SUBMIT_SERVICE_CHANGE_PRICE_FAILED`
export const SUBMIT_SERVICE_CHANGE_PRICE_FULFILLED = `SUBMIT_SERVICE_CHANGE_PRICE_FULFILLED`
export const CLEAR_SERVICE_CHANGE_DATA = `CLEAR_SERVICE_CHANGE_DATA`
export const CLEAR_SERVICE_CHANGE_API_STATUSES = `CLEAR_SERVICE_CHANGE_API_STATUSES`
export const GET_SERVICE_CHANGE_HISTORY_PENDING = `GET_SERVICE_CHANGE_HISTORY_PENDING`
export const GET_SERVICE_CHANGE_HISTORY_FAILED = `GET_SERVICE_CHANGE_HISTORY_FAILED`
export const GET_SERVICE_CHANGE_HISTORY_FULFILLED = `GET_SERVICE_CHANGE_HISTORY_FULFILLED`
export const CANCEL_SERVICE_CHANGE_PENDING = `CANCEL_SERVICE_CHANGE_PENDING`
export const CANCEL_SERVICE_CHANGE_FAILED = `CANCEL_SERVICE_CHANGE_FAILED`
export const CANCEL_SERVICE_CHANGE_FULFILLED = `CANCEL_SERVICE_CHANGE_FULFILLED`
export const UPDATE_CURRENT_SERVICE_CHANGE_DATA = `UPDATE_CURRENT_SERVICE_CHANGE_DATA`
export const CLEAR_CURRENT_SERVICE_CHANGE_DATA = `CLEAR_CURRENT_SERVICE_CHANGE_DATA`
export const GET_SERVICE_CHANGE_DATES_PENDING = `GET_SERVICE_CHANGE_DATES_PENDING`
export const GET_SERVICE_CHANGE_DATES_FAILED = `GET_SERVICE_CHANGE_DATES_FAILED`
export const GET_SERVICE_CHANGE_DATES_FULFILLED = `GET_SERVICE_CHANGE_DATES_FULFILLED`

/* Extra Pickup */
export const GET_EXTRA_PICKUP_ELIGIBILITY_PENDING = `GET_EXTRA_PICKUP_ELIGIBILITY_PENDING`
export const GET_EXTRA_PICKUP_ELIGIBILITY_FAILED = `GET_EXTRA_PICKUP_ELIGIBILITY_FAILED`
export const GET_EXTRA_PICKUP_ELIGIBILITY_FULFILLED = `GET_EXTRA_PICKUP_ELIGIBILITY_FULFILLED`
export const GET_EXTRA_PICKUP_DATES_PENDING = `GET_EXTRA_PICKUP_DATES_PENDING`
export const GET_EXTRA_PICKUP_DATES_FAILED = `GET_EXTRA_PICKUP_DATES_FAILED`
export const GET_EXTRA_PICKUP_DATES_FULFILLED = `GET_EXTRA_PICKUP_DATES_FULFILLED`
export const GET_EXTRA_PICKUP_PRICING_PENDING = `GET_EXTRA_PICKUP_PRICING_PENDING`
export const GET_EXTRA_PICKUP_PRICING_FAILED = `GET_EXTRA_PICKUP_PRICING_FAILED`
export const GET_EXTRA_PICKUP_PRICING_FULFILLED = `GET_EXTRA_PICKUP_PRICING_FULFILLED`
export const SUBMIT_EXTRA_PICKUP_PENDING = `SUBMIT_EXTRA_PICKUP_PENDING`
export const SUBMIT_EXTRA_PICKUP_FAILED = `SUBMIT_EXTRA_PICKUP_FAILED`
export const SUBMIT_EXTRA_PICKUP_FULFILLED = `SUBMIT_EXTRA_PICKUP_FULFILLED`
export const SUBMIT_UPDATE_EXTRA_PICKUP_PENDING = `SUBMIT_UPDATE_EXTRA_PICKUP_PENDING`
export const SUBMIT_UPDATE_EXTRA_PICKUP_FAILED = `SUBMIT_UPDATE_EXTRA_PICKUP_FAILED`
export const SUBMIT_UPDATE_EXTRA_PICKUP_FULFILLED = `SUBMIT_UPDATE_EXTRA_PICKUP_FULFILLED`
export const CLEAR_EXTRA_PICKUP_DATA = `CLEAR_EXTRA_PICKUP_DATA`
export const GET_EXTRA_PICKUP_HISTORY_PENDING = `GET_EXTRA_PICKUP_HISTORY_PENDING`
export const GET_EXTRA_PICKUP_HISTORY_FAILED = `GET_EXTRA_PICKUP_HISTORY_FAILED`
export const GET_EXTRA_PICKUP_HISTORY_FULFILLED = `GET_EXTRA_PICKUP_HISTORY_FULFILLED`
export const CANCEL_EXTRA_PICKUP_PENDING = `CANCEL_EXTRA_PICKUP_PENDING`
export const CANCEL_EXTRA_PICKUP_FAILED = `CANCEL_EXTRA_PICKUP_FAILED`
export const CANCEL_EXTRA_PICKUP_FULFILLED = `CANCEL_EXTRA_PICKUP_FULFILLED`
// export const CANCEL_EXTRA_PICKUP_PENDING = `CANCEL_EXTRA_PICKUP_PENDING`
// export const CANCEL_EXTRA_PICKUP_FAILED = `CANCEL_EXTRA_PICKUP_FAILED`
// export const CANCEL_EXTRA_PICKUP_FULFILLED = `CANCEL_EXTRA_PICKUP_FULFILLED`

/* Bulk Pickup */
export const GET_BULK_PICKUP_ELIGIBILITY_PENDING = `GET_BULK_PICKUP_ELIGIBILITY_PENDING`
export const GET_BULK_PICKUP_ELIGIBILITY_FAILED = `GET_BULK_PICKUP_ELIGIBILITY_FAILED`
export const GET_BULK_PICKUP_ELIGIBILITY_FULFILLED = `GET_BULK_PICKUP_ELIGIBILITY_FULFILLED`
export const GET_BULK_PICKUP_DATES_PENDING = `GET_BULK_PICKUP_DATES_PENDING`
export const GET_BULK_PICKUP_DATES_FAILED = `GET_BULK_PICKUP_DATES_FAILED`
export const GET_BULK_PICKUP_DATES_FULFILLED = `GET_BULK_PICKUP_DATES_FULFILLED`
export const GET_BULK_PICKUP_GUIDELINES_PENDING = `GET_BULK_PICKUP_GUIDELINES_PENDING`
export const GET_BULK_PICKUP_GUIDELINES_FAILED = `GET_BULK_PICKUP_GUIDELINES_FAILED`
export const GET_BULK_PICKUP_GUIDELINES_FULFILLED = `GET_BULK_PICKUP_GUIDELINES_FULFILLED`
export const GET_BULK_PICKUP_PRICING_PENDING = `GET_BULK_PICKUP_PRICING_PENDING`
export const GET_BULK_PICKUP_PRICING_FAILED = `GET_BULK_PICKUP_PRICING_FAILED`
export const GET_BULK_PICKUP_PRICING_FULFILLED = `GET_BULK_PICKUP_PRICING_FULFILLED`
export const SUBMIT_BULK_PICKUP_PENDING = `SUBMIT_BULK_PICKUP_PENDING`
export const SUBMIT_BULK_PICKUP_FAILED = `SUBMIT_BULK_PICKUP_FAILED`
export const SUBMIT_BULK_PICKUP_FULFILLED = `SUBMIT_BULK_PICKUP_FULFILLED`
export const CLEAR_BULK_PICKUP_DATA = `CLEAR_BULK_PICKUP_DATA`
export const CANCEL_BULK_PICKUP_PENDING = `CANCEL_BULK_PICKUP_PENDING`
export const CANCEL_BULK_PICKUP_FAILED = `CANCEL_BULK_PICKUP_FAILED`
export const CANCEL_BULK_PICKUP_FULFILLED = `CANCEL_BULK_PICKUP_FULFILLED`
export const SET_BULK_PICKUP_ITEMS = `SET_BULK_PICKUP_ITEMS`

/* Missed Pickup */
export const REPORT_MISSED_PICKUP_PENDING = `REPORT_MISSED_PICKUP_PENDING`
export const REPORT_MISSED_PICKUP_FAILED = `REPORT_MISSED_PICKUP_FAILED`
export const REPORT_MISSED_PICKUP_FULFILLED = `REPORT_MISSED_PICKUP_FULFILLED`
export const CANCEL_MISSED_PICKUP_PENDING = `CANCEL_MISSED_PICKUP_PENDING`
export const CANCEL_MISSED_PICKUP_FAILED = `CANCEL_MISSED_PICKUP_FAILED`
export const CANCEL_MISSED_PICKUP_FULFILLED = `CANCEL_MISSED_PICKUP_FULFILLED`
export const SET_REQUEST_MISSED_PICKUP_ORDER_ID = `SET_REQUEST_MISSED_PICKUP_ORDER_ID`
export const SET_REQUEST_MISSED_PICKUP_TRACKING_ID = `SET_REQUEST_MISSED_PICKUP_TRACKING_ID`
export const SET_REQUEST_MISSED_PICKUP_CUSTOMER_ID = `SET_REQUEST_MISSED_PICKUP_CUSTOMER_ID`
export const SET_REQUEST_MISSED_PICKUP_SUCCESS = `SET_REQUEST_MISSED_PICKUP_SUCCESS`
export const SET_REQUEST_MISSED_PICKUP_ERROR = `SET_REQUEST_MISSED_PICKUP_ERROR`
export const SET_GUEST_MISSED_PICKUP_TOKEN = `SET_GUEST_MISSED_PICKUP_TOKEN`
export const SET_IVR_TEXT_ID_FOR_ETA = `SET_IVR_TEXT_ID_FOR_ETA`

/* Pause Service */
export const SUBMIT_PAUSE_SERVICE_PENDING = `SUBMIT_PAUSE_SERVICE_PENDING`
export const SUBMIT_PAUSE_SERVICE_FAILED = `SUBMIT_PAUSE_SERVICE_FAILED`
export const SUBMIT_PAUSE_SERVICE_FULFILLED = `SUBMIT_PAUSE_SERVICE_FULFILLED`

export const CANCEL_PAUSE_SERVICE_PENDING = `CANCEL_PAUSE_SERVICE_PENDING`
export const CANCEL_PAUSE_SERVICE_FAILED = `CANCEL_PAUSE_SERVICE_FAILED`
export const CANCEL_PAUSE_SERVICE_FULFILLED = `CANCEL_PAUSE_SERVICE_FULFILLED`

/* Service change automation tagging metrics */
export const RESET_SCA_VALUES = `RESET_SCA_VALUES`
export const SET_SCA_VALUES = `SET_SCA_VALUES`

/* Global error handling component data */
export const SET_ERROR_HANDLING_POPUP_DATA = `SET_ERROR_HANDLING_POPUP_DATA`

export const CLEAR_SERVICES_ORDER_HISTORY = `CLEAR_SERVICES_ORDER_HISTORY`
export const CLEAR_CANCEL_ORDER_STATUS = `CLEAR_CANCEL_ORDER_STATUS`
export const CLEAR_CLOUD_CRAZE_ORDER_HISTORY = `CLEAR_CLOUD_CRAZE_ORDER_HISTORY`

/* for use with webworker fetching and session caching of API responses */
export const SET_DATA_CACHE = `SET_DATA_CACHE`
export const CLEAR_DATA_CACHE = `CLEAR_DATA_CACHE`
export const UPDATE_ORDER_HISTORY_DATA_CACHE = `UPDATE_ORDER_HISTORY_DATA_CACHE`

/* combined order history */
export const GET_COMBINED_ORDER_HISTORY_PENDING = `GET_COMBINED_ORDER_HISTORY_PENDING`
export const GET_COMBINED_ORDER_HISTORY_FAILED = `GET_COMBINED_ORDER_HISTORY_FAILED`
export const GET_COMBINED_ORDER_HISTORY_FULFILLED = `GET_COMBINED_ORDER_HISTORY_FULFILLED`
export const CLEAR_COMBINED_ORDER_HISTORY = `CLEAR_COMBINED_ORDER_HISTORY`

/* pending charges */
export const GET_PENDING_CHARGES_PENDING = `GET_PENDING_CHARGES_PENDING`
export const GET_PENDING_CHARGES_FAILED = `GET_PENDING_CHARGES_FAILED`
export const GET_PENDING_CHARGES_FULFILLED = `GET_PENDING_CHARGES_FULFILLED`

/* survey questions */
export const SURVEY_START_PENDING = `SURVEY_START_PENDING`
export const SURVEY_START_FAILED = `SURVEY_START_FAILED`
export const SURVEY_START_FULFILLED = `SURVEY_START_FULFILLED`
export const SURVEY_RATING = `SURVEY_RATING`
export const PROCESS_SURVEY_PENDING = `PROCESS_SURVEY_PENDING`
export const PROCESS_SURVEY_FAILED = `PROCESS_SURVEY_FAILED`
export const PROCESS_SURVEY_FULFILLED = `PROCESS_SURVEY_FULFILLED`
export const SURVEY_EXPERIENCE = `SURVEY_EXPERIENCE`
export const IS_SURVEY_HIDDEN = `IS_SURVEY_HIDDEN`
export const CLEAR_SURVEY_DATA = `CLEAR_SURVEY_DATA`

/* Billing Details */
export const GET_BILLING_DETAILS_PENDING = `GET_BILLING_DETAILS_PENDING`
export const GET_BILLING_DETAILS_FAILED = `GET_BILLING_DETAILS_FAILED`
export const GET_BILLING_DETAILS_FULFILLED = `GET_BILLING_DETAILS_FULFILLED`

/* SMS Self Service Flows */
export const GET_TEXT_FOR_BULK_PENDING = `GET_TEXT_FOR_BULK_PENDING`
export const GET_TEXT_FOR_BULK_FAILED = `GET_TEXT_FOR_BULK_FAILED`
export const GET_TEXT_FOR_BULK_FULFILLED = `GET_TEXT_FOR_BULK_FULFILLED`
export const GET_TEXT_FOR_ROLLOFF_PENDING = `GET_TEXT_FOR_ROLLOFF_PENDING`
export const GET_TEXT_FOR_ROLLOFF_FAILED = `GET_TEXT_FOR_ROLLOFF_FAILED`
export const GET_TEXT_FOR_ROLLOFF_FULFILLED = `GET_TEXT_FOR_ROLLOFF_FULFILLED`
export const GET_TEXT_FOR_XPU_PENDING = `GET_TEXT_FOR_XPU_PENDING`
export const GET_TEXT_FOR_XPU_FAILED = `GET_TEXT_FOR_XPU_FAILED`
export const GET_TEXT_FOR_XPU_FULFILLED = `GET_TEXT_FOR_XPU_FULFILLED`
export const GET_TEXT_FOR_CONTAINER_REPAIR_PENDING = `GET_TEXT_FOR_CONTAINER_REPAIR_PENDING`
export const GET_TEXT_FOR_CONTAINER_REPAIR_FAILED = `GET_TEXT_FOR_CONTAINER_REPAIR_FAILED`
export const GET_TEXT_FOR_CONTAINER_REPAIR_FULFILLED = `GET_TEXT_FOR_CONTAINER_REPAIR_FULFILLED`
export const GET_TEXT_FOR_VIEW_ETA_PENDING = `GET_TEXT_FOR_VIEW_ETA_PENDING`
export const GET_TEXT_FOR_VIEW_ETA_FAILED = `GET_TEXT_FOR_VIEW_ETA_FAILED`
export const GET_TEXT_FOR_VIEW_ETA_FULFILLED = `GET_TEXT_FOR_VIEW_ETA_FULFILLED`

/* Guest Container Issue */
export const RESET_GUEST_CONTAINER_REPAIR = `RESET_GUEST_CONTAINER_REPAIR`
export const SET_GUEST_CONTAINER_REPAIR_SELECTION = `SET_GUEST_CONTAINER_REPAIR_SELECTION`
export const GET_GUEST_CONTAINER_REPAIR_DATES_FULFILLED = `GET_GUEST_CONTAINER_REPAIR_DATES_FULFILLED`
export const GET_GUEST_CONTAINER_REPAIR_DATES_PENDING = `GET_GUEST_CONTAINER_REPAIR_DATES_PENDING`
export const GET_GUEST_CONTAINER_REPAIR_DATES_FAILED = `GET_GUEST_CONTAINER_REPAIR_DATES_FAILED`
export const RESET_GUEST_CONTAINER_REPAIR_DATES = `RESET_GUEST_CONTAINER_REPAIR_DATES`
export const GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_PENDING = `GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_PENDING`
export const GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_FAILED = `GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_FAILED`
export const GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_FULFILLED = `GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_FULFILLED`
export const GET_GUEST_CONTAINER_REPAIR_SERVICES_PENDING = `GET_GUEST_CONTAINER_REPAIR_SERVICES_PENDING`
export const GET_GUEST_CONTAINER_REPAIR_SERVICES_FAILED = `GET_GUEST_CONTAINER_REPAIR_SERVICES_FAILED`
export const GET_GUEST_CONTAINER_REPAIR_SERVICES_FULFILLED = `GET_GUEST_CONTAINER_REPAIR_SERVICES_FULFILLED`
export const GET_GUEST_CONTAINER_REPAIR_PRICING_PENDING = `GET_GUEST_CONTAINER_REPAIR_PRICING_PENDING`
export const GET_GUEST_CONTAINER_REPAIR_PRICING_FAILED = `GET_GUEST_CONTAINER_REPAIR_PRICING_FAILED`
export const GET_GUEST_CONTAINER_REPAIR_PRICING_FULFILLED = `GET_GUEST_CONTAINER_REPAIR_PRICING_FULFILLED`
export const RESET_GUEST_CONTAINER_REPAIR_PRICING = `RESET_GUEST_CONTAINER_REPAIR_PRICING`
export const GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_PENDING = `GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_PENDING`
export const GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_FAILED = `GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_FAILED`
export const GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_FULFILLED = `GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_FULFILLED`
export const RESET_GUEST_CONTAINER_REPAIR_TICKET = `RESET_GUEST_CONTAINER_REPAIR_TICKET`

/* Energy Surcharge */
export const GET_ENERGY_SURCHARGE_CALCULATOR_RESULT_PENDING = `GET_ENERGY_SURCHARGE_CALCULATOR_RESULT_PENDING`
export const GET_ENERGY_SURCHARGE_CALCULATOR_RESULT_FAILED = `GET_ENERGY_SURCHARGE_CALCULATOR_RESULT_FAILED`
export const GET_ENERGY_SURCHARGE_CALCULATOR_RESULT_FULFILLED = `GET_ENERGY_SURCHARGE_CALCULATOR_RESULT_FULFILLED`
export const RESET_ENERGY_SURCHARGE_CALCULATOR_RESULT = `RESET_ENERGY_SURCHARGE_CALCULATOR_RESULT`

/* Article Data */
export const GET_ARTICLE_DETAILS_PENDING = `GET_ARTICLE_DATA_PENDING`
export const GET_ARTICLE_DETAILS_FAILED = `GET_ARTICLE_DATA_FAILED`
export const GET_ARTICLE_DETAILS_FULFILLED = `GET_ARTICLE_DATA_FULFILLED`

/* Bulk Container Repair */
export const SET_CONTAINER_REPAIR = `SET_CONTAINER_REPAIR`
export const SET_CONTAINER_BULK = `SET_CONTAINER_BULK`

/* Paymentus signature */
export const GET_PAYMENTUS_SIGNATURE_FULFILLED = `GET_PAYMENTUS_SIGNATURE_FULFILLED`
export const GET_PAYMENTUS_SIGNATURE_FAILED = `GET_PAYMENTUS_SIGNATURE_FAILED`
export const GET_PAYMENTUS_SIGNATURE_PENDING = `GET_PAYMENTUS_SIGNATURE_PENDING`

/* Processing Fee */
export const GET_PROCESSING_FEE_FULFILLED = `GET_PROCESSING_FEE_FULFILLED`
export const GET_PROCESSING_FEE_FAILED = `GET_PROCESSING_FEE_FAILED`
export const GET_PROCESSING_FEE_PENDING = `GET_PROCESSING_FEE_PENDING`
export const RESET_PROCESSING_FEE = `RESET_PROCESSING_FEE`

/* Conatiner Repair */
export const SET_IS_LID_CHECKED = `SET_IS_LID_CHECKED`
export const SET_IS_CONTAINER_CHECKED = `SET_IS_CONTAINER_CHECKED`
export const SET_IS_WHEELS_CHECKED = `SET_IS_WHEELS_CHECKED`
export const SET_CONTAINER_REVIEW_PAGE_OPEN = `SET_CONTAINER_REVIEW_PAGE_OPEN`
export const RESET_CONTAINER_DAMAGE_DATA = `RESET_CONTAINER_DAMAGE_DATA`
export const SET_NOTES_EXIST = `SET_NOTES_EXIST`

/* Setting bulk active state */
export const INCREMENT_BULK_PICKUP_ACTIVE_STEP = `INCREMENT_BULK_PICKUP_ACTIVE_STEP`
export const DECREMENT_BULK_PICKUP_ACTIVE_STEP = `DECREMENT_BULK_PICKUP_ACTIVE_STEP`
export const RESET_BULK_PICKUP_ACTIVE_STEP = `RESET_BULK_PICKUP_ACTIVE_STEP`
export const SET_BULK_CUSTOMER_SELECTED_OPTIONS = `SET_BULK_CUSTOMER_SELECTED_OPTIONS`
