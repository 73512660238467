import * as ActionTypes from "constants-lib/actionTypes"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import _ from "lodash"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.GET_TICKET_PICKUP_INFO_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.GET_TICKET_PICKUP_INFO_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_TICKET_PICKUP_INFO_FULFILLED,
  payload: data,
})

export const clearPickupData = () => ({
  type: ActionTypes.CLEAR_TICKET_PICKUP_INFO,
})

export const getTicketPickupInfo =
  (data, customerId) => (dispatch, getState) => {
    const api = `TICKET_PICKUP_INFO`
    const template = URITemplate(urls.url[api])
    const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)
    const lang = _.get(getState(), `siteLanguage.language`, ``)

    const url = template.expand({
      userId,
      customerId,
      lang,
    })

    const config = {
      headers: {},
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return getTicketPickupInfoHelper(data, url, config, dispatch)
    })
  }

export const getTicketPickupInfoHelper = (data, url, config, dispatch) => {
  dispatch(pendingAC())

  return axios
    .create(config)
    .post(url, data)
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      dispatch(
        fulfilledAC({
          ticketPickupInfo: response.data,
          statusCode,
        }),
      )
      return response
    })
    .catch((error) => {
      dispatch(failedAC(_.get(error, `response.status`, ``)))
    })
}

export const clearPickupInfoState = () => (dispatch) => {
  dispatch(clearPickupData())
}
