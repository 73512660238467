import { isProd } from "utils-lib/builds"

export const profileIdForAPIs = () => {
  if (isProd()) {
    return {
      profileId: {
        CATEGORIES: `eBizCN_714`,
        OPTIONS: `eBizCN_714`,
        GET_CONTACTS: `eBizCN_704`,
        PUT_CONTACTS: `eBizCN_713`,
        GET_EMAIL_CLEANSE: `eBiz_725`,
        GET_LOCALSITE: `eBiz_733`,
      },
    }
  }

  return {
    profileId: {
      CATEGORIES: `eBizCN_314`,
      OPTIONS: `eBizCN_314`,
      GET_CONTACTS: `eBizCN_304`,
      PUT_CONTACTS: `eBizCN_313`,
      GET_EMAIL_CLEANSE: `eBiz_325`,
      GET_LOCALSITE: `eBiz_333`,
    },
  }
}
