import { genericPropertyMapper } from "utils-lib/objects/genericPropertyMapper"
import { toSnakeFromCamel } from "utils-lib/strings/toSnakeFromCamel"

/**
 * Transforms the keys of an input object from camelCase to snake_case.
 * @param {Object} inObj - The object whose keys are to be transformed.
 * @returns {Object} A new object with the same values as the input object, but with keys transformed from camelCase to snake_case.
 * @example
 * // Transforms keys from camelCase to snake_case
 * const newObj = mapToSnakeFromCamel(oldObj);
 * `Note: This function returns null if the input object is null or undefined.`
 */
// eslint-disable-next-line import/prefer-default-export
export const mapToSnakeFromCamel = (inObj) => {
  if (inObj === undefined || inObj === null) {
    return null
  }

  return genericPropertyMapper(inObj, toSnakeFromCamel)
}
