import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  GET_SERVICE_CHANGE_DATES_PENDING,
  GET_SERVICE_CHANGE_DATES_FAILED,
  GET_SERVICE_CHANGE_DATES_FULFILLED,
} from "constants-lib/actionTypes"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"
import { MOCK_SERVICE_CHANGE_INFORMATION } from "utils-lib/mocks"

export const pendingAC = () => ({
  type: GET_SERVICE_CHANGE_DATES_PENDING,
})

export const failedAC = () => ({
  type: GET_SERVICE_CHANGE_DATES_FAILED,
})

export const fulfilledAC = (data) => ({
  type: GET_SERVICE_CHANGE_DATES_FULFILLED,
  payload: data,
})

export const getServiceChangeDates =
  ({ customerId, userId, lang }) =>
  (dispatch, getState) => {
    const api = `SERVICE_CHANGE_DATES`
    const template = URITemplate(urls.url[api])

    const url = MOCK_SERVICE_CHANGE_INFORMATION
      ? `${window.origin}/mock/service-change-get-dates-response.json`
      : template.expand({
          customerId,
          userId,
          lang,
        })

    const config = {
      method: `GET`,
      headers: {},
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return getServiceChangeDatesHelper(url, config, dispatch)
    })
  }

export const getServiceChangeDatesHelper = (url, config, dispatch) => {
  dispatch(pendingAC())

  return axios(url, config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      return response.data
    })
    .catch(() => {
      dispatch(failedAC())
    })
}
