import PropTypes from "prop-types"

export const H6 = ({ children, className = "" }) => (
  <div
    className={`H6 maax-font-weight-bold ${className}`}
    style={{
      fontSize: `18px`,
      lineHeight: `1.22`,
      color: `#4f4f58`,
    }}
    data-testid="H6"
  >
    {children}
  </div>
)

H6.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

H6.defaultProps = {
  children: null,
  className: "",
}
