import isFunction from "lodash/isFunction"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import defer from "lodash/defer"
import { Component } from "react"
import PropTypes from "prop-types"
import css from "classnames"
import Select from "react-select"
import { withLocalization } from "hoc-lib/localization"
import InfoMessage from "component-lib/Geosuggest/InfoMessage"
import "./Dropdown.scss"

export const strings = {
  en_CA: {
    PLACEHOLDER: `Select...`,
  },
  fr_CA: {
    PLACEHOLDER: `Sélectionner...`,
  },
}

export class Dropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedOption: this.props.selectFirstOption
        ? get(this.props, `options[0]`)
        : null,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.resetSelected && this.state.selectedOption) {
      if (!this.props.selectFirstOption) {
        this.setSelectedOption(null)
      } else if (
        this.props.selectFirstOption &&
        this.state.selectedOption !== get(this.props, `options[0]`)
      ) {
        this.setSelectedOption(get(this.props, `options[0]`))
      }
    }

    if (
      this.props.selectFirstOption &&
      this.props.storeSelectedOption &&
      prevProps.options.length !== this.props.options.length
    ) {
      this.setSelectedOption(get(this.props, `options[0]`))
    }
  }

  setSelectedOption(selectedOption) {
    this.setState({
      selectedOption,
    })
  }

  render() {
    const {
      strings,
      allowOneOption,
      className,
      components,
      errorMessage,
      hasError,
      isSearchable,
      isSelected,
      filterOption,
      label,
      onSelect,
      options,
      storeSelectedOption,
      value,
      maxMenuHeight,
      ariaLabel,
    } = this.props

    const { selectedOption } = this.state

    const errorInfoMessage = errorMessage && (
      <InfoMessage>
        <div className="maax-font-weight-regular error">{errorMessage}</div>
      </InfoMessage>
    )

    const classNames = css(`Dropdown`, {
      [className]: className,
      [`Dropdown--error`]: errorMessage || hasError,
      [`Dropdown--empty`]: !selectedOption && !value,
    })

    return options.length === 1 && !allowOneOption ? (
      <>
        <div
          className="wm-font-weight-regular Dropdown__selection--single"
          data-testid="Dropdown - !allowOneOption"
        >
          {options[0].label}
        </div>
        {errorInfoMessage}
      </>
    ) : (
      <div
        data-testid="Dropdown"
        className="Dropdown__container"
        aria-label={ariaLabel}
        style={{
          position: `relative`,
        }}
      >
        <div data-testid="Dropdown - Select">
          <Select
            aria-label={label || strings.PLACEHOLDER}
            className={classNames}
            components={
              !isEmpty(components)
                ? components
                : {
                    Option: ({ data, innerProps }) => {
                      const classNames = css(
                        `Dropdown__option maax-font-weight-bold text-nowrap ${get(
                          data,
                          `classes`,
                          ``,
                        )}`,
                        {
                          // Default to false
                          [`Dropdown__option--selected`]: isFunction(isSelected)
                            ? isSelected(data)
                            : get(data, `value`, `a`) ===
                              get(value, `value`, `b`),
                        },
                      )

                      return (
                        <div
                          {...innerProps}
                          className={classNames}
                          style={{
                            height: `3rem`,
                            paddingLeft: `.625rem`,
                            paddingRight: `.625rem`,
                            paddingTop: `.875rem`,
                            overflow: `hidden`,
                            textOverflow: `ellipsis`,
                          }}
                          title={get(data, `label`, ``)}
                        >
                          {get(data, `label`, ``)}
                        </div>
                      )
                    },
                    SingleValue: ({ data }) => (
                      <div
                        className={`label-width maax-font-weight-bold text-nowrap ${
                          !isSearchable ? "w-100 pt-2" : ""
                        }`}
                        style={{
                          overflow: `hidden`,
                          textOverflow: `ellipsis`,
                        }}
                        title={get(data, `label`, ``)}
                      >
                        {get(data, `label`, ``)}
                      </div>
                    ),
                  }
            }
            filterOption={filterOption}
            isSearchable={isSearchable}
            maxMenuHeight={maxMenuHeight}
            onChange={(option) => {
              if (storeSelectedOption) {
                this.setState({
                  selectedOption: option,
                })
              }

              onSelect(option.value, option)
            }}
            onMenuClose={() => {
              defer(() => {
                document.querySelectorAll(`input`).forEach((el) => {
                  el.blur()
                })
              })
            }}
            options={options}
            placeholder={label || strings.PLACEHOLDER}
            value={value || selectedOption}
            styles={{
              dropdownIndicator: (base, state) => ({
                ...base,
                transform: state.selectProps.menuIsOpen
                  ? `rotate(180deg)`
                  : null,
                color: `#1d890a !important`,
              }),
            }}
          />
        </div>
        {errorInfoMessage}
      </div>
    )
  }
}

Dropdown.propTypes = {
  strings: PropTypes.object.isRequired,
  className: PropTypes.string,
  components: PropTypes.array,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isSelected: PropTypes.bool,
  filterOption: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  storeSelectedOption: PropTypes.bool,
  value: PropTypes.object.isRequired,
  maxMenuHeight: PropTypes.string,
  ariaLabel: PropTypes.string,
  selectFirstOption: PropTypes.bool,
  resetSelected: PropTypes.bool,
  data: PropTypes.object,
  innerProps: PropTypes.object,
  allowOneOption: PropTypes.bool,
}

Dropdown.defaultProps = {
  className: "",
  label: "",
  innerProps: {},
  allowOneOption: false,
  selectFirstOption: false,
  components: [],
  errorMessage: "",
  data: {},
  ariaLabel: "",
  maxMenuHeight: "",
  storeSelectedOption: false,
  hasError: false,
  filterOption: "",
  isSearchable: false,
  isSelected: false,
  resetSelected: false,
}

export default withLocalization(strings)(Dropdown)
