import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { generateUniqueID } from "utils-lib/requests"
import { urls } from "utils-lib/builds"
import {
  GET_OUTAGES_PENDING,
  GET_OUTAGES_FAILED,
  GET_OUTAGES_FULFILLED,
} from "constants-lib/actionTypes"

export const pendingGetOutages = () => ({
  type: GET_OUTAGES_PENDING,
})

export const failedGetOutages = (data) => ({
  type: GET_OUTAGES_FAILED,
  payload: data,
})

export const fulfilledGetOutages = (data) => ({
  type: GET_OUTAGES_FULFILLED,
  payload: data,
})

/**
 * Fetches outage information for a given site and dispatches relevant actions based on the request's outcome.
 * @param {string} siteId - The unique identifier for the site to fetch outage information for.
 * @returns {Function} A function that takes a dispatch function as an argument and returns a Promise representing the asynchronous fetch operation.
 */
export const getOutages = (siteId) => (dispatch) => {
  const api = `GET_OUTAGES`
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    siteId,
  })
  const apiKey = get(urls, `apiKey.ESB[${api}]`, ``)
  const config = {
    url,
    method: `get`,
    headers: {
      "Content-Type": `application/json`,
      ClientId: apiKey,
      "Request-Tracking-Id": generateUniqueID(),
    },
  }
  dispatch(pendingGetOutages())
  return axios(config)
    .then((response) => {
      dispatch(fulfilledGetOutages({ outages: response.data }))
    })
    .catch((error) => {
      dispatch(failedGetOutages(error?.response?.status))
    })
}
