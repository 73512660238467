/**
 * Validates a customer ID string.
 *
 * @function
 * @param {string} customerId - The customer ID to validate.
 * @returns {boolean} A boolean value indicating whether the specified customer ID is valid.
 *
 * @example
 * // Returns true
 * validateCustomerId('1234-5678-9012-3456');
 */
export const validateCustomerId = (customerId) =>
  customerId !== undefined &&
  customerId !== null &&
  typeof customerId === `string` &&
  customerId.match(/^[a-zA-Z0-9-]{17}$/) !== null
