import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.SAVE_PAYMENT_METHOD_PENDING,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.SAVE_PAYMENT_METHOD_FULFILLED,
  payload: data,
})

export const failedAC = (data) => ({
  type: ActionTypes.SAVE_PAYMENT_METHOD_FAILED,
  payload: data,
})

export const savePaymentMethod = (payMethodId) => (dispatch, getState) => {
  const api = `SAVE_PAYMENT_METHOD`
  dispatch(pendingAC())

  const template = URITemplate(urls.url[api])
  const url = template.expand({ payMethodId })

  return getTokenAction(dispatch, getState)
    .then((token) => {
      const state = getState()
      const config = {
        headers: {
          token: token.accessToken,
        },
        params: {
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
          lang: state.siteLanguage.language,
        },
      }

      const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      if (apiKey !== ``) {
        config.headers.apiKey = apiKey
      }

      return axios
        .create(config)
        .put(url)
        .then((response) => {
          dispatch(fulfilledAC(response.data))
          return response
        })
    })
    .catch((response) => {
      dispatch(
        failedAC({
          statusCode: _.get(response, `response.status`, ``),
          errorMessage: _.get(response, `response.data.Error`, ``),
        }),
      )
    })
}
