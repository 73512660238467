import forEach from "lodash/forEach"
import * as ActionTypes from "constants-lib/actionTypes"
import {
  DEFAULT_SORT_DIRECTION,
  DEFAULT_SORT_FIELD,
} from "constants-lib/common"

const initialState = {
  brokerAccountBalancesAndDetails: {},
  brokerAccountNicknames: {},
  fetchBrokerAccountBalancesAndDetailsStatus: ``,
  sortedBrokerAccounts: [],
  sortDirection: DEFAULT_SORT_DIRECTION,
  sortField: DEFAULT_SORT_FIELD,
  lastSelectedBrokerAccount: null,
  billingDetailsAccount: ``,
  billingDetailsAddress: ``,
  billingDetailsDisplayContent: 0,
  overviewPaymentAmount: 0,
  viewAgedBalance: false,
  viewMoreInvoices: false,
  viewMorePayments: false,
  getPendingChargesStatus: ``,
  pendingCharges: [],
  billingDetails: {},
  getBillingDetailsState: ``,
}

/* billingDetailsDisplayContent:
  0 - Invoice history
  1 - Payment History
*/

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.RESET_USER_ACCOUNT:
      return {
        ...initialState,
        sortedBrokerAccounts: state.sortedBrokerAccounts,
        sortDirection: state.sortDirection,
        sortField: state.sortField,
      }

    case ActionTypes.SET_BROKER_ACCOUNT_SORT_PREFERENCE:
      const { sortedBrokerAccounts, sortDirection, sortField } = action.payload

      return {
        ...state,
        sortedBrokerAccounts,
        sortDirection,
        sortField,
      }

    case ActionTypes.SET_LAST_SELECTED_BROKER_ACCOUNT:
      const { lastSelectedBrokerAccount } = action.payload

      return {
        ...state,
        lastSelectedBrokerAccount,
      }

    case ActionTypes.SET_BILLING_DETAILS_ACCOUNT:
      return {
        ...state,
        billingDetailsAccount: action.payload,
      }

    case ActionTypes.SET_BILLING_DETAILS_ADDRESS:
      /*
      This check is here to prevent `billingDetailsAddress` from being set to an empty string when a user removes
      a linked account's nickname on the manage-accounts page and then navigates to either the billing details or
      scheduled payments pages. If the payload is an empty string, return the current state unmodified, else update
      `billingDetailsAddress`
    */
      if (action.payload) {
        return {
          ...state,
          billingDetailsAddress: action.payload,
        }
      }
      return state

    case ActionTypes.SET_BILLING_DETAILS_DISPLAY_CONTENT:
      return {
        ...state,
        billingDetailsDisplayContent: action.payload,
      }

    case ActionTypes.SET_OVERVIEW_PAYMENT_AMOUNT:
      return {
        ...state,
        overviewPaymentAmount: action.payload,
      }

    case ActionTypes.SET_VIEW_AGED_BALANCE:
      return {
        ...state,
        viewAgedBalance: action.payload,
      }

    case ActionTypes.SET_VIEW_MORE_INVOICES:
      return {
        ...state,
        viewMoreInvoices: action.payload,
      }

    case ActionTypes.SET_VIEW_MORE_PAYMENTS:
      return {
        ...state,
        viewMorePayments: action.payload,
      }

    case ActionTypes.GET_BROKER_ACCOUNT_BALANCE_AND_DETAILS_PENDING:
      return {
        ...state,
        fetchBrokerAccountBalancesAndDetailsStatus: `pending`,
      }

    case ActionTypes.GET_BROKER_ACCOUNT_BALANCE_AND_DETAILS_FAILED:
      return {
        ...state,
        fetchBrokerAccountBalancesAndDetailsStatus: `failed`,
      }

    case ActionTypes.GET_BROKER_ACCOUNT_BALANCE_AND_DETAILS_FULFILLED:
      const updatedBrokerAccountBalancesAndDetails = {}
      forEach(action.payload, (account) => {
        updatedBrokerAccountBalancesAndDetails[account.custAccountId] = account
      })

      return {
        ...state,
        brokerAccountBalancesAndDetails: updatedBrokerAccountBalancesAndDetails,
        fetchBrokerAccountBalancesAndDetailsStatus: `fulfilled`,
      }

    case ActionTypes.UPDATE_BROKER_ACCOUNT_NICKNAME:
      const { nickName, custAccountId } = action.payload

      const updatedBrokerAccountNicknames = {
        ...state.updatedBrokerAccountNicknames,
      }

      /* update the nickname in state to prevent caching of nicknames for balanceContact API */
      updatedBrokerAccountNicknames[custAccountId] = nickName

      return {
        ...state,
        brokerAccountNicknames: updatedBrokerAccountNicknames,
      }

    /* pending charges */
    case ActionTypes.GET_PENDING_CHARGES_PENDING:
      return {
        ...state,
        getPendingChargesStatus: `pending`,
        pendingCharges: [],
      }

    case ActionTypes.GET_PENDING_CHARGES_FAILED:
      return {
        ...state,
        getPendingChargesStatus: `failed`,
      }

    case ActionTypes.GET_PENDING_CHARGES_FULFILLED:
      return {
        ...state,
        getPendingChargesStatus: `fulfilled`,
        pendingCharges: action.payload,
      }

    case ActionTypes.GET_BILLING_DETAILS_PENDING:
      return {
        ...state,
        getBillingDetailsState: `pending`,
      }

    case ActionTypes.GET_BILLING_DETAILS_FAILED:
      return {
        ...state,
        getBillingDetailsState: `failed`,
      }

    case ActionTypes.GET_BILLING_DETAILS_FULFILLED: {
      return {
        ...state,
        getBillingDetailsState: `fulfilled`,
        billingDetails: {
          ...state.billingDetails,
          ...action.payload,
        },
      }
    }

    default:
      return state
  }
}
