import PropTypes from "prop-types"
import isEmpty from "lodash/isEmpty"
import { transformModelWithStrings } from "utils-lib/ssr/transformModels"
import React from "react"

// AEM ResponsiveGrid component doesn't parse booleans properly, (everything is transformed to an empty string), so we need to re-transform the booleans we stringified with transformModelWithStrings
const getTransformedModel = (model) => transformModelWithStrings(model, true)

/**
 * Higher-order component that wraps a component and passes it a transformed model prop.
 * @function
 * @param {React.Component} WrappedComponent - The component to wrap.
 * @returns {React.Component} The wrapped component with a transformed model prop.
 */
export default (WrappedComponent) => {
  const WrappedComponentWithSSRModel = ({ model, ...rest }) => {
    let cleanedProps = { ...rest }
    if (!isEmpty(model)) {
      const transformedModel = getTransformedModel(model)

      cleanedProps = {
        ...rest,
        ...transformedModel,
      }
    }

    return <WrappedComponent {...cleanedProps} />
  }

  WrappedComponentWithSSRModel.propTypes = {
    model: PropTypes.object,
  }

  WrappedComponentWithSSRModel.defaultProps = {
    model: null,
  }

  return WrappedComponentWithSSRModel
}
