import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  GET_PENDING_CHARGES_PENDING,
  GET_PENDING_CHARGES_FAILED,
  GET_PENDING_CHARGES_FULFILLED,
} from "constants-lib/actionTypes"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: GET_PENDING_CHARGES_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: GET_PENDING_CHARGES_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: GET_PENDING_CHARGES_FULFILLED,
  payload: data,
})

export const getPendingCharges =
  ({ customerId, userId, lang }) =>
  (dispatch, getState) => {
    const api = `PENDING_CHARGES`
    const template = URITemplate(urls.url[api])

    const url = template.expand({
      customerId,
      userId,
      lang,
    })

    const config = {
      method: `GET`,
      headers: {},
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.token = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

      return getPendingChargesHelper(url, config, dispatch)
    })
  }

export const getPendingChargesHelper = (url, config, dispatch) => {
  dispatch(pendingAC())

  return axios
    .get(url, config)
    .then((response) => {
      const res = _.get(response, `data.body`, {})
      const formattedData = _.flatten(
        _.map(_.get(res, `charges`, []), ({ date, services }) =>
          _.map(services, (service) => {
            const description =
              _.get(service, `equipment.serviceDescription`, ``) ||
              _.get(service, `equipment.description`, ``)
            const type = _.get(service, `equipment.specialDescription2`, ``)
            const total = _.get(service, `prices.extendedAmount`, ``)
            const quantity = _.get(service, `equipment.count`, ``) || null
            return { date, description, type, total, quantity }
          }),
        ),
      )

      dispatch(fulfilledAC(formattedData))
    })
    .catch((error) => {
      dispatch(failedAC(_.get(error, `response.status`, ``)))
    })
}
