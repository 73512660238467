/**
 * Returns the router hook based on the application type (Next.js or non-Next.js).
 * @returns {Function} - The router hook function to be used.
 */

import { isNextApp } from "utils-lib/isNextApp"
import { useHistory } from "react-router-dom"
import { useRouter } from "next/router"

export default () => {
  if (!isNextApp) {
    return useHistory()
  }
  return useRouter()
}
