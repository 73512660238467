export const strings = {
  en_CA: {
    CARD: `Card`,
    BANK_ACCOUNT: `Bank Account`,
    DIGITAL_PAYMENTS: "Digital Payments",
    CONVENIENCE_FEE: `convenience fee`,
    NEXT: `Next`,
    ENDING_IN: "ending in",
    SAVED: "Saved Payments",
    NO_FEE: "NO FEE",
    EXPIRED: "Expired",
    EXEMPT_CONVENIENCE_FEE: "One or more of your accounts require a",
    FOR_NON_BANK_PAYMENTS: "for non bank account payments.",
    PAYMENT_METHOD: `Payment Method`,
    OF: "of",
    CONVENIENCE_FEE_API_ERROR: `Payment Method options may not be available due to an error. Refresh the page to resolve.`,
    CONVENIENCE_FEE_START_TEXT: "",
    CONVENIENCE_FEE_BANNER_MESSAGE:
      "convenience fee applies per payment when using card or digital wallet",
    CONVENIENCE_FEE_EXEMPT_TEXT: "",
    CONVENIENCE_FEE_EXEMPT_BANNER:
      "convenience fee per payment applies to one or more of your accounts when using card or digital wallet",
  },
  fr_CA: {
    CARD: `Carte`,
    BANK_ACCOUNT: `Compte bancaire`,
    DIGITAL_PAYMENTS: "Paiements numériques",
    CONVENIENCE_FEE: `Frais de commodité`,
    NEXT: `Suivant`,
    ENDING_IN: "finissant en",
    SAVED: "Modes de paiement enregistrés",
    NO_FEE: "Pas de frais",
    EXPIRED: "Expiré", // TODO: Yet to receive translation.
    EXEMPT_CONVENIENCE_FEE: "Un ou plusieurs de vos comptes nécessitent des",
    FOR_NON_BANK_PAYMENTS:
      "pour les paiements non effectués par compte bancaire.",
    PAYMENT_METHOD: `Mode de paiement`,
    OF: "de",
    CONVENIENCE_FEE_API_ERROR: `Il est possible que les options de mode de paiement ne soient pas disponibles en raison d'une erreur. Actualisez la page pour résoudre le problème.`,
    CONVENIENCE_FEE_START_TEXT: "Des frais de commodité de ",
    CONVENIENCE_FEE_BANNER_MESSAGE:
      "CA par transaction s' appliquent lorsque vous utilisez une carte ou un portefeuille numérique",
    CONVENIENCE_FEE_EXEMPT_TEXT:
      "Un ou plusieurs de vos comptes exigent des frais de commodité de",
    CONVENIENCE_FEE_EXEMPT_BANNER:
      "CA par transaction lorsque vous utilisez une carte ou un portefeuille numérique",
  },
}

export const SAVED_PAYMENTS_DROPDOWN_LABEL_LOCALIZATION_KEY = {
  NO_FEE: "NO_FEE",
  EXPIRED: "EXPIRED",
}

export const PAYMENT_METHODS = {
  CREDIT_CARD: `creditdebit`,
  CHECKINGS: `checking`,
  SAVINGS: `savings`,
  ACH: `ACH`,
  CREDIT: `CREDIT`,
  CHEQUING: `chequing`,
}

export const PAYMENT_METHODS_TYPE = {
  CREDIT_CARD: `Credit-Debit Card`,
  CHECKINGS: `Checking Account`,
  SAVINGS: `Savings Account`,
}
export const PAYMENT_MODE = {
  CHECKING: "Checking",
  SAVINGS: "Savings",
  CREDIT_CARD: "Credit-Card",
}

export const ALL_PAYMENT_METHODS = [
  "visa",
  "checking",
  "savings",
  "disc",
  "discover",
  "amex",
  "mc",
]

export const UNSAVED_PAYMENT_METHODS = {
  BANK_ACCOUNT: {
    keyForLocalization: "BANK_ACCOUNT",
    value: "DD",
    icon: "/content/dam/wm/icons/single-use/dashboard/bank.svg",
    badge: "NO_FEE",
  },
  CARD: {
    keyForLocalization: "CARD",
    value: "CC",
    icon: "/content/dam/wm/icons/single-use/dashboard/card.svg",
  },
  APAY: {
    keyForLocalization: "",
    value: "AP",
    icon: "/content/dam/wm/icons/single-use/payment-method/apple-pay.svg",
  },
  GPAY: {
    keyForLocalization: "",
    value: "GP",
    icon: "/content/dam/wm/icons/single-use/payment-method/g-pay.svg",
  },
  PAYPAL: {
    keyForLocalization: "",
    value: "PAYPAL_ACCOUNT",
    icon: "/content/dam/wm/icons/single-use/payment-method/paypal.svg",
  },
}

export const SAVED_PAYMENT_METHODS = {
  SAVED: {
    keyForLocalization: "SAVED",
    value: "Saved",
    icon: "/content/dam/wm/icons/single-use/dashboard/wallet.svg",
  },
}

export const PAYMENT_METHOD_STATUS = {
  ACTIVE: "Active",
}

export const PAYMENT_METHOD_VALUES = {
  CREDIT_CARD: `CC`,
  SAVED: `Saved`,
  ACH: `ACH`,
  CHEQUING: `Chequing`,
  CHECKING: `Checking`,
  SAVING: `Saving`,
  AP: `AP`,
  GP: `GP`,
  PAYPAL_ACCOUNT: `PAYPAL_ACCOUNT`,
  PAYPAL_CREDIT: `PAYPAL_CREDIT`,
  DD: `DD`,
}

export const PAYMENT_METHOD_CC_VALUES = {
  VISA: `VISA`,
  DISCOVER: `DISC`,
  MASTERCARD: `MC`,
  AMEX: `AMEX`,
}

export const PAYMENT_METHOD_TYPES = {
  BANK_ACCOUNT: `Bank Account`,
  GOOGLE_PAY: `Google Pay`,
  APPLE_PAY: `Apple Pay`,
  PAYPAL: `Paypal`,
  Card: `Card`,
}
