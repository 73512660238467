import findIndex from "lodash/findIndex"
import get from "lodash/get"
import last from "lodash/last"
import reject from "lodash/reject"
import isEmpty from "lodash/isEmpty"
import toLower from "lodash/toLower"
import toUpper from "lodash/toUpper"
import filter from "lodash/filter"
import find from "lodash/find"
import remove from "lodash/remove"
import dayjs from "utils-lib/date"
import {
  lob as LOB,
  LINE_OF_BUSINESS,
  MAS_LOB_ROUTE,
} from "constants-lib/common"

/**
 * Checks if there are any customers with a line of business that is not residence.
 * @param {string} lob - The line of business to check for (residence or business).
 * @param {Array} customers - An array of customer objects.
 * @returns {boolean} True if there are any customers with a line of business that is not residence, false otherwise.
 */
export const addressHasLOB = (lob, customers) =>
  findIndex(customers, (o) => {
    const customerLOB = toUpper(get(o, `line_of_business`, ``))
    return toLower(lob) === LOB.HOME
      ? customerLOB === LINE_OF_BUSINESS.RESIDENTIAL
      : customerLOB !== LINE_OF_BUSINESS.RESIDENTIAL
    // customerLOB === `COMMERCIAL` || customerLOB === `ROLLOFF` || customerLOB === `LANDFILL`
  }) !== -1

/**
 * Checks if the account LOB matches the LOB in the current route.
 * @param {string} currentRoute - The current route the user is on.
 * @param {string} accountLOB - The account LOB.
 * @returns {boolean} True if the account LOB matches the LOB in the current route, false otherwise.
 */
export const accountLOBMatchesRoute = (currentRoute, accountLOB) => {
  const lob = toUpper(accountLOB)
  return (
    (currentRoute === MAS_LOB_ROUTE.RESIDENTIAL_ROUTE &&
      (lob === `R` || accountLOB === LOB.HOME)) || // lob home is in lowercase
    (currentRoute === MAS_LOB_ROUTE.BUSINESS_ROUTE &&
      (lob === `B` ||
        lob === `O` ||
        lob === `C` ||
        accountLOB === LOB.BUSINESS)) // lob business is in lowercase
  )
}

/**
 * Checks if the MAS LOB matches the LOB in the current route.
 * @param {string} currentRoute - The current route the user is on.
 * @param {string} masLOB - The LOB of the account.
 * @returns {boolean} True if the MAS LOB matches the LOB in the current route, false otherwise.
 */
export const masLOBMatchesRoute = (currentRoute, masLOB) =>
  (currentRoute === MAS_LOB_ROUTE.RESIDENTIAL_ROUTE && masLOB === LOB.HOME) ||
  (currentRoute === MAS_LOB_ROUTE.BUSINESS_ROUTE && masLOB === LOB.BUSINESS)

/**
 * Returns the latest invoice in an MAS account, or null if there are no invoices.
 * @param {Object} singleAccount - The account object that you want to get the latest invoice for.
 * @returns {Object|null} The latest invoice in an MAS account, or null if there are no invoices.
 */
export const getLatestInvoice = (singleAccount) => {
  const invoiceDetails = get(
    singleAccount,
    `customerAccount.account.accountMASInfo.invoiceDetails`,
    null,
  )
  return invoiceDetails !== null && invoiceDetails.length > 0
    ? last(invoiceDetails)
    : null
}

/**
 * Returns the total MAS balance for a single account.
 * @param {Object} singleAccount - The account object.
 * @returns {number} The total MAS balance.
 */
export const getTotalBalance = (singleAccount) =>
  get(singleAccount, `customerAccount.account.accountMASInfo.totalBalAmount`, 0)

/**
 * Returns the number of days until the MAS account balance is past due for a single account.
 * @param {Object} singleAccount - The account object from the API.
 * @returns {number} The number of days until the MAS account balance is past due.
 */
export const getNumDaysToPastDue = (singleAccount) => {
  const invoice = getLatestInvoice(singleAccount)
  const invoiceDueDate = get(invoice, `invoiceDueDate`, null)
  let numDaysToPastDue = 0

  if (invoiceDueDate !== null) {
    const now = dayjs().format(`MM/DD/YYYY`)
    numDaysToPastDue = dayjs(invoiceDueDate, `MM/DD/YYYY`).diff(now, `days`)
  }

  return numDaysToPastDue
}

/**
 * Checks if there are any local sites with a line of business of "Residential".
 * @param {Object} masData - The data returned from the MAS API.
 * @returns {boolean} True if there are any local sites with a line of business of "Residential", false otherwise.
 */
export const hasResidenceLocalSites = (masData) => {
  const businessSite = reject(
    masData.localsites,
    (item) => item.line_of_business !== `Residential`,
  )
  return businessSite.length > 0
}

/**
 * Returns the number of local sites in the MAS data.
 * @param {Object} masData - The data returned from the MAS API.
 * @returns {number} The number of local sites in the MAS data.
 */
export const getLocalSitesCount = (masData) =>
  get(masData, `localsites`, [])?.length

/**
 * Returns the number of sub-geo-locations in the given MAS data.
 * @param {Object} masData - The data object that is returned from the API.
 * @returns {number} The number of sub-geo-locations in the given MAS data.
 */
export const getCommunityCount = (masData) =>
  get(masData, `sub_geo_locations`, []).length

/**
 * Returns the value of the `is_negative_zipcode` property of the `masData` object,
 * or `N` if the `masData` object is undefined.
 * @param {Object} masData - The data that is returned from the MAS API.
 * @returns {string} The value of the `is_negative_zipcode` property of the `masData` object,
 * or `N` if the `masData` object is undefined.
 */
export const getNegativeZipcodeFlag = (masData) =>
  get(masData, `is_negative_zipcode`, `N`)

/**
 * If the latest invoice has a due date, returns true, otherwise returns false.
 * @param {Object} singleAccount - The account object that is being passed in from the parent component.
 * @returns {boolean} A boolean value indicating whether the latest invoice has a due date.
 */
export const hasInvoiceDueDate = (singleAccount) => {
  const invoice = getLatestInvoice(singleAccount)
  const invoiceDueDate = get(invoice, `invoiceDueDate`, null)
  return invoiceDueDate !== null
}

/**
 * Checks if the data has exactly one MAS account.
 * @param {Object} masData - The data returned from the MAS API.
 * @returns {boolean} True if the data has exactly one MAS account, false otherwise.
 */
export const hasOneMASAccount = (masData) =>
  get(masData, `customers`, []).length > 0

/**
 * If the results of the MAS API call contain the string "CS227", then the user has multiple MAS accounts.
 * @param {Object} masData - The data returned from the MAS API.
 * @returns {boolean} True if the results of the MAS API call contain the string "CS227", false otherwise.
 */
export const hasMASAccount = (masData) =>
  hasOneMASAccount(masData) ||
  // CS227 code in results means multiple MAS accounts exist.
  // In that case, no customers are returned.
  get(masData, `results`, ``).indexOf(`CS227`) !== -1

/**
 * Checks if the data has more than one MAS account.
 * @param {Object} masData - The data returned from the MAS API.
 * @returns {boolean} True if the data has more than one MAS account, false otherwise.
 */
export const hasMultipleMASAccounts = (masData) =>
  get(masData, `customers`, []).length > 1 ||
  // CS227 code in results means multiple MAS accounts exist.
  // In that case, no customers are returned.
  get(masData, `results`, ``).indexOf(`CS227`) !== -1

/**
 * Checks if the data has exactly one MAS account with a line of business of "RESIDENTIAL".
 * @param {Object} masData - The data returned from the MAS API.
 * @returns {boolean} True if the data has exactly one MAS account with a line of business of "RESIDENTIAL", false otherwise.
 */
export const hasResidenceMASAccount = (masData) =>
  get(masData, `customers[0].line_of_business`, ``) ===
  LINE_OF_BUSINESS.RESIDENTIAL

/**
 * Checks if the data has at least one local site.
 * @param {Object} masData - The data returned from the MAS API.
 * @returns {boolean} True if the data has at least one local site, false otherwise.
 */
export const hasLocalSites = (masData) => getLocalSitesCount(masData) > 0

/**
 * Checks if the data has at least one community.
 * @param {Object} masData - The data returned from the MAS API.
 * @returns {boolean} True if the data has at least one community, false otherwise.
 */
export const hasCommunity = (masData) => getCommunityCount(masData) > 0

/**
 * Checks if the `masData` object has a `negativeZipcodeFlag` property with a value of `Y`.
 * @param {Object} masData - The data object returned from the MAS API.
 * @returns {boolean} True if the `masData` object has a `negativeZipcodeFlag` property with a value of `Y`, false otherwise.
 */
export const hasNegativeZipcode = (masData) =>
  getNegativeZipcodeFlag(masData) === `Y`

/**
 * Checks if there is an MAS account with the specified line of business (LOB).
 * @param {Object} masData - The data returned from the MAS API.
 * @param {string} lob - The line of business (LOB) of the account. This can be either LOB.HOME or LOB.BUSINESS.
 * @returns {boolean} A boolean value indicating whether there is an MAS account with the specified LOB.
 */
export const masAccountHasLob = (masData, lob) => {
  const customers = get(masData, `customers`, [])

  return (
    customers !== null &&
    customers.length > 0 &&
    findIndex(customers, (customer) => {
      const lineOfBusiness = toUpper(customer.line_of_business)
      return (
        (lob === LOB.HOME && lineOfBusiness === LINE_OF_BUSINESS.RESIDENTIAL) ||
        (lob === LOB.BUSINESS &&
          lineOfBusiness !== LINE_OF_BUSINESS.RESIDENTIAL)
      )
    }) !== -1
  )
}

/**
 * Returns the rootUrl if it exists, otherwise it returns the url of the curbside product.
 * @param {Object} getCurbsideProduct - The product object that is returned from the query.
 * @returns {string} The url of the curbside product.
 */
export const getCommunityCurbsideUrl = (getCurbsideProduct) => {
  const rootUrl = get(getCurbsideProduct, `rootUrl`, ``)
  if (!isEmpty(rootUrl)) {
    const url = get(getCurbsideProduct, `rootUrl`, ``)
    return url
  }
  const url = get(getCurbsideProduct, `url`, ``)
  return url
}

/**
 * Finds the product with the ID of 300 in the category of Residential and sub-category of Curbside Pickup.
 * @param {Array} masData - The data returned from the API.
 * @returns {Object} The residential product object.
 */
export const getResidentialNoProduct = (masData) => {
  const getResidentialWastePickup = filter(
    masData.categories,
    (o) => o.name === `Residential` && o.sub_category === `Curbside Pickup`,
  )
  if (isEmpty(getResidentialWastePickup)) {
    return null
  }

  const getResidentialProduct = find(
    find(getResidentialWastePickup, (o) => o).products,
    (o) => parseInt(o.id, 10) === 300,
  )

  return getResidentialProduct
}

/**
 * Returns the `request_quote_form` value from the `curbside` product if it exists,
 * otherwise it returns the `request_quote_form` value from the `residential` product.
 * @param {function} getResidentialProduct - A function that returns the residential product object.
 * @param {function} getCurbsideProduct - A function that returns the curbside product object.
 * @returns {string} The `request_quote_form` value.
 */
export const getRequestFormFlag = (
  getResidentialProduct,
  getCurbsideProduct,
) => {
  const residentialQuoteForm = get(
    getResidentialProduct,
    `request_quote_form`,
    ``,
  )
  const requestQuoteForm = get(
    getCurbsideProduct,
    `request_quote_form`,
    residentialQuoteForm,
  )

  return requestQuoteForm
}

/**
 * Sorts a list of communities alphabetically and moves the community "Other" to the end of the list.
 * @function
 * @name getSortedCommunities
 * @param {string[]} list - The list of communities to sort.
 * @returns {string[]} A sorted list of communities, with the community "Other" moved to the end of the list if it is present.
 */
export const getSortedCommunities = (list) => {
  if (list.includes(`Other`)) {
    remove(list, (e) => e === `Other`)
    list.sort()
    list.push(`Other`)
    return list
  }
  return list.sort()
}
