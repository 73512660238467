import { getWindow } from "../getWindow"

const window = getWindow()
/**
 * Checks if the current device is a mobile device.
 *
 * @returns {boolean} Returns true if the current device is a mobile device, else false.
 */
// eslint-disable-next-line max-len
export default () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    window.navigator.userAgent,
  )
