// constants
import * as ActionTypes from "constants-lib/actionTypes"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { generateUniqueID } from "utils-lib/requests"
import { urls } from "utils-lib/builds"
import { profileIdForAPIs } from "constants-lib/profileIdForAPIs"

/**
 * Fetches contact options for a customer based on their ID and token.
 * @param {string} ezpayid - The ID of the customer.
 * @param {string} tokenValue - The token for the customer.
 * @param {function} cb - The callback function to be executed after the async operation.
 * @returns {function} A function that takes the Redux dispatch and getState functions as arguments.
 */
export const getContactOptions =
  (ezpayid, tokenValue, cb = () => {}) =>
  (dispatch, getState) => {
    const state = getState()
    const lang = state.siteLanguage.language
    // TODO Fail action if token or customerId is empty.
    const template = URITemplate(urls.cloudfront.myPreferences.getOptions.url)
    const getCustomerPrefOptionsUrl = template.expand({
      customerId: ezpayid,
      lang,
    })
    const apiType = `OPTIONS`
    const profileId = profileIdForAPIs().profileId[apiType]
    const getOptions = {
      url: getCustomerPrefOptionsUrl,
      headers: {
        "Request-Tracking-Id": generateUniqueID(),
        Authorization: `Bearer ${tokenValue}`,
        // ProfileId: `eBizCN_307`,
        ProfileId: profileId,
      },
      method: `GET`,
    }
    dispatch({
      type: ActionTypes.GET_CONTACT_OPTIONS_PENDING,
    })

    return axios(getOptions)
      .then((response) => {
        //  const statusCode = _.get(response, `status`, ``)
        dispatch({
          type: ActionTypes.GET_CONTACT_OPTIONS_FULFILLED,
          payload: response.data.preferences,
        })
        cb()
        return response
      })
      .catch(() => {
        dispatch({
          type: ActionTypes.GET_CONTACT_OPTIONS_FAILED,
        })
        cb()
      })
  }
