import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import { get } from "lodash"
import { getTokenAction } from "actions-lib/getToken"
import { urls } from "utils-lib/builds"

export const pendingSignature = () => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_SIGNATURE_PENDING,
})

export const failedSignature = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_SIGNATURE_FAILED,
  payload: data,
})

export const fulfilledSignature = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_SIGNATURE_FULFILLED,
  payload: data,
})

export const getUTCDateTime = () => {
  const now = new Date()
  return `${now.toISOString().replace(/\..+/, ``)}Z`
}

export const generateCyberSignatureHelper = (
  url,
  data,
  config,
  onFail,
  dispatch,
) =>
  axios
    .create(config)
    .post(url, data)
    .then((response) => {
      const statusCode = get(response, `status`, ``)
      if (statusCode === 200) {
        const translatedResponse = {
          signatureDetails: response.data.data,
          statusCode,
        }

        dispatch(fulfilledSignature(translatedResponse))
        return translatedResponse
      }
      throw response
    })
    .catch((response) => {
      const statusCode = get(response, `response.status`, ``)
      const errorMessage = get(response, `response.data.Error`, ``)
      dispatch(
        failedSignature({
          statusCode,
          errorMessage,
        }),
      )
      onFail()
    })

export const generateCyberSignature =
  (passedData, { onFail = () => {} }, requireLogin = false) =>
  (dispatch, getState) => {
    dispatch(pendingSignature())
    const state = getState()
    const api = `GENERATE_CYBS_SIGNATURE`
    const url = urls.url[api]

    const config = {
      headers: {},
    }
    const { profile } = passedData
    const profileValue = `2b4e84fd8d66646930ae4262a2065357343dbc7c`
    const data = {
      locale: get(state, `siteLanguage.language`, `en_CA`).replace(`_`, `-`),
      profile: `2b4e84fd8d66646930ae4262a2065357343dbc7c`,
      signedDateTime: getUTCDateTime(),
      ...passedData,
    }

    if (!profile) {
      data.profile = profileValue
    }
    if (requireLogin) {
      return getTokenAction(dispatch, getState).then((token) => {
        config.headers.token = token.accessToken

        config.params = {
          userId: get(state, `userAccount.userDetails.userId`, ``),
        }

        const apiKey = get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }
        return generateCyberSignatureHelper(url, data, config, onFail, dispatch)
      })
    }

    config.headers.token =
      get(state, `myPaymentVerification.verificationToken`, ``) ||
      get(state, `guestContainerRepair.eligibilityData.token`) ||
      get(state, `customerSelections.cityBilledEligibility.token`, ``)

    const apiKey = get(urls, `apiKey.GUEST[${api}]`, ``)
    if (apiKey !== ``) {
      config.headers.apiKey = apiKey
    }

    data.customerId =
      get(state, `myPaymentVerification.verificationData.customerId`, ``) ||
      get(state, `myPaymentVerification.verificationDetails.customerId`, ``) ||
      get(state, `guestContainerRepair.eligibilityData.customerId`) ||
      get(state, `customerSelections.cityBilledEligibility.customerId`, ``)

    return generateCyberSignatureHelper(url, data, config, onFail, dispatch)
  }
