/**
 * Returns a boolean value indicating whether the specified name is valid.
 *
 * @function
 * @param {string} name - The name to validate.
 * @returns {boolean} A boolean value indicating whether the specified name is valid.
 */
export const isValidName = (name) => {
  const nameRegex = /^[A-Za-z0-9-" "~`!@#$%^&*()_+={}[]|\\:;"'<>,.?\/]+$/
  if (name) return nameRegex.test(name)

  return false
}
