/**
 * Validates a phone number string using a regular expression.
 *
 * @function
 * @param {string} phone - The phone number to validate.
 * @returns {boolean} A boolean value indicating whether the specified phone number is valid.
 */
export const validatePhone = (phone) => {
  const phonePattern = /\(?([0-9]{3})\)?(\s)([0-9]{3})-([0-9]{4})/
  const regexPhone = phone?.match(phonePattern)
  return regexPhone !== null
}
