import { SET_ERROR_HANDLING_POPUP_DATA } from "constants-lib/actionTypes"

const initialState = {
  message: ``,
  callText: ``,
  buttonText: ``,
  buttonHref: ``,
  analytics_key: ``,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_HANDLING_POPUP_DATA:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
