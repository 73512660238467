import "dayjs/locale/fr-ca"
import "dayjs/locale/en"
import dayjs from "utils-lib/date"
import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Typography } from "@mui/material"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Box from "@mui/material/Box"
import { ReactSVG } from "react-svg"
import { makeStyles } from "tss-react/mui"
import { getWindow } from "utils-lib/getWindow"
import { handleEnterPress } from "utils-lib/handleEnterPress"
import { KEY_CODE_MAPPING } from "constants-lib/keyCodes"
import noop from "lodash/noop"
import { SCREEN_SM_MAX } from "constants-lib/dimensions"
import informationIcon from "./img/exclamation-circle.svg"

const window = getWindow()

const datePickerPlaceholder = "MM/DD/YYYY"

const useStyles = makeStyles()((theme) => ({
  root: {
    height: `50px`,
    width: `100%`,
    color: `${theme.palette.text.bodyGray} !important`,
    borderRadius: 4,
    [theme.breakpoints.up(`sm`)]: {
      width: `auto`,
    },
  },
  bottomMessageContainer: {
    padding: `0.25rem`,
    borderRadius: 3,
    margin: "0 1rem 1rem",
    backgroundColor: theme.palette.background.info,
    "& *": {
      color: `${theme.palette.text.lightHeader} !important`,
    },
  },
  bottomMessageInnerContainer: {
    maxWidth: `17rem`,
    display: "flex",
  },
  informationIcon: {
    marginRight: `0.25rem`,
    "& svg *": {
      fill: theme.palette.text.lightHeader,
    },
  },
}))

const DatePicker = ({
  label,
  selectedDate,
  handleDateChange,
  getShouldDisableDate,
  lastAllowedDate,
  defaultSelectedDate,
  shouldDisableDateProps,
  customWrapperClass,
  customInputClass,
  reduxState,
  shouldShowBottomMessage,
  bottomMessage,
  onCalendarOpen,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const { classes } = useStyles()
  const { siteLanguage } = reduxState
  const locale = siteLanguage?.language
  const isMobile = window.innerWidth <= SCREEN_SM_MAX

  /* have to do this, as disabling the input disables the dropdown as well, and we don't want the user to be able to clear or type in the input */
  useEffect(() => {
    const inputEl = window.document.getElementById(`date-picker-input-${label}`)

    if (inputEl) {
      inputEl.setAttribute(`readonly`, true)
    }
  }, [])

  const handleKeyPress = (e) => {
    if (
      e.keyCode === KEY_CODE_MAPPING.ENTER ||
      e.keyCode === KEY_CODE_MAPPING.SPACE_BAR
    ) {
      handleEnterPress(e, toggleInlineCalendar())
    }
  }

  const handleChange = (value) => {
    handleDateChange(value)
  }

  const handleCheckShouldDisableDate = (date) =>
    getShouldDisableDate(date, shouldDisableDateProps)

  const toggleInlineCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen)
    if (isCalendarOpen) {
      // updating this to call on close to fix breaking changes from muiv5 upgrade (calling fakeAnalyticsClick here triggers the clickAway handler)
      onCalendarOpen()
    }
  }

  const notificationComponent = () =>
    shouldShowBottomMessage && (
      <Box
        display="flex"
        className={`${classes.bottomMessageContainer} noticeMessage`}
      >
        <Box className={classes.bottomMessageInnerContainer}>
          <ReactSVG
            wrapper="span"
            src={informationIcon}
            className={classes.informationIcon}
          />
          <Typography variant="body2" component="p">
            {bottomMessage}
          </Typography>
        </Box>
      </Box>
    )

  const additionalProps = {}
  if (isMobile) {
    additionalProps.onClose = () => {
      toggleInlineCalendar()
    }
  }

  return (
    <ClickAwayListener
      onClickAway={isCalendarOpen ? toggleInlineCalendar : noop}
    >
      <Box
        data-testid="DatePicker"
        position="relative"
        className={customWrapperClass}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={locale === `fr_CA` ? "fr-ca" : "en"}
        >
          <MUIDatePicker
            index="0"
            open={isCalendarOpen || false}
            views={["day"]}
            disablePast
            shouldDisableDate={handleCheckShouldDisableDate}
            maxDate={dayjs(lastAllowedDate)}
            id={`date-picker-inline-${label}`}
            label={label}
            referenceDate={dayjs(defaultSelectedDate)}
            initialFocusedDate={dayjs(defaultSelectedDate)}
            value={selectedDate ? dayjs(selectedDate) : null}
            onChange={handleChange}
            slotProps={{
              PaperContent: {
                padding: `0.5rem`,
              },
              toolbar: {
                hidden: true,
              },
              textField: {
                className: `${classes.root} ${customInputClass || ``}`,
                onClick: toggleInlineCalendar,
                inputProps: {
                  "data-testid": "DatePicker-input",
                  id: `date-picker-input-${label}`,
                  label,
                  placeholder: datePickerPlaceholder,
                  variant: "outlined",
                  value: selectedDate
                    ? dayjs(selectedDate).format(`MM/DD/YYYY`)
                    : ``,
                  onKeyDownCapture: handleKeyPress,
                },
              },
              day: {
                onClick: toggleInlineCalendar,
              },
              inputAdornment: {
                hidden: true,
              },
              layout: {
                sx: {
                  [`.noticeMessage`]: {
                    gridColumn: 2,
                    gridRow: 3,
                  },
                },
              },
            }}
            slots={{
              actionBar: notificationComponent,
            }}
            {...additionalProps}
          />
        </LocalizationProvider>
      </Box>
    </ClickAwayListener>
  )
}

DatePicker.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  handleDateChange: PropTypes.func.isRequired,
  getShouldDisableDate: PropTypes.func.isRequired,
  shouldDisableDateProps: PropTypes.object,
  defaultSelectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  lastAllowedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customWrapperClass: PropTypes.string,
  customInputClass: PropTypes.string,
  reduxState: PropTypes.shape({
    siteLanguage: PropTypes.object.isRequired,
  }).isRequired,
  shouldShowBottomMessage: PropTypes.bool,
  bottomMessage: PropTypes.string,
  onCalendarOpen: PropTypes.func,
}

DatePicker.defaultProps = {
  shouldDisableDateProps: {},
  lastAllowedDate: ``,
  customWrapperClass: ``,
  customInputClass: ``,
  shouldShowBottomMessage: false,
  bottomMessage: ``,
  onCalendarOpen: noop,
}

export default DatePicker
