/*
  Products API documentation: https://github.wm.com/esb/roadmap/issues/204
*/

export const combinedProducts = {
  VALID_REDIRECT_URL: `000`, // Redirect to cloudcraze url.
  DISPLAY_SUPPORT_FOR_UNAVAILABLE_PRODUCTS: `001`, // Show error message on L3 page.
  HIDE_SUPPORT_FOR_UNAVAILABLE_PRODUCTS: `002`, // Hide the product component.
  SHOW_FRANCHISE_MESSAGE: `003`, // Show Franchise Messgae.
}
