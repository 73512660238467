import isNull from "lodash/isNull"
import keys from "lodash/keys"
import {
  GET_CUSTOMER_ACCOUNT_FULFILLED,
  GET_CUSTOMER_ACCOUNT_FAILED,
} from "constants-lib/actionTypes"

/**
 * Action for updating the store with customer account information.
 * @function fulfilledAC
 * @param {Object} data - The customer account data to update the store with.
 * @param {boolean} [isCustomerVerified=false] - Whether the customer is verified.
 * @param {string} token - The token to update the store with.
 * @returns {Object} A redux action object.
 */

export const fulfilledAC = (data, isCustomerVerified = false, token) => ({
  type: GET_CUSTOMER_ACCOUNT_FULFILLED,
  payload: {
    customerAccounts: {
      ...(keys(data).length ? data : {}),
      accounts: isNull(data.accounts) ? [] : data.accounts,
      accountsError: isNull(data.accountsError) ? {} : data.accountsError,
    },
    isCustomerVerified,
    token,
  },
})

/**
 * Action for updating the store with a failed customer account fetch.
 * @function failedAC
 * @param {number} fetchCode - The fetch code to update the store with.
 * @returns {Object} A redux action object.
 */
export const failedAC = (fetchCode) => ({
  type: GET_CUSTOMER_ACCOUNT_FAILED,
  payload: fetchCode,
})
