import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import _ from "lodash"
import { urls } from "utils-lib/builds"
import { getTokenAction } from "actions"
import { MOCK_BULK_PICKUP_INFORMATION } from "utils-lib/mocks"
import {
  GET_BULK_PICKUP_ELIGIBILITY_PENDING,
  GET_BULK_PICKUP_ELIGIBILITY_FAILED,
  GET_BULK_PICKUP_ELIGIBILITY_FULFILLED,
} from "constants-lib/actionTypes"

export const pendingAC = () => ({
  type: GET_BULK_PICKUP_ELIGIBILITY_PENDING,
})

export const failedAC = () => ({
  type: GET_BULK_PICKUP_ELIGIBILITY_FAILED,
})

export const fulfilledAC = (data) => ({
  type: GET_BULK_PICKUP_ELIGIBILITY_FULFILLED,
  payload: data,
})

export const getBulkPickupEligibility =
  (customerId) => (dispatch, getState) => {
    const state = getState()
    const api = `BULK_PICKUP_ELIGIBILITY`
    const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
    const template = URITemplate(urls.url[api])

    const url = MOCK_BULK_PICKUP_INFORMATION
      ? `${window.origin}/mock/bulk-pickup-eligibility-response.json`
      : template.expand({
          lang: _.get(state, `siteLanguage.language`, ``),
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
          customerId,
        })

    const config = {
      headers: {
        apiKey,
      },
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken

      return getBulkPickupEligibilityHelper(url, config, dispatch)
    })
  }

export const getBulkPickupEligibilityHelper = (url, config, dispatch) => {
  dispatch(pendingAC())

  return axios
    .get(url, config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      return response
    })
    .catch((error) => {
      dispatch(failedAC())
      return error
    })
}
