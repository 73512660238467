import { localStorageKey } from "constants-lib/localStorage"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

const initialState = {
  // single account
  customerId: ``, // current active customer id
  accountType: ``, // SMB, OMR, TRO
  // multi account, split by lob
  accountTypeCount: {
    SMB: 0,
    OMR: 0,
    TRO: 0,
  },
  isSMBCust: false,
  isSMBCustActive: false,
  isOMRCust: false,
  isOMRCustActive: false,
  isTROCust: false,
  isTROCustActive: false,
  customerWasteType: ``, // ``, `trash`, `recycle`, `both`
  scaFlowType: ``, // single, multi
  accountTypeObject: {},
}

export const resetSCAValues = () => () => {
  window.localStorage.setItem(
    localStorageKey.SCA_VALUES,
    JSON.stringify(initialState),
  )
}

export const setSCAValues = (updatedMetrics) => () => {
  window.localStorage.setItem(
    localStorageKey.SCA_VALUES,
    JSON.stringify(updatedMetrics),
  )
}
