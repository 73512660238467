import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

/**
 * Deletes all cookies by setting their expiration date to a date in the past.
 * @function
 * @see {@link https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript/33366171|Stack Overflow}
 * @example
 * deleteAllCookies();
 * @returns {void}
 */
export function deleteAllCookies() {
  const cookies = window.document.cookie.split(`;`)

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf(`=`)
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    window.document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
  }
}
