import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import _ from "lodash"
import { urls } from "utils-lib/builds"
import { getTokenAction } from "actions"
import { MOCK_BULK_PICKUP_INFORMATION } from "utils-lib/mocks"
import {
  GET_BULK_PICKUP_PRICING_PENDING,
  GET_BULK_PICKUP_PRICING_FAILED,
  GET_BULK_PICKUP_PRICING_FULFILLED,
} from "constants-lib/actionTypes"

export const pendingAC = () => ({
  type: GET_BULK_PICKUP_PRICING_PENDING,
})

export const failedAC = () => ({
  type: GET_BULK_PICKUP_PRICING_FAILED,
})

export const fulfilledAC = (data) => ({
  type: GET_BULK_PICKUP_PRICING_FULFILLED,
  payload: data,
})

export const getBulkPickupPricing =
  ({ customerId, payload }) =>
  (dispatch, getState) => {
    dispatch(pendingAC())

    const api = `BULK_PICKUP_PRICING`
    const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

    const template = URITemplate(urls.url[api])

    const state = getState()

    const url = MOCK_BULK_PICKUP_INFORMATION
      ? `${window.origin}/mock/bulk-pickup-pricing-response.json`
      : template.expand({
          lang: _.get(state, `siteLanguage.language`, ``),
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
          customerId,
        })

    return getTokenAction(dispatch, getState).then((token) => {
      const config = {
        method: MOCK_BULK_PICKUP_INFORMATION ? `GET` : `POST`,
        headers: {
          apiKey,
          oktaToken: token.accessToken,
        },
        data: payload,
      }

      return axios(url, config)
        .then((response) => {
          dispatch(fulfilledAC(response.data))
          return response.data
        })
        .catch(() => {
          dispatch(failedAC())
        })
    })
  }
