import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  surveyStartStatus: "",
  processSurveyStatus: "",
  rating: 0,
  surveyExperience: "",
  isSurveyHidden: false,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SURVEY_START_PENDING: {
      return {
        ...state,
        surveyStartStatus: "pending",
      }
    }

    case ActionTypes.SURVEY_START_FAILED: {
      return {
        ...state,
        surveyStartStatus: "failed",
      }
    }

    case ActionTypes.SURVEY_START_FULFILLED: {
      return {
        ...state,
        surveyStartStatus: "fulfilled",
        surveyResponse: action.payload,
      }
    }

    case ActionTypes.PROCESS_SURVEY_PENDING: {
      return {
        ...state,
        processSurveyStatus: "pending",
      }
    }

    case ActionTypes.PROCESS_SURVEY_FAILED: {
      return {
        ...state,
        processSurveyStatus: "failed",
        processSurveyResponse: action.payload,
      }
    }

    case ActionTypes.PROCESS_SURVEY_FULFILLED: {
      return {
        ...state,
        processSurveyStatus: "fulfilled",
        processSurveyResponse: action.payload,
      }
    }

    case ActionTypes.SURVEY_RATING: {
      return {
        ...state,
        rating: action.payload,
      }
    }

    case ActionTypes.SURVEY_EXPERIENCE: {
      return {
        ...state,
        surveyExperience: action.payload,
      }
    }

    case ActionTypes.IS_SURVEY_HIDDEN: {
      return {
        ...state,
        isSurveyHidden: action.payload,
      }
    }

    case ActionTypes.CLEAR_SURVEY_DATA: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}
