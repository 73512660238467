import forEach from "lodash/forEach"
import isObjectLike from "lodash/isObjectLike"
import trim from "lodash/trim"
import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { generateUniqueID } from "utils-lib/requests"
import { combinedProducts } from "constants-lib/ecommerce"
import { urls } from "utils-lib/builds"
import {
  GET_COMBINED_PRODUCT_URL_PENDING,
  GET_COMBINED_PRODUCT_URL_FAILED,
  GET_COMBINED_PRODUCT_URL_FULFILLED,
  GET_COMBINED_PRODUCT_URL_CLEAR_STATE,
  SHOW_COMBINED_PRODUCT_STATE,
  HIDE_COMBINED_PRODUCT_STATE,
} from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export const pendingAC = () => ({
  type: GET_COMBINED_PRODUCT_URL_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: GET_COMBINED_PRODUCT_URL_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: GET_COMBINED_PRODUCT_URL_FULFILLED,
  payload: data,
})

export const clearProductUrlState = () => ({
  type: GET_COMBINED_PRODUCT_URL_CLEAR_STATE,
})

export const showCombinedProductState = () => ({
  type: SHOW_COMBINED_PRODUCT_STATE,
})

export const hideCombinedProductState = () => ({
  type: HIDE_COMBINED_PRODUCT_STATE,
})

export const showCombinedProduct = () => (dispatch) => {
  dispatch(showCombinedProductState())
}

export const hideCombinedProduct = () => (dispatch) => {
  dispatch(hideCombinedProductState())
}

/**
 * It gets the customer address from the Redux store, and if the address is valid, it calls the
 * getCloudcrazeCombinedProductsUrl() function, which returns a URL to the CloudCraze storefront
 * @param language - The language of the customer.
 * @returns a function.
 */
export const getCombinedProductsUrl = () => (dispatch, getState) => {
  dispatch(pendingAC())
  const { customerSelections } = getState()

  const customerAddress = get(customerSelections, `customerAddress`, {})

  const street =
    customerAddress.streetNumber || customerAddress.streetName
      ? `${customerAddress.streetNumber} ${customerAddress.streetName}`
      : ``

  if (!trim(street)) {
    return dispatch(fulfilledAC({ invalidAddress: true }))
  }
  return getCloudcrazeCombinedProductsUrl(dispatch, getState).then((res) => {
    if (res) {
      if (res.url) {
        window.open(res.url, `_self`)
      }
    }
  })
}

/**
 * It takes a customerSelections, userAccount, siteLanguage of data from the Redux store, and uses it to make a request to the ESB
 * @param dispatch - The dispatch function from Redux.
 * @param getState - This is a function that returns the current state of the Redux store.
 * @returns The return value is an object with a url property.
 */
export const getCloudcrazeCombinedProductsUrl = (dispatch, getState) => {
  const { customerSelections, userAccount, siteLanguage } = getState()

  const customerAddress = get(customerSelections, `customerAddress`, {})
  const naics_code = encodeURI(
    get(customerSelections, `businessType.NAICSCode`, null),
  )
  const businessType = encodeURI(
    get(customerSelections, `businessType.label`, null),
  )
  const productType = encodeURI(
    get(customerSelections, `businessType.productType`, null),
  )
  const zipcode = customerAddress.postalCode || ``
  const locale = encodeURI(siteLanguage.language || ``)
  const street =
    `${customerAddress.streetNumber} ${customerAddress.streetName}` || ``
  const city = encodeURI(customerAddress.city || ``)
  const county = encodeURI(customerAddress.county || ``)
  const state = encodeURI(customerAddress.state || ``)
  const country = encodeURI(customerAddress.country || ``)
  const lob = encodeURI(customerSelections.customerLob || ``)
  const label = get(customerAddress, `label`, ``)
  const fname = encodeURI(userAccount.userDetails.firstName || ``)
  const lname = encodeURI(userAccount.userDetails.lastName || ``)
  const userid = encodeURI(userAccount.userDetails.userId || ``)
  const sessiontoken = encodeURI(userAccount.sessionToken || ``)
  const accessToken = get(userAccount, `token.accessToken`, ``)
  const latitude = encodeURI(customerAddress.lat || ``)
  const longitude = encodeURI(customerAddress.lng || ``)
  const google_placeId = encodeURI(customerAddress.gPlaceId || ``)
  const api = `GET_COMBINED_PRODUCTS`
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    latitude,
    longitude,
    naics_code,
    city,
    county,
    state,
    street,
    zipcode,
    label,
    country,
    locale,
    lob,
    fname,
    lname,
    userid,
    sessiontoken,
    accessToken,
    refreshToken: `_khEtXW82qn8LZVAzSQD1vnE7fmy86uBD0yBI41i7_k`,
    businessType,
    productType,
    google_placeId,
  })
  const config = {
    url,
    method: `get`,
    headers: {
      "Content-Type": `application/json`,
      // "Authorization": `Bearer ${_.get(getState(), `cloudCraze.esbTokens.access_token`, ``)}`,
      ProfileId: urls.cloudCraze.headers.ClientId,
      "Request-Tracking-Id": generateUniqueID(),
    },
  }

  return axios(config)
    .then((response) => {
      const cloudcrazeUrl = get(
        response,
        `data.wm_metadata.cloudcraze_products_url`,
      )
      if (cloudcrazeUrl) {
        dispatch(fulfilledAC({}))
        return { url: cloudcrazeUrl }
      }
      const data = get(response, `data`)
      const customers = get(data, `customers`, [])
      const customerResults =
        get(data, `customer`) || get(data, `customer_results`)
      let eligibilityResults =
        get(data, `eligibility`) || get(data, `eligibility_results`)
      let productResults =
        get(data, `products`) || get(data, `products_results`)
      const localsitesResults =
        get(data, `localsites`) || get(data, `localsites_results`)

      /*
      If customer has exactly one (not multiple) account and not logged in, set the customer as
      COOKIED.

      If customerResults is 204, then the address has no accounts.
      If customerResults is 200, then the address has a single account.
      If customerResults is 227, then the address has multiple accounts.
    */
      // if (
      //   userState !== userType.LOGGED_IN &&
      //   _.toString(customerResults) === `200`
      // ) {
      //   dispatch(setUserState(userType.COOKIED))
      // }

      if (customerResults !== `204`) {
        /* filter eligibility */
        if (isObjectLike(eligibilityResults)) {
          const tempResults = {}
          forEach(eligibilityResults, (eligibility) => {
            tempResults[eligibility.line_of_business] =
              eligibility.is_customer_eligible === `Y`
          })
          eligibilityResults = tempResults
        }
        /* filter products */
        if (isObjectLike(productResults)) {
          const tempResults = {}
          const isFranchiseZone = `isFranchiseZone`
          const statusCode = `statusCode`
          const { pathname } = window.location
          const splitPath = pathname.split(`/`)
          const isDumspterPage = splitPath.indexOf(`dumpster-rental`) > 0
          forEach(productResults, (product) => {
            switch (product.status_code) {
              case combinedProducts.VALID_REDIRECT_URL:
                tempResults[product.line_of_business] = product.url
                break
              case combinedProducts.DISPLAY_SUPPORT_FOR_UNAVAILABLE_PRODUCTS:
                if (!isDumspterPage) {
                  tempResults[product.line_of_business] = product.status_code
                }
                break
              case combinedProducts.HIDE_SUPPORT_FOR_UNAVAILABLE_PRODUCTS:
                break
              case combinedProducts.SHOW_FRANCHISE_MESSAGE:
                tempResults[isFranchiseZone] = product.is_franchise_zone
                tempResults[statusCode] = product.status_code
                break
              default:
                break
            }
          })
          productResults = tempResults
        }

        dispatch(
          fulfilledAC({
            customers,
            customerResults,
            eligibilityResults,
            productResults,
            localsitesResults,
          }),
        )

        return {
          customers,
          customerResults,
          eligibilityResults,
          productResults,
          localsitesResults,
        }
      }

      return null
    })
    .catch(() => {
      dispatch(failedAC())
      return null
    })
}
