import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.UN_ENROLL_PAPERLESS_BILLING_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.UN_ENROLL_PAPERLESS_BILLING_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.UN_ENROLL_PAPERLESS_BILLING_FULFILLED,
  payload: data,
})

export const clearUnenrollPaperlessStatus = () => ({
  type: ActionTypes.CLEAR_UNENROLL_PAPERLESS_STATUS,
})

export const unEnrollPaperlessBilling =
  (customerIdToUnEnroll = ``) =>
  (dispatch, getState) => {
    const state = getState()
    const api = `PAPERLESS_BILLING`
    dispatch(pendingAC())

    const template = URITemplate(urls.url[api])
    const customerId =
      customerIdToUnEnroll === ``
        ? _.get(
            state.customerAutoPayPaperless,
            `autoPaymentAndPaperlessAccount`,
            ``,
          )
        : customerIdToUnEnroll
    const url = template.expand({ customerId })

    return getTokenAction(dispatch, getState)
      .then((token) => {
        const state = getState()
        const config = {
          headers: {
            oktaToken: token.accessToken,
            referrerUrl: `${window.location.origin}${window.location.pathname}`,
          },
          params: {
            userId: _.get(state, `userAccount.userDetails.userId`, ``),
            lang: state.siteLanguage.language,
          },
        }

        const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }

        return axios
          .create(config)
          .post(url, {
            paperless: `N`,
          })
          .then((response) => {
            dispatch(fulfilledAC(customerId))
            return response
          })
      })
      .catch((response) => {
        dispatch(
          failedAC({
            statusCode: _.get(response, `response.status`, ``),
            errorMessage: _.get(response, `response.data.Error`, ``),
          }),
        )
        return response
      })
  }
