import dayjs from "utils-lib/date"

/**
 * Returns an array of dates to exclude from the calendar if the argument
 * passed is "excludeDates", otherwise it returns a boolean value based on the
 * date passed.
 *
 * @function
 * @param {string} paymentDate - The date that the user has selected.
 * @returns {(boolean|Array)} An array of dates to exclude from the calendar if the argument passed is "excludeDates", otherwise a boolean value based on the date passed.
 *
 * For PAD Agreement Scenario to disable continue button when selected calendar
 * date is not expected Return a excludeDates array which disabled 3days of date
 * selection if exclude is passed on call Return true/false if payment dates is
 * passed to disabled the continue button on both Guest and Logged in Checkout
 */
export const padValidationForCheckout = (paymentDate) => {
  const excludeDates = []
  const today = dayjs().format()
  const tommorow = dayjs().add(1, "days").format()
  const secondDay = dayjs().add(2, "days").format()
  const thirdDay = dayjs().add(3, "days").format()
  excludeDates.push(today, tommorow, secondDay, thirdDay)
  const isDateInvalid =
    paymentDate === `excludeDates`
      ? excludeDates
      : dayjs(paymentDate).isSame(today, `day`) ||
        dayjs(paymentDate).isSame(tommorow, `day`) ||
        dayjs(paymentDate).isSame(secondDay, `day`) ||
        dayjs(paymentDate).isSame(thirdDay, `day`)
  return isDateInvalid
}
