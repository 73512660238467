import isString from "lodash/isString"

/**
 * Removes all non-numeric characters from a string.
 *
 * The first line of the function is a guard clause.
 * It checks to see if the input is a string. If it's
 * not, it returns an empty string.
 *
 * @function
 * @param {string} inputString - The string to be cleaned.
 * @returns {string} A string with all non-numeric characters removed.
 */
export const removeNonNumericCharacters = (inputString) => {
  if (!isString(inputString)) {
    return ``
  }

  const rawNumeric = inputString.replace(/\D/g, ``)
  return rawNumeric
}
