import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  isSubMenuShown: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SUBMENU_SHOWN:
      return {
        ...state,
        isSubMenuShown: action.payload,
      }

    default:
      return state
  }
}
