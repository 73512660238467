import * as ActionTypes from "constants-lib/actionTypes"
import URITemplate from "urijs/src/URITemplate"
import { getTokenAction } from "actions"
import axios from "axios"
import _ from "lodash"
import { urls } from "utils-lib/builds"

export const pendingRepairEligibilityAC = () => ({
  type: ActionTypes.GET_REPAIR_ELIGIBILITY_PENDING,
})

export const failedRepairEligibilityAC = (fetchCode) => ({
  type: ActionTypes.GET_REPAIR_ELIGIBILITY_FAILED,
  payload: fetchCode,
})

export const fulfilledRepairEligibilityAC = (data) => ({
  type: ActionTypes.GET_REPAIR_ELIGIBILITY_FULFILLED,
  payload: data,
})

export const getContainerRepairEligibility =
  (serviceId, customerId, lang) => (dispatch, getState) => {
    const api = `REPAIR_ELIGIBILITY`
    dispatch(pendingRepairEligibilityAC())
    const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)
    const template = URITemplate(urls.url[api])
    const url = template.expand({
      lang,
      serviceId,
      customerId,
      userId,
    })
    const config = {
      method: `GET`,
      headers: {},
    }

    return getTokenAction(dispatch, getState).then((token) => {
      config.headers[urls.okta.tokenFieldKey] = token.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

      return getContainerRepairEligibilityHelper(url, config, dispatch)
    })
  }

export const getContainerRepairEligibilityHelper = (url, config, dispatch) =>
  axios(url, config)
    .then((response) => {
      const token = response.config.headers.oktaToken
      const statusCode = _.get(response, `status`, ``)
      dispatch(
        fulfilledRepairEligibilityAC({
          data: response.data,
          statusCode,
          verificationToken: token,
        }),
      )

      return response
    })
    .catch((error) => {
      dispatch(failedRepairEligibilityAC(_.get(error, `response.status`, ``)))

      return error
    })
