import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import {
  MOCK_BULK_ERROR_SERVLET,
  MOCK_RESI_ERROR_SERVLET,
} from "utils-lib/mocks"
import { urls } from "utils-lib/builds"
import {
  GET_ERROR_MESSAGE_SERVLET_PENDING,
  GET_ERROR_MESSAGE_SERVLET_FAILED,
  GET_ERROR_MESSAGE_SERVLET_FULFILLED,
} from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export const pendingAC = () => ({
  type: GET_ERROR_MESSAGE_SERVLET_PENDING,
})

export const failedAC = (data) => ({
  type: GET_ERROR_MESSAGE_SERVLET_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: GET_ERROR_MESSAGE_SERVLET_FULFILLED,
  payload: data,
})

export const getServletErrorMessages = () => (dispatch, getState) => {
  const { siteLanguage } = getState()
  const locale = siteLanguage.language || ""
  const serviceType = `Resi`
  const api = `AEM_RESIERROR_MESSAGE_SERVLET`
  const template = URITemplate(urls.url[api])
  const preURL = template.expand({
    locale,
    serviceType,
  })
  const url = MOCK_RESI_ERROR_SERVLET
    ? `${window.origin}/mock/resi-page.json`
    : preURL
  const config = {
    url,
    method: `get`,
  }

  dispatch(pendingAC(locale))
  // eslint-disable-next-line consistent-return
  return axios(config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      return response.data
    })
    .catch((error) => {
      dispatch(failedAC(error?.response?.status))
      return error
    })
}

export const getServletBulkErrorMessage = () => (dispatch, getState) => {
  const { siteLanguage } = getState()
  const locale = encodeURI(siteLanguage.language || ``)
  const serviceType = `Bulk`
  const api = `AEM_BULKERROR_MESSAGE_SERVLET`
  const template = URITemplate(urls.url[api])
  const theURL = template.expand({
    locale,
    serviceType,
  })
  const url = MOCK_BULK_ERROR_SERVLET
    ? `${window.origin}/mock/bulk-exception-address-servlet.json`
    : theURL
  const config = {
    url,
    method: `get`,
  }

  dispatch(pendingAC(locale))
  // eslint-disable-next-line consistent-return
  return axios(config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      return response.data
    })
    .catch((error) => {
      dispatch(failedAC(error?.response?.status))
      return error
    })
}
