import * as ActionTypes from "constants-lib/actionTypes"
import URITemplate from "urijs/src/URITemplate"
import { getTokenAction } from "actions"
import axios from "axios"
import _ from "lodash"
import { urls } from "utils-lib/builds"

/* GET Recycling Pickup Eligibility API */
export const pendingRecylingPickupEligibility = () => ({
  type: ActionTypes.GET_RECYLING_PICKUP_ELIGIBILITY_PENDING,
})

export const failedRecylingPickupEligibility = (fetchCode) => ({
  type: ActionTypes.GET_RECYLING_PICKUP_ELIGIBILITY_FAILED,
  payload: fetchCode,
})

export const fulfilledRecylingPickupEligibility = (data) => ({
  type: ActionTypes.GET_RECYLING_PICKUP_ELIGIBILITY_FULFILLED,
  payload: data,
})

/* GET Recycling Pickup Date */
export const pendingRecylingPickupDate = () => ({
  type: ActionTypes.GET_RECYLING_PICKUP_DATE_PENDING,
})

export const failedRecylingPickupDate = (fetchCode) => ({
  type: ActionTypes.GET_RECYLING_PICKUP_DATE_FAILED,
  payload: fetchCode,
})

export const fulfilledRecylingPickupDate = (data) => ({
  type: ActionTypes.GET_RECYLING_PICKUP_DATE_FULFILLED,
  payload: data,
})

/* Post Recycling Pickup Submit */
export const pendingRecylingPickupSubmit = () => ({
  type: ActionTypes.GET_RECYLING_PICKUP_SUBMIT_PENDING,
})

export const failedRecylingPickupSubmit = (fetchCode) => ({
  type: ActionTypes.GET_RECYLING_PICKUP_SUBMIT_FAILED,
  payload: fetchCode,
})

export const fulfilledRecylingPickupSubmit = (data) => ({
  type: ActionTypes.GET_RECYLING_PICKUP_SUBMIT_FULFILLED,
  payload: data,
})

/* Recycling Pickup Eligibility API and Helper */
export const getRecyclingPickupEligibility =
  (customerId, lang) => (dispatch, getState) => {
    const api = `RECYCLING_PICKUP`
    dispatch(pendingRecylingPickupEligibility())
    const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)
    const serviceId = _.get(
      getState(),
      `myServices.selectedServiceObject.serviceId`,
      ``,
    )
    const template = URITemplate(urls.url[api])
    if (userId && serviceId) {
      const url = template.expand({
        customerId,
        userId,
        lang: lang.language,
        serviceId,
      })

      const config = {
        headers: {},
      }

      return getTokenAction(dispatch, getState).then((token) => {
        config.headers[urls.okta.tokenFieldKey] = token.accessToken
        config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

        return getRecyclingPickupEligibilityHelper(url, config, dispatch)
      })
    }

    return null
  }

export const getRecyclingPickupEligibilityHelper = (url, config, dispatch) =>
  axios
    .get(url, config)
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      dispatch(
        fulfilledRecylingPickupEligibility({
          data: response,
          statusCode,
        }),
      )

      return response
    })
    .catch((error) => {
      dispatch(
        failedRecylingPickupEligibility(_.get(error, `response.status`, ``)),
      )

      return error
    })

/* Recycling Pickup Dates API and Helper */
export const getRecyclingPickupDate =
  (customerId, lang) => (dispatch, getState) => {
    const api = `RECYCLING_PICKUP_DATE`
    dispatch(pendingRecylingPickupDate())
    const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)
    const serviceId = _.get(
      getState(),
      `myServices.selectedServiceObject.serviceId`,
      ``,
    )
    const template = URITemplate(urls.url[api])
    const url = template.expand({
      customerId,
      userId,
      lang: lang.language,
      serviceId,
    })

    const config = {
      headers: {},
    }

    return getTokenAction(dispatch, getState).then((token) => {
      config.headers[urls.okta.tokenFieldKey] = token.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

      return getRecyclingPickupDateHelper(url, config, dispatch)
    })
  }

export const getRecyclingPickupDateHelper = (url, config, dispatch) =>
  axios
    .get(url, config)
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      dispatch(
        fulfilledRecylingPickupDate({
          data: response,
          statusCode,
        }),
      )
      return response
    })
    .catch((error) => {
      dispatch(failedRecylingPickupDate(_.get(error, `response.status`, ``)))

      return error
    })

/* Recycling Pickup Submit API and Helper */
export const setRecyclingPickupSubmit =
  (customerId, lang, data) => (dispatch, getState) => {
    const api = `RECYCLING_PICKUP_SUBMIT`
    dispatch(pendingRecylingPickupSubmit())
    const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)
    const serviceId = _.get(
      getState(),
      `myServices.selectedServiceObject.serviceId`,
      ``,
    )
    const template = URITemplate(urls.url[api])
    const url = template.expand({
      customerId,
      userId,
      lang,
      serviceId,
    })

    const config = {
      headers: {},
    }

    return getTokenAction(dispatch, getState).then((token) => {
      config.headers[urls.okta.tokenFieldKey] = token.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

      return setRecyclingPickupSubmitHelper(url, data, config, dispatch)
    })
  }

export const setRecyclingPickupSubmitHelper = (url, data, config, dispatch) =>
  axios
    .post(url, data, config)
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      dispatch(
        fulfilledRecylingPickupSubmit({
          data: response,
          statusCode,
        }),
      )

      return response
    })
    .catch((error) => {
      dispatch(failedRecylingPickupSubmit(_.get(error, `response.status`, ``)))

      return error
    })
