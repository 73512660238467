import { userType } from "constants-lib/authentication"
import { authClient } from "constants-lib/okta"
import { getTimeLeftSec } from "utils-lib/time"
import { logRocketTrack } from "utils-lib/logRocket"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { setToken } from "../setToken"
import { resetUserAccount } from "../resetUserAccount"

/**
 * Retrieves the access token for making backend API calls, handling token refresh if necessary.
 * @param {Function} getState - A function that returns the current state of the Redux store.
 * @returns {Promise<Object>} A promise that resolves with the access token object.
 */
/* Called by other actions to get the access token for making backend API calls. */
export const getTokenAction = (dispatch, getState) => {
  const state = getState()
  const userState = get(state, `userAccount.userState`, ``)
  const oldToken = get(state, `userAccount.token`, null)

  const setLoggedOut = () => {
    if (userState === userType.LOGGED_IN || userState === userType.LOGGING_IN) {
      /*
        Update the user state as no longer logged in. Update to cookied if we have an address for
        the user, or else update to anonymous.

        TODO: If we want to redirect a user who failed to log in to the login page, add LOGGING_IN
        state to the conditional block below. For now, we'll keep the user on the user dashboard
        page so that the current implementation of the showPageFailedModal will kick in.
      */
      if (userState === userType.LOGGED_IN) {
        console.warn(`Logged in user is no longer logged in.`)
        const newState =
          get(
            state.customerSelections,
            `customerAddress.formattedAddress`,
            ``,
          ) !== ``
            ? userType.COOKIED
            : userType.ANONYMOUS

        dispatch(resetUserAccount(newState))
      } else {
        console.warn(`Logging in failed.`)
        logRocketTrack(`getWithoutPrompt-failed-logging-in`)
      }

      /*
        If a user is expected to be logged in but okta reported that it's no longer logged in,
        probably due to an expired token/session, then throw an error to end the promise chain.

        For example, if getLinkedAccounts is called but the user has timed out, then we need to
        throw an error here to prevent the chained actions (.then's) from being called after
        getLinkedAccounts fulfills.
      */
      throw new Error(`Logged out`)
    }
    return null
  }

  /*
    authClient.session.exists()
    Returns a promise that resolves with true if there is an existing Okta session, or false if not.
    https://github.com/okta/okta-auth-js#sessionexists
    Do not call session.exists() every time we get a token to reduce the number of okta calls.
    Only call session.exists() the first time the app loads in App.jsx.

    The first time we get an access token (if userAccount.token is null) we should use
    getWithoutPrompt() to generate one, once we have an access token we should use refresh()
    to revoke that token and generate a new one with new expiration time, but we are not going
    to refresh it every time we get a token. Instead, we'll rely on the expiration feature in
    our main App component.

    1. authClient.token.getWithoutPrompt(oauthOptions)
    When you've obtained a sessionToken from the authorization flows, or a session already exists,
    you can obtain a token or tokens without prompting the user to log in.
    https://github.com/okta/okta-auth-js#tokengetwithoutpromptoauthoptions

    2. authClient.token.renew(oldToken)
    Returns a new token if the Okta session is still valid.
    oldToken - an access token or ID token previously provided by Okta. note: this is not the raw JWT
    https://github.com/okta/okta-auth-js#tokenrefreshtokentorefresh

    Generate first access token if we haven't yet or if the access token has expired.
  */

  const accessTokenTimeLeftSec = getTimeLeftSec(get(oldToken, `expiresAt`, 0))
  if (oldToken === null || accessTokenTimeLeftSec <= 0) {
    logRocketTrack(`getWithoutPrompt`)
    logRocketTrack(`getWithoutPrompt-self`)

    return authClient.token
      .getWithoutPrompt({
        responseType: `token`,
      })
      .then((token) => {
        // Okta token object has changed the structure, but to be safe we are checking the old structure as well in case this changes again
        const refreshedToken = !isEmpty(token?.accessToken)
          ? token
          : token?.tokens?.accessToken

        // this to help with simulating an error in test env
        if (process.env.NODE_ENV === `test` && oldToken === `error`) {
          throw new Error()
        }

        logRocketTrack(`getWithoutPrompt-fulfilled`)
        dispatch(setToken(refreshedToken))
        return refreshedToken
      })
      .catch((e) => {
        logRocketTrack(`getWithoutPrompt-failed`)
        console.error(`getWithoutPrompt failed with error:`, e)

        return setLoggedOut()
      })
  }

  /* We had a token already, so return that token.
     To reduce the number of okta calls, we aren't refreshing that token here. */
  return new Promise((resolve) => {
    resolve(oldToken)
  })
}

export const getToken = () => getTokenAction
