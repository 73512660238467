import toArray from "lodash/toArray"
import slice from "lodash/slice"
import mapValues from "lodash/mapValues"
import join from "lodash/join"
import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { getUserValues } from "utils-lib/analytics/sca"
import { setSCAValues, getTokenAction } from "actions-lib"
import { urls } from "utils-lib/builds"
import { updateVisitorDL } from "utils-lib/analytics/dataLayer"
import {
  GET_LINKED_ACCOUNTS_PENDING,
  GET_LINKED_ACCOUNTS_FAILED,
  GET_LINKED_ACCOUNTS_FULFILLED,
} from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export const pendingAC = () => ({
  type: GET_LINKED_ACCOUNTS_PENDING,
})

export const failedAC = (data) => ({
  type: GET_LINKED_ACCOUNTS_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: GET_LINKED_ACCOUNTS_FULFILLED,
  payload: data,
})

/**
 * Initiates the process to fetch linked accounts for a user. If the token is not provided, it attempts to fetch a new token.
 * @param {Object} options - The options object.
 * @param {Object} options.token - The token object containing the access token.
 * @param {string} options.token.accessToken - The access token used for authentication.
 * @param {string} [options.userId=""] - The ID of the user for whom to fetch linked accounts. Defaults to an empty string.
 * @param {function} dispatch - The Redux dispatch function.
 */
export const getLinkedAccounts =
  ({ token, userId = `` } = {}) =>
  (dispatch, getState) => {
    dispatch(pendingAC())
    const state = getState()
    const config = {
      params: {
        lang: get(state, `siteLanguage.language`, `en_CA`),
      },
    }

    if (token === undefined) {
      return getTokenAction(dispatch, getState).then((accessToken) =>
        getLinkedAccountsHelper(
          config,
          accessToken,
          get(state, `userAccount.userDetails.userId`, ``),
          state,
          dispatch,
        ),
      )
    }

    return getLinkedAccountsHelper(
      config,
      token,
      userId || get(state, `userAccount.userDetails.userId`, ``),
      state,
      dispatch,
    )
  }

/**
 * This function helps to get linked accounts.
 * @param {Object} config - The configuration object.
 * @param {Object} config.headers - The headers of the configuration object.
 * @param {Object} token - The token object.
 * @param {string} token.accessToken - The access token of the token object.
 * @param {string} userId - The ID of the user.
 * @param {Object} state - The state object.
 * @param {Object} state.userAccount - The user account of the state object.
 * @param {function} dispatch - The dispatch function.
 * @returns {Promise} A promise that resolves to an object containing linked accounts, access token, and user ID.
 */
export const getLinkedAccountsHelper = (
  config,
  token,
  userId,
  state,
  dispatch,
) => {
  const api = `USER_ACCOUNTS`
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    userId,
  })

  // eslint-disable-next-line no-param-reassign
  config.headers = {
    [urls.okta.tokenFieldKey]: token.accessToken,
  }

  const apiKey = get(urls, `apiKey.USER[${api}]`, ``)
  if (apiKey !== ``) {
    // eslint-disable-next-line no-param-reassign
    config.headers.apiKey = apiKey
  }

  return axios
    .get(url, config)
    .then((response) => {
      const linkedAccounts = get(response, `data.data.linkedAccounts`, [])
      const linkedAccountsObject = {
        linkedAccounts,
      }
      dispatch(fulfilledAC(linkedAccountsObject))

      window.scaValues = getUserValues({ linkedAccounts })
      setSCAValues(window.scaValues)

      window.cnxUserId = get(response, `data.data.userId`, ``)
      window.cnxCustomerIds = join(
        toArray(mapValues(slice(linkedAccounts, -5), `custAccountId`)),
      )

      /* update visitor data layer */
      updateVisitorDL({
        userAccount: state.userAccount,
        userManageAccount: { linkedAccounts },
      })

      return {
        linkedAccounts,
        token: token.accessToken,
        userId,
      }
    })
    .catch((response) => {
      const statusCode = get(response, `response.status`, ``)
      const errorMessage = get(
        response,
        `response.data.verificationErrorMsg`,
        ``,
      )
      const customerContacts = get(response, `response.data`)
      dispatch(
        failedAC({
          statusCode,
          errorMessage,
          customerContacts,
        }),
      )
    })
}
