import get from "lodash/get"
import filter from "lodash/filter"
import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import { urls } from "utils-lib/builds"
import URITemplate from "urijs/src/URITemplate"

export const fulfilledAC = (data) => ({
  type: ActionTypes.GUEST_BULK_PICKUP_CREATE_TICKET_FULFILLED,
  payload: { data },
})

export const pendingAC = () => ({
  type: ActionTypes.GUEST_BULK_PICKUP_CREATE_TICKET_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.GUEST_BULK_PICKUP_CREATE_TICKET_FAILED,
  payload: data,
})

/**
 * This is an asynchronous function that creates a bulk pickup ticket for a guest user.
 * @function guestBulkPickupCreateTicket
 * @async
 * @param {string} confirmationNumber - The confirmation number for the bulk pickup request.
 * @returns {Promise<Object>} A promise that resolves to the response data from the API call.
 * @throws {Object} An error object containing the error code, error message, and status code if the API call fails.
 */
export const guestBulkPickupCreateTicket =
  (confirmationNumber, isAch = false) =>
  async (dispatch, getState) => {
    const state = getState()
    const bulkFormSelections = get(
      state,
      `myServices.guestBulkPickupIncludedCategories.data.bulkFormSelections`,
      {},
    )
    const customerSelections = get(state, `customerSelections`, {})
    const userAccount = get(state, `userAccount`, {})
    const customerServiceContact =
      filter(
        get(state, `customerContacts.data.customerContacts.contacts`, []),
        (contact) => contact.type === `service`,
      )[0] ||
      filter(
        get(state, `customerContacts.data.customerContacts.contacts`, []),
        (contact) => contact.type === `billing`,
      )[0]

    const data = {
      items: get(
        state,
        `myServices.guestBulkPickupIncludedCategories.data.itemsSelected`,
        [],
      ),
      device: `D`,
      reqType: `BULK_PICKUP`,
      bulkPickupRequestId: get(
        state,
        `myServices.guestBulkPickupPricingData.data.bulkPickupRequestId`,
        ``,
      ),
      payReconcId: confirmationNumber || "",
      contactInfo: {
        firstName:
          get(bulkFormSelections, `First Name`, ``) ||
          get(userAccount, `userDetails.firstName`, ``),
        lastName:
          get(bulkFormSelections, `Last Name`, ``) ||
          get(userAccount, `userDetails.lastName`, ` `),
        email:
          get(bulkFormSelections, `Email`, ``) ||
          get(userAccount, `userDetails.login`, ``),
        phoneNumber:
          get(bulkFormSelections, `Phone Number`, ``) ||
          get(customerServiceContact, `work_phone`, ``) ||
          get(customerServiceContact, `mobile_phone`, ``) ||
          get(userAccount, `userDetails.primaryPhone`, ``) ||
          get(userAccount, `userDetails.mobilePhone`, ``),
      },
      address: {
        street: `${get(
          customerSelections,
          `customerAddress.streetNumber`,
          ``,
        )} ${get(customerSelections, `customerAddress.streetName`, ``)}`,
        city: get(customerSelections, `customerAddress.city`, ``),
        state: get(customerSelections, `customerAddress.state`, ``),
        country: get(customerSelections, `customerAddress.country`, ``),
        postalCode: get(customerSelections, `customerAddress.postalCode`, ``),
      },
    }
    const updatedData = {
      ...data,
      ...(!isAch && {
        convenienceFee: get(
          state,
          `processingFee.processingFeeDetails.convenienceFee`,
          "",
        ),
      }),
    }

    const api = `SUBMIT_GUEST_BULK_PICKUP`
    const baseUrl = urls.url[api]
    dispatch(pendingAC())

    const config = {
      headers: {
        token: get(customerSelections, `cityBilledEligibility.token`, ``),
      },
    }

    const template = URITemplate(`${baseUrl}`)
    const url = template.expand({
      customerId: get(
        customerSelections,
        `cityBilledEligibility.customerId`,
        ``,
      ),
      lang: get(state, `siteLanguage.language`, ``),
    })
    const apiKey = get(urls, `apiKey.GUEST[${api}]`, ``)
    if (apiKey !== ``) {
      config.headers.apiKey = apiKey
    }

    try {
      const response = await axios.create(config).post(url, updatedData)

      dispatch(fulfilledAC(response.data))
      return response.data
    } catch (error) {
      const errorCode = get(error, `response.status`, ``)
      const errorMsg = get(error, `response.data.errorMsg`, ``)
      const statusCode = get(error, `response.data.statusCode`, ``)
      dispatch(failedAC({ errorCode, errorMsg, statusCode }))
      return error
    }
  }
