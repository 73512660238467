import get from "lodash/get"
import axios from "axios"
import {
  GET_GUEST_BULK_PICKUP_GUIDELINES_FAILED,
  GET_GUEST_BULK_PICKUP_GUIDELINES_FULFILLED,
  GET_GUEST_BULK_PICKUP_GUIDELINES_PENDING,
} from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"

const window = getWindow()
const GET_MOCK = false

export const pendingAC = () => ({
  type: GET_GUEST_BULK_PICKUP_GUIDELINES_PENDING,
})

export const failedAC = () => ({
  type: GET_GUEST_BULK_PICKUP_GUIDELINES_FAILED,
})

export const fulfilledAC = (data) => ({
  type: GET_GUEST_BULK_PICKUP_GUIDELINES_FULFILLED,
  payload: data,
})

/**
 * Fetches guidelines for guest bulk pickup based on location and customer details.
 * @param {Object} params - The parameters for fetching the guidelines.
 * @param {string} params.street - The street address for the pickup location.
 * @param {string} params.city - The city of the pickup location.
 * @param {string} params.state - The state of the pickup location.
 * @param {string} params.country - The country of the pickup location.
 * @param {string} params.postalCode - The postal code of the pickup location.
 * @param {string} params.language - The language preference for the guidelines.
 * @param {string} params.customerId - The unique identifier for the customer.
 * @param {Function} dispatch - Dispatches actions to the Redux store.
 * @returns {Function} Initiates the fetch process and handles the response.
 */
export const getGuestBulkPickupGuidelines =
  ({ street, city, state, country, postalCode, language, customerId }) =>
  (dispatch) => {
    const api = `GUEST_BULK_PICKUP_GUIDELINES`

    const baseUrl = urls.url[api]

    const template = URITemplate(`${baseUrl}`)
    const url = GET_MOCK
      ? `${window.origin}/mock/guest-bulk-pickup-guidelines-response.json`
      : template.expand({
          customerId,
          street,
          city,
          state,
          country,
          postalCode,
          lang: language,
        })
    dispatch(pendingAC())
    return axios
      .get(url, {
        headers: {
          apiKey: get(urls, `apiKey.GUEST[${api}]`, ``),
        },
      })
      .then((response) => {
        dispatch(fulfilledAC(response.data))
        return response.data
      })
      .catch(() => {
        dispatch(failedAC())
      })
  }
