import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import moment from "moment"
import { getOrderDetails } from "actions/getOrderDetails"
import { generateUniqueID } from "utils-lib/requests"
import { urls } from "utils-lib/builds"
import { MOCK_ORDER_INFORMATION } from "utils-lib/mocks"
import { setDataCache } from "actions/setDataCache"
import { CACHE_KEYS } from "constants-lib/dataCache"

export const pendingAC = () => ({
  type: ActionTypes.GET_ORDER_SUMMARY_PENDING,
})

export const failedAC = (errorCode) => ({
  type: ActionTypes.GET_ORDER_SUMMARY_FAILED,
  payload: errorCode,
})

export const fulfilledAC = (data, statusCode) => ({
  type: ActionTypes.GET_ORDER_SUMMARY_FULFILLED,
  payload: {
    data,
    statusCode,
  },
})

export const getOrderSummary =
  (worker = null) =>
  (dispatch, getState) => {
    dispatch(pendingAC())
    const state = getState()
    const api = `ORDER_SUMMARY`
    const template = URITemplate(urls.url[api])

    const url = MOCK_ORDER_INFORMATION
      ? `/mock/order_summary_response.json`
      : template.expand({
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
        })

    // const Authorization = `Bearer ${_.get(state, `cloudCraze.esbTokens.access_token`, ``)}`
    const accessToken = _.get(state, `userAccount.token.accessToken`, ``)

    const config = {
      headers: {
        // Authorization,
        "Content-Type": `application/json`,
        "Request-Tracking-Id": generateUniqueID(),
        "X-Authorization-CA": accessToken,
      },
      params: {
        from_date: moment().subtract(180, `days`).format(`MM-DD-YYYY`),
        to_date: moment().format(`MM-DD-YYYY`),
      },
    }

    const apiKey = _.get(urls, `apiKey.ESB[${api}]`, ``)
    if (apiKey !== ``) {
      config.headers.ClientId = apiKey
    }

    return axios
      .create(config)
      .get(url)
      .then((response) => {
        const statusCode = _.get(response, `status`, 200)
        dispatch(fulfilledAC(response.data, statusCode))
        dispatch(getOrderDetails(worker))
        return response
      })
      .catch((error) => {
        dispatch(failedAC(_.get(error, `response.status`, 500)))
        dispatch(
          setDataCache({
            field: CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA,
            /* cache results as an array of 1 null item if there are no results, this helps with tracking completion on the CombinedOrderHistory page  */
            data: [null],
          }),
        )
      })
  }
