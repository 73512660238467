import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  CANCEL_EXTRA_PICKUP_PENDING,
  CANCEL_EXTRA_PICKUP_FAILED,
  CANCEL_EXTRA_PICKUP_FULFILLED,
} from "constants-lib/actionTypes"
import { getTokenAction } from "actions"
import { updateOrderHistoryCache } from "actions/updateOrderHistoryCache"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: CANCEL_EXTRA_PICKUP_PENDING,
})

export const failedAC = () => ({
  type: CANCEL_EXTRA_PICKUP_FAILED,
})

export const fulfilledAC = () => ({
  type: CANCEL_EXTRA_PICKUP_FULFILLED,
})

export const cancelExtraPickup =
  ({ customerId, serviceId, extraPickupRequestId, userId, lang }) =>
  (dispatch, getState) => {
    const api = `EXTRA_PICKUP_CANCEL`
    const template = URITemplate(urls.url[api])

    const url = template.expand({
      customerId,
      serviceId,
      extraPickupRequestId,
      userId,
      lang,
    })

    const config = {
      method: `DELETE`,
      headers: {},
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return cancelExtraPickupHelper(url, config, dispatch)
    })
  }

export const cancelExtraPickupHelper = (url, config, dispatch) => {
  dispatch(pendingAC())

  return axios(url, config)
    .then((response) => {
      dispatch(updateOrderHistoryCache(response))
      dispatch(fulfilledAC())
      return response
    })
    .catch(() => {
      dispatch(failedAC())
    })
}
