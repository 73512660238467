import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { profileIdForAPIs } from "constants-lib/profileIdForAPIs"
import { urls } from "utils-lib/builds"
import { generateUniqueID } from "utils-lib/requests"
import {
  CLEAR_EMAIL_CLEANSE,
  GET_EMAIL_CLEANSE_PENDING,
  GET_EMAIL_CLEANSE_FULFILLED,
  GET_EMAIL_CLEANSE_FAILED,
} from "constants-lib/actionTypes"

export const clearEmailCleanse = () => ({
  type: CLEAR_EMAIL_CLEANSE,
})

/**
 * Cleanses an email address.
 * @function
 * @param {string} email - The email address to cleanse.
 * @param {function} [cb=() => {}] - The callback function to be called on completion.
 * @returns {function} - dispatches an action to cleanse an email address.
 */
export const getEmailCleanse =
  (email, cb = () => {}) =>
  (dispatch) => {
    // TODO Fail action if token or customerId is empty.
    const template = URITemplate(urls.cloudfront.melissaData.emailCleanse.url)
    const getEmailCleanseUrl = template.expand({ emailId: email })
    const apiType = `GET_EMAIL_CLEANSE`
    const clientId = profileIdForAPIs().profileId[apiType]

    const getEmailCleanseRequest = {
      url: getEmailCleanseUrl,
      headers: {
        Accept: `application/json`,
        "Request-Tracking-Id": generateUniqueID(),
        ClientId: clientId,
      },
      method: `GET`,
    }
    dispatch({
      type: GET_EMAIL_CLEANSE_PENDING,
      payload: { email },
    })

    return axios(getEmailCleanseRequest)
      .then((response) => {
        dispatch({
          type: GET_EMAIL_CLEANSE_FULFILLED,
          payload: {
            ...response.data,
            email,
          },
        })
        cb()
        return response
      })
      .catch((response) => {
        dispatch({
          type: GET_EMAIL_CLEANSE_FAILED,
          payload: {
            ...response,
            email,
          },
        })
        cb()
      })
  }
