import get from "lodash/get"
import filter from "lodash/filter"
import * as ActionTypes from "constants-lib/actionTypes"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"

import { urls } from "utils-lib/builds"
import { getTokenAction } from "../getToken"

export const pendingAC = () => ({
  type: ActionTypes.SUBMIT_CONTAINER_NOT_OUT_REQUEST_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.SUBMIT_CONTAINER_NOT_OUT_REQUEST_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.SUBMIT_CONTAINER_NOT_OUT_REQUEST_FULFILLED,
  payload: data,
})

export const clearSubmitContainerNotOutRequest = () => ({
  type: ActionTypes.CLEAR_SUBMIT_CONTAINER_NOT_OUT_REQUEST,
})

export const submitContainerNotOutRequest = () => (dispatch, getState) => {
  const state = getState()
  const api = `CONTAINER_NOT_OUT_SUBMISSION`
  const template = URITemplate(urls.url[api])
  const lang = state.siteLanguage?.language ? state.siteLanguage.language : ``
  const customerId = state.userManageAccount?.linkedAccounts[0]?.custAccountId
  const serviceId = state?.myServices?.containerNotOutEligibilityData?.serviceId

  const { userId, firstName, lastName, login, primaryPhone, mobilePhone } =
    state.userAccount?.userDetails || {}

  const cnoEligibilityId =
    state?.myServices?.containerNotOutEligibilityData?.cnoEligibilityId

  const customerServiceContact =
    filter(
      get(state.customerContacts, `data.customerContacts.contacts`, []),
      (contact) => contact.type === `service`,
    )[0] ||
    filter(
      get(state.customerContacts, `data.customerContacts.contacts`, []),
      (contact) => contact.type === `billing`,
    )[0]

  const url = template.expand({
    userId,
    customerId,
    lang,
  })

  const config = {
    headers: {},
  }

  const data = {
    cnoEligibilityId,
    serviceId,
    contactInfo: {
      firstName,
      lastName,
      email: login,
      phoneNumber:
        primaryPhone ||
        mobilePhone ||
        customerServiceContact?.workPhone ||
        customerServiceContact?.work_phone ||
        customerServiceContact?.mobilePhone ||
        customerServiceContact?.mobile_phone ||
        `5555555555`,
    },
  }

  return getTokenAction(dispatch, getState).then(async (res) => {
    config.headers.oktaToken = res.accessToken
    config.headers.apiKey = get(urls, `apiKey.USER[${api}]`, ``)
    return submitContainerNotOutRequestHelper(data, url, config, dispatch)
  })
}

/**
 * Helper function for submitting a container not out request to the server.
 * @param {Object} data - The payload for the POST request.
 * @param {string} url - The URL to make the POST request to.
 * @param {Object} config - The Axios request configuration.
 * @param {Function} dispatch - The Redux dispatch function.
 * @returns {Promise} A promise that resolves with the response of the POST request or rejects with an error.
 */
export const submitContainerNotOutRequestHelper = (
  data,
  url,
  config,
  dispatch,
) => {
  if (!data.cnoEligibilityId || !data.serviceId) {
    dispatch(failedAC())
    return
  }

  dispatch(pendingAC())
  // eslint-disable-next-line consistent-return
  return axios
    .create(config)
    .post(url, data)
    .then((response) => {
      const statusCode = get(response, `status`, ``)
      dispatch(
        fulfilledAC({
          submitContainerNotOutRequest: response.data.data,
          statusCode,
        }),
      )
      return response
    })
    .catch((error) => {
      dispatch(failedAC(get(error, `response.status`, ``)))
    })
}

export const clearSubmitContainerNotOut = () => (dispatch) => {
  dispatch(clearSubmitContainerNotOutRequest())
}
