import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import moment from "moment"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"
import { isValidPaymentAmount } from "utils-lib/validation"
import { isUSCanadaAccount } from "utils-lib/isUSCanadaAccount"

export const pendingAC = () => ({
  type: ActionTypes.GET_SINGLE_USER_PAYMENT_PENDING,
})

export const failedAC = (statusCode) => ({
  type: ActionTypes.GET_SINGLE_USER_PAYMENT_FAILED,
  payload: statusCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_SINGLE_USER_PAYMENT_FULFILLED,
  payload: data,
})

export const translateResponse = (response, getState, customerId) => {
  let totalCurrent = 0
  let totalCurrentWithCredits = 0
  const invoice = {}
  let currency = ``
  const billingDetails = {}
  const amountBalanceByAccount = {}

  const state = getState()

  const isMultiCountry = isUSCanadaAccount(state.userManageAccount)
  const selectedCurrency = _.get(
    state,
    `myPaymentCheckout.selectedCurrency`,
    `USD`,
  )

  const userAccountDetails = _.get(
    state,
    `userManageAccount.userAccountDetails`,
    [],
  )
  const invalidAccounts = userAccountDetails
    .filter((s) => s.status === `WRTOFFCAN`)
    .map((s) => s.custAccountId)

  const balances = _.get(response, `data.body.balances`, [])
  currency = _.get(response, `data.body.currency`, ``)
  const balanceObj = _.find(balances, (s) => s.type === `Total`)

  const balance = parseFloat(_.get(balanceObj, `amount`, `0`))

  const shouldAddBalance =
    !isMultiCountry ||
    (selectedCurrency === `USD` && currency === `USD`) ||
    (selectedCurrency === `CAD` && currency === `CAD`)

  if (shouldAddBalance) {
    totalCurrentWithCredits += balance
  }
  if (
    isValidPaymentAmount(balance) === true &&
    !invalidAccounts.includes(customerId) &&
    shouldAddBalance
  ) {
    totalCurrent += balance
  }

  /* Create key value pairs for amount and due date for each account */
  const invoices = _.get(response, `data.body.invoices`, [])
  if (invoices.length > 0) {
    const latestInvoice = _.last(invoices)
    amountBalanceByAccount[customerId] = {
      amount: balance,
      amountAfterDueDate: _.get(
        latestInvoice,
        `amount_owed_after_due_date`,
        `0`,
      ),
      dueDate: _.get(latestInvoice, `due_date`, ``),
    }
  } else if (balances.length > 0) {
    amountBalanceByAccount[customerId] = {
      amount: balance,
    }
  }
  billingDetails[customerId] = _.get(response, `data.body`, {})

  return {
    totalCurrent,
    totalCurrentWithCredits,
    invoice,
    billingDetails,
    currency,
    amountBalanceByAccount,
  }
}

export const getSingleUserPaymentDetails =
  (forceCustomerId) => (dispatch, getState) => {
    dispatch(pendingAC())

    const state = getState()
    const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)
    const customerId =
      forceCustomerId || _.get(state, `userBilling.billingDetailsAccount`, ``)

    // if (token === undefined) {
    return getTokenAction(dispatch, getState)
      .then((res) =>
        getUserPaymentDetailsHelper(
          {
            customerId,
            token: res.accessToken,
            userId,
          },
          dispatch,
          getState,
        ),
      )
      .catch(() => {
        dispatch(failedAC())
      })
  }

export const getUserPaymentDetailsHelper = (
  { customerId, token, userId },
  dispatch,
  getState,
) => {
  const api = `INVOICE`
  const template = URITemplate(urls.url[api])
  const url = template.expand({ customerId })

  const config = {
    headers: {
      token,
      apiKey: _.get(urls, `apiKey.USER[${api}]`, ``),
    },
    params: {
      lang: _.get(getState(), `siteLanguage.language`, `en_CA`),
      fromDate: moment().subtract(1, `years`).format(`YYYY-MM-DD`),
      toDate: moment().add(1, `months`).format(`YYYY-MM-DD`),
      userId,
    },
  }

  return axios
    .create(config)
    .get(url)
    .then((response) => {
      const translatedResponse = translateResponse(
        response,
        getState,
        customerId,
      )
      dispatch(fulfilledAC(translatedResponse))
      return translatedResponse
    })
    .catch((e) => {
      dispatch(failedAC(e.response.status))
      return false
    })
}
