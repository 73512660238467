import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { urls } from "utils-lib/builds"

export const pendingServices = () => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_SERVICES_PENDING,
})

export const failedServices = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_SERVICES_FAILED,
  payload: data,
})

export const fulfilledServices = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_SERVICES_FULFILLED,
  payload: data,
})

export const getPaymentServicesInfo = () => (dispatch, getState) => {
  const api = `CUSTOMER_SERVICES`
  const template = URITemplate(urls.url[api])
  const state = getState()
  const token = _.get(state, `myPaymentVerification.verificationToken`, ``)
  const customerId =
    _.get(state, `myPaymentVerification.verificationData.customerId`, ``) ||
    _.get(state, `myPaymentVerification.verificationDetails.customerId`, ``)

  const url = template.expand({
    customerId,
  })

  dispatch(pendingServices())

  return axios
    .create({
      headers: {
        token,
        apiKey: _.get(urls, `apiKey.GUEST[${api}]`, ``),
      },
      params: {
        lang: _.get(state, `siteLanguage.language`, `en_CA`),
      },
    })
    .get(url)
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      dispatch(
        fulfilledServices({
          serviceInfo: response.data,
          statusCode,
        }),
      )

      return response
    })
    .catch((response) => {
      const statusCode = _.get(response, `response.status`, ``)
      const errorMessage = _.get(response, `response.data.Error`, ``)
      dispatch(
        failedServices({
          statusCode,
          errorMessage,
        }),
      )
    })
}
