import axios from "axios"
import moment from "moment"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { getDeviceChannel } from "utils-lib/getDeviceChannel"
import { getPadCheckingAcc, isNewCanadianAch } from "utils-lib/strings"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.ENROLL_AUTOPAYMENT_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.ENROLL_AUTOPAYMENT_FAILED,
  payload: data,
})

export const fulfilledAC = (data, isBulkRequest = false) => ({
  type: isBulkRequest
    ? ActionTypes.BULK_ENROLL_AUTOPAYMENT_FULFILLED
    : ActionTypes.ENROLL_AUTOPAYMENT_FULFILLED,
  payload: data,
})

export const bulkEnrollForAutoPayment =
  (customerIds) => (dispatch, getState) => {
    const state = getState()

    const { myPaymentCheckout, userManageAccount } = state

    const api = `BULK_AUTO_PAYMENT`

    dispatch(pendingAC())

    /* get first customerId from the list of customerIds to enroll and check its Canadian billing status */
    const accountDetails = _.find(
      _.get(userManageAccount, `userAccountDetails`, []),
      (account) => account.custAccountId === customerIds[0],
    )
    const isCanadianAccount =
      _.get(accountDetails, `isCanadianBilled`, ``) === `Y`

    const url = urls.url[api]
    const payMethodId = _.get(myPaymentCheckout, `selectedPayMethodId`, ``)

    const payload = isCanadianAccount
      ? {
          payMethodId,
          dayOfMonth: `20`,
          deviceChannel: getDeviceChannel(),
          customerIdList: customerIds,
          currency: `CAD`,
        }
      : {
          payMethodId,
          invStatus: `Y`,
          deviceChannel: getDeviceChannel(),
          customerIdList: customerIds,
          currency: `USD`,
        }

    return getTokenAction(dispatch, getState)
      .then((token) => {
        const state = getState()
        const config = {
          headers: {
            oktaToken: token.accessToken,
            referrerUrl: `${window.location.origin}${window.location.pathname}`,
          },
          params: {
            userId: _.get(state, `userAccount.userDetails.userId`, ``),
            lang: state.siteLanguage.language,
          },
        }

        const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }

        return axios
          .create(config)
          .post(url, payload)
          .then((response) => {
            dispatch(fulfilledAC(response, true))
            return response
          })
      })
      .catch((response) => {
        dispatch(
          failedAC({
            statusCode: _.get(response, `response.status`, ``),
            errorMessage: _.get(response, `response.data.Error`, ``),
          }),
        )
        return response
      })
  }

export const enrollForAutoPayment =
  (customerIdToEnroll = ``, paymentMethodId = ``) =>
  (dispatch, getState) => {
    const state = getState()
    const {
      customerAutoPayPaperless,
      myPayment,
      userManageAccount,
      userBilling,
      padDataSubmit,
      myPaymentCheckout,
      myPaymentConfirm,
      userPaymentMethods,
    } = state
    const api = `AUTO_PAYMENT`
    dispatch(pendingAC())

    const template = URITemplate(urls.url[api])
    const customerId =
      customerIdToEnroll === ``
        ? _.get(customerAutoPayPaperless, `autoPaymentAndPaperlessAccount`, ``)
        : customerIdToEnroll

    const accountDetails =
      _.find(
        _.get(userManageAccount, `userAccountDetails`, []),
        (account) => account.custAccountId === customerId,
      ) ||
      _.get(userBilling, `brokerAccountBalancesAndDetails[${customerId}]`, {})

    const isCanadianAccount =
      _.get(accountDetails, `isCanadianBilled`, ``) === `Y` ||
      _.get(accountDetails, `currency`, ``) === `CAD`

    const url = template.expand({ customerId })
    const payMethodId =
      paymentMethodId === ``
        ? _.get(
            customerAutoPayPaperless,
            `enrollForAutopaymentData[${customerId}].selectedPaymentMethodId`,
            ``,
          ) ||
          _.get(
            customerAutoPayPaperless,
            `manageAutopaymentDetailsByAccount[${customerId}].selectedPaymentMethodId`,
            ``,
          )
        : paymentMethodId

    const isSavedCACheckingAcc = getPadCheckingAcc(
      myPaymentCheckout,
      userPaymentMethods,
      payMethodId,
      `Autopay`,
    )
    const isPadFlow =
      isSavedCACheckingAcc?.isSavedCACheckingAcc ||
      isNewCanadianAch(myPaymentCheckout)

    const padId = isPadFlow ? _.get(padDataSubmit, `response.data.id`, ``) : ``
    const scheduledConfirmationNumber = _.get(
      myPaymentConfirm,
      `scheduledConfirmationNumber`,
      ``,
    )
    const customerID = _.get(
      customerAutoPayPaperless,
      `autoPaymentAndPaperlessAccount`,
      ``,
    )
    const amountBalanceByAccount = _.get(
      myPayment,
      `amountBalanceByAccount[${customerID}]`,
      {},
    )
    const manageAutopayDetailsByAcc = _.get(
      customerAutoPayPaperless,
      `manageAutopaymentDetailsByAccount[${customerID}].selectedPaymentMethodId`,
      ``,
    )
    const enrollForAutoPaymentDataByCustId = _.get(
      customerAutoPayPaperless,
      `enrollForAutopaymentData[${customerID}]`,
      {},
    )
    const manageAutopayPaperlessDetailsByAcc = _.get(
      customerAutoPayPaperless,
      `manageAutopaymentDetailsByAccount[${customerID}]`,
      {},
    )

    const enrollAutopayData =
      manageAutopayDetailsByAcc === ``
        ? enrollForAutoPaymentDataByCustId
        : manageAutopayPaperlessDetailsByAcc

    const paymentAmount =
      _.get(enrollAutopayData, `outstandingBalance`, 0) ||
      _.get(amountBalanceByAccount, `amount`, 0)
    const noScheduledPaymentLabel = `No Current Balance Payment Due`
    const payload = isCanadianAccount
      ? {
          payMethodId,
          dayOfMonth: `20`,
          padId,
          confirmationNumber: scheduledConfirmationNumber,
          oneTimePaymentAmount:
            isPadFlow && paymentAmount <= 0
              ? `0.00`
              : _.toString(paymentAmount),
          oneTimePaymentDate:
            isPadFlow && paymentAmount > 0
              ? moment().add(4, "days").format(`MM/DD/YYYY`)
              : noScheduledPaymentLabel,
          deviceChannel: getDeviceChannel(),
        }
      : {
          payMethodId,
          invStatus: `Y`,
          deviceChannel: getDeviceChannel(),
        }

    return getTokenAction(dispatch, getState)
      .then((token) => {
        const state = getState()
        const config = {
          headers: {
            oktaToken: token.accessToken,
            referrerUrl: `${window.location.origin}${window.location.pathname}`,
          },
          params: {
            userId: _.get(state, `userAccount.userDetails.userId`, ``),
            lang: state.siteLanguage.language,
          },
        }

        const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }

        return axios
          .create(config)
          .post(url, payload)
          .then((response) => {
            dispatch(fulfilledAC(response.data))
            return response
          })
      })
      .catch((response) => {
        dispatch(
          failedAC({
            statusCode: _.get(response, `response.status`, ``),
            errorMessage: _.get(response, `response.data.Error`, ``),
          }),
        )
        return response
      })
  }

export const updateEnrollmentDetailsByAccount = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_ENROLL_AUTOPAY_PAPERLESS_DETAILS_BY_ACCOUNT,
    payload: data,
  })
}

export const updateAutopayEnroll = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_PAYMENT_METHOD_ID_AUTOPAY,
    payload: data,
  })
}

export const clearAutopayEnrollDetails = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_AUTOPAY_ENROLL_DETAILS,
  })
}

export const clearAutopayEnrollAndUnenrollStatus = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_AUTOPAY_ENROLL_AND_UNENROLL_STATUS,
  })
}

export const clearAutopayUnenrollStatus = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_AUTOPAY_UNENROLL_STATUS,
  })
}

export const clearAutopayBulkEnrollmentStatusMap = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_AUTOPAY_BULK_ENROLL_STATUS_MAP,
  })
}
