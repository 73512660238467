import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import { urls } from "utils-lib/builds"
import { TYPE_ALL_HOLIDAYS, TYPE_UPCOMING_HOLIDAYS } from "constants-lib/urls"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { getTokenAction } from "../getToken"

// Action creators
export const pendingAC = () => ({
  type: ActionTypes.GET_HOLIDAY_SCHEDULE_PENDING,
})

export const failedAC = () => ({
  type: ActionTypes.GET_HOLIDAY_SCHEDULE_FAILED,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_HOLIDAY_SCHEDULE_FULFILLED,
  payload: data,
})

/**
 * This function gets the holiday schedule for a customer.
 * @param {Object} params - The parameters for the function.
 * @param {boolean} params.allHolidays - If true, get all holidays; otherwise, get upcoming holidays.
 * @param {string} params.locale - The locale for the holidays.
 * @param {boolean} params.isLoggedIn - If true, the user is logged in; otherwise, the user is not logged in.
 * @param {Object} params.customerAddress - The address of the customer.
 * @param {string} params.customerAddress.postalCode - The postal code of the customer's address.
 * @param {string} params.customerAddress.streetName - The street name of the customer's address.
 * @param {string} params.customerAddress.streetNumber - The street number of the customer's address.
 * @param {string} params.customerAddress.city - The city of the customer's address.
 * @param {string} params.customerAddress.state - The state of the customer's address.
 * @param {string} params.customerAddress.country - The country of the customer's address.
 * @param {string} params.customerId - The ID of the customer.
 * @param {Object} params.userDetails - The details of the user.
 * @param {string} params.userDetails.userId - The ID of the user.
 * @returns {function} A function that dispatches actions and returns a promise.
 */
export const getHolidaySchedule =
  ({
    allHolidays,
    locale,
    isLoggedIn,
    customerAddress,
    customerId,
    userDetails,
  }) =>
  (dispatch, getState) => {
    const {
      postalCode: zipcode,
      streetName,
      streetNumber,
      city,
      state,
      country,
    } = customerAddress || {}
    const userId = userDetails?.userId

    const guestToken = get(
      getState(),
      `guestContainerRepair.eligibilityData.token`,
      ``,
    )
    const type =
      allHolidays === true ? TYPE_ALL_HOLIDAYS : TYPE_UPCOMING_HOLIDAYS

    let holidaySearchUrl = ""
    let apiKey = ""

    if (isLoggedIn && !isEmpty(customerId) && customerId !== `undefined`) {
      apiKey = get(urls, `apiKey.USER.HOLIDAYS_USER_BY_CUSTOMER_ID`, ``)
      const template = URITemplate(urls.url.HOLIDAYS_USER_BY_CUSTOMER_ID)

      holidaySearchUrl = template.expand({ userId, customerId, locale, type })
    } else if (isLoggedIn && !customerId) {
      apiKey = get(urls, `apiKey.USER.HOLIDAYS_USER_BY_ADDRESS`, ``)
      const template = URITemplate(urls.url.HOLIDAYS_USER_BY_ADDRESS)

      holidaySearchUrl = template.expand({
        userId,
        locale,
        street: `${streetNumber} ${streetName}`,
        city,
        state,
        zipcode,
        country,
        type,
      })
    } else if (
      !isLoggedIn &&
      !isEmpty(customerId) &&
      customerId !== `undefined`
    ) {
      apiKey = get(urls, `apiKey.GUEST.HOLIDAYS_GUEST_BY_CUSTOMER_ID`, ``)
      const template = URITemplate(urls.url.HOLIDAYS_GUEST_BY_CUSTOMER_ID)

      holidaySearchUrl = template.expand({ customerId, locale, type })
    } else if (!isLoggedIn && !customerId) {
      apiKey = get(urls, `apiKey.GUEST.HOLIDAYS_GUEST_BY_ADDRESS`, ``)
      const template = URITemplate(urls.url.HOLIDAYS_GUEST_BY_ADDRESS)

      holidaySearchUrl = template.expand({
        locale,
        street: `${streetNumber} ${streetName}`,
        zipcode,
        city,
        state,
        country,
        type,
      })
    }

    // const mockURL = `/mock/upcoming-holiday-response-en_CA.json`

    dispatch(pendingAC())

    const config = {
      headers: {
        apiKey,
      },
    }

    if (isLoggedIn) {
      return getTokenAction(dispatch, getState).then((token) => {
        config.headers.oktaToken = token.accessToken

        axios
          .create(config)
          .get(holidaySearchUrl)
          .then((response) => {
            dispatch(fulfilledAC(response.data))
            return response
          })
          .catch(() => {
            dispatch(failedAC())
          })
        return null
      })
    }
    config.headers.token = guestToken
    axios
      .create(config)
      .get(holidaySearchUrl)
      .then((response) => {
        dispatch(fulfilledAC(response.data))
        return response
      })
      .catch(() => {
        dispatch(failedAC())
      })
    return null
  }
