import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getPaddedCustomerId } from "utils-lib/strings"

const initialState = {
  attributesDetailsByAccount: {},
  autoPaymentAndPaperlessAccount: ``,
  autopayPaperlessAccountAddress: ``,
  fetchAccountAttributesStatus: ``,
  enrollPaperlessStatus: ``,
  unenrollPaperlessStatus: ``,
  fetchAttributesForSingleAccountStatus: ``,
  fetchAutopaymentStatus: ``,
  unEnrollAutopaymentStatus: ``,
  autopaymentDetailsForAllAccounts: {},
  enrollForAutopaymentData: {},
  enrollAutopaymentStatus: ``,
  manageAutopaymentDetailsByAccount: {},
  outstandingPaymentStatus: ``,
  updateAutopaymentStatus: ``,
  bulkAutopayEnrollmentStatusMap: {},
  bulkPaperlessEnrollmentStatusMap: {},
  getPaperlessPreferencesStatus: ``,
  paperlessPreferences: {},
  setPaperlessPreferencesStatus: ``,
  setPaperlessPreferencesData: {},
  accountAttributesApiData: {},
}

export default (state = initialState, action = {}) => {
  let attributesByAccount = {}
  switch (action.type) {
    case ActionTypes.FETCH_AUTOPAY_PAPERLESS_ATTRIBUTES_PENDING:
      return {
        ...state,
        fetchAccountAttributesStatus: `pending`,
      }
    case ActionTypes.FETCH_AUTOPAY_PAPERLESS_ATTRIBUTES_FAILED:
      return {
        ...state,
        fetchAccountAttributesStatus: `failed`,
      }

    case ActionTypes.FETCH_AUTOPAY_PAPERLESS_ATTRIBUTES_FULFILLED:
      return {
        ...state,
        fetchAccountAttributesStatus: `fulfilled`,
        attributesDetailsByAccount: _.get(
          action.payload,
          `attributesDetailsByAccount`,
          {},
        ),
        accountAttributesApiData: _.get(action.payload, `actualResponse`, {}),
      }

    case ActionTypes.DELETE_PAYMENT_METHOD_FULFILLED: {
      const isAutoPayLinked = action.payload
      return isAutoPayLinked
        ? {
            ...state,
            fetchAccountAttributesStatus: ``,
          }
        : state
    }

    case ActionTypes.FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT_PENDING:
      return {
        ...state,
        fetchAttributesForSingleAccountStatus: `pending`,
        ...action.payload,
      }

    case ActionTypes.FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT_FAILED:
      return {
        ...state,
        fetchAttributesForSingleAccountStatus: `failed`,
      }

    case ActionTypes.FETCH_ATTRIBUTES_FOR_SINGLE_ACCOUNT_FULFILLED:
      return {
        ...state,
        fetchAttributesForSingleAccountStatus: `fulfilled`,
        attributesDetailsByAccount: {
          ...state.attributesDetailsByAccount,
          ...action.payload,
        },
      }

    case ActionTypes.SET_AUTOPAY_PAPERLESS_ACCOUNT_DETAILS:
      return {
        ...state,
        autoPaymentAndPaperlessAccount: action.payload,
      }

    case ActionTypes.SET_AUTOPAY_PAPERLESS_ACCOUNT_ADDRESS:
      return {
        ...state,
        autopayPaperlessAccountAddress: action.payload,
      }

    case ActionTypes.ENROLL_PAPERLESS_BILLING_PENDING:
      return {
        ...state,
        enrollPaperlessStatus: `pending`,
      }

    case ActionTypes.ENROLL_PAPERLESS_BILLING_FAILED:
      return {
        ...state,
        enrollPaperlessStatus: `failed`,
      }

    /* Payload contains the customer ID that enrolled in paperless. */
    case ActionTypes.ENROLL_PAPERLESS_BILLING_FULFILLED: {
      const { attributesDetailsByAccount } = state
      _.set(
        attributesDetailsByAccount,
        `[${action.payload}].paperlessStatus`,
        true,
      )
      return {
        ...state,
        enrollPaperlessStatus: `fulfilled`,
        attributesDetailsByAccount,
      }
    }

    case ActionTypes.BULK_ENROLL_PAPERLESS_BILLING_FULFILLED:
      const updatedBulkPaperlessEnrollmentStatusMap = {
        ...state.bulkPaperlessEnrollmentStatusMap,
      }
      const updatedPaperlessAttributes = { ...state.attributesDetailsByAccount }

      _.forEach(action.payload, ({ customerId, statusCode }) => {
        const paddedCustomerId = getPaddedCustomerId(customerId)
        const isSuccess = statusCode.toString() === `200`
        if (isSuccess && !_.has(updatedPaperlessAttributes, paddedCustomerId)) {
          updatedPaperlessAttributes[paddedCustomerId].paperlessStatus = true
        }
        updatedBulkPaperlessEnrollmentStatusMap[paddedCustomerId] = isSuccess
      })

      return {
        ...state,
        enrollPaperlessStatus: `fulfilled`,
        attributesDetailsByAccount: updatedPaperlessAttributes,
        bulkPaperlessEnrollmentStatusMap:
          updatedBulkPaperlessEnrollmentStatusMap,
      }

    case ActionTypes.CLEAR_PAPERLESS_BULK_ENROLL_STATUS_MAP:
      return {
        ...state,
        bulkPaperlessEnrollmentStatusMap: {},
      }

    case ActionTypes.CLEAR_ENROLL_PAPERLESS_STATUS: {
      return {
        ...state,
        enrollPaperlessStatus: ``,
      }
    }

    /* Payload contains the customer ID that enrolled in paperless. */
    case ActionTypes.UN_ENROLL_PAPERLESS_BILLING_FULFILLED: {
      const { attributesDetailsByAccount } = state
      _.set(
        attributesDetailsByAccount,
        `[${action.payload}].paperlessStatus`,
        false,
      )
      return {
        ...state,
        unenrollPaperlessStatus: `fulfilled`,
        attributesDetailsByAccount,
      }
    }

    case ActionTypes.CLEAR_UNENROLL_PAPERLESS_STATUS: {
      return {
        ...state,
        unenrollPaperlessStatus: ``,
      }
    }

    case ActionTypes.UN_ENROLL_PAPERLESS_BILLING_PENDING:
      return {
        ...state,
        unenrollPaperlessStatus: `pending`,
      }

    case ActionTypes.UN_ENROLL_PAPERLESS_BILLING_FAILED:
      return {
        ...state,
        unenrollPaperlessStatus: `failed`,
      }
    case ActionTypes.GET_PAPERLESS_PREFERENCES_PENDING:
      return {
        ...state,
        getPaperlessPreferencesStatus: `pending`,
      }
    case ActionTypes.GET_PAPERLESS_PREFERENCES_FAILED:
      return {
        ...state,
        getPaperlessPreferencesStatus: `failed`,
      }
    case ActionTypes.GET_PAPERLESS_PREFERENCES_FULFILLED:
      const attributesDetailsByAccount = _.assign(
        {},
        state.attributesDetailsByAccount,
      )
      _.set(
        attributesDetailsByAccount,
        `${state.autoPaymentAndPaperlessAccount}.paperlessStatus`,
        _.get(action.payload, `paperless`, `N`) === `Y`,
      )
      return {
        ...state,
        getPaperlessPreferencesStatus: `fulfilled`,
        paperlessPreferences: action.payload,
        attributesDetailsByAccount, // update detailsByAccount
      }
    case ActionTypes.CLEAR_GET_PAPERLESS_PREFERENCES_STATUS:
      return {
        ...state,
        getPaperlessPreferencesStatus: ``,
        paperlessPreferences: {},
      }
    case ActionTypes.SET_PAPERLESS_PREFERENCES_PENDING:
      return {
        ...state,
        setPaperlessPreferencesStatus: `pending`,
      }
    case ActionTypes.SET_PAPERLESS_PREFERENCES_FAILED:
      return {
        ...state,
        setPaperlessPreferencesStatus: `failed`,
      }
    case ActionTypes.SET_PAPERLESS_PREFERENCES_FULFILLED:
      return {
        ...state,
        setPaperlessPreferencesStatus: `fulfilled`,
        paperlessPreferences: action.payload,
      }
    case ActionTypes.CLEAR_SET_PAPERLESS_PREFERENCES_STATUS:
      return {
        ...state,
        setPaperlessPreferencesStatus: ``,
      }
    case ActionTypes.FETCH_AUTOPAYMENT_PENDING:
      return {
        ...state,
        fetchAutopaymentStatus: `pending`,
      }

    case ActionTypes.FETCH_AUTOPAYMENT_FAILED:
      return {
        ...state,
        fetchAutopaymentStatus: `failed`,
      }

    case ActionTypes.FETCH_AUTOPAYMENT_FULFILLED: {
      return {
        ...state,
        fetchAutopaymentStatus: `fulfilled`,
        autopaymentDetailsForAllAccounts: {
          ...state.autopaymentDetailsForAllAccounts,
          ...action.payload,
        },
        // autopaymentDetailsForAllAccounts,
      }
    }

    case ActionTypes.UPDATE_ENROLL_AUTOPAY_PAPERLESS_DETAILS_BY_ACCOUNT:
      const selectedCustomerId = state.autoPaymentAndPaperlessAccount
      return {
        ...state,
        enrollForAutopaymentData: {
          ..._.get(
            state.enrollForAutopaymentData,
            `[${selectedCustomerId}]`,
            {},
          ),
          ...action.payload.enrollForAutopaymentData,
        },
      }

    case ActionTypes.MANAGE_AUTOPAY_DETAILS_BY_ACCOUNT:
      const selctedCustomerIdForManage = state.autoPaymentAndPaperlessAccount
      return {
        ...state,
        manageAutopaymentDetailsByAccount: {
          ...state.manageAutopaymentDetailsByAccount[
            `${selctedCustomerIdForManage}`
          ],
          ...action.payload.manageAutopaymentDetails,
        },
      }

    case ActionTypes.UPDATE_PAYMENT_METHOD_ID_AUTOPAY:
      const customerId = state.autoPaymentAndPaperlessAccount
      const customerIdData = {
        ...state.enrollForAutopaymentData[`${customerId}`],
        ...action.payload,
      }
      const localEnrollForAutopaymentData = {
        ..._.get(state, `enrollForAutopaymentData`, {}),
      }

      localEnrollForAutopaymentData[`${customerId}`] = customerIdData
      return {
        ...state,
        enrollForAutopaymentData: localEnrollForAutopaymentData,
      }

    case ActionTypes.UPDATE_MANAGE_AUTOPAY_DETAILS_OF_ACCOUNT:
      const manageCustomerId = state.autoPaymentAndPaperlessAccount
      const manageCustomerIdData = {
        ...state.manageAutopaymentDetailsByAccount[`${manageCustomerId}`],
        ...action.payload,
      }
      const localManageAutopayDetails = {
        ..._.get(state, `manageAutopaymentDetailsByAccount`, {}),
      }

      localManageAutopayDetails[`${manageCustomerId}`] = manageCustomerIdData
      return {
        ...state,
        manageAutopaymentDetailsByAccount: localManageAutopayDetails,
      }

    case ActionTypes.UNENROLL_AUTOPAYMENT_PENDING:
      return {
        ...state,
        unEnrollAutopaymentStatus: `pending`,
      }

    case ActionTypes.UNENROLL_AUTOPAYMENT_FAILED:
      return {
        ...state,
        unEnrollAutopaymentStatus: `failed`,
      }

    case ActionTypes.UNENROLL_AUTOPAYMENT_FULFILLED:
      attributesByAccount = state.attributesDetailsByAccount
      attributesByAccount[
        state.autoPaymentAndPaperlessAccount
      ].autopayStatus = false
      return {
        ...state,
        unEnrollAutopaymentStatus: `fulfilled`,
      }

    case ActionTypes.PAID_OUTSTANDING_BALANCE_WHILE_ENROLLING:
      const { autoPaymentAndPaperlessAccount, enrollForAutopaymentData } = state
      let enrollForAutopaymentDataForSelectedAccount = {}
      enrollForAutopaymentDataForSelectedAccount = {
        ...state.enrollForAutopaymentData[`${autoPaymentAndPaperlessAccount}`],
        ...action.payload,
      }
      _.set(
        enrollForAutopaymentData,
        `${autoPaymentAndPaperlessAccount}`,
        enrollForAutopaymentDataForSelectedAccount,
      )
      return {
        ...state,
        enrollForAutopaymentData,
        outstandingPaymentStatus: `fulfilled`,
      }

    case ActionTypes.ENROLL_AUTOPAYMENT_PENDING:
      return {
        ...state,
        enrollAutopaymentStatus: `pending`,
      }

    case ActionTypes.ENROLL_AUTOPAYMENT_FAILED:
      return {
        ...state,
        enrollAutopaymentStatus: `failed`,
      }

    case ActionTypes.ENROLL_AUTOPAYMENT_FULFILLED:
      const attributes = state.attributesDetailsByAccount

      if (state.autoPaymentAndPaperlessAccount) {
        attributes[state.autoPaymentAndPaperlessAccount].autopayStatus = true
      }

      return {
        ...state,
        enrollAutopaymentStatus: `fulfilled`,
        attributesDetailsByAccount: attributes,
      }

    case ActionTypes.BULK_ENROLL_AUTOPAYMENT_FULFILLED:
      const updatedBulkAutopayEnrollmentStatusMap = {
        ...state.bulkAutopayEnrollmentStatusMap,
      }
      const updatedAutopayAttributes = { ...state.attributesDetailsByAccount }

      _.forEach(action.payload.data.status, ({ customerId, statusCode }) => {
        const isSuccess = statusCode.toString() === `200`
        if (isSuccess && !_.has(updatedAutopayAttributes, customerId)) {
          updatedAutopayAttributes[customerId].autopayStatus = true
        }
        updatedBulkAutopayEnrollmentStatusMap[customerId] = isSuccess
      })

      return {
        ...state,
        enrollAutopaymentStatus: `fulfilled`,
        attributesDetailsByAccount: updatedAutopayAttributes,
        bulkAutopayEnrollmentStatusMap: updatedBulkAutopayEnrollmentStatusMap,
      }

    case ActionTypes.CLEAR_AUTOPAY_BULK_ENROLL_STATUS_MAP:
      return {
        ...state,
        bulkAutopayEnrollmentStatusMap: {},
      }

    case ActionTypes.UPDATE_AUTOPAYMENT_PENDING:
      return {
        ...state,
        updateAutopaymentStatus: `pending`,
      }

    case ActionTypes.UPDATE_AUTOPAYMENT_FAILED:
      return {
        ...state,
        updateAutopaymentStatus: `failed`,
      }
    case ActionTypes.UPDATE_AUTOPAYMENT_FULFILLED:
      return {
        ...state,
        updateAutopaymentStatus: `fulfilled`,
      }

    case ActionTypes.CLEAR_AUTOPAY_ENROLL_DETAILS: {
      return {
        ...state,
        enrollForAutopaymentData: {},
        manageAutopaymentDetailsByAccount: {},
      }
    }

    case ActionTypes.CLEAR_AUTOPAY_ENROLL_AND_UNENROLL_STATUS: {
      return {
        ...state,
        enrollAutopaymentStatus: ``,
        updateAutopaymentStatus: ``,
      }
    }

    case ActionTypes.CLEAR_AUTOPAY_UNENROLL_STATUS: {
      return {
        ...state,
        unEnrollAutopaymentStatus: ``,
      }
    }

    case ActionTypes.UPDATE_AUTOPAY_ENROLLMENT_BY_ACCOUNT:
      const { accountId } = action.payload
      const updatedAttributes = { ...state.attributesDetailsByAccount }
      updatedAttributes[accountId] = {
        ...state.attributesDetailsByAccount[accountId],
        autopayStatus: action.payload.autoPayEnrollmentState,
      }

      return {
        ...state,
        attributesDetailsByAccount: updatedAttributes,
      }

    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
    case ActionTypes.RESET_USER_ACCOUNT:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
