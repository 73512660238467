import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  setPadDataSubmitStatus: ``,
  setPadDataSubmit: {},
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.SET_PAD_DATA_SUBMIT_PENDING:
      return {
        ...state,
        setPadDataSubmitStatus: `pending`,
        setPadDataSubmit: {},
      }

    case ActionTypes.SET_PAD_DATA_SUBMIT_FAILED:
      return {
        ...state,
        setPadDataSubmitStatus: `failed`,
        setPadDataSubmit: {},
      }

    case ActionTypes.SET_PAD_DATA_SUBMIT_FULFILLED:
      return {
        ...state,
        setPadDataSubmitStatus: `fulfilled`,
        response: { ...action.payload.data.data },
      }

    default:
      return state
  }
}
