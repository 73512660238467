/**
 * The default HTTP status code for errors
 * @constant {number}
 */
export const DEFAULT_ERROR_STATUS_CODE = 500

/**
 * It generates a random number and returns it
 * @returns {number} A random number
 */
export const generateUniqueID = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
  }
  return s4()
}
