import PropTypes from "prop-types"
import "./H3.scss"

export const H3 = ({ children, className = "" }) => (
  <div
    className={`H3 maax-font-weight-black-condensed ${className}`}
    data-testid="H3"
  >
    {children}
  </div>
)

H3.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

H3.defaultProps = {
  children: null,
  className: "",
}
