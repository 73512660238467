import { UPDATE_CURRENT_NEIGHBORHOOD } from "constants-lib/actionTypes"

// Must be a pure function that requires the state
// and the action being dispatched.
const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    // case ActionTypes.INIT_STATE:
    //   return initialState

    // case ActionTypes.LOGOUT_FULFILLED:
    //   return initialState

    case UPDATE_CURRENT_NEIGHBORHOOD:
      return { ...action.payload }

    default:
      return state
  }
}
