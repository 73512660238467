import get from "lodash/get"
import axios from "axios"
import URI from "urijs"
import { urls } from "utils-lib/builds"
import URITemplate from "urijs/src/URITemplate"
import { GET_CUSTOMER_ACCOUNT_PENDING } from "constants-lib/actionTypes"
import { failedAC, fulfilledAC } from "./actionsCreators"

// Action creators
export const pendingAC = () => ({
  type: GET_CUSTOMER_ACCOUNT_PENDING,
})

/**
 * Action for getting customer account information by customer ID.
 * @function getCustomerAccountByCustomerId
 * @param {Object} data - The data to include in the API call.
 * @param {string} lang - The language to use for the API call.
 * @returns {function} dispatches actions to update the store with the results of the API call.
 */
export const getCustomerAccountByCustomerId = (data, lang) => (dispatch) => {
  const api = `CUSTOMER_SEARCH`

  dispatch(pendingAC())

  const template = URITemplate(urls.url[api])
  const url = template.expand({
    ezpayId: data,
    lang,
  })

  return axios
    .create({
      headers: {
        apiKey: get(urls, `apiKey.GUEST[${api}]`, ``),
      },
    })
    .get(url)
    .then((response) => {
      const token = get(response, `headers.token`, ``)
      dispatch(fulfilledAC(response.data || {}, true, token))
      return response.data || {}
    })
    .catch((e) => {
      dispatch(failedAC(get(e, `response.status`, 403)))
    })
}
/**
 * Action for getting customer information by address.
 * @function getCustomerByAddress
 * @param {Object} data - The data to include in the API call.
 * @param {function} [onComplete=() => {}] - Optional callback function to be called after the API call is completed.
 * @param {boolean} [isSupportForm] - Whether the API call is for a support form.
 * @param {boolean} [isResumeService] - Whether the API call is for resuming service.
 * @returns {function} dispatches actions to update the store with the results of the API call.
 */
export const getCustomerByAddress =
  (data, onComplete = () => {}, isSupportForm, isResumeService) =>
  (dispatch, getState) => {
    const api = `CUSTOMER_SEARCH_GUEST`

    dispatch(pendingAC())

    const state = getState()
    const customerAddress =
      data.customerAddress !== undefined
        ? data.customerAddress
        : state.customerSelections.customerAddress

    const baseURL = urls.url[api]
    const uri = new URI(baseURL)
    let url = uri.search({
      street: `${customerAddress.streetNumber} ${customerAddress.streetName}`,
      city: customerAddress.city,
      state: customerAddress.state,
      country: customerAddress.country,
      postalCode: customerAddress.postalCode,
      lang: state.siteLanguage.language,
    })

    if (isSupportForm) {
      url = `${url}&mode=${isResumeService ? `RESUME_FORM` : `SUPPORT_FORMS`}`
    }

    return axios
      .create({
        headers: {
          apiKey: get(urls, `apiKey.GUEST[${api}]`, ``),
        },
      })
      .get(url)
      .then((response) => {
        dispatch(fulfilledAC(response.data))
        onComplete(response.data)
        return response
      })
      .catch((e) => {
        dispatch(failedAC(get(e, `response.status`, 403)))
        onComplete()
      })
  }
