import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  SUBMIT_EXTRA_PICKUP_PENDING,
  SUBMIT_EXTRA_PICKUP_FAILED,
  SUBMIT_EXTRA_PICKUP_FULFILLED,
} from "constants-lib/actionTypes"
import { getDeviceChannel } from "utils-lib/getDeviceChannel"
import { getTokenAction, clearDataCache } from "actions"
import { urls } from "utils-lib/builds"
import { CACHE_KEYS } from "constants-lib/dataCache"

export const pendingAC = () => ({
  type: SUBMIT_EXTRA_PICKUP_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: SUBMIT_EXTRA_PICKUP_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: SUBMIT_EXTRA_PICKUP_FULFILLED,
  payload: data,
})

/* {
  "orderId" : 13, _.get(state.myServices, `extraPickupPricingData.data.orderId`)
  "serviceDate": "2020-09-02", _.get(state.myServices, `extraPickupDatesData.data.date`)
  "device": "D",
  "reqType": "EXTRA_PICKUP",
  "extraPickupAmount": "100.00" _.get(state.myServices, `extraPickupPricingData.data.oneTimePickUpPrice`)
} */

export const submitExtraPickup =
  ({ customerId, serviceId, additionalNotes, contactInfo, customData = {} }) =>
  (dispatch, getState) => {
    const api = `EXTRA_PICKUP_CREATE`

    const template = URITemplate(urls.url[api])

    const state = getState()

    const url = template.expand({
      customerId,
      lang: _.get(state, `siteLanguage.language`, ``),
      userId: _.get(state, `userAccount.userDetails.userId`, ``),
      serviceId,
    })

    let data = {
      orderId: _.get(state, `myServices.extraPickupPricingData.data.orderId`),
      serviceDate: _.get(state, `myServices.extraPickupDatesData.data.date`),
      device: getDeviceChannel(),
      reqType: `EXTRA_PICKUP`,
      extraPickupAmount: _.get(
        state,
        `myServices.extraPickupPricingData.data.oneTimePickUpPrice`,
      ),
      additionalNotes,
      contactInfo,
    }

    if (!_.isEmpty(customData)) {
      data = {
        ...data,
        ...customData,
      }
    }
    const config = {
      method: `POST`,
      headers: {},
      data,
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return submitExtraPickupHelper(url, config, dispatch, additionalNotes)
    })
  }

export const submitExtraPickupHelper = (
  url,
  config,
  dispatch,
  additionalNotes,
) => {
  dispatch(pendingAC())
  dispatch(clearDataCache(CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA))

  return axios(url, config)
    .then((response) => {
      dispatch(fulfilledAC({ ...response.data, additionalNotes }))

      return response.data
    })
    .catch((error) => {
      dispatch(failedAC(error.response.status))
      return error.response.status
    })
}
