/**
 * Strips specific properties from a contact object for update operations, as per API requirements.
 * @param {Object} contact - The contact object to be processed.
 * @param {string} contact.fullName - The full name of the contact.
 * @param {Array} contact.preferences - Array of preference objects for the contact.
 * @param {string} contact.id - The ID of the contact, which should not be included in the PUT request.
 * @returns {Object} The processed contact object with specified properties removed.
 */
// eslint-disable-next-line import/prefer-default-export
export const stripPropertiesForUpdate = (contact) => {
  const { fullName, preferences, id, ...rest } = contact
  const strippedPrefs = preferences.map(
    ({ hideForEmail, hideForSms, ...prefsRest }) => ({
      ...prefsRest,
    }),
  )
  return {
    ...rest,
    preferences: strippedPrefs,
  }
}
