import * as actions from "actions"
import * as actionsLib from "actions-lib"
import _ from "lodash"
import { setRenderDashboard, getAEMJSON } from "actions-lib"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import HeaderApp from "./HeaderApp"

export const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      // getESBTokens: actions.getESBTokens,
      getUserDetails: actions.getUserDetails,
      getLinkedAccounts: actions.getLinkedAccounts,
      getToken: actions.getToken,
      setToken: actions.setToken,
      revokeToken: actionsLib.revokeToken,
      refreshToken: actionsLib.refreshToken,
      resetUserAccount: actions.resetUserAccount,
      setViewport: actions.setViewport,
      switchLanguage: actions.switchLanguage,
      setMyServicesMain: actions.setMyServicesMain,
      setRenderDashboard,
      setSessionIntervalId: actions.setSessionIntervalId,
      setUserState: actions.setUserState,
      setUserDetails: actions.setUserDetails,
      setLastActivityTime: actions.setLastActivityTime,
      setBillingDetailsAccount: actions.setBillingDetailsAccount,
      getAEMJSON,
    },
    dispatch,
  ),
})

export const mapStateToProps = (state) => ({
  reduxState: {
    authentication: state.authentication,
    customerSelections: state.customerSelections,
    siteLanguage: state.siteLanguage,
    userAccount: state.userAccount,
    userManageAccount: state.userManageAccount,
    useSharedLib: _.get(state, `aemData.useSharedLib`, false),
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderApp),
)
