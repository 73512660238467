import join from "lodash/join"
import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import {
  GET_FACILITY_ALERTS_PENDING,
  GET_FACILITY_ALERTS_FULFILLED,
  GET_FACILITY_ALERTS_FAILED,
} from "constants-lib/actionTypes"

/**
 * Fetches alerts for specified facility IDs and dispatches actions based on the request's outcome.
 * @param {Array<string>} facilityIds - An array of facility IDs for which alerts are to be fetched.
 */
export const getFacilityAlerts = (facilityIds) => (dispatch, getState) => {
  dispatch({
    type: GET_FACILITY_ALERTS_PENDING,
  })

  const api = `FACILITY_ALERTS`
  const apiKey = get(urls, `apiKey.GUEST.ALERTS`, ``)

  const template = URITemplate(urls.url[api])

  const url = template.expand({
    // facilityId: `${_.join(facilityIds, `,`)},S09875`,
    facilityId: join(facilityIds, `,`),
    lang: get(getState(), `siteLanguage.language`, ``),
  })

  const config = {
    headers: {
      apiKey,
    },
  }

  return axios
    .create(config)
    .get(url)
    .then((response) => {
      const alerts = get(response, `data.data.alerts`, [])

      dispatch({
        type: GET_FACILITY_ALERTS_FULFILLED,
        payload: alerts,
      })

      return response
    })
    .catch(() => {
      dispatch({
        type: GET_FACILITY_ALERTS_FAILED,
      })
    })
}
