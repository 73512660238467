import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

/**
 * Returns the device channel based on the user agent.
 * @returns {string} The device channel ('T' for Tablet, 'M' for Mobile, 'D' for Desktop).
 */
export const getDeviceChannel = () => {
  if (window.navigator.userAgent.match(/Tablet|iPad/i)) {
    return `T`
  }
  if (
    window.navigator.userAgent.match(
      /Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i,
    )
  ) {
    return `M`
  }
  return `D`
}
