/* eslint-disable max-len */
import { getWindow } from "utils-lib/getWindow"
import getBackendAPIs from "../getBackendAPIs"
import getMicrositeData from "../getMicrositeData"

const window = getWindow()

const origin = {
  EBIZ: `https://rest-api.wm.com`, // Bhargavi, Ramakanth: Replaces api.wmcanada.com.
  ESB: `https://webapi.wm.com/v1`,
  SUPPORT: `https://support.wm.com`,
  // TODO: Update to actual URL once we receive it
  SERVLET: `${window.location.origin}/bin`,
  CDN: "https://cdn.wm.com",
  MULESOFT: `https://cloudwebapi.wm.com`,
  ESB_LOCAL: `https://d3qtt900u9ulb5.cloudfront.net/v1`,
  ROOT_AEM_SERVLET_PATH: `${window.location.origin}/content/wm/servlets`,
  GENESYS: `https://wmchat.wm.com/webapi/api/v2`,
}

// TODO Get correct API keys from backend.
const apiKeys = {
  /* Unique API keys for various groups of guest flow backend services. */
  GUEST: {
    LIVE_CHAT: `2DC6C5B8146A1C281F96`,
    PAYMENT: `E55043B23D67AE80FD93`,
    ROLLOFF: `C34B2B520265840EF06A`,
    BALANCE: `65D55CF6F9D38F50E02C`,
    CUSTOMER_SERVICES: `498C9CEE262DA37A80AA`,
    USER_REQUEST_SERVICE: `E0008A94C99903B68AB5`,
    ALERTS: `54E890DC1B56C8C9900D`,
    ADS_ACCOUNTS: `65D55CF6F9D38F50E02C`,
    AWS_TRANSLATE: `us-east-1:30d30e7c-e59d-4826-8871-2e252cfa9a09`,
    ALGOLIA_API_KEY: `dd1b057a64346fdac6fe1648ec4a665b`,
    APPLE_PAY: `10018AB474C4D9767EF5`,
    APPLE_PAY_SCHEDULED: `6AF695551CAFEBB0C373`,
    APPLE_PAY_PROCESS: `507B8B3549B99242BB20`,
    ALGOLIA_APP_ID: `S5EXGS68FF`,
    BULK_PICKUP_DATES: `65D55CF6F9D38F50E02C`,
    SUBMIT_BULK_PICKUP: `E0008A94C99903B68AB5`,
    HOLIDAYS_GUEST_BY_CUSTOMER_ID: `65D55CF6F9D38F50E02C`,
    HOLIDAYS_GUEST_BY_ADDRESS: `C2068E03CB6B73D4FEBA`,
    CONTACT_INFO_GUEST_BY_CUSTOMER_ID: `65D55CF6F9D38F50E02C`,
    CONTACT_INFO_GUEST_BY_ADDRESS: `C2068E03CB6B73D4FEBA`,
    ENERGY_SURCHARGE_CALCULATOR: `65D55CF6F9D38F50E02C`,
    GUEST_BULK_PICKUP_GUIDELINES: `65D55CF6F9D38F50E02C`,
    GUEST_BULK_PICKUP_DATES: `65D55CF6F9D38F50E02C`,
    SUBMIT_GUEST_BULK_PICKUP: `65D55CF6F9D38F50E02C`,
    GUEST_BULK_PICKUP_PRICING: `65D55CF6F9D38F50E02C`,
    GET_CITY_BILLED: `65D55CF6F9D38F50E02C`,
    TEXT_FOR_CITY_BILLED_BULK: `65D55CF6F9D38F50E02C`,
    USER_DETAILS: `8FF1558D095DBFB1068F`,
    CREATE_USER: `FC1FF1306771BE1049FF`,
    ENROLL_AUTOPAY_PAPERLESS_GUEST: `65D55CF6F9D38F50E02C`,
    TEXT_FOR_BILL_PAY: `E55043B23D67AE80FD93`,
    TEXT_FOR_VIEW_ETA: `65D55CF6F9D38F50E02C`,
    REPORT_MISSED_PICKUP_GUEST: `65D55CF6F9D38F50E02C`,
    GUEST_CONTAINER_REPAIR: `65D55CF6F9D38F50E02C`,
    PROCESSING_FEE: `E55043B23D67AE80FD93`,
  },
  /* Unique API keys for various groups of logged in user flow backend services. */
  USER: {
    PAYMENT: `6ACAFBEEC54039F21716`,
    ROLLOFF: `5B1B5D23674B426465F0`,
    AUTHENTICATION: `6277FF29BB19666078AC`,
    BALANCE_INVOICE: `615F2EFC82FF8BB2F864`,
    CUSTOMER_SERVICES: `1611D2AB8045EFB4D148`,
    USER_ACCOUNTS: `AABFF96D4542575160FC`,
    USER_REQUEST_SERVICE: `F1ADA754F65E546BFC97`,
    CHAT_LOGGED_IN: `2DC6C5B8146A1C281F96`,
    CHAT_LOGGED_IN_MOBILE: `306B8BA80546A3CE5DEC`,
    ALERTS: `C861FBA520CDD18ADC8D`,
    INCIDENTS_BY_USER_ID: `6277FF29BB19666078AC`,
    HOLIDAYS_USER_BY_CUSTOMER_ID: `6277FF29BB19666078AC`,
    HOLIDAYS_USER_BY_ADDRESS: `6277FF29BB19666078AC`,
    CONTACT_INFO_USER_BY_CUSTOMER_ID: `6277FF29BB19666078AC`,
    CONTACT_INFO_USER_BY_ADDRESS: `6277FF29BB19666078AC`,
    REPAIR: `6277FF29BB19666078AC`,
    APPLE_PAY: `B8F7CE5A093141B1FFA2`,
    APPLE_PAY_SCHEDULED: `4E6D3BBDE553BE2807ED`,
    APPLE_PAY_AUTOPAY: `6CBA95B0A31A94C3C91C`,
    APPLE_PAY_PROCESS: `0A4031783CB69A4BC435`,
    BULK_AUTOPAY_PAPERLESS_ENROLL: `65D55CF6F9D38F50E02C`,
    SERVICE_CHANGE: `74DC8D4A7C565E51908E`,
    PAUSE_SERVICE: `74DC8D4A7C565E51908E`, // uses same API key as service change APIs
    EXTRA_PICKUP: `615F2EFC82FF8BB2F864`,
    RECYCLING_PICKUP: `615F2EFC82FF8BB2F864`, // uses same API key as Extra pickup APIs
    BULK_PICKUP: `6277FF29BB19666078AC`,
    HISTORY_VISUALS: `615F2EFC82FF8BB2F864`,
    PENDING_CHARGES: `615F2EFC82FF8BB2F864`,
    COMBINED_ORDER_HISTORY: `6277FF29BB19666078AC`,
    SURVEY: `6277FF29BB19666078AC`,
    SUPPORT_SURVEY: `FC1FF1306771BE1049FF`,
    USER_BILLING_DETAILS: `6277FF29BB19666078AC`,
    PAPERLESS_PREFERENCES: `6277FF29BB19666078AC`,
    TEXT_FOR_BULK: `65D55CF6F9D38F50E02C`,
    TEXT_FOR_ROLLOFF: `65D55CF6F9D38F50E02C`,
    TEXT_FOR_XPU: `65D55CF6F9D38F50E02C`,
    TEXT_FOR_CONTAINER_REPAIR: `65D55CF6F9D38F50E02C`,
    REPORT_MISSED_PICKUP: `6277FF29BB19666078AC`,
    TEXT_FOR_CITY_BILLED_BULK: `65D55CF6F9D38F50E02C`,
    TICKET_PICKUP_INFO: `6277FF29BB19666078AC`,
    REDESIGNED_DASHBOARD_PICKUP_INFO: `6277FF29BB19666078AC`,
    REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY: `6277FF29BB19666078AC`,
    REDESIGNED_DASHBOARD_SERVICES_DETAILS: `6277FF29BB19666078AC`,
    CAN_NOT_OUT: `6277FF29BB19666078AC`,
    PROCESSING_FEE: `6ACAFBEEC54039F21716`,
  },
  ESB: {
    /* Make sure REFRESH_TOKEN isn't referenced anywhere before removing it. We don't need it. */
    REFRESH_TOKEN: `0zqoBUUwHpGwp04xxkwM5zKBCy8SeLYm9BibvwEgN2g`,
    ORDERS: `E5E2902D4E578F37CNQ3`,
    GET_OUTAGES: `7YGCO464FF79AZ0Q44WI`, // TODO
  },
}

const okta = {
  clientId: `0oa44bj10rVLE2ns42p7`,
  issuer: `https://ssologin.wm.com/oauth2/aus170minjtQORe4u2p7`,
  url: `https://ssologin.wm.com`,
}

export default {
  ...getBackendAPIs({ origin, apiKeys, okta }),
  ...getMicrositeData(),
  chatbot: {
    awsConfig: {
      region: `us-east-1`,
      identityPoolId: `us-east-1:20b89447-12be-43b0-895c-144ffa2c8294`,
    },
    chatbotConfig: {
      botAlias: `$LATEST`,
      botName: `WMBot`,
      lexUserIdPrefix: `chatbot-wm`,
    },
    host: {
      domain: `https://dfg1tz8260y0c.cloudfront.net`,
      subdirectory: `/config.json`,
    },
  },
  cloudfront: {
    melissaData: {
      emailCleanse: {
        url: `${origin.ESB}/emails/{emailId}/cleanse`,
      },
    },
    myPreferences: {
      getOptions: {
        url: `${origin.ESB}/customers/{customerId}/communication-preferences?locale={lang}`,
      },
      putOptions: {
        url: `${origin.ESB}/customers/{customerId}/communication-preferences?locale={lang}`,
      },
      putResendConfirmation: {
        url: `${origin.ESB}/customers/{customerId}/communication-preferences?resend={resend}&locale={lang}`,
      },
      contacts: {
        url: `${origin.ESB}/customers/{ezpayId}/contacts`,
      },
    },
    // My services customer account info
    alerts: {
      url: `${origin.EBIZ}/alerts/alerts.json`,
    },
    customerSupportLiveChat: {
      url: `${origin.EBIZ}/contactPref/getChatByAddress/zip/`,
    },
    paymentUrl: {
      url: `https://secureacceptance.cybersource.com/embedded/token/create`,
    },
    paymentusIframeURL: {
      url: `https://ipn5.paymentus.com/xotp/pm/wmpt?authToken=`,
    },
    // My services customer account info
    getStartedNavEn: {
      url: `${origin.EBIZ}/nav/wm-canada-nav_en_CA.json`,
    },
    getStartedNavFr: {
      url: `${origin.EBIZ}/nav/wm-canada-nav_fr_CA.json`,
    },
    productListEn: {
      url: `${origin.EBIZ}/products/product-list-wmca_en_CA.json`,
    },
    productListFr: {
      url: `${origin.EBIZ}/products/product-list-wmca_fr_CA.json`,
    },
    facilitySearch: {
      url: `${origin.EBIZ}/getFacilityService?latitude={lat}&longitude={lng}&distance={distance}&lang={lang}&country={country}`,
    },
    // URL for getting facility localized content.
    facilityLocalizedContent: {
      url: `${origin.ESB}/localsites/nonps/zipcode/`,
    },
    getLocalsite: {
      url: `${origin.ESB}/localsites?city={city}&state={state}&street={street}&zipcode={zipcode}&latitude={latitude}&longitude={longitude}`,
    },
  },
  cloudCraze: {
    headers: {
      ClientId: `0oa2a4n454qQs5IlK2p7`,
      ClientSecret: `kcBh_XxJMQI6Uv1bdtLIJTG4VhN-Xq_s3n3htXse`,
    },
  },
  wm: {
    headers: {
      Authorization: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJiZ29sbGFAd20uY29tIiwic3ViIjoiV2FzdGUgbWFuYWdlbWVudCB0ZWFtIiwianRpIjoiQTg4MTFEM0QxMDg1MkJDMDRGMUUiLCJzY29wZSI6WyJzZWxmIiwiYWxsIl0sImlhdCI6MTUwODc3Nzc2NCwiZXhwIjoxNTcxODQ5NzY0fQ.0RTFlpTH25SIsTk438LE4EUhvQjw_CIpJBKbnFeksiQ`,
      ClientId: `A8811D3D10852BC04F1E`,
    },
  },
  commercialChat: {
    liveChatKey: `5738a000000PG4r`,
    liveChatApi: `https://d.la5-c2-ia5.salesforceliveagent.com/chat`,
    liveChatHelper: `00D80000000d7li`,
    businessLiveChatkey: `5730h000000XZz8`,
    businessLiveChatApi: `https://d.la5-c2-ia4.salesforceliveagent.com/chat`,
    businessLiveChatHelper: `00D80000000d7li`,
  },
}
