import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  height: 0,
  width: 0,
  isLandscape: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_VIEWPORT:
      return action.payload

    default:
      return state
  }
}
