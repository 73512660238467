import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  getPickupInfoState: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_PICKUP_INFO_PENDING:
      return {
        ...state,
        getPickupInfoState: `pending`,
      }

    case ActionTypes.GET_PICKUP_INFO_FAILED:
      return {
        ...state,
        getPickupInfoState: `failed`,
      }

    case ActionTypes.GET_PICKUP_INFO_FULFILLED:
      return {
        ...state,
        pickupInfo: action.payload.pickupInfo,
        getPickupInfoState: `fulfilled`,
      }

    case ActionTypes.RESET_MYSERVICES_DASHBOARD:
      return initialState

    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
      return initialState

    default:
      return state
  }
}
