import get from "lodash/get"
import random from "lodash/random"
import { profileIdForAPIs } from "constants-lib/profileIdForAPIs"
import URITemplate from "urijs/src/URITemplate"
import axios from "axios"
import { urls } from "utils-lib/builds"
import * as ActionTypes from "constants-lib/actionTypes"

export const getLocalSitesMASPending = () => ({
  type: ActionTypes.GET_LOCALSITES_MAS_PENDING,
})

export const getLocalSitesMASFulfilled = (data) => ({
  type: ActionTypes.GET_LOCALSITES_MAS_FULFILLED,
  payload: data,
})

export const getLocalSitesMASRejected = (error) => ({
  type: ActionTypes.GET_LOCALSITES_MAS_REJECTED,
  payload: error,
})

export const setLocalsiteVisitFlag = (data) => ({
  type: ActionTypes.SET_LOCALSITE_VISIT_FLAG,
  payload: data,
})

/**
 * Action for getting local sites.
 * @function getLocalSitesMAS
 * @param {Object} params - The parameters for the API call.
 * @param {string} params.city - The city to search for local sites.
 * @param {string} params.state - The state to search for local sites.
 * @param {string} params.street - The street to search for local sites.
 * @param {string} params.zipcode - The zipcode to search for local sites.
 * @param {number} params.latitude - The latitude to search for local sites.
 * @param {number} params.longitude - The longitude to search for local sites.
 * @param {function} [params.cb=() => {}] - Optional callback function to be called after the API call is completed.
 * @returns {function}  dispatches actions to update the store with the results of the API call.
 */
export const getLocalSitesMAS =
  ({ city, state, street, zipcode, latitude, longitude, cb = () => {} }) =>
  (dispatch) => {
    const apiType = `GET_LOCALSITE`
    const profileId = profileIdForAPIs().profileId[apiType]
    const template = URITemplate(urls.cloudfront.getLocalsite.url)
    const localsiteUrl = template.expand({
      city,
      state,
      street,
      zipcode,
      latitude,
      longitude,
    })
    const headers = {
      Authorization: `Basic ZWJpenVzZXI6ZWJpenVzZXI=`,
      ClientId: profileId,
    }
    headers[`Request-Tracking-Id`] = random(1, 999999999)

    dispatch(getLocalSitesMASPending())

    return axios
      .request({
        url: localsiteUrl,
        headers,
        transformResponse: [
          (data) => {
            if (data?.length === 0) {
              return {}
            }
            try {
              return JSON.parse(data)
            } catch {
              return data
            }
          },
        ],
      })
      .then((response) => {
        // TODO do not get wm-us-nav.json if not needed.
        dispatch(getLocalSitesMASFulfilled(response.data))
        return response
      })
      .then((response) => cb(response))
      .catch((error) => {
        dispatch(getLocalSitesMASRejected(error))
      })
  }

/**
 * Action for getting local sites and MAS data from the address object returned by Google.
 * @function getLocalSitesMASFromGoogleAddress
 * @returns {function}  dispatches actions to update the store with the results of the API call.
 */
export const getLocalSitesMASFromGoogleAddress = () => (dispatch, getState) => {
  const reduxState = getState()
  const city = get(reduxState, `customerSelections.customerAddress.city`, ``) // getCity(googleAddress)
  const state = get(reduxState, `customerSelections.customerAddress.state`, ``) // g etState(googleAddress, { shortName: true })
  const street = get(
    reduxState,
    `customerSelections.customerAddress.streetName`,
    ``,
  ) // getStreet(googleAddress)
  const zipcode = get(
    reduxState,
    `customerSelections.customerAddress.postalCode`,
    ``,
  ) // getZipcode(googleAddress)
  const latitude = get(reduxState, `customerSelections.customerAddress.lat`, ``) // _.get(googleAddress, `geometry.location.lat`, null)
  const longitude = get(
    reduxState,
    `customerSelections.customerAddress.lng`,
    ``,
  ) // _.get(googleAddress, `geometry.location.lng`, null)

  return dispatch(
    getLocalSitesMAS({ city, state, street, zipcode, latitude, longitude }),
  )
}

export const onDontSeeAreaClicked = () => ({
  type: ActionTypes.DONT_SEE_AREA_CLICKED,
})
