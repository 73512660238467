import isEmpty from "lodash/isEmpty"
import join from "lodash/join"
import get from "lodash/get"
import size from "lodash/size"
import map from "lodash/map"
import split from "lodash/split"
import noop from "lodash/noop"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { makeStyles } from "tss-react/mui"
import reactHtmlParser from "react-html-parser"
import handleTrademark from "utils-lib/handleTrademark"
import "./FormattedTextField.scss"

const useStyles = makeStyles()((theme) => ({
  root: {
    // defaulting to body styling to maintain proper font useage in the case that the provided rich text is just a string with no tags
    // making sure that the rich text component honors our h tag style guidelines
    "& h1": {
      ...theme.typography.h1,
    },
    "& h2": {
      ...theme.typography.h2,
    },
    "& h3": {
      ...theme.typography.h3,
    },
    "& h4": {
      ...theme.typography.h4,
    },
    "& h5": {
      ...theme.typography.h5,
    },
    "& a": {
      overflowWrap: "anywhere",
    },
  },
  // only using this class if no component is specified as it can mess with other styles
  noComponent: {
    ...theme.typography.body1,
  },
}))

export const FormattedTextField = (
  {
    children,
    className,
    style,
    useSpan,
    useTypography,
    component,
    variant,
    noTrademarkStyle,
    setRef,
    useBoldLinks,
    testId,
  },
  ...props
) => {
  const { classes } = useStyles()

  function getTaggedLinks() {
    const splitOnAnchorOpen = split(children, "<a ")
    const anchors = map(splitOnAnchorOpen, (item) => {
      const splitOnCloseAnchor = split(item, "</a>")
      if (size(splitOnCloseAnchor) > 1) {
        const splitOnAnchorOpenEnd = split(splitOnCloseAnchor[0], `>`)
        const linkText = get(splitOnAnchorOpenEnd, `[1]`, `inline link`)
        return `<a class="a-taggable" analytics="${linkText}" ${item}`
      }

      return item
    })

    return join(anchors, ``)
  }

  let text = getTaggedLinks()
  if (!noTrademarkStyle) {
    text = handleTrademark(text)
  }

  let spreadProps = []
  // eslint-disable-next-line no-empty, react/prop-types
  if (!isEmpty(props) || (props.length === 1 && isEmpty(props[0]))) {
  } else {
    spreadProps = [...props]
  }

  if (useTypography || variant) {
    return (
      <Typography
        {...spreadProps}
        data-testid={testId}
        component={component}
        variant={variant}
        className={`rich-text link-${
          useBoldLinks ? `bold` : `normal`
        } ${className} ${classes.root}`}
        style={style}
        ref={setRef}
      >
        {reactHtmlParser(text)}
      </Typography>
    )
  }

  return (
    <Box
      {...spreadProps}
      data-testid={testId}
      component={useSpan ? `span` : `div`}
      className={`rich-text link-${
        useBoldLinks ? `normal` : `bold`
      } ${className} ${classes.root} ${!component ? classes.noComponent : ``}`}
      style={style}
      ref={setRef}
    >
      {reactHtmlParser(text)}
    </Box>
  )
}

FormattedTextField.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  useSpan: PropTypes.bool,
  useTypography: PropTypes.bool,
  component: PropTypes.string,
  variant: PropTypes.string,
  noTrademarkStyle: PropTypes.bool,
  useBoldLinks: PropTypes.bool,
  setRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  testId: PropTypes.string,
}

FormattedTextField.defaultProps = {
  className: ``,
  style: {},
  noTrademarkStyle: false,
  useSpan: false,
  useTypography: false,
  component: ``,
  variant: ``,
  useBoldLinks: false,
  setRef: noop,
  testId: "FormattedTextField",
}
