import get from "lodash/get"

/**
 * Determines if a user has accounts in both the US and Canada.
 * @function
 * @param {Object} userManageAccount - The user's account management information.
 * @param {boolean} wantObject - Whether to return an object with separate flags for US and Canada or a single boolean value.
 * @param {Array} [brokerAccounts=[]] - An optional array of broker account information.
 * @returns {(Object|boolean)} If wantObject is true, returns an object with separate flags for US and Canada. Otherwise,
 * returns a single boolean value indicating if the user has accounts in both the US and Canada.
 */
export function isUSCanadaAccount(
  userManageAccount,
  wantObject,
  brokerAccounts = [],
) {
  /*
    the broker account API returns data in a different structure and is stored in a different redux state,
    use if present, otherwise use userManageAccount.userAccountDetails
  */
  const userAccountDetails = get(userManageAccount, `userAccountDetails`, [])
  const hasBrokerDetails = brokerAccounts.length
  const accountDetails = hasBrokerDetails ? brokerAccounts : userAccountDetails

  let hasUS = false
  let hasCanada = false

  accountDetails.reduce((result, account) => {
    if (result) {
      return result
    }

    if (hasBrokerDetails) {
      if (!hasUS && get(account, `currency`, ``) === `USD`) {
        hasUS = true
      } else if (!hasCanada && get(account, `currency`, ``) === `CAD`) {
        hasCanada = true
      }
    } else if (!hasUS && get(account, `isCanadianBilled`, ``) === `N`) {
      hasUS = true
    } else if (!hasCanada && get(account, `isCanadianBilled`, ``) === `Y`) {
      hasCanada = true
    }

    return null
  }, false)

  if (wantObject) {
    return { hasUS, hasCanada }
  }
  return hasUS && hasCanada
}
