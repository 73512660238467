import get from "lodash/get"
import dayjs from "utils-lib/date"
import * as ActionTypes from "constants-lib/actionTypes"
import { userType } from "constants-lib/authentication"

export const initialState = {
  paymentAmount: `0.00`,
  paymentDate: dayjs().format(),
  customerIDs: {},
  invoiceNo: ``,
  emailPayment: ``,
  selectedPayMethodId: ``,
  selectedPayMethodAccountNo: ``,
  singleAccountBalance: ``,
  totalAccountBalance: ``,
  currency: `USD`,
  amountBalanceByAccount: {},
  /* Saving a new payment method. */
  savePaymentMethodStatus: ``,
  selectedCurrency: `USD`,
  selectedAddPaymentMethod: ``,
  totalUserBalance: ``,
  billingEmail: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
    case ActionTypes.GET_MY_WM_PAYMENT_VERIFICATION_PENDING:
    case ActionTypes.GET_MY_WM_PAYMENT_CANCEL_TRANSACTION:
    case ActionTypes.RESET_MY_WM_PAYMENT_INFO:
      return {
        ...initialState,
        customerIDs: {},
        selectedCurrency: state.selectedCurrency,
      }

    case ActionTypes.MAKE_ANOTHER_PAYMENT:
      return {
        ...initialState,
        customerIDs: state.customerIDs,
        selectedCurrency: state.selectedCurrency,
        amountBalanceByAccount: state.amountBalanceByAccount,
      }

    case ActionTypes.GET_USER_PAYMENT_METHODS_PENDING:
      return {
        ...state,
        selectedPayMethodId: ``,
        selectedPayMethodAccountNo: ``,
      }

    case ActionTypes.UPDATE_MY_WM_PAYMENT_INFO_DETAILS:
      const clearSelectedPaymentMethod =
        action.payload.clearSelectedPaymentMethod
      // eslint-disable-next-line no-param-reassign
      delete action.payload.clearSelectedPaymentMethod
      const data = clearSelectedPaymentMethod
        ? {
            ...state,
            ...action.payload,
            selectedAddPaymentMethod: ``,
          }
        : {
            ...state,
            ...action.payload,
          }
      return data

    /* Store the total account balance. */
    case ActionTypes.GET_USER_PAYMENT_FULFILLED:
      return {
        ...state,
        totalAccountBalance: get(action.payload, `totalCurrent`, ``),
        currency: get(action.payload, `currency`, ``),
        amountBalanceByAccount: get(
          action.payload,
          `amountBalanceByAccount`,
          {},
        ),
      }

    case ActionTypes.GET_USER_ACCOUNT_BALANCE_FULFILLED:
      return {
        ...state,
        amountBalanceByAccount: action.payload.amountBalanceByAccount,
      }

    case ActionTypes.SET_MY_WM_PAYMENT_REVIEW_DETAILS:
      return {
        ...state,
        reviewParams: action.payload,
      }

    case ActionTypes.SAVE_PAYMENT_METHOD_PENDING:
      return {
        ...state,
        savePaymentMethodStatus: `pending`,
      }

    case ActionTypes.SAVE_PAYMENT_METHOD_FULFILLED:
      return {
        ...state,
        savePaymentMethodStatus: `fulfilled`,
      }

    case ActionTypes.SAVE_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        savePaymentMethodStatus: `failed`,
      }

    case ActionTypes.SET_USER_STATE: {
      /* Clear state after logging in/out. */
      if (
        action.payload === userType.LOGGED_IN ||
        action.payload === userType.LOGGED_OUT
      ) {
        return initialState
      }
      return state
    }

    case ActionTypes.SET_SELECTED_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.payload.selectedCurrency,
      }

    case ActionTypes.SET_USER_ADD_PAYMENT_METHOD:
      return {
        ...state,
        selectedAddPaymentMethod: action.payload.selectedAddPaymentMethod,
      }

    case ActionTypes.SET_TOTAL_USER_BALANCE:
      return {
        ...state,
        totalUserBalance: action.payload,
      }

    case ActionTypes.GET_MY_WM_PAYMENT_BALANCE_FULFILLED:
      return {
        ...state,
        singleAccountBalance: action.payload.totalAmount,
        ...(action.payload.currency && { currency: action.payload.currency }),
      }

    default:
      return state
  }
}
