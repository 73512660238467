import isEmpty from "lodash/isEmpty"
import { getWindow } from "utils-lib/getWindow"
import getLanguageRoute from "../getLanguageRoute"
import { matchLanguageFromUrl } from "../validation"

const window = getWindow()

/**
 * Determines if the browser language matches the specified language code.
 *
 * @function
 * @param {string} lang - The language code to check for, e.g. "en-US".
 * @returns {boolean} Returns true if the browser language matches the specified language code, otherwise false.
  Browser languages:
  fr-CA
  en-CA
  en-US
  es-US
*/
export const isBrowserLanguage = (lang) =>
  (window.navigator.languages && window.navigator.languages[0] === lang) || // Chrome / Firefox
  window.navigator.language === lang || // All browsers
  window.navigator.userLanguage === lang // IE <= 10

/**
 * If the browser language is en-CA, fr-CA, or es-US,
 * then return the language code in the format of
 * en_CA, fr_CA, or es_US. Otherwise, return en_US
 * @returns The browser language.
 */
export const getBrowserLanguage = () => {
  let browserLanguage = `en_US` // Default to en_US
  if (isBrowserLanguage(`en-CA`)) {
    browserLanguage = `en_CA`
  } else if (isBrowserLanguage(`fr-CA`)) {
    browserLanguage = `fr_CA`
  } else if (isBrowserLanguage(`es-US`)) {
    browserLanguage = `es_US`
  }
  return browserLanguage
}

/**
 * Gets the language code for the current page based on the URL pathname.
 * If the language cannot be determined from the URL, then return "en_US".
 *
 * @function
 * @returns {string} The language code for the current page.
 */
// eslint-disable-next-line consistent-return
export const getLanguageFromRoute = () => {
  const { pathname } = window.location
  const trimPathName = pathname.substring(0, 6)
  const lang = matchLanguageFromUrl(pathname)

  if (!lang) {
    return `en_US`
  }
  if (lang === `en`) {
    return `en_CA`
  }
  if (lang === `fr`) {
    return `fr_CA`
  }
  if (lang === `es`) {
    if (trimPathName === `/us/es`) return `es_US`
  }
}

/**
 * If the route contains `/us/` or ends with `/us`, return `us`.
 * if the route contains `/ca/` or ends with `/ca`, return `ca`.
 * Otherwise, return an empty string.
 *
 * @function
 * @param {string} [route=""] - The route to check. If not provided,
 *  it will default to the current route.
 * @returns {string} The country code of the current page.
 */
export const getCountryFromRoute = (route = ``) => {
  const pathname = route !== `` ? route : window.location.pathname
  let country = ``
  if (pathname.indexOf(`/us/`) !== -1 || pathname?.endsWith(`/us`)) {
    country = `us`
  } else if (pathname.indexOf(`/ca/`) !== -1 || pathname?.endsWith(`/ca`)) {
    country = `ca`
  }

  return country
}

/**
 * Pushes a URL to the browser history with the language route prepended to it.
 *
 * @function
 * @param {string} url - The URL to push to the browser history.
 * @param {string} language - The language that the user has selected.
 * @param {object} history - The history object that is passed to the component by the router.
 *
 */
export const pushUrl = (url, language, history) => {
  const languageRoute = getLanguageRoute(language)
  const redirectUrl = `${languageRoute}/${url}`
  history.push(redirectUrl)
}

/**
 * Gets the value of a parameter from the URL query string.
 *
 * @function
 * @param {string} paramName - The name of the parameter to get from the URL.
 * @returns {string} The value of the parameter if it exists, otherwise an empty string.
 */
export const getUrlParam = (paramName) => {
  const urlParams = new URLSearchParams(window.location.search)
  const p = urlParams.get(paramName)

  return !isEmpty(p) ? p : ``
}

/**
 * Determines if the current page is a home page based on the pathname.
 *
 * @function
 * @param {string} pathname - The pathname of the current page. If not provided, the function will use the window location pathname.
 * @returns {boolean} Returns true if the pathname contains the word 'myhome' or 'mybusiness', otherwise false.
 */
export const isHomePage = (pathname) => {
  const path = pathname || window.location.pathname
  return path.indexOf(`myhome`) !== -1 || path.indexOf(`mybusiness`) !== -1
}
