import get from "lodash/get"
import {
  hasLocalSites as hasLocalSitesFn,
  hasMASAccount as hasMASAccountFn,
  hasMultipleMASAccounts as hasMultipleMASAccountsFn,
  hasOneMASAccount as hasOneMASAccountFn,
  getLocalSitesCount,
} from "utils-lib/mas"
import {
  GET_CUSTOMER_ACCOUNT_FULFILLED,
  GET_LOCALSITES_MAS_FULFILLED,
} from "constants-lib/actionTypes"

// Must be a pure function that requires the state
// and the action being dispatched.
const initialState = {
  pageExperience: `non-local`,
}
export default (state = initialState, action) => {
  switch (action.type) {
    // NEVER mutate the state, always return a new state.
    // case ActionTypes.INIT_STATE:
    //   return initialState

    // case RESET_USER_ACCOUNT:
    //   return initialState

    case GET_LOCALSITES_MAS_FULFILLED:
    case GET_CUSTOMER_ACCOUNT_FULFILLED: {
      const payload =
        get(action.payload, `customerAccounts.accounts`, action.payload) || {}

      const hasLocalSites = hasLocalSitesFn(payload)
      const hasMASAccount = hasMASAccountFn(payload)
      const hasMultipleMASAccounts = hasMultipleMASAccountsFn(payload)
      const hasOneMASAccount = hasOneMASAccountFn(payload)
      const localsitesCount = getLocalSitesCount(payload)

      return {
        ...state,
        hasEzpayId: !!get(action, `payload.customers[0].id`),
        hasLocalSites,
        hasOneLocalsite: localsitesCount === 1,
        hasMultipleLocalsites: localsitesCount > 1,
        hasMASAccount,
        hasMultipleMASAccounts,
        hasOneMASAccount,
        noMatchInSFDCAndMAS: hasLocalSites === false && hasMASAccount === false,
        matchInSFDCAndMas: hasLocalSites === true && hasMASAccount === true,
        matchInSFDCOnly: hasLocalSites === true && hasMASAccount === false,
        matchInMASOnly: hasLocalSites === false && hasMASAccount === true,
        pageExperience: hasLocalSites ? `local` : `non-local`,
      }
    }

    default:
      return state
  }
}
