import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity"
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import axios from "axios"
import {
  userType,
  TIME_BETWEEN_CHECKING_ACTIVITY,
  TIME_BETWEEN_SESSION_STATUS,
  TIME_LEFT_BEFORE_SHOWING_EXPIRATION_WARNING_SEC,
  IDLE_TIME_ALLOWED_BEFORE_AUTO_REFRESH,
  TIME_LEFT_BEFORE_AUTO_LOGOUT_SEC,
} from "constants-lib/authentication"
import { authClient } from "constants-lib/okta"
import { localStorageKey } from "constants-lib/localStorage"
import * as pubSubTopics from "constants/pubSubTopics"
import { AuthenticationContext } from "contexts-lib/AuthenticationContext"
import { AEMPageContentContext } from "contexts-lib/AEMPageContentContext"
import { LanguageContext } from "contexts-lib/LanguageContext"
import _ from "lodash"
import PropTypes from "prop-types"
import PubSub from "pubsub-js"
import { Component } from "react"
import Helmet from "react-helmet"
import Routes from "routes"
import aa from "search-insights"
import { wmTheme } from "themes-lib/wmTheme"
import { urls } from "utils-lib/builds"
import isUnitTestEnv from "utils-lib/isUnitTest"
import { addShowOrHideChatbotOnPage } from "utils-lib/chat"
import { logRocketIdentify, logRocketTrack } from "utils-lib/logRocket"
import { getLanguageFromRoute, getBrowserLanguage } from "utils-lib/route"
import { getTimeLeftSec } from "utils-lib/time"
import {
  resetDataLayer,
  updateDataLayer,
  getDLVarFromParam,
  updatePageDL,
  updateVisitorDL,
} from "utils-lib/analytics"
import { API_STATUSES } from "constants-lib/common"
import getLanguageRoute from "utils-lib/getLanguageRoute"
import LogOutModal from "component-lib/Base/components/LogOutModal/LogOutModal"
import SessionExpirationModal from "component-lib/Base/components/SessionExpirationModal/SessionExpirationModal"
import PageFailedModal from "./PageFailedModal/PageFailedModal"
import "styles-lib/bootstrap.min.css"

const ENABLE_CHATBOT = false
const DEFAULT_TITLE = `Waste Management`
const URL_MAPPING = {
  metatags: {
    en_CA: {
      "/mywm/my-payment/verify?redirect=/mywm/user/my-payment/billing":
        "Our simple and intuitive account management tools let you check your balance, pay your bills online and manage your invoices with just a few clicks. Log in now.",
      "/user/forgot-password":
        "Forget your password? Enter your email address and we’ll send you a link to reset your password.",
      "/user/register":
        "Create a My WM account today to pay your bill online, access pickup and holiday schedules, enroll in autopay, go paperless and more. Sign up.",
      "/user/login":
        "Our simple and intuitive account management tools let you pay your bill online, enroll in autopay, set notification preferences and more! Log in to My WM now.",
      "/mywm/my-services":
        "My WM is a simple and intuitive account management tool that lets you pay your bill online, set communication preferences and more! Log in to get started.",
      "/mywm/my-preferences/verify":
        "Waste Management is everywhere you need us to be exactly when you need us to be there. Log in now to set your communication preferences.",
      "/mywm/my-payment/verify":
        "Check your balance, pay your bills online and manage your invoices with just a few clicks. Log in to get started.",
      "/mywm/locate?redirect=/mywm/my-services":
        "My WM is a simple and intuitive account management tool that lets you pay your bill online, set communication preferences and more! Log in to get started.",
      "/mywm/locate?redirect=/mywm":
        "Access My WM Dashboard to pay your bill, view holiday schedule, or set communication preferences. Login now!",
      "/mywm/locate":
        "Access My WM Dashboard to pay your bill, view holiday schedule, or set communication preferences. Login now!",
      "/user/login?redirect=/ca/fr/mywm/user/my-services/extra-pickup":
        "Extra Pickup",
    },
    fr_CA: {
      "/mywm/my-payment/verify?redirect=/mywm/user/my-payment/billing":
        "Nos outils de gestion de compte simples et intuitifs vous permettent de consulter votre solde, payer vos factures en ligne; et gérer vos factures en seulement quelques clics. Connectez-vous maintenant.",

      "/user/forgot-password":
        "Vous avez oublié votre mot de passe? Saisissez votre adresse courriel et nous vous enverrons un lien pour réinitialiser votre mot de passe.",

      "/user/register":
        "Créez un compte My WM dès aujourd'hui pour payer votre facture en ligne, accéder aux horaires de collecte et les jours fériés, vous inscrire au paiement automatique, passer à la facturation sans papier, et plus encore. Inscrivez-vous.",

      "/user/login":
        "Nos outils de gestion de compte simples et intuitifs vous permettent de payer votre facture en ligne, vous inscrire au paiement automatique, définir des préférences de notification, et plus encore! Connectez-vous à My WM maintenant.",

      "/mywm/my-services":
        "My WM est un outil de gestion de compte simple et intuitif qui vous permet de payer votre facture en ligne, définir des préférences de communication, et plus encore! Connectez-vous pour vous lancer.",

      "/mywm/my-preferences/verify":
        "Waste Management va là où vous avez besoin de nous, quand vous avez besoin de nous. Connectez-vous maintenant pour définir vos préférences de communication.",

      "/mywm/my-payment/verify":
        "Consultez votre solde, payez vos factures en ligne et gérez-les en seulement quelques clics. Connectez-vous pour vous lancer.",

      "/mywm/locate?redirect=/mywm/my-services":
        "My WM est un outil de gestion de compte simple et intuitif qui vous permet de payer votre facture en ligne, définir des préférences de communication, et plus encore! Connectez-vous pour vous lancer.",

      "/mywm/locate?redirect=/mywm":
        "Accédez au Tableau de bord My WM pour payer votre facture, voir le calendrier des jours fériés, ou définir vos préférences de communication. Connectez-vous maintenant!",

      "/mywm/locate":
        "Accédez au Tableau de bord My WM pour payer votre facture, voir le calendrier des jours fériés, ou définir vos préférences de communication. Connectez-vous maintenant!",

      "/user/login?redirect=/ca/fr/mywm/user/my-services/extra-pickup":
        "Collecte supplémentaire",
    },
  },
  en_CA: {
    "/terms": `Terms of Use`,
    "/user/login": `My WM Login | Waste Management`,
    "/user/register": `Create An Account | Waste Management`,
    "/user/login?redirect=/mywm/user/my-services": `View MyServices on My WM`,
    "/mywm/my-preferences/verify": `Communication Preferences | Waste Management`,
    "/user/login?redirect=/mywm/user/my-payment/paperless-billing": `Manage Paperless Billing on My WM`,
    "/mywm/locate?redirect=/mywm": `My WM Dashboard | Waste Management`,
    "/user/login?redirect=/mywm/user/my-payment/auto-payment": `Manage Auto Payment on My WM`,
    "/mywm/my-payment/verify": `Payment Portal | Waste Management`,
    "/accessibility": `Accessibility Customer Service Policy`,
    "/privacy#cookieSandtools": `Privacy Policy and Cookies Collection`,
    "/privacy": `Privacy Policy`,
    "/mywm/user/my-services": `Log In to My Services`,
    "/mywm/user/my-payment/auto-payment": `Log In for Auto Payment`,
    "/mywm/user/my-payment/paperless-billing": `Log In for Paperless Billing`,
    "/mywm": `Get Started with the My WM Portal`,
    "/mywm/my-services": `My WM Dashboard Log In | Waste Management`,
    "/sitemap": `Sitemap – Explore WM`,
    "/mywm/my-payment/verify?redirect=/mywm/user/my-payment/billing":
      "Payment Log In | Waste Management",
    "/user/forgot-password": "Password Reset | Waste Management",
    "/mywm/locate?redirect=/mywm/my-services":
      "My WM Dashboard Log In | Waste Management",
    "/mywm/locate": "My WM Dashboard Log In | Waste Management",
    "/mywm/user/my-payment/billing/overview":
      "Payment Portal | Waste Management",
    "/user/login?redirect=/ca/fr/mywm/user/my-services/extra-pickup":
      "Extra Pickup",
  },
  fr_CA: {
    "/terms": `Conditions d’utilisation`,
    "/user/login": `Connexion MonWM`,
    "/user/register": `Inscrivez-vous à  Mon WM`,
    "/user/login?redirect=/mywm/user/my-services": `Voir MesServices sur MonWMM`,
    "/mywm/my-preferences/verify": `Mettre à jour et vérifier MesPréférences sur MonWM`,
    "/user/login?redirect=/mywm/user/my-payment/paperless-billing": `Gérer la facturation électronique sur MonWM`,
    "/mywm/locate?redirect=/mywm": `Démarrer avec MonWM est facile`,
    "/user/login?redirect=/mywm/user/my-payment/auto-payment": `Gérer le paiement automatique sur MonWM`,
    "/mywm/my-payment/verify": `Vérifiez votre mode de paiement sur MonWM`,
    "/accessibility": `Politique d'accessibilité du service à la clientèle`,
    "/privacy#cookieSandtools": `Politique de confidentialité et collecte de cookies`,
    "/privacy": `Politique de confidentialité`,
    "/mywm/user/my-services": `Connectez-vous à Mes services`,
    "/mywm/user/my-payment/auto-payment": `Ouvrir une session pour le paiement automatique `,
    "/mywm/user/my-payment/paperless-billing": `Connectez-vous pour la facturation électronique`,
    "/mywm": `Démarrer avec le portail MonWM `,
    "/mywm/my-services": `Démarrer avec MonWM est facile`,
    "/sitemap": `Plan du site - Explorez WM`,
    "/mywm/my-payment/verify?redirect=/mywm/user/my-payment/billing":
      "Connexion paiement | Waste Management",
    "/user/forgot-password":
      "Réinitialisation du mot de passe | Waste Management",
    "/mywm/locate?redirect=/mywm/my-services":
      "Connexion au Tableau de bord My WM | Waste Management",
    "/mywm/locate": "Connexion au Tableau de bord My WM | Waste Management",
    "/mywm/user/my-payment/billing/overview":
      "Portail de paiement | Waste Management",
    "/user/login?redirect=/ca/fr/mywm/user/my-services/extra-pickup":
      "Collecte supplémentaire",
  },
}

const SHOW_FEEDBACK_TAB_PATHS = {}

// eslint-disable-next-line no-new
new CognitoIdentityClient({
  region: urls.chatbot.awsConfig.region,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({
      region: urls.chatbot.awsConfig.region,
    }),
    IdentityPoolId: urls.chatbot.awsConfig.identityPoolId,
  }),
})

class AppContainer extends Component {
  debounceSetLastActivityTime = _.debounce(
    () => {
      // this method gets annoying during development so turning it off
      // on local environment only
      this.props.reduxActions.setLastActivityTime()
    },
    TIME_BETWEEN_CHECKING_ACTIVITY,
    {
      maxWait: TIME_BETWEEN_CHECKING_ACTIVITY,
    },
  )

  constructor(props) {
    super(props)

    this.state = {
      checkingUserState: true,
      closeExpirationModal: false,
      showExpirationWarning: false,
      showLogOutModal: false,
      showPageFailedModal: false,
      adobeTargetActivated: false,
      isGuest: true,
    }

    window._dl = window._dl || resetDataLayer()
    updateDataLayer({
      previous_page_name:
        getDLVarFromParam(`previous-pagename`) ||
        _.get(window, `_dl.page.pagename`, ``) ||
        _.get(window, `localStorage.${localStorageKey.DL_PAGE_NAME}`, ``),
      language: this.props.reduxState.siteLanguage.language,
      userAccount: this.props.reduxState.userAccount,
      userManageAccount: this.props.reduxState.userManageAccount,
      clicks: {
        ui_element:
          decodeURI(getDLVarFromParam(`ui_element`) || ``) ||
          _.get(window, `_dl.clicks.ui_element`, ``) ||
          _.get(window, `localStorage.${localStorageKey.DL_UI_ELEMENT}`, ``),
        object_content:
          decodeURI(getDLVarFromParam(`object_content`) || ``) ||
          _.get(window, `_dl.clicks.object_content`, ``) ||
          _.get(
            window,
            `localStorage.${localStorageKey.DL_OBJECT_CONTENT}`,
            ``,
          ),
      },
    })
  }

  /* This code gets called the first time the app (website) is loaded (refreshed). */
  componentDidMount() {
    aa("init", {
      appId: urls.apiKey.GUEST.ALGOLIA_APP_ID,
      apiKey: urls.apiKey.GUEST.ALGOLIA_API_KEY,
    })
    this.setFeedbackTabVisibility()
    /** Reloading the page when user clicking on browser back button in order to avoid blank screen */
    this.refreshIfOnHomepage()

    this.getAEMJSON()

    this.props.reduxActions.setViewport()
    window.addEventListener(`resize`, this.setViewport)
    const { userState } = this.props.reduxState.userAccount

    /* Check if the user is logged in. */
    if (userState === userType.LOGGED_IN || userState === userType.LOGGING_IN) {
      /* https://github.com/okta/okta-auth-js#sessionget */
      authClient.session
        ?.get()
        .then((session) => {
          console.warn(
            `session.get() fulfilled with session response:`,
            session,
          )

          if (session && _.get(session, `status`) !== `INACTIVE`) {
            console.warn(
              `App > componentDidMount > authClient.session.get() > User is logged in`,
            )
            /* The user is logged in. */
            this.props.reduxActions.getToken().then((accessToken) => {
              this.getTokenCallback(accessToken)
            })
          } else {
            console.warn(
              `App > componentDidMount > authClient.session.get() > User is NOT logged in`,
            )
            this.onLoginFail()
          }

          this.setState({
            checkingUserState: false,
          })
        })
        .catch((error) => {
          console.warn(`session.get() failed with error:`, error)

          this.onLoginFail()

          this.setState({
            checkingUserState: false,
          })
        })
    } else {
      /* If user is in logged out state, check if there's an active session that may have started
         from another app (e.g. CloudCraze). */
      this.checkIfLoggedInElsewhere()
    }
    const { resetCartDetails } = this.props.reduxActions
    if (userState === userType.LOGGED_IN) {
      resetCartDetails()
    }

    /* Log variables into data layer */
    updateDataLayer({
      previous_page_name:
        getDLVarFromParam(`previous-pagename`) ||
        _.get(window, `_dl.page.pagename`, ``) ||
        _.get(window, `localStorage.${localStorageKey.DL_PAGE_NAME}`, ``),
      language: this.props.reduxState.siteLanguage.language,
      userAccount: this.props.reduxState.userAccount,
      userManageAccount: this.props.reduxState.userManageAccount,
      clicks: {
        ui_element:
          decodeURI(getDLVarFromParam(`ui_element`) || ``) ||
          _.get(window, `_dl.clicks.ui_element`, ``) ||
          _.get(window, `localStorage.${localStorageKey.DL_UI_ELEMENT}`, ``),
        object_content:
          decodeURI(getDLVarFromParam(`object_content`) || ``) ||
          _.get(window, `_dl.clicks.object_content`, ``) ||
          _.get(
            window,
            `localStorage.${localStorageKey.DL_OBJECT_CONTENT}`,
            ``,
          ),
      },
    })

    const satellite = window._satellite
    if (typeof satellite !== `undefined`) {
      satellite.track(`pageview_cms`, window._dl)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      aemData,
      customerSelections: {
        getProductsUrlStatus,
        getCombinedProductUrlStatus,
        getCustomerAccountState,
        customerAccounts,
        customerAccountSearchState,
      },
    } = this.props.reduxState

    // This is to prevent dynamically changing isGuest from causing an infinite redirect loop when navigating to view-eta
    if (
      getProductsUrlStatus !== API_STATUSES.PENDING &&
      getCombinedProductUrlStatus !== API_STATUSES.PENDING &&
      getCustomerAccountState !== API_STATUSES.PENDING &&
      customerAccountSearchState !== API_STATUSES.PENDING
    ) {
      const newIsGuest = !(
        customerAccounts === undefined ||
        customerAccounts.accounts === undefined
      )

      if (newIsGuest !== this.state.isGuest) {
        this.setState({ isGuest: newIsGuest })
      }
    }

    this.setFeedbackTabVisibility()
    if (_.get(this.props.reduxState.siteLanguage, `language`, ``) !== `fr_CA`) {
      const feedbackEl = document.getElementById(`feedback-number-0`)
      if (feedbackEl) {
        feedbackEl.style.visibility = `visible`
      }
    }
    if (this.isCurrentPageHomePage()) {
      /** Reloading the page when user clicking on browser back button in order to avoid blank screen */
      this.refreshIfOnHomepage()
    }

    const previousPageName =
      getDLVarFromParam(`previous-pagename`) ||
      _.get(window, `_dl.page.pagename`, ``) ||
      _.get(window, `localStorage.${localStorageKey.DL_PAGE_NAME}`, ``)

    if (
      !_.isEqual(this.props.location, prevProps.location) ||
      !this.state.adobeTargetActivated
    ) {
      this.adobeTargetCall(encodeURI(this.props.location))
    }

    /* Log variables into data layer */
    if (!_.isEqual(this.props.location, prevProps.location)) {
      updatePageDL({
        previous_page_name: previousPageName,
        language: this.props.reduxState.siteLanguage.language,
      })
    }

    const { userState } = this.props.reduxState.userAccount

    if (
      userState !== prevProps.reduxState.userAccount.userState &&
      userState ===
        _.get(window, `localStorage.${localStorageKey.USER_STATE}`, ``)
    ) {
      updateVisitorDL({
        userAccount: this.props.reduxState.userAccount,
        userManageAccount: this.props.reduxState.userManageAccount,
      })
    }

    /* only load chatbot if the currently selected locale has chatbot configured in the datapage.model.json */
    if (
      aemData &&
      !_.isEqual(aemData, prevProps.reduxState.aemData) &&
      !_.isEmpty(aemData.chatbot)
    ) {
      addShowOrHideChatbotOnPage()
    }
  }

  componentWillUnmount() {
    /* cleanup after all web workers to prevent memory leaks */
    window.workerPool.shutDown()

    window.removeEventListener(`resize`, this.setViewport)
    this.debounceSetLastActivityTime.cancel()
    this.stopTrackingAuthenticationStatus()
  }

  onLoginFail() {
    if (this.props.reduxState.userAccount.userState === userType.LOGGING_IN) {
      /* User should have logged in but didn't. */
      this.showPageFailedModal()
    } else {
      /* The user is NOT logged in. */
      // eslint-disable-next-line no-lonely-if
      if (this.props.hasAddress) {
        this.props.reduxActions.resetUserAccount(userType.COOKIED)
      } else {
        this.props.reduxActions.resetUserAccount(userType.ANONYMOUS)
      }
    }
  }

  setFeedbackTabVisibility() {
    const feedbackEl = document.getElementById(`feedback-number-0`)
    if (feedbackEl) {
      const feedbackTabPagePathMatches = _.filter(
        SHOW_FEEDBACK_TAB_PATHS,
        (pagePath) => window.location.pathname === pagePath,
      )
      if (
        _.get(this.props.reduxState.siteLanguage, `language`, ``) !== `fr_CA` &&
        !_.isEmpty(feedbackTabPagePathMatches)
      ) {
        feedbackEl.style.visibility = `visible`
      } else {
        feedbackEl.style.visibility = `hidden`
      }
    }
  }

  onRootClick = (event) => {
    PubSub.publish(pubSubTopics.ON_ROOT_CLICK, event)
  }

  setViewport = () => {
    this.props.reduxActions.setViewport()
  }

  adobeTargetCall = (location) => {
    const satellite = window._satellite
    let pathName = location.pathname
    pathName = _.trim(pathName, `/`)
    const urlString = pathName.split(`/`)
    const targetData = {
      pageInfo: {
        viewName: pathName?.split("/").join("-"),
        local: `${urlString[0]}/${urlString[1]}`,
        pathName,
      },
    }
    if (typeof satellite !== `undefined`) {
      this.setState({
        adobeTargetActivated: true,
      })
      satellite.track(`view_change`, targetData)
    }
  }

  getAEMJSON = async () => {
    let urlLanguage = getLanguageFromRoute()
    urlLanguage = _.isEmpty(urlLanguage) ? getBrowserLanguage() : urlLanguage
    this.updateLanguageIfNeeded(urlLanguage)

    try {
      const aemJsonResponse = await this.props.reduxActions.getAEMJSON(
        urlLanguage,
      )

      // Do not inject chatbot scripts for canada-app yet. This feature
      // is not yet slated to go out with the upcoming February release.
      if (aemJsonResponse && ENABLE_CHATBOT) {
        /*
          Get the config.json from the AWS S3 bucket.
          The build js and css filenames are written to this JSON during each build.
          Insert styles and scripts in the appropriate elements.
        */

        const domain = urls?.chatbot?.host?.domain
        const subdirectory = urls?.chatbot?.host?.subdirectory
        const configResponse = await axios.get(`${domain}${subdirectory}`)
        const styles = _.get(configResponse, `data.styles`, [])
        const scripts = _.get(configResponse, `data.scripts`, [])

        _.forEach(styles, (value) => {
          const el = document.createElement(`link`)
          el.type = `text/css`
          el.rel = `stylesheet`
          el.href = `${domain}${value}`
          document.querySelector(`head`).appendChild(el)
        })

        _.forEach(scripts, (value) => {
          const el = document.createElement(`script`)
          el.type = `text/javascript`
          el.src = `${domain}${value}`
          document.querySelector(`body`).appendChild(el)
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  startTrackingAuthenticationStatus = () => {
    const sessionIntervalId = setInterval(
      this.checkAuthenticationStatus,
      TIME_BETWEEN_SESSION_STATUS,
    )
    this.props.reduxActions.setSessionIntervalId(sessionIntervalId)
  }

  stopTrackingAuthenticationStatus = () => {
    const sessionIntervalId = _.get(
      this.props.reduxState.userAccount,
      `sessionIntervalId`,
      null,
    )

    if (sessionIntervalId !== null) {
      clearInterval(sessionIntervalId)
      this.props.reduxActions.setSessionIntervalId(null)
    }
  }

  checkIfLoggedInElsewhere = () => {
    /* Try to get an active session. Could be active from another app such as CloudCraze. */
    authClient.session.exists().then((exists) => {
      logRocketTrack(`elsewhere-session-exists-fulfilled`)
      console.warn(
        `elsewhere > session.exists() fulfilled with exists:`,
        exists,
      )

      /* Check if an active session exists before calling the Okta auth session.get().  */
      if (exists) {
        logRocketTrack(`elsewhere-session-exists-fulfilled-true`)

        authClient.session?.get().then((session) => {
          console.warn(
            `elsewhere > session.get() fulfilled with session:`,
            session,
          )
          logRocketTrack(`getWithoutPrompt`)
          logRocketTrack(`getWithoutPrompt-elsewhere`)

          /* Make sure user is logged in. */
          authClient.token
            .getWithoutPrompt({ responseType: `token` })
            .then((token) => {
              // Okta token object has changed the structure, but to be safe we are checking the old structure as well in case this changes again
              const accessToken = !_.isEmpty(token?.accessToken)
                ? token
                : token?.tokens?.accessToken

              logRocketTrack(`getWithoutPrompt-fulfilled`)

              console.warn(
                `elsewhere > getWithoutPrompt() fulfilled with accessToken`,
                accessToken,
              )

              /* User is logged in from an active session from another app). */
              this.props.reduxActions.setToken(accessToken)

              this.getTokenCallback(accessToken)

              /* Save user details. */
              const userId = _.get(session, `userId`, ``)
              const login = _.get(session, `login`, ``)
              const data = {
                userId,
                login,
              }
              this.props.reduxActions.setUserDetails(data)

              _.defer(() => {
                /* Invalidate/Reset linked accounts status. */
                this.props.reduxActions
                  .getLinkedAccounts({
                    token: accessToken,
                    userId,
                  })
                  .then(() => {
                    /* User id will be passed over to dashboard and other routes which will avoid Unnecessary redirections within the app.
                /* Get user details - first name and last name do not come with the session object. */
                    this.props.reduxActions
                      .getUserDetails({
                        token: accessToken,
                        userId,
                      })
                      .then(() => {
                        this.setState({
                          checkingUserState: false,
                        })
                      })
                    /* update visitor data layer */
                    updateVisitorDL({
                      userAccount: this.props.reduxState.userAccount,
                      userManageAccount:
                        this.props.reduxState.userManageAccount,
                    })
                  })
              })
            })
            .catch((e) => {
              logRocketTrack(`getWithoutPrompt-failed`)
              console.warn(
                `elsewhere > getWithoutPrompt() failed with error`,
                e,
              )

              this.checkLoggedOutState()
            })
        })
      } else {
        logRocketTrack(`elsewhere-session-exists-fulfilled-false`)

        /* The user is NOT logged in. */
        this.checkLoggedOutState()
      }
    })
  }

  /*
    Determine which logged out state we're in.
    This method should only be called once we've checked okta session to
    determine that we're not logged in (from any app).
  */
  checkLoggedOutState = () => {
    const currentUserState = this.props.reduxState.userAccount.userState
    /*
      If user is cookied, don't change the state, because the only way we know
      if a user is cookied is if they're already set as cookied in userAccount.userState.

      A user is cookied if:
      1. An address is saved in redux.
      2. User state has been marked as userType.COOKIED.
         userAccount.userState is set to userType.COOKIED on logout,
         so if we see that the current userAccount.userState is userType.COOKIED
         then we know the user was logged in previously.

      Check if user is PROSPECTIVE.
      A user is a prospective user if:
      1. An address is saved in redux.
      2. Current user state in redux is not userType.COOKIED.
    */
    if (this.props.hasAddress) {
      if (currentUserState !== userType.COOKIED) {
        this.props.reduxActions.resetUserAccount(userType.PROSPECTIVE)
      }
      /*
        The user is cookied if we're here in the code. Don't change user state
        since it's already set to cookied.
      */
    } else {
      /* No address found in redux, so set the user as ANONYMOUS. */
      this.props.reduxActions.resetUserAccount(userType.ANONYMOUS)
    }
    this.setState({
      checkingUserState: false,
    })
  }

  getTokenCallback = (token) => {
    if (token !== null) {
      const { firstName, lastName, login } =
        this.props.reduxState.userAccount.userDetails

      logRocketIdentify({
        name: `${firstName} ${lastName}`,
        email: login,
      })

      // this.props.reduxActions.getESBTokens()
      if (this.props.reduxState.userAccount.userState !== userType.LOGGED_IN) {
        /* Update the user redux state (from LOGGING_IN to LOGGED_IN). */
        this.props.reduxActions.setUserState(userType.LOGGED_IN)
      }

      /* Start tracking user activity to see how long they're idle for. */
      this.debounceSetLastActivityTime()
      document.onclick = this.debounceSetLastActivityTime
      document.onmousemove = this.debounceSetLastActivityTime
      document.onkeypress = this.debounceSetLastActivityTime

      /* Start tracking user's authentication status. */
      this.startTrackingAuthenticationStatus()
    }
  }

  checkAuthenticationStatus = () => {
    const { userAccount } = this.props.reduxState

    const expiresAt = _.get(userAccount, `token.expiresAt`, null)
    if (userAccount.userState === userType.LOGGED_IN && expiresAt !== null) {
      const accessTokenTimeLeftSec = getTimeLeftSec(expiresAt)
      console.warn(
        `${_.round(accessTokenTimeLeftSec)} seconds (~${_.round(
          accessTokenTimeLeftSec / 60,
        )} min) left`,
      )

      /* Display modal when session is about to expire. */
      if (
        accessTokenTimeLeftSec <=
        TIME_LEFT_BEFORE_SHOWING_EXPIRATION_WARNING_SEC
      ) {
        /* If there has been user activity, then auto-refresh the token, otherwise show warning. */
        const idleTime =
          _.now() - this.props.reduxState.authentication.lastActivityTime

        console.warn(`Idle for ${_.round(idleTime / 1000)} seconds`)
        /*
          Do not auto refresh once expiration warning is displayed.
        */

        if (
          idleTime <= IDLE_TIME_ALLOWED_BEFORE_AUTO_REFRESH &&
          !this.state.showExpirationWarning
        ) {
          this.refreshToken()
        } else {
          /*
            There was no activity during the allowed idle time before auto-refresh happens,
            so either auto log the user out or show the expiration warning.
          */
          // eslint-disable-next-line no-lonely-if
          if (accessTokenTimeLeftSec <= TIME_LEFT_BEFORE_AUTO_LOGOUT_SEC) {
            this.logOut(true) /* Show log out modal after successful logout. */
          } else {
            /* Display expiration warning. */
            this.showExpirationWarning()
          }
        }
      }
    }
  }

  logOutHelper = () => {
    const { siteLanguage } = this.props.reduxState
    /*
      Remove any local storage keys related to userAccount
      on user logout.
    */
    const userAccountKeys = [
      localStorageKey.TOKEN,
      localStorageKey.USER_DETAILS,
    ]
    _.forEach(userAccountKeys, (key) => {
      window.localStorage.removeItem(key)
    })

    /* User is logging out. Set userType to LOGGING_OUT so that applicable redux states are cleared and
       Authenticated Routes redirect to the home page instead of the login page.  */
    this.props.reduxActions.resetUserAccount(userType.LOGGING_OUT)

    const languageRoute = getLanguageRoute(siteLanguage.language)
    /* Routes user to dashboard on logout, to prevent going to myservices directly after logging in.   */
    const redirectDashboard =
      window.location.pathname.includes(`/mywm/user/my-services`) &&
      this.props.reduxState.userAccount.userState === userType.LOGGING_OUT
    const postLogoutRedirectUri = redirectDashboard
      ? `${languageRoute}/mywm/locate?redirect=${languageRoute}/mywm/user/dashboard`
      : `${languageRoute}/mywm/locate?redirect=${window.location.pathname}`

    const authClientParams = {
      postLogoutRedirectUri,
    }

    authClient
      .signOut(authClientParams)
      .then(() => {
        /* Okta signout completed. Set userType to GUEST so that Authenticated, Unauthenticated, Guest,
      and Router routes redirect appropriately. */
        this.props.reduxActions.resetUserAccount(userType.COOKIED)
        this.props.reduxActions.resetCartDetails()

        /* update visitor data layer */
        updateVisitorDL({
          userAccount: this.props.reduxState.userAccount,
          userManageAccount: this.props.reduxState.userManageAccount,
        })
      })
      .fail((e) => {
        /* Even if this signOut failed, set user to COOKIED state. */
        this.props.reduxActions.resetUserAccount(userType.COOKIED)

        console.error(e)
      })
  }

  logOut = (showLogOutModal = false) => {
    /* User is not logged in. Stop the timer because there is no need to keep checking
    if the session is active. */
    this.debounceSetLastActivityTime.cancel()
    this.stopTrackingAuthenticationStatus()
    this.setState({
      closeExpirationModal: false,
      showExpirationWarning: false,
      showLogOutModal: showLogOutModal === true,
    })

    return this.props.reduxActions
      .revokeToken()
      .then(() => {
        this.logOutHelper()
      })
      .catch(() => {
        this.logOutHelper()
      })
  }

  refreshToken = () => {
    this.hideExpirationWarning()

    /* Returns a new token if the Okta session is still valid. */
    this.props.reduxActions.refreshToken(() => {
      // this.props.reduxActions.getESBTokens()
      this.stopTrackingAuthenticationStatus()
      this.startTrackingAuthenticationStatus()
    })
  }

  showExpirationWarning = () => {
    /* Only set to true if user didn't close out of the expiration modal to prevent the modal from
    displaying again in the checkAuthenticationStatus call after the user already closed the window. */
    if (!this.state.closeExpirationModal) {
      this.setState({ showExpirationWarning: true })
    }
  }

  hideExpirationWarning = () => {
    this.setState({
      closeExpirationModal: false,
      showExpirationWarning: false,
    })
  }

  getTitleByLocation = (location, siteLanguage) => {
    const { pathname, search, hash } = location

    const path = `${pathname}${search || hash || ``}`.replace(
      /\/ca\/fr|\/ca\/en|\/us\/en/g,
      ``,
    )
    const language =
      siteLanguage.language === `en_US` ? `en_CA` : siteLanguage.language
    return URL_MAPPING[language][path] || null
  }

  getMetaDscByLocation = (location, siteLanguage) => {
    const { pathname, search, hash } = location

    const path = `${pathname}${search || hash || ``}`.replace(
      /\/ca\/fr|\/ca\/en|\/us\/en/g,
      ``,
    )

    const language =
      siteLanguage.language === `en_US` ? `en_CA` : siteLanguage.language

    return _.get(URL_MAPPING.metatags, `[${language}][${path}]`, ``) || null
  }

  showPageFailedModal = () => {
    logRocketTrack(`login-failed-modal`)

    this.setState({
      showPageFailedModal: true,
    })
  }

  isCurrentPageHomePage = () =>
    !!window.location.pathname.match(/\/([a-zA-Z]{2})?(\/[a-zA-Z]{2}\/)?$/)

  refreshIfOnHomepage = () => {
    const { action } = this.props.history

    const isCurrentLocationHomePage = this.isCurrentPageHomePage()
    const isLocalEnv = process.env.REACT_APP_NODE_ENV === "local"

    /*
      If working in the local env, the application should be able to render on the root path.
      In deployed envs we should be serving the AEM stack so we have to reload the page
      if we are stuck in the legacy stack on a path that should be serving the homepage.
    */
    if (!isLocalEnv && isCurrentLocationHomePage && action === `POP`) {
      window.location.reload()
    }
  }

  /*
    If the url's locale does not match the saved language in the app, update app language to match
    the one in the url.

    If the app language has not been set, update it to the browser language.
  */
  updateLanguageIfNeeded(urlLanguage) {
    const appLanguage = _.get(
      this.props.reduxState.siteLanguage,
      `language`,
      ``,
    )

    if (!_.isEmpty(urlLanguage) && urlLanguage !== appLanguage) {
      this.props.reduxActions.switchLanguage(urlLanguage, true)
    } else if (_.isEmpty(appLanguage)) {
      this.props.reduxActions.switchLanguage(urlLanguage)
    }
  }

  render() {
    const { reduxState, reduxActions, location } = this.props

    const {
      customerSelections,
      userAccount,
      siteLanguage,
      userManageAccount,
      aemPageData,
    } = reduxState

    const { setBillingDetailsAccount } = reduxActions

    const { userState, userDetails } = userAccount
    const {
      showPageFailedModal,
      checkingUserState,
      showExpirationWarning,
      showLogOutModal,
      isGuest,
    } = this.state

    if (showPageFailedModal) {
      return (
        <ThemeProvider theme={wmTheme}>
          <PageFailedModal logOut={this.logOut} />
        </ThemeProvider>
      )
    }

    /* Do not render page when it's not ready or while logging in or logging out. */
    if (
      (!isUnitTestEnv() && checkingUserState) ||
      userState === userType.LOGGING_IN ||
      userState === userType.LOGGING_OUT
    ) {
      return null
    }

    if (
      userState === userType.LOGGED_IN &&
      window.localStorage.getItem(`pageFailedVisited`)
    ) {
      window.localStorage.removeItem(`pageFailedVisited`)
    }

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={wmTheme}>
          <LanguageContext.Provider
            value={{
              language: siteLanguage.language,
              setLanguage: (language) => {
                // This is never used.
                this.props.reduxActions.switchLanguage(language)
              },
            }}
          >
            <AuthenticationContext.Provider
              value={{
                userState,
                logOut: this.logOut,
                // When user has no accounts, because of the AddressBarHero, user no longer has the userState LOGGED_IN
                isLoggedIn:
                  userState === userType.LOGGED_IN ||
                  Boolean(_.get(userDetails, `userId`, ``)),
                userDetails: userAccount.userDetails,
              }}
            >
              <AEMPageContentContext.Provider
                value={{ aemPageContent: aemPageData.pageData }}
              >
                {/* Helmet is used here for dynamically updating the title of the page based on the current URL path.
                According to react-helmet docs, `nested or later components will override duplicate changes`,
                so title should be set on the App level and other meta data should be set on the child component level */}
                <Helmet
                  title={this.getTitleByLocation(location, siteLanguage)}
                  //  titleTemplate={`%s | ${DEFAULT_TITLE}`}
                  defaultTitle={DEFAULT_TITLE}
                  meta={[
                    {
                      name: `description`,
                      content: this.getMetaDscByLocation(
                        location,
                        siteLanguage,
                      ),
                    },
                  ]}
                />
                <div
                  data-testid="App"
                  className="App"
                  onClick={this.onRootClick}
                  style={{ height: `100%` }}
                >
                  <Routes
                    childProps={{
                      locale: siteLanguage.language,
                      userAccount,
                      userManageAccount,
                      setBillingDetailsAccount,
                      /* _.get(customerSelections, `renderDashboard`, false) && */
                      isGuest: isGuest || customerSelections.isSearchAgain,
                      onLogout: this.logOut,
                    }}
                  />
                  <SessionExpirationModal
                    language={siteLanguage.language}
                    isVisible={showExpirationWarning}
                    logOut={this.logOut}
                    onStayLoggedInClick={() => {
                      this.refreshToken()
                      this.setState({
                        closeExpirationModal: false,
                        showExpirationWarning: false,
                      })
                    }}
                    onClose={() => {
                      this.setState({
                        closeExpirationModal: true,
                        showExpirationWarning: false,
                      })
                    }}
                  />
                  <LogOutModal
                    isVisible={showLogOutModal}
                    siteLanguage={this.props.reduxState.siteLanguage}
                    onClose={() => {
                      this.setState({
                        showLogOutModal: false,
                      })
                    }}
                  />
                  <div className="dropdown-container" />
                </div>
              </AEMPageContentContext.Provider>
            </AuthenticationContext.Provider>
          </LanguageContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }
}

AppContainer.propTypes = {
  hasAddress: PropTypes.bool.isRequired,
  reduxActions: PropTypes.shape({
    resetCartDetails: PropTypes.func.isRequired,
    getAEMJSON: PropTypes.func.isRequired,
    getLinkedAccounts: PropTypes.func.isRequired,
    getToken: PropTypes.func.isRequired,
    getUserDetails: PropTypes.func.isRequired,
    refreshToken: PropTypes.func.isRequired,
    resetUserAccount: PropTypes.func.isRequired,
    revokeToken: PropTypes.func.isRequired,
    setBillingDetailsAccount: PropTypes.func.isRequired,
    setCustomerLob: PropTypes.func.isRequired,
    setLastActivityTime: PropTypes.func.isRequired,
    setMyServicesMain: PropTypes.func.isRequired,
    setRenderDashboard: PropTypes.func.isRequired,
    setSessionIntervalId: PropTypes.func.isRequired,
    setToken: PropTypes.func.isRequired,
    setUserDetails: PropTypes.func.isRequired,
    setUserState: PropTypes.func.isRequired,
    setViewport: PropTypes.func.isRequired,
    switchLanguage: PropTypes.func.isRequired,
  }).isRequired,
  reduxState: PropTypes.shape({
    authentication: PropTypes.object.isRequired,
    customerSelections: PropTypes.object.isRequired,
    siteLanguage: PropTypes.object.isRequired,
    userAccount: PropTypes.object.isRequired,
    userManageAccount: PropTypes.object.isRequired,
    ecommerceCart: PropTypes.object,
    aemData: PropTypes.object,
    aemPageData: PropTypes.object,
  }).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default AppContainer
