import { useState } from "react"
import PropTypes from "prop-types"
import { GenericModal } from "components/shared/Base"
import { deleteAllCookies } from "utils-lib/cookies/deleteAllCookies"
import getLanguageRoute from "utils-lib/getLanguageRoute"
import { withLocalization } from "hoc-lib/localization"

export const strings = {
  en_CA: {
    OOPS: `Oops! Something went wrong.`,
    PLEASE_CLICK: `Please click the button below to reload this page.`,
    RELOAD_PAGE: `Reload Page`,
    SORRY: `Sorry! This area of wm.com is experiencing technical difficulties.`,
    PLEASE_VISIT: `Please visit `,
    WM_SUPPORT: `WM Support`,
    FOR_ASSISTANCE: ` for assistance`,
    GO_TO_SUPPORT: `Go to WM Support`,
  },
  fr_CA: {
    OOPS: `Oups! Un problème s'est produit.`,
    PLEASE_CLICK: `Veuillez cliquer le bouton ci-dessous pour recharger cette page.`,
    RELOAD_PAGE: `Recharger la Page`,
    SORRY: `Désolé! Cette zone de wm.com rencontre en ce moment quelques difficultés.`,
    PLEASE_VISIT: `Veuillez visiter`,
    WM_SUPPORT: `Soutien WM`,
    FOR_ASSISTANCE: `pour assistance`,
    GO_TO_SUPPORT: `Aller au Soutien WM`,
  },
}

const PageFailedModal = ({ strings, language, logOut }) => {
  const [isVisible, setIsVisible] = useState(true)
  const [showSupportModal] = useState(
    window.localStorage.getItem(`pageFailedVisited`),
  )

  const onReloadClick = () => {
    logOut().then(() => {
      window.localStorage.clear()
      window.localStorage.setItem(`pageFailedVisited`, `true`)
      deleteAllCookies()
      window.location = `${getLanguageRoute(language)}/user/login`
    })
  }

  const onSupportClick = () => {
    logOut().then(() => {
      window.localStorage.clear()
      window.localStorage.setItem(`pageFailedVisited`, `true`)
      deleteAllCookies()
      window.location = `${getLanguageRoute(language)}/support`
    })
  }

  if (showSupportModal) {
    return (
      <GenericModal
        open={isVisible}
        onClose={() => {
          setIsVisible(false)
          onReloadClick()
        }}
        icon="/content/dam/mywm/icons/icon-error.svg"
        title={strings.SORRY}
        primaryCTA={strings.GO_TO_SUPPORT}
        primaryOnClick={onSupportClick}
        modalTestingId="PageFailedModal"
        primaryTestingId="PageFailedModal-support-button"
      >
        <div className="mb-5" data-testid="PageFailedModal-inner-container">
          {strings.PLEASE_VISIT}
          <a className="wm-link" href={`${getLanguageRoute(language)}/support`}>
            {strings.WM_SUPPORT}
          </a>
          {strings.FOR_ASSISTANCE}
        </div>
      </GenericModal>
    )
  }

  return (
    <GenericModal
      open={isVisible}
      onClose={() => {
        setIsVisible(false)
        onReloadClick()
      }}
      icon="/content/dam/mywm/icons/icon-error.svg"
      title={strings.OOPS}
      primaryCTA={strings.RELOAD_PAGE}
      primaryOnClick={onReloadClick}
      modalTestingId="PageFailedModal"
      primaryTestingId="PageFailedModal-reload-page-button"
    >
      <div className="mb-5" data-testid="PageFailedModal-inner-container">
        {strings.PLEASE_CLICK}
      </div>
    </GenericModal>
  )
}

PageFailedModal.propTypes = {
  language: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired,
}

export default withLocalization(strings)(PageFailedModal)
