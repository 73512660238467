import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  isLidChecked: ``,
  isContainerChecked: ``,
  isWheelsChecked: ``,
  notes: ``,
  isConatinerReviewPage: false,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.SET_IS_LID_CHECKED:
      return {
        ...state,
        isLidChecked: action.payload,
      }

    case ActionTypes.SET_IS_CONTAINER_CHECKED:
      return {
        ...state,
        isContainerChecked: action.payload,
      }

    case ActionTypes.SET_IS_WHEELS_CHECKED:
      return {
        ...state,
        isWheelsChecked: action.payload,
      }

    case ActionTypes.SET_NOTES_EXIST:
      return {
        ...state,
        notes: action.payload,
      }

    case ActionTypes.SET_CONTAINER_REVIEW_PAGE_OPEN:
      return {
        ...state,
        isConatinerReviewPage: action.payload,
      }

    case ActionTypes.RESET_CONTAINER_DAMAGE_DATA:
      return initialState

    default:
      return state
  }
}
