import * as ActionTypes from "constants-lib/actionTypes"
import URITemplate from "urijs/src/URITemplate"
import { getTokenAction } from "actions"
import axios from "axios"
import _ from "lodash"
import { urls } from "utils-lib/builds"

// Action creators
export const pendingAC = () => ({
  type: ActionTypes.UPDATE_ROLLOFF_SERVICE_REQUEST_PENDING,
})

export const failedAC = () => ({
  type: ActionTypes.UPDATE_ROLLOFF_SERVICE_REQUEST_FAILED,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.UPDATE_ROLLOFF_SERVICE_REQUEST_FULFILLED,
  payload: data,
})

export const setDuplicateRollOffRequest = (data) => ({
  type: ActionTypes.SET_DUPLICATE_ROLL_OFF_REQUEST,
  payload: data,
})

export const updateRollOffServiceRequest =
  (
    data,
    lang,
    customerId,
    token,
    onSuccess,
    requireLogin = false,
    onDuplicate,
  ) =>
  (dispatch, getState) => {
    const api = `UPDATE_ROLLOFF_REQUEST`
    const template = URITemplate(urls.url[api])
    let url = template.expand({
      customerId,
      rollOffRequestId: data.rollOffRequestId,
      lang,
    })

    const config = {
      headers: {},
    }

    if (requireLogin) {
      return getTokenAction(dispatch, getState).then((res) => {
        const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)
        url += `&userId=${userId}`
        config.headers[urls.okta.tokenFieldKey] = res.accessToken
        config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        return updateRollOffRequestHelper(
          url,
          data,
          config,
          onSuccess,
          dispatch,
          getState,
          onDuplicate,
        )
      })
    }
    url += `&token=${token}`
    config.headers.apiKey = _.get(urls, `apiKey.GUEST[${api}]`, ``)

    return updateRollOffRequestHelper(
      url,
      data,
      config,
      onSuccess,
      dispatch,
      getState,
      onDuplicate,
    )
  }

export const updateRollOffRequestHelper = (
  url,
  data,
  config,
  onSuccess,
  dispatch,
  getState,
  onDuplicate,
) => {
  dispatch(pendingAC())

  return axios
    .put(url, data, config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      onSuccess()
      return response
    })
    .catch((error) => {
      const statusCode = _.get(error, `response.status`, ``)
      if (statusCode === 406) {
        dispatch(setDuplicateRollOffRequest(data))
        if (onDuplicate) {
          onDuplicate()
        }
      } else {
        dispatch(failedAC())
      }
    })
}
