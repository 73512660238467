import get from "lodash/get"
import axios from "axios"
import { logRocketIdentify } from "utils-lib/logRocket"
import { urls } from "utils-lib/builds"
import {
  GET_USER_DETAILS_FULFILLED,
  GET_USER_DETAILS_FAILED,
  GET_USER_DETAILS_PENDING,
} from "constants-lib/actionTypes"
import { getTokenAction } from "../getToken"

export const pendingAC = () => ({
  type: GET_USER_DETAILS_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: GET_USER_DETAILS_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: GET_USER_DETAILS_FULFILLED,
  payload: data,
})

/**
 * Retrieves user details for a guest user.
 *
 * @param {string} userName - The username of the guest user.
 * @returns {Promise} A promise that resolves to the user details.
 */
export const getUserDetailsGuest = (userName) => (dispatch, getState) => {
  const state = getState()

  dispatch(pendingAC())

  const config = {
    params: {
      userName,
      locale: get(state, `siteLanguage.language`, `en_CA`),
    },
  }

  const api = `USER_DETAILS`
  const url = `${urls.url[api]}`

  const apiKey = get(urls, `apiKey.GUEST[${api}]`, ``)
  if (apiKey !== ``) {
    config.headers = {
      apiKey,
    }
  }

  return axios
    .create(config)
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      dispatch(failedAC(get(error, `response.status`, ``)))
    })
}

/**
 * Retrieves user details from the server.
 *
 * @param {Object} options - The options for retrieving user details.
 * @param {Function} options.cb - The callback function to be called on success.
 * @param {Function} options.errorCb - The callback function to be called on error.
 * @param {string|null} options.token - The authentication token.
 * @param {string} options.userId - The user ID.
 * @returns {Function} - The Redux thunk function.
 */
export const getUserDetails =
  ({ cb = () => {}, errorCb = () => {}, token = null, userId = `` }) =>
  (dispatch, getState) => {
    const state = getState()

    dispatch(pendingAC())

    const config = {
      params: {
        userId:
          userId !== ``
            ? userId
            : get(state, `userAccount.userDetails.userId`, ``),
        locale: get(state, `siteLanguage.language`, `en_CA`),
      },
    }

    logRocketIdentify({
      userId: get(state, `userAccount.userDetails.userId`, ``),
    })

    if (token === null) {
      return getTokenAction(dispatch, getState).then((token) =>
        getUserDetailsHelper(state, config, token, dispatch, cb, errorCb),
      )
    }
    return getUserDetailsHelper(state, config, token, dispatch, cb, errorCb)
  }

/**
 * Retrieves user details from the API.
 *
 * @param {Object} state - The current state object.
 * @param {Object} config - The configuration object for the API request.
 * @param {Object} token - The access token object.
 * @param {Function} dispatch - The dispatch function for Redux actions.
 * @param {Function} cb - The callback function to be called on success.
 * @param {Function} errorCb - The callback function to be called on error.
 * @returns {Promise} - A promise that resolves with the API response.
 */
export const getUserDetailsHelper = (
  state,
  config,
  token,
  dispatch,
  cb,
  errorCb,
) => {
  const api = `USER_DETAILS`
  const url = urls.url[api]

  // eslint-disable-next-line no-param-reassign
  config.headers = {
    token: token.accessToken,
  }

  const apiKey = get(urls, `apiKey.USER[${api}]`, ``)
  if (apiKey !== ``) {
    // eslint-disable-next-line no-param-reassign
    config.headers.apiKey = apiKey
  }

  return axios
    .get(url, config)
    .then((response) => {
      const res = get(response, `data.data.profile`, {})
      const responseIsFirstTime = get(res, `firstLogin`, `false`) === `true`
      const reduxIsfirstTime = get(
        state,
        `userAccount.userDetails.isFirstTimeUser`,
        false,
      )

      const data = {
        firstName: get(res, `firstName`, ``),
        lastName: get(res, `lastName`, ``),
        login: get(res, `login`, ``),
        newLogin: get(res, `newLogin`, ``),
        mobilePhone: get(res, `mobilePhone`, ``),
        primaryPhone: get(res, `primaryPhone`, ``),
        isLinkExpired: get(res, `loginUpdateLinkExp`, `YES`),
        isFirstTimeUser: reduxIsfirstTime || responseIsFirstTime, // else take API response
      }
      dispatch(fulfilledAC(data))
      cb()
      return response
    })
    .catch((error) => {
      dispatch(failedAC(get(error, `response.status`, ``)))
      errorCb()
    })
}
