import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  repair: {},
  getRepairState: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_REPAIR_REQUEST_PENDING:
      return {
        ...state,
        status: ``,
        getRepairState: `pending`,
      }

    case ActionTypes.GET_REPAIR_REQUEST_FAILED:
      return {
        ...state,
        status: ``,
        getRepairState: `failed`,
      }

    case ActionTypes.GET_REPAIR_REQUEST_FULFILLED:
      return {
        ...state,
        repair: action.payload.data.data || {},
        status: action.payload.data.status,
        getRepairState: `fulfilled`,
      }

    default:
      return state
  }
}
