import isString from "lodash/isString"

/**
 * A function that takes a string as an argument and modifies it by wrapping the registered trademark symbol in a superscript tag.
 * @param {string} text - The input string to be modified.
 * @returns {string} - The modified string wrapped in a span tag.
 */
export default (text) => {
  if (!isString(text) || text.indexOf(`®`) === -1) {
    return text
  }

  const modifiedString = text.split(`®`).join(`<sup>®</sup>`)

  /* It's returning a string with the modified string wrapped in a span tag. */
  return `<span>${modifiedString}</span>`
}
