/**
 * Takes a raw number, checks if it's a number, and if it is, it formats
 * it to a phone number format.
 *
 * @function
 * @param {string|number} rawNumber - The raw number that we want to format.
 * @returns {string} The formatted phone number, or the original input if it's not a number.
 */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
export const getFormattedNumber = (rawNumber) => {
  if (isNaN(parseInt(rawNumber, 10))) {
    return rawNumber
  }
  rawNumber = rawNumber.toString()
  const maskedNumber = []
  maskedNumber.push(`(`)
  for (let i = 0; i < rawNumber.length; i++) {
    if (maskedNumber.length === 4) {
      maskedNumber.push(`)`, ` `, rawNumber[i])
    } else if (maskedNumber.length === 9) {
      maskedNumber.push(`-`, rawNumber[i])
    } else {
      maskedNumber.push(rawNumber[i])
    }
  }
  return maskedNumber.join(``)
}
