import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import { generateUniqueID } from "utils-lib/requests"
import { profileIdForAPIs } from "constants-lib/profileIdForAPIs"
import { urls } from "utils-lib/builds"
import { mapToCamelFromSnake } from "utils-lib/objects"
import { MOCK_MANAGE_CONTACTS_GET } from "utils-lib/mocks"
import { AuthoringUtils } from "@adobe/aem-spa-page-model-manager"
import { getTokenAction } from "../getToken"

const pendingAC = () => ({
  type: ActionTypes.GET_CUSTOMER_CONTACTS_PENDING,
})

const failedAC = (data) => ({
  type: ActionTypes.GET_CUSTOMER_CONTACTS_FAILED,
  payload: data,
})

const fulfilledAC = (data) => ({
  type: ActionTypes.GET_CUSTOMER_CONTACTS_FULFILLED,
  payload: data,
})

/**
 * Fetches customer contacts information based on customer ID and token.
 * @param {string} customerId - The ID of the customer.
 * @param {string} token - The token for the customer.
 * @param {function} cb - The callback function to be executed after the async operation.
 * @returns {function} A function that takes the Redux dispatch and getState functions as arguments.
 */
export const getCustomerContacts =
  (customerId, token, cb = () => {}) =>
  async (dispatch, getState) => {
    if (token === ``) {
      return null
    }

    const template = URITemplate(urls.cloudfront.myPreferences.contacts.url)
    const getCustomerContactsUrl = template.expand({ ezpayId: customerId })
    const apiType = `GET_CONTACTS`
    const profileId = profileIdForAPIs().profileId[apiType]

    dispatch(pendingAC())
    try {
      let response
      if (AuthoringUtils.isInEditor() || MOCK_MANAGE_CONTACTS_GET) {
        const importedMockFile = await import(
          `./mocks/getCustomerContactsResponse.js`
        )
        response = importedMockFile.default
      } else {
        const tokenResponse = await getTokenAction(dispatch, getState)
        const token = tokenResponse.accessToken
        response = await axios.get(getCustomerContactsUrl, {
          headers: {
            "Request-Tracking-Id": generateUniqueID(),
            Authorization: `Bearer ${token}`,
            ProfileId: profileId,
          },
        })
      }
      const customerContacts = mapToCamelFromSnake(response?.data)
      const verificationToken = get(response, `headers.token`, ``)
      dispatch(
        fulfilledAC({
          customerContacts,
          verificationToken,
        }),
      )
      cb()

      return customerContacts
    } catch (response) {
      const statusCode = get(response, `response.status`, ``)
      const errorMessage = get(
        response,
        `response.data.verificationErrorMsg`,
        ``,
      )
      const customerContacts = get(response, `response.data`)
      const data = {
        statusCode,
        errorMessage,
        customerContacts,
      }
      dispatch(failedAC(data))
      cb()
      return data
    }
  }
