/**
 * Converts a string from snake_case to camelCase.
 *
 * @param {string} s - The string to convert.
 * @returns {string} A new string with the first letter of each word capitalized.
 *
 * @example
 * const snakeCaseString = 'my_snake_case_string';
 * const camelCaseString = toCamelFromSnake(snakeCaseString);
 * console.log(camelCaseString); // Output: 'mySnakeCaseString'
 * @see {@link https://matthiashager.com/converting-snake-case-to-camel-case-object-keys-with-javascript}
 */
// eslint-disable-next-line import/prefer-default-export
export const toCamelFromSnake = (s) =>
  s.replace(/([-_][a-z])/gi, ($1) =>
    $1.toUpperCase().replace("-", "").replace("_", ""),
  )
