import { SET_CONTAINER_REPLACE_REPAIR } from "constants-lib/actionTypes"

export const fulfilledAC = (data) => ({
  type: SET_CONTAINER_REPLACE_REPAIR,
  payload: { data },
})

export const setContainerReplaceRepair = (data) => (dispatch) => {
  dispatch(fulfilledAC(data))
}
