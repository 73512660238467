import * as ActionTypes from "constants-lib/actionTypes"
import { removeNonNumericCharacters } from "utils-lib/strings"
import _ from "lodash"

const initialState = {
  fetchState: ``,
  putState: ``,
  resendConfirmationState: ``,
  options: {},
  resendNewOptions: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CONTACT_OPTIONS_PENDING:
      return {
        ...state,
        fetchState: `pending`,
        putState: state.putState,
        options: {},
      }

    case ActionTypes.GET_CONTACT_OPTIONS_FAILED:
      return {
        ...state,
        fetchState: `failed`,
        putState: state.putState,
        options: {},
      }

    /*
{
  "request_tracking_id": "95970",
  "preferences": [{
    "category": "Service Exception",
    "is_category_enabled": "N",
    "channels": [{
      "name": "Email",
      "options": [{
        "value": "MKALMBACH@SHAWPIPE.CA",
        "type": "BEMAIL"
      }, {
        "value": "CANADA.AP@SHAWPIPE.CA",
        "type": "SEMAIL"
      }]
    }, {
      "name": "Text",
      "options": [{
        "value": "7804675501",
        "type": "BPHONE"
      }, {
        "value": "7804675501",
        "type": "SPHONE"
      }]
    }]
  }, {
    "category": "Service Notification",
    "is_category_enabled": "Y",
    "channels": [{
      "name": "Email",
      "options": [{
        "value": "MKALMBACH@SHAWPIPE.CA",
        "type": "BEMAIL"
      }, {
        "value": "CANADA.AP@SHAWPIPE.CA",
        "type": "SEMAIL"
      }]
    }, {
      "name": "Text",
      "options": [{
        "value": "7804675501",
        "type": "BPHONE"
      }, {
        "value": "7804675501",
        "type": "SPHONE"
      }]
    }]
  }]
}
*/
    case ActionTypes.GET_CONTACT_OPTIONS_FULFILLED:
      /* options = preference category > channel name > option value */
      const options = {}
      const enabledCategories = _.filter(
        action.payload,
        (enabledCat) => enabledCat.is_category_enabled === `Y`,
      )
      const resendNewOptions = {}
      _.map(enabledCategories, (optionCategory) => {
        const category = _.get(optionCategory, `category_code`, ``)
        if (options[category] === undefined) {
          options[category] = {}
        }
        _.map(optionCategory.channels, (channel) => {
          if (options[category][channel.name] === undefined) {
            options[category][channel.name] = {}
          }
          /* Channels potentially have a `selections` and an `options` object. */
          _.map(channel.options, (option) => {
            if (options[category][channel.name][option.value] === undefined) {
              if (channel.name === `Text`) {
                options[category][channel.name][
                  removeNonNumericCharacters(option.value)
                ] = {}
              } else {
                options[category][channel.name][option.value] = {}
              }
            }
            /*
            Check if this option is selected.
            It's possible for the same value to exist under different types,
            especially if backend did not properly remove them when they were
            removed from customer contacts.
          */
            let selection = ``
            if (channel.name === `Text`) {
              selection = _.find(
                channel.selections,
                (o) =>
                  o.value === removeNonNumericCharacters(option.value) &&
                  o.is_opt_in !== `N`,
              )
            } else {
              selection = _.find(
                channel.selections,
                (o) => o.value === option.value && o.is_opt_in !== `N`,
              )
            }

            /* `Y`, `N`, or `P` for pending. */
            const status = _.get(selection, `is_opt_in`, `N`)
            if (channel.name === `Text`) {
              options[category][channel.name][
                removeNonNumericCharacters(option.value)
              ][option.type] = status
            } else {
              options[category][channel.name][option.value][option.type] =
                status
            }
          })
        })
      })

      _.map(action.payload, (optionCategory) => {
        const category = _.get(optionCategory, `category_code`, ``)
        if (resendNewOptions[category] === undefined) {
          resendNewOptions[category] = {}
        }
        _.map(optionCategory.channels, (channel) => {
          if (resendNewOptions[category][channel.name] === undefined) {
            resendNewOptions[category][channel.name] = {}
          }
          /* Channels potentially have a `selections` and an `options` object. */
          _.map(channel.options, (option) => {
            if (
              resendNewOptions[category][channel.name][option.value] ===
              undefined
            ) {
              if (channel.name === `Text`) {
                resendNewOptions[category][channel.name][
                  removeNonNumericCharacters(option.value)
                ] = {}
              } else {
                resendNewOptions[category][channel.name][option.value] = {}
              }
            }
            /*
            Check if this option is selected.
            It's possible for the same value to exist under different types,
            especially if backend did not properly remove them when they were
            removed from customer contacts.
          */
            let selection = ``
            if (channel.name === `Text`) {
              selection = _.find(
                channel.selections,
                (o) =>
                  o.value === removeNonNumericCharacters(option.value) &&
                  o.is_opt_in !== `N`,
              )
            } else {
              selection = _.find(
                channel.selections,
                (o) => o.value === option.value && o.is_opt_in !== `N`,
              )
            }
            /* `Y`, `N`, or `P` for pending. */
            const status = _.get(selection, `is_opt_in`, `N`)
            if (channel.name === `Text`) {
              resendNewOptions[category][channel.name][
                removeNonNumericCharacters(option.value)
              ][option.type] = status
            } else {
              resendNewOptions[category][channel.name][option.value][
                option.type
              ] = status
            }
            if (channel.name === `Text`) {
              _.extend(
                resendNewOptions[category][channel.name][
                  removeNonNumericCharacters(option.value)
                ],
                { resendStatus: `` },
              )
            }
          })
        })
      })

      return {
        ...state,
        fetchState: `fulfilled`,
        putState: state.putState,
        options,
        resendNewOptions,
      }

    case ActionTypes.SET_CONTACT_OPTIONS_PENDING:
      return {
        ...state,
        putState: `pending`,
      }

    case ActionTypes.SET_CONTACT_OPTIONS_FAILED:
      return {
        ...state,
        putState: `failed`,
        ...action.payload,
      }

    case ActionTypes.SET_CONTACT_OPTIONS_FULFILLED:
      return {
        ...state,
        putState: `fulfilled`,
        // data: {
        //   ...action.payload,
        // },
      }

    case ActionTypes.SEND_CONFIRMATION_TEXT_PENDING:
      const resendPending = _.cloneDeep(state.resendNewOptions)
      resendPending[action.payload.category][action.payload.channel][
        action.payload.optionKey
      ].resendStatus = `pending`

      return {
        ...state,
        resendConfirmationState: `pending`,
        resendNewOptions: resendPending,
      }

    case ActionTypes.SEND_CONFIRMATION_TEXT_FAILED:
      const resendFailed = _.cloneDeep(state.resendNewOptions)
      resendFailed[action.payload.category][action.payload.channel][
        action.payload.optionKey
      ].resendStatus = `failed`

      return {
        ...state,
        resendConfirmationState: `failed`,
        resendNewOptions: resendFailed,
        ...action.payload,
      }

    case ActionTypes.SEND_CONFIRMATION_TEXT_FULFILLED:
      const resendFulfilled = _.cloneDeep(state.resendNewOptions)
      resendFulfilled[action.payload.category][action.payload.channel][
        action.payload.optionKey
      ].resendStatus = `fulfilled`

      return {
        ...state,
        resendConfirmationState: `fulfilled`,
        resendNewOptions: resendFulfilled,
      }

    default:
      return state
  }
}
