import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "tss-react/mui"
import TextField from "@mui/material/TextField"
import { Autocomplete } from "@mui/material"
import { handleEnterPress } from "utils-lib/handleEnterPress"
import { BOOTSTRAP_GRID_MD_MIN } from "constants-lib/dimensions"
import { KEY_CODE_MAPPING } from "constants-lib/keyCodes"
import { getWindow } from "utils-lib/getWindow"
import "./Dropdown.scss"

const window = getWindow()

const useStyles = makeStyles()((theme, { inputWidth }) => ({
  root: {
    width: `100%`,
    [theme.breakpoints.up(`sm`)]: {
      width: window.innerWidth < BOOTSTRAP_GRID_MD_MIN ? `100%` : `inherit`,
    },
    "& .MuiAutocomplete-endAdornment": {
      right: `0 !important`,
    },
    "& .MuiAutocomplete-endAdornment button": {
      height: `48px`,
      width: `48px`,
    },
  },
  input: {
    backgroundColor: theme.palette.background.white,
    width: inputWidth || `inherit`,
    cursor: `default`,
  },
}))

const Dropdown = ({
  label,
  value,
  inputWidth,
  options,
  style,
  id,
  handleChange,
  required,
  changeParams,
  noDefaultValue,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { classes } = useStyles({ inputWidth })

  /* have to do this, as disabling the input disables the dropdown as well, and we don't want the user to be able to clear or type in the input */
  useEffect(() => {
    const inputEl = window.document.getElementById(
      id || `select-outlined-${label}`,
    )

    if (inputEl) {
      inputEl.setAttribute(`readonly`, true)
    }
  }, [])

  const handleOnChange = (event, newValue) => {
    handleChange(newValue, changeParams)
    toggleMenu()
  }
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)

  const closeMenu = () => setIsMenuOpen(false)

  const handleKeyPress = (e) => {
    if (
      e.keyCode === KEY_CODE_MAPPING.ENTER ||
      e.keyCode === KEY_CODE_MAPPING.SPACE_BAR
    ) {
      handleEnterPress(e, toggleMenu())
    }
  }

  return (
    <Autocomplete
      data-testid="MUIDropdown"
      id={id || `select-outlined-${label}`}
      className={classes.root}
      disableClearable
      defaultValue={noDefaultValue ? null : options[0]}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value}
      onChange={handleOnChange}
      onClick={toggleMenu}
      options={options}
      blurOnSelect
      open={isMenuOpen}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.input}
          style={style}
          label={label}
          required={required}
          variant="outlined"
          autoComplete="new-password"
          onClick={toggleMenu}
          onKeyDown={handleKeyPress}
          onBlur={closeMenu}
        />
      )}
    />
  )
}

Dropdown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  inputWidth: PropTypes.string,
  options: PropTypes.array,
  id: PropTypes.string,
  handleChange: PropTypes.func,
  required: PropTypes.bool,
  style: PropTypes.object,
  changeParams: PropTypes.object,
  noDefaultValue: PropTypes.bool,
}

Dropdown.defaultProps = {
  label: ``,
  value: ``,
  inputWidth: ``,
  options: [],
  id: ``,
  handleChange: () => {},
  required: false,
  style: {},
  changeParams: {},
  noDefaultValue: false,
}
export default Dropdown
