import withSSRModel from "hoc-lib/ssrModelMapper"
import { connect } from "react-redux"
import DatePicker from "./DatePicker"

export const mapStateToProps = (state) => ({
  reduxState: {
    siteLanguage: state.siteLanguage,
  },
})

export default withSSRModel(connect(mapStateToProps)(DatePicker))
