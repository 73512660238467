// https://serverless-stack.com/chapters/create-a-route-that-redirects.html
import { Component } from "react"
import PropTypes from "prop-types"
import getLanguageRoute from "utils-lib/getLanguageRoute"
import { userType } from "constants-lib/authentication"
import { Route, Redirect } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import _ from "lodash"
import { parse } from "query-string"
import * as actions from "actions"
import { isBrokerAccountSelector } from "selectors-lib/linkedAccounts"

const ACTION_MAPPING = {
  SERVICE_CHANGE: `service-change`,
  ADD_WASTE_STREAM: `add-service`,
}

const EXEMPT_ROUTE_MAPPING = {
  NOTIFICATIONS: `$LANGUAGE_PATH/mywm/user/notifications`,
  SERVICE_HISTORY: `$LANGUAGE_PATH/mywm/user/my-services/service-history`,
  ORDER_HISTORY: `$LANGUAGE_PATH/mywm/user/order-history`,
  EXTRA_PICKUP: `$LANGUAGE_PATH/mywm/user/my-services/extra-pickup`,
  MY_SERVICES: `$LANGUAGE_PATH/mywm/user/my-services`,
  BILLING_OVERVIEW: `$LANGUAGE_PATH/mywm/user/my-payment/billing/overview`,
  BULK_PICKUP: `$LANGUAGE_PATH/mywm/user/my-services/bulk-pickup`,
  ROLLOFF_REQUEST: `$LANGUAGE_PATH/mywm/user/my-services/rolloff-request`,
}

const SERVICE_ORDER_HISTORY = `$LANGUAGE_PATH/mywm/user/my-services/view-orders`
const COMBINED_ORDER_HISTORY = `$LANGUAGE_PATH/mywm/user/order-history`

const REDIRECT_TO_COMBINED_ORDER_HISTORY = true

export class AuthenticatedRoute extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasSearchedLinkAccounts: false,
    }
  }

  componentDidMount() {
    const userState = _.get(this.props.reduxState.userAccount, `userState`, ``)

    /* If the user is logged in and has no matching accounts, redirect to manage link accounts page. */
    if (userState === userType.LOGGED_IN) {
      this.checkLinkedAccounts()
    }
  }

  componentDidUpdate(prevProps) {
    const oldUserState = _.get(
      prevProps.reduxState.userAccount,
      `userState`,
      ``,
    )
    const userState = _.get(this.props.reduxState.userAccount, `userState`, ``)

    if (oldUserState !== userState && userState === userType.LOGGED_IN) {
      this.checkLinkedAccounts()
    }
  }

  /* Check if we need to search for linked accounts. */
  checkLinkedAccounts() {
    const status = _.get(
      this.props.reduxState.userManageAccount,
      `getLinkedAccountStatus`,
      ``,
    )
    if (status === `` || status === `failed`) {
      this.props.reduxActions.getLinkedAccounts().then(() => {
        this.setState({
          hasSearchedLinkAccounts: true,
        })
      })
    } else {
      this.setState({
        hasSearchedLinkAccounts: true,
      })
    }
  }

  render() {
    const {
      component: C,
      layout: L,
      secondaryLayout: SL,
      props: cProps,
      getPath,
      reduxState,
      requireLinkedAccounts,
      redirectPath,
      ...rest
    } = this.props

    const shouldUseSecondaryLayout = SL !== undefined
    /* If in logging state, do not redirect yet, just wait. */
    const { userState, userDetails } = cProps.userAccount
    if (userState === userType.LOGGING_IN) {
      return null
    }

    return (
      <div data-testid="AuthenticatedRoute" className="h-100">
        <Route
          {...rest}
          render={(props) => {
            let innerComponent = <C {...props} {...cProps} />
            if (shouldUseSecondaryLayout) {
              innerComponent = <SL {...cProps}>{innerComponent}</SL>
            }

            const languageRoute = getLanguageRoute(cProps.locale)

            // When user has no accounts, because of the AddressBarHero, user no longer has the userState LOGGED_IN
            if (
              userState === userType.LOGGED_IN ||
              _.get(userDetails, `userId`, ``)
            ) {
              const pathnameWithoutTrailingSlash = window.location.pathname
                .replace(languageRoute, `$LANGUAGE_PATH`)
                .replace(/\/$/, ``)

              /* this is temporary until we re-enable the service dashboard level history page */
              if (
                REDIRECT_TO_COMBINED_ORDER_HISTORY &&
                pathnameWithoutTrailingSlash === SERVICE_ORDER_HISTORY
              ) {
                const redirect = COMBINED_ORDER_HISTORY.replace(
                  `$LANGUAGE_PATH`,
                  languageRoute,
                )
                return <Redirect to={redirect} />
              }

              /* Check for linked accounts if required. */
              if (requireLinkedAccounts === true) {
                /* Check if we should wait for linked accounts to be fetched. */
                const status = _.get(
                  this.props.reduxState.userManageAccount,
                  `getLinkedAccountStatus`,
                  ``,
                )
                if (
                  this.state.hasSearchedLinkAccounts === false &&
                  status !== `fulfilled`
                ) {
                  return null
                }

                /* If the user is logged in and has no matching accounts, redirect to manage link accounts page. */
                const linkedAccounts = _.get(
                  this.props.reduxState.userManageAccount,
                  `linkedAccounts`,
                  [],
                )

                /* If user is logged in and is first time user, redirect to manage link accounts page. */
                const isFirstTimeUser = _.get(
                  this.props.reduxState.userAccount,
                  `userDetails.isFirstTimeUser`,
                  false,
                )

                if (linkedAccounts.length === 0 || isFirstTimeUser) {
                  return <Redirect to={`${languageRoute}/mywm/user/accounts`} />
                }

                if (redirectPath) {
                  const { id } = parse(window.location.search)

                  const isPayMyBill =
                    window.location.search?.includes("my-payment/billing")
                  const isViewETA =
                    window.location.search?.indexOf(`view-pickup-eta`) !== -1

                  if (id) {
                    const { IVRToText9_0 } = window._dl

                    // handle setting datalayer for bill pay SMS links
                    if (isPayMyBill) {
                      if (!IVRToText9_0) {
                        window._dl.IVRToText9_0 = {
                          IVRtoTextBillPaymentURL: window.location.href,
                        }
                      } else {
                        window._dl.IVRToText9_0.IVRtoTextBillPaymentURL =
                          window.location.href
                      }
                    }

                    // handle setting datalayer for view eta SMS links
                    if (isViewETA) {
                      if (!IVRToText9_0) {
                        window._dl.IVRToText9_0 = {
                          IVRtoTextViewETAURL: window.location.href,
                        }
                      } else {
                        window._dl.IVRToText9_0.IVRtoTextViewETAURL =
                          window.location.href
                      }
                    }
                  }

                  return <Redirect to={`${languageRoute}${redirectPath}`} />
                }

                /*
              If the user is logged in and has over 50 matching accounts, redirect to the billing overview page
              whenever dashboard is accessed.
            */
                const isDashboard =
                  window.location.pathname.indexOf(`/dashboard`) !== -1
                if (this.props.isBrokerAccount && isDashboard) {
                  return (
                    <Redirect
                      to={`${languageRoute}/mywm/user/my-payment/billing/overview`}
                    />
                  )
                }

                /* for single accounts, redirect user from billing overview to details page */
                const isOverview =
                  window.location.pathname.indexOf(`/overview`) !== -1
                if (isOverview && linkedAccounts.length === 1) {
                  return (
                    <Redirect
                      to={`${languageRoute}/mywm/user/my-payment/billing/details`}
                    />
                  )
                }

                /* logged-in users visiting view-pickup-eta page should be re-directed to my-services dashboard */
                const isViewETA =
                  window.location.pathname.indexOf(`/view-pickup-eta`) !== -1
                if (isViewETA) {
                  return (
                    <Redirect to={`${languageRoute}/mywm/user/my-services`} />
                  )
                }

                if (getPath) {
                  const path = getPath(reduxState, linkedAccounts.length)
                  if (path) {
                    return <Redirect to={`${languageRoute}${path}`} />
                  }
                }
                /* This will be default navigation to user dashboard if the user manually enters url as '/mywm' */
                const splitPathname = window.location.pathname.split("/")

                const hasRedirect =
                  window.location.search &&
                  !!window.location.search.match(/redirect=/)

                if (
                  splitPathname[splitPathname.length - 1] === "mywm" ||
                  splitPathname[splitPathname.length - 1] === "locate" ||
                  splitPathname[splitPathname.length - 1] === "login"
                ) {
                  if (splitPathname[splitPathname.length - 1] === "login") {
                    if (hasRedirect) {
                      const redirectPathMatch =
                        window.location.search.match(/(redirect=)(\/.+)/)[2]
                      const splitRedirect = redirectPathMatch?.split(/[?,&]/g)

                      // fix bulk pickup redirect, or any other redirect with incorrect query params
                      let formattedRedirect = ""
                      _.forEach(splitRedirect, (string, i) => {
                        let delimiter = ""
                        if (i === 1) {
                          delimiter = "?"
                        } else if (i > 1) {
                          delimiter = "&"
                        }

                        formattedRedirect =
                          formattedRedirect + delimiter + string
                      })

                      return <Redirect to={formattedRedirect} />
                    }

                    const path =
                      redirectPath || `${languageRoute}/mywm/user/dashboard`
                    return <Redirect to={path} />
                  }

                  return (
                    <Redirect to={`${languageRoute}/mywm/user/dashboard`} />
                  )
                }
              }
              return <L {...cProps}>{innerComponent}</L>
            }
            if (userState === userType.LOGGING_OUT) {
              /* User chose to log out, so redirect user to home page instead of the login page. */
              window.location = `/`
            } else if (userState === userType.COOKIED) {
              /* if the query params contain an action for service change or add service, skip to the section below */
              const queryStrings = parse(window.location.search)
              const action = _.get(queryStrings, `action`, ``)
              const pathnameWithoutTrailingSlash = window.location.pathname
                .replace(languageRoute, `$LANGUAGE_PATH`)
                .replace(/\/$/, ``)

              /* remove trailing slash so comparison passes */
              const isRedirectExempt = _.includes(
                _.values(EXEMPT_ROUTE_MAPPING),
                pathnameWithoutTrailingSlash,
              )

              /* this is temporary until we re-enable the service dashboard level history page */
              if (
                REDIRECT_TO_COMBINED_ORDER_HISTORY &&
                pathnameWithoutTrailingSlash === SERVICE_ORDER_HISTORY
              ) {
                const redirect = COMBINED_ORDER_HISTORY.replace(
                  languageRoute,
                  `$LANGUAGE_PATH`,
                )
                return <Redirect to={redirect} />
              }

              /* we also want to skip this condition for certain routes, for example notifications center */
              if (
                !isRedirectExempt &&
                !_.includes(_.values(ACTION_MAPPING), action)
              ) {
                /* Navigate guest users to the guest My WM Dashboard page (/ca/{lang}/mywm) */
                return <Redirect to={`${languageRoute}/mywm`} />
              }
            }
            /* Not logged in or logging out, go to login page. */
            return (
              <Redirect
                to={`${languageRoute}/user/login?redirect=${props.location.pathname}${props.location.search}`}
              />
            )
          }}
        />
      </div>
    )
  }
}

AuthenticatedRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
  secondaryLayout: PropTypes.func,
  props: PropTypes.object.isRequired,
  getPath: PropTypes.func,
  requireLinkedAccounts: PropTypes.bool,
  isBrokerAccount: PropTypes.bool.isRequired,
  redirectPath: PropTypes.string,
  reduxActions: PropTypes.shape({
    getLinkedAccounts: PropTypes.func.isRequired,
  }).isRequired,
  reduxState: PropTypes.shape({
    userAccount: PropTypes.object.isRequired,
    userManageAccount: PropTypes.object.isRequired,
  }).isRequired,
}

AuthenticatedRoute.defaultProps = {
  requireLinkedAccounts: true,
  location: {},
  secondaryLayout: undefined,
  getPath: _.noop,
  redirectPath: ``,
}

/* Convert AuthenticationRoute to be a container. */

export const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getLinkedAccounts: actions.getLinkedAccounts,
    },
    dispatch,
  ),
})

export const mapStateToProps = (state) => ({
  isBrokerAccount: isBrokerAccountSelector(state),
  reduxState: {
    userAccount: state.userAccount, // TODO instead of passing in as cProp from App.jsx, use it from here
    userManageAccount: state.userManageAccount,
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute)
