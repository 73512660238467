import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import {
  GET_GLOBAL_ALERTS_PENDING,
  GET_GLOBAL_ALERTS_FAILED,
  GET_GLOBAL_ALERTS_FULFILLED,
} from "constants-lib/actionTypes"

function handlePending() {
  return {
    type: GET_GLOBAL_ALERTS_PENDING,
  }
}

function handleFailed() {
  return {
    type: GET_GLOBAL_ALERTS_FAILED,
  }
}

function handleFulfilled(globalAlerts) {
  return {
    type: GET_GLOBAL_ALERTS_FULFILLED,
    payload: {
      globalAlerts,
    },
  }
}

/**
 * It gets the global alerts from the API and dispatches the results to the redux store
 * @returns An object with a function that returns a function that returns a promise.
 */
export const getGlobalAlerts = () => (dispatch, getState) => {
  dispatch(handlePending(GET_GLOBAL_ALERTS_PENDING))

  const api = `GLOBAL_ALERTS`
  const apiKey = get(urls, `apiKey.GUEST.ALERTS`, ``)

  const template = URITemplate(urls.url[api])

  const url = template.expand({
    lang: get(getState(), `siteLanguage.language`, ``),
  })

  const config = {
    headers: {
      apiKey,
    },
  }

  return axios
    .create(config)
    .get(url)
    .then((response) => {
      const alerts = get(response, `data.data.alerts`, [])

      dispatch(handleFulfilled(alerts))
      return response
    })
    .catch(() => {
      dispatch(handleFailed(GET_GLOBAL_ALERTS_FAILED))
    })
}
