import { getAdobeLaunchClassName } from "utils-lib/analytics"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

/**
 * Simulates an analytics click event.
 * @function
 * @param {Object} options - The options object.
 * @param {string} options.analytics_key - The analytics key to track.
 * @param {string} options.dummyClass - The dummy class name to use for the fake button element.
 * @param {function} [options.cb=() => {}] - The callback function to execute after the click event.
 * @returns {number|null} Returns a timeout ID or null if no analytics_key is provided.
 */
export const fakeAnalyticsClick = ({
  analytics_key,
  dummyClass,
  setAnalyticsFlag,
  cb = () => {},
}) => {
  if (!analytics_key) return null

  const attrs = {
    id: `fake-analytics-${dummyClass}`,
    analytics: analytics_key,
    class: `${getAdobeLaunchClassName({
      disabled: false,
      isSCA: false,
    })} ${dummyClass}`,
    tabindex: -1,
    style: "visibility: hidden;",
    "aria-disabled": "true",
  }

  const fakeButtonEl = window.document.createElement("div")

  Object.keys(attrs).forEach((key) =>
    fakeButtonEl.setAttribute(key, attrs[key]),
  )

  return setTimeout(() => {
    window.document.body.appendChild(fakeButtonEl)
    fakeButtonEl.click()
    /* uncomment console log to track passed analytics values, and data layer snapshot */
    // console.log(`analytics click`, { analytics_key, fakeButtonEl, dl: window._dl.clicks })
    setAnalyticsFlag ? setAnalyticsFlag(true) : null
    fakeButtonEl.remove()
    cb()
  }, 100)
}
