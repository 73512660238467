import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"

export const initialState = {
  fetchStatus: ``,
  isScheduledLoaded: false,
  isPaymentHistoryLoaded: false,
  isAllScheduledLoaded: false,
  paymentHistory: [],
  allScheduleHistory: [],
  userSelectedSchedulePayment: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_PAYMENT_HISTORY_PENDING:
    case ActionTypes.GET_SCHEDULED_PAYMENT_PENDING:
    case ActionTypes.GET_ALL_SCHEDULED_PAYMENT_PENDING:
      return {
        ...state,
        fetchStatus: `pending`,
      }

    case ActionTypes.GET_PAYMENT_HISTORY_FAILED:
    case ActionTypes.GET_SCHEDULED_PAYMENT_FAILED:
    case ActionTypes.GET_ALL_SCHEDULED_PAYMENT_FAILED:
      return {
        ...state,
        fetchStatus: `failed`,
      }

    case ActionTypes.GET_PAYMENT_HISTORY_FULFILLED:
      const combinedPaymentHistory = [
        ...state.paymentHistory,
        ...action.payload.paymentHistory,
      ]
      const distinctPaymentHistory = _.uniqWith(
        combinedPaymentHistory,
        _.isEqual,
      )
      return {
        ...state,
        fetchStatus: `fulfilled`,
        isPaymentHistoryLoaded: true,
        paymentHistory: distinctPaymentHistory,
      }

    case ActionTypes.GET_SCHEDULED_PAYMENT_FULFILLED:
      return {
        ...state,
        fetchStatus: `fulfilled`,
        isScheduledLoaded: true,
        paymentHistory: [
          ...state.paymentHistory,
          ...action.payload.scheduledPayments,
        ],
      }

    case ActionTypes.GET_USER_SELECTED_SCHEDULE_PAYMENTS:
      return {
        ...state,
        userSelectedSchedulePayment: action.payload,
      }

    case ActionTypes.GET_ALL_SCHEDULED_PAYMENT_FULFILLED:
      return {
        ...state,
        fetchStatus: `fulfilled`,
        isAllScheduledLoaded: true,
        allScheduleHistory: _.uniqBy(
          [
            ..._.reverse([
              ...state.allScheduleHistory,
              ...action.payload.scheduledPayments,
            ]),
          ],
          `confirmationNumber`,
        ),
      }

    case ActionTypes.CLEAR_PAYMENT_HISTORY:
      return {
        ...initialState,
        allScheduleHistory: state.allScheduleHistory,
      }

    case ActionTypes.RESET_USER_ACCOUNT:
      return initialState

    default:
      return state
  }
}
