import { makeStyles } from "tss-react/mui"
import PropTypes from "prop-types"

import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { wmColor } from "themes-lib/wmTheme"
import { handleEnterPress } from "utils-lib/handleEnterPress"
import { KEY_CODE_MAPPING } from "constants-lib/keyCodes"

const useStyle = makeStyles()({
  formControl: {
    color: wmColor.gray4,
  },
})

// eslint-disable-next-line react/prop-types
const LabeledCheckbox = ({
  disabled,
  onChange,
  hideLabel,
  className,
  listItem: { value, label, key },
}) => {
  const { classes } = useStyle()
  const checkBox = (
    <Checkbox
      onChange={(e) => onChange({ value: e?.target?.checked, label, key })}
      checked={value}
      disabled={disabled}
      className="LabeledCheckbox__checkbox"
    />
  )

  const handleKeyPress = (e) => {
    if (
      e.keyCode === KEY_CODE_MAPPING.ENTER ||
      e.keyCode === KEY_CODE_MAPPING.SPACE_BAR
    ) {
      e.preventDefault()
      handleEnterPress(e, onChange({ value: !value, label, key }))
    }
  }

  return (
    <FormControlLabel
      className={`LabeledCheckbox ${className}`}
      tabIndex="0"
      onKeyDown={handleKeyPress}
      control={checkBox}
      label={
        !hideLabel && (
          <span
            className={`LabeledCheckbox__label ${classes.formControl}`}
            data-testid="LabeledCheckbox"
          >
            {label}
          </span>
        )
      }
    />
  )
}

LabeledCheckbox.propTypes = {
  listItem: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.bool,
    key: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
}

LabeledCheckbox.defaultProps = {
  disabled: false,
  onChange: () => {},
  hideLabel: false,
  className: ``,
}

export default LabeledCheckbox
