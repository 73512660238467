import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { urls } from "utils-lib/builds"

// Action creators
export const pendingAC = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_VERIFICATION_PENDING,
  payload: data,
})

export const failedAC = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_VERIFICATION_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_VERIFICATION_FULFILLED,
  payload: data,
})

export const clearPaymentInfoState = () => ({
  type: ActionTypes.RESET_MY_WM_PAYMENT_VERIFICATION,
})

export const getPaymentInfoVerification =
  ({ customerId, postalcode, lang, isSMSFlow = false }) =>
  (dispatch) => {
    const api = `CUSTOMER_VERIFICATION`
    const template = URITemplate(urls.url[api])

    const paymentInfoVerification = template.expand({
      customerId,
    })

    dispatch(
      pendingAC({
        isSMSFlow, // do this to prevent SMS data from being cleared
        customerId,
        postalcode,
      }),
    )

    return axios
      .create({
        headers: {
          apiKey: _.get(urls, `apiKey.USER[${api}]`, ``),
        },
        params: {
          billingPostalCode: postalcode,
          lang,
        },
      })
      .get(paymentInfoVerification)
      .then((response) => {
        const statusCode = _.get(response, `status`, ``)
        const verificationToken = _.get(response, `headers.token`, ``)
        dispatch(
          fulfilledAC({
            isSMSFlow, // do this to prevent SMS data from being cleared
            verificationDetails: response.data,
            verificationToken,
            statusCode,
          }),
        )

        return response
      })
      .catch((response) => {
        const statusCode = _.get(response, `response.status`, ``)
        let errorMessage = _.get(
          response,
          `response.data.verificationErrorMsg`,
          ``,
        )
        if (errorMessage === `` && statusCode === 400) {
          errorMessage = _.get(response, `response.data`, ``)
        }
        const verificationDetails = _.get(response, `response.data`)
        dispatch(
          failedAC({
            statusCode,
            errorMessage,
            verificationDetails,
          }),
        )
      })
  }
