import { urls } from "utils-lib/builds"
import URITemplate from "urijs/src/URITemplate"
import { MOCK_COMMUNICATION_PREFERENCES_GET } from "utils-lib/mocks"
import axios from "axios"
import { mapToCamelFromSnake } from "utils-lib/objects"
import { getTokenAction } from "actions-lib/getToken"
import { generateUniqueID } from "utils-lib/requests"
import { AuthoringUtils } from "@adobe/aem-spa-page-model-manager"
import {
  GET_NOTIFICATION_PREFERENCES_PENDING,
  GET_NOTIFICATION_PREFERENCES_FAILED,
  GET_NOTIFICATION_PREFERENCES_FULFILLED,
  CLEAR_GET_NOTIFICATION_PREFERENCES_STATUS,
} from "constants-lib/actionTypes"
import { addCustomProperties } from "./utils"

const pendingAC = (isAfterPut) => ({
  type: GET_NOTIFICATION_PREFERENCES_PENDING,
  payload: isAfterPut || false,
})

const failedAC = () => ({
  type: GET_NOTIFICATION_PREFERENCES_FAILED,
})

const fulfilledAC = (data) => ({
  type: GET_NOTIFICATION_PREFERENCES_FULFILLED,
  payload: data,
})

export const clearGetNotificationPreferencesStatus = () => ({
  type: CLEAR_GET_NOTIFICATION_PREFERENCES_STATUS,
})

/**
 * Fetches the notification preferences for a given customer and user, optionally after a PUT operation.
 * @param {Object} params - The parameters for fetching notification preferences.
 * @param {string} params.customerId - The ID of the customer whose notification preferences are to be fetched.
 * @param {string} params.userId - The ID of the user requesting the notification preferences.
 * @param {boolean} [params.isAfterPut=false] - Flag indicating whether the request is made after a PUT operation.
 * @returns {Promise<Object>} A promise that resolves to the notification preferences object or rejects with an error.
 */
export const getNotificationPreferences =
  ({ customerId, userId, isAfterPut = false }) =>
  async (dispatch, getState) => {
    const api = `COMMUNICATION_PREFERENCES_GET`
    const {
      userManageAccount: { linkedAccounts },
    } = getState()

    const template = URITemplate(
      urls.url[api],
      // `${origin.MULESOFT}/wm-exp-contacts-v1-cloudapi-dev/customers/{customerId}/contacts/communication-preferences?user_id={userId}`
    )

    const url = template.expand({
      customerId: customerId.replace(/^0*/g, ``), // API requires removal of leading zeroes
      user_id: userId,
    })

    const token = await getTokenAction(dispatch, getState)

    const config = {
      headers: {
        "Request-Tracking-Id": generateUniqueID(),
        Authorization: `Bearer ${token.accessToken}`,
      },
    }

    const selectedAccount = linkedAccounts?.find(
      (account) => account.custAccountId === customerId,
    )

    const serviceCountry = selectedAccount?.serviceAddress?.country || ``

    const isCanadian = serviceCountry.toUpperCase() === `CA`

    const mapToNotificationPreferences = (data) => {
      const mappedResponseData = mapToCamelFromSnake(data)
      return {
        ...mappedResponseData,
        contacts: addCustomProperties(mappedResponseData.contacts),
        isCanadian,
      }
    }

    dispatch(pendingAC(isAfterPut))
    try {
      let response
      if (AuthoringUtils.isInEditor() || MOCK_COMMUNICATION_PREFERENCES_GET) {
        // importing lazily since this file will only be used in dev or the
        //  AEM editor
        const importedMockFile = await import(
          `./mocks/getNotificationPreferencesResponse.js`
        )
        response = importedMockFile.default
      } else {
        response = await axios.get(url, config)
      }

      const notificationPreferences = mapToNotificationPreferences(
        response.data,
      )
      dispatch(fulfilledAC(notificationPreferences))
      return notificationPreferences
    } catch (e) {
      dispatch(failedAC())
      // throw new Error(e)

      return e
    }
  }
