import { BOOTSTRAP_GRID_SM_MAX } from "constants-lib/dimensions"
import find from "lodash/find"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

/**
 * Returns the width of the window.
 * @function
 * @returns {number} The width of the window.
 */
export const getWindowWidth = () =>
  Math.max(window.document.documentElement.clientWidth, window.innerWidth || 0)

/**
 * Returns the height of the window.
 * @function
 * @returns {number} The height of the window.
 */
export const getWindowHeight = () =>
  Math.max(
    window.document.documentElement.clientHeight,
    window.innerHeight || 0,
  )

/**
 * Determines whether the current device is a desktop.
 * @function
 * @returns {boolean} Whether the current device is a desktop.
 */
export const isDesktop = () => {
  const windowWidth = getWindowWidth()
  return windowWidth > BOOTSTRAP_GRID_SM_MAX
}

/**
 * Determines whether a media query matches the current state of the device.
 * @function
 * @param {string} mediaQuery - The media query to test.
 * @returns {boolean} Whether the media query matches.
 */
export const isMediaMatch = (mediaQuery) =>
  window.matchMedia(mediaQuery).matches

/**
 * Finds the first object in an array of objects that has a media query that matches the current state of the device.
 * @function
 * @param {Object[]} queryObjects - An array of objects with a `mediaQuery` property.
 * @returns {Object|undefined} The first object with a matching media query, or undefined if no match is found.
 */
export const firstToMatch = (queryObjects) =>
  find(queryObjects, (u) => isMediaMatch(u.mediaQuery))
