import * as ActionTypes from "constants-lib/actionTypes"
import { CLEAR_RO_SUCCESS_DATA } from "constants-lib/actionTypes"

const initialState = {
  setRollOffRequestState: ``,
  showSuccessMessage: false,
  updateRollOffRequestState: ``,
  availableDates: [],
  getAvailableDatesState: ``,
  duplicateRollOffData: {},
  roSuccessData: {},
  roPricingData: {},
  getROPricingState: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.SET_ROLL_OFF_SERVICE_FAILED:
      return {
        ...state,
        setRollOffRequestState: `failed`,
      }

    case ActionTypes.SET_ROLL_OFF_SERVICE_FULFILLED:
      return {
        ...state,
        showSuccessMessage: true,
        setRollOffRequestState: `fulfilled`,
        roSuccessData: action.payload,
      }

    case ActionTypes.SET_ROLL_OFF_SERVICE_PENDING:
      return {
        ...state,
        setRollOffRequestState: `pending`,
        duplicateRollOffData: {},
      }
    case ActionTypes.SET_DUPLICATE_ROLL_OFF_REQUEST:
      return {
        ...state,
        duplicateRollOffData: action.payload,
        setRollOffRequestState: `duplicateRequest`,
      }

    case ActionTypes.RESET_DUPLICATE_ROLL_OFF_REQUEST:
      return {
        ...state,
        duplicateRollOffData: {},
        setRollOffRequestState: ``,
        updateRollOffRequestState: ``,
      }

    case ActionTypes.RESET_ROLLOFF_TICKET_DETAILS:
      return {
        ...state,
        duplicateRollOffData: {},
        setRollOffRequestState: ``,
      }

    case ActionTypes.UPDATE_ROLLOFF_SERVICE_REQUEST_FAILED:
      return {
        ...state,
        updateRollOffRequestState: `failed`,
      }

    case ActionTypes.UPDATE_ROLLOFF_SERVICE_REQUEST_FULFILLED:
      return {
        ...state,
        // showSuccessMessage: true,
        updateRollOffRequestState: `fulfilled`,
        setRollOffRequestState: ``,
      }

    case ActionTypes.UPDATE_ROLLOFF_SERVICE_REQUEST_PENDING:
      return {
        ...state,
        updateRollOffRequestState: `pending`,
        duplicateRollOffData: {},
      }

    case ActionTypes.GET_ROLL_OFF_AVAILABLE_DATES_PENDING:
      return {
        ...state,
        getAvailableDatesState: `pending`,
      }
    case ActionTypes.GET_ROLL_OFF_AVAILABLE_DATES_FAILED:
      return {
        ...state,
        getAvailableDatesState: `failed`,
      }
    case ActionTypes.GET_ROLL_OFF_AVAILABLE_DATES_FULFILLED:
      return {
        ...state,
        availableDates: action.payload.dates.AvailableDates || [],
        firstDate: action.payload.dates.from_date,
        lastDate: action.payload.dates.to_date,
        dnrDate: action.payload.dates.dnr_date,
        getAvailableDatesState: `fulfilled`,
      }
    case ActionTypes.GET_ROLLOFF_PRICE_PENDING:
      return {
        ...state,
        getROPricingState: `pending`,
      }
    case ActionTypes.GET_ROLLOFF_PRICE_FAILED:
      return {
        ...state,
        getROPricingState: `failed`,
      }
    case ActionTypes.GET_ROLLOFF_PRICE_FULFILLED:
      return {
        ...state,
        roPricingData: action.payload,
        getROPricingState: `fulfilled`,
      }
    case ActionTypes.SET_ROLLOFF_PRICE_DATA:
      return {
        ...state,
        roPricingData: action.payload,
      }
    case CLEAR_RO_SUCCESS_DATA:
      return {
        ...state,
        roSuccessData: {},
      }

    default:
      return state
  }
}
