export const localStorageKey = {
  LANGUAGE: `language`,
  TOKEN: `token`,
  USER_STATE: `userState`,
  USER_DETAILS: `userDetails`,
  IS_CHAT_WINDOW_OPEN: `isChatbotWindowOpen`,
  SCA_VALUES: `scaValues`,
  SNAPSHOT_INCIDENT_DATE: `snapshotIncidentDate`,
  SNAPSHOT_PUBLISHED_DATE: `snapshotPublishedDate`,
  SERVICE_CHANGE_ENTRY_POINT: `serviceChangeEntryPoint`,
  SERVICE_HISTORY_CUSTOMER_ID: `serviceHistoryCustomerId`,
  SERVICE_HISTORY_SERVICE_ID: `serviceHistoryServiceId`,
  CHATBOT_STORE: `redux-chatbot`,
  RO_CONFIRMATION_PAGE: `roConfirmation`,
  ADD_WASTE_TRACKER: `addWasteTracker`,
  SKIP_INIT: `skipInit`,
  DL_PAGE_NAME: `pagename`,
  DL_UI_ELEMENT: `ui_element`,
  DL_OBJECT_CONTENT: `object_content`,
  EMAIL_ID: `email`,
  IS_UNSUBSCRIBED: `isUnsubscribed`,
  APPLICATION_USER_STATE: `APPLICATION_USER_STATE`,
  SHOW_SERVICE_DROP_DOWN: `showServiceDropdown`,
}
