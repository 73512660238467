/**
 * This module exports a function that transforms a model object by recursively checking its properties.
 * @module transformModels
 */

import toPairs from "lodash/toPairs"
import forEach from "lodash/forEach"
import isBoolean from "lodash/isBoolean"
import isArray from "lodash/isArray"
import isObject from "lodash/isObject"
import isEmpty from "lodash/isEmpty"
import { Constants } from "@adobe/aem-spa-page-model-manager"

/**
 * Stringifies a boolean value.
 *
 * @param {boolean} bool - The boolean value to be stringified.
 * @returns {string} - The stringified boolean value.
 */
const stringifyBoolean = (bool) => JSON.stringify(bool)

/**
 * Parses a string and returns a boolean value.
 *
 * @param {string} string - The string to be parsed.
 * @returns {boolean} - The parsed boolean value.
 */
const parseBoolean = (string) => JSON.parse(string)

/**
 * Recursively checks the properties of an object and updates boolean values to strings or vice versa based on the `reverse` flag.
 *
 * @param {Object} props - The object whose properties need to be checked.
 * @param {boolean} reverse - If `true`, stringified boolean values will be updated to boolean values. If `false`, boolean values will be updated to string values.
 */
const recursivelyCheckProps = (props, reverse) => {
  const iterable = toPairs(props)
  forEach(iterable, ([prop, value]) => {
    if (isBoolean(value)) {
      // at the bottom level, check for booleans and update to strings so the AEM ResponsiveGrid component parses properly
      // eslint-disable-next-line no-param-reassign
      props[prop] = stringifyBoolean(value)
    } else if (reverse && ["true", "false"].includes(value)) {
      // at the bottom level, check for stringified booleans and update to booleans so component logic functions properly
      // eslint-disable-next-line no-param-reassign
      props[prop] = parseBoolean(value)
    } else if (isArray(value)) {
      forEach(value, (prop) => recursivelyCheckProps(prop, reverse))
    } else if (isObject(value)) {
      recursivelyCheckProps(prop, reverse)
    }
  })
}

/**
 * Transforms a model object by recursively checking its properties.
 *
 * @function
 * @param {Object} model - The model object to transform.
 * @param {boolean} [reverse=false] - Whether to reverse the transformation.
 * @returns {Object} The transformed model object.
 */
export const transformModelWithStrings = (model, reverse = false) => {
  const newModel = { ...model }

  if (!reverse) {
    // Items (components) can either be found at the root or nested responsive grid levels
    let items = newModel.cqItems?.root?.[Constants.ITEMS_PROP]
    if (items && !isEmpty(items.responsivegrid)) {
      items = items.responsivegrid[Constants.ITEMS_PROP]
    }

    // eslint-disable-next-line no-unused-vars
    forEach(toPairs(items), ([component, props]) => {
      recursivelyCheckProps(props, reverse)
    })
  } else {
    recursivelyCheckProps(newModel, reverse)
  }

  return newModel
}
