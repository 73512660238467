import * as ActionTypes from "constants-lib/actionTypes"

export const initialState = {
  eligibilityStatusCode: ``,
  dateStatusCode: ``,
  submitStatusCode: ``,
  getRecyclingPickupEligibilityStatus: ``,
  getRecyclingPickupDateStatus: ``,
  getRecyclingPickupSubmitStatus: ``,
  recylingPickupEligiblity: {},
  recyclingPickupDate: {},
  recylingPickupSubmit: {},
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_RECYLING_PICKUP_ELIGIBILITY_PENDING:
      return {
        ...state,
        eligibilityStatusCode: ``,
        getRecyclingPickupEligibilityStatus: `pending`,
        recylingPickupEligiblity: {},
      }

    case ActionTypes.GET_RECYLING_PICKUP_ELIGIBILITY_FAILED:
      return {
        ...state,
        eligibilityStatusCode: `404`,
        getRecyclingPickupEligibilityStatus: `failed`,
        recylingPickupEligiblity: {},
      }

    case ActionTypes.GET_RECYLING_PICKUP_ELIGIBILITY_FULFILLED:
      return {
        ...state,
        eligibilityStatusCode: action?.payload?.statusCode,
        getRecyclingPickupEligibilityStatus: `fulfilled`,
        recylingPickupEligiblity: { ...action.payload.data.data.data },
      }

    // Recyling Pickup Date
    case ActionTypes.GET_RECYLING_PICKUP_DATE_PENDING:
      return {
        ...state,
        dateStatusCode: ``,
        getRecyclingPickupDateStatus: `pending`,
        recyclingPickupDate: {},
      }

    case ActionTypes.GET_RECYLING_PICKUP_DATE_FAILED:
      return {
        ...state,
        dateStatusCode: `404`,
        getRecyclingPickupDateStatus: `failed`,
        recyclingPickupDate: {},
      }

    case ActionTypes.GET_RECYLING_PICKUP_DATE_FULFILLED:
      return {
        ...state,
        dateStatusCode: action?.payload?.statusCode,
        recyclingPickupDate: { ...action.payload.data.data.data },
        getRecyclingPickupDateStatus: `fulfilled`,
      }

    // Recyling Pickup Submit
    case ActionTypes.GET_RECYLING_PICKUP_SUBMIT_PENDING:
      return {
        ...state,
        submitStatusCode: ``,
        getRecyclingPickupSubmitStatus: `pending`,
        recylingPickupSubmit: {},
      }

    case ActionTypes.GET_RECYLING_PICKUP_SUBMIT_FAILED:
      return {
        ...state,
        submitStatusCode: `404`,
        getRecyclingPickupSubmitStatus: `failed`,
        recylingPickupSubmit: {},
      }

    case ActionTypes.GET_RECYLING_PICKUP_SUBMIT_FULFILLED:
      return {
        ...state,
        submitStatusCode: action?.payload?.statusCode,
        getRecyclingPickupSubmitStatus: `fulfilled`,
        recylingPickupSubmit: { ...action.payload.data.data },
      }

    default:
      return state
  }
}
