import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import { urls } from "utils-lib/builds"
import { MOCK_TEXT_FOR_CITY_BILLED_BULK_PICKUP_INFORMATION } from "utils-lib/mocks"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export const pendingAC = () => ({
  type: ActionTypes.GET_TEXT_FOR_CITY_BILLED_BULK_PENDING,
})

export const failedAC = () => ({
  type: ActionTypes.GET_TEXT_FOR_CITY_BILLED_BULK_FAILED,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_TEXT_FOR_CITY_BILLED_BULK_FULFILLED,
  payload: data,
})

/**
 * Fetches text details for city-billed bulk services based on the provided ID.
 * @param {string} id - The unique identifier for the city-billed bulk service.
 * @returns {Promise<Object>} A promise that resolves with the service text details or an error object.
 */
export const getTextForCityBilledBulkDetails = (id) => (dispatch) => {
  const api = `TEXT_FOR_CITY_BILLED_BULK`
  const template = URITemplate(urls.url[api])

  const url = MOCK_TEXT_FOR_CITY_BILLED_BULK_PICKUP_INFORMATION
    ? `${window.origin}/mock/text-for-city-billed-bulk-pickup-response.json`
    : template.expand({
        id,
      })

  dispatch(pendingAC())

  return axios
    .create({
      headers: {
        apiKey: get(urls, `apiKey.GUEST[${api}]`, ``),
      },
    })
    .get(url)
    .then((response) => {
      dispatch(fulfilledAC(response.data.data))

      return response.data.data
    })
    .catch((error) => {
      const statusCode = get(error, `response.status`, ``)
      const errorMessage = get(error, `response.data.data.Error`, ``)
      dispatch(
        failedAC({
          statusCode,
          errorMessage,
        }),
      )

      return { errorCode: statusCode }
    })
}
