import isString from "lodash/isString"

/**
 * Returns a padded version of the customer ID by adding leading zeros.
 *
 * The function is named getPaddedCustomerId. It takes a customer ID as an
 * argument and returns a padded version of the customer ID.
 *
 * @param {string} [customerId=""] - The customer ID to be padded.
 * @returns {string} A padded version of the customer ID.
 */
export const getPaddedCustomerId = (customerId = ``) => {
  if (!isString(customerId) || !customerId) {
    return ``
  }

  const rawCustomerId = customerId.replace(/-/g, ``)
  return `000000000000000${rawCustomerId}`.slice(-15)
}

/**
 * Returns a trimmed version of the customer ID by removing leading zeros and dashes.
 *
 * The function is named getTrimmedCustomerId. It takes a customer ID as an
 * argument and returns a trimmed version of the customer ID.
 *
 * @param {string} [customerId=""] - The customer ID to be trimmed.
 * @returns {string} A trimmed version of the customer ID.
 */
export const getTrimmedCustomerId = (customerId = ``) =>
  customerId.replace(/^(0|-)+/, ``)
