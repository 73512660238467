import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  SUBMIT_SERVICE_CHANGE_PRICE_PENDING,
  SUBMIT_SERVICE_CHANGE_PRICE_FAILED,
  SUBMIT_SERVICE_CHANGE_PRICE_FULFILLED,
} from "constants-lib/actionTypes"
import { getTokenAction, clearDataCache } from "actions"
import { urls } from "utils-lib/builds"
import { CACHE_KEYS } from "constants-lib/dataCache"

export const pendingAC = () => ({
  type: SUBMIT_SERVICE_CHANGE_PRICE_PENDING,
})

export const failedAC = () => ({
  type: SUBMIT_SERVICE_CHANGE_PRICE_FAILED,
})

export const fulfilledAC = (data) => ({
  type: SUBMIT_SERVICE_CHANGE_PRICE_FULFILLED,
  payload: data,
})

export const submitServiceChange =
  ({ customerId, userId, lang, payload }) =>
  (dispatch, getState) => {
    const api = `SERVICE_CHANGE_SUBMIT`
    const template = URITemplate(urls.url[api])

    const url = template.expand({
      customerId,
      userId,
      lang,
    })

    const config = {
      method: `POST`,
      headers: {
        referrerUrl: `${window.location.origin}${window.location.pathname}`,
      },
      data: payload,
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return submitServiceChangeHelper(url, config, dispatch)
    })
  }

export const submitServiceChangeHelper = (url, config, dispatch) => {
  dispatch(pendingAC())
  dispatch(clearDataCache(CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA))

  return axios(url, config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))

      return response.data
    })
    .catch(() => {
      dispatch(failedAC())
    })
}
