/* This action is not used, but could be if customer search API works with userIds. */

import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

// Action creators
export const pendingAC = () => ({
  type: ActionTypes.GET_CUSTOMER_ACCOUNT_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.GET_CUSTOMER_ACCOUNT_FAILED,
  payload: fetchCode,
})

/* Customer is verified if they locate their services by Customer ID. */
const fulfilledAC = (data) => ({
  type: ActionTypes.GET_CUSTOMER_ACCOUNT_FULFILLED,
  payload: data,
})

export const getCustomer = (customerId) => (dispatch, getState) => {
  dispatch(pendingAC())

  const state = getState()

  return getTokenAction(dispatch, getState).then((token) => {
    const api = `CUSTOMER_SEARCH_GUEST`
    return axios
      .get(urls.url[api], {
        headers: {
          token: token.accessToken,
          [urls.okta.tokenFieldKey]: token.accessToken,
          apiKey: _.get(urls, `apiKey.USER[${api}]`, ``),
        },
        params: {
          ezpayId: customerId,
          lang: _.get(state, `siteLanguage.language`, `en_CA`),
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
        },
      })
      .then((response) => {
        dispatch(fulfilledAC(response?.data))
        return response
      })
      .catch((e) => {
        dispatch(failedAC(_.get(e, `response.status`, 403)))
      })
  })
}
