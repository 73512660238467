import get from "lodash/get"
import toString from "lodash/toString"
/* eslint-disable consistent-return */
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { generateUniqueID } from "utils-lib/requests"
import { urls } from "utils-lib/builds"
import getLanguageRoute from "utils-lib/getLanguageRoute"
import {
  GET_PRODUCTS_URL_PENDING,
  GET_PRODUCTS_URL_FAILED,
  GET_PRODUCTS_URL_FULFILLED,
  ADD_REMOVE_CATALOG,
  RESET_CATALOG,
  SELECTED_DELIVERY_DATE,
} from "constants-lib/actionTypes"
import { serviceTypes } from "constants-lib/common"
import { getWindow } from "utils-lib/getWindow"
import {
  getCommercialServletDetails,
  getCloudCrazeServiceDays,
  getCloudCrazeDeliverySchedule,
} from "../getCloudcrazeTokens"
import { getOutages } from "../getOutages"

const window = getWindow()

export const pendingAC = (productType) => ({
  type: GET_PRODUCTS_URL_PENDING,
  productType,
})

export const failedAC = (fetchCode) => ({
  type: GET_PRODUCTS_URL_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: GET_PRODUCTS_URL_FULFILLED,
  payload: data,
})

/**
 * Retrieves the URL for a specific product type.
 * @param {string} productType - The type of product.
 * @param {string} language - The language for the URL.
 * @param {string} cityaAndZipcode - The city and zipcode for the URL.
 * @param {Object} options - Additional options for the URL retrieval.
 * @returns {Promise} A promise that resolves to the retrieved URL.
 */
export const getProductsUrl =
  (productType, language, cityaAndZipcode, options = {}) =>
  (dispatch, getState) => {
    dispatch(pendingAC(productType))
    const { customerSelections } = getState()
    const street =
      `${customerSelections.customerSearchedAddress.streetNumber} ${customerSelections.customerSearchedAddress.streetName}` ||
      ``
    if (street.trim() === ``) {
      dispatch(fulfilledAC({ invalidAddress: true }))
      return
    }

    // getESBTokens()(dispatch, getState).then(() => {
    const returnValue = getCloudcrazeProductsUrl(
      productType,
      dispatch,
      getState,
      cityaAndZipcode,
    ).then((res) => {
      if (res) {
        if (res.url && options.preventRedirectToECommerce !== true) {
          window.open(res.url, `_self`)
        }
        return res
      }
    })
    // })
    return returnValue.then((promiseValue) => promiseValue)
  }

/**
 * Handles the error response from an API request.
 *
 * @param {Object} response - The response object from the API request.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @param {string} productType - The type of product.
 * @returns {Object|null} - The processed data or null.
 */
export const handleErrorResponse = (response, dispatch, productType) => {
  const data = get(response, `data`)

  if (
    (data.catalogs && data.catalogs.length > 0) ||
    productType === serviceTypes.CURB_SIDE
  ) {
    return data
  }

  const customerResults = toString(get(response, `data.customers_results`))
  const zonesResult = toString(get(response, `data.zones_results`))
  const eligibleCustomer = get(response, `data.is_eligible_customer`)
  const existingCustomer = get(response, `data.is_existing_customer`)
  const serviceAvailable = get(response, `data.is_service_available`)
  const pricingAvailable = get(response, `data.is_pricing_available`)
  const productResults = toString(get(response, `data.products_results`))
  const franchiseZones = get(response, `data.is_franchise_zone`, ``)
  const requestQuote = get(response, `data.request_quote_form`, ``)
  const isNonServiceableZone = get(response, `data.is_Non_serviceable_zone`, ``)
  const { pathname } = window.location

  if (existingCustomer === `N` && pricingAvailable === `N`) {
    /*
      We used to have logic to show the service unavailable message to a non-existing user looking
      for roll-off (temporary_bin) at an address that does not have pricing.

      After talking to Chandan, we still want to show them the pricing is unavailable message that
      contains a number for them to call.
    */
    dispatch(fulfilledAC({ pricingUnavailable: true }))
    return { pricingUnavailable: true }
  }

  if (
    existingCustomer === `N` &&
    customerResults === `204` &&
    franchiseZones === `Y`
  ) {
    dispatch(fulfilledAC({ pricingUnavailable: true }))
    return data
  }

  if (
    (customerResults === `204` && franchiseZones === `Y`) ||
    (customerResults === `204` && requestQuote === `Y`)
  ) {
    dispatch(fulfilledAC({ pricingUnavailable: true }))
    return null
  }

  if (existingCustomer === `N` && customerResults === `204`) {
    if (
      pathname.indexOf(`/residential-waste-recycling-pickup`) !== -1 &&
      data?.zones?.[0]?.contract_type_code === 1 &&
      data?.zones?.[0]?.type_indicator_code === 4
    ) {
      dispatch(fulfilledAC({ pricingUnavailable: true }))
      return data
    }

    dispatch(fulfilledAC({ serviceUnavailable: true }))
    return data
  }

  if (
    customerResults === `227` &&
    (pathname.indexOf(`/residential-waste-recycling-pickup`) !== -1 ||
      pathname.indexOf(`./location/`) ||
      pathname.indexOf(`/business-waste-recycling-pickup`) !== -1)
  ) {
    dispatch(fulfilledAC({ pricingUnavailable: true }))
    return data
  }

  if (existingCustomer === `Y` && customerResults === `200`) {
    const result = {
      existingCustomer,
      data,
    }
    if (franchiseZones === `Y`) {
      result.pricingUnavailable = true
    }
    if (eligibleCustomer === `N`) {
      result.isIneligibleCustomer = true
    }
    if (serviceAvailable === `N`) {
      result.serviceUnavailable = true
    }
    if (pricingAvailable === `N`) {
      result.pricingUnavailable = true
    }
    dispatch(fulfilledAC(result))
    return data
  }

  if (franchiseZones === "N" && isNonServiceableZone === "Y") {
    dispatch(fulfilledAC({ serviceUnavailable: true }))
    return data
  }

  if (
    customerResults === `500` ||
    (zonesResult === `500` && existingCustomer === `N`) ||
    productResults === `500` ||
    zonesResult === `500`
  ) {
    dispatch(fulfilledAC({ customerResultFailed: true }))
    return data
  }
  if (existingCustomer === `N` && pricingAvailable === `N`) {
    /*
      We used to have logic to show the service unavailable message to a non-existing user looking
      for roll-off (temporary_bin) at an address that does not have pricing.

      After talking to Chandan, we still want to show them the pricing is unavailable message that
      contains a number for them to call.
    */
    dispatch(fulfilledAC({ pricingUnavailable: true }))
    return { pricingUnavailable: true }
  }
  if (
    (customerResults === `204` && franchiseZones === `Y`) ||
    (customerResults === `204` && requestQuote === `Y`)
  ) {
    dispatch(fulfilledAC({ pricingUnavailable: true }))
    return null
  }
  if (
    (serviceAvailable === `N` && existingCustomer === `N`) ||
    (customerResults === `204` &&
      zonesResult === `204` &&
      existingCustomer === `N`) ||
    zonesResult === `204`
  ) {
    dispatch(fulfilledAC({ serviceUnavailable: true }))
    return data
  }
}

/**
 * Retrieves the Cloudcraze products URL based on the provided parameters.
 *
 * @param {string} productType - The type of product.
 * @param {function} dispatch - The Redux dispatch function.
 * @param {function} getState - The Redux getState function.
 * @param {object} options - Additional options for the URL.
 * @param {string} options.cityValue - The city value.
 * @param {string} options.zipCode - The zip code.
 * @returns {Promise} A promise that resolves to the Cloudcraze products URL.
 */
export const getCloudcrazeProductsUrl = (
  productType,
  dispatch,
  getState,
  { cityValue = "", zipCode = "" } = {},
) => {
  // dispatch(pendingAC(productType))
  const { customerSelections, userAccount, siteLanguage } = getState()
  const zipcode =
    customerSelections.customerSearchedAddress.postalCode || zipCode
  const locale = encodeURI(siteLanguage.language || ``)
  const product = encodeURI(productType)
  const street =
    `${customerSelections.customerSearchedAddress.streetNumber} ${customerSelections.customerSearchedAddress.streetName}` ||
    ``
  const city = encodeURI(
    customerSelections.customerSearchedAddress.city || cityValue,
  )
  const county = encodeURI(
    customerSelections.customerSearchedAddress.county || ``,
  )
  const state = encodeURI(
    customerSelections.customerSearchedAddress.state || ``,
  )
  const country = encodeURI(
    customerSelections.customerSearchedAddress.country || ``,
  )
  const lob = encodeURI(customerSelections.customerLob || ``)
  const fname = encodeURI(userAccount.userDetails.firstName || ``)
  const lname = encodeURI(userAccount.userDetails.lastName || ``)
  const userid = encodeURI(userAccount.userDetails.userId || ``)
  const sessiontoken = encodeURI(userAccount.sessionToken || ``)
  const accessToken = get(userAccount, `token.accessToken`, ``)
  const latitude = encodeURI(
    customerSelections.customerSearchedAddress.lat || ``,
  )
  const longitude = encodeURI(
    customerSelections.customerSearchedAddress.lng || ``,
  )
  const customerFirstName = encodeURI(get(customerSelections, `firstName`, ``))
  // eslint-disable-next-line camelcase
  const google_placeId = encodeURI(
    customerSelections.customerSearchedAddress.gPlaceId || ``,
  )
  const customerLastName = encodeURI(get(customerSelections, `lastName`, ``))
  // eslint-disable-next-line camelcase
  const naics_code = encodeURI(
    get(customerSelections, `businessType.NAICSCode`, null),
  )
  const api =
    productType === serviceTypes.PERMANENT_BIN ||
    productType === serviceTypes.CURB_SIDE
      ? `ONLINE_PRODUCTS`
      : `GET_PRODUCTS`
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    zipcode,
    locale,
    product,
    street,
    city,
    county,
    state,
    country,
    lob,
    fname,
    lname,
    userid,
    sessiontoken,
    accessToken,
    latitude,
    longitude,
    customer_first_name: customerFirstName,
    customer_last_name: customerLastName,
    naics_code,
    refreshToken: `_khEtXW82qn8LZVAzSQD1vnE7fmy86uBD0yBI41i7_k`,
    google_placeId,
  })

  const config = {
    url,
    method: `get`,
    headers: {
      "Content-Type": `application/json`,
      // "Authorization": `Bearer ${get(getState(), `cloudCraze.esbTokens.access_token`, ``)}`,
      clientID: urls.cloudCraze.headers.ClientId,
      "Request-Tracking-Id": generateUniqueID(),
    },
  }

  return axios(config)
    .then((response) => {
      const cloudcrazeUrl = get(
        response,
        `data.wm_metadata.cloudcraze_products_url`,
        "",
      )
      if (cloudcrazeUrl) {
        dispatch(
          fulfilledAC({
            isCloudCrazeUrlValid: true,
          }),
        )
        return { url: cloudcrazeUrl }
      }

      if (productType === serviceTypes.CURB_SIDE && !response?.data?.catalogs) {
        dispatch(
          fulfilledAC({
            renderSupportForm: true,
            businessPickupData: response.data,
          }),
        )
      }

      if (response.data.catalogs && response.data.catalogs.length > 0) {
        dispatch(
          fulfilledAC({
            isCloudCrazeUrlValid: true,
            businessPickupData: response.data,
          }),
        )
        const languageRoute = getLanguageRoute(locale)
        const navURL =
          productType === serviceTypes.PERMANENT_BIN
            ? `business/business-waste-recycling-pickup/permanent-dumpster-sizes`
            : `home/residential-waste-recycling-pickup/residential-services`
        return new Promise((resolve) => {
          const siteId =
            response.data.catalogs?.[0]?.products?.[0]?.site_id ||
            response.data.catalogs?.[0]?.site_id
          dispatch(getOutages(siteId))
          const deliveryDays = () => {
            dispatch(getCloudCrazeDeliverySchedule(productType))
              .then(() => {
                if (
                  response?.data?.catalogs &&
                  response?.data?.catalogs?.length > 0 &&
                  (response?.data?.is_eComm_experience === 1 ||
                    productType === serviceTypes.PERMANENT_BIN)
                ) {
                  resolve({
                    url: `${languageRoute}/${navURL}`,
                  })
                } else {
                  resolve(response?.data)
                }
              })
              .catch(() => {
                resolve({
                  url: `${languageRoute}/${navURL}`,
                })
              })
          }
          const serviceDays = () => {
            dispatch(getCloudCrazeServiceDays())
              .then(deliveryDays)
              .catch(deliveryDays)
          }
          // dispatch(pendingAC(productType))
          dispatch(getCommercialServletDetails(productType))
            .then(
              productType === serviceTypes.PERMANENT_BIN
                ? serviceDays
                : deliveryDays,
            )
            .catch(
              productType === serviceTypes.PERMANENT_BIN
                ? serviceDays
                : deliveryDays,
            )
        })
      }
      return handleErrorResponse(response, dispatch, productType)
    })
    .catch(() => {
      dispatch(failedAC())
      return null
    })
}
export const addOrRemoveService = (catalog) => (dispatch) => {
  dispatch({
    type: ADD_REMOVE_CATALOG,
    catalog,
  })
}

export const resetService = () => (dispatch) => {
  dispatch({
    type: RESET_CATALOG,
    selectedCatalogs: [],
    catalog: [],
  })
}

export const setSelectedDeliveryDate = (date) => (dispatch) => {
  dispatch({
    type: SELECTED_DELIVERY_DATE,
    date,
  })
}
