/**
 * Mocking the window for ssr
 * @function
 * @returns {Object} The global window object or a mock window object for SSR.
 */
export const getWindow = () => {
  if (typeof window !== "undefined") return window

  const ssrDocument = {
    body: {},
    addEventListener() {},
    removeEventListener() {},
    activeElement: {
      blur() {},
      nodeName: "",
    },
    querySelector() {
      return null
    },
    querySelectorAll() {
      return []
    },
    getElementById() {
      return null
    },
    getElementsByClassName() {
      return null
    },
    createEvent() {
      return {
        initEvent() {},
      }
    },
    createElement() {
      return {
        children: [],
        childNodes: [],
        style: {},
        setAttribute() {},
        getElementsByTagName() {
          return []
        },
      }
    },
    createElementNS() {
      return {}
    },
    importNode() {
      return null
    },
    location: {
      hash: "",
      host: "",
      hostname: "",
      href: "",
      origin: "",
      pathname: "",
      protocol: "",
      search: "",
    },
  }

  const ssrWindow = {
    document: ssrDocument,
    screen: {
      width: null,
      querySelector() {
        return null
      },
    },
    navigator: {
      userAgent: "",
      language: "",
    },
    location: {
      hash: "",
      host: "",
      hostname: "",
      href: "",
      origin: "",
      pathname: "",
      protocol: "",
      search: "",
    },
    history: {
      replaceState() {},
      pushState() {},
      go() {},
      back() {},
    },
    matchMedia() {},
    addEventListener() {},
    removeEventListener() {},
    localStorage: {
      getItem() {},
      setItem() {},
    },
    sessionStorage: {
      getItem() {},
      setItem() {},
    },
    crypto: {
      getRandomValues() {},
    },
    google: {
      maps: {
        Size: function Size(width, height) {
          this.width = width
          this.height = height
        },
        Geocoder: function Geocoder() {
          // this.geocode = jest.fn()
        },
        LatLng: function LatLng() {
          // this.LatLng = jest.fn()
        },
        Marker: function Marker() {},
        Map: function Map() {
          this.setMapTypeId = function setMapTypeId() {}
          this.mapTypes = {
            set: function set() {},
          }
          this.setCenter = function setCenter() {}
          this.setZoom = function setZoom() {}
          this.panBy = function panBy() {}
        },
        StyledMapType: function StyledMapType() {},
        places: {
          AutocompleteService: function AutocompleteService() {},
          AutocompleteSessionToken: function AutocompleteSessionToken() {},
          PlacesService: function PlacesService() {},
        },
      },
    },
  }

  return ssrWindow
}
