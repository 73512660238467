import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import _ from "lodash"
import { urls } from "utils-lib/builds"
import { getTokenAction } from "actions"
import { MOCK_BULK_PICKUP_INFORMATION } from "utils-lib/mocks"
import {
  GET_BULK_PICKUP_GUIDELINES_PENDING,
  GET_BULK_PICKUP_GUIDELINES_FAILED,
  GET_BULK_PICKUP_GUIDELINES_FULFILLED,
} from "constants-lib/actionTypes"

export const pendingAC = () => ({
  type: GET_BULK_PICKUP_GUIDELINES_PENDING,
})

export const failedAC = () => ({
  type: GET_BULK_PICKUP_GUIDELINES_FAILED,
})

export const fulfilledAC = (data) => ({
  type: GET_BULK_PICKUP_GUIDELINES_FULFILLED,
  payload: data,
})

export const getBulkPickupGuidelines = (customerId) => (dispatch, getState) => {
  dispatch(pendingAC())

  const api = `BULK_PICKUP_GUIDELINES`
  const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

  const template = URITemplate(urls.url[api])

  const state = getState()

  const url = MOCK_BULK_PICKUP_INFORMATION
    ? `${window.origin}/mock/bulk-pickup-guidelines-response.json`
    : template.expand({
        lang: _.get(state, `siteLanguage.language`, ``),
        userId: _.get(state, `userAccount.userDetails.userId`, ``),
        customerId,
      })

  return getTokenAction(dispatch, getState).then((token) => {
    const config = {
      headers: {
        apiKey,
        oktaToken: token.accessToken,
      },
    }

    return axios
      .create(config)
      .get(url)
      .then((response) => {
        dispatch(fulfilledAC(response.data))
        return response.data
      })
      .catch(() => {
        dispatch(failedAC())
      })
  })
}
