import get from "lodash/get"
import axios from "axios"
import { urls } from "utils-lib/builds"
import {
  RESEND_EMAIL_PENDING,
  RESEND_EMAIL_FULFILLED,
  RESEND_EMAIL_FAILED,
} from "constants-lib/actionTypes"

export const pendingAC = () => ({
  type: RESEND_EMAIL_PENDING,
})

export const failedAC = (errorCode) => ({
  type: RESEND_EMAIL_FAILED,
  payload: errorCode,
})

export const fulfilledAC = () => ({
  type: RESEND_EMAIL_FULFILLED,
})

/**
 * Dispatches an action to resend an email by making a POST request to the server with the provided data.
 * @param {Object} data - The data to be sent to the server in the body of the POST request.
 * @param {Function} dispatch - The Redux dispatch function used to dispatch actions based on the request's outcome.
 * @returns {null} Always returns null, indicating that the return value is not used.
 */
export const resendEmail = (data) => (dispatch /* ,  getState */) => {
  const api = `RESEND_EMAIL`
  const url = urls.url[api]
  dispatch(pendingAC())

  const config = {
    headers: {},
  }

  const apiKey = get(urls, `apiKey.USER[${api}]`, ``)
  if (apiKey !== ``) {
    config.headers.apiKey = apiKey
  }

  axios
    .post(url, data, config)
    .then((response) => {
      dispatch(fulfilledAC())
      return response
    })
    .catch((error) => {
      dispatch(failedAC(get(error, `response.status`, ``)))
    })
  return null
}
