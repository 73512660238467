const exports = {}

/* eslint-disable no-underscore-dangle */
Object.defineProperty(exports, "__esModule", {
  value: true,
})

/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
function _default() {
  self.addEventListener("message", (e) => {
    if (!e) return

    const { workName } = e.data

    Promise.all(
      e.data.work.map((d) =>
        fetch(d[0], d[1]).then((res) => {
          if (res.status.toString() === "200") {
            return res.json()
          }

          return null
        }),
      ),
    )
      .then((responses) => {
        self.postMessage({
          responses,
          workName,
        })
      })
      .catch(() => {
        self.postMessage({
          responses: 500,
          workName,
        })
      })
  })
}

export { _default as default }
