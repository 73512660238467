import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  REPORT_MISSED_PICKUP_PENDING,
  REPORT_MISSED_PICKUP_FAILED,
  REPORT_MISSED_PICKUP_FULFILLED,
} from "constants-lib/actionTypes"
import * as ActionTypes from "constants-lib/actionTypes"
import { MOCK_MISSED_PICKUP_INFORMATION } from "utils-lib/mocks"
import { getTokenAction, clearDataCache } from "actions"
import { urls } from "utils-lib/builds"
import { CACHE_KEYS } from "constants-lib/dataCache"

export const pendingAC = () => ({
  type: REPORT_MISSED_PICKUP_PENDING,
})

export const failedAC = () => ({
  type: REPORT_MISSED_PICKUP_FAILED,
})

export const fulfilledAC = (data) => ({
  type: REPORT_MISSED_PICKUP_FULFILLED,
  payload: data,
})

export const submitMissedPickupRequest =
  ({ customerId, payload }) =>
  (dispatch, getState) => {
    const api = `REPORT_MISSED_PICKUP`
    const template = URITemplate(urls.url[api])

    const state = getState()

    const url = MOCK_MISSED_PICKUP_INFORMATION
      ? `${window.origin}/mock/missed-pickup-response.json`
      : template.expand({
          customerId,
          lang: _.get(state, `siteLanguage.language`, ``),
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
        })

    const config = {
      method: MOCK_MISSED_PICKUP_INFORMATION ? `GET` : `POST`,
      headers: {},
      data: payload,
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return submitMissedPickupRequestHelper(url, config, dispatch)
    })
  }

export const submitMissedPickupRequestHelper = (url, config, dispatch) => {
  dispatch(pendingAC())
  dispatch(clearDataCache(CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA))

  return axios(url, config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))

      return response.data
    })
    .catch((error) => {
      dispatch(failedAC())
      return error.response?.data
    })
}

export const setRequestMissedPickupOrderID = (data) => ({
  type: ActionTypes.SET_REQUEST_MISSED_PICKUP_ORDER_ID,
  payload: data,
})

export const setRequestMissedPickupTrackingID = (data) => ({
  type: ActionTypes.SET_REQUEST_MISSED_PICKUP_TRACKING_ID,
  payload: data,
})

export const setRequestMissedPickupCustomerID = (data) => ({
  type: ActionTypes.SET_REQUEST_MISSED_PICKUP_CUSTOMER_ID,
  payload: data,
})

export const setRequestMissedPickupSuccess = (data) => ({
  type: ActionTypes.SET_REQUEST_MISSED_PICKUP_SUCCESS,
  payload: data,
})

export const setRequestMissedPickupError = (data) => ({
  type: ActionTypes.SET_REQUEST_MISSED_PICKUP_ERROR,
  payload: data,
})
