import isEmpty from "lodash/isEmpty"
import each from "lodash/each"
import isArray from "lodash/isArray"
import get from "lodash/get"
import findIndex from "lodash/findIndex"
import map from "lodash/map"
import uniqBy from "lodash/uniqBy"
import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  getLinkedAccountStatus: ``,
  linkedAccounts: [],
  getUnlinkedAccountStatus: ``,
  unlinkedAccounts: [],
  setLinkAccountState: ``,
  setLinkAccountStateMessage: ``,
  setUnLinkAccountStateMessage: ``,
  updateNickNameState: ``,
  userAccountDetails: [],
  userAccountStatus: ``,
  contactUpdate: false,
  contactUpdateStatus: ``,
  eligibleDashboardTour: [],
  eligibleTours: {
    residential: false,
    commercial: false,
    rolloff: false,
    selector: false,
  },
  actualResponse: {},
}

export default (state = initialState, action = {}) => {
  let dedupedAccounts = []

  switch (action.type) {
    case ActionTypes.GET_LINKED_ACCOUNTS_PENDING:
      return {
        ...state,
        getLinkedAccountStatus: `pending`,
      }

    case ActionTypes.GET_LINKED_ACCOUNTS_FAILED:
      return {
        ...state,
        getLinkedAccountStatus: `failed`,
      }

    case ActionTypes.GET_LINKED_ACCOUNTS_FULFILLED:
      dedupedAccounts = dedupeAndMergeAccounts(dedupedAccounts, action, state)

      return {
        ...state,
        contactUpdate: action.payload.contactUpdate,
        eligibleDashboardTour: action.payload.eligibleDashboardTour,
        eligibleTours: action.payload.eligibleTours,
        linkedAccounts: dedupedAccounts,
        getLinkedAccountStatus: `fulfilled`,
        setLinkAccountState: ``,
        setLinkAccountStateMessage: ``,
        setUnLinkAccountStateMessage: ``,
      }

    case ActionTypes.GET_CUSTOMER_ACCOUNT_BY_EMAIL_PENDING:
      return {
        ...state,
        getUnlinkedAccountStatus: `pending`,
      }

    case ActionTypes.GET_CUSTOMER_ACCOUNT_BY_EMAIL_FAILED:
      return {
        ...state,
        getUnlinkedAccountStatus: `failed`,
      }

    case ActionTypes.GET_CUSTOMER_ACCOUNT_BY_EMAIL_FULFILLED:
      return {
        ...state,
        unlinkedAccounts: action.payload,
        getUnlinkedAccountStatus: `fulfilled`,
      }

    case ActionTypes.SET_LINKED_ACCOUNTS_PENDING:
      return {
        ...state,
        setLinkAccountState: `pending`,
        setUnLinkAccountStateMessage: ``,
        setLinkAccountStateMessage: ``,
        errorMessage: ``,
      }

    case ActionTypes.SET_LINKED_ACCOUNTS_FAILED:
      return {
        ...state,
        setLinkAccountState: `failed`,
        setLinkAccountStateMessage: `failed`,
        errorMessage: action.payload,
      }

    case ActionTypes.SET_LINKED_ACCOUNTS_FULFILLED:
      /* backend is sending inconsistent field names */
      const newAccounts = []
      if (isArray(action.payload.linkedAccount)) {
        each(action.payload.linkedAccount, (linkedAccount) => {
          newAccounts.push({
            ...linkedAccount,
            lob: linkedAccount.Lob,
          })
        })
      } else {
        newAccounts.push({
          ...action.payload.linkedAccount,
          lob: action.payload.linkedAccount.Lob,
        })
      }

      dedupedAccounts = uniqBy(
        [...state.linkedAccounts, ...newAccounts],
        "custAccountId",
      )

      return {
        ...state,
        setLinkAccountState: `fulfilled`,
        setLinkAccountStateMessage: `success`,
        errorMessage: ``,
        linkedAccounts: dedupedAccounts,
        // unlinkedAccounts: [...state.unlinkedAccounts.filter(s => s.custAccountId !== action.payload.custAccountId)],
      }

    case ActionTypes.SET_UNLINKED_ACCOUNTS_PENDING:
      return {
        ...state,
        setUnLinkAccountStateMessage: `pending`,
        setLinkAccountStateMessage: ``,
        errorMessage: ``,
      }

    case ActionTypes.SET_UNLINKED_ACCOUNTS_FAILED:
      return {
        ...state,
        setUnLinkAccountStateMessage: `failed`,
        errorMessage: action.payload,
      }

    case ActionTypes.SET_UNLINKED_ACCOUNTS_FULFILLED:
      dedupedAccounts = uniqBy(
        [
          ...state.linkedAccounts.filter(
            (s) =>
              s.custAccountId !== action.payload.linkedAccount.custAccountId,
          ),
        ],
        "custAccountId",
      )

      return {
        ...state,
        setUnLinkAccountStateMessage: `fulfilled`,
        errorMessage: ``,
        linkedAccounts: dedupedAccounts,
      }

    case ActionTypes.HIDE_MANAGE_ACCOUNT_MESSAGE:
      return {
        ...state,
        setLinkAccountStateMessage: ``,
        setUnLinkAccountStateMessage: ``,
        errorMessage: ``,
      }

    case ActionTypes.UPDATE_LINKED_ACCOUNTS_FULFILLED:
      const clonedLinkedAccounts = getLinkedAccounts(state, action)
      return {
        ...state,
        updateNickNameState: `fulfilled`,
        updateNickNameStateStateMessage: `success`,
        errorMessage: ``,
        linkedAccounts: clonedLinkedAccounts,
      }

    case ActionTypes.GET_USER_PAYMENT_STATUS_PENDING:
      return {
        ...state,
        userAccountStatus: `pending`,
      }

    case ActionTypes.GET_USER_PAYMENT_STATUS_FAILED:
      return {
        ...state,
        userAccountStatus: `failed`,
      }

    case ActionTypes.GET_USER_PAYMENT_STATUS_FULFILLED:
      return {
        ...state,
        userAccountStatus: `fulfilled`,
        userAccountDetails: [...action.payload.accounts],
        contactUpdate: state.contactUpdate,
        actualResponse: action.payload.actualResponse,
      }

    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
    case ActionTypes.RESET_USER_ACCOUNT:
      return {
        ...initialState,
      }

    case ActionTypes.SET_CONTACT_UPDATE_PENDING:
      return {
        ...state,
        contactUpdateStatus: `pending`,
      }

    case ActionTypes.SET_CONTACT_UPDATE_FAILED:
      return {
        ...state,
        contactUpdateStatus: `failed`,
      }

    case ActionTypes.SET_CONTACT_UPDATE_FULFILLED:
      return {
        ...state,
        contactUpdate: false,
        contactUpdateStatus: `fulfilled`,
      }

    case ActionTypes.CLEAR_FIRST_TIME_USER:
      return {
        ...state,
        contactUpdate: !isEmpty(action.payload.eligibleDashboardTour),
        eligibleDashboardTour: action.payload.eligibleDashboardTour,
        eligibleTours: action.payload.eligibleTours,
      }

    case ActionTypes.CLEAR_DASHBOARD_TOUR:
      const eligibleDashboardTour = getEligibleDashboardTour(action, state)

      return {
        ...state,
        eligibleDashboardTour,
      }

    default:
      return state
  }
}

/**
 * Retrieves the eligible dashboard tour based on the provided action and state.
 * @param {Object} action - The action object.
 * @param {Object} state - The state object.
 * @returns {Array} - The filtered eligible dashboard tour.
 */
export const getEligibleDashboardTour = (action, state) =>
  action?.payload !== `selector`
    ? state?.eligibleDashboardTour?.filter((tour) => tour !== action?.payload)
    : state?.eligibleDashboardTour?.filter((tour) => tour === `dashboard`)

/**
 * Retrieves the linked accounts from the state and updates the nickname of the specified account.
 * @param {Object} state - The current state object.
 * @param {Object} action - The action object containing the payload.
 * @returns {Array} - The updated array of linked accounts.
 */
export const getLinkedAccounts = (state, action) => {
  if (!state || !action) {
    return []
  }

  const clonedLinkedAccounts = (state.linkedAccounts || []).map((account) => {
    if (account && account.custAccountId === action.payload?.custAccountId) {
      return { ...account, nickName: action.payload?.nickName }
    }
    return account
  })

  return clonedLinkedAccounts
}

/**
 * Deduplicates and merges the given accounts with the existing accounts in the state.
 * @param {Array} dedupedAccounts - The deduplicated accounts.
 * @param {Object} action - The action object.
 * @param {Object} state - The state object.
 * @returns {Array} - The deduplicated and merged accounts.
 */
export const dedupeAndMergeAccounts = (dedupedAccounts, action, state) => {
  // eslint-disable-next-line no-param-reassign
  dedupedAccounts = uniqBy(
    [...action.payload.linkedAccounts, ...state.linkedAccounts],
    "custAccountId",
  )

  // eslint-disable-next-line no-param-reassign
  dedupedAccounts = map(action.payload.linkedAccounts, (item) => {
    const updateIdx = findIndex(
      dedupedAccounts,
      (uniqAcct) =>
        get(uniqAcct, `custAccountId`, `a`) === get(item, `custAccountId`, `b`),
    )

    if (
      updateIdx > -1 &&
      get(dedupedAccounts, `[${updateIdx}].statusCode`, ``)
    ) {
      return item
    }
    return get(dedupedAccounts, `[${updateIdx}]`, ``)
  })
  return dedupedAccounts
}
