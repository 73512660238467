import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import endsWith from "lodash/endsWith"
import forEach from "lodash/forEach"
import { urls } from "utils-lib/builds"
import { getWindow } from "utils-lib/getWindow"
import { logRocketIdentify } from "../logRocket"
import getLanguageRoute from "../getLanguageRoute"

const window = getWindow()

/**
 * HTTP status codes for user account login.
 * @typedef {Object} HttpStatus
 * @property {number} ACCOUNT_FOUND - The account was found and login was successful.
 * @property {number} ACCOUNT_NOT_FOUND - The account was not found.
 * @property {number} BAD_CREDENTIALS - The provided credentials were incorrect.
 * @property {number} INTERNAL_SERVER_ERROR - An internal server error occurred.
 *                                            This is currently returned when the user's login email does not exist.
 */

/**
 * HTTP status codes for user account login.
 * @type {HttpStatus}
 */
export const httpStatus = {
  ACCOUNT_FOUND: 200,
  ACCOUNT_NOT_FOUND: 204,
  BAD_CREDENTIALS: 401,
  INTERNAL_SERVER_ERROR: 500,
}

/**
 * Object containing login status codes.
 * @typedef {Object} LoginStatusCode
 * @property {number} UNCONFIRMED_EMAIL - The email is not confirmed.
 * @property {number} LOCKED_OUT - The account is locked out.
 * @property {number} BAD_CREDENTIALS - The credentials are invalid.
 * @property {number} ACCOUNT_NOT_FOUND - The account was not found.
 * @property {number} NO_ACCESS_TOKEN - The access token is missing.
 * @property {number} NO_SESSION_TOKEN - The session token is missing.
 * @property {number} GET_WITHOUT_PROMPT_FAIL - Failed to get without prompt.
 * @property {number} UNKNOWN_LOGIN_STATUS - The login status is unknown.
 * @property {number} NO_RESPONSE - There was no response.
 * @property {number} NETWORK_ERROR - There was a network error.
 * @property {number} SUCCESS - The login was successful.
 */

/**
 * Object containing login status codes.
 * @type {LoginStatusCode}
 */
export const loginStatusCode = {
  UNCONFIRMED_EMAIL: 100,
  LOCKED_OUT: 101,
  BAD_CREDENTIALS: 102,
  ACCOUNT_NOT_FOUND: 103,
  NO_ACCESS_TOKEN: 104,
  NO_SESSION_TOKEN: 105,
  GET_WITHOUT_PROMPT_FAIL: 106,
  UNKNOWN_LOGIN_STATUS: 154,
  NO_RESPONSE: 155,
  NETWORK_ERROR: 156,
  SUCCESS: 200,
}

/**
 * Object containing failure login status codes.
 * @typedef {Object} FailureLoginStatus
 * @property {string} STAGED - Unconfirmed email status.
 * @property {string} LOCKED_OUT - Locked out status.
 */

/**
 * Failure login status codes.
 * @type {FailureLoginStatus}
 */
export const failureLoginStatus = {
  STAGED: `UNCONFIRMED_EMAIL`,
  LOCKED_OUT: `LOCKED_OUT`,
}

/**
 * Extracts the session token from the backend authentication response data.
 *
 * @function
 * @param {Object} response - The response object returned from the backend authentication request.
 * @returns {string} The session token extracted from the response data, or an empty string if it cannot be found.
 */
export const getSessionTokenFromData = (response) =>
  get(response, `data.data.sessionToken`, ``)

/**
 * Gets the failure login status code based on the status returned from the backend.
 * If the status is not defined, returns the UNKNOWN_LOGIN_STATUS code.
 *
 * @function
 * @param {number} status - The status code returned from the backend authentication request.
 * @returns {number} The failure login status code.
 */
export const getFailureLoginStatusCode = (status) =>
  loginStatusCode[status] || loginStatusCode.UNKNOWN_LOGIN_STATUS

/**
 * Checks if the backend failure login status is known in our app.
 *
 * @function
 * @param {number} status - The status code returned from the backend authentication request.
 * @returns {boolean} True if the failure login status is known in our app, false otherwise.
 */
export const isKnownFailureLoginStatus = (status) =>
  failureLoginStatus[status] !== undefined

/**
 * Gets the login status code based on the response from the login request.
 *
 * @function
 * @param {Object} response - The response object returned from the login request.
 * @returns {number} The login status code.
 *
 * The function checks the response from the server and returns a login status code.
 * If the response status is ACCOUNT_FOUND, it checks for known failure login statuses.
 * If the session token exists, it returns SUCCESS.
 * If the user is not registered, it returns ACCOUNT_NOT_FOUND.
 * If the response status is unexpected, it returns UNKNOWN_LOGIN_STATUS.
 */
export const getLoginStatus = (response) => {
  const responseHttpStatus = get(response, `status`)
  const responseFailureStatus = get(response, `data.data.status`, ``)

  if (httpStatus.ACCOUNT_FOUND === responseHttpStatus) {
    /* Check for known failure login statuses. */
    if (isKnownFailureLoginStatus(responseFailureStatus)) {
      return getFailureLoginStatusCode(
        failureLoginStatus[responseFailureStatus],
      )
    }

    /* Check if session token exists. */
    if (getSessionTokenFromData(response) === ``) {
      return loginStatusCode.NO_SESSION_TOKEN
    }

    /* Woohoo! Login success! */
    return loginStatusCode.SUCCESS
  }

  /* Check if user is not registered. */
  if (
    httpStatus.ACCOUNT_NOT_FOUND === responseHttpStatus &&
    responseFailureStatus === ``
  ) {
    return loginStatusCode.ACCOUNT_NOT_FOUND
  }

  /* Unexpected response at this point so return unknown login status. */
  return loginStatusCode.UNKNOWN_LOGIN_STATUS
}

/**
 * Returns a login status code based on the type of error encountered during authentication.
 *
 * @function
 * @param {Object} error - The error object that was caught during an API request.
 * It contains information about the error, such as the response status code, request details, and error message.
 * @returns {number} The login status code based on the error object passed as an argument.
 * The possible login status codes are `BAD_CREDENTIALS`, `ACCOUNT_NOT_FOUND`, `UNKNOWN_LOGIN_STATUS`, `NO_RESPONSE`, and `NETWORK_ERROR`.
 *
 * The function checks the error object and returns a login status code based on the type of error encountered during authentication.
 * * If the response status code is `BAD_CREDENTIALS`, it returns `BAD_CREDENTIALS`.
 * * If the response status code is `INTERNAL_SERVER_ERROR`, it returns `ACCOUNT_NOT_FOUND`.
 * * If there is no response, it returns `NO_RESPONSE`.
 * * If there is a network error, it returns `NETWORK_ERROR`.
 * * If the error is unknown, it returns `UNKNOWN_LOGIN_STATUS`.
 */
export const getExceptionLoginStatus = (error) => {
  // console.warn(`Authentication error config:`, error.config)

  if (error.response) {
    /*
      The request was made and the server responded with a status code
      that falls out of the range of 2xx
    */

    // console.warn(`Authentication error response data:`, error.response.data)
    // console.warn(`Authentication error response status:`,
    // error.response.status)
    // console.warn(`Authentication error response headers:`,
    // error.response.headers)

    const { status } = error.response

    /* Check if bad credentials were entered. */
    if (httpStatus.BAD_CREDENTIALS === status) {
      return loginStatusCode.BAD_CREDENTIALS
    }

    /*
      Handle Internal Server Error status. We currently get this when user 
      login email does not exist, so we'll default to ACCOUNT NOT FOUND.
    */
    if (httpStatus.INTERNAL_SERVER_ERROR === status) {
      return loginStatusCode.ACCOUNT_NOT_FOUND
    }

    /* At this point we're not sure of the reason why login failed. */
    return loginStatusCode.UNKNOWN_LOGIN_STATUS
  }

  if (error.request) {
    /*
      The request was made but no response was received
      `error.request` is an instance of XMLHttpRequest in the browser
       and an instance of http.ClientRequest in node.js
    */
    // console.warn(`Authentication error request:`, error.request)
    return loginStatusCode.NO_RESPONSE
  }

  /* Something happened in setting up the request that triggered an error. */
  // console.warn(`Authentication error message:`, error.message)
  return loginStatusCode.NETWORK_ERROR
}

/**
 * @typedef {Object} UserDetails
 * @property {string} userId - The user's ID.
 * @property {string} firstName - The user's first name.
 * @property {string} lastName - The user's last name.
 * @property {string} login - The user's login.
 * @property {string} cookiedLogin - The user's cookied login.
 * @property {string} mobilePhone - The user's mobile phone number.
 * @property {string} primaryPhone - The user's primary phone number.
 * @property {boolean} isUnconfirmed - Indicates whether the user's email is unconfirmed or not.
 * @property {boolean} isFirstTimeUser - Indicates whether the user is a first-time user or not.
 */

/**
 * Extracts user details from the backend authentication response data.
 *
 * @param {Object} response - The response object returned from the backend authentication request.
 * @param {Object} [state={}] - The redux state (optional).
 *
 * @returns {UserDetails} An object containing the user's details.
 *
 * This function checks the server response and extracts the user details from the response data.
 * If the response data contains a `firstLogin` property with a value of `true`,
 * the `isFirstTimeUser` property is set to `true`. If the `isFirstTimeUser` property is not set
 * in the redux state, it is set to the value of the `firstLogin` property in the response data.
 */

export const getUserDetailsFromData = (response, state = {}) => {
  const data = get(response, `data.data`, {})

  const responseIsFirstTime = get(data, `firstLogin`, `false`) === `true`
  const reduxIsfirstTime = get(
    state,
    `userAccount.userDetails.isFirstTimeUser`,
    false,
  )

  return {
    userId: get(data, `id`, ``),
    firstName: get(data, `firstName`, ``),
    lastName: get(data, `lastName`, ``),
    login: get(data, `login`, ``), // TODO: Rename to email.
    cookiedLogin: get(data, `login`, ``), // TODO: Check if needed.
    mobilePhone: get(data, `mobilePhone`, ``),
    primaryPhone: get(data, `primaryPhone`, ``),
    isUnconfirmed: get(data, `emailVerified`, ``) === `N`, // TODO: Check if needed.
    isFirstTimeUser: reduxIsfirstTime || responseIsFirstTime, // else take API response
  }
}

/**
 * Identifies the user with the given email in LogRocket.
 *
 * @function
 * @param {string} email - The user's email address.
 *
 * The function identifies the user with the given email address in LogRocket.
 */
export const logRocketIdentifyFailedLoggedInUser = (email) => {
  logRocketIdentify({
    email,
  })
}

/**
 * Identifies the user in LogRocket using the response from a GraphQL query.
 *
 * @function
 * @param {Object} response - The response object from the GraphQL query.
 *
 * The function identifies the user in LogRocket using the response from a GraphQL query.
 * It extracts the user details from the response object and passes them to the LogRocketIdentify function.
 * The user's name is constructed by concatenating the first and last name properties from the response object.
 */
export const logRocketIdentifyLoggedInUser = (response) => {
  const { firstName, lastName, login } = getUserDetailsFromData(response)

  logRocketIdentify({
    name: `${firstName} ${lastName}`,
    email: login,
  })
}

/**
 * Redirects the user to the page they were trying to access after login if they are already logged in.
 *
 * @function
 * @param {string} locale - The locale of the user.
 * @param {string} redirectUrl - The URL to redirect the user to after login.
 *
 * The function builds the redirect URL based on the following criteria:
 * 1. If a `redirectUrl` was passed in the props, the user is redirected to that URL.
 * 2. If it's a deeplink redirect, the user is redirected to the deeplink after login.
 * 3. For all others, the user is redirected to the user dashboard.
 *
 * The function checks if this is a deeplink redirect with a redirect path in the URL.
 * If the `redirectUrl` is empty and the URL ends with `/my-services`, the user is redirected to `/mywm/user/my-services`.
 * The function then splits the redirect URL into an array of substrings and formats the redirect URL by removing any incorrect query parameters.
 * Finally, the function builds the final URL and redirects the user to that URL.
 */
export const redirectLoggedInUser = (locale, redirectUrl) => {
  const languageRoute = getLanguageRoute(locale)
  // Check if this is a deeplink redirect with a redirect path in the url
  const urlQueryParameters = encodeURI(window.location.search)
  const redirectQueryParameterStart = urlQueryParameters.indexOf(`?redirect=`)
  const isDeepLinkRedirect = urlQueryParameters.indexOf(`/mywm/user`) !== -1
  const hasRedirectUrl = redirectQueryParameterStart !== -1

  let redirectionURL = redirectUrl
  if (isEmpty(redirectUrl) && endsWith(urlQueryParameters, `/my-services`)) {
    /* this is here as a temporary fix to SCA-1163 */
    redirectionURL = `${languageRoute}/mywm/user/my-services`
  }
  let redirectSubstring
  if (redirectionURL !== ``) {
    redirectSubstring = redirectionURL
  } else if (isDeepLinkRedirect && (redirectionURL || hasRedirectUrl)) {
    redirectSubstring = urlQueryParameters.substring(`?redirect=`.length)
  } else {
    redirectSubstring = `${languageRoute}${`/mywm/user/dashboard`}`
  }
  const splitRedirect = redirectSubstring?.split(/[?,&]/g)
  // fix bulk pickup redirect, or any other redirect with incorrect query params
  let formattedRedirect = ""
  forEach(splitRedirect, (string, i) => {
    let delimiter = ""
    if (i === 1) {
      delimiter = "?"
    } else if (i > 1) {
      delimiter = "&"
    }
    formattedRedirect = formattedRedirect + delimiter + string
  })
  /* Build the final url */
  const finalUrl = urls.loginRedirect.replace(
    `__LANGROUTE__`,
    formattedRedirect,
  )
  window.location = finalUrl
}
