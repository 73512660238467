import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import _ from "lodash"
import moment from "moment"
import { urls } from "utils-lib/builds"
import { getTokenAction } from "actions"
import { MOCK_EXTRA_PICKUP_INFORMATION } from "utils-lib/mocks"
import {
  GET_EXTRA_PICKUP_PRICING_PENDING,
  GET_EXTRA_PICKUP_PRICING_FULFILLED,
  GET_EXTRA_PICKUP_PRICING_FAILED,
} from "constants-lib/actionTypes"

export const pendingAC = () => ({
  type: GET_EXTRA_PICKUP_PRICING_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: GET_EXTRA_PICKUP_PRICING_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: GET_EXTRA_PICKUP_PRICING_FULFILLED,
  payload: data,
})

export const submitExtraPickupPricing =
  ({ customerId, serviceId, serviceDescription }) =>
  (dispatch, getState) => {
    dispatch(pendingAC(GET_EXTRA_PICKUP_PRICING_PENDING))

    const api = `EXTRA_PICKUP_PRICING`
    const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

    const template = URITemplate(urls.url[api])

    const state = getState()

    const url = MOCK_EXTRA_PICKUP_INFORMATION
      ? `${window.origin}/mock/extra-pickup-pricing-response.json`
      : template.expand({
          lang: _.get(state, `siteLanguage.language`, ``),
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
          customerId,
        })

    return getTokenAction(dispatch, getState).then((token) =>
      axios(
        url,
        MOCK_EXTRA_PICKUP_INFORMATION
          ? {
              headers: {
                apiKey,
                oktaToken: token.accessToken,
              },
            }
          : {
              method: `POST`,
              headers: {
                apiKey,
                oktaToken: token.accessToken,
              },
              data: {
                serviceId,
                serviceRequestDate: moment()
                  .add(3, `days`)
                  .format(`YYYY-MM-DD`),
                priceType: `basePrice`,
                productDescription: serviceDescription,
              },
            },
      )
        .then((response) => {
          // handle pickup dates
          dispatch(fulfilledAC(response.data))
          return response.data
        })
        .catch((error) => {
          dispatch(failedAC(error.response.status))
          return error.response.status
        }),
    )
  }
