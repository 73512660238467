import PropTypes from "prop-types"
import getLanguageRoute from "utils-lib/getLanguageRoute"
import { userType } from "constants-lib/authentication"
import { Route, Redirect } from "react-router-dom"
import { parse } from "query-string"

const paths = {
  USER_DASHBOARD: `/mywm/user/dashboard`,
  MANAGE_ACCOUNTS: `/mywm/user/accounts`,
}

export const UnauthenticatedRoute = ({
  component: C,
  layout: L,
  secondaryLayout: SL,
  props: cProps,
  redirectPath,
  cookiedRedirectPath,
  ...rest
}) => (
  <div data-testid="UnauthenticatedRoute">
    <Route
      {...rest}
      render={(props) => {
        const shouldUseSecondaryLayout = SL !== undefined
        let innerComponent

        if (shouldUseSecondaryLayout) {
          innerComponent = (
            <SL {...cProps}>
              <C {...props} {...cProps} />
            </SL>
          )
        } else {
          innerComponent = <C {...props} {...cProps} />
        }

        const { locale } = cProps

        const languageRoute = getLanguageRoute(locale)

        if (
          cookiedRedirectPath &&
          cProps.userAccount.userState === userType.COOKIED
        ) {
          return (
            <Redirect
              to={`${languageRoute}${cookiedRedirectPath.replace(
                "___LANGUAGE_ROUTE___",
                languageRoute,
              )}`}
            />
          )
        }

        if (cProps.userAccount.userState !== userType.LOGGED_IN) {
          const hasRedirect =
            window.location.search &&
            !!window.location.search.match(/redirect=/)

          if (hasRedirect) {
            const isBulk = window.location.search?.includes("bulk-pickup")
            const isRolloff =
              window.location.search?.includes("rolloff-request")
            const isXPU = window.location.search?.includes("extra-pickup")
            const isContainerRepair =
              window.location.search?.includes("container-repair")

            // handle setting datalayer for bulk pickup SMS links
            if (isBulk) {
              const search = window.location.search.replace(
                /redirect=.+\?/g,
                "",
              )
              const { id } = parse(search)

              if (id) {
                const { IVRToText9_0 } = window._dl

                if (!IVRToText9_0) {
                  window._dl.IVRToText9_0 = {
                    IVRtoTextBulkPickupURL: window.location.href,
                  }
                } else {
                  window._dl.IVRToText9_0.IVRtoTextBulkPickupURL =
                    window.location.href
                }
              }
            }

            // handle setting datalayer for XPU SMS links
            if (isXPU) {
              const search = window.location.search.replace(
                /redirect=.+\?/g,
                "",
              )
              const { id } = parse(search)

              if (id) {
                const { IVRToText9_0 } = window._dl

                if (!IVRToText9_0) {
                  window._dl.IVRToText9_0 = {
                    IVRtoTextXpuURL: window.location.href,
                  }
                } else {
                  window._dl.IVRToText9_0.IVRtoTextXpuURL = window.location.href
                }
              }
            }

            // handle setting datalayer for rolloff request SMS links
            if (isRolloff) {
              const search = window.location.search.replace(
                /redirect=.+\?/g,
                "",
              )
              const { id } = parse(search)

              if (id) {
                const { IVRToText9_0 } = window._dl

                if (!IVRToText9_0) {
                  window._dl.IVRToText9_0 = {
                    IVRtoTextRolloffURL: window.location.href,
                  }
                } else {
                  window._dl.IVRToText9_0.IVRtoTextRolloffURL =
                    window.location.href
                }
              }
            }

            // handle setting datalayer for container repair request SMS links
            if (isContainerRepair) {
              const search = window.location.search.replace(
                /redirect=.+\?/g,
                "",
              )
              const { id } = parse(search)

              if (id) {
                const { IVRToText9_0 } = window._dl

                if (!IVRToText9_0) {
                  window._dl.IVRToText9_0 = {
                    IVRtoTextContainerRepairURL: window.location.href,
                  }
                } else {
                  window._dl.IVRToText9_0.IVRtoTextContainerRepairURL =
                    window.location.href
                }
              }
            }
          }

          return <L {...cProps}>{innerComponent}</L>
        }

        /* TODO: Check if user has linked accounts. */
        const hasLinkedAccounts = false

        /* Go to dashboard if the user has linked accounts. */
        let path = redirectPath
        if (path === ``) {
          path = hasLinkedAccounts
            ? paths.USER_DASHBOARD
            : paths.MANAGE_ACCOUNTS
        }

        const hasRedirect =
          window.location.search && !!window.location.search.match(/redirect=/)

        /* if there is a redirect, route to it */
        if (hasRedirect) {
          const redirectPath =
            window.location.search.match(/(redirect=)(\/.+)/)[2]
          return <Redirect to={redirectPath} />
        }

        return <Redirect to={`${languageRoute}${path}`} />
      }}
    />
  </div>
)

UnauthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
  redirectPath: PropTypes.string,
  cookiedRedirectPath: PropTypes.string,
  secondaryLayout: PropTypes.func,
  props: PropTypes.object.isRequired,
  redirectCookied: PropTypes.string,
}

UnauthenticatedRoute.defaultProps = {
  redirectPath: ``,
  redirectCookied: ``,
  cookiedRedirectPath: "",
  secondaryLayout: undefined,
}

export default UnauthenticatedRoute
