import axios from "axios"
import {
  GET_GUEST_CONTAINER_REPAIR_DATES_FULFILLED,
  GET_GUEST_CONTAINER_REPAIR_DATES_PENDING,
  GET_GUEST_CONTAINER_REPAIR_DATES_FAILED,
  RESET_GUEST_CONTAINER_REPAIR_DATES,
} from "constants-lib/actionTypes"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: GET_GUEST_CONTAINER_REPAIR_DATES_PENDING,
})

export const failedAC = () => ({
  type: GET_GUEST_CONTAINER_REPAIR_DATES_FAILED,
})

export const fulfilledAC = (data) => ({
  type: GET_GUEST_CONTAINER_REPAIR_DATES_FULFILLED,
  payload: data,
})

export const resetContainerRepairDate = () => ({
  type: RESET_GUEST_CONTAINER_REPAIR_DATES,
})

export const getGuestContainerRepairDates =
  ({ language, customerId, serviceId, eligibilityId, token }) =>
  (dispatch) => {
    dispatch(pendingAC())
    const api = `GUEST_CONTAINER_REPAIR_DATES`
    const apiKey = _.get(urls, `apiKey.GUEST[${api}]`, ``)
    const template = URITemplate(urls.url[api])

    const url = template.expand({
      customerId,
      language,
      serviceId,
      eligibilityId,
    })
    const config = {
      headers: {
        apiKey,
        token,
      },
    }
    return axios
      .get(url, config)
      .then((response) => {
        dispatch(fulfilledAC(response.data))
        return response.data
      })
      .catch(() => {
        dispatch(failedAC())
      })
  }
