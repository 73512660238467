import axios from "axios"
import moment from "moment"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { getDeviceChannel } from "utils-lib/getDeviceChannel"
import { urls } from "utils-lib/builds"
import { getPadCheckingAcc, isNewCanadianAch } from "utils-lib/strings"
import { CURRENCIES, YES } from "constants-lib/common"

export const pendingAC = () => ({
  type: ActionTypes.UPDATE_AUTOPAYMENT_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.UPDATE_AUTOPAYMENT_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.UPDATE_AUTOPAYMENT_FULFILLED,
  payload: data,
})

export const updateAutoPayment =
  (updatedPaymentMethodId) => (dispatch, getState) => {
    const state = getState()
    const {
      customerAutoPayPaperless,
      myPayment,
      userManageAccount,
      userBilling,
      padDataSubmit,
      myPaymentCheckout,
      myPaymentConfirm,
      userPaymentMethods,
    } = state
    const api = `AUTO_PAYMENT`
    dispatch(pendingAC())

    const template = URITemplate(urls.url[api])
    const customerId = _.get(
      customerAutoPayPaperless,
      `autoPaymentAndPaperlessAccount`,
      ``,
    )

    const accountDetails =
      _.find(
        _.get(userManageAccount, `userAccountDetails`, []),
        (account) => account.custAccountId === customerId,
      ) ||
      _.get(userBilling, `brokerAccountBalancesAndDetails[${customerId}]`, {})

    const isCanadianAccount =
      _.get(accountDetails, `isCanadianBilled`, ``) === YES ||
      _.get(accountDetails, `currency`, ``) === CURRENCIES.CAD

    const isSavedCACheckingAcc = getPadCheckingAcc(
      myPaymentCheckout,
      userPaymentMethods,
      updatedPaymentMethodId,
      `Autopay`,
    )
    const isPadFlow =
      isSavedCACheckingAcc?.isSavedCACheckingAcc ||
      isNewCanadianAch(myPaymentCheckout)

    const padId = isPadFlow ? _.get(padDataSubmit, `response.data.id`, ``) : ``
    const scheduledConfirmationNumber = _.get(
      myPaymentConfirm,
      `scheduledConfirmationNumber`,
      ``,
    )
    const customerID = _.get(
      customerAutoPayPaperless,
      `autoPaymentAndPaperlessAccount`,
      ``,
    )
    const amountBalanceByAccount = _.get(
      myPayment,
      `amountBalanceByAccount[${customerID}]`,
      {},
    )
    const manageAutopayDetailsByAcc = _.get(
      customerAutoPayPaperless,
      `manageAutopaymentDetailsByAccount[${customerID}].selectedPaymentMethodId`,
      ``,
    )
    const enrollForAutoPaymentDataByCustId = _.get(
      customerAutoPayPaperless,
      `enrollForAutopaymentData[${customerID}]`,
      {},
    )
    const manageAutopayPaperlessDetailsByAcc = _.get(
      customerAutoPayPaperless,
      `manageAutopaymentDetailsByAccount[${customerID}]`,
      {},
    )

    const enrollAutopayData =
      manageAutopayDetailsByAcc === ``
        ? enrollForAutoPaymentDataByCustId
        : manageAutopayPaperlessDetailsByAcc

    const paymentAmount =
      _.get(enrollAutopayData, `outstandingBalance`, 0) ||
      _.get(amountBalanceByAccount, `amount`, 0)
    const noScheduledPaymentLabel = `No Current Balance Payment Due`

    const payload = isCanadianAccount
      ? {
          payMethodId: updatedPaymentMethodId,
          dayOfMonth: `20`,
          deviceChannel: getDeviceChannel(),
          padId,
          confirmationNumber: scheduledConfirmationNumber,
          oneTimePaymentAmount:
            isPadFlow && paymentAmount <= 0
              ? `0.00`
              : _.toString(paymentAmount),
          oneTimePaymentDate:
            isPadFlow && paymentAmount > 0
              ? moment().add(4, "days").format(`MM/DD/YYYY`)
              : noScheduledPaymentLabel,
        }
      : {
          payMethodId: updatedPaymentMethodId,
          invStatus: `Y`,
          deviceChannel: getDeviceChannel(),
        }

    const url = template.expand({ customerId })

    return getTokenAction(dispatch, getState)
      .then((token) => {
        const state = getState()
        const config = {
          headers: {
            oktaToken: token.accessToken,
            referrerUrl: `${window.location.origin}${window.location.pathname}`,
          },
          params: {
            userId: _.get(state, `userAccount.userDetails.userId`, ``),
            lang: state.siteLanguage.language,
          },
        }

        const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }

        return axios
          .create(config)
          .put(url, payload)
          .then((response) => {
            dispatch(fulfilledAC(response.data))
            return response
          })
      })
      .catch((response) => {
        dispatch(
          failedAC({
            statusCode: _.get(response, `response.status`, ``),
            errorMessage: _.get(response, `response.data.Error`, ``),
          }),
        )
        return response
      })
  }
