import _ from "lodash"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import createStore from "store-lib/createStore"
import { getLocalReducers, localPersistWhitelist } from "store/reducers"
import loadGoogleMaps from "utils-lib/loadGoogleMaps"
import { logRocketInit } from "utils-lib/logRocket"
import { isProd } from "utils-lib/builds"
import WorkerPool from "utils/worker/WorkerPool"
import { customConsoleError } from "utils-lib/console"
import App from "./components/App"
import FooterApp from "./components/exported/FooterApp/FooterApp"
import HeaderApp from "./components/exported/HeaderApp"
import { unregister } from "./registerServiceWorker"
import "styles-lib/mywm-Main.scss"
import backgroundRequestWorker from "./workers/requestWorker"

const NUM_WORKERS = 1
const WORKER_SCRIPT = backgroundRequestWorker

/* pool of workers that can be used to fetch data in parallel */
window.workerPool = new WorkerPool(NUM_WORKERS, WORKER_SCRIPT)

if (isProd()) {
  console.log = _.noop
  console.warn = _.noop
} else {
  // We are extending the default console.error functionality to filter out unwanted errors
  console.nativeError = console.error
  console.error = customConsoleError
}

window?.document.addEventListener(
  "logrocketload",
  () => {
    logRocketInit()
  },
  false,
)

const store = createStore(getLocalReducers(), localPersistWhitelist)
const persistor = persistStore(store)

/* make redux store available on the global window object */
window.store = store

loadGoogleMaps() // Load google maps dynamically

// SPA app (with Header & Footer)
if (window.WM.SITE === `SPA`) {
  const root = createRoot(document.getElementById(`root`))

  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </Provider>,
  )
}

// JSP app (with Header & Footer)
if (window.WM.SITE === `JSP`) {
  const root = createRoot(document.getElementById(`header`))

  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <HeaderApp />
        </Router>
      </PersistGate>
    </Provider>,
  )
}

if (window.WM.SITE === `JSP`) {
  const root = createRoot(document.getElementById(`footer`))

  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <FooterApp hideLanguageDropdown />
        </Router>
      </PersistGate>
    </Provider>,
  )
}

unregister()
