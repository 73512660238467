/* config options for microsites */
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export default () => ({
  /* 
    expected format is: https://technologyqa.wm.com/home/datapage.model.json 
    load the model from the mock origin when running locally
  */
  MICROSITE_DATAPAGE_MODEL_PATH: `${
    process.env.REACT_APP_NODE_ENV === "local"
      ? process.env.REACT_APP_MICROSITE_MOCK_ORIGIN
      : window.location.origin
  }/datapage.model.json`,
})
