import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { getDeviceChannel } from "utils-lib/getDeviceChannel"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.CREATE_SCHEDULE_PAYMENT_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.CREATE_SCHEDULE_PAYMENT_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.CREATE_SCHEDULE_PAYMENT_FULFILLED,
  payload: data,
})

export const createSchedulePayment =
  (
    data,
    onSuccessRedirect,
    enableSubmitCallback,
    requireLogin = false,
    scheduledPaymentDate,
  ) =>
  (dispatch, getState) => {
    dispatch(pendingAC())
    const state = getState()
    const api = `SCHEDULE_PAYMENT`
    const urlObj = urls.url[api]
    const language = _.get(getState(), `siteLanguage.language`, `en_CA`)

    if (requireLogin) {
      const { userBilling, myPaymentCheckout } = state

      let customerID = ``
      const billingDetailsAccount = _.get(
        userBilling,
        `billingDetailsAccount`,
        ``,
      )

      if (billingDetailsAccount === ``) {
        const selectedCustomerIds = _.keys(
          _.pickBy(myPaymentCheckout.customerIDs, (custID) => custID === true),
        )
        if (selectedCustomerIds.length === 1) {
          customerID = selectedCustomerIds[0]
        }
      } else {
        customerID = billingDetailsAccount
      }

      return getTokenAction(dispatch, getState).then((token) => {
        const config = {
          headers: {
            token: token.accessToken,
          },
          params: {
            userId: _.get(state, `userAccount.userDetails.userId`, ``),
            lang: language,
          },
        }

        const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }

        return createSchedulePaymentHelper(
          api,
          {
            payOnAccount: customerID,
            ...data,
          },
          config,
          onSuccessRedirect,
          enableSubmitCallback,
          scheduledPaymentDate,
          dispatch,
          getState,
        )
      })
    }

    const { myPaymentVerification } = state

    const config = {
      headers: {
        token: _.get(myPaymentVerification, `verificationToken`, ``),
        ..._.get(urlObj, `guest.headers`, {}),
      },
      params: {
        userId: `Guest`,
        lang: language,
      },
    }

    const apiKey = _.get(urls, `apiKey.GUEST[${api}]`, ``)

    if (apiKey !== ``) {
      config.headers.apiKey = apiKey
    }

    return createSchedulePaymentHelper(
      api,
      {
        payOnAccount: _.get(
          myPaymentVerification,
          `verificationData.customerId`,
          ``,
        ),
        ...data,
      },
      config,
      onSuccessRedirect,
      enableSubmitCallback,
      scheduledPaymentDate,
      dispatch,
      getState,
    )
  }

export const changeDuplicatePayment = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_DUPLICATE,
  payload: data,
})

export const createSchedulePaymentHelper = (
  api,
  data,
  config,
  onSuccessRedirect,
  enableSubmitCallback,
  schedulePaymentDate,
  dispatch,
  getState,
) => {
  const invoiceNo = _.get(data, `payOnInvoice`, ``)
  const customerId = _.get(data, `payOnAccount`, ``)
  const state = getState()
  const convenienceFee =
    state.processingFee?.processingFeeDetails?.convenienceFee

  const template = URITemplate(urls.url[api])
  const url = template.expand({
    customerId,
  })

  return axios
    .create(config)
    .post(url, {
      deviceChannel: getDeviceChannel(),
      payFee: `0.00`,
      paySubmitDate: schedulePaymentDate,
      payChannel: `O`,
      paySubmittedBy: ``,
      paySubmittedFor: ``,
      payMemo: ``,
      convenienceFee,
      payType: invoiceNo !== `` ? `I` : `A`,
      locale: _.get(getState(), `siteLanguage.language`, `en_CA`),
      ...data,
    })
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      dispatch(
        fulfilledAC({
          statusCode,
          scheduledConfirmationNumber: _.get(
            response,
            `data.data.confirmationNumber`,
            ``,
          ),
        }),
      )
      return response
    })
    .then((response) => {
      if (response.data.status === `Success`) {
        /* Do not enable submit button on success. */
        onSuccessRedirect()
      }
    })
    .catch((response) => {
      enableSubmitCallback()
      const statusCode = _.get(response, `response.status`, ``)
      let errorMessage = _.get(response, `response.data.errorMsg`, ``)
      if (errorMessage === ``) {
        errorMessage = _.get(response, `response.data.message`, ``)
      }
      const status = _.get(response, `response.data.status`, ``)
      const msg = _.get(response, `response.data.errorMsg.msg`, ``)
      const language = _.get(getState(), `siteLanguage.language`, `en_CA`)
      if (
        statusCode === 406 &&
        status === `Failed` &&
        msg.toLowerCase() ===
          (language === `fr_CA` ? `paiement en double` : `duplicate payment.`)
      ) {
        enableSubmitCallback()
        dispatch(
          failedAC({
            statusCode,
            msg,
          }),
        )
        dispatch(changeDuplicatePayment(true))
      } else {
        dispatch(
          failedAC({
            statusCode,
            errorMessage,
          }),
        )
      }
    })
}
