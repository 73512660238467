import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  activateUserDetails: {},
  activateUserState: ``,
  autopayConsentDetails: {},
  autopayConsentState: ``,
  createUserState: ``,
  createUserDetails: {
    errorMsg: ``,
    statusCode: ``,
  },
  forgotPasswordState: ``,
  statusCode: {
    createUser: ``,
    activateUser: ``,
    forgotPassword: ``,
  },
  userStatusDetails: {
    statusCode: ``,
    status: ``,
  },
  userStatusState: ``,
  emailStatusState: {
    statusCode: ``,
    status: ``,
  },
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.ACTIVATE_USER_PENDING:
      return {
        ...state,
        // For some reason the action is being called twice
        // If it succeeds the first time, don't invalidate the success with the second call
        activateUserState:
          state.activateUserState === `fulfilled` ? `fulfilled` : `pending`,
      }

    case ActionTypes.ACTIVATE_USER_FAILED:
      return {
        ...state,
        // For some reason the action is being called twice
        // If it succeeds the first time, don't invalidate the success with the second call
        activateUserState:
          state.activateUserState === `fulfilled` ? `fulfilled` : `failed`,
        activateUserDetails: {},
        statusCode: {
          ...state.statusCode,
          activateUser: action.payload,
        },
      }

    case ActionTypes.ACTIVATE_USER_FULFILLED:
      return {
        ...state,
        activateUserState: `fulfilled`,
        activateUserDetails: action.payload,
        statusCode: {
          ...state.statusCode,
          activateUser: ``,
        },
      }

    case ActionTypes.AUTOPAY_CONSENT_PENDING:
      return {
        ...state,
        autopayConsentState: `pending`,
      }

    case ActionTypes.AUTOPAY_CONSENT_FAILED:
      return {
        ...state,
        autopayConsentState: `failed`,
        autopayConsentDetails: {
          statusCode: action.payload.statusCode,
        },
      }

    case ActionTypes.AUTOPAY_CONSENT_FULFILLED:
      return {
        ...state,
        autopayConsentState: `fulfilled`,
        autopayConsentDetails: {
          statusCode: action.payload.statusCode,
        },
      }

    case ActionTypes.CREATE_USER_WITH_CREDENTIALS_PENDING:
      return {
        ...state,
        createUserState: `pending`,
        createUserDetails: {
          errorMsg: ``,
          statusCode: ``,
        },
      }

    case ActionTypes.CREATE_USER_WITH_CREDENTIALS_FAILED:
      return {
        ...state,
        createUserState: `failed`,
        createUserDetails: {
          errorMsg: action.payload.errorMsg,
          statusCode: action.payload.statusCode,
        },
        statusCode: {
          ...state.statusCode,
          createUser: action.payload.errorCode,
        },
      }

    case ActionTypes.CREATE_USER_WITH_CREDENTIALS_FULFILLED:
      return {
        ...state,
        createUserState: `fulfilled`,
        createUserDetails: {
          errorMsg: action.payload.errorMsg,
          statusCode: action.payload.statusCode,
        },
        statusCode: {
          ...state.statusCode,
          createUser: ``,
        },
      }

    case ActionTypes.FORGOT_PASSWORD_PENDING:
      return {
        ...state,
        forgotPasswordState: `pending`,
      }

    case ActionTypes.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgotPasswordState: `failed`,
        statusCode: {
          ...state.statusCode,
          forgotPassword: action.payload,
        },
      }

    case ActionTypes.FORGOT_PASSWORD_FULFILLED:
      return {
        ...state,
        forgotPasswordState: `fulfilled`,
        statusCode: {
          ...state.statusCode,
          forgotPassword: action.payload,
        },
      }

    case ActionTypes.GET_USER_STATUS_PENDING:
      return {
        ...state,
        userStatusState: `pending`,
      }

    case ActionTypes.GET_USER_STATUS_FAILED:
      return {
        ...state,
        userStatusDetails: {
          statusCode: ``,
          status: ``,
        },
        userStatusState: `failed`,
      }

    case ActionTypes.GET_USER_STATUS_FULFILLED:
      return {
        ...state,
        userStatusDetails: {
          statusCode: action.payload.statusCode,
          status: action.payload.userStatus,
        },
        userStatusState: `fulfilled`,
      }

    case ActionTypes.GET_EMAIL_CLEANSE_PENDING:
      return {
        ...state,
        emailStatusState: {
          status: `pending`,
          statusCode: ``,
          reason: [],
          email: action.payload.email,
        },
      }

    case ActionTypes.GET_EMAIL_CLEANSE_FAILED:
      return {
        ...state,
        emailStatusState: {
          status: `failed`,
          statusCode: action.payload,
          reason: action.payload,
          email: action.payload.email,
        },
      }

    case ActionTypes.GET_EMAIL_CLEANSE_FULFILLED:
      return {
        ...state,
        emailStatusState: {
          status: `fulfilled`,
          statusCode: action.payload.status,
          reason: action.payload.reason,
          email: action.payload.email,
        },
      }

    case ActionTypes.CLEAR_EMAIL_CLEANSE:
      return {
        ...state,
        emailStatusState: {
          status: ``,
          email: ``,
        },
      }

    default:
      return state
  }
}
