import get from "lodash/get"

/**
 * Takes in an account object and returns a formatted address.
 *
 * @function
 * @param {object} account - The account object that contains the address information.
 * @param {string} account.serviceAddress.street - The street address of the account.
 * @param {string} account.serviceAddress.city - The city of the account.
 * @param {string} account.serviceAddress.state - The state of the account.
 * @param {string} account.serviceAddress.postalCode - The postal code of the account.
 * @returns {string} A formatted address.
 */
export const getFormattedAddressAutoPayPaperless = (account) => {
  /* Get raw/unformatted address components */
  const rawStreet = get(account, `serviceAddress.street`, ``).toLowerCase()
  const rawCity = get(account, `serviceAddress.city`, ``).toLowerCase()
  const rawState = get(account, `serviceAddress.state`, ``)

  const rawPostal = get(account, `serviceAddress.postalCode`, ``)
  const formattedAddress = `${rawStreet}, ${rawCity}, ${rawState} ${rawPostal}`

  /* Format address using regex (capitalize street, city, etc) */
  const regexPattern = /(\b[a-z](?!\s))/g
  return formattedAddress.replace(regexPattern, (o) => o.toUpperCase())
}
