import { OktaAuth } from "@okta/okta-auth-js"
import { urls } from "utils-lib/builds"
import { isMyWMApp } from "utils-lib/isMyWMApp"

export const dummyToken = {
  tokens: {
    accessToken: {
      accessToken:
        "eyJraWQiOiJIRHpCYzQ2Nl9wOVpWZjdnbmptTWZhd0tFUlk3WXZ3dWkwN0NZSDA0N3pRIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULk1IRV91X0tfekxIWFYwY2Rpb0loRldKM1NZdjFSM3otc3ZmUFNKMWNRMFUiLCJpc3MiOiJodHRwczovL3Nzb2xvZ2lucWEud20uY29tL29hdXRoMi9hdXNlcXZxZnhuMFk2NnBwYTBoNyIsImF1ZCI6Imh0dHBzOi8vd3d3LndtY2FuYWRhcWEuY29tIiwiaWF0IjoxNTY4NjcwNDM4LCJleHAiOjE1Njg2NzEzMzgsImNpZCI6IjBvYWpzaDJnMTk2dTFLUDVGMGg3IiwidWlkIjoiMDB1ZjFzYmVtMFUxY3JRVVYwaDciLCJzY3AiOlsib3BlbmlkIiwiZW1haWwiXSwic3ViIjoic3F1aWdnbGU4QG1haWxpbmF0b3IuY29tIn0.CdsOJenFD-lZRjgQ4sSI6Z78k7M8mlBVEEUd1qgueWh9OA5gE2aVHS2TZSn2JBATZHARKndfw_Og_sOXjsu1TOzSn1YKyQPCWFLOIyCXl2rd5nVjMwdVbX59_EqKlu4m0KaYmsmxtrvqPyEzdECZqVy70xEVCd5C87lJT9C2HMYJHMlGKtoLc7VMkEYxd6CGExDq6NkyW4hCgoD17K7lDZEGj-609VC77Tqnr-OqR1Ab3QyV-K8oEMQM-H4IWFVpYdZijomepIWEymwKC5QWrY6v__g1KntY5ZWmUJ0yp1gFaP7K8-vjmzT6ZD7OxaQpoi0ChKd5iAlqHx1KwKaOBA",
      authorizeUrl:
        "https://ssologinqa.wm.com/oauth2/auseqvqfxn0Y66ppa0h7/v1/authorize",
      expiresAt: 1568671337,
      scopes: ["openid", "email"],
      tokenType: "Bearer",
      userinfoUrl:
        "https://ssologinqa.wm.com/oauth2/auseqvqfxn0Y66ppa0h7/v1/userinfo",
    },
  },
}

const getClient = () => {
  // this to help with mocking the authClient in test env
  if (process.env.NODE_ENV === `test`) {
    if (isMyWMApp) {
      return {
        token: {
          renew: () =>
            new Promise((resolve) => {
              resolve(dummyToken)
            }),
          getWithoutPrompt: () =>
            new Promise((resolve) => {
              resolve(dummyToken)
            }),
        },
        session: {
          get: () => new Promise((resolve) => resolve()),
        },
      }
    }
    return {
      token: {
        renew: () =>
          new Promise((resolve) => {
            resolve(dummyToken)
          }),
        getWithoutPrompt: () =>
          new Promise((resolve) => {
            resolve(dummyToken)
          }),
      },
      session: {
        get: () =>
          new Promise((resolve) => {
            resolve(dummyToken)
          }),
        exists: () =>
          new Promise((resolve) => {
            resolve(dummyToken)
          }),
      },
    }
  }

  return new OktaAuth({
    clientId: urls.okta.clientId,
    issuer: urls.okta.issuer,
    redirectUri: urls.okta.redirectUri,
    pkce: false,
    autoRenew: false,
  })
}

export const authClient = getClient()
