import PropTypes from "prop-types"
import { CheckboxForm } from "../CheckboxForm/CheckboxForm"

export const CustomCheckbox = (
  {
    label,
    inputClassName,
    labelClassName,
    isLabelLeftAligned,
    normalText,
    isChecked,
    onChange,
    style,
    labelStyle,
    isDisabled,
    analytics_key,
    shouldNotTrackInComponent,
    testid,
    isUncontrolled,
    showTabIndex,
  },
  ...props
) => (
  <div data-testid={testid}>
    <CheckboxForm
      {...props}
      isUncontrolled={isUncontrolled}
      analytics_key={analytics_key}
      shouldNotTrackInComponent={shouldNotTrackInComponent}
      inputClassName={`CustomCheckbox ${inputClassName}`}
      inputStyle={style}
      labelPlacement={isLabelLeftAligned ? `start` : `end`}
      labelClassName={`${normalText ? `` : `wm-link`} ${labelClassName}`}
      itemStyle={{
        ...labelStyle,
        cursor: `pointer`,
        position: `relative`,
        top: normalText ? 0 : `.3rem`,
      }}
      items={[
        {
          label,
          checked: isChecked,
          onChange,
          disabled: isDisabled,
        },
      ]}
      showTabIndex={showTabIndex}
    />
  </div>
)

CustomCheckbox.propTypes = {
  checkboxClassName: PropTypes.string,
  label: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLabelLeftAligned: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
  checkboxStyle: PropTypes.object,
  normalText: PropTypes.bool,
  analytics_key: PropTypes.string,
  shouldNotTrackInComponent: PropTypes.bool,
  labelStyle: PropTypes.object,
  testid: PropTypes.string,
  isUncontrolled: PropTypes.bool,
  showTabIndex: PropTypes.bool,
}

CustomCheckbox.defaultProps = {
  checkboxClassName: ``,
  label: ``,
  inputClassName: ``,
  labelClassName: ``,
  isChecked: false,
  isDisabled: false,
  isLabelLeftAligned: false,
  onChange: () => {},
  style: {},
  labelStyle: {},
  checkboxStyle: {},
  normalText: false,
  analytics_key: ``,
  shouldNotTrackInComponent: false,
  testid: "CustomCheckbox",
  isUncontrolled: false,
  showTabIndex: true,
}
