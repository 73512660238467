import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import { GET_CUSTOMER_ACCOUNT_PENDING } from "constants-lib/actionTypes"
import { failedAC, fulfilledAC } from "./actionsCreators"

const api = `CUSTOMER_SEARCH_GUEST`

// Action creators
export const pendingAC = (dontResetDontSeeAreaClicked) => ({
  type: GET_CUSTOMER_ACCOUNT_PENDING,
  payload: {
    dontResetDontSeeAreaClicked,
  },
})

/**
 * Action for getting customer account information by customer ID.
 * @function getCustomerAccountByCustomerId
 * @param {Object} data - The data to include in the API call.
 * @param {string} lang - The language to use for the API call.
 * @param {function} [onComplete=() => {}] - Optional callback function to be called after the API call is completed.
 * @returns {function} dispatches actions to update the store with the results of the API call.
 */
export const getCustomerAccountByCustomerId =
  (data, lang, onComplete = () => {}) =>
  (dispatch) => {
    dispatch(pendingAC())
    const getOptions = {
      url: `${urls.url[api]}?ezpayId=${data}&lang=${lang}`,
    }

    return axios
      .create({
        headers: {
          apiKey: get(urls, `apiKey.GUEST[${api}]`, ``),
        },
      })
      .get(getOptions.url)
      .then((response) => {
        const token = get(response, `headers.token`, ``)
        dispatch(fulfilledAC(response.data || {}, true, token))
        onComplete(response.data || {})
        return response
      })
      .catch((e) => {
        dispatch(failedAC(get(e, `response.status`, 403)))
        onComplete()
      })
  }

/**
 * Actions for getting customer information by address.
 * @function getCustomerByAddress
 * @param {Object} data - The data to include in the API call.
 * @param {function} [onComplete=() => {}] - Optional callback function to be called after the API call is completed.
 * @param {boolean} [dontResetDontSeeAreaClicked] - Whether to reset the "don't see area" clicked state.
 * @returns {function} dispatches actions to update the store with the results of the API call.
 */
export const getCustomerByAddress =
  (data, onComplete = () => {}, dontResetDontSeeAreaClicked) =>
  (dispatch, getState) => {
    dispatch(pendingAC(dontResetDontSeeAreaClicked))

    const state = getState()
    const customerAddress =
      data.customerAddress !== undefined
        ? data.customerAddress
        : state.customerSelections.customerAddress

    // const baseURL = urls.url[api]
    // const uri = new URI(baseURL)
    // const url = uri.search({
    //   street: `${customerAddress.streetNumber} ${customerAddress.streetName}`,
    //   city: customerAddress.city,
    //   state: customerAddress.state,
    //   country: customerAddress.country,
    //   postalCode: customerAddress.postalCode,
    //   lang: state.siteLanguage.language,
    // })
    const baseUrl = urls.url[api]

    const template = URITemplate(
      `${baseUrl}?street={street}&city={city}&state={state}&country={country}&address={label}&postalCode={postalCode}&lang={lang}&googlePlaceId={googlePlaceId}`,
    )

    const url = template.expand({
      street: customerAddress.street
        ? customerAddress.street
        : `${customerAddress.streetNumber} ${customerAddress.streetName}`,
      city: customerAddress.city,
      label: customerAddress.label,
      state: customerAddress.state,
      country: customerAddress.country,
      postalCode: customerAddress.zipCode
        ? customerAddress.zipCode
        : customerAddress.postalCode,
      lang: state.siteLanguage.language,
      googlePlaceId: customerAddress.gPlaceId,
    })

    return axios
      .create({
        headers: {
          apiKey: get(urls, `apiKey.GUEST[${api}]`, ``),
        },
      })
      .get(url)
      .then((response) => {
        dispatch(fulfilledAC(response.data))
        onComplete(response.data)
        return response
      })
      .catch((e) => {
        dispatch(failedAC(get(e, `response.status`, 403)))
        onComplete()
      })
  }
