import { getWindow } from "utils-lib/getWindow"
import { isNextApp } from "utils-lib/isNextApp"
import getFrontendUrls from "./getFrontendUrls"
import { getEnvVars } from "./getEnvVars"

const window = getWindow()

export const isProd = () => getRunMode() === runMode.PROD

export const isLocal = () => getRunMode() === runMode.LOCAL

export const runMode = {
  LOCAL: `local`,
  PROD: `prod`,
  DEV: `dev`,
  DEV1: `dev1`,
  DEV3: `dev3`,
  QA: `qa`,
  QA1: `qa1`,
  QA3: `qa3`,
  UAT: `uat`,
  CPV: `cpv`,
}

export const getRunMode = () => {
  const { hostname } =
    window.location /* e.g. localhost, www.wm.com, wmqa2.wm.com, etc. */

  /* AEM sets window.ENV_RUNMODE based on their build scripts. */
  let mode = window.ENV_RUNMODE
    ? window.ENV_RUNMODE
    : process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV

  /* Override the mode if we're on a hostname that we recognize. */
  if (hostname === `localhost`) {
    mode = runMode.LOCAL
  } else if (hostname === `wm.com` || hostname === `www.wm.com`) {
    /* Override the mode if we're on a hostname that we recognize. */
    mode = runMode.PROD
  } else if (hostname?.indexOf(`dev1`) !== -1) {
    mode = runMode.DEV1
  } else if (hostname?.indexOf(`dev3`) !== -1) {
    mode = runMode.DEV3
  } else if (
    hostname?.indexOf(`dev`) !== -1 ||
    process.env.NODE_ENV === `test`
  ) {
    mode = runMode.DEV
  } else if (hostname?.indexOf(`uat`) !== -1) {
    mode = runMode.UAT
  } else if (hostname?.indexOf(`qa1`) !== -1) {
    mode = runMode.QA1
  } else if (hostname?.indexOf(`qa3`) !== -1) {
    mode = runMode.QA3
  } else if (hostname?.indexOf(`qa`) !== -1) {
    mode = runMode.QA
  } else if (hostname?.indexOf(`cpv`) !== -1) {
    mode = runMode.CPV
  }

  // this keeps things consistent in NextJS apps
  if (isNextApp) {
    mode = process.env.NEXT_PUBLIC_NODE_ENV
  }

  return mode
}

const { origin } = window.location // e.g. https://wmqa2.wm.com

export const currentRunMode = getRunMode()

if (currentRunMode !== runMode.PROD && process.env.NODE_ENV !== `test`) {
  console.warn(
    `You are currently working in our`,
    currentRunMode,
    `environment.`,
  )
}

/* Front-end full urls. */
export const feUrls = getFrontendUrls(origin)

// TODO remove urls and just use appEnvVqrs
export const urls = getEnvVars(currentRunMode)

export const appEnvVars = urls

/* Front-end url subdirectory. */
export const feUrlSubdirectory = (sub, language) => {
  const allFeFullUrls = getFrontendUrls(origin)
  const fullUrl = allFeFullUrls[sub]

  const subdirectory =
    fullUrl.split("/").indexOf("mywm") === -1
      ? `/user${fullUrl.split("user")[1]}`
      : `/mywm${fullUrl.split("mywm")[1]}`

  return language ? language + subdirectory : subdirectory
}
