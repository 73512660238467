import * as ActionTypes from "constants-lib/actionTypes"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import get from "lodash/get"
import find from "lodash/find"
import map from "lodash/map"
import isEmpty from "lodash/isEmpty"
import { urls } from "utils-lib/builds"
import { getTokenAction } from "../getToken"

export const pendingAC = () => ({
  type: ActionTypes.GET_REDESIGNED_DASHBOARD_PICKUP_INFO_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.GET_REDESIGNED_DASHBOARD_PICKUP_INFO_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_REDESIGNED_DASHBOARD_PICKUP_INFO_FULFILLED,
  payload: data,
})

export const serviceDashboardPickupInfoFulfilledAC = (data) => ({
  type: ActionTypes.SERVICE_DASHBOARD_PICKUP_INFO_FULFILLED,
  payload: data,
})

/**
 * Fetches pickup information for the redesigned dashboard for a specific customer and their services.
 * @param {string|null} customerId - The unique identifier of the customer, or null to use the first linked account.
 * @param {Object|null} services - The services object containing service details, or null to fetch all services for the customer.
 * @param {Function} getState - The Redux getState function used to access the current state.
 * @returns {Promise} A promise that resolves with the pickup information or rejects with an error.
 */
export const getRedesignedDashboardPickupInfo =
  (customerId = null, services = null) =>
  (dispatch, getState) => {
    const state = getState()
    const api = `REDESIGNED_DASHBOARD_PICKUP_INFO`
    const lang = state.siteLanguage?.language ? state.siteLanguage.language : ``
    const template = URITemplate(urls.url[api])
    const userId = state.userAccount?.userDetails?.userId

    const url = template.expand({
      lang,
      userId,
    })

    const config = {
      headers: {},
    }

    return getTokenAction(dispatch, getState).then((token) => {
      const state = getState()
      const selectedcustomerId =
        customerId || state?.userManageAccount?.linkedAccounts[0]?.custAccountId

      const customerServices = !isEmpty(services)
        ? services.services
        : find(
            state.myServices?.redesignedDashboardServicesDetailsData,
            (service) => service.ezpayId === selectedcustomerId,
          )?.services

      const wasteStreams = [
        { code: "MSW" }, // 1
        { code: "RECYCLABLE" }, // 2
        { code: "ORGANIC" }, // 3
        { code: "YARD_WASTE" }, // 4
        { code: "OTHERS" }, // 5
      ]

      // customerServices can either be an array or an object, check type and handle accordingly
      const customerServicesArray = Array.isArray(customerServices)
        ? map(customerServices, (service) => service.serviceId)
        : []

      if (isEmpty(customerServicesArray)) {
        // eslint-disable-next-line consistent-return
        wasteStreams.forEach((category) => {
          const hasCategory = customerServices[category?.code]

          if (hasCategory) {
            return customerServices[category.code].forEach((service) =>
              customerServicesArray.push(service.serviceId),
            )
          }
        })
      }

      config.headers = {
        token: token.accessToken,
        apiKey: get(urls, `apiKey.USER[${api}]`, ``),
      }

      return getRedesignedDashboardPickupInfoHelper(
        url,
        config,
        dispatch,
        selectedcustomerId,
        customerServicesArray,
      )
    })
  }

/**
 * Fetches pickup information for the redesigned dashboard for a specific customer and their services.
 * @param {string} url - The endpoint URL to fetch the pickup information.
 * @param {Object} config - The Axios configuration object for the request.
 * @param {Function} dispatch - The Redux dispatch function used to dispatch actions based on the request outcome.
 * @param {string} customerId - The unique identifier of the customer for whom the pickup information is being fetched.
 * @param {Array<string>} customerServicesArray - An array of service IDs for which the pickup information is requested.
 * @returns {Promise<Object>} A promise that resolves with the fetched pickup information or rejects with an error.
 */
export const getRedesignedDashboardPickupInfoHelper = (
  url,
  config,
  dispatch,
  customerId,
  customerServicesArray,
) => {
  dispatch(pendingAC())

  const services = customerServicesArray.map((service) => ({
    serviceId: service,
  }))

  const data = {
    customers: [
      {
        customerId,
        services,
      },
    ],
  }

  return axios
    .create(config)
    .post(url, data)
    .then((response) => {
      const statusCode = get(response, `status`, ``)
      dispatch(
        fulfilledAC({
          redesignedDashboardPickupInfo: {
            ...response?.data?.data,
            loggedInStatus: response?.data?.loggedInStatus,
            status: response?.data?.status,
            statusCode: response?.data?.statusCode,
            requestTrackingId: response?.data?.requestTrackingId,
            errorMsg: response?.data?.errorMsg?.msg,
          },
          statusCode,
        }),
      )
      return {
        ...response?.data?.data,
        loggedInStatus: response?.data?.loggedInStatus,
      }
    })
    .catch((error) => {
      dispatch(failedAC(get(error, `response.status`, ``)))
    })
}

export const setServiceDashboardPickupInfoResponse =
  (newResponse) => (dispatch) => {
    dispatch(serviceDashboardPickupInfoFulfilledAC(newResponse))
    return newResponse
  }
