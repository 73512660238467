import { GET_GEOCODE_FULFILLED } from "constants-lib/actionTypes"
import get from "lodash/get"
import getFormattedGoogleAddressFromGeocode from "utils-lib/getFormattedGoogleAddressFromGeocode"
import { getWindow } from "utils-lib/getWindow"
import { setGeocodeCustomerAddress } from "../setGeocodeCustomerAddress"

const window = getWindow()

export const fulfilledAC = (data) => ({
  type: GET_GEOCODE_FULFILLED,
  payload: {
    data,
  },
})

/**
 * Uses the Google Maps JavaScript API to geocode an address, then dispatches the formatted address.
 *
 * @function setCustomerAddressFromGeocode
 * @param {string} address - The address to geocode.
 * @returns {Function} A Redux Thunk action creator that dispatches the geocoded address.
 */
export const setCustomerAddressFromGeocode = (address) => (dispatch) => {
  const geocoder = new window.google.maps.Geocoder()
  geocoder.geocode({ address }, (results, status) => {
    if (status === `OK`) {
      const formattedAddress = getFormattedGoogleAddressFromGeocode(
        get(results, `[0]`, {}),
      )
      dispatch(setGeocodeCustomerAddress(formattedAddress))
    } else {
      // console.log(status)
    }
  })
}
