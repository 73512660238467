import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import { getTokenAction } from "actions-lib"
import { MOCK_SERVICE_VISUALS } from "utils-lib/mocks"
import { localStorageKey } from "constants-lib/localStorage"
import {
  GET_SERVICE_VISUALS_PENDING,
  GET_SERVICE_VISUALS_FULFILLED,
  GET_SERVICE_VISUALS_FAILED,
} from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

function handlePending() {
  return {
    type: GET_SERVICE_VISUALS_PENDING,
  }
}

function handleFailed() {
  return {
    type: GET_SERVICE_VISUALS_FAILED,
  }
}

function handleFulfilled(payload) {
  return {
    type: GET_SERVICE_VISUALS_FULFILLED,
    payload,
  }
}

/**
 * Fetches service visuals and dispatches the appropriate action.
 * @param {string} serviceDate - The date of the service.
 * @returns {Function} A Redux thunk function that accepts `dispatch` and `getState` as parameters.
 */

export const getServiceVisuals = (serviceDate) => (dispatch, getState) => {
  dispatch(handlePending(GET_SERVICE_VISUALS_PENDING))

  const api = `GET_SERVICE_VISUALS`
  const apiKey = get(urls, `apiKey.USER.HISTORY_VISUALS`, ``)

  const template = URITemplate(urls.url[api])

  const state = getState()

  const lang = get(state, `siteLanguage.language`, ``)
  const userId = get(state, `userAccount.userDetails.userId`, ``)

  const serviceKey = get(state, `myServices.selectedServiceKey`, ``)

  let chatBotCustomerId = window.localStorage.getItem(
    localStorageKey.SERVICE_HISTORY_CUSTOMER_ID,
  )

  if (chatBotCustomerId) {
    chatBotCustomerId = chatBotCustomerId.replace(/-/g, "")
  }

  const customerId = chatBotCustomerId || serviceKey.split(" ")[0] || ""
  let serviceId =
    window.localStorage.getItem(localStorageKey.SERVICE_HISTORY_SERVICE_ID) ||
    serviceKey.split(" ")[1] ||
    ""

  while (serviceId.length < 9) {
    serviceId = `0${serviceId}`
  }

  const url = MOCK_SERVICE_VISUALS
    ? `${window.origin}/mock/serviceVisuals.json`
    : template.expand({
        lang,
        userId,
        customerId,
        serviceId,
        serviceDate,
      })

  return getTokenAction(dispatch, getState).then((token) => {
    const config = {
      headers: {
        apiKey,
        oktaToken: token.accessToken,
      },
    }

    return axios
      .create(config)
      .get(url)
      .then((response) => {
        const data = get(response, `data.data`, [])

        dispatch(handleFulfilled(data))
        return response
      })
      .catch(() => {
        dispatch(handleFailed(GET_SERVICE_VISUALS_FAILED))
      })
  })
}
