import _ from "lodash"
import {
  SET_DATA_CACHE,
  CLEAR_DATA_CACHE,
  UPDATE_ORDER_HISTORY_DATA_CACHE,
} from "constants-lib/actionTypes"

const initialState = {
  combinedOrderHistoryData: [],
  customerServicesData: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_CACHE: {
      const { field, data } = action.payload

      return {
        ...state,
        [field]: [...state[field], ...data],
      }
    }

    /* used to invalidate cache when linked accounts are changed. This works for the current use case, if more APIs start to use this caching, we'll have to revisting validation strategies */
    case CLEAR_DATA_CACHE: {
      const cacheKey = action.payload
      if (!cacheKey) {
        return initialState
      }

      return {
        ...state,
        [cacheKey]: _.get(initialState, cacheKey, null),
      }
    }

    /* update cache item based on order number (there can be multiple matches) when cancel action is taken */
    case UPDATE_ORDER_HISTORY_DATA_CACHE: {
      const { data } = action.payload

      const updatedCombinedOrderHistoryData = _.map(
        state.combinedOrderHistoryData,
        (item) => {
          if (_.get(item, `orderId`, ``) === data.orderId) {
            return {
              ...item,
              status: data.status,
              isCancellable: `N`,
              isEditable: `N`,
            }
          }

          return item
        },
      )

      return {
        ...state,
        combinedOrderHistoryData: updatedCombinedOrderHistoryData,
      }
    }

    default:
      return state
  }
}
