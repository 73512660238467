export const white = `#ffffff`
export const backgroundGray = `#f4f4f4`
export const green1 = `#024731`
export const green3 = `#bed600`
export const green4 = `#79b943`
export const trueGreen = `#1d890a`
export const imageBGColor = `#D8D8D8`
export const gray4 = `#67696D`
export const gray5 = `#4F4F58`
export const disabledGray = `#9B9B9B`

export const COLORS = {
  WHITE: `white`,
  GREEN: `green`,
  GRAY: `gray`,
  GREEN1: `green1`,
  SAND: `sand`,
}
