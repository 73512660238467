import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getUserValues } from "utils-lib/analytics"
import { getTokenAction, setSCAValues } from "actions"
import { urls } from "utils-lib/builds"

export const getCustomerIds = (accounts) =>
  _.map(accounts, (item) => item.custAccountId)

export const pendingAC = () => ({
  type: ActionTypes.GET_BROKER_ACCOUNT_BALANCE_AND_DETAILS_PENDING,
})

export const failedAC = () => ({
  type: ActionTypes.GET_BROKER_ACCOUNT_BALANCE_AND_DETAILS_FAILED,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_BROKER_ACCOUNT_BALANCE_AND_DETAILS_FULFILLED,
  payload: data,
})

export const updateNickNameAC = (data) => ({
  type: ActionTypes.UPDATE_BROKER_ACCOUNT_NICKNAME,
  payload: data,
})

export const updateBrokerAccountNickname = (data) => (dispatch) =>
  dispatch(updateNickNameAC(data))

export const getBrokerAccountBalancesAndDetails =
  () => (dispatch, getState) => {
    dispatch(pendingAC())

    const state = getState()
    const userId = _.get(state, `userAccount.userDetails.userId`, ``)

    return getTokenAction(dispatch, getState)
      .then((res) =>
        getBrokerAccountBalancesAndDetailsHelper(
          {
            token: res.accessToken,
            userId,
          },
          dispatch,
          getState,
        ),
      )
      .catch(() => {
        dispatch(failedAC())
      })
  }

export const getBrokerAccountBalancesAndDetailsHelper = (
  { token, userId },
  dispatch,
  getState,
) => {
  const api = `BROKER_ACCOUNT_BALANCE_CONTACT`
  const template = URITemplate(urls.url[api])
  const url = template.expand({ userId })

  const config = {
    headers: {
      oktatoken: token,
      apikey: _.get(urls, `apiKey.USER[${api}]`, ``),
      "Content-Type": `application/json`,
    },
    params: {
      lang: _.get(getState(), `siteLanguage.language`, `en_CA`),
    },
  }

  return axios
    .create(config)
    .get(url)
    .then((response) => {
      const accountBalancesAndDetails = _.get(
        response,
        `data.data.accounts`,
        [],
      )
      dispatch(fulfilledAC(accountBalancesAndDetails))

      const userValues = getUserValues({
        userManageAccount: getState().userBilling,
        isBrokerAccount: true,
      })
      window.scaValues = userValues
      setSCAValues(userValues)
    })
    .catch((error) => {
      dispatch(failedAC(_.get(error, `response.status`, 500)))
    })
}
