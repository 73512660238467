import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import dayjs from "utils-lib/date"
import { urls } from "utils-lib/builds"
import { MOCK_INCIDENTS } from "utils-lib/mocks"
import {
  GET_INCIDENTS_BY_USER_ID_PENDING,
  GET_INCIDENTS_BY_USER_ID_FAILED,
  GET_INCIDENTS_BY_USER_ID_FULFILLED,
} from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"
import { getTokenAction } from "../getToken"

const window = getWindow()

function handlePending() {
  return {
    type: GET_INCIDENTS_BY_USER_ID_PENDING,
  }
}

function handleFailed() {
  return {
    type: GET_INCIDENTS_BY_USER_ID_FAILED,
  }
}

/**
 * Constructs an action object for when fetching incidents by user ID is fulfilled.
 * @param {Array<Object>} incidents - An array of incident objects.
 * @param {string} lang - The language code to be used in the payload.
 * @returns {Object} The action object with type, payload containing incidents, isNotificationsPage flag, and language.
 */
function handleFulfilled(incidents, lang) {
  return {
    type: GET_INCIDENTS_BY_USER_ID_FULFILLED,
    payload: {
      incidents,
      isNotificationsPage:
        window.location.pathname.indexOf("notifications") !== -1,
      lang,
    },
  }
}

/**
 * This function gets the incidents by user id from the API and returns the response
 * @returns the response from the API.
 */
export const getIncidentsByUserId = () => (dispatch, getState) => {
  dispatch(handlePending(GET_INCIDENTS_BY_USER_ID_PENDING))

  const api = `INCIDENTS_BY_USER_ID`
  const apiKey = get(urls, `apiKey.USER.INCIDENTS_BY_USER_ID`, ``)

  const template = URITemplate(urls.url[api])

  const state = getState()

  const lang = get(state, `siteLanguage.language`, ``)

  const fromDate = dayjs().subtract(90, "days").format("YYYY-MM-DD")
  const toDate = dayjs().format("YYYY-MM-DD")

  const url = MOCK_INCIDENTS
    ? `${window.origin}/mock/incidents.json`
    : template.expand({
        lang,
        userId: get(state, `userAccount.userDetails.userId`, ``),
        fromDate,
        toDate,
      })

  return getTokenAction(dispatch, getState).then((token) => {
    const config = {
      headers: {
        apiKey,
        oktaToken: token?.accessToken,
      },
    }

    return axios
      .create(config)
      .get(url)
      .then((response) => {
        const incidents = get(response, `data.data.customers`, [])

        dispatch(handleFulfilled(incidents, lang))
        return response
      })
      .catch(() => {
        dispatch(handleFailed(GET_INCIDENTS_BY_USER_ID_FAILED))
      })
  })
}

/**
 * This function gets the incidents for a customer.
 * @param {string} customerId - The customer ID of the customer you want to get incidents for.
 * @param {string} customFromDate - Optional custom from date.
 * @param {string} customToDate - Optional custom to date.
 * @returns {function} A function that dispatches actions and returns a promise.
 */
export const getIncidentsByCustomerId =
  (customerId, customFromDate, customToDate) => (dispatch, getState) => {
    dispatch(handlePending(GET_INCIDENTS_BY_USER_ID_PENDING))

    const api = `INCIDENTS_BY_CUSTOMER_ID`
    const apiKey = get(urls, `apiKey.USER.INCIDENTS_BY_USER_ID`, ``)

    const template = URITemplate(urls.url[api])

    const state = getState()

    const lang = get(state, `siteLanguage.language`, ``)

    /*
    custom date range (customFromDate, customToDate)
      use case: billing details for retrieving data prior to the past 90 days
    past 90 days date range
      default case: notifications center, always retrieves the past 90 days
  */
    const fromDate =
      customFromDate || dayjs().subtract(90, "days").format("YYYY-MM-DD")
    const toDate = customToDate || dayjs().format("YYYY-MM-DD")
    const url = MOCK_INCIDENTS
      ? `${window.origin}/mock/incidents.json`
      : template.expand({
          userId: get(state, `userAccount.userDetails.userId`, ``),
          lang,
          customerId,
          fromDate,
          toDate,
        })

    return getTokenAction(dispatch, getState).then((token) => {
      const config = {
        headers: {
          apiKey,
          oktaToken: token?.accessToken,
        },
      }

      return axios
        .create(config)
        .get(url)
        .then((response) => {
          const customers = get(response, `data.data.customers`, [])

          dispatch(handleFulfilled(customers, lang))
          return response
        })
        .catch(() => {
          dispatch(handleFailed(GET_INCIDENTS_BY_USER_ID_FAILED))
        })
    })
  }
