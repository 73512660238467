/**
 * Determines whether a specified invoice number is valid.
 *
 * @function
 * @param {string} num - The invoice number to validate.
 * @returns {boolean} A boolean value indicating whether the specified invoice number is valid.
 */
export const isValidInvoiceNumber = (invoiceNo) =>
  invoiceNo !== undefined && // Invoice number cannot be undefined
  invoiceNo !== null && // Invoice number cannot be null
  typeof invoiceNo === `string` && // Invoice number must be of type string
  invoiceNo.match(/^\d{7}-\d{4}-\d{1}$/) !== null // Valid invoice number format: #######-####-#
