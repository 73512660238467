import { localStorageKey } from "constants-lib/localStorage"
import { SET_INCIDENTS_DATE } from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export function setIncidentsDate(incidentsDate, publishedDate) {
  /* clear values set in chatbot */
  window.localStorage.removeItem(localStorageKey.SNAPSHOT_INCIDENT_DATE)
  window.localStorage.removeItem(localStorageKey.SNAPSHOT_PUBLISHED_DATE)

  return {
    type: SET_INCIDENTS_DATE,
    payload: {
      incidentsDate,
      publishedDate,
    },
  }
}
