// https://serverless-stack.com/chapters/add-the-session-to-the-state.html
import PropTypes from "prop-types"
import { Route } from "react-router-dom"

export const AppliedRoute = ({
  component: C,
  layout: L,
  secondaryLayout: SL,
  props: cProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const shouldUseSecondaryLayout = SL !== undefined
      let innerComponent

      if (shouldUseSecondaryLayout) {
        innerComponent = (
          <SL {...cProps}>
            <C {...props} {...cProps} />
          </SL>
        )
      } else {
        innerComponent = <C {...props} {...cProps} />
      }

      return <L {...cProps}>{innerComponent}</L>
    }}
  />
)

AppliedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
  secondaryLayout: PropTypes.func,
  props: PropTypes.object.isRequired,
}

AppliedRoute.defaultProps = {
  secondaryLayout: undefined,
}

export default AppliedRoute
