// https://serverless-stack.com/chapters/create-a-route-that-redirects.html
import { Component } from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import getLanguageRoute from "utils-lib/getLanguageRoute"
import { userType } from "constants-lib/authentication"
import { Route, Redirect } from "react-router-dom"

const ENCODED_QUERY = `%3F`
const DECODED_QUERY = `?`

const SHOULD_REDIRECT_TO_SERVICES_DASHBOARD = {
  VIEW_ETA: `$LANGUAGE_PATH/mywm/my-services/view-pickup-eta`,
}

const EXEMPT_ROUTE_MAPPING = {
  ROLLOFF: `$LANGUAGE_PATH/mywm/my-services/rolloff-request`,
}

class GuestRoute extends Component {
  cleanURL = (url) => url.replace(ENCODED_QUERY, DECODED_QUERY)

  isDirtyURL = (url) => _.includes(url, ENCODED_QUERY)

  render() {
    const {
      component: C,
      layout: L,
      secondaryLayout: SL,
      props: cProps,
      ...rest
    } = this.props

    const shouldUseSecondaryLayout = SL !== undefined

    const isUserLoggedIn = cProps.userAccount.userState === userType.LOGGED_IN

    return (
      <Route
        {...rest}
        render={(props) => {
          let innerComponent = <C {...props} {...cProps} />
          if (shouldUseSecondaryLayout) {
            innerComponent = <SL {...cProps}>{innerComponent}</SL>
          }

          const { locale } = cProps

          const languageRoute = getLanguageRoute(locale)
          const path = props.location.pathname

          /* in some cases the AEM side is encoding "?" as "%3F", to handle this this hack cleans the URL and redirects to the clean URL */
          if (this.isDirtyURL(path)) {
            const cleanedURL = this.cleanURL(path)
            return <Redirect to={cleanedURL} />
          }
          const redirectURL = `${path}${props.location.search}`.replace(
            /\?/g,
            `&`,
          )

          const pathnameWithoutTrailingSlash = window.location.pathname
            .replace(languageRoute, `$LANGUAGE_PATH`)
            .replace(/\/$/, ``)

          /* remove trailing slash so comparison passes */
          const shouldRedirectToServicesDashboard = _.includes(
            _.values(SHOULD_REDIRECT_TO_SERVICES_DASHBOARD),
            pathnameWithoutTrailingSlash,
          )

          /* some experiences are guest only, logged-in users in this case should be re-directed to my-services dashboard */
          if (isUserLoggedIn && shouldRedirectToServicesDashboard) {
            return <Redirect to={`${languageRoute}/mywm/user/my-services`} />
          }

          const isRedirectExempt = _.includes(
            _.values(EXEMPT_ROUTE_MAPPING),
            pathnameWithoutTrailingSlash,
          )

          /* in the case that a user is a guest or if they are a logged-in user on an exempt route, render the route, otherwise re-direct to locate page */
          return (!isUserLoggedIn && cProps.isGuest === true) ||
            (isUserLoggedIn && isRedirectExempt) ? (
            <L {...cProps}>{innerComponent}</L>
          ) : (
            <Redirect
              to={`${languageRoute}/mywm/locate?redirect=${redirectURL}`}
            />
          )
        }}
      />
    )
  }
}

GuestRoute.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
  props: PropTypes.object.isRequired,
  location: PropTypes.object,
  secondaryLayout: PropTypes.func,
}

GuestRoute.defaultProps = {
  location: {},
  secondaryLayout: undefined,
}

export default GuestRoute
