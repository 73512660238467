import PropTypes from "prop-types"
import "./H4.scss"

export const H4 = ({ children, className, testid, ...rest }) => (
  <div
    className={`H4 maax-font-weight-regular ${className}`}
    style={{
      color: `#4f4f58`,
    }}
    data-testid={testid}
    {...rest}
  >
    {children}
  </div>
)

H4.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  testid: PropTypes.string,
}

H4.defaultProps = {
  children: null,
  className: "",
  testid: "H4",
}
