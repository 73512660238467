// constants
import { getWindowHeight, getWindowWidth } from "utils-lib/styles"
import * as ActionTypes from "constants-lib/actionTypes"

export const setViewport = () => {
  const height = getWindowHeight()
  const width = getWindowWidth()

  return {
    type: ActionTypes.SET_VIEWPORT,
    payload: {
      height,
      width,
      isLandscape: width > height,
    },
  }
}
