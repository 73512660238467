import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import { getTokenAction } from "actions-lib/getToken"
import { urls } from "utils-lib/builds"

// Action creators
export const pendingAC = () => ({
  type: ActionTypes.GET_CUSTOMER_SERVICES_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.GET_CUSTOMER_SERVICES_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_CUSTOMER_SERVICES_FULFILLED,
  payload: data,
})

/**
 * Retrieves customer services.
 * @function
 * @param {Object} options - The options object.
 * @param {string} options.customerId - The customer ID.
 * @param {boolean} [options.requireLogin=false] - Whether login is required.
 * @param {function} [options.onComplete=() => true] - The callback function to be called on completion.
 * @param {boolean} [options.serviceChangeEligibility=true] - Whether the customer is eligible for service change.
 * @returns {function} A redux-thunk action creator that dispatches an action to retrieve customer services.
 */
export const getCustomerServices =
  ({
    customerId,
    requireLogin = false,
    onComplete = () => true,
    serviceChangeEligibility = true,
  }) =>
  (dispatch, getState) => {
    const api = `CUSTOMER_SERVICES`
    const template = URITemplate(urls.url[api])
    const url = template.expand({
      customerId,
    })

    const config = {
      headers: {},
      params: {
        lang: get(getState(), `siteLanguage.language`, `en_CA`),
        serviceChangeEligibility: serviceChangeEligibility ? "Y" : "N",
      },
    }

    if (requireLogin) {
      return getTokenAction(dispatch, getState).then((token) => {
        config.headers[urls.okta.tokenFieldKey] = token.accessToken
        config.params.userId = get(
          getState(),
          `userAccount.userDetails.userId`,
          ``,
        )
        config.headers.apiKey = get(urls, `apiKey.USER[${api}]`, ``)
        getCustomerServicesHelper(url, config, dispatch, onComplete)
      })
    }

    config.headers.apiKey = get(urls, `apiKey.GUEST[${api}]`, ``)
    return getCustomerServicesHelper(
      url,
      config,
      dispatch,
      onComplete,
      serviceChangeEligibility,
    )
  }

/**
 * Helper function to retrieve customer services.
 * @function
 * @param {string} url - The URL to send the request to.
 * @param {Object} config - The configuration object for the request.
 * @param {function} dispatch - The redux dispatch function.
 * @param {function} [onComplete] - The callback function to be called on completion.
 * @returns {Promise} A promise that resolves with the response data or rejects with an error.
 */
export const getCustomerServicesHelper = (
  url,
  config,
  dispatch,
  onComplete,
) => {
  dispatch(pendingAC())

  return axios
    .get(url, config)
    .then((response) => {
      const statusCode = get(response, `status`, ``)
      dispatch(
        fulfilledAC({
          customerServices: response.data,
          statusCode,
        }),
      )

      if (onComplete) {
        onComplete(response.data)
      }

      return response
    })
    .catch((error) => {
      dispatch(failedAC(get(error, `response.status`, ``)))
    })
}
