import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import { generateUniqueID } from "utils-lib/requests"
import { profileIdForAPIs } from "constants-lib/profileIdForAPIs"
import { mapToSnakeFromCamel } from "utils-lib/objects/mapToSnakeFromCamel"
import {
  getContactOptions,
  getCustomerContacts,
  getTokenAction,
} from "actions-lib"
import { AuthoringUtils } from "@adobe/aem-spa-page-model-manager"
import { urls } from "utils-lib/builds"
import { MOCK_MANAGE_CONTACTS_PUT } from "utils-lib/mocks"

export const pendingAC = (data = null) => ({
  type: ActionTypes.SET_CUSTOMER_CONTACTS_PENDING,
  payload: data,
})

export const failedAC = (data) => ({
  type: ActionTypes.SET_CUSTOMER_CONTACTS_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.SET_CUSTOMER_CONTACTS_FULFILLED,
  payload: data,
})

export const setCustomerContactsMyWm =
  (customerId, token, contactInfo, successCB = () => {}) =>
  (dispatch) => {
    const template = URITemplate(urls.cloudfront.myPreferences.contacts.url)
    const putUrl = template.expand({
      ezpayId: customerId,
    })
    const apiType = `PUT_CONTACTS`
    const profileId = profileIdForAPIs().profileId[apiType]
    dispatch(pendingAC())

    const putOptions = {
      url: putUrl,
      headers: {
        "Request-Tracking-Id": generateUniqueID(),
        Authorization: `Bearer ${token}`,
        ProfileId: profileId,
      },
      method: `PUT`,
      data: contactInfo,
    }

    return axios(putOptions)
      .then((response) => {
        successCB()

        const statusCode = get(response, `status`, ``)
        const verificationTokenPref = get(response, `headers.token`, ``)

        //  refresh the list of contacts on left side
        dispatch(
          getCustomerContacts(customerId, token, () => {
            dispatch(
              getContactOptions(customerId, token, () => {
                dispatch(
                  fulfilledAC({
                    customerContacts: contactInfo,
                    verificationTokenPref,
                    statusCode,
                  }),
                )
              }),
            )
          }),
        )

        //  update contacts
        return response
      })
      .catch((response) => {
        const statusCode = get(response, `response.status`, ``)
        const errorMessage = get(
          response,
          `response.data.verificationErrorMsg`,
          ``,
        )
        const customerContacts = get(response, `response.data`)
        dispatch(
          failedAC({
            statusCode,
            errorMessage,
            customerContacts,
          }),
        )
      })
  }

export const setCustomerContacts =
  (customerId, contactInfo, successCB = () => {}) =>
  async (dispatch, getState) => {
    const api = `MANAGE_CONTACTS`
    const template = URITemplate(urls.url[api])
    const putUrl = template.expand({
      customerId,
    })
    dispatch(pendingAC())

    const mappedRequestData = mapToSnakeFromCamel(contactInfo)

    const { customerContacts } = getState()
    const oldContacts = customerContacts?.data?.customerContacts?.contacts
    const newContactsPartial = contactInfo?.contacts

    const newContactsFull = [
      {
        ...oldContacts.find((contact) => contact.type === `billing`),
        ...newContactsPartial.find((contact) => contact.type === `billing`),
      },
      {
        ...oldContacts.find((contact) => contact.type === `service`),
        ...newContactsPartial.find((contact) => contact.type === `service`),
      },
    ]

    const token = await getTokenAction(dispatch, getState)

    try {
      let response
      if (AuthoringUtils.isInEditor() || MOCK_MANAGE_CONTACTS_PUT) {
        const importedMockFile = await import(
          `./mocks/setCustomerContactsResponse.js`
        )
        response = importedMockFile.default
      } else {
        response = await axios.put(putUrl, mappedRequestData, {
          headers: {
            "Request-Tracking-Id": generateUniqueID(),
            Authorization: `Bearer ${token.accessToken}`,
          },
        })
      }

      successCB()
      dispatch(fulfilledAC(newContactsFull))

      return response
    } catch (response) {
      // console.log(`response error`, response)
      const statusCode = get(response, `response.status`, ``)
      const errorMessage = get(
        response,
        `response.data.verificationErrorMsg`,
        ``,
      )

      dispatch(
        failedAC({
          statusCode,
          errorMessage,
          customerContacts: get(response, `response.data`),
        }),
      )

      return statusCode
    }
  }
