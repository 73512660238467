import { localStorageKey } from "constants-lib/localStorage"
import {
  SET_CUSTOMER_SELECTED_LANGUAGE,
  SET_LANGUAGE,
  INVALIDATE_LANGUAGE,
} from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export const setCustomerSelectedLanguage = (language) => ({
  type: SET_CUSTOMER_SELECTED_LANGUAGE,
  payload: language,
})

export const invalidateLanguage = () => ({
  type: INVALIDATE_LANGUAGE,
})

/**
 * It sets the language in localStorage and dispatches an action to update the state.
 * @param language - The language to set.
 */
export const setLanguage = (language) => (dispatch) => {
  window.localStorage.setItem(localStorageKey.LANGUAGE, language)
  dispatch({
    type: SET_LANGUAGE,
    payload: {
      language,
    },
  })

  // Invalidate language dependent state.
  dispatch(invalidateLanguage())
}

/**
 * It sets the language in the Redux store, and if the second argument is true, it also sets the
 * customer selected language in the Redux store
 * @param language - The language to switch to.
 * @param shouldSetCustomerSelectedLanguage - This is a boolean value that determines whether the
 * customer's selected language should be set to the language that is being switched to.
 */
export const switchLanguage =
  (language, shouldSetCustomerSelectedLanguage) => (dispatch) => {
    dispatch(setLanguage(language))
    if (shouldSetCustomerSelectedLanguage === true) {
      dispatch(setCustomerSelectedLanguage(language))
    }
  }
