import get from "lodash/get"
import map from "lodash/map"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import {
  GET_ALERTS_ADDRESS_PENDING,
  GET_ALERTS_ADDRESS_FULFILLED,
  GET_ALERTS_ADDRESS_FAILED,
  GET_ALERTS_CUSTOMER_ID_PENDING,
  GET_ALERTS_CUSTOMER_ID_FULFILLED,
  GET_ALERTS_CUSTOMER_ID_FAILED,
  GET_ALERTS_USER_ID_PENDING,
  GET_ALERTS_USER_ID_FULFILLED,
  GET_ALERTS_USER_ID_FAILED,
} from "constants-lib/actionTypes"
import { getTokenAction } from "../getToken"

function handlePending(type) {
  return {
    type,
  }
}

function handleFailed(type) {
  return {
    type,
  }
}

/**
 * Processes a list of notifications and returns a structured object containing a type and a payload with filtered notifications.
 * @param {string} type - The action type.
 * @param {Array<Object>} notifications - The array of notification objects to be processed.
 * @param {string} lang - The language code for the notifications.
 * @returns {Object} An object with a 'type' property and a 'payload' property containing processed notifications and the language.
 */
function handleFulfilled(type, notifications, lang) {
  return {
    type,
    payload: {
      notifications: map(notifications, (item) => ({
        alertId: item.alertId,
        customerId: item.customerId,
        startDate: item.startDate,
        endDate: item.endDate,
        description: item.description,
        title: item.title,
        type: get(item, `details.type`, ``),
        wasteStream: item.wasteStream,
      })),
      lang,
    },
  }
}

/**
 * Fetches notifications based on the provided address data.
 * @param {Object} options - The function parameters.
 * @param {Object} options.addressData - The object containing address details.
 * @param {string} options.addressData.label - The label for the address.
 * @param {string} options.addressData.gPlaceId - The Google Place ID for the address.
 * @param {string} options.addressData.streetNumber - The street number of the address.
 * @param {string} options.addressData.streetName - The street name of the address.
 * @param {string} options.addressData.city - The city of the address.
 * @param {string} options.addressData.state - The state of the address.
 * @param {string} options.addressData.country - The country of the address.
 * @param {string} options.addressData.postalCode - The postal code of the address.
 * @returns {Function}  function that dispatches actions based on the API response.
 */
export const getNotificationsByAddress =
  ({ addressData }) =>
  (dispatch, getState) => {
    dispatch(handlePending(GET_ALERTS_ADDRESS_PENDING))

    const api = `NOTIFICATIONS_BY_ADDRESS`
    const apiKey = get(urls, `apiKey.GUEST.ALERTS`, ``)

    const template = URITemplate(urls.url[api])

    const lang = get(getState(), `siteLanguage.language`, ``)

    const url = template.expand({
      lang,
      label: addressData.label,
      googlePlaceId: addressData.gPlaceId,
      street: `${addressData.streetNumber} ${addressData.streetName}`,
      city: addressData.city,
      state: addressData.state,
      country: addressData.country,

      postalCode: addressData.postalCode,
    })

    const config = {
      headers: {
        apiKey,
        token: ``,
      },
    }

    return axios
      .create(config)
      .get(url)
      .then((response) => {
        const notifications = get(response, `data.data.alerts`, [])

        dispatch(
          handleFulfilled(GET_ALERTS_ADDRESS_FULFILLED, notifications, lang),
        )
        return response
      })
      .catch(() => {
        dispatch(handleFailed(GET_ALERTS_ADDRESS_FAILED))
      })
  }

/**
 * It gets the notifications for a customer
 * @returns a function that takes in dispatch and getState.
 */
export const getNotificationsByCustomerId =
  ({ customerId }) =>
  (dispatch, getState) => {
    dispatch(handlePending(GET_ALERTS_CUSTOMER_ID_PENDING))

    const api = `NOTIFICATIONS_BY_CUSTOMER_ID`
    const apiKey = get(urls, `apiKey.USER.ALERTS`, ``)

    const template = URITemplate(urls.url[api])

    const state = getState()

    const lang = get(state, `siteLanguage.language`, ``)

    const url = template.expand({
      customerId,
      lang,
      userId: get(state, `userAccount.userDetails.userId`, ``),
    })

    return getTokenAction(dispatch, getState).then((token) => {
      const config = {
        headers: {
          apiKey,
          oktaToken: token.accessToken,
        },
      }

      return axios
        .create(config)
        .get(url)
        .then((response) => {
          const notifications = get(response, `data.data.alerts`, [])

          dispatch(
            handleFulfilled(
              GET_ALERTS_CUSTOMER_ID_FULFILLED,
              notifications,
              lang,
            ),
          )
          return response
        })
        .catch(() => {
          dispatch(handleFailed(GET_ALERTS_CUSTOMER_ID_FAILED))
        })
    })
  }

/**
 * It gets the user's notifications from the API and dispatches the result to the Redux store
 * @returns a function.
 */
export const getNotificationsByUserId = () => (dispatch, getState) => {
  dispatch(handlePending(GET_ALERTS_USER_ID_PENDING))

  const api = `NOTIFICATIONS_BY_USER_ID`
  const apiKey = get(urls, `apiKey.USER.ALERTS`, ``)

  const template = URITemplate(urls.url[api])

  const state = getState()

  const lang = get(state, `siteLanguage.language`, ``)

  const url = template.expand({
    lang,
    userId: get(state, `userAccount.userDetails.userId`, ``),
  })

  return getTokenAction(dispatch, getState).then((token) => {
    const config = {
      headers: {
        apiKey,
        oktaToken: token.accessToken,
      },
    }

    return axios
      .create(config)
      .get(url)
      .then((response) => {
        const notifications = get(response, `data.data.alerts`, [])

        dispatch(
          handleFulfilled(GET_ALERTS_USER_ID_FULFILLED, notifications, lang),
        )
        return response
      })
      .catch(() => {
        dispatch(handleFailed(GET_ALERTS_USER_ID_FAILED))
      })
  })
}
