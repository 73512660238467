import isEmpty from "lodash/isEmpty"

/**
 * Matches the language code from a URL string.
 *
 * @function
 * @param {string} text - The URL string to match the language code from.
 * @returns {string|null} The language code matched from the URL string, or `null` if no language code was found.
 */
export const matchLanguageFromUrl = (text) => {
  const exp = /^.*\/ca\/(fr|en)(|\/.*)$/
  const expUS = /^.*\/us\/(es)(|\/.*)$/
  const langUS_es = text?.match(expUS)
  const langSelection = langUS_es ? langUS_es[0].substring(0, 6) : ``
  const lang = isEmpty(text)
    ? null
    : langSelection === `/us/es`
    ? text?.match(expUS)
    : text?.match(exp)

  return !lang ? null : lang[1]
}
