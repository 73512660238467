/**
 * The function takes a string as an argument and returns true if the string is
 * a valid Canadian postal code, and false if it is not.
 *
 * Canadian Postal Code Format Validation according to following rules:
 * Should follow format ANA NAN where A is alphabet & N is a number.
 * Cannot contain following characters in all postions/indexes in string:
 * D, F, I, O, Q, U
 * Cannot start with W or Z on first position/index in string.
 * Returns true only if all above conditions are met.
 *
 * @function
 * @param {string} inputString - The string to be tested.
 * @returns {boolean} A boolean value indicating whether the input string is a valid Canadian postal code.
 *
 * Accepts:
 * h2z 1b8
 * H2Z1B8
 *
 * Disallows:
 * Z2T 1B8 (leading Z)
 * H2T 1O3 (contains O)
 */
export const isValidCADPostalCode = (inputString) => {
  const regex =
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z] ?\d[ABCEGHJ-NPRSTV-Z]\d$/i
  const isValidPostalCode = regex.test(inputString)
  return isValidPostalCode
}
