import map from "lodash/map"
import each from "lodash/each"
import reduce from "lodash/reduce"
import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  fetchState: ``,
  globalAlerts: [],
  notifications: [],
  notificationsLanguage: ``,
  incidents: [],
  incidentsByDate: [],
  incidentsDate: ``,
  publishedDate: ``,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALERTS_ADDRESS_PENDING:
    case ActionTypes.GET_ALERTS_CUSTOMER_ID_PENDING:
    case ActionTypes.GET_ALERTS_USER_ID_PENDING:
      return {
        ...state,
        fetchState: `pending`,
        notifications: [],
      }

    case ActionTypes.GET_ALERTS_ADDRESS_FAILED:
    case ActionTypes.GET_ALERTS_CUSTOMER_ID_FAILED:
    case ActionTypes.GET_ALERTS_USER_ID_FAILED:
      return {
        ...state,
        fetchState: `failed`,
        notifications: [],
      }

    case ActionTypes.GET_ALERTS_ADDRESS_FULFILLED:
    case ActionTypes.GET_ALERTS_CUSTOMER_ID_FULFILLED:
    case ActionTypes.GET_ALERTS_USER_ID_FULFILLED:
      return {
        ...state,
        fetchState: `fulfilled`,
        notifications: action.payload.notifications,
        notificationsLanguage: action.payload.lang,
      }

    case ActionTypes.RESET_USER_ACCOUNT:
    case ActionTypes.CLEAR_NOTIFICATIONS:
      return {
        fetchState: ``,
        notifications: [],
        incidents: [],
      }

    case ActionTypes.GET_GLOBAL_ALERTS_FULFILLED:
      return {
        ...state,
        globalAlerts: action.payload.globalAlerts,
      }

    case ActionTypes.GET_INCIDENTS_BY_USER_ID_PENDING:
    case ActionTypes.GET_INCIDENTS_BY_USER_ID_FAILED:
      return {
        ...state,
        incidents: [],
      }

    case ActionTypes.GET_INCIDENTS_BY_USER_ID_FULFILLED: {
      const customers = action.payload.incidents

      const incidentsByDate = getIncidentByDate(customers)

      const flattenedIncidents = reduce(
        customers,
        (result, item) => {
          each(item.incidents, (item1) => {
            result.push({
              ...item1,
              customerId: item.id,
            })
          })

          return result
        },
        [],
      )

      return {
        ...state,
        incidents: flattenedIncidents,
        incidentsByDate: map(incidentsByDate, (value, key) => ({
          incidents: map(value, (item) => item),
          publishedDate: key,
        })),
        notificationsLanguage: action.payload.lang,
      }
    }

    case ActionTypes.SET_INCIDENTS_DATE:
      return {
        ...state,
        incidentsDate: action.payload.incidentsDate,
        publishedDate: action.payload.publishedDate,
      }

    default:
      return state
  }
}

export const getIncidentByDate = (customers) =>
  customers.reduce((result, customer) => {
    customer.incidents.forEach((incident) => {
      if (incident.published_date) {
        if (!result[incident.published_date]) {
          // eslint-disable-next-line no-param-reassign
          result[incident.published_date] = {}
        }

        if (!result[incident.published_date][incident.date]) {
          // eslint-disable-next-line no-param-reassign
          result[incident.published_date][incident.date] = []
        }

        result[incident.published_date][incident.date].push({
          ...incident,
          customerId: customer.id,
        })
      }
    })

    return result
  }, {})
