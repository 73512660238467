import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  fetchState: ``,
  putState: ``,
  verificationTokenPref: ``,
  isContactInfoEditable: false,
  editContactType: ``,
  siteContactName: ``,
  siteContactEmail: ``,
  siteContactPhone: ``,
  roFormDate: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_CUSTOMER_CONTACTS_PENDING:
      return {
        ...state,
        fetchState: `pending`,
        putState: state.putState,
        verificationTokenPref: ``,
      }

    case ActionTypes.GET_CUSTOMER_CONTACTS_FAILED:
      return {
        ...state,
        fetchState: `failed`,
        putState: state.putState,
        verificationTokenPref: ``,
        ...action.payload,
      }

    case ActionTypes.GET_CUSTOMER_CONTACTS_FULFILLED:
      return {
        ...state,
        fetchState: `fulfilled`,
        putState: state.putState,
        verificationTokenPref: action.payload.verificationTokenPref,
        data: {
          ...action.payload,
        },
      }

    case ActionTypes.SET_CUSTOMER_CONTACTS_FAILED:
      return {
        ...state,
        putState: `failed`,
        ...action.payload,
      }

    case ActionTypes.SET_CUSTOMER_CONTACTS_PENDING:
      return {
        ...state,
        putState: `pending`,
      }

    case ActionTypes.SET_CUSTOMER_CONTACTS_FULFILLED:
      return {
        ...state,
        putState: `fulfilled`,
        isContactInfoEditable: true,
        data: {
          ...state.data,
          customerContacts: {
            ...state.data.customerContacts,
            contacts: action.payload,
          },
        },
      }

    case ActionTypes.SET_CUSTOMER_INFO_EDITABLE_STATE:
      return {
        ...state,
        isContactInfoEditable: action.payload,
      }

    case ActionTypes.SET_EDIT_CONTACT_TYPE:
      return {
        ...state,
        editContactType: action.payload,
      }

    case ActionTypes.RESET_CUSTOMER_CONTACTS:
      return initialState

    case ActionTypes.SET_SITE_CONTACT:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    default:
      return state
  }
}
