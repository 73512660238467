import { genericPropertyMapper } from "utils-lib/objects/genericPropertyMapper"
import { toCamelFromSnake } from "utils-lib/strings/toCamelFromSnake"

/**
 * Transforms the keys of an input object from snake_case to camelCase.
 * @param {Object} inObj - The object whose keys are to be transformed.
 * @returns {Object} A new object with the same values as the input object, but with keys transformed from snake_case to camelCase.
 * @example
 * // Transforms keys from snake_case to camelCase
 * const newObj = mapToCamelFromSnake(oldObj);
 * `Note: This function returns null if the input object is null or undefined.`
 */
// eslint-disable-next-line import/prefer-default-export
export const mapToCamelFromSnake = (inObj) => {
  if (inObj === undefined || inObj === null) {
    return null
  }

  return genericPropertyMapper(inObj, toCamelFromSnake)
}
