import app from "reducers/app"
import accountSettings from "reducers/accountSettings"
import contactOptions from "reducers/contactOptions"
import currentLocalsite from "reducers/currentLocalsite"
import customerVerification from "reducers/customerVerification"
import facilityLocator from "routes/FacilityLocator/reducers/map"
import reducerMasData from "reducers/reducerMasData"
import menu from "reducers/menu"
import myServicesMainROForm from "reducers/myServicesMainROForm"
import containerRepairDates from "reducers/containerRepairDates"
import containerRepairRequest from "reducers/containerRepairRequest"
import containerRepairPricing from "reducers/containerRepairPricing"
import myServicesPickup from "reducers/myServicesPickup"
import myPaymentGuest from "reducers/myPaymentGuest"
import myPaymentVerification from "reducers/myPaymentVerification"
import naturalLanguageDropdowns from "reducers/naturalLanguageDropdowns"
import productListJson from "reducers/productListJson"
import customerAutoPayPaperless from "reducers/customerAutoPayPaperless"
import userPaymentMethods from "reducers/userPaymentMethods"
import userPaymentHistory from "reducers/userPaymentHistory"
import surveyQuestions from "reducers/surveyQuestions"
import viewport from "reducers/viewport"
import errorHandlingPopupData from "reducers/errorHandlingPopupData"
import dataCache from "reducers/dataCache"
import recyclingPickupData from "reducers/recyclingPickupData"
import padDataSubmit from "reducers/padDataSubmit"
import guestContainerRepair from "reducers/guestContainerRepair"

export const getLocalReducers = () => ({
  app,
  accountSettings,
  contactOptions,
  currentLocalsite,
  customerAutoPayPaperless,
  customerVerification,
  facilityLocator,
  menu,
  myServicesMainROForm,
  recyclingPickupData,
  padDataSubmit,
  containerRepairDates,
  containerRepairRequest,
  containerRepairPricing,
  myServicesPickup,
  myPaymentGuest,
  myPaymentVerification,
  naturalLanguageDropdowns,
  productListJson,
  reducerMasData,
  userPaymentMethods,
  userPaymentHistory,
  surveyQuestions,
  viewport,
  errorHandlingPopupData,
  dataCache,
  guestContainerRepair,
})

export const localPersistWhitelist = [
  "accountSettings",
  "currentLocalsite",
  "customerAutoPayPaperless",
  "customerVerification",
  "geoSuggest",
  "myPaymentVerification",
  "myServicesMainROForm",
  "padDataSubmit",
  "recyclingPickupData",
  "surveyQuestions",
  "userPaymentHistory",
  "userPaymentMethods",
]
