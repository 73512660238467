// constants
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { getRollOffTicketDetails } from "actions/getRollOffTicketDetails"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.GET_CUSTOMER_VERIFICATION_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.GET_CUSTOMER_VERIFICATION_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_CUSTOMER_VERIFICATION_FULFILLED,
  payload: data,
})

export const getCustomerVerification =
  (
    { customerId, email, phone },
    changeMainAfterVerification,
    recaptcha,
    actionType,
    rollOffRequestId,
    onSuccess,
  ) =>
  (dispatch, getState) => {
    const api = `CUSTOMER_VERIFICATION`
    /* Set verification parameters. */
    const params = {}

    if (customerId !== undefined) {
      if (
        process.env.REACT_APP_NODE_ENV === `dev` ||
        process.env.REACT_APP_NODE_ENV === `qa2` ||
        process.env.REACT_APP_NODE_ENV === `wmqa2`
      ) {
        params.accountId = customerId
      } else {
        params.customerId = customerId
      }
    }

    if (email !== undefined) {
      params.email = email
    }

    if (phone !== undefined) {
      params.phoneNumber = phone
    }

    if (recaptcha !== `` && recaptcha !== `recaptchaDisabled`) {
      params.recaptchaResponse = recaptcha
    }

    const { myServices } = getState()
    // const {
    //   locateServicesMethodType,
    //   locateServicesInputValue,
    // } = myServices
    //
    // if (locateServicesMethodType === `servicePhone`) {
    //   params.phoneNumber = locateServicesInputValue
    // } else if (locateServicesMethodType === `emailAddress`) {
    //   params.email = locateServicesInputValue
    // }

    /* Set selected customer ID in URL. */
    const selectedCustomerId = _.get(
      myServices,
      `selectedServiceObject.customerId`,
      ``,
    )
    if (selectedCustomerId === `` || _.isEmpty(params)) {
      dispatch(failedAC())
      return null
    }
    const template = URITemplate(urls.url[api])
    const customerVerificationUrl = template.expand({
      customerId: selectedCustomerId,
    })

    dispatch(pendingAC())
    return axios
      .create({
        headers: {
          apiKey: _.get(urls, `apiKey.GUEST[${api}]`, ``),
        },
      })
      .get(customerVerificationUrl, { params })
      .then((response) => {
        const verifiedCode = _.get(response, `data.customerVerified`, ``)
        const isVerified = verifiedCode === `Y`
        const verificationToken = _.get(response, `headers.token`, ``)
        const statusCode = _.get(response, `status`, ``)

        dispatch(
          fulfilledAC({
            customerId: selectedCustomerId,
            isVerified,
            params,
            changeMainAfterVerification,
            verificationToken,
            statusCode,
          }),
        )

        /* If copying or editing the rolloff ticket, get the token and details before proceeding to the form. */
        if (actionType === `copy` || actionType === `edit`) {
          const token = _.get(response, `headers.token`, ``)
          dispatch(
            getRollOffTicketDetails({
              customerId: selectedCustomerId,
              rollOffRequestId,
              token,
            }),
          )
        }
        onSuccess()
        return response
      })
      .catch((error) => {
        dispatch(failedAC(_.get(error, `response.status`, ``)))
      })
  }
