import * as ActionTypes from "constants-lib/actionTypes"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import get from "lodash/get"
import { urls } from "utils-lib/builds"
import { getTokenAction } from "../getToken"

export const pendingAC = () => ({
  type: ActionTypes.GET_CONTAINER_NOT_OUT_ELIGIBILITY_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.GET_CONTAINER_NOT_OUT_ELIGIBILITY_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_CONTAINER_NOT_OUT_ELIGIBILITY_FULFILLED,
  payload: data,
})

export const clearCNOEligibility = () => ({
  type: ActionTypes.CLEAR_CONTAINER_NOT_OUT_ELIGIBILITY,
})

/**
 * Thunk action creator for fetching container not out eligibility data.
 * @param {string|null} serviceId - The service ID, optional.
 * @param {string|null} cnoEligibilityId - The container not out eligibility ID, optional.
 * @returns {Function} A thunk function that takes dispatch and getState as arguments.
 */
export const getContainerNotOutEligibility =
  (serviceId = null, cnoEligibilityId = null) =>
  (dispatch, getState) => {
    const state = getState()
    const api = `CONTAINER_NOT_OUT_ELIGIBILITY`
    const lang = state.siteLanguage?.language ? state.siteLanguage.language : ``
    const template = URITemplate(urls.url[api])
    const userId = state.userAccount?.userDetails?.userId
    const customerId = state.userManageAccount?.linkedAccounts[0]?.custAccountId

    const url = template.expand({
      userId,
      customerId,
      lang,
      serviceId,
      cnoEligibilityId,
    })

    const config = {
      headers: {},
    }

    return getTokenAction(dispatch, getState).then((token) => {
      config.headers = {
        token: token.accessToken,
        apiKey: get(urls, `apiKey.USER[${api}]`, ``),
      }
      return getContainerNotOutEligibilityInfoHelper(url, config, dispatch)
    })
  }

/**
 * Helper function for making the API call to fetch container not out eligibility data.
 * @param {string} url - The URL to make the GET request to.
 * @param {Object} config - The Axios request configuration.
 * @param {Function} dispatch - The Redux dispatch function.
 * @returns {Promise} A promise that resolves with the fetched data or rejects with an error.
 */
export const getContainerNotOutEligibilityInfoHelper = (
  url,
  config,
  dispatch,
) => {
  dispatch(pendingAC())

  return axios
    .create(config)
    .get(url)
    .then((response) => {
      const statusCode = get(response, `status`, ``)

      dispatch(
        fulfilledAC({
          containerNotOutEligibility: {
            ...response?.data?.data,
          },
          statusCode,
        }),
      )
      return {
        ...response?.data?.data,
        loggedInStatus: response?.data?.loggedInStatus,
      }
    })
    .catch((error) => {
      dispatch(failedAC(get(error, `response.status`, ``)))
    })
}

export const clearContainerNotOutEligibility = () => (dispatch) => {
  dispatch(clearCNOEligibility())
}
