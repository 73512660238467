import * as ActionTypes from "constants-lib/actionTypes"

export const setCustomCalendarInputValue = (data) => ({
  type: ActionTypes.SET_CUSTOMCALENDAR_INPUT_VALUE,
  payload: data,
})

export const setCustomCalendarDateValid = (data) => ({
  type: ActionTypes.SET_CUSTOMCALENDAR_DATE_VALID,
  payload: data,
})
