import has from "lodash/has"
import get from "lodash/get"
import noop from "lodash/noop"
import PropTypes from "prop-types"
import MUIButton from "@mui/material/Button"
import { updateClicksDL } from "utils-lib/analytics/dataLayer"
import {
  getAdobeLaunchAttribute,
  getAdobeLaunchClassName,
} from "utils-lib/analytics/launch"

// eslint-disable-next-line import/prefer-default-export
export const ButtonMUI = ({
  analytics_key: analyticsKey,
  children,
  className,
  onClick,
  shouldNotTrackInComponent,
  ariaLabel,
  ariaHasPopup,
  isSCA,
  scaValues,
  setSCAValues,
  elementType,
  buttonRef,
  component,
  // eslint-disable-next-line react/jsx-props-no-spreading
  ...rest
}) => {
  let clickLabel = ``
  if (typeof analyticsKey === `string` && analyticsKey !== ``) {
    clickLabel = analyticsKey
  } else if (typeof children === `string`) {
    clickLabel = children
  } else {
    const child = get(children, `[0]`, ``)
    if (typeof child === `string`) {
      clickLabel = child
    }
  }

  const analyticsClass = getAdobeLaunchClassName({
    disabled: shouldNotTrackInComponent,
    isSCA,
  })

  const handleClick = (e) => {
    if (!shouldNotTrackInComponent) {
      updateClicksDL({
        ui_element: elementType,
        object_name: ``,
        object_content: clickLabel,
      })

      if (isSCA) {
        setSCAValues(scaValues)
      }
    }

    /* Call the onClick handler. */
    onClick(e)
  }

  const restProps = { ...rest }
  // Fixes "React does not recognize the `useMUI` prop on a DOM element" error
  if (restProps.useMUI) delete restProps.useMUI
  // Fixes "React does not recognize the `isExternalLink` prop on a DOM element" error
  if (has(restProps, "isExternalLink")) delete restProps.isExternalLink

  return (
    <MUIButton
      data-testid="ButtonMUI"
      ref={buttonRef}
      variant="contained"
      className={`${analyticsClass} ${className}`}
      onClick={handleClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getAdobeLaunchAttribute({
        disabled: shouldNotTrackInComponent,
        value: clickLabel,
      })}
      aria-label={ariaLabel}
      aria-haspopup={ariaHasPopup}
      component={component}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    >
      {children}
    </MUIButton>
  )
}

ButtonMUI.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  analytics_key: PropTypes.string,
  shouldNotTrackInComponent: PropTypes.bool,
  ariaLabel: PropTypes.string,
  ariaHasPopup: PropTypes.string,
  isSCA: PropTypes.bool,
  scaValues: PropTypes.object,
  setSCAValues: PropTypes.func,
  elementType: PropTypes.string,
  buttonRef: PropTypes.node,
}

ButtonMUI.defaultProps = {
  onClick: () => {},
  className: ``,
  analytics_key: ``,
  shouldNotTrackInComponent: false,
  ariaLabel: ``,
  ariaHasPopup: ``,
  isSCA: false,
  setSCAValues: noop,
  scaValues: {},
  children: null,
  elementType: `Button`,
  buttonRef: null,
}
