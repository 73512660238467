import { localStorageKey } from "constants-lib/localStorage"
import {
  SET_USER_DETAILS,
  SET_USER_SESSION_TOKEN,
} from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

/**
 * Saves data to localStorage and returns a Redux action with the data as payload.
 * @param {Object} data- The data that you want to store in the local storage.
 * @returns {Object} An object with a type and a payload.
 */
export const setUserDetails = (data) => {
  window.localStorage.setItem(
    localStorageKey.USER_DETAILS,
    JSON.stringify(data),
  )
  return {
    type: SET_USER_DETAILS,
    payload: data,
  }
}

export const setUserSessionToken = (token) => ({
  type: SET_USER_SESSION_TOKEN,
  payload: token,
})
