import * as ActionTypes from "constants-lib/actionTypes"
import URITemplate from "urijs/src/URITemplate"
import { getTokenAction } from "actions"
import axios from "axios"
import _ from "lodash"
import { urls } from "utils-lib/builds"

export const pendingPadDataSubmit = () => ({
  type: ActionTypes.SET_PAD_DATA_SUBMIT_PENDING,
})

export const failedPadDataSubmit = (fetchCode) => ({
  type: ActionTypes.SET_PAD_DATA_SUBMIT_FAILED,
  payload: fetchCode,
})

export const fulfilledPadDataSubmit = (data) => ({
  type: ActionTypes.SET_PAD_DATA_SUBMIT_FULFILLED,
  payload: data,
})

/* Pad Agreement Data Submit API and Helper */
export const setPadData = (data, userMode) => (dispatch, getState) => {
  const api = `PAD_POST`
  dispatch(pendingPadDataSubmit())
  const state = getState()
  const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    userId,
  })
  const urlObj = urls.url[api]

  if (userMode === `GUEST_PAY`) {
    const { myPaymentVerification } = state

    const config = {
      headers: {
        token: _.get(myPaymentVerification, `verificationToken`, ``),
        ..._.get(urlObj, `guest.headers`, {}),
      },
    }

    const apiKey = _.get(urls, `apiKey.GUEST[${api}]`, ``)

    if (apiKey !== ``) {
      config.headers.apiKey = apiKey
    }

    return setPadDataHelper(url, data, config, dispatch)
  }
  const config = {
    headers: {},
  }

  return getTokenAction(dispatch, getState).then((token) => {
    config.headers[urls.okta.tokenFieldKey] = token.accessToken
    config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

    return setPadDataHelper(url, data, config, dispatch)
  })
}

export const setPadDataHelper = (url, data, config, dispatch) =>
  axios
    .post(url, data, config)
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      dispatch(
        fulfilledPadDataSubmit({
          data: response,
          statusCode,
        }),
      )

      return response
    })
    .catch((error) => {
      dispatch(failedPadDataSubmit(_.get(error, `response.status`, ``)))

      return error
    })
