import get from "lodash/get"

/**
 * Retrieves the Google address component from a geocode suggestion based on the specified type.
 *
 * @param {Object} suggest - The geocode suggestion object.
 * @param {string} type - The type of address component to retrieve.
 * @returns {string} The short name of the address component, or an empty string if not found.
 */
const getGoogleAddressComponentFromGeocode = (suggest, type) => {
  const comps = get(suggest, `address_components`, []).filter(
    (comp) => comp.types.length > 0 && comp.types[0] === type,
  )
  return comps.length > 0 ? comps[0].short_name : ``
}

/**
 * Returns a formatted address object from Google Geocode data.
 * @param {Object} data - The Google Geocode data.
 * @returns {Object} - The formatted address object.
 */
export default (data) => {
  const postalCode = getGoogleAddressComponentFromGeocode(data, `postal_code`)
  const address = {
    formattedAddress: data.formatted_address,
    label: data.formatted_address.replace(` ${postalCode}`, ``),
    streetNumber: getGoogleAddressComponentFromGeocode(data, `street_number`),
    streetName: getGoogleAddressComponentFromGeocode(data, `route`),
    county: getGoogleAddressComponentFromGeocode(
      data,
      `administrative_area_level_2`,
    ),
    lat: data.geometry.location.lat(),
    lng: data.geometry.location.lng(),
    city: getGoogleAddressComponentFromGeocode(data, `locality`),
    state: getGoogleAddressComponentFromGeocode(
      data,
      `administrative_area_level_1`,
    ),
    country: getGoogleAddressComponentFromGeocode(data, `country`),
    postalCode,
  }
  return address
}
