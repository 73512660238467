import axios from "axios"
import _ from "lodash"
import { urls } from "utils-lib/builds"
import URITemplate from "urijs/src/URITemplate"
import {
  GET_GUEST_CONTAINER_REPAIR_PRICING_FULFILLED,
  GET_GUEST_CONTAINER_REPAIR_PRICING_PENDING,
  GET_GUEST_CONTAINER_REPAIR_PRICING_FAILED,
  RESET_GUEST_CONTAINER_REPAIR_PRICING,
} from "constants-lib/actionTypes"

export const fulfilledAC = (data) => ({
  type: GET_GUEST_CONTAINER_REPAIR_PRICING_FULFILLED,
  payload: data,
})

export const pendingAC = () => ({
  type: GET_GUEST_CONTAINER_REPAIR_PRICING_PENDING,
})

export const failedAC = (data) => ({
  type: GET_GUEST_CONTAINER_REPAIR_PRICING_FAILED,
  payload: data,
})

export const resetContainerRepairPricing = () => ({
  type: RESET_GUEST_CONTAINER_REPAIR_PRICING,
})

export const getGuestContainerRepairPricing =
  ({
    customerId,
    language,
    serviceId,
    priceType,
    reason,
    eligibilityId,
    token,
  }) =>
  async (dispatch) => {
    const api = `GUEST_CONTAINER_REPAIR_PRICING`
    const baseUrl = urls.url[api]
    dispatch(pendingAC())

    const config = {
      headers: {
        token,
      },
    }
    const template = URITemplate(`${baseUrl}`)
    const url = template.expand({
      customerId,
      language,
    })
    const apiKey = _.get(urls, `apiKey.GUEST[${api}]`, ``)
    if (apiKey !== ``) {
      config.headers.apiKey = apiKey
    }
    const body = {
      serviceId,
      priceType,
      reason,
      eligibilityId,
    }

    return axios
      .create(config)
      .post(url, body)
      .then((response) => {
        dispatch(fulfilledAC(response.data))
        return response.data
      })
      .catch((error) => {
        const errorCode = _.get(error, `response.status`, ``)
        const errorMsg = _.get(error, `response.data.errorMsg`, ``)
        const statusCode = _.get(error, `response.data.statusCode`, ``)
        dispatch(failedAC({ errorCode, errorMsg, statusCode }))
        return error
      })
  }
