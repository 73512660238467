import { SET_CONTAINER_REPAIR } from "constants-lib/actionTypes"

const initialState = {
  onListContainer: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTAINER_REPAIR:
      return {
        onListContainer: action.payload,
      }

    default:
      return state
  }
}
