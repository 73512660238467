import { configureStore } from "@reduxjs/toolkit"
import thunk from "redux-thunk"
import LogRocket from "logrocket"
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"
import logger from "redux-logger"
import { isProd } from "utils-lib/builds"
import { isNextApp } from "utils-lib/isNextApp"
import makeRootReducer from "./reducers"

const createStore = (customReducers = {}, customWhitelist = []) => {
  const middleware = [thunk]

  const persistConfig = {
    key: "redux",
    storage,
    // define the state you want to persist below
    whitelist: [
      // these are global shared reducers
      "alert",
      "cloudCraze",
      "communicationPreferences",
      "crossPollination",
      "currentNeighborhood",
      "customerContacts",
      "customerPrefVerification",
      "customerSelections",
      "customerSupportContact",
      "ecommerceCart",
      "flags",
      "holidaySchedule",
      "localsitesCdn",
      "myPayment",
      "myPaymentCheckout",
      "myPaymentConfirm",
      "myServices",
      "myServicesMainROStatus",
      "myWM",
      "notifications",
      "onlineProducts",
      "siteLanguage",
      "userAccount",
      "userBilling",
      "userManageAccount",
      "setIsRecycleQuizPage",
      "manageContacts",
      "topSupportTopicData",
      "authorTools",
      "processingFee",
      "guestContainerRepair",
      "containerRepairDamage",
      // these are coming from the host application (in the future, we can and should port over the rest of the reducers, this allows for an incremental approach without breaking anything)
      ...customWhitelist,
    ],
  }

  const persistedReducer = persistReducer(
    persistConfig,
    makeRootReducer(customReducers),
  )

  if (!isProd() && process.env.NODE_ENV !== `test` && !isNextApp) {
    middleware.push(logger)
  }

  const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // Disabling these checks prevents excess mutation errors that the old version of redux let fail silently, going forward we can use the "immutable/no-mutation" eslint rule to find and fix mutations and then re-enable these checks.
        serializableCheck: false,
        immutableCheck: false,
      }).concat([...middleware, LogRocket.reduxMiddleware()]),
  })

  return store
}

export default createStore
