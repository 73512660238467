import forEach from "lodash/forEach"
import debounce from "lodash/debounce"
import get from "lodash/get"
import includes from "lodash/includes"
import find from "lodash/find"
import axios from "axios"
import { ModelManager } from "@adobe/aem-spa-page-model-manager"
import { localStorageKey } from "constants-lib/localStorage"
import { VALID_ROOT_PATHS } from "constants-lib/common"
import { isMyWMApp } from "utils-lib/isMyWMApp"
import isUnitTest from "utils-lib/isUnitTest"
import { getWindow } from "utils-lib/getWindow"
import { appEnvVars } from "../builds"

const window = getWindow()

const CHATBOT_DEBOUNCE_TIME = 250

export const chatHost = appEnvVars.chatbot.host.domain

/**
 * Adds a JavaScript file to the head of the DOM.
 *
 * @function
 * @param {string} src - The source URL of the JavaScript file to add.
 * @param {string} id - The ID to assign to the new script element.
 */
export const addJSToHead = (src, id) => {
  const el = window.document.createElement(`script`)
  el.type = `text/javascript`
  el.id = id
  el.src = src
  window.document.head.appendChild(el)
}

/**
 * Adds a CSS file to the head of the DOM.
 *
 * @function
 * @param {string} src - The source URL of the CSS file to add.
 * @param {string} id - The ID to assign to the new link element.
 */
export const addCSSToHead = (src, id) => {
  const el = window.document.createElement(`link`)
  el.type = `text/css`
  el.id = id
  el.src = src
  window.document.head.appendChild(el)
}
/**
 * Adds CSS and JavaScript files to the head of the DOM.
 *
 * @function
 * @param {Object} styles - An object containing key-value pairs of CSS file names and their corresponding paths.
 * @param {Object} scripts - An object containing key-value pairs of JavaScript file names and their corresponding paths.
 */
export const addChatbotCSSAndJS = (styles, scripts) => {
  forEach(styles, (value, index) => {
    addCSSToHead(`${chatHost}${value}`, `${index}chatcss`)
  })

  forEach(scripts, (value, index) => {
    addJSToHead(`${chatHost}${value}`, `${index}chatjs`)
  })
}

/**
 * Checks if the chatbot is enabled for the current page.
 *
 * @function
 * @param {Object} model - The data model to use for checking if the chatbot is enabled.
 * @param {boolean} forceLoad - A boolean value indicating whether to force a reload of the chatbot.
 * @returns {boolean} A boolean value indicating whether the chatbot is enabled for the current page.
 */
export const isChatbotEnabled = (
  model = {},
  forceLoad = false,
  isNextJs = false,
) => {
  /*
  Check if chatbot is enabled for the current page.
  It's enabled if it is authored as such or if the chat window is currently
  open.
*/
  const isChatbotWindowOpen = window.localStorage.getItem(
    localStorageKey.IS_CHAT_WINDOW_OPEN,
  )

  const path = window.location.pathname.replace(/\/$/g, "") || "/" // remove trailing slashes

  let pageData = null
  if (isNextJs) {
    pageData = model
  } else {
    const dataModel = isUnitTest()
      ? model
      : model || ModelManager.modelStore.getData()
    const pages = get(dataModel, ":children")

    /* we need to check if the model path has been modified 
  if the current path is a valid root path */
    pageData = get(pages, path, {})
    if (includes(VALID_ROOT_PATHS, path)) {
      pageData = find(pages, (page) => page[":path"] === path)
    }
  }

  return (
    forceLoad ||
    (pageData && pageData.chatbot === `yes`) ||
    isChatbotWindowOpen === `true`
  )
}

/* there is a race condition that is causing multiple chatbot calls to be made,
 so we are debouncing to prevent duplicate calls */
/**
 * Adds or hides the chatbot on the current page.
 *
 * @function
 * @async
 * @param {Object} model - The data model to use for checking if the chatbot is enabled.
 * @param {boolean} forceLoad - A boolean value indicating whether to force a reload of the chatbot.
 */
export const addShowOrHideChatbotOnPage = debounce(
  async (model = {}, forceLoad = false, isNextJs = false) => {
    const isChatbotEnabledOnPage =
      isMyWMApp || isChatbotEnabled(model, forceLoad, isNextJs)
    /*
  Get the chatbot element.
*/
    const chatbotElement = window.document.getElementById(`chatbot`)
    if (isChatbotEnabledOnPage && chatbotElement === null) {
      /*
    If the chatbot is enabled on the current page and the element does not
    exist, we need to add the css and js styles and scripts so that it will
    render.

    Get the config.json from the AWS S3 bucket.
    The build js and css filenames are written to this JSON during each build.
    Insert styles and scripts in the appropriate elements.
  */
      const configResponse = await axios.get(
        `${appEnvVars.chatbot.host.domain}${appEnvVars.chatbot.host.subdirectory}`,
      )
      const styles = get(configResponse, `data.styles`, [])
      const scripts = get(configResponse, `data.scripts`, [])

      addChatbotCSSAndJS(styles, scripts)
    } else if (isChatbotEnabledOnPage && chatbotElement !== null) {
      /*
    If the chatbot is enabled on the current page and the element 
    already exists,we need to check if it is hidden. If so, un-hide it.
  */
      const isChatbotElementHidden = chatbotElement.style.display === `none`
      if (isChatbotElementHidden) {
        chatbotElement.style.display = `inline`

        /* prevent chat icon from being covered by sticky CTA */
        chatbotElement.style.zIndex = `9999`
      }
    } else if (!isChatbotEnabledOnPage && chatbotElement !== null) {
      /*
    If the chatbot is NOT enabled on the current page and the element already
    exists, we need to hide it.
  */
      chatbotElement.style.display = `none`
    }
  },
  CHATBOT_DEBOUNCE_TIME,
)
