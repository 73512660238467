import * as ActionTypes from "constants-lib/actionTypes"

export const setViewMore = (data, viewType) => {
  let type = ActionTypes.SET_VIEW_MORE_INVOICES
  if (viewType === `viewMorePayments`) {
    type = ActionTypes.SET_VIEW_MORE_PAYMENTS
  }

  return {
    type,
    payload: data,
  }
}
