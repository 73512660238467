import { FC } from "react"
import { Box, Typography } from "@mui/material"

import { makeStyles } from "tss-react/mui"

interface StepProps {
  eyebrow: string
  header: string
  footer: string
  isDisabled?: boolean
}

const useStyles = makeStyles()((theme) => ({
  disabled: {
    "& *": {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      color: `${theme.palette.text.gray3} !important`,
    },
  },
}))

const Step: FC<StepProps> = ({ eyebrow, header, footer, isDisabled }) => {
  const { classes } = useStyles()

  const renderContent = () => (
    <Box
      display="flex"
      flexDirection="column"
      className={isDisabled ? classes.disabled : ``}
      data-testid="Step"
    >
      <Typography variant="body2" component="p" data-testid="Step-eyebrow">
        {eyebrow}
      </Typography>
      <Typography variant="subtitle2" component="h2" data-testid="Step-header">
        {header}
      </Typography>
      <Typography variant="body2" component="p" data-testid="Step-footer">
        {footer}
      </Typography>
    </Box>
  )

  return renderContent()
}

export default Step
