import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import moment from "moment"
import { getTokenAction } from "actions"
import { getDeviceChannel } from "utils-lib/getDeviceChannel"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.GET_MY_WM_BROKER_PAYMENT_CAPTURE_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.GET_MY_WM_BROKER_PAYMENT_CAPTURE_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_MY_WM_BROKER_PAYMENT_CAPTURE_FULFILLED,
  payload: data,
})

export const changeDuplicatePayment = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_PAYMENT_CAPTURE_DUPLICATE,
  payload: data,
})
export const clearDeleteScheduledPaymentStatus = () => ({
  type: ActionTypes.CLEAR_DELETE_SCHEDULED_PAYMENT_STATUS,
})
export const clearCreateScheduledPaymentStatus = () => ({
  type: ActionTypes.CLEAR_CREATE_SCHEDULED_PAYMENT_STATUS,
})

export const payOutstandingBalanceAndEnrollAutopay = (data) => ({
  type: ActionTypes.PAID_OUTSTANDING_BALANCE_WHILE_ENROLLING,
  payload: data,
})

export const processBrokerSaleRequest =
  (
    data,
    onSuccessRedirect,
    enableSubmitCallback,
    // eslint-disable-next-line no-unused-vars
    requireLogin = false, // not using this here, but this is an expected field and breaks if it's not passed
    enrollAndPay = false,
  ) =>
  (dispatch, getState) => {
    dispatch(pendingAC())

    const state = getState()
    const userId = _.get(state, `userAccount.userDetails.userId`, ``)
    const api = `BROKER_ACCOUNT_SALE_REQUEST`

    return getTokenAction(dispatch, getState).then((token) => {
      const config = {
        headers: {
          token: token.accessToken,
        },
        params: {
          lang: _.get(getState(), `siteLanguage.language`, `en_CA`),
        },
      }

      const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      if (apiKey !== ``) {
        config.headers.apiKey = apiKey
      }

      return processBrokerSaleRequestHelper(
        api,
        data,
        config,
        onSuccessRedirect,
        enableSubmitCallback,
        enrollAndPay,
        dispatch,
        getState,
        userId,
      )
    })
  }

export const processBrokerSaleRequestHelper = (
  api,
  data,
  config,
  onSuccessRedirect,
  enableSubmitCallback,
  enrollAndPay,
  dispatch,
  getState,
  userId,
) => {
  const invoiceNo = _.get(data, `payOnInvoice`, ``).replace(/[\s-]/g, ``)
  const template = URITemplate(urls.url[api])
  const url = template.expand({ userId })
  const state = getState()
  const convenienceFee =
    state.processingFee?.processingFeeDetails?.convenienceFee

  return axios
    .create(config)
    .post(url, {
      deviceChannel: getDeviceChannel(),
      payFee: `0.00`,
      paySubmitDate: moment().format(),
      payChannel: `O`,
      paySubmittedBy: ``,
      paySubmittedFor: ``,
      payMemo: ``,
      convenienceFee,
      payType: invoiceNo !== `` ? `I` : `A`,
      locale: _.get(getState(), `siteLanguage.language`, `en_CA`),
      ...data,
    })
    .then((response) => {
      // response.data = { "statusCode":200, "status":`Declined`, "errorMsg":``, "data":{ "code":`102`, "message":`REJECT`, "confirmationNumber":69759109168 } }
      const statusCode = _.get(response, `status`, ``)
      if (enrollAndPay) {
        dispatch(
          payOutstandingBalanceAndEnrollAutopay({
            statusCode,
            paymentStatus: _.get(response, `data.status`, ``),
            confirmationNumber: _.get(
              response,
              `data.data.confirmationNumber`,
              ``,
            ),
          }),
        )
      } else {
        dispatch(
          fulfilledAC({
            // saleResponse: response.data, // TODO remove this object and just pull out needed fields.
            statusCode,
            paymentStatus: _.get(response, `data.status`, ``), // `Declined`, `Success`
            confirmationNumber: _.get(
              response,
              `data.data.confirmationNumber`,
              ``,
            ),
            lastPaymentAmount: data.payAmount,
          }),
        )
      }

      return response
    })
    .then((response) => {
      if (response.data.status === `Success`) {
        /* Do not enable submit button on success. */
        onSuccessRedirect()
      } else if (
        response.data.status === `Failed` &&
        response.status === 406 &&
        response.data.errorMsg &&
        response.data.errorMsg.msg === `duplicate payment`
      ) {
        enableSubmitCallback()
        dispatch(changeDuplicatePayment(true))
      } else if (response.data.status === `Declined`) {
        enableSubmitCallback()
        onSuccessRedirect()
      }
    })
    .catch((response) => {
      enableSubmitCallback()
      const statusCode = _.get(response, `response.status`, ``)
      const status = _.get(response, `response.data.status`, ``)
      const msg = _.get(response, `response.data.errorMsg.msg`, ``)
      if (
        statusCode === 406 &&
        status === `Failed` &&
        msg === `duplicate payment`
      ) {
        dispatch(
          fulfilledAC({
            // saleResponse: response.response.data,
            paymentStatus: _.get(response, `response.data.status`, ``), // `Declined`, `Success`
            statusCode,
            lastPaymentAmount: data.payAmount,
          }),
        )
        dispatch(changeDuplicatePayment(true))
      } else {
        let errorMessage = _.get(response, `response.data.errorMsg`, ``)
        if (errorMessage === ``) {
          errorMessage = _.get(response, `response.data.message`, ``)
        }
        dispatch(
          failedAC({
            statusCode,
            errorMessage,
          }),
        )
      }
    })
}
