import { CURRENCIES, YES } from "constants-lib/common"
import { getWindow } from "utils-lib/getWindow"
import get from "lodash/get"
import keys from "lodash/keys"
import filter from "lodash/filter"
import includes from "lodash/includes"

/**
 * Retrieves the currency based on various conditions.
 * @param {Object} options - The options object.
 * @param {boolean} options.isMultiCountry - Indicates if it is a multi-country scenario.
 * @param {boolean} options.isBrokerAccount - Indicates if it is a broker account.
 * @param {Object} options.myPaymentCheckout - The payment checkout details.
 * @param {Object} options.myPayment - The payment details.
 * @param {Object} options.userManageAccount - The user account details.
 * @returns {string} The currency.
 */
export const getCurrency = ({
  isMultiCountry,
  isBrokerAccount,
  myPaymentCheckout,
  myPayment,
  userManageAccount,
}) => {
  const window = getWindow()
  const routePaths = window?.location?.pathname?.split(`/`)
  const isBulkPage =
    includes(routePaths, `bulk-pickup`) ||
    includes(routePaths, `bulk-trash-pickup`)
  const isContainerRepair = includes(routePaths, `container-repair`)
  if (isBulkPage || isContainerRepair) return CURRENCIES.USD
  let currency
  if (isMultiCountry) {
    currency = myPaymentCheckout?.selectedCurrency
  } else if (isBrokerAccount) {
    currency = get(myPaymentCheckout, `selectedCurrency`, CURRENCIES.USD)
  } else {
    const selectedCustomerIds = filter(
      keys(myPaymentCheckout?.customerIDs),
      (customerId) => myPaymentCheckout?.customerIDs[customerId] === true,
    )
    currency = get(
      myPayment?.billingDetails?.[selectedCustomerIds[0]],
      `currency`,
      ``,
    )
  }
  if (!currency) {
    const accounts = get(userManageAccount, `userAccountDetails`, [])
    if (accounts.length === 1)
      currency =
        accounts[0].isCanadianBilled === YES ? CURRENCIES.CAD : CURRENCIES.USD
    else currency = get(myPaymentCheckout, `currency`, CURRENCIES.USD)
  }
  return currency
}
