import { setGuestMissedPickupToken } from "actions-lib"
import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import { get } from "lodash"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.GET_REDESIGNED_DASHBOARD_GUEST_PICKUP_INFO_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.GET_REDESIGNED_DASHBOARD_GUEST_PICKUP_INFO_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_REDESIGNED_DASHBOARD_GUEST_PICKUP_INFO_FULFILLED,
  payload: data,
})

/**
 * Fetches guest pickup information for a specific EZPay ID and service ID, dispatches actions based on the API response.
 * @param {string} ezpayId - The EZPay ID associated with the guest user.
 * @param {string} serviceId - The unique identifier for the service to fetch pickup information for.
 * @returns {Function} A function that takes dispatch and getState as arguments and returns a Promise.
 */
export const getRedesignedDashboardGuestPickupInfo =
  (ezpayId, serviceId) => (dispatch, getState) => {
    dispatch(pendingAC())
    const state = getState()
    const api = `GUEST_PICKUP_INFO`
    const lang = state.siteLanguage?.language ? state.siteLanguage.language : ``
    const template = URITemplate(urls.url[api])
    const url = template.expand({ customerId: ezpayId, lang, serviceId })

    return axios
      .get(url, {
        headers: {
          Apikey: get(urls, `apiKey.GUEST[CUSTOMER_SERVICES]`, ``),
        },
      })
      .then((response) => {
        const guestToken = get(response, "headers.token", "")

        dispatch(fulfilledAC(response?.data))
        dispatch(
          setGuestMissedPickupToken({
            type: ActionTypes.SET_GUEST_MISSED_PICKUP_TOKEN,
            payload: guestToken,
          }),
        )
        return response.data.data
      })
      .catch((e) => {
        dispatch(failedAC(get(e, `response.data.status`, 403)))
      })
  }
