import { AEM_JSON_FULFILLED, AEM_JSON_FAILED } from "constants-lib/actionTypes"

const initialState = {
  standardnavigation: {},
  footer: {},
  chatbot: {},
}

export default function aemData(state = initialState, { type, payload }) {
  switch (type) {
    case AEM_JSON_FULFILLED:
      return {
        ...state,
        ...payload,
      }

    case AEM_JSON_FAILED:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}
