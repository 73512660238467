import { createSelector } from "reselect"

/**
 * The minimum number of accounts that define a user's My WM account as a Broker Account.
 * @type {number}
 */
export const BROKER_ACCOUNT_MIN_SIZE =
  process.env.REACT_APP_NODE_ENV === `prod` || process.env.NODE_ENV === `test`
    ? 50
    : 20

/**
 * Selects the linkedAccounts collection from redux.
 * @param {Object} state - The redux state.
 * @returns {Array} The linkedAccounts collection.
 */
export const linkedAccountsSelector = (state) =>
  typeof state.userManageAccount === `object` &&
  typeof state.userManageAccount.linkedAccounts === `object` &&
  state.userManageAccount.linkedAccounts.length !== undefined
    ? state.userManageAccount.linkedAccounts
    : []

/**
 * Selector for getting the number of linked accounts.
 * @param {Object} state - The redux state.
 * @returns {number} The number of linked accounts.
 */
export const getNumLinkedAccountsSelector = createSelector(
  linkedAccountsSelector,
  (accounts) =>
    typeof accounts === `object` && accounts.length !== undefined
      ? accounts.length
      : 0,
)

/**
 * Selector returning true for a single account, false otherwise.
 * @param {Object} state - The redux state.
 * @returns {boolean} True if there is only one account, false otherwise.
 */
export const isSingleAccountSelector = createSelector(
  getNumLinkedAccountsSelector,
  (numAccounts) => numAccounts === 1,
)

/**
 * Selector returning true if the number of accounts makes up a broker account, false otherwise.
 * @param {Object} state - The redux state.
 * @returns {boolean} True if the number of accounts makes up a broker account, false otherwise.
 */
export const isBrokerAccountSelector = createSelector(
  getNumLinkedAccountsSelector,
  (numLinkedAccounts) => numLinkedAccounts >= BROKER_ACCOUNT_MIN_SIZE,
)
