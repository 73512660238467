import {
  GET_NOTIFICATION_PREFERENCES_FAILED,
  GET_NOTIFICATION_PREFERENCES_FULFILLED,
  GET_NOTIFICATION_PREFERENCES_PENDING,
  CLEAR_GET_NOTIFICATION_PREFERENCES_STATUS,
  SET_SELECTED_ACCOUNT,
  SET_NOTIFICATION_PREFERENCES_FAILED,
  SET_NOTIFICATION_PREFERENCES_FULFILLED,
  SET_NOTIFICATION_PREFERENCES_PENDING,
  CLEAR_SET_NOTIFICATION_PREFERENCES_STATUS,
} from "constants-lib/actionTypes"

// this dispatch type is only used for testing purposes, since navigating
// from a canada-app page back to PC will result in this behavior
// and cannot be otherwise replicated locally
const RESET_COMMUNICATION_PREFERENCES_DATA =
  "RESET_COMMUNICATION_PREFERENCES_DATA"

export const initialState = {
  notificationPreferences: {},
  notificationPreferencesStatusCode: ``,
  getNotificationPreferencesStatus: ``,
  setNotificationPreferencesStatusCode: ``,
  setNotificationPreferencesStatus: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SELECTED_ACCOUNT: {
      const { customerId, lob } = action.payload
      return {
        ...state,
        selectedCustomerId: customerId,
        selectedCustomerLob: lob,
      }
    }
    case GET_NOTIFICATION_PREFERENCES_PENDING:
      return {
        ...state,
        getNotificationPreferencesStatus: `pending`,
        isAfterPut: action.payload,
      }
    case SET_NOTIFICATION_PREFERENCES_PENDING:
      return {
        ...state,
        setNotificationPreferences: ``,
        setNotificationPreferencesStatus: `pending`,
      }
    case GET_NOTIFICATION_PREFERENCES_FULFILLED:
      return {
        ...state,
        getNotificationPreferencesStatus: `fulfilled`,
        notificationPreferences: action.payload,
      }
    case SET_NOTIFICATION_PREFERENCES_FULFILLED:
      return {
        ...state,
        setNotificationPreferencesStatus: `fulfilled`,
        notificationPreferences: action.payload,
      }
    case GET_NOTIFICATION_PREFERENCES_FAILED:
      return {
        ...state,
        getNotificationPreferencesStatus: `failed`,
      }
    case SET_NOTIFICATION_PREFERENCES_FAILED:
      return {
        ...state,
        setNotificationPreferences: action.payload,
        setNotificationPreferencesStatus: `failed`,
      }
    case CLEAR_GET_NOTIFICATION_PREFERENCES_STATUS:
      return {
        ...state,
        notificationPreferences: {},
        getNotificationPreferencesStatus: ``,
      }
    case CLEAR_SET_NOTIFICATION_PREFERENCES_STATUS:
      return {
        ...state,
        setNotificationPreferences: ``,
        setNotificationPreferencesStatus: ``,
      }
    case RESET_COMMUNICATION_PREFERENCES_DATA: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}
