import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.DELETE_SCHEDULED_PAYMENT_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.DELETE_SCHEDULED_PAYMENT_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.DELETE_SCHEDULED_PAYMENT_FULFILLED,
  payload: data,
})

export const deleteScheduledPayment =
  (payReconId, onSuccessRedirect) => (dispatch, getState) => {
    const api = `SCHEDULE_PAYMENT`
    const state = getState()
    const customerId = _.get(state, `userBilling.billingDetailsAccount`, ``)
    const template = URITemplate(urls.url[api])
    const url = template.expand({
      customerId,
    })

    dispatch(pendingAC())

    return getTokenAction(dispatch, getState).then((token) => {
      const config = {
        headers: {
          token: token.accessToken,
        },
        params: {
          payReconId,
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
          lang: _.get(state, `siteLanguage.language`, `en_CA`),
        },
      }

      const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      if (apiKey !== ``) {
        config.headers.apiKey = apiKey
      }

      return axios
        .create(config)
        .delete(url)
        .then((response) => {
          dispatch(fulfilledAC(response))
        })
        .then(() => {
          onSuccessRedirect()
        })
        .catch((error) => {
          const errorCode = _.get(error, `response.status`, ``)
          const errorMsg = _.get(error, `response.data.errorMsg`, ``)
          const statusCode = _.get(error, `response.data.statusCode`, ``)
          dispatch(failedAC({ errorCode, errorMsg, statusCode }))
        })
    })
  }
