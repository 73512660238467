import { createContext } from "react"

/**
 * An object containing language codes.
 * @constant
 */
export const languages = {
  en_us: `en_US`,
  en_ca: `en_CA`,
  fr_ca: `fr_CA`,
  es_us: `es_US`,
}

/**
 * A React context for language.
 * @constant
 */
export const LanguageContext = createContext({
  language: languages.en_us,
  setLanguage: () => {},
})
