/**
 * Validates an email address using a regular expression.
 *
 * @function
 * @param {string} email - The email address to validate.
 * @returns {boolean} A boolean value indicating whether the specified email address is valid.
 */
export const validateEmail = (email) =>
  email !== undefined &&
  email !== null &&
  typeof email === `string` &&
  email.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/) !== null
/**
 * Determines whether an email address is a @wm.com email address.
 *
 * @function
 * @param {string} email - The email address to check.
 * @returns {boolean} A boolean value indicating whether the specified email address is a @wm.com email address.
 */
export const isWmEmail = (email) =>
  email.toLowerCase().indexOf(`@wm.com`) !== -1

/**
 * Determines whether an email address is an @advanceddisposal.com email address.
 *
 * @function
 * @param {string} email - The email address to check.
 * @returns {boolean} A boolean value indicating whether the specified email address is an @advanceddisposal.com email address.
 */
export const isAdsEmail = (email) =>
  email.toLowerCase().indexOf(`@advanceddisposal.com`) !== -1

/**
 * Determines whether an email address is an internal email address.
 *
 * @function
 * @param {string} email - The email address to check.
 * @returns {boolean} A boolean value indicating whether the specified email address is an internal email address.
 */
export const isInternalEmail = (email) => isWmEmail(email) || isAdsEmail(email)
