import { getWindow } from "utils-lib/getWindow"

const window = getWindow()
// This is only used by mywm/src/actions/setLastWidgetViewed, we no-longer use tealium but there is a lot of legacy logic built around the last viewed widget.
/**
 * Returns the hostname of the current window location, with the 'www.' prefix removed.
 * @function
 * @returns {string} The hostname of the current window location.
 */
export default () => {
  const hostName = window.location.hostname.replace(/www./i, ``)
  // const dotIndex = hostName.lastIndexOf(`.`)
  // const domainName = dotIndex !== -1
  //   ? hostName.substring(dotIndex, hostName.substring(dotIndex))
  //   : hostNamex

  return hostName
}
