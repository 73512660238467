import now from "lodash/now"

/**
 * Get the time left in seconds by subtracting the current time
 * from the given unix time.
 *
 * @function
 * @param {number} unixTime - The time in seconds since the Unix epoch.
 * @returns {number} The difference between the current time and the time passed in.
 */
export const getTimeLeftSec = (unixTime) => unixTime - now() / 1000
