import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  signatureDetails: {},
  signatureState: ``,
  errorMessage: ``,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PAYMENTUS_SIGNATURE_PENDING:
      return {
        ...state,
        signatureState: `pending`,
      }
    case ActionTypes.GET_PAYMENTUS_SIGNATURE_FULFILLED:
      return {
        ...state,
        signatureDetails: action.payload,
        signatureState: `fulfilled`,
      }
    case ActionTypes.GET_PAYMENTUS_SIGNATURE_FAILED:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        signatureState: `failed`,
      }

    default:
      return state
  }
}
