import { load } from "@fingerprintjs/botd"
import { isNextApp } from "../isNextApp"
import { getWindow } from "../getWindow"

const window = getWindow()

/**
 * Retrieve the value from the session storage under the given key.
 *
 * @param {string} key - The key to retrieve the value from.
 * @returns {string|null} - The value string if it exists, otherwise null.
 */
export const getSessionStorage = (key) => window.sessionStorage.getItem(key)

/**
 * Store an data object in the session storage under the given key.
 *
 * @param {string} key - The key to store the error object under.
 * @param {data} data - The error object to store.
 */
export const setSessionStorage = (key, data) => {
  window.sessionStorage.setItem(key, JSON.stringify(data))
}

/**
 * Initialize an agent at application startup, once per page/app.
 * @type {Promise}
 * This function will detect the bot and store the result in the session storage.
 * If an error occurs during detection, the error will be stored in the session storage.
 */

export const botdPromise = new Promise((resolve, reject) => {
  // don't execute on server side
  if (isNextApp && !window.location.pathname) {
    resolve({ isBot: false })
    return
  }

  load()
    .then((botd) => botd.detect())
    .then((result) => {
      resolve(result)
    })
    .catch((error) => {
      reject(error)
    })
})

/**
 * Checks if the current user is a bot.
 * The result is cached in the session storage for future use.
 * @returns {Promise<boolean>} A Promise that resolves with a boolean indicating whether the user is a bot.
 * @throws {Error} If the bot detection operation fails.
 */
export const isBot = async () => {
  let result = getSessionStorage("botdResult")
  if (!result) {
    try {
      result = await botdPromise
      setSessionStorage("botdResult", result)
    } catch (error) {
      console.log("Bot detection failed", error)
      throw error
    }
  } else {
    result = JSON.parse(result)
  }
  return result?.isBot
}
