import PropTypes from "prop-types"
import "./H1.scss"

export const H1 = ({ children, className, testid }) => (
  <div
    className={`H1 maax-font-weight-black ${className}`}
    data-testid={testid}
  >
    {children}
  </div>
)

H1.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  testid: PropTypes.string,
}

H1.defaultProps = {
  children: null,
  className: "",
  testid: "H1",
}
