import * as ActionTypes from "constants-lib/actionTypes"
import {
  resetRollOffTicketDetails,
  setMyServicesMain,
  setCustomerAccountSource,
} from "actions"

export const resetAC = () => ({
  type: ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED,
})

export const resetDashboardFromGettingStarted = () => (dispatch) => {
  dispatch(resetAC())
  dispatch(resetRollOffTicketDetails())
  dispatch(setMyServicesMain(`GettingStarted`))
  dispatch(setCustomerAccountSource(``))
}
