import {
  FETCH_DATA_LOCAL_SITE_FULFILLED,
  FETCH_DATA_LOCAL_SITE_FAILED,
} from "constants-lib/actionTypes"
import {
  GET_LOCALSITES_CDN_PENDING,
  GET_LOCALSITES_CDN_FULFILLED,
  GET_LOCALSITES_CDN_FAILED,
} from "constants-lib/localSites"

const initialState = {
  currentFetchState: ``,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCALSITES_CDN_PENDING:
      return {
        ...state,
        currentFetchState: `pending`,
      }

    case GET_LOCALSITES_CDN_FULFILLED:
      return {
        ...action.payload.localsites[0],
        currentFetchState: `fulfilled`,
      }

    case GET_LOCALSITES_CDN_FAILED:
      return {
        currentFetchState: `failed`,
      }
    case FETCH_DATA_LOCAL_SITE_FULFILLED:
      return {
        ...action.payload.localsites[0],
        currentFetchState: `fulfilled-for-LA`,
      }

    case FETCH_DATA_LOCAL_SITE_FAILED:
      return {
        currentFetchState: `failed`,
      }

    default:
      return state
  }
}
