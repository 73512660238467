import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { urls } from "utils-lib/builds"

export const pendingEmail = () => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_EMAIL_PENDING,
})

export const failedEmail = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_EMAIL_FAILED,
  payload: data,
})

export const fulfilledEmail = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_EMAIL_FULFILLED,
  payload: data,
})

export const getPaymentEmailInfo = () => (dispatch, getState) => {
  const api = `CUSTOMER_SEARCH_GUEST`

  const state = getState()
  const token = _.get(state, `myPaymentVerification.verificationToken`, ``)
  const customerId =
    _.get(state, `myPaymentVerification.verificationData.customerId`, ``) ||
    _.get(state, `myPaymentVerification.verificationDetails.customerId`, ``)

  const url = urls.url[api]

  dispatch(pendingEmail())

  let env = `unknownEnvironment`

  if (process.env.REACT_APP_NODE_ENV === `prod`) {
    env = ``
  } else if (
    process.env.REACT_APP_NODE_ENV === `dev` ||
    process.env.REACT_APP_NODE_ENV === `local`
  ) {
    env = `dev`
  } else if (process.env.REACT_APP_NODE_ENV === `qa`) {
    env = `qa`
  } else if (process.env.NODE_ENV === `test`) {
    env = `test`
  } else if (process.env.REACT_APP_NODE_ENV === `dev2`) {
    env = `dev2`
  } else if (process.env.REACT_APP_NODE_ENV === `qa2`) {
    env = `qa2`
  } else if (process.env.REACT_APP_NODE_ENV === `qa3`) {
    env = `qa3`
  } else if (process.env.REACT_APP_NODE_ENV === `uat`) {
    env = `uat`
  }

  return axios
    .create({
      headers: {
        token,
        apiKey: _.get(urls, `apiKey.USER[${api}]`, ``),
      },
      params: {
        ezpayId: customerId,
        lang: _.get(state, `siteLanguage.language`, `en_CA`),
      },
    })
    .get(url)
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      const email = _.get(response, `data.accounts[0].billingEmail`, ``)
      const lob = _.get(response, `data.accounts[0].lineOfBusiness`, ``)
      const processedResponse = {
        paymentEmail: env ? env + email : email,
        lineOfBusiness: lob,
        statusCode,
      }
      dispatch(fulfilledEmail(processedResponse))

      return processedResponse
    })
    .catch((response) => {
      const statusCode = _.get(response, `response.status`, ``)
      const errorMessage = _.get(response, `response.data.Error`, ``)
      dispatch(
        failedEmail({
          statusCode,
          errorMessage,
        }),
      )
    })
}
