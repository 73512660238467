/**
 * It returns true if the input string is empty or contains only whitespaces,
 * otherwise it returns false
 * @function
 * @param {string} inputString - The string you want to check the length of.
 * @returns {boolean} A boolean indicating whether the input string is empty or contains only whitespaces.
 */
export const getStringLength = (inputString) => {
  const strLength = inputString.replace(/\s/g, "").length
  const isStrEmpty = strLength === 0
  return isStrEmpty
}
