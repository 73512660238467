import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { urls } from "utils-lib/builds"

// Action creators
export const pendingAC = () => ({
  type: ActionTypes.GET_TEXT_FOR_BULK_PENDING,
})

export const failedAC = () => ({
  type: ActionTypes.GET_TEXT_FOR_BULK_FAILED,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_TEXT_FOR_BULK_FULFILLED,
  payload: data,
})

export const getTextForBulkDetails = (id) => (dispatch) => {
  const api = `TEXT_FOR_BULK`
  const template = URITemplate(urls.url[api])

  const url = template.expand({
    id,
  })

  dispatch(pendingAC())

  return axios
    .create({
      headers: {
        apiKey: _.get(urls, `apiKey.USER[${api}]`, ``),
      },
    })
    .get(url)
    .then((response) => {
      dispatch(fulfilledAC(response.data.data))

      return response.data.data
    })
    .catch((error) => {
      const statusCode = _.get(error, `response.status`, ``)
      const errorMessage = _.get(error, `response.data.data.Error`, ``)
      dispatch(
        failedAC({
          statusCode,
          errorMessage,
        }),
      )

      return { errorCode: statusCode }
    })
}
