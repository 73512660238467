import isEmpty from "lodash/isEmpty"
import parseInt from "lodash/parseInt"
import isNumber from "lodash/isNumber"
import toPairs from "lodash/toPairs"
import forEach from "lodash/forEach"
import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { validatePhone } from "utils-lib/validation"
import MaskedTextField from "component-lib/Base/components/MaskedTextField/MaskedTextField"
import { phonePattern } from "utils-lib/strings/maskPatterns"
import { defaultToSpace } from "utils-lib/strings"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

const PhoneInput = (
  {
    hasFocus,
    isRequired,
    onChange,
    customErrorMessage,
    hasError,
    handleKeyDown,
    label,
    placeholder,
    value,
    onFocus,
    onBlur,
    disabled,
    fullWidth,
    testId,
    InputProps,
    customClass,
  },
  ...rest
) => {
  const [fieldHasFocus, setFieldHasFocus] = useState(false)
  const formattedId = `phoneInputField-${label}`.replaceAll(/\s/g, "")
  const spreadProps = {}
  // fixes annoying consoler error "Warning: Invalid attribute name: `0`"
  forEach(toPairs(rest), ([key, value]) => {
    if (!isNumber(parseInt(key))) {
      spreadProps[key] = value
    }
  })

  useEffect(() => {
    if (hasFocus) {
      setFieldHasFocus(true)
    }
  }, [])

  useEffect(() => {
    if (fieldHasFocus) {
      window.document.getElementById(formattedId).focus()
    }
  }, [fieldHasFocus])

  const doValidatePhone = (value) =>
    (!isRequired && isEmpty(value)) || validatePhone(value)

  const handleChange = (value) => {
    const isValid = doValidatePhone(value)
    onChange(value, isValid)
  }

  let className = `PhoneInput`
  if (!fieldHasFocus && hasError) {
    className = `PhoneInput input-error`
  }
  if (disabled) {
    className = `PhoneInput disabled-field`
  }

  return (
    <MaskedTextField
      data-testid={testId}
      variant="outlined"
      helperText={defaultToSpace(customErrorMessage)}
      error={!isEmpty(customErrorMessage) || (!fieldHasFocus && hasError)}
      disabled={disabled}
      type="tel"
      name="tel"
      label={label || placeholder}
      onAccept={handleChange}
      onKeyDown={handleKeyDown}
      unmask={false}
      className={`${className} ${customClass}`}
      value={value}
      onFocus={onFocus}
      required={isRequired}
      fullWidth={fullWidth}
      onBlur={() => {
        onBlur(value, doValidatePhone(value))
      }}
      id={formattedId}
      mask={phonePattern}
      InputProps={InputProps}
      ariaLabel={label || placeholder}
      {...spreadProps}
    />
  )
}

PhoneInput.propTypes = {
  hasError: PropTypes.bool,
  hasFocus: PropTypes.bool,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  handleKeyDown: PropTypes.func,
  customErrorMessage: PropTypes.string,
  testId: PropTypes.string,
  InputProps: PropTypes.object,
  style: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  customClass: PropTypes.string,
}

PhoneInput.defaultProps = {
  hasError: false,
  disabled: false,
  hasFocus: false,
  isRequired: false,
  label: ``,
  placeholder: ``,
  className: ``,
  fullWidth: false,
  onFocus: () => {},
  onBlur: () => {},
  handleKeyDown: () => {},
  customErrorMessage: ``,
  testId: `PhoneInput`,
  InputProps: {},
  style: {},
  error: false,
  helperText: ``,
  required: false,
  customClass: "",
}

export default PhoneInput
