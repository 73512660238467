import LogRocket from "logrocket"
import nanoid from "nanoid"
import { runMode, getRunMode } from "utils-lib/builds"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

/**
 * This function determines whether LogRocket should be enabled based on the current run mode.
 * @returns The function `isShouldEnableLogRocket` returns a boolean value.
 */
export const isShouldEnableLogRocket = () => {
  switch (getRunMode()) {
    // Add here to enable logrocket and it takes `REACT_APP_NODE_ENV`
    // To enable logRocket in local use `case runMode.LOCAL:`
    case runMode.CPV:
    case runMode.PROD: {
      return true
    }
    default: {
      return false
    }
  }
}

/**
 * If LogRocket is enabled, then identify the user with the ID stored
 * in sessionStorage
 * @param o - Object
 * @returns The return value of the function is
 * the return value of the last function call.
 */
// eslint-disable-next-line consistent-return
export const logRocketIdentify = (o) => {
  if (isShouldEnableLogRocket()) {
    return LogRocket.identify(window.sessionStorage.getItem(`ID`), o)
  }
}

/**
 * Initializes LogRocket if shouldEnableLogRocket is true.
 * @function
 * @returns {void}
 */
export const logRocketInit = () => {
  const rootHostname = `wm.com`

  if (isShouldEnableLogRocket()) {
    const ID = nanoid(12)

    if (window.sessionStorage.getItem(`ID`) === null) {
      window.sessionStorage.setItem(`ID`, ID)
    }

    /**
     * A LogRocket configuration.
     * @type {Object}
     * @property {boolean} shouldCaptureIP - Whether or not to capture the user's IP address.
     * @property {string} rootHostname - The root hostname to use for LogRocket.
     * @property {Object} network - Network configuration options.
     * @property {Function} network.requestSanitizer - A function to sanitize network requests.
     */
    LogRocket.init(`u51vwj/wm-ecom`, {
      shouldCaptureIP: false,
      rootHostname,
      network: {
        requestSanitizer: (request) => {
          // if the url contains 'rest-api.wm.com/user/authenticate'
          if (
            request.url
              .toString()
              .toLowerCase()
              .indexOf(`rest-api.wm.com/user/authenticate`) !== -1
          ) {
            // scrub out the password
            request.body = null
          }
          return request
        },
      },
    })
  }
}

/**
 * If the user is logged in and the user has opted in to LogRocket,
 * then track the custom event string
 * @param {string} customEventString - The name of the event to be tracked.
 */
export const logRocketTrack = (customEventString) => {
  if (isShouldEnableLogRocket()) {
    LogRocket.track(customEventString)
  }
}

/**
 * If the user has opted in to LogRocket,
 * then track the custom event with additional properties
 * @param {string} eventName - The name of the event to be tracked.
 * @param {Object} eventProperties - The properties of the event to be tracked.
 */
export const logRocketTrackwithObject = (eventName, eventProperties) => {
  if (isShouldEnableLogRocket()) {
    LogRocket.track(eventName, eventProperties)
  }
}
