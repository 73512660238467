import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  CANCEL_SERVICE_CHANGE_PENDING,
  CANCEL_SERVICE_CHANGE_FAILED,
  CANCEL_SERVICE_CHANGE_FULFILLED,
} from "constants-lib/actionTypes"
import { getTokenAction, updateOrderHistoryCache } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: CANCEL_SERVICE_CHANGE_PENDING,
})

export const failedAC = () => ({
  type: CANCEL_SERVICE_CHANGE_FAILED,
})

export const fulfilledAC = (data) => ({
  type: CANCEL_SERVICE_CHANGE_FULFILLED,
  payload: data,
})

export const cancelServiceChange =
  ({ customerId, userId, orderId, lang }) =>
  (dispatch, getState) => {
    const api = `SERVICE_CHANGE_CANCEL`
    const template = URITemplate(urls.url[api])

    const url = template.expand({
      customerId,
      userId,
      orderId,
      lang,
    })

    const config = {
      method: `DELETE`,
      headers: {},
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return cancelServiceChangeHelper(url, config, dispatch)
    })
  }

export const cancelServiceChangeHelper = (url, config, dispatch) => {
  dispatch(pendingAC())

  return axios(url, config)
    .then((response) => {
      dispatch(updateOrderHistoryCache(response.data))
      dispatch(fulfilledAC(response.data))
      return response.data
    })
    .catch(() => {
      dispatch(failedAC())
    })
}
