import axios from "axios"
import { urls } from "utils-lib/builds"
import { getWindow } from "utils-lib/getWindow"
import {
  GET_ARTICLE_DETAILS_PENDING,
  GET_ARTICLE_DETAILS_FAILED,
  GET_ARTICLE_DETAILS_FULFILLED,
} from "constants-lib/actionTypes"

const window = getWindow()

export const pendingAC = () => ({
  type: GET_ARTICLE_DETAILS_PENDING,
})

export const failedAC = (data) => ({
  type: GET_ARTICLE_DETAILS_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: GET_ARTICLE_DETAILS_FULFILLED,
  payload: data,
})

/**
 * Fetches article data based on the current page and language, then dispatches actions based on the API response.
 * @param {number} currentArticlePage - The current page of articles to fetch.
 * @param {string} language - The language setting for the articles.
 * @param {Function} dispatch - Function to dispatch actions based on the result of the fetch operation.
 */
export const getArticleData = (currentArticlePage, language) => (dispatch) => {
  const shouldMockOrigin = () => window.location.hostname === "localhost"
  const origin = shouldMockOrigin() ? urls.mockOrigin : window.location.origin

  const config = {
    params: {
      currentArticlePage,
      locale: language,
    },
  }

  dispatch(pendingAC())

  return axios
    .get(
      `${
        window.location.origin.indexOf(`localhost`) !== -1
          ? `${origin}/content/wm/servlets/relatedArticles`
          : `/content/wm/servlets/relatedArticles`
      }`,
      config,
    )
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      return response.data
    })
    .catch((response) => {
      dispatch(failedAC(response.data))
      return null
    })
}
