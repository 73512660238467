import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  GET_ROLLOFF_PRICE_PENDING,
  GET_ROLLOFF_PRICE_FAILED,
  GET_ROLLOFF_PRICE_FULFILLED,
} from "constants-lib/actionTypes"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: GET_ROLLOFF_PRICE_PENDING,
})

export const failedAC = () => ({
  type: GET_ROLLOFF_PRICE_FAILED,
})

export const fulfilledAC = (data) => ({
  type: GET_ROLLOFF_PRICE_FULFILLED,
  payload: data,
})

export const getROPricing =
  ({ customerId, userId, lang, payload }) =>
  (dispatch, getState) => {
    const api = `ROLLOFF_PRICING`
    const template = URITemplate(urls.url[api])

    const url = template.expand({
      customerId,
      userId,
      lang,
    })

    const config = {
      method: `POST`,
      headers: {},
      data: payload,
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return getROPricingHelper(url, config, dispatch)
    })
  }

export const getROPricingHelper = (url, config, dispatch) => {
  dispatch(pendingAC())

  return axios(url, config)
    .then((response) => {
      const data = _.get(response, `data.data`, {})
      dispatch(fulfilledAC(data))
      return data
    })
    .catch(() => {
      dispatch(failedAC())
    })
}
