import * as ActionTypes from "constants-lib/actionTypes"
import { userType } from "constants-lib/authentication"

const initialState = {
  /* Payment signature */
  signatureDetails: {},
  signatureState: ``,
  errorMessage: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    /* Payment signature */
    case ActionTypes.GET_MY_WM_PAYMENT_SIGNATURE_PENDING:
      return {
        ...state,
        signatureState: `pending`,
      }
    case ActionTypes.GET_MY_WM_PAYMENT_SIGNATURE_FULFILLED:
      return {
        ...state,
        signatureDetails: action.payload.signatureDetails,
        signatureState: `fulfilled`,
      }
    case ActionTypes.GET_MY_WM_PAYMENT_SIGNATURE_FAILED:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        signatureState: `failed`,
      }

    case ActionTypes.GET_MY_WM_PAYMENT_VERIFICATION_PENDING:
    case ActionTypes.GET_MY_WM_PAYMENT_CANCEL_TRANSACTION:
    case ActionTypes.RESET_MY_WM_PAYMENT_INFO:
    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
      return {
        ...initialState,
      }

    case ActionTypes.SET_USER_STATE: {
      /* Clear state after logging in. */
      if (action.payload === userType.LOGGED_IN) {
        return {
          ...initialState,
        }
      }
      return {
        ...state,
      }
    }

    default:
      return state
  }
}
