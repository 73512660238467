import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { generateUniqueID } from "utils-lib/requests"
import { urls } from "utils-lib/builds"
import { MOCK_ORDER_INFORMATION } from "utils-lib/mocks"
import { setDataCache } from "actions/setDataCache"
import { CACHE_KEYS } from "constants-lib/dataCache"

export const pendingAC = () => ({
  type: ActionTypes.GET_ORDER_DETAILS_PENDING,
})

export const failedAC = (errorCode) => ({
  type: ActionTypes.GET_ORDER_DETAILS_FAILED,
  payload: errorCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_ORDER_DETAILS_FULFILLED,
  payload: data,
})

export const getOrderDetails =
  (worker = null) =>
  (dispatch, getState) => {
    dispatch(pendingAC())
    const state = getState()
    const orders = _.get(state, `cloudCraze.orderSummary.orders`, [])
    const userId = _.get(state, `userAccount.userDetails.userId`, ``)

    return worker
      ? getOrderDetailsWorkerHelper(
          worker,
          {
            orders,
            userId,
          },
          dispatch,
          getState,
        )
      : getOrderDetailsHelper(
          {
            orders,
            userId,
          },
          dispatch,
          getState,
        )
  }

export const getOrderDetailsWorkerHelper = (
  worker,
  { orders, userId },
  dispatch,
  getState,
) => {
  const api = `ORDER_DETAILS`
  const template = URITemplate(urls.url[api])
  const state = getState()
  const config = {
    headers: {
      // Authorization: `Bearer ${_.get(getState(), `cloudCraze.esbTokens.access_token`, ``)}`,
      "Content-Type": `application/json`,
      "Request-Tracking-Id": generateUniqueID(),
      "X-Authorization-CA": _.get(state, `userAccount.token.accessToken`, ``),
    },
    params: {
      locale: _.get(state, `siteLanguage.language`, `en_CA`),
    },
  }
  const apiKey = _.get(urls, `apiKey.ESB[${api}]`, ``)
  if (apiKey !== ``) {
    config.headers.ClientId = apiKey
  }

  const promises = _.map(orders, (orderItem) => {
    const url = MOCK_ORDER_INFORMATION
      ? `/mock/order_details_response.json`
      : template.expand({
          userId,
          orderId: _.get(orderItem, `id`, ``),
        })
    return [url, config]
  })

  dispatch(pendingAC())

  const workName = `ORDER_DETAILS`
  worker.addEventListener(`message`, (e) => onMessageHandler(e))
  worker.postMessage({ work: promises, workName })

  /* onMessage handler for web worker */
  function onMessageHandler(e) {
    if (e.data.workName === workName) {
      if (e.data.responses === 500) {
        dispatch(failedAC(500))
        dispatch(
          setDataCache({
            field: CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA,
            /* cache results as an array of 1 500 status item if there are no results, this helps with tracking completion on the CombinedOrderHistory page  */
            data: [500],
          }),
        )

        /* cleanup after worker and mark status as ready */
        worker.removeEventListener(`message`, this)
      } else {
        const responses = _.filter(e.data.responses, (res) => !_.isNull(res))

        const res = _.map(responses, (response, index) => {
          const orderId = _.get(orders, `[${index}].id`, ``)

          return {
            orderId,
            ...response,
          }
        })

        dispatch(
          setDataCache({
            field: CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA,
            /* cache results as an array of 1 null item if there are no results, this helps with tracking completion on the CombinedOrderHistory page  */
            data: res.length ? res : [null],
          }),
        )

        dispatch(fulfilledAC(res))

        /* cleanup after worker and mark status as ready */
        worker.removeEventListener(`message`, this)
      }
    }
  }
}

export const getOrderDetailsHelper = (
  { orders, userId },
  dispatch,
  getState,
) => {
  const api = `ORDER_DETAILS`
  const template = URITemplate(urls.url[api])
  const state = getState()
  const config = {
    headers: {
      // Authorization: `Bearer ${_.get(getState(), `cloudCraze.esbTokens.access_token`, ``)}`,
      "Content-Type": `application/json`,
      "Request-Tracking-Id": generateUniqueID(),
      "X-Authorization-CA": _.get(state, `userAccount.token.accessToken`, ``),
    },
    params: {
      locale: _.get(state, `siteLanguage.language`, `en_CA`),
    },
  }
  const apiKey = _.get(urls, `apiKey.ESB[${api}]`, ``)
  if (apiKey !== ``) {
    config.headers.ClientId = apiKey
  }

  const promises = _.map(orders, (orderItem) => {
    const url = MOCK_ORDER_INFORMATION
      ? `/mock/order_details_response.json`
      : template.expand({
          userId,
          orderId: _.get(orderItem, `id`, ``),
        })
    return axios.create(config).get(url)
  })

  return axios
    .all(promises)
    .then(
      axios.spread((...responses) => {
        const res = _.map(responses, (response, index) => {
          const orderId = _.get(orders, `[${index}].id`, ``)
          return {
            orderId,
            ..._.get(response, `data`, {}),
          }
        })
        dispatch(fulfilledAC(res))

        dispatch(
          setDataCache({
            field: CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA,
            /* cache results as an array of 1 null item if there are no results, this helps with tracking completion on the CombinedOrderHistory page  */
            data: res.length ? res : [null],
          }),
        )
      }),
    )
    .catch((error) => {
      dispatch(failedAC(_.get(error, `response.status`, 500)))
      return false
    })
}
