import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"
import { updateVisitorDL } from "utils-lib/analytics"

export const pendingAC = (type, data) => ({
  type,
  payload: data,
})

export const failedAC = (type, data) => ({
  type,
  payload: data,
})

export const fulfilledAC = (type, data) => ({
  type,
  payload: data,
})

export const resetSelectedAddress = (type) => ({
  type,
})

export const getLinkedAccounts =
  ({ token, userId = ``, timeStamp = null } = {}) =>
  (dispatch, getState) => {
    dispatch(pendingAC(ActionTypes.GET_LINKED_ACCOUNTS_PENDING, {}))
    const state = getState()

    const config = {
      params: {
        lang: _.get(state, `siteLanguage.language`, `en_CA`),
      },
    }

    if (token === undefined) {
      return getTokenAction(dispatch, getState).then((accessToken) =>
        getLinkedAccountsHelper(
          config,
          accessToken,
          _.get(state, `userAccount.userDetails.userId`, ``),
          timeStamp,
          dispatch,
          state,
        ),
      )
    }

    return getLinkedAccountsHelper(
      config,
      token,
      userId,
      timeStamp,
      dispatch,
      state,
    )
  }

export const getLinkedAccountsHelper = (
  config,
  token,
  userId,
  timeStamp,
  dispatch,
  state,
) => {
  const api = `USER_ACCOUNTS`
  const template = URITemplate(urls.url[api])
  /* Implement cache busting by adding timestamp to the URL to prevent caching in ie */
  const url = `${template.expand({ userId })}?timestamp=${
    timeStamp || Date.now()
  }`

  const apiConfig = { ...config }
  apiConfig.headers = {
    [urls.okta.tokenFieldKey]: token.accessToken,
  }

  const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
  if (apiKey !== ``) {
    apiConfig.headers.apiKey = apiKey
  }

  const isFirstTimeUser = _.get(
    state,
    `userAccount.userDetails.isFirstTimeUser`,
    false,
  )

  const reduxContactUpdate = _.get(
    state,
    `userManageAccount.contactUpdate`,
    false,
  )

  const reduxEligibleDashboardTour = _.get(
    state.userManageAccount,
    `eligibleDashboardTour`,
    [],
  )

  return axios
    .create(apiConfig)
    .get(url)
    .then((response) => {
      const eligibleDashboardTour = []
      const linkedAccounts = _.get(response, `data.data.linkedAccounts`, [])
      const isMulti = _.size(linkedAccounts) > 1
      const hasCanadian = !_.isEmpty(
        _.find(
          linkedAccounts,
          (item) => _.get(item, `serviceAddress.country`, ``) !== `US`,
        ),
      )

      const hasResi = !_.isEmpty(
        _.find(
          linkedAccounts,
          (item) => _.upperCase(_.get(item, `lob`, ``)) === `RESIDENTIAL`,
        ),
      )

      const hasComm = !_.isEmpty(
        _.find(
          linkedAccounts,
          (item) => _.upperCase(_.get(item, `lob`, ``)) === `COMMERCIAL`,
        ),
      )

      const hasRoll = !_.isEmpty(
        _.find(
          linkedAccounts,
          (item) => _.upperCase(_.get(item, `lob`, ``)) === `ROLLOFF`,
        ),
      )

      const eligibleToUpdate =
        _.get(response, `data.data.contactUpdate`, false) ||
        reduxContactUpdate ||
        isFirstTimeUser

      if (eligibleToUpdate || !_.isEmpty(reduxEligibleDashboardTour)) {
        eligibleDashboardTour.push(`dashboard`)
        isMulti && eligibleDashboardTour.push(`selector`)
        hasResi && eligibleDashboardTour.push(`residential`)
        hasComm && eligibleDashboardTour.push(`commercial`)
        hasRoll && eligibleDashboardTour.push(`rolloff`)
      }

      dispatch(
        fulfilledAC(ActionTypes.GET_LINKED_ACCOUNTS_FULFILLED, {
          contactUpdate:
            eligibleToUpdate &&
            !hasCanadian &&
            !_.isEmpty(eligibleDashboardTour),
          linkedAccounts,
          eligibleDashboardTour,
          eligibleTours: {
            residential: hasResi,
            commercial: hasComm,
            rolloff: hasRoll,
            selector: isMulti,
          },
        }),
      )

      window.cnxUserId = _.get(response, `data.data.userId`, ``)
      window.cnxCustomerIds = _.join(
        _.toArray(_.mapValues(_.slice(linkedAccounts, -5), `custAccountId`)),
      )

      /* update visitor data layer */
      updateVisitorDL({
        userAccount: state.userAccount,
        userManageAccount: { linkedAccounts },
      })

      return {
        linkedAccounts,
        token: token.accessToken,
        userId,
      }
    })
    .catch((response) => {
      const statusCode = _.get(response, `response.status`, ``)
      const errorMessage = _.get(
        response,
        `response.data.verificationErrorMsg`,
        ``,
      )
      const customerContacts = _.get(response, `response.data`)
      dispatch(
        failedAC(ActionTypes.GET_LINKED_ACCOUNTS_FAILED, {
          statusCode,
          errorMessage,
          customerContacts,
        }),
      )
    })
}

export const getCustomerUnLinkedAccountByEmail =
  (email) => (dispatch, getState) => {
    const api = `CUSTOMER_SEARCH_GUEST`
    const state = getState()

    dispatch(pendingAC(ActionTypes.GET_CUSTOMER_ACCOUNT_BY_EMAIL_PENDING, {}))
    // const getOptions = {
    //   url: `${urls.cloudfront.customerSearch.url}?email=${data}&lang=${lang}`,
    // }

    return getTokenAction(dispatch, getState).then((token) => {
      const userId = _.get(state, `userAccount.userDetails.userId`, ``)
      return axios
        .create({
          headers: {
            [urls.okta.tokenFieldKey]: token.accessToken,
            apiKey: _.get(urls, `apiKey.USER[${api}]`, ``),
          },
          params: {
            email,
            lang: _.get(state, `siteLanguage.language`, `en_CA`),
            userId,
          },
        })
        .get(urls.url[api])
        .then((response) => {
          dispatch(
            fulfilledAC(
              ActionTypes.GET_CUSTOMER_ACCOUNT_BY_EMAIL_FULFILLED,
              _.get(response, `data.accounts`, []),
            ),
          )
          return response
        })
        .catch((e) => {
          dispatch(
            failedAC(
              ActionTypes.GET_CUSTOMER_ACCOUNT_BY_EMAIL_FAILED,
              _.get(e, `response.status`, 403),
            ),
          )
        })
    })
  }

export const setLinkAccount = (data) => (dispatch, getState) => {
  const isMulti = _.isArray(data)
  const api = isMulti ? `USER_ACCOUNTS_MULTI_LINK` : `USER_ACCOUNTS`
  const state = getState()
  const template = URITemplate(urls.url[api])
  const userId = _.get(state, `userAccount.userDetails.userId`, ``)
  const url = template.expand({
    userId,
    lang: _.get(state, `siteLanguage.language`, `en_US`),
  })

  dispatch(pendingAC(ActionTypes.SET_LINKED_ACCOUNTS_PENDING, {}))

  return getTokenAction(dispatch, getState).then((token) => {
    const config = {
      headers: {
        [urls.okta.tokenFieldKey]: token.accessToken,
      },
      params: {
        lang: _.get(state, `siteLanguage.language`, `en_CA`),
      },
    }

    const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
    if (apiKey !== ``) {
      config.headers.apiKey = apiKey
    }

    return axios
      .create(config)
      .post(url, data)
      .then((response) => {
        const responseData = _.get(response, `data.data`)
        const statusCode = _.get(response, `data.statusCode`, 200)

        if (statusCode === 200) {
          if (isMulti) {
            dispatch(
              fulfilledAC(ActionTypes.SET_LINKED_ACCOUNTS_FULFILLED, {
                linkedAccount: responseData,
                numLinkedAccounts:
                  _.get(state, `userManageAccount.linkedAccounts`, []).length +
                  data.length,
              }),
            )
          } else {
            dispatch(
              fulfilledAC(ActionTypes.SET_LINKED_ACCOUNTS_FULFILLED, {
                linkedAccount: responseData,
                numLinkedAccounts:
                  _.get(state, `userManageAccount.linkedAccounts`, []).length +
                  1,
              }),
            )
          }

          dispatch(
            resetSelectedAddress(
              ActionTypes.RESET_SELECTED_ADRRESS_MY_SERVICES,
            ),
          )
        } else {
          dispatch(
            failedAC(
              ActionTypes.SET_LINKED_ACCOUNTS_FAILED,
              _.get(response, `data.statusCode`, ``),
            ),
          )
        }
        return response
      })
      .catch((e) => {
        dispatch(
          failedAC(
            ActionTypes.SET_LINKED_ACCOUNTS_FAILED,
            _.get(e, `response.data.statusCode`, ``),
          ),
        )
        return null
      })
  })
}

export const updateLinkAcount = (data) => (dispatch, getState) => {
  const api = `USER_ACCOUNTS`
  const state = getState()
  const template = URITemplate(urls.url[api])
  const userId = _.get(state, `userAccount.userDetails.userId`, ``)
  const url = template.expand({ userId })
  const encodedData = {
    nickName: data.nickName,
    custAccountId: data.custAccountId,
    billingZip: data.billingZip,
  }

  dispatch(pendingAC(ActionTypes.UPDATE_LINKED_ACCOUNTS_PENDING, {}))

  return getTokenAction(dispatch, getState).then((token) => {
    const config = {
      headers: {
        [urls.okta.tokenFieldKey]: token.accessToken,
      },
      params: {
        lang: _.get(state, `siteLanguage.language`, `en_CA`),
      },
    }

    const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
    if (apiKey !== ``) {
      config.headers.apiKey = apiKey
    }

    return axios
      .create(config)
      .put(url, encodedData)
      .then((response) => {
        const statusCode = _.get(response, `data.statusCode`, 200)
        if (statusCode === 200) {
          dispatch(
            fulfilledAC(
              ActionTypes.UPDATE_LINKED_ACCOUNTS_FULFILLED,
              encodedData,
            ),
          )
        } else {
          dispatch(
            failedAC(
              ActionTypes.UPDATE_LINKED_ACCOUNTS_FAILED,
              _.get(response, `data.errorMsg`, ``),
            ),
          )
        }
        return response
      })
      .catch((e) => {
        dispatch(
          failedAC(
            ActionTypes.UPDATE_LINKED_ACCOUNTS_FAILED,
            _.get(e, `response.data.errorMsg`, ``),
          ),
        )
        return null
      })
  })
}

export const hideManageAccountMessage = () => (dispatch) => {
  dispatch({ type: ActionTypes.HIDE_MANAGE_ACCOUNT_MESSAGE })
}
