import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import { serviceTypes } from "constants-lib/common"
import getLanguageRoute from "utils-lib/getLanguageRoute"
import {
  GET_CITY_BILLED_ELIGIBILITY_URL_PENDING,
  GET_CITY_BILLED_ELIGIBILITY_URL_FAILED,
  GET_CITY_BILLED_ELIGIBILITY_URL_FULFILLED,
} from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export const pendingAC = (productType) => ({
  type: GET_CITY_BILLED_ELIGIBILITY_URL_PENDING,
  productType,
})

export const failedAC = (fetchCode) => ({
  type: GET_CITY_BILLED_ELIGIBILITY_URL_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: GET_CITY_BILLED_ELIGIBILITY_URL_FULFILLED,
  payload: data,
})

/**
 * @typedef {Object} CustomAddress
 * @property {string} street - The street of the custom address.
 * @property {string} city - The city of the custom address.
 * @property {string} state - The state of the custom address.
 * @property {string} country - The country of the custom address.
 * @property {string} zipCode - The zip code of the custom address.
 */

/**
 * Checks the eligibility of a city based on the provided or selected customer address and dispatches appropriate actions.
 * @param {CustomAddress|null} customAddress - The custom address object to check eligibility for. If null, uses selected customer address from state.
 * @returns {Function} Dispatches actions based on the eligibility check outcome.
 */
export const getCityEligibility =
  (customAddress = null) =>
  (dispatch, getState) => {
    dispatch(pendingAC(serviceTypes.CITY_BILLED))
    const { customerSelections } = getState()
    const street =
      `${customerSelections.customerAddress.streetNumber} ${customerSelections.customerAddress.streetName}` ||
      ``
    if (street.trim() === ``) {
      dispatch(fulfilledAC({ invalidAddress: true }))
      return
    }

    // eslint-disable-next-line consistent-return
    return getCityEligibilityHelper(customAddress, dispatch, getState).then(
      (res) => {
        if (res) {
          if (res.url) {
            window.open(res.url, `_self`)
            dispatch(fulfilledAC(res))
          } else if (res.serviceUnavailable) {
            dispatch(failedAC({ serviceUnavailable: true, response: res }))
          } else if (res.disclaimerText) {
            dispatch(
              failedAC({
                serviceUnavailable: true,
                disclaimerText: res.miscInstructions,
                response: res,
              }),
            )
          } else {
            dispatch(failedAC({ searchFailed: true, response: res }))
          }
        }
      },
    )
  }

/**
 * Fetches city eligibility based on the provided or selected customer address and dispatches actions based on the response.
 * @param {CustomAddress|null} customAddress - The custom address object for eligibility check. If null, uses selected customer address from state.
 * @param {Function} dispatch - The Redux dispatch function.
 * @param {Function} getState - The Redux getState function to access the current state.
 * @returns {Promise} A promise that resolves to the dispatch of an action based on the eligibility check outcome.
 */
export const getCityEligibilityHelper = (customAddress, dispatch, getState) => {
  const { customerSelections, siteLanguage } = getState()
  const languageRoute = getLanguageRoute(siteLanguage.language)
  const street = customAddress
    ? customAddress.street
    : `${customerSelections.customerAddress.streetNumber} ${customerSelections.customerAddress.streetName}` ||
      ``
  const city = encodeURI(
    customAddress
      ? customAddress.city
      : customerSelections.customerAddress.city || ``,
  )
  const state = encodeURI(
    customAddress
      ? customAddress.state
      : customerSelections.customerAddress.state || ``,
  )
  const country = encodeURI(
    customAddress
      ? customAddress.country
      : customerSelections.customerAddress.country || ``,
  )
  const label = get(customerSelections, `customerAddress.label`, ``)
  const zipcode = customAddress
    ? customAddress.zipCode
    : customerSelections.customerAddress.postalCode || ``
  const locale = encodeURI(siteLanguage.language || ``)

  const api = `GET_CITY_BILLED`
  const apiKey = get(urls, `apiKey.GUEST[${api}]`, ``)
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    zipcode,
    locale,
    street,
    city,
    state,
    label,
    country,
  })

  const config = {
    url,
    method: `get`,
    headers: {
      apiKey,
      "Content-Type": `application/json`,
    },
  }
  return (
    axios(config)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        const data = get(response, "data.data")
        if (data) {
          const { isEligible, reason } = data

          if (isEligible === "Y" && reason === "cityBilledAndFranchise") {
            const { token } = response.headers
            data.token = token
            return {
              ...data,
              url: `${window.location.origin}${languageRoute}/home/bulk-trash-pickup/request`,
            }
          }
          if (isEligible === "N" && reason === "cityBilledAndFranchise") {
            const { token } = response.headers
            data.token = token
            return {
              ...data,
              url: `${window.location.origin}${languageRoute}/home/bulk-trash-pickup/`,
            }
          }
          if (
            (isEligible === "Y" || isEligible === "N") &&
            reason === "loggedInEligible"
          ) {
            return {
              ...data,
              url: `${window.location.origin}${languageRoute}/mywm/user/my-services`,
            }
          }
          if (isEligible === "Y" && reason === "oaklandResiCustomer") {
            return {
              ...data,
              url: `${window.location.origin}${languageRoute}/oakland-recycles`,
            }
          }
          return {
            ...data,
            serviceUnavailable: true,
            url: `${window.location.origin}${languageRoute}/home/bulk-trash-pickup/`,
          }
        }
      })
      .catch((error) => {
        dispatch(failedAC(error.response?.status))
        return { searchFailed: true }
      })
  )
}
