import { SET_LANGUAGE } from "constants-lib/actionTypes"

const initialState = {
  language: `en_US`, // en_CA, fr_CA or en_US
  // copy: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    // NEVER mutate the state, always return a new state.
    case SET_LANGUAGE:
      return {
        language: action.payload.language,
        // copy: action.payload.copy,
      }

    default:
      return state
  }
}
