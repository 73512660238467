import isString from "lodash/isString"
/**
 * Takes a string, removes all dashes, adds dashes every 4 characters,
 * and returns the number of dashes added.
 *
 * @function
 * @param {string} customerIdInput - The customer ID input from the user.
 * @returns {object} An object with two properties: `dashesAdded` and `customerId`.
 * `dashesAdded` is the number of dashes added to the customer ID.
 * `customerId` is the formatted customer ID with dashes every 4 characters.
 */
export const getFormattedCustomerId = (customerIdInput) => {
  if (!isString(customerIdInput)) {
    return { customerId: `` }
  }

  const rawCustomerId = customerIdInput.trim().replace(/-/g, "")
  const chunks = []

  for (let i = rawCustomerId.length; i > 0; i -= 5) {
    chunks.push(rawCustomerId.slice(Math.max(i - 5, 0), i))
  }

  const customerId = chunks.reverse().join("-")
  const dashesAdded = customerId.length - rawCustomerId.length

  return { dashesAdded, customerId }
}
