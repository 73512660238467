import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import { getTokenAction } from "actions"
import _ from "lodash"
import { urls } from "utils-lib/builds"

// Action creators
export const pendingAC = () => ({
  type: ActionTypes.GET_EXTRA_PICKUP_TICKET_DETAILS_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.GET_EXTRA_PICKUP_TICKET_DETAILS_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_EXTRA_PICKUP_TICKET_DETAILS_FULFILLED,
  payload: data,
})

export const getExtraPickupTicketDetails =
  ({
    customerId,
    serviceId,
    extraPickupRequestId,
    userId,
    lang,
    requireLogin = false,
  }) =>
  (dispatch, getState) => {
    const api = `GET_EXTRA_PICKUP_DETAILS`
    const template = URITemplate(urls.url[api])

    const url = template.expand({
      customerId,
      serviceId,
      extraPickupRequestId,
      userId,
      lang,
    })

    const config = {
      method: `GET`,
      headers: {},
    }

    if (!requireLogin) {
      return getTokenAction(dispatch, getState).then((res) => {
        config.headers[urls.okta.tokenFieldKey] = res.accessToken
        config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        return getExtraPickupTicketDetailsHelper(url, config, dispatch)
      })
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return getExtraPickupTicketDetailsHelper(url, config, dispatch)
    })
  }

export const getExtraPickupTicketDetailsHelper = (url, config, dispatch) => {
  dispatch(pendingAC())
  return axios
    .get(url, config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      return response
    })
    .catch((error) => {
      dispatch(failedAC(_.get(error, `response.status`, ``)))
    })
}
