import * as ActionTypes from "constants-lib/actionTypes"
import URITemplate from "urijs/src/URITemplate"
import { getTokenAction } from "actions"
import axios from "axios"
import moment from "moment"
import _ from "lodash"
import { clearDataCache } from "actions/clearDataCache"
import { urls } from "utils-lib/builds"
import { CACHE_KEYS } from "constants-lib/dataCache"

export const pendingAC = () => ({
  type: ActionTypes.SET_ROLL_OFF_SERVICE_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.SET_ROLL_OFF_SERVICE_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.SET_ROLL_OFF_SERVICE_FULFILLED,
  payload: data,
})

export const pendingAvailableDatesAC = () => ({
  type: ActionTypes.GET_ROLL_OFF_AVAILABLE_DATES_PENDING,
})

export const failedAvailableDatesAC = (fetchCode) => ({
  type: ActionTypes.GET_ROLL_OFF_AVAILABLE_DATES_FAILED,
  payload: fetchCode,
})

export const fulfilledAvailableDatesAC = (data) => ({
  type: ActionTypes.GET_ROLL_OFF_AVAILABLE_DATES_FULFILLED,
  payload: data,
})

export const setDuplicateRollOffRequest = (data) => ({
  type: ActionTypes.SET_DUPLICATE_ROLL_OFF_REQUEST,
  payload: data,
})

export const setRollOffServiceRequest =
  (
    data,
    customerId,
    locale,
    mode,
    token,
    onSuccess,
    requireLogin,
    onDuplicate,
    isAch,
  ) =>
  (dispatch, getState) => {
    const api = `CREATE_ROLLOFF_REQUEST`
    dispatch(pendingAC())

    const template = URITemplate(urls.url[api])
    const state = getState()
    const convenienceFee =
      state.processingFee?.processingFeeDetails?.convenienceFee
    const updatedData = { ...data, ...(!isAch && { convenienceFee }) }
    let url = template.expand({
      customerId,
      locale,
      mode: requireLogin ? `VERIFICATION_OPTED` : mode,
    })

    const config = {
      headers: {},
    }

    if (requireLogin) {
      return getTokenAction(dispatch, getState).then((res) => {
        const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)
        url += `&userId=${userId}`
        config.headers[urls.okta.tokenFieldKey] = res.accessToken
        config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

        return setRollOffServiceRequestHelper(
          url,
          updatedData,
          config,
          onSuccess,
          dispatch,
          getState,
          onDuplicate,
        )
      })
    }
    url += `&token=${token}&guestUser=Y`
    config.headers.apiKey = _.get(urls, `apiKey.GUEST[${api}]`, ``)

    return setRollOffServiceRequestHelper(
      url,
      updatedData,
      config,
      onSuccess,
      dispatch,
      onDuplicate,
    )
  }

export const setRollOffServiceRequestHelper = (
  url,
  data,
  config,
  onSuccess,
  dispatch,
  onDuplicate,
) =>
  axios
    .post(url, data, config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      onSuccess()
      return response
    })
    .catch((response) => {
      const statusCode = _.get(response, `response.status`, ``)
      if (statusCode === 406) {
        dispatch(setDuplicateRollOffRequest(data))
        if (onDuplicate) {
          onDuplicate()
        }
      } else {
        dispatch(failedAC(statusCode))
      }
    })

export const cancelDuplicateRollOffRequest = () => ({
  type: ActionTypes.RESET_DUPLICATE_ROLL_OFF_REQUEST,
})

export const getAvailableDates =
  (data, customerId, requireLogin = false) =>
  (dispatch, getState) => {
    const api = `ROLLOFF_DATES`
    dispatch(pendingAvailableDatesAC())
    const state = getState()

    const template = URITemplate(urls.url[api])
    let url = template.expand({
      fromDate: moment().add(0, `days`).format(`YYYY-MM-DD`),
      toDate: moment().add(2, `month`).format(`YYYY-MM-DD`),
      mode: requireLogin ? `REGULAR_PICKUP` : data.mode,
      lang: _.get(state, `siteLanguage.language`, `en_CA`),
      serviceId: data.serviceId,
      // state: data.state,
      customerId,
    })

    if (!_.isEmpty(data.ticketType)) {
      url = `${url}&ticketType=${data.ticketType}`
    }

    const config = {
      headers: {},
    }

    if (requireLogin) {
      return getTokenAction(dispatch, getState).then((token) => {
        const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)
        url += `&userId=${userId}`

        const serviceDate = _.get(data, `serviceDate`, ``)
        if (serviceDate) {
          url += `&serviceDate=${serviceDate}`
        }

        config.headers[urls.okta.tokenFieldKey] = token.accessToken
        config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

        return getAvailableDatesHelper(url, config, dispatch)
      })
    }
    config.headers.apiKey = _.get(urls, `apiKey.GUEST[${api}]`, ``)
    return getAvailableDatesHelper(url, config, dispatch)
  }

export const getAvailableDatesHelper = (url, config, dispatch) => {
  dispatch(clearDataCache(CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA))

  return axios
    .get(url, config)
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      dispatch(
        fulfilledAvailableDatesAC({
          dates: response.data,
          statusCode,
        }),
      )

      return response
    })
    .catch((error) => {
      dispatch(failedAvailableDatesAC(_.get(error, `response.status`, ``)))

      return error
    })
}
