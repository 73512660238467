import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

/**
 * This function dynamically loads the Google Maps JavaScript API into the document and runs a callback function when it's loaded.
 * @param {Function} callback - The function to be executed after the Google Maps API script has been successfully loaded.
 */
const loadGoogleMaps = (callback) => {
  const existingScript = window.document.getElementById("googleMaps")
  const googleMapKey = process.env.REACT_APP_GOOGLE_API_KEY

  if (!existingScript) {
    const script = window.document.createElement("script")
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapKey}&libraries=geometry,places`
    script.id = "googleMaps"
    window.document.body.appendChild(script)

    script.onload = () => {
      if (callback) callback()
    }
  }

  if (existingScript && callback) callback()
}

export default loadGoogleMaps
