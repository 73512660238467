import get from "lodash/get"

/**
 * Returns the formatted address by combining the raw/unformatted address components
 * and formatting them using regex (capitalize street, city, etc).
 * @param {Object} account - The account object that contains the address information.
 * @param {string} account.serviceAddress.street - The street address.
 * @param {string} account.serviceAddress.city - The city name.
 * @param {string} account.serviceAddress.state - The state name.
 * @param {string} account.serviceAddress.postalCode - The postal code.
 * @param {string} account.serviceAddress.country - The country name.
 * @returns {string} The formatted address.
 */
export const getFormattedAddress = (account) => {
  /* Get raw/unformatted address components */
  const rawStreet = get(account, `serviceAddress.street`, ``).toLowerCase()
  const rawCity = get(account, `serviceAddress.city`, ``).toLowerCase()
  const rawState = get(account, `serviceAddress.state`, ``)
  const rawCountry = get(account, `serviceAddress.country`, ``)

  let formattedAddress
  /* It's getting the postal code from the account object 
  and assigning it to the formattedAddress variable. */
  const rawPostal = get(account, `serviceAddress.postalCode`, ``)
  // eslint-disable-next-line prefer-const
  formattedAddress = `${rawStreet}, ${rawCity}, ${rawState} ${rawPostal} ${rawCountry}`

  /* Format address using regex (capitalize street, city, etc) */
  const regexPattern = /(\b[a-z](?!\s))/g
  return formattedAddress.replace(regexPattern, (o) => o.toUpperCase())
}
