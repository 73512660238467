import * as ActionTypes from "constants-lib/actionTypes"

export const initialState = {
  fetchState: ``,
  params: {},
  token: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_CUSTOMER_ACCOUNT_FULFILLED:
      return {
        ...state,
        token: action.payload.token !== undefined ? action.payload.token : ``,
      }

    case ActionTypes.GET_CUSTOMER_VERIFICATION_PENDING:
      return {
        ...state,
        fetchState: `pending`,
      }

    case ActionTypes.GET_CUSTOMER_VERIFICATION_FAILED:
      return {
        ...state,
        fetchState: `failed`,
      }

    case ActionTypes.GET_CUSTOMER_VERIFICATION_FULFILLED:
      return {
        ...state,
        fetchState: `fulfilled`,
        isVerified: action.payload.isVerified,
        params: action.payload.params,
        token: action.payload.verificationToken,
      }

    case ActionTypes.RESET_MYSERVICES_DASHBOARD:
      return {
        ...initialState,
        params: {},
      }

    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
      return {
        ...initialState,
        params: {},
      }

    default:
      return state
  }
}
