import PropTypes from "prop-types"
import { getFormattedCustomerId, getPaddedCustomerId } from "utils-lib/strings"
import { validateCustomerId } from "utils-lib/validation"
import { customerIdPattern } from "utils-lib/strings/maskPatterns"
import MaskedTextField from "../MaskedTextField/MaskedTextField"

import "./CustomerIDInput.scss"

const DEFAULT_MAX_INPUT_LENGTH = 17

const CustomerIDInput = ({
  placeholder,
  value,
  hideLabel,
  handleKeyDown,
  onChange,
  customPattern,
  maxLength,
  ...rest
}) => {
  const handleChange = (inputCustomerId) => {
    // const inputCustomerId = _.get(e, `target.value`, ``)
    let formattedCustomerId = ``
    if (inputCustomerId) {
      const paddedRawCustomerId = getPaddedCustomerId(inputCustomerId)
      formattedCustomerId =
        getFormattedCustomerId(paddedRawCustomerId).customerId
    }
    onChange(inputCustomerId, validateCustomerId(formattedCustomerId))
  }

  return (
    <MaskedTextField
      data-testid="CustomerIDInput"
      fullWidth
      variant="outlined"
      className="CustomerIdInput"
      onKeyDown={handleKeyDown}
      onAccept={handleChange}
      value={value}
      label={hideLabel ? null : placeholder}
      mask={customPattern || customerIdPattern}
      inputProps={{ maxLength }}
      {...rest}
    />
  )
}

CustomerIDInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleKeyDown: PropTypes.func,
  hideLabel: PropTypes.bool,
  customPattern: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  maxLength: PropTypes.number,
}

CustomerIDInput.defaultProps = {
  handleKeyDown: () => {},
  hideLabel: false,
  customPattern: null,
  maxLength: DEFAULT_MAX_INPUT_LENGTH,
  placeholder: "",
}

export default CustomerIDInput
