import {
  INCREMENT_BULK_PICKUP_ACTIVE_STEP,
  DECREMENT_BULK_PICKUP_ACTIVE_STEP,
  RESET_BULK_PICKUP_ACTIVE_STEP,
  SET_BULK_CUSTOMER_SELECTED_OPTIONS,
} from "constants-lib/actionTypes"

export const incrementBulkActiveStep = (incrementStep) => (dispatch) => {
  dispatch({
    type: INCREMENT_BULK_PICKUP_ACTIVE_STEP,
    payload: incrementStep,
  })
}

export const decrementBulkActiveStep = (decrementStep) => (dispatch) => {
  dispatch({
    type: DECREMENT_BULK_PICKUP_ACTIVE_STEP,
    payload: decrementStep,
  })
}

export const resetBulkActiveStep = () => (dispatch) => {
  dispatch({
    type: RESET_BULK_PICKUP_ACTIVE_STEP,
  })
}

export const setBulkPickupcustomerSelectedOptions =
  (selectedOptions) => (dispatch) => {
    dispatch({
      type: SET_BULK_CUSTOMER_SELECTED_OPTIONS,
      payload: selectedOptions,
    })
  }
