import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import * as actions from "actions"
import * as actionsLib from "actions-lib"
import { hasAddress } from "selectors/customerAddress"
import {
  setCustomerLob,
  setRenderDashboard,
  getCartDetails,
  resetCartDetails,
  getAEMJSON,
} from "actions-lib"
import App from "./App"

export const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getUserDetails: actions.getUserDetails,
      getLinkedAccounts: actions.getLinkedAccounts,
      getToken: actions.getToken,
      setToken: actions.setToken,
      revokeToken: actionsLib.revokeToken,
      refreshToken: actionsLib.refreshToken,
      resetUserAccount: actions.resetUserAccount,
      setViewport: actions.setViewport,
      switchLanguage: actions.switchLanguage,
      setMyServicesMain: actions.setMyServicesMain,
      setRenderDashboard,
      setSessionIntervalId: actions.setSessionIntervalId,
      setUserState: actions.setUserState,
      setUserDetails: actions.setUserDetails,
      setLastActivityTime: actions.setLastActivityTime,
      setBillingDetailsAccount: actions.setBillingDetailsAccount,
      resetCartDetails,
      getCartDetails,
      setCustomerLob,
      getServiceChangeHistory: actions.getServiceChangeHistory,
      getOrderSummary: actions.getOrderSummary,
      getExtraPickupHistory: actions.getExtraPickupHistory,
      getAEMJSON,
    },
    dispatch,
  ),
})

export const mapStateToProps = (state) => ({
  hasAddress: hasAddress(state),
  reduxState: {
    authentication: state.authentication,
    customerSelections: state.customerSelections,
    siteLanguage: state.siteLanguage,
    userAccount: state.userAccount,
    ecommerceCart: state.ecommerceCart,
    userManageAccount: state.userManageAccount,
    aemPageData: state.aemPageData,
    aemData: state.aemData,
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
