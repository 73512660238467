import get from "lodash/get"
import keys from "lodash/keys"
import axios from "axios"
import { urls } from "utils-lib/builds"
import URITemplate from "urijs/src/URITemplate"
import { getTokenAction } from "../getToken"

/**
 * Constructs data object for API requests, optionally overriding ezpayId based on the provided arguments and state.
 * @param {Object} state - The current application state.
 * @param {string} [overrideEzpayId] - Optional. Directly provided ezpayId, bypassing the default logic.
 * @returns {Object} The constructed data object containing userId, ezpayId, and optionally guestToken.
 */
function getData(state, overrideEzpayId) {
  const userId = get(state, `userAccount.userDetails.userId`, ``)

  const data = {
    userId,
  }

  /* when called from the COD RO flow, the ezpayId is passed directly */
  if (overrideEzpayId) {
    data.ezpayId = overrideEzpayId
  } else if (userId) {
    const customerIdsObj = get(state, `myPaymentCheckout.customerIDs`, {})
    const customerIds = keys(customerIdsObj)
    const firstCustomerId = customerIds[0]
    data.ezpayId = firstCustomerId
  } else {
    data.ezpayId = get(state, `customerSelections.customerId`, ``)
    data.guestToken = get(state, `myPaymentVerification.verificationToken`, ``)
  }

  return data
}

/**
 * Initiates a request to validate Apple Pay setup using the provided validation URL and optional override Ezpay ID.
 * @param {string} validationURL - The URL provided by Apple Pay for validation.
 * @param {string} [overrideEzpayId] - Optional. A specific Ezpay ID to use instead of the one from the state.
 * @returns {Function} A function that takes dispatch and getState as arguments and returns a promise resolving to the validation response.
 */

export const getApplePay =
  (validationURL, overrideEzpayId) => (dispatch, getState) => {
    const state = getState()

    const { ezpayId, guestToken, userId } = getData(state, overrideEzpayId)

    const api = userId ? `APPLE_PAY_LOGGED_IN` : `APPLE_PAY_GUEST`

    const template = URITemplate(urls.url[api])

    let url
    if (userId) {
      url = template.expand({
        ezpayId,
        validationURL,
        userId,
      })
    } else {
      url = template.expand({
        ezpayId,
        validationURL,
      })
    }

    let apiKey
    if (userId) {
      apiKey = get(urls, `apiKey.USER.APPLE_PAY`, ``)
    } else {
      apiKey = get(urls, `apiKey.GUEST.APPLE_PAY`, ``)
    }

    if (userId) {
      return getTokenAction(dispatch, getState).then((token) => {
        const config = {
          headers: {
            apiKey,
            token: token.accessToken,
          },
        }

        return axios
          .create(config)
          .get(url)
          .then((response) => response)
          .catch((e) => e)
      })
    }
    const config = {
      headers: {
        apiKey,
        token: guestToken,
      },
    }

    return axios
      .create(config)
      .get(url)
      .then((response) => response)
      .catch((e) => e)
  }
