import { localStorageKey } from "constants-lib/localStorage"
import { SET_TOKEN } from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export const setToken = (token) => {
  /* Save the token object so we can pass it to
     authClient.token.renew() later to refresh it. */
  window.localStorage.setItem(localStorageKey.TOKEN, JSON.stringify(token))
  return {
    type: SET_TOKEN,
    payload: token,
  }
}
