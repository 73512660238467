export const userAccountWidgetState = {
  REGISTER: 0,
  LOGIN: 1,
  FORGOT_PASSWORD: 2,
}

export const userType = {
  ANONYMOUS: 0,
  COOKIED: 1,
  LOGGING_IN: 2,
  LOGGED_IN: 3,
  LOGGING_OUT: 4,
  PROSPECTIVE: 5,
  EXISTING: 6,
}

/* Constants to help with development testing. */
export const DEV_DISABLE_AUTO_REFRESH = false
export const DEV_SHOW_EXPIRATION_WARNING = false // See expiration warning.
export const DEV_ENABLE_QUICK_AUTO_LOGOUT = false // Auto logs out after 1 minutes.

export const MS_PER_SEC = 1000
export const SECONDS_PER_MINUTE = 60

/* The interval wait time between checking for mouse/keyboard activity.
   Check activity every 5 seconds. */
export const TIME_BETWEEN_CHECKING_ACTIVITY = MS_PER_SEC * 5

/* The interval wait time between checking expiration status of session.
   Check session expiration status every 5 seconds. */
export const TIME_BETWEEN_SESSION_STATUS = MS_PER_SEC * 5

/* The amount of idle time allowed before auto-renewing an expiring token.
   Idle time allowed: 5 minute. */
export const IDLE_TIME_SECONDS = DEV_DISABLE_AUTO_REFRESH
  ? 10
  : SECONDS_PER_MINUTE * 5
export const IDLE_TIME_ALLOWED_BEFORE_AUTO_REFRESH =
  MS_PER_SEC * IDLE_TIME_SECONDS

/* How much time left before showing the token expiration warning.
   Okta gives 15 minutes in dev and in QA.
   Time left: 5 minutes. */
export const TIME_LEFT_BEFORE_SHOWING_EXPIRATION_WARNING_SEC =
  DEV_SHOW_EXPIRATION_WARNING
    ? SECONDS_PER_MINUTE * 15 - 10
    : SECONDS_PER_MINUTE * 5

/* How much time left before automatically logging the user out.
   We'll auto log out after 2 minutes of showing the expiration warning.
   Time left: 3 minutes, which means expiration warning will show for 2 minutes. */
export const TIME_LEFT_BEFORE_AUTO_LOGOUT_SEC = DEV_ENABLE_QUICK_AUTO_LOGOUT
  ? SECONDS_PER_MINUTE * 14 + 30
  : SECONDS_PER_MINUTE * 3
