import get from "lodash/get"
import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import { getWindow } from "utils-lib/getWindow"
import { getTokenAction } from "../getToken"

const window = getWindow()

export const pendingAC = () => ({
  type: ActionTypes.REVOKE_TOKEN_PENDING,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.REVOKE_TOKEN_FULFILLED,
  payload: data,
})

export const failedAC = (data) => ({
  type: ActionTypes.REVOKE_TOKEN_FAILED,
  payload: data,
})

/**
 * function to revoke an access token, either provided or retrieved, and handle the response.
 * @param {string|null} tokenToRevoke - The token to revoke. If null, the token will be retrieved using getTokenAction.
 * @returns {Function} A thunk function that takes dispatch and getState functions, revokes the token, and dispatches actions based on the outcome.
 */
export const revokeToken =
  (tokenToRevoke = null) =>
  (dispatch, getState) => {
    dispatch(pendingAC())

    if (tokenToRevoke !== null) {
      return revokeTokenHelper(tokenToRevoke, getState, dispatch)
    }

    return getTokenAction(dispatch, getState)
      .then((token) => revokeTokenHelper(token, getState, dispatch))
      .catch((error) => {
        dispatch(
          failedAC({
            statusCode: get(error, `response.status`, ``),
            errorMessage: get(error, `response.data.Error`, ``),
          }),
        )
      })
  }

/**
 * Revokes an access token and updates the application state based on the response.
 * @param {string} token - The access token to be revoked.
 * @param {Function} getState - A function to retrieve the current state of the Redux store.
 * @param {Function} dispatch - The Redux dispatch function to dispatch actions based on the API call's outcome.
 */
export const revokeTokenHelper = (token, getState, dispatch) => {
  const api = `REVOKE_TOKEN`
  const state = getState()
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    userId: get(state, `userAccount.userDetails.userId`, ``),
  })

  return axios
    .post(
      url,
      {
        tokenType: `access_token`,
        locale: state.siteLanguage.language,
      },
      {
        headers: {
          apiKey: get(urls, `apiKey.USER[${api}]`, ``),
          token: token.accessToken,
        },
      },
    )
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      window.cnxUserId = ``
      window.cnxCustomerIds = ``
      return response.data
    })
    .catch((error) => {
      dispatch(
        failedAC({
          statusCode: get(error, `response.status`, ``),
          errorMessage: get(error, `response.data.Error`, ``),
        }),
      )
    })
}
