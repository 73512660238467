import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  getCartDetailsStatus: ``,
  setCartDetailsStatus: ``,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CART_DETAILS_PENDING: {
      return {
        ...state,
        getCartDetailsStatus: `pending`,
      }
    }

    case ActionTypes.GET_CART_DETAILS_FULFILLED: {
      if (!action.payload.url) {
        // eslint-disable-next-line no-param-reassign
        action.payload.url = ``
      }
      return {
        ...state,
        ...action.payload.cart,
        getCartDetailsStatus: `fulfilled`,
      }
    }

    case ActionTypes.GET_CART_DETAILS_FAILED: {
      return {
        ...initialState,
        getCartDetailsStatus: `failed`,
      }
    }

    case ActionTypes.SET_CART_DETAILS_PENDING: {
      return {
        ...state,
        setCartDetailsStatus: `pending`,
      }
    }

    case ActionTypes.SET_CART_DETAILS_FULFILLED: {
      if (!action.payload.url) {
        // eslint-disable-next-line no-param-reassign
        action.payload.url = ``
      }
      return {
        ...state,
        ...action.payload,
        setCartDetailsStatus: `fulfilled`,
      }
    }

    case ActionTypes.SET_CART_DETAILS_FAILED: {
      return {
        ...initialState,
        setCartDetailsStatus: `failed`,
      }
    }

    case ActionTypes.RESET_CART_DETAILS: {
      return {
        ...state,
        setCartDetailsStatus: ``,
        getCartDetailsStatus: ``,
        id: ``,
        count: ``,
        url: ``,
      }
    }

    case ActionTypes.RESET_CART_STATUS: {
      return {
        ...state,
        setCartDetailsStatus: ``,
      }
    }

    default:
      return state
  }
}
