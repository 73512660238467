import { KEY_CODE_MAPPING } from "constants-lib/keyCodes"
import isFunction from "lodash/isFunction"

/**
 * Handles the enter and space bar key press events for accessibility purposes.
 * @param {Event} e - The key press event object.
 * @param {Function} callback - The function to be called on key press event.
 */
export const handleEnterPress = (e, callback) => {
  const code = e.keyCode || e.which
  if (code === KEY_CODE_MAPPING.ENTER || code === KEY_CODE_MAPPING.SPACE_BAR) {
    isFunction(callback) && callback()
  }
}

/**
 * Handles the enter key press event for accessibility purposes.
 * @param {Event} e - The key press event object.
 * @param {Function} callback - The function to be called on key press event.
 */
export const handleOnlyEnterPress = (e, callback) => {
  const code = e.keyCode || e.which
  if (code === KEY_CODE_MAPPING.ENTER) {
    isFunction(callback) && callback()
  }
}
