import _ from "lodash"

export default class WorkerPool {
  constructor(numWorkers = 1, workerScript) {
    const code = workerScript.toString()
    // eslint-disable-next-line no-undef
    const blob = new Blob([`(${code})()`])
    // eslint-disable-next-line no-undef

    this.workers = _.map(
      _.range(0, numWorkers),
      () => new Worker(URL.createObjectURL(blob)),
    )
  }

  shutDown = () => _.forEach(this.workers, (worker) => worker.terminate())

  deligateWork = (worker, work) => work(worker)

  /* leaving this here for later scalability, for now just return the single worker we have */
  getAvailableWorker = () => this.workers[0]

  scheduleJob = (work) => {
    const worker = this.getAvailableWorker()
    this.deligateWork(worker, work)
  }
}
