import PropTypes from "prop-types"
import { ReactSVG } from "react-svg"
import { makeStyles } from "tss-react/mui"
import getContentUrl from "utils-lib/getContentUrl"
import { isMyWMApp } from "utils-lib/isMyWMApp"
import { wmFonts } from "themes-lib/wmTheme"
import { ButtonMUI } from "../ButtonMUI/ButtonMUI"
import "./ButtonSecondary.scss"

const useStyles = makeStyles()((theme, { isYellowBg, isMyWMApp }) => ({
  root: {
    textTransform: `none !important`,
    background: `transparent ${!isMyWMApp ? "!important" : ""}`,
    border: isYellowBg
      ? `2px solid ${theme.palette.background.yellow}`
      : `2px solid ${theme.palette.primary.main}`,
    color: `${
      isYellowBg ? theme.palette.background.white : theme.palette.primary.main
    } ${!isMyWMApp ? "!important" : ""}`,
    borderRadius: isMyWMApp && `1.5rem !important`,
    padding: isMyWMApp && `0.375rem 2rem`,
    minHeight: isMyWMApp && `3rem !important`,
    height: isMyWMApp && `auto`,
    "&:hover": {
      background: `${
        isYellowBg
          ? theme.palette.secondary.hoverYellow
          : theme.palette.secondary.hoverGreen
      } !important`,
      boxShadow: `0 3px 6px 0 rgba(33,33,33,0.2)`,
    },
    "&:focus": {
      outline:
        !isMyWMApp &&
        `1px dashed ${
          isYellowBg
            ? theme.palette.background.yellow
            : theme.palette.primary.main
        }`,
      outlineOffset: 4,
    },
    "&:active": {
      backgroundColor: `${
        isYellowBg
          ? theme.palette.secondary.activeYellow
          : theme.palette.secondary.activeGreen
      } !important`,
    },
    "&:disabled": {
      cursor: `default`,
      backgroundColor: isMyWMApp
        ? `transparent !important`
        : theme.palette.text.bodyGray,
      opacity: isMyWMApp && `.2`,
      borderColor: `${
        isYellowBg ? theme.palette.text.gray3 : theme.palette.text.gray4
      } !important`,
      color: `${
        isYellowBg ? theme.palette.text.gray3 : theme.palette.text.gray4
      } !important`,
      "&:hover": {
        boxShadow: `none`,
      },
    },
  },
  beigeVariant: {
    textTransform: `none !important`,
    background: `transparent ${!isMyWMApp ? "!important" : ""}`,
    border: `2px solid ${theme.palette.background.beige}`,
    color: `${theme.palette.text.darkRemodeled} !important`,
    "&:hover": {
      background: `${theme.palette.background.beige} !important`,
    },
    "&:focus": {
      outline: `1px ${theme.palette.background.beige_25}`,
      outlineOffset: 4,
    },
    "&:active": {
      backgroundColor: `${theme.palette.background.beige_25} !important`,
    },
    "&:disabled": {
      cursor: `default`,
      border: `2px solid ${theme.palette.background.beige} !important`,
      color: `${
        isYellowBg ? theme.palette.text.gray3 : theme.palette.text.gray4
      } !important`,
    },
    ...wmFonts.maaxMedium,
  },
}))

// eslint-disable-next-line import/prefer-default-export
export const ButtonSecondary = ({
  beigeVariant,
  analytics_key: analyticsKey,
  children,
  className,
  onClick,
  padding,
  shouldNotTrackInComponent,
  hasLinkWrapper,
  isIconButton,
  isSCA,
  elementType,
  shouldPropagate,
  isYellowBg,
  component,
  testid,
  useDiv,
  // eslint-disable-next-line react/jsx-props-no-spreading
  ...rest
}) => {
  const { classes } = useStyles({
    isYellowBg,
    isMyWMApp,
  })

  const finalClassNames = `ButtonPrimary2 ${
    !beigeVariant && `maax-font-weight-bold`
  } ${padding || ""} ${className} ${
    beigeVariant ? classes.beigeVariant : classes.root
  }`

  if (useDiv) {
    return (
      <div
        data-testid="ButtonSecondary"
        // eslint-disable-next-line react/no-unknown-property
        analytics_key={analyticsKey}
        className={finalClassNames}
      >
        {children}
      </div>
    )
  }

  return (
    <ButtonMUI
      data-testid={testid}
      color="primary"
      analytics_key={analyticsKey}
      className={finalClassNames}
      onClick={(e) => {
        if (!shouldPropagate && !hasLinkWrapper) {
          e.preventDefault()
        }
        onClick(e)
      }}
      shouldNotTrackInComponent={shouldNotTrackInComponent}
      type="submit"
      isSCA={isSCA}
      elementType={elementType}
      component={component}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...rest}
    >
      {children}
      {isIconButton ? (
        <ReactSVG
          wrapper="span"
          src={getContentUrl("/content/dam/wm/icons/button-arrow.svg")}
          className="pl-3"
        />
      ) : (
        ``
      )}
    </ButtonMUI>
  )
}

ButtonSecondary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  padding: PropTypes.string,
  analytics_key: PropTypes.string,
  shouldNotTrackInComponent: PropTypes.bool,
  hasLinkWrapper: PropTypes.bool,
  isIconButton: PropTypes.bool,
  isSCA: PropTypes.bool,
  elementType: PropTypes.string,
  shouldPropagate: PropTypes.bool,
  useDiv: PropTypes.bool,
  isYellowBg: PropTypes.bool,
  component: PropTypes.string,
  testid: PropTypes.string,
  beigeVariant: PropTypes.bool,
}

ButtonSecondary.defaultProps = {
  className: ``,
  onClick: () => {},
  style: {},
  padding: ``,
  analytics_key: ``,
  shouldNotTrackInComponent: false,
  hasLinkWrapper: false,
  isIconButton: false,
  isSCA: false,
  elementType: `ButtonSecondary`,
  shouldPropagate: false,
  useDiv: false,
  isYellowBg: false,
  component: `button`,
  testid: "ButtonSecondary",
  beigeVariant: false,
}
