export const SET_USER_STATE = `SET_USER_STATE`

export const setUserState = (userState) => ({
  type: SET_USER_STATE,
  payload: userState,
})

const initialState = {
  userState: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_STATE:
      return {
        ...state,
        userState: action.payload,
      }

    default:
      return state
  }
}
