import {
  SET_CP_CONTACT_NUMBER,
  SET_CP_CATEGORY,
} from "constants-lib/actionTypes"

const initialState = {
  contactNumber: ``,
  category: "",
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CP_CONTACT_NUMBER:
      return {
        ...state,
        contactNumber: action.payload,
      }
    case SET_CP_CATEGORY:
      return {
        ...state,
        category: action.payload,
      }

    default:
      return state
  }
}
