export const GET_LOCALSITES_CDN = `GET_LOCALSITES_CDN`
export const GET_LOCALSITES_CDN_PENDING = `GET_LOCALSITES_CDN_PENDING`
export const GET_LOCALSITES_CDN_FULFILLED = `GET_LOCALSITES_CDN_FULFILLED`
export const GET_LOCALSITES_CDN_FAILED = `GET_LOCALSITES_CDN_FAILED`
export const UPDATE_CURRENT_LOCALSITE = `UPDATE_CURRENT_LOCALSITE`
export const GET_LOCALSITES_MAS_PENDING = `GET_LOCALSITES_MAS_PENDING`
export const GET_LOCALSITES_MAS_FULFILLED = `GET_LOCALSITES_MAS_FULFILLED`
export const GET_LOCALSITES_MAS_REJECTED = `GET_LOCALSITES_MAS_REJECTED`
export const SET_LOCALSITE_VISIT_FLAG = `SET_LOCALSITE_VISIT_FLAG`
export const GET_PERMALINK_LOCAL_SITES_CDN_PENDING = `GET_PERMALINK_LOCAL_SITES_CDN_PENDING`
export const GET_PERMALINK_LOCAL_SITES_CDN_FAILED = `GET_PERMALINK_LOCAL_SITES_CDN_FAILED`
export const GET_PERMALINK_LOCAL_SITES_CDN_FULFILLED = `GET_PERMALINK_LOCAL_SITES_CDN_FULFILLED`
