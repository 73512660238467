import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import {
  UPDATE_USER_PROFILE_PENDING,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_FULFILLED,
} from "constants-lib/actionTypes"
import { getTokenAction } from "../getToken"

const pendingAC = () => ({
  type: UPDATE_USER_PROFILE_PENDING,
})

const failedAC = (data) => ({
  type: UPDATE_USER_PROFILE_FAILED,
  payload: data,
})

const fulfilledAC = (data) => ({
  type: UPDATE_USER_PROFILE_FULFILLED,
  payload: data,
})

/**
 * Asynchronously updates the user profile with the provided data, handling success or error with Redux actions.
 * @param {Object} data - The data object containing the user's profile information to be updated.
 * @param {Function} errorCb - A callback function that is called when an error occurs during the profile update process.
 * @returns {Function} A function that dispatches Redux actions based on the update process's success or failure.
 */
export const updateUserProfile = (data, errorCb) => (dispatch, getState) => {
  const state = getState()
  const api = `USER_PROFILE`

  const template = URITemplate(urls.url[api])
  const url = template.expand({
    userId: get(state, `userAccount.userDetails.userId`, ``),
  })

  dispatch(pendingAC())

  return getTokenAction(dispatch, getState).then((token) => {
    const config = {
      headers: {
        token: token.accessToken,
      },
    }

    const apiKey = get(urls, `apiKey.USER[${api}]`, ``)
    if (apiKey !== ``) {
      config.headers.apiKey = apiKey
    }

    return axios
      .create(config)
      .post(url, data)
      .then((response) => {
        const errorMsg = get(response, `data.errorMsg`, ``)
        const statusCode = get(response, `data.statusCode`, ``)
        const responseData = get(response, `data.data.profile`, ``)

        const outData = {
          firstName: get(responseData, `firstName`, ``),
          lastName: get(responseData, `lastName`, ``),
          login: get(responseData, `login`, ``),
          mobilePhone: get(responseData, `mobilePhone`, ``) ?? ``,
          primaryPhone: get(responseData, `primaryPhone`, ``) ?? ``,
          newLogin: get(responseData, `newLogin`, ``),
        }

        dispatch(fulfilledAC({ errorMsg, statusCode, data: outData }))
        return response
      })
      .catch((error) => {
        const errorCode = get(error, `response.status`, ``)
        const errorMsg = get(error, `response.data.errorMsg`, ``)
        const statusCode = get(error, `response.data.statusCode`, ``)
        dispatch(failedAC({ errorCode, errorMsg, statusCode }))
        errorCb()
      })
  })
}
