import axios from "axios"
import {
  GET_AEM_PAGE_JSON_PENDING,
  GET_AEM_PAGE_JSON_FAILED,
  GET_AEM_PAGE_JSON_FULFILLED,
} from "constants-lib/actionTypes"
import { urls } from "utils-lib/builds"

const pendingAC = () => ({
  type: GET_AEM_PAGE_JSON_PENDING,
})

const failedAC = () => ({
  type: GET_AEM_PAGE_JSON_FAILED,
})

const fulfilledAC = (data) => ({
  type: GET_AEM_PAGE_JSON_FULFILLED,
  payload: data,
})

/**
 * Fetches AEM page JSON data from the specified path and locale.
 * @param {string} path - The path of the AEM page.
 * @param {string} locale - The locale of the AEM page.
 * @returns {Function} A Redux thunk function.
 */
export const getAEMPageJSON = (path, locale) => (dispatch) => {
  const modelPath = `${urls.aem.ROOT_AEM_SERVLET_PATH}${path}?locale=${locale}`

  dispatch(pendingAC())
  return axios
    .get(modelPath)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      return response.data
    })
    .catch(() => {
      dispatch(failedAC())
    })
}
