import axios from "axios"
import moment from "moment"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  GET_COMBINED_ORDER_HISTORY_PENDING,
  GET_COMBINED_ORDER_HISTORY_FAILED,
  GET_COMBINED_ORDER_HISTORY_FULFILLED,
  CLEAR_COMBINED_ORDER_HISTORY,
} from "constants-lib/actionTypes"
import {
  getTokenAction,
  setDataCache,
  clearCloudCrazeOrderHistory,
  clearDataCache,
} from "actions"
import { urls } from "utils-lib/builds"
import { CACHE_KEYS } from "constants-lib/dataCache"

export const pendingAC = () => ({
  type: GET_COMBINED_ORDER_HISTORY_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: GET_COMBINED_ORDER_HISTORY_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: GET_COMBINED_ORDER_HISTORY_FULFILLED,
  payload: data,
})

export const clearHistoryAC = () => ({
  type: CLEAR_COMBINED_ORDER_HISTORY,
})

export const clearCombinedOrderHistory = () => (dispatch) => {
  dispatch(clearHistoryAC())
  dispatch(clearCloudCrazeOrderHistory())
}

export const getCombinedOrderHistoryByCustomer =
  ({ userId, customerIds, lang }) =>
  (dispatch, getState) => {
    const api = `COMBINED_ORDER_HISTORY_BY_CUSTOMER`
    const template = URITemplate(urls.url[api])

    const fromDate = moment().subtract(`3`, `months`).format("YYYY-MM-DD")
    const toDate = moment().format("YYYY-MM-DD")
    const modifiedIds = {
      customerId: customerIds,
    }
    const url = template.expand({
      userId,
      lang,
      fromDate,
      toDate,
    })
    const config = {
      method: `POST`,
      headers: {},
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return getCombinedOrderHistoryByCustomerHelper(
        modifiedIds,
        url,
        config,
        dispatch,
      )
    })
  }

export const getCombinedOrderHistoryByCustomerHelper = (
  modifiedIds,
  url,
  config,
  dispatch,
) => {
  dispatch(pendingAC())
  dispatch(clearDataCache(CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA))

  return axios
    .create(config)
    .post(url, modifiedIds)
    .then((responses) => {
      console.log(responses)
      const res = _.get(responses, `data.data.orders`)
      dispatch(fulfilledAC(res))
      dispatch(
        setDataCache({
          field: CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA,
          /* cache results as an array of 1 null item if there are no results, this helps with tracking completion on the CombinedOrderHistory page  */
          data: res.length ? res : [null],
        }),
      )
      return res
    })
    .catch(() => {
      dispatch(failedAC())
    })
}
