// https://serverless-stack.com/chapters/create-a-route-that-redirects.html
import { Component } from "react"
import PropTypes from "prop-types"
import getLanguageRoute from "utils-lib/getLanguageRoute"
import { userType } from "constants-lib/authentication"
import { Route, Redirect } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import _ from "lodash"
import * as actions from "actions"

class RouterRoute extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasSearchedLinkAccounts: false,
    }
  }

  componentDidMount() {
    const userState = _.get(this.props.reduxState.userAccount, `userState`, ``)

    /* If the user is logged in and has no matching accounts, redirect to manage link accounts page. */
    if (userState === userType.LOGGED_IN) {
      this.checkLinkedAccounts()
    }
  }

  componentDidUpdate(prevProps) {
    const oldUserState = _.get(
      prevProps.reduxState.userAccount,
      `userState`,
      ``,
    )
    const userState = _.get(this.props.reduxState.userAccount, `userState`, ``)

    if (oldUserState !== userState && userState === userType.LOGGED_IN) {
      this.checkLinkedAccounts()
    }
  }

  /* Check if we need to search for linked accounts. */
  checkLinkedAccounts() {
    const status = _.get(
      this.props.reduxState.userManageAccount,
      `getLinkedAccountStatus`,
      ``,
    )
    if (status === ``) {
      this.props.reduxActions.getLinkedAccounts().then(() => {
        this.setState({
          hasSearchedLinkAccounts: true,
        })
      })
    } else {
      this.setState({
        hasSearchedLinkAccounts: true,
      })
    }
  }

  render() {
    const {
      component: C,
      layout: L,
      secondaryLayout: SL,
      props: cProps,
      requireLinkedAccounts,
      getPath,
      reduxState,
      ...rest
    } = this.props

    /* If in logging state, do not redirect yet, just wait. */
    const { userState } = cProps.userAccount
    if (userState === userType.LOGGING_IN) {
      return null
    }

    return (
      <Route
        {...rest}
        render={(props) => {
          const languageRoute = getLanguageRoute(cProps.locale)

          if (userState === userType.LOGGED_IN) {
            /* Check for linked accounts if required. */
            if (requireLinkedAccounts === true) {
              /* Check if we should wait for linked accounts to be fetched. */
              const status = _.get(
                this.props.reduxState.userManageAccount,
                `getLinkedAccountStatus`,
                ``,
              )
              if (
                this.state.hasSearchedLinkAccounts === false &&
                (status === `` || status === `pending`)
              ) {
                return null
              }

              /* Pass redux state and number of linked accounts to get the redirect path.
            If user is logged in with no matching accounts, redirect to manage accounts page.
            Otherwise, determine which url/path user should redirect to. */
              const linkedAccounts = _.get(
                this.props.reduxState.userManageAccount,
                `linkedAccounts`,
                [],
              )
              const path = getPath(reduxState, linkedAccounts.length)

              // Redirect to the end path.
              return <Redirect to={`${languageRoute}${path}`} />
            }
          } else if (userState === userType.LOGGING_OUT) {
            /* User chose to log out, so redirect user to home page instead of the login page. */
            return <Redirect to={`${languageRoute}`} />
          }
          /* Not logged in or logging out, go to login page. */
          return (
            <Redirect
              to={`${languageRoute}/user/login?redirect=${props.location.pathname}${props.location.search}`}
            />
          )
        }}
      />
    )
  }
}

RouterRoute.propTypes = {
  location: PropTypes.object,
  layout: PropTypes.func.isRequired,
  secondaryLayout: PropTypes.func,
  props: PropTypes.object.isRequired,
  getPath: PropTypes.func.isRequired,
  requireLinkedAccounts: PropTypes.bool,
  reduxActions: PropTypes.shape({
    getLinkedAccounts: PropTypes.func.isRequired,
  }).isRequired,
  reduxState: PropTypes.shape({
    userAccount: PropTypes.object.isRequired,
    userManageAccount: PropTypes.object.isRequired,
  }).isRequired,
  component: PropTypes.node.isRequired,
}

RouterRoute.defaultProps = {
  requireLinkedAccounts: true,
  location: {},
  secondaryLayout: undefined,
}

/* Convert AuthenticationRoute to be a container. */

export const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getLinkedAccounts: actions.getLinkedAccounts,
    },
    dispatch,
  ),
})

export const mapStateToProps = (state) => ({
  reduxState: {
    userAccount: state.userAccount, // TODO instead of passing in as cProp from App.jsx, use it from here
    userManageAccount: state.userManageAccount,
    customerAutoPayPaperless: state.customerAutoPayPaperless,
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(RouterRoute)
