import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.GET_USER_PAYMENT_METHODS_PENDING,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_USER_PAYMENT_METHODS_FULFILLED,
  payload: data,
})

export const failedAC = (data) => ({
  type: ActionTypes.GET_USER_PAYMENT_METHODS_FAILED,
  payload: data,
})

export const translateResponse = (response) => {
  const paymentMethods = _.map(
    _.get(response, `data.data.payMethods`, []),
    (method) => ({
      billingEmail: method.billingEmail,
      expMonth: _.get(method, `expMonth`, ``),
      expYear: _.get(method, `expYear`, ``),
      firstName: _.get(method, `firstName`, ``),
      lastName: _.get(method, `lastName`, ``),
      zip: _.get(method, `zip`, ``),
      country: _.get(method, `country`, ``),
      currency: _.get(method, `currency`, ``),
      methodAcctNo: method.methodAcctNo,
      methodType: method.methodType,
      bankRoutingNumber: method.bankRoutingNumber,
      methodSubType: method.methodSubType,
      methodStatus: method.methodStatus,
      nickName: method.nickName,
      payMethodId: method.payMethodId,
      isAutoPayLinked: method.autoPayLinked === `Yes`,
    }),
  )

  return { paymentMethods }
}

export const getUserPaymentMethods = () => (dispatch, getState) => {
  const api = `PAYMENT_METHODS`
  dispatch(pendingAC())

  return getTokenAction(dispatch, getState)
    .then((token) => {
      const state = getState()
      const config = {
        headers: {
          token: token.accessToken,
        },
        params: {
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
          lang: state.siteLanguage.language,
        },
      }

      const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      if (apiKey !== ``) {
        config.headers.apiKey = apiKey
      }

      return axios
        .create(config)
        .get(`${urls.url[api]}?timestamp=${Date.now()}`)
        .then((response) => {
          const translatedResponse = translateResponse(response)
          dispatch(fulfilledAC(translatedResponse))
          return translatedResponse
        })
    })
    .catch((response) => {
      dispatch(
        failedAC({
          statusCode: _.get(response, `response.status`, ``),
          errorMessage: _.get(response, `response.data.Error`, ``),
        }),
      )
    })
}
