import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import {
  GET_GUEST_CONTAINER_REPAIR_SERVICES_FULFILLED,
  GET_GUEST_CONTAINER_REPAIR_SERVICES_PENDING,
  GET_GUEST_CONTAINER_REPAIR_SERVICES_FAILED,
} from "constants-lib/actionTypes"

export const pendingAC = () => ({
  type: GET_GUEST_CONTAINER_REPAIR_SERVICES_PENDING,
})

export const failedAC = () => ({
  type: GET_GUEST_CONTAINER_REPAIR_SERVICES_FAILED,
})

export const fulfilledAC = (data) => ({
  type: GET_GUEST_CONTAINER_REPAIR_SERVICES_FULFILLED,
  payload: data,
})

export const getGuestContainerRepairServices =
  ({ language, eligibilityId, customerId, token }) =>
  (dispatch) => {
    const api = `GUEST_CONTAINER_REPAIR_SERVICES`
    const apiKey = _.get(urls, `apiKey.GUEST[${api}]`, ``)
    const template = URITemplate(urls.url[api])
    const url = template.expand({
      customerId,
      language,
      eligibilityId,
    })
    const config = {
      headers: {
        apiKey,
        token,
      },
    }
    return getGuestContainerRepairServicesHelper(url, config, dispatch)
  }

export const getGuestContainerRepairServicesHelper = (
  url,
  config,
  dispatch,
) => {
  dispatch(pendingAC())

  return axios
    .get(url, config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      return response
    })
    .catch((error) => {
      dispatch(failedAC())
      return error
    })
}
