import { SET_TOP_SUPPORT_TOPIC_DATA } from "constants-lib/actionTypes"

export const initialState = {
  topSupportTopicData: {
    category: "",
    subCategory: "",
    action: "",
  },
}
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TOP_SUPPORT_TOPIC_DATA:
      return {
        ...state,
        topSupportTopicData: action.payload,
      }

    default:
      return state
  }
}
