import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  pricing: {},
  getPricingState: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_REPAIR_PRICING_PENDING:
      return {
        ...state,
        getPricingState: `pending`,
      }

    case ActionTypes.GET_REPAIR_PRICING_FAILED:
      return {
        ...state,
        getPricingState: `failed`,
      }

    case ActionTypes.GET_REPAIR_PRICING_FULFILLED:
      return {
        ...state,
        pricing: action.payload.data || {},
        getPricingState: `fulfilled`,
      }

    default:
      return state
  }
}
