import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  CANCEL_RECYCLING_PICKUP_FULFILLED,
  CANCEL_RECYCLING_PICKUP_PENDING,
  CANCEL_RECYCLING_PICKUP_FAILED,
} from "constants-lib/actionTypes"
import { getTokenAction, updateOrderHistoryCache } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: CANCEL_RECYCLING_PICKUP_PENDING,
})

export const failedAC = () => ({
  type: CANCEL_RECYCLING_PICKUP_FAILED,
})

export const fulfilledAC = () => ({
  type: CANCEL_RECYCLING_PICKUP_FULFILLED,
})

export const cancelRecyclingPickupHelper = (url, config, dispatch) => {
  dispatch(pendingAC())

  return axios(url, config)
    .then((response) => {
      dispatch(updateOrderHistoryCache(response))
      dispatch(fulfilledAC())
      return response
    })
    .catch(() => {
      dispatch(failedAC())
    })
}

export const cancelRecyclingPickup =
  (serviceId, customerId, userId, ondemandPickupRequestId, lang) =>
  (dispatch, getState) => {
    const api = `CANCEL_RECYCLING_PICKUP`
    const template = URITemplate(urls.url[api])

    const url = template.expand({
      serviceId,
      customerId,
      userId,
      ondemandPickupRequestId,
      lang,
    })

    const config = {
      method: `DELETE`,
      headers: {},
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return cancelRecyclingPickupHelper(url, config, dispatch)
    })
  }
