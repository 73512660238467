/* eslint-disable max-len */
import { getWindow } from "utils-lib/getWindow"
import getBackendAPIs from "../getBackendAPIs"
import getMicrositeData from "../getMicrositeData"

const window = getWindow()

const origin = {
  EBIZ: `https://cpv-rest-api.wm.com`, // Bhargavi, Ramakanth: Replaces api.wmcanada.com.
  ESB: `https://webapicpv.wm.com/v1`,
  CDN: "https://stage-cdn.wm.com",
  SERVLET: `${window.location.origin}/bin`,
  ROOT_AEM_SERVLET_PATH: `${window.location.origin}/content/wm/servlets`,
  GENESYS: `https://wmchatint.wm.com/webapi/api/v2`,
}

const apiKeys = {
  /* Unique API keys for various groups of guest flow backend services. */
  GUEST: {
    LIVE_CHAT: `5057CE1FF767AE17B600`,
    PAYMENT: `917A6D3074587D37ED8C`,
    ROLLOFF: `162FB898B410D88ABC7E`,
    BALANCE: `19A524A913A4B64B903F`,
    CUSTOMER_SERVICES: `60BE3BD12DF2FE4E9198`,
    USER_REQUEST_SERVICE: `58D6263A43C0782A248F`,
    ADS_ACCOUNTS: `19A524A913A4B64B903F`,
    AWS_TRANSLATE: `us-east-1:30d30e7c-e59d-4826-8871-2e252cfa9a09`,
    ALGOLIA_API_KEY: `a879e0c540e46c2a18869bb329c46fb3`,
    ALGOLIA_APP_ID: `1W4TGL0C8E`,
    BULK_PICKUP_DATES: `19A524A913A4B64B903F`,
    SUBMIT_BULK_PICKUP: `58D6263A43C0782A248F`,
    HOLIDAYS_GUEST_BY_CUSTOMER_ID: `19A524A913A4B64B903F`,
    HOLIDAYS_GUEST_BY_ADDRESS: `B0474318FB620EB1C044`,
    CONTACT_INFO_GUEST_BY_CUSTOMER_ID: `19A524A913A4B64B903F`,
    CONTACT_INFO_GUEST_BY_ADDRESS: `B0474318FB620EB1C044`,
    ENERGY_SURCHARGE_CALCULATOR: `19A524A913A4B64B903F`,
    GUEST_BULK_PICKUP_GUIDELINES: `19A524A913A4B64B903F`,
    GUEST_BULK_PICKUP_DATES: `19A524A913A4B64B903F`,
    SUBMIT_GUEST_BULK_PICKUP: `19A524A913A4B64B903F`,
    GUEST_BULK_PICKUP_PRICING: `19A524A913A4B64B903F`,
    GET_CITY_BILLED: `19A524A913A4B64B903F`,
    TEXT_FOR_CITY_BILLED_BULK: `19A524A913A4B64B903F`,
  },
  /* Unique API keys for various groups of logged in user flow backend services. */
  USER: {
    PAYMENT: `6F06AF0FCFBCB762B2EC`,
    ROLLOFF: `97B25A4C1698C60E7757`,
    AUTHENTICATION: `59A2C3BDBA3160A042A3`,
    BALANCE_INVOICE: `B2104C2D169C9154EC70`,
    CUSTOMER_SERVICES: `E14AD4D5238E636B414B`,
    USER_ACCOUNTS: `3A4738BC2D3E71C5DE95`,
    USER_REQUEST_SERVICE: `B4941CA732AF920AD9DD`,
    INCIDENTS_BY_USER_ID: `59A2C3BDBA3160A042A3`,
    HOLIDAYS_USER_BY_CUSTOMER_ID: `59A2C3BDBA3160A042A3`,
    HOLIDAYS_USER_BY_ADDRESS: `59A2C3BDBA3160A042A3`,
    CONTACT_INFO_USER_BY_CUSTOMER_ID: `59A2C3BDBA3160A042A3`,
    CONTACT_INFO_USER_BY_ADDRESS: `59A2C3BDBA3160A042A3`,
  },
  ESB: {
    REFRESH_TOKEN: `L1x8j7GvLVj5_bO1v8l85K97-P1UPn1lp0VQey3vU2s`,
    ORDERS: `E5E2902D4E578F37CNQ3`,
  },
}

const okta = {
  clientId: `0oajsh2g196u1KP5F0h7`,
  issuer: `https://ssologinqa.wm.com/oauth2/auseqvqfxn0Y66ppa0h7`,
}

export default {
  ...getBackendAPIs({ origin, apiKeys, okta }),
  ...getMicrositeData(),
  chatbot: {
    awsConfig: {
      region: `us-east-1`,
      identityPoolId: `us-east-1:ceb4f6de-d943-45fe-911f-9afdecae2331`,
    },
    chatbotConfig: {
      botAlias: `qa`,
      botName: `WMBotQA`,
      lexUserIdPrefix: `chatbot-wm`,
    },
    host: {
      domain: `https://dqocrrt6dia9u.cloudfront.net`,
      subdirectory: `/config.json`,
    },
  },
  cloudfront: {
    melissaData: {
      emailCleanse: {
        url: `${origin.ESB}/emails/{emailId}/cleanse`,
      },
    },
    myPreferences: {
      getOptions: {
        url: `${origin.ESB}/customers/{customerId}/communication-preferences?locale={lang}`,
      },
      putOptions: {
        url: `${origin.ESB}/customers/{customerId}/communication-preferences?locale={lang}`,
      },
      putResendConfirmation: {
        url: `${origin.ESB}/customers/{customerId}/communication-preferences?resend={resend}&locale={lang}`,
      },
      contacts: {
        // url: `${cloudfrontHostName}/customer/{customerId}/contactInfo`,
        url: `${origin.ESB}/customers/{ezpayId}/contacts`,
      },
    },
    // My services customer account info
    alerts: {
      url: `${origin.EBIZ}/alerts/alerts.json`,
    },
    customerSupportLiveChat: {
      url: `${origin.EBIZ}/contactPref/getChatByAddress/zip/`,
    },
    paymentUrl: {
      url: `https://testsecureacceptance.cybersource.com/embedded/token/create`,
    },
    getStartedNavEn: {
      url: `${origin.EBIZ}/nav/wm-canada-nav_en_CA.json`,
    },
    getStartedNavFr: {
      url: `${origin.EBIZ}/nav/wm-canada-nav_fr_CA.json`,
    },
    productListEn: {
      url: `${origin.EBIZ}/products/product-list-wmca_en_CA.json`,
    },
    productListFr: {
      url: `${origin.EBIZ}/products/product-list-wmca_fr_CA.json`,
    },
  },
  cloudCraze: {
    headers: {
      ClientId: `0oa7tcatpmBbWBUII1t7`,
      ClientSecret: `C6IAlN_frO2jrYQ2s1nh_eKzFM17P4x5m0tP0oFd`,
    },
  },
  wm: {
    headers: {
      Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJiZ29sbGFAd20uY29tIiwic3ViIjoiV2FzdGUgbWFuYWdlbWVudCAgdGVhbSIsImp0aSI6IjMxOEQ1QUFEN0E0MTQ5OENFRDI2Iiwic2NvcGUiOlsic2VsZiIsImFsbCJdLCJpYXQiOjE1MDUzMTQxNzYsImV4cCI6MTU2ODM4NjE3Nn0.DlrES5SVfBBrkmf9rIYhT_pTn8eWKh4vpcLz7stL63M`,
      ClientId: `318D5AAD7A41498CED26`,
    },
  },
  commercialChat: {
    liveChatKey: `5738a000000PG4r`,
    liveChatApi: `https://d.la1-c1cs-ph2.salesforceliveagent.com/chat`,
    liveChatHelper: `00D22000000DMFO`,
    businessLiveChatkey: `5730h000000XZz8`,
    businessLiveChatApi: `https://d.la2-c1cs-iad.salesforceliveagent.com/chat`,
    businessLiveChatHelper: `00D0R000000msjj`,
  },
}
