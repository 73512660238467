import {
  RESET_GUEST_CONTAINER_REPAIR,
  GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_FULFILLED,
  GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_PENDING,
  GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_FAILED,
  GET_GUEST_CONTAINER_REPAIR_SERVICES_FULFILLED,
  GET_GUEST_CONTAINER_REPAIR_SERVICES_PENDING,
  GET_GUEST_CONTAINER_REPAIR_SERVICES_FAILED,
  GET_GUEST_CONTAINER_REPAIR_DATES_FULFILLED,
  GET_GUEST_CONTAINER_REPAIR_DATES_PENDING,
  GET_GUEST_CONTAINER_REPAIR_DATES_FAILED,
  RESET_GUEST_CONTAINER_REPAIR_DATES,
  GET_GUEST_CONTAINER_REPAIR_PRICING_FULFILLED,
  GET_GUEST_CONTAINER_REPAIR_PRICING_PENDING,
  GET_GUEST_CONTAINER_REPAIR_PRICING_FAILED,
  RESET_GUEST_CONTAINER_REPAIR_PRICING,
  GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_FAILED,
  GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_PENDING,
  GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_FULFILLED,
  RESET_GUEST_CONTAINER_REPAIR_TICKET,
  SET_GUEST_CONTAINER_REPAIR_SELECTION,
} from "constants-lib/actionTypes"

const initialState = {
  availableDates: [],
  getAvailableDatesState: ``,
  serviceData: {},
  getServiceDataState: ``,
  eligibilityData: {},
  eligibilityErrorInfo: {},
  getEligibilityStatus: ``,
  pricing: {},
  getPricingState: ``,
  createRepairRequestOrderDetails: {},
  createRepairRequestState: ``,
  selectedType: ``,
  selectedSize: ``,
  selectedIssue: ``,
  isChecked: false,
  formData: { fullName: "", phoneNumber: "", email: "" },
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET_GUEST_CONTAINER_REPAIR:
      return {
        ...state,
        ...initialState,
      }
    case GET_GUEST_CONTAINER_REPAIR_DATES_PENDING:
      return {
        ...state,
        getAvailableDatesState: `pending`,
      }

    case GET_GUEST_CONTAINER_REPAIR_DATES_FAILED:
      return {
        ...state,
        getAvailableDatesState: `failed`,
      }

    case GET_GUEST_CONTAINER_REPAIR_DATES_FULFILLED:
      return {
        ...state,
        availableDates: action.payload.data.availableDates || [],
        getAvailableDatesState: `fulfilled`,
      }

    case RESET_GUEST_CONTAINER_REPAIR_DATES:
      return {
        ...state,
        availableDates: [],
        getAvailableDatesState: ``,
      }

    case GET_GUEST_CONTAINER_REPAIR_SERVICES_PENDING:
      return {
        ...state,
        getServiceDataState: `pending`,
      }

    case GET_GUEST_CONTAINER_REPAIR_SERVICES_FAILED:
      return {
        ...state,
        getServiceDataState: `failed`,
      }

    case GET_GUEST_CONTAINER_REPAIR_SERVICES_FULFILLED:
      return {
        ...state,
        serviceData: action.payload.data || {},
        getServiceDataState: `fulfilled`,
      }

    case GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_PENDING:
      return {
        ...state,
        getEligibilityStatus: `pending`,
      }

    case GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_FAILED:
      return {
        ...state,
        eligibilityErrorInfo: action.payload,
        getEligibilityStatus: `failed`,
      }

    case GET_GUEST_CONTAINER_REPAIR_ELIGIBILITY_FULFILLED:
      return {
        ...state,
        eligibilityData: action.payload || {},
        getEligibilityStatus: `fulfilled`,
      }

    case GET_GUEST_CONTAINER_REPAIR_PRICING_PENDING:
      return {
        ...state,
        getPricingState: `pending`,
      }

    case GET_GUEST_CONTAINER_REPAIR_PRICING_FAILED:
      return {
        ...state,
        getPricingState: `failed`,
      }

    case GET_GUEST_CONTAINER_REPAIR_PRICING_FULFILLED:
      return {
        ...state,
        pricing: action.payload.data || {},
        getPricingState: `fulfilled`,
      }

    case RESET_GUEST_CONTAINER_REPAIR_PRICING:
      return {
        ...state,
        pricing: {},
        getPricingState: ``,
      }

    case GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_PENDING:
      return {
        ...state,
        createRepairRequestState: `pending`,
      }

    case GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_FAILED:
      return {
        ...state,
        createRepairRequestState: `failed`,
      }

    case GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_FULFILLED:
      return {
        ...state,
        createRepairRequestOrderDetails: action.payload.data || {},
        createRepairRequestState: `fulfilled`,
      }

    case RESET_GUEST_CONTAINER_REPAIR_TICKET:
      return {
        ...state,
        createRepairRequestOrderDetails: {},
        createRepairRequestState: ``,
      }

    case SET_GUEST_CONTAINER_REPAIR_SELECTION:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
