import join from "lodash/join"
import assign from "lodash/assign"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import { setSCAValues } from "actions-lib/scaValues"
import { getUserValues } from "utils-lib/analytics/sca"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { isBrokerAccountSelector } from "selectors-lib/linkedAccounts"
import Link from "./Link"

export const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setSCAValues,
    },
    dispatch,
  ),
})

export const mapStateToProps = (state, ownProps) => {
  if (ownProps.isSCA) {
    let customerId =
      ownProps.scaID || get(state.myServices, `selectedCustomerId`, ``)
    if (isEmpty(customerId)) {
      customerId = get(state.myServices, `selectedServiceObject.customerId`, ``)
    }

    return {
      scaValues: assign(
        {},
        state.scaValues,
        getUserValues({
          customerId: join(customerId, ``),
          isBrokerAccount: isBrokerAccountSelector(state),
          userManageAccount: state.userManageAccount,
          userBilling: state.userBilling,
          myServices: state.myServices,
        }),
      ),
    }
  }
  return {}
}

const connectedLink = connect(mapStateToProps, mapDispatchToProps)(Link)
export { connectedLink as Link }
