import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import each from "lodash/each"
import dayjs from "utils-lib/date"
import * as ActionTypes from "constants-lib/actionTypes"
import { LINE_OF_BUSINESS, API_STATUSES } from "constants-lib/common"
import { userType } from "constants-lib/authentication"
import { logRocketIdentify } from "utils-lib/logRocket"

export const initialState = {
  customerLob: ``,
  customerSelectedLob: ``,
  customerSelectedLanguage: null,
  customerProduct: ``,
  customerAddress: {
    formattedAddress: ``,
    label: ``,
    streetNumber: ``,
    streetName: ``,
    county: ``,
    lat: null,
    lng: null,
    city: ``,
    state: ``,
    country: ``,
    postalCode: ``,
    gPlaceId: ``,
  },
  customerSearchedAddress: {
    formattedAddress: ``,
    label: ``,
    streetNumber: ``,
    streetName: ``,
    county: ``,
    lat: null,
    lng: null,
    city: ``,
    state: ``,
    country: ``,
    postalCode: ``,
    gPlaceId: ``,
  },
  firstName: ``,
  lastName: ``,
  apartmentNumber: ``,
  businessType: {
    label: ``,
    industryClassification: ``,
    NAICSCode: ``,
  },
  guidedFlowSelections: {},
  contactBackAttributesStatus: "",
  contactBackAttributesData: {},
  cityBilledEligibility: {},
  cityBilledEligibilityStatus: ``,
  customerEmail: ``,
  customerId: ``,
  customerPhone: ``,
  customerAccounts: {}, // Search results for customer accounts.
  primaryAddress: ``, // The initial formatted address.
  customerServiceType: `empty`,
  customerContactUsMethod: ``,
  getCustomerAccountState: ``,
  fetchCode: 0,
  customerAccountSource: ``,
  renderDashboard: false,
  showErrorMessage: ``,
  getProductsUrlStatus: ``,
  productSupportData: {},
  getCombinedProductUrlStatus: ``,
  combinedProductSupportData: {},
  showCombinedProduct: false,
  showSuggestError: false,
  showSuggestErrorSchedule: false,
  servicesModal: false,
  modalId: ``,
  isMenuOpen: false,
  userProducts: {},
  alertsSelectedAccount: ``,
  alertsRead: false,
  isSearchAgain: false,
  isAddressSearch: true,
  hasNotifications: false,
  showMobileSearch: false,
  isSupportSearch: false,
  isSupportTopic: false,
  noFrenchContent: false,
  selectAreaModalClosed: false,
  formSubmitted: false,
  businessPickupData: {},
  selectedCatalogs: [],
  selectedTrashService: {},
  selectedTrashFrequency: {},
  selectedTrashQuantity: {},
  selectedRecyclingService: {},
  selectedRecyclingFrequency: {},
  selectedRecycleQuantity: {},
  selectedFoodWasteService: {},
  selectedFoodWasteFrequency: {},
  selectedFoodWasteQuantity: {},
  energySurchargeCalculatorStatus: ``,
  energySurchargeCalculatorResult: 0,
  movingIn: false,
  helpmeChooseBusiness: ``,
  customerAccountSearchState: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_CUSTOMER_ACCOUNT_FAILED:
      return {
        ...state,
        customerAccounts: {},
        getCustomerAccountState: API_STATUSES.FAILED,
        fetchCode: action.payload,
      }

    case ActionTypes.GET_CUSTOMER_ACCOUNT_PENDING:
      return {
        ...state,
        customerAccounts: {},
        getCustomerAccountState: API_STATUSES.PENDING,
        renderDashboard: initialState.renderDashboard,
        fetchCode: 0,
        customerAccountSearchState: API_STATUSES.PENDING,
      }

    case ActionTypes.GET_CUSTOMER_ACCOUNT_FULFILLED: {
      const userProducts = {}

      each(get(action, `payload.customerAccounts.accounts`, []), (account) => {
        const lob = get(account, `lineOfBusiness`, ``).toUpperCase()

        if (!userProducts.TRO && lob === LINE_OF_BUSINESS.ROLLOFF) {
          userProducts.TRO = true
        }

        if (!userProducts.SMB && lob === LINE_OF_BUSINESS.COMMERCIAL) {
          userProducts.SMB = true
        }

        if (!userProducts.OMR && lob === LINE_OF_BUSINESS.RESIDENTIAL) {
          userProducts.OMR = true
        }
      })

      let newLob = state.customerLob

      if (userProducts.TRO && userProducts.OMR && !userProducts.SMB) {
        newLob = `home`
      } else if (userProducts.TRO || userProducts.SMB) {
        newLob = `business`
      } else if (userProducts.OMR) {
        newLob = `home`
      }

      return {
        ...state,
        customerAccounts: action.payload.customerAccounts,
        getCustomerAccountState: API_STATUSES.FULFILLED,
        fetchCode: 0,
        userProducts,
        customerLob: newLob,
        customerSelectedLob: newLob,
      }
    }

    case ActionTypes.SET_CUSTOMER_SELECTED_LANGUAGE:
      return {
        ...state,
        customerSelectedLanguage: action.payload,
      }

    case ActionTypes.SET_CUSTOMER_LOB:
      return {
        ...state,
        customerLob: action.payload,
        customerSelectedLob: action.payload,
      }

    case ActionTypes.SET_CHECK_AVAILABILITY_LOB:
      return {
        ...state,
        checkAvailabilityLob: action.payload.checkAvailabilityLob,
      }

    case ActionTypes.SET_GEOCODE_CUSTOMER_ADDRESS: {
      const oldAddress = get(state, `customerAddress`, {})
      const newAddress = get(action, `payload`, {})

      const isSameAddress =
        oldAddress.streetNumber === newAddress.streetNumber &&
        oldAddress.streetName === newAddress.streetName &&
        oldAddress.city === newAddress.city
      return {
        ...state,
        customerAddress: isSameAddress ? state.customerAddress : action.payload,
        customerSearchedAddress: isSameAddress
          ? action.payload
          : state.customerSearchedAddress ||
            !get(state, `customerSearchedAddress.formattedAddress`, ``),
        isAddressSearch: false,
      }
    }

    case ActionTypes.SET_CUSTOMER_ADDRESS:
      return {
        ...state,
        customerAddress: action.payload || {
          formattedAddress: ``,
          label: ``,
          streetNumber: ``,
          streetName: ``,
          county: ``,
          lat: null,
          lng: null,
          city: ``,
          state: ``,
          country: ``,
          postalCode: ``,
          gPlaceId: ``,
        },
        customerSearchedAddress: action.payload,
        customerAccounts: {},
        getProductsUrlStatus: ``,
        renderDashboard: initialState.renderDashboard,
        customerId: ``,
        isAddressSearch: true,
      }

    case ActionTypes.SET_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload || ``,
        businessType: {
          label: ``,
          industryClassification: ``,
          NAICSCode: ``,
        },
      }

    case ActionTypes.GET_CITY_BILLED_ELIGIBILITY_URL_PENDING:
      return {
        ...state,
        cityBilledEligibilityStatus: API_STATUSES.PENDING,
      }
    case ActionTypes.GET_CITY_BILLED_ELIGIBILITY_URL_FULFILLED:
      return {
        ...state,
        cityBilledEligibility: {
          ...action.payload,
        },
        cityBilledEligibilityStatus: API_STATUSES.FULFILLED,
      }

    case ActionTypes.GET_CITY_BILLED_ELIGIBILITY_URL_FAILED:
      return {
        ...state,
        cityBilledEligibility: {
          ...action.payload,
        },
        cityBilledEligibilityStatus: API_STATUSES.FAILED,
        hasErrorMessage: true,
      }

    case ActionTypes.SET_LAST_NAME:
      return {
        ...state,
        lastName: action.payload || ``,
        businessType: {
          label: ``,
          industryClassification: ``,
          NAICSCode: ``,
        },
      }

    case ActionTypes.SET_BUSINESS_TYPE:
      return {
        ...state,
        businessType: action.payload || {
          label: ``,
          industryClassification: ``,
          NAICSCode: ``,
        },
        firstName: ``,
        lastName: ``,
      }

    case ActionTypes.SET_APARTMENT_NUMBER:
      return {
        ...state,
        apartmentNumber: action.payload || ``,
        businessType: {
          label: ``,
          industryClassification: ``,
          NAICSCode: ``,
        },
      }

    case ActionTypes.GET_ALERTS_ADDRESS_FULFILLED:
    case ActionTypes.GET_ALERTS_CUSTOMER_ID_FULFILLED:
    case ActionTypes.GET_ALERTS_USER_ID_FULFILLED:
      return {
        ...state,
        alertsRead: action.payload && action.payload?.isNotificationsPage,
        hasNotifications: !isEmpty(action.payload.notifications),
      }

    case ActionTypes.GET_INCIDENTS_BY_USER_ID_FULFILLED: {
      const customers = action.payload.incidents

      const isWithin30Days = getWithin30Days(customers)

      return {
        ...state,
        alertsRead:
          action.payload &&
          (action.payload.isNotificationsPage ||
            (!isWithin30Days && !state.hasNotifications)),
      }
    }

    case ActionTypes.SET_PRIMARY_ADDRESS: {
      const primaryAddress = action.payload

      return {
        ...state,
        primaryAddress,
      }
    }

    /* Clear state after logging in. */
    case ActionTypes.SET_USER_STATE: {
      if (action.payload === userType.LOGGED_IN) {
        return {
          ...initialState,
          customerAccounts: {},
          customerAddress: state.customerAddress,
          customerSearchedAddress: state.customerSearchedAddress,
          customerLob: state.customerLob,
          customerSelectedLob: state.customerSelectedLob,
          customerSelectedLanguage: state.customerSelectedLanguage,
          guidedFlowSelections: state.guidedFlowSelections,
          businessPickupData: state.businessPickupData,
          modalId: state.modalId,
        }
      }
      return {
        ...state,
      }
    }

    case ActionTypes.SET_ERROR_ON_SUGGEST: {
      return {
        ...state,
        showSuggestError: action.payload,
        getProductsUrlStatus: ``,
      }
    }

    case ActionTypes.SET_ERROR_ON_SUGGEST_SCHEDULE: {
      return { ...state, showSuggestErrorSchedule: action.payload }
    }

    case ActionTypes.GET_PRODUCTS_URL_PENDING:
      return {
        ...state,
        getProductsUrlStatus: API_STATUSES.PENDING,
        productSupportData: {},
        hasErrorMessage: ``,
        productType: action.productType,
      }

    case ActionTypes.GET_PRODUCTS_URL_FULFILLED:
      return {
        ...state,
        getProductsUrlStatus: API_STATUSES.FULFILLED,
        hasErrorMessage:
          !action.payload.renderSupportForm &&
          !action.payload.isCloudCrazeUrlValid,
        productSupportData: action.payload,
        businessPickupData: action.payload.businessPickupData,
        selectedDeliveryDate: "",
      }

    case ActionTypes.ADD_REMOVE_CATALOG:
    case ActionTypes.RESET_CATALOG: {
      return {
        ...state,
        selectedCatalogs: action.catalog,
      }
    }

    case ActionTypes.MOVING_IN: {
      return {
        ...state,
        movingIn: action.movingIn,
      }
    }

    case ActionTypes.SELECTED_DELIVERY_DATE: {
      return {
        ...state,
        selectedDeliveryDate: action.date,
      }
    }

    case ActionTypes.GET_PRODUCTS_CLEAR_STATE:
      return {
        ...state,
        getProductsUrlStatus: ``,
        productSupportData: {},
        hasErrorMessage: ``,
        combinedProductSupportData: {},
        getCombinedProductUrlStatus: ``,
      }

    case ActionTypes.GET_PRODUCTS_URL_FAILED:
      return {
        ...state,
        getProductsUrlStatus: API_STATUSES.FAILED,
        hasErrorMessage: true,
      }

    case ActionTypes.GET_COMBINED_PRODUCT_URL_CLEAR_STATE:
      return {
        ...state,
        getCombinedProductUrlStatus: ``,
        combinedProductSupportData: {},
      }

    case ActionTypes.GET_COMBINED_PRODUCT_URL_PENDING:
      return {
        ...state,
        getCombinedProductUrlStatus: API_STATUSES.PENDING,
        combinedProductSupportData: {
          // Prevent flashing of select area modal
          localsitesResults: get(
            state,
            `combinedProductSupportData.localsitesResults`,
            [],
          ),
        },
        getProductsUrlStatus: ``,
        productSupportData: {},
        hasErrorMessage: ``,
      }

    case ActionTypes.GET_COMBINED_PRODUCT_URL_FULFILLED:
      return {
        ...state,
        getCombinedProductUrlStatus: API_STATUSES.FULFILLED,
        combinedProductSupportData: action.payload,
      }

    case ActionTypes.GET_COMBINED_PRODUCT_URL_FAILED:
      return {
        ...state,
        getCombinedProductUrlStatus: API_STATUSES.FAILED,
      }

    case ActionTypes.SHOW_COMBINED_PRODUCT_STATE:
      return {
        ...state,
        showCombinedProduct: true,
      }

    case ActionTypes.HIDE_COMBINED_PRODUCT_STATE:
      return {
        ...state,
        showCombinedProduct: false,
      }

    case ActionTypes.SET_MODAL_ID:
      return {
        ...state,
        modalId: action.payload.modalId,
      }

    case ActionTypes.SET_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: action.payload.isMenuOpen,
      }

    case ActionTypes.GET_USER_PAYMENT_STATUS_FULFILLED: {
      const userProducts = {}

      each(action.payload, (account) => {
        const lob = get(account, `lineOfBusiness`, ``).toUpperCase()

        if (!userProducts.TRO && lob === LINE_OF_BUSINESS.ROLLOFF) {
          userProducts.TRO = true
        }

        if (!userProducts.SMB && lob === LINE_OF_BUSINESS.COMMERCIAL) {
          userProducts.SMB = true
        }

        if (!userProducts.OMR && lob === LINE_OF_BUSINESS.RESIDENTIAL) {
          userProducts.OMR = true
        }
      })

      let newLob = state.customerLob

      if (userProducts.TRO && userProducts.OMR && !userProducts.SMB) {
        newLob = `home`
      } else if (userProducts.TRO || userProducts.SMB) {
        newLob = `business`
      } else if (userProducts.OMR) {
        newLob = `home`
      }

      return {
        ...state,
        userProducts: isEmpty(userProducts) ? state.userProducts : userProducts,
        customerLob: newLob,
        customerSelectedLob: newLob,
      }
    }

    case ActionTypes.GET_LINKED_ACCOUNTS_FULFILLED: {
      const userProducts = {}

      each(action.payload.linkedAccounts, (account) => {
        const lob = get(account, `lob`, ``).toUpperCase()

        if (!userProducts.TRO && lob === LINE_OF_BUSINESS.ROLLOFF) {
          userProducts.TRO = true
        }

        if (!userProducts.SMB && lob === LINE_OF_BUSINESS.COMMERCIAL) {
          userProducts.SMB = true
        }

        if (!userProducts.OMR && lob === LINE_OF_BUSINESS.RESIDENTIAL) {
          userProducts.OMR = true
        }
      })

      let newLob = state.customerLob

      if (userProducts.TRO && userProducts.OMR && !userProducts.SMB) {
        newLob = `home`
      } else if (userProducts.TRO || userProducts.SMB) {
        newLob = `business`
      } else if (userProducts.OMR) {
        newLob = `home`
      }

      return {
        ...state,
        userProducts,
        customerLob: newLob,
        customerSelectedLob: newLob,
      }
    }

    case ActionTypes.SET_SHOW_MOBILE_SEARCH:
      return {
        ...state,
        showMobileSearch: action.payload.showMobileSearch,
        isSupportSearch: !!action.payload.isSupportSearch,
      }

    case ActionTypes.SET_IS_SUPPORT_TOPIC:
      return {
        ...state,
        isSupportTopic: action.payload.isSupportTopic,
      }

    case ActionTypes.SET_NO_FRENCH_CONTENT:
      return {
        ...state,
        noFrenchContent: action.payload.noFrenchContent,
      }

    case ActionTypes.SET_SELECT_AREA_MODAL_CLOSED:
      return {
        ...state,
        selectAreaModalClosed: action.payload.selectAreaModalClosed,
      }

    case ActionTypes.SET_ALERTS_READ:
      return {
        ...state,
        alertsRead: action.payload.alertsRead,
      }

    case ActionTypes.SET_FORM_SUBMITTED:
      return {
        ...state,
        formSubmitted: action.payload.formSubmitted,
      }

    case ActionTypes.SET_ALERTS_SELECTED_ACCOUNT:
      return {
        ...state,
        alertsSelectedAccount: action.payload.alertsSelectedAccount,
      }

    case ActionTypes.SET_CUSTOMER_ID: {
      const customerId = action.payload

      logRocketIdentify({
        name: `Guest (Customer ID)`,
        customerId,
      })

      return {
        ...state,
        customerId,
      }
    }

    case ActionTypes.SET_CUSTOMER_EMAIL: {
      const customerEmail = action.payload

      logRocketIdentify({
        name: `Guest (Email)`,
        email: customerEmail,
      })

      return {
        ...state,
        customerEmail: action.payload,
      }
    }

    case ActionTypes.SET_CUSTOMER_PHONE: {
      const customerPhone = action.payload

      logRocketIdentify({
        name: `Guest (Phone)`,
        phone: customerPhone,
      })

      return {
        ...state,
        customerPhone,
      }
    }

    case ActionTypes.SET_CUSTOMER_PRODUCT:
      return {
        ...state,
        productSupportData: {},
        customerProduct: action.payload,
      }

    case ActionTypes.SET_HAS_GEOSUGGESTION:
      if (action.payload) {
        return {
          ...state,
          showErrorMessage: false,
        }
      }
      return {
        ...state,
        showErrorMessage: true,
      }

    case ActionTypes.SET_CUSTOMER_SERVICE_TYPE:
      return {
        ...state,
        getProductsUrlStatus: ``,
        productSupportData: {},
        customerServiceType: action.payload,
      }

    case ActionTypes.SET_CUSTOMER_CONTACT_US_METHOD:
      return {
        ...state,
        customerContactUsMethod: action.payload,
      }

    case ActionTypes.SET_RENDER_DASHBOARD:
      return {
        ...state,
        renderDashboard: action.payload,
      }

    case ActionTypes.RESET_MYSERVICES_DASHBOARD:
    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
      return {
        ...initialState,
        alertsRead: state.alertsRead,
        customerAccounts: {},
        customerAddress: state.customerAddress,
      }

    case ActionTypes.SET_FIRST_COMPONENT_LOADED:
      return { ...state, getProductsUrlStatus: ``, productSupportData: {} }

    case ActionTypes.GET_ALERTS_ADDRESS_PENDING:
    case ActionTypes.GET_ALERTS_CUSTOMER_ID_PENDING:
    case ActionTypes.GET_ALERTS_USER_ID_PENDING:
    case ActionTypes.GET_ALERTS_ADDRESS_FAILED:
    case ActionTypes.GET_ALERTS_CUSTOMER_ID_FAILED:
    case ActionTypes.GET_ALERTS_USER_ID_FAILED:
      return {
        ...state,
        hasNotifications: false,
      }

    case ActionTypes.CLEAR_GUEST_DATA:
      return {
        ...initialState,
        alertsRead: state.alertsRead,
        customerLob: state.customerLob,
        customerSelectedLob: state.customerSelectedLob,
        userProducts: state.userProducts,
        isSearchAgain: true,
      }

    case ActionTypes.SET_IS_SEARCH_AGAIN:
      return {
        ...state,
        isSearchAgain: action.payload.isSearchAgain,
      }

    case ActionTypes.RESET_CUSTOMER_SERVICE_TYPE:
      return {
        ...state,
        customerContactUsMethod: initialState.customerContactUsMethod,
      }

    case ActionTypes.SET_CUSTOMER_ACCOUNT_SOURCE:
      return {
        ...state,
        customerAccountSource: action.payload,
      }

    case ActionTypes.SET_GUIDED_FLOW_SELECTIONS:
      return {
        ...state,
        guidedFlowSelections: {
          ...action.payload,
        },
      }

    case ActionTypes.GET_CONTACT_BACK_ATTRIBUTES_PENDING:
      return {
        ...state,
        contactBackAttributesStatus: API_STATUSES.PENDING,
      }

    case ActionTypes.GET_CONTACT_BACK_ATTRIBUTES_FULFILLED:
      return {
        ...state,
        contactBackAttributesStatus: API_STATUSES.FULFILLED,
        contactBackAttributesData: action.payload,
      }

    case ActionTypes.GET_CONTACT_BACK_ATTRIBUTES_FAILED:
      return {
        ...state,
        contactBackAttributesStatus: API_STATUSES.FAILED,
      }

    case ActionTypes.GET_ENERGY_SURCHARGE_CALCULATOR_RESULT_PENDING:
      return {
        ...state,
        energySurchargeCalculatorStatus: API_STATUSES.PENDING,
      }

    case ActionTypes.GET_ENERGY_SURCHARGE_CALCULATOR_RESULT_FULFILLED:
      return {
        ...state,
        energySurchargeCalculatorStatus: API_STATUSES.FULFILLED,
        energySurchargeCalculatorResult: action.payload,
      }

    case ActionTypes.GET_ENERGY_SURCHARGE_CALCULATOR_RESULT_FAILED:
      return {
        ...state,
        energySurchargeCalculatorStatus: API_STATUSES.FAILED,
      }
    case ActionTypes.RESET_ENERGY_SURCHARGE_CALCULATOR_RESULT:
      return {
        ...state,
        energySurchargeCalculatorStatus: ``,
        energySurchargeCalculatorResult: 0,
      }

    case ActionTypes.SET_BUISNESS_HELPMECHOOSE:
      return {
        ...state,
        helpmeChooseBusiness: action.payload,
      }

    default:
      return state
  }
}

/**
 * Checks if any customer has incidents within the last 30 days.
 * @param {Array} customers - The array of customers.
 * @returns {boolean} - Returns true if any customer has incidents within the last 30 days, otherwise false.
 */
export const getWithin30Days = (customers) =>
  customers.some((customer) =>
    customer.incidents.some((incident) => {
      const incidentDate = dayjs(incident.date)
      const date30DaysAgo = dayjs().subtract(30, "days")

      return incidentDate.isAfter(date30DaysAgo)
    }),
  )
