import get from "lodash/get"
import {
  GET_CUSTOMER_ACCOUNT_PENDING,
  GET_CUSTOMER_ACCOUNT_FULFILLED,
  DONT_SEE_AREA_CLICKED,
  GET_LOCALSITES_MAS_REJECTED,
  GET_LOCALSITES_MAS_PENDING,
  GET_LOCALSITES_MAS_FULFILLED,
} from "constants-lib/actionTypes"
import { lob as LOB, LINE_OF_BUSINESS } from "constants-lib/common"

const initialState = {
  currentFetchState: ``,
  lob: ``,
  localsites: [],
  dontSeeAreaClicked: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCALSITES_MAS_REJECTED:
      return {
        currentFetchState: `rejected`,
        error: action.payload,
      }

    case GET_LOCALSITES_MAS_PENDING:
      return {
        currentFetchState: `pending`,
        localsites: [],
      }

    case GET_LOCALSITES_MAS_FULFILLED: {
      const masLob = get(action, `payload.customers[0].line_of_business`, ``)
      let lob = ``
      if (masLob === LINE_OF_BUSINESS.RESIDENTIAL) {
        lob = LOB.HOME
      } else if (masLob !== ``) {
        lob = LOB.BUSINESS
      }

      return {
        currentFetchState: `fulfilled`,
        ...action.payload,
        lob,
      }
    }

    case GET_CUSTOMER_ACCOUNT_PENDING: {
      return {
        ...state,
        localsites: [],
        dontSeeAreaClicked: action?.payload?.dontResetDontSeeAreaClicked
          ? state.dontSeeAreaClicked
          : false,
      }
    }

    case GET_CUSTOMER_ACCOUNT_FULFILLED: {
      const masLob = get(action, `payload.customers[0].line_of_business`, ``)
      let lob = ``
      if (masLob === LINE_OF_BUSINESS.RESIDENTIAL) {
        lob = LOB.HOME
      } else if (masLob !== ``) {
        lob = LOB.BUSINESS
      }

      return {
        currentFetchState: `fulfilled`,
        lob,
        localsites: get(action, `payload.customerAccounts.localsites`, []),
        dontSeeAreaClicked: state.dontSeeAreaClicked,
      }
    }

    case DONT_SEE_AREA_CLICKED:
      return {
        ...state,
        dontSeeAreaClicked: true,
      }

    // case ActionTypes.LOGGED_IN:
    //   return initialState

    default:
      return state
  }
}
