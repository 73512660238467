import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"

import { urls } from "utils-lib/builds"

export const pendingAC = (customerId) => ({
  type: ActionTypes.GET_MY_WM_PREFERENCE_VERIFICATION_PENDING,
  payload: customerId,
})

export const failedAC = (failedResponseData) => ({
  type: ActionTypes.GET_MY_WM_PREFERENCE_VERIFICATION_FAILED,
  payload: failedResponseData,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_MY_WM_PREFERENCE_VERIFICATION_FULFILLED,
  payload: data,
})

export const jwtPref = (data) => ({
  type: ActionTypes.GET_MY_WM_PREFERENCE_VERIFICATION_STORE_JWT,
  payload: data,
})

export const resetCustomerContacts = () => ({
  type: ActionTypes.RESET_CUSTOMER_CONTACTS,
})

/**
 * Fetches customer preference verification information based on user status and location.
 * @param {Object} params - The parameters for the function.
 * @param {string} params.customerId - The ID of the customer.
 * @param {string} params.postalcode - The postal code of the customer's address.
 * @param {string} params.lang - The language of the user.
 * @param {string} params.mode - The mode of the request.
 * @param {Object} params.router - The router object.
 * @param {string} params.redirectUrl - The URL to redirect to.
 * @param {function} onError - The function to call when an error occurs.
 * @returns {function} A function that takes the Redux dispatch and getState functions as arguments.
 */
export const getCustomerPrefVerification =
  (
    {
      customerId,
      postalcode,
      lang = null,
      mode = `PREFERENCES`,
      router = null,
      redirectUrl = null,
    },
    onError,
  ) =>
  (dispatch, getState) => {
    const api = `CUSTOMER_VERIFICATION`
    const template = URITemplate(urls.url[api])

    let customerLanguage = lang
    if (!customerLanguage) {
      const { siteLanguage } = getState()
      customerLanguage = siteLanguage.language
    }

    const preferenceInformationVerification = template.expand({
      customerId,
    })
    dispatch(pendingAC(customerId))

    return axios
      .create({
        headers: {
          apiKey: get(urls, `apiKey.GUEST[${api}]`, ``),
        },
        params: {
          billingPostalCode: postalcode,
          lang: customerLanguage,
          mode,
        },
      })
      .get(preferenceInformationVerification)
      .then((response) => {
        const statusCode = get(response, `status`, ``)
        const verificationToken = get(response, `headers.token`, ``)
        dispatch(
          fulfilledAC({
            data: response.data,
            verificationToken,
            statusCode,
          }),
        )

        return response
      })
      .then((response) => {
        const verificationToken = get(response, `headers.token`, ``)
        if (response.data.customerVerified === `Y` && router && redirectUrl) {
          router.push(`${redirectUrl}?token=${verificationToken}`)
        }
        return response
      })
      .catch((error) => {
        if (onError) {
          onError()
        }

        const fetchCode = get(error, `response.status`, 403)
        const errorMessage = get(
          error,
          `response.data.verificationErrorMsg`,
          ``,
        )
        const customerVerified = get(
          error,
          `response.data.customerVerified`,
          ``,
        )

        dispatch(
          failedAC({
            fetchCode,
            errorMessage,
            customerVerified,
          }),
        )
      })
  }
