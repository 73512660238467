/**
 * Takes a string as an argument, removes special characters and returns the string.
 * Allows French and Spanish Diactrics along with `-` and `’` as part of ESPSM-1334
 *
 * @function
 * @param {string} inputString - The string to remove special characters from.
 * @returns {string} The input string with all special characters removed.
 */
export const removeSpecialCharacters = (inputString) => {
  const rawString = inputString.replace(/[^a-zA-Z0-9\s+\-’À-ÖØ-öø-ÿœ']+/g, ``)
  return rawString
}
