import get from "lodash/get"
import map from "lodash/map"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import { urls } from "utils-lib/builds"
import { getTokenAction } from "../getToken"

export const getCustomerIds = (accounts) =>
  map(accounts, (item) => item.custAccountId)

const pendingAC = () => ({
  type: ActionTypes.GET_USER_ACCOUNT_BALANCE_PENDING,
})

const failedAC = (statusCode) => ({
  type: ActionTypes.GET_USER_ACCOUNT_BALANCE_FAILED,
  payload: statusCode,
})

const fulfilledAC = (data) => ({
  type: ActionTypes.GET_USER_ACCOUNT_BALANCE_FULFILLED,
  payload: data,
})

/**
 * Fetches the user account balance and dispatches the appropriate action.
 * @param {Object} params - The parameters for the function.
 * @param {string} params.token - The token for authentication.
 * @param {string} params.userId - The user ID.
 * @param {Function} dispatch - The Redux dispatch function.
 * @param {Function} getState - The Redux getState function.
 * @returns {Promise} A promise that resolves to an object containing the amount balance by account.
 */
const getUserAccountBalanceHelper = ({ token, userId }, dispatch, getState) => {
  const api = `BROKER_ACCOUNT_BALANCE`
  const template = URITemplate(urls.url[api])
  const url = template.expand({ userId })

  const config = {
    headers: {
      token,
      apiKey: get(urls, `apiKey.USER[${api}]`, ``),
    },
    params: {
      lang: get(getState(), `siteLanguage.language`, `en_CA`),
    },
  }

  return axios
    .create(config)
    .get(url)
    .then((response) => {
      const statusCode = get(response, `status`, 200)
      const amountBalanceByAccount = {}
      const userBalance = get(response, `data.data.userBalance`, [])
      userBalance.forEach((item) => {
        amountBalanceByAccount[item.customerId] = {
          amount: parseFloat(item.balance),
          balance: parseFloat(item.balance),
          customerId: item.customerId,
          currency: item.currency,
        }
      })

      dispatch(fulfilledAC({ amountBalanceByAccount }, statusCode))
      return { amountBalanceByAccount }
    })
    .catch((error) => {
      dispatch(failedAC(get(error, `response.status`, 500)))
    })
}

/**
 * Initiates the process to fetch the user's account balance for linked accounts.
 * @param {Function} getState - The Redux getState function used to access the current state.
 * @returns {Promise} A promise that resolves after the account balances have been fetched and processed.
 */
export const getUserAccountBalance = () => (dispatch, getState) => {
  dispatch(pendingAC())

  const state = getState()
  const userId = get(getState(), `userAccount.userDetails.userId`, ``)
  const accounts = get(state, `userManageAccount.linkedAccounts`, [])

  return getTokenAction(dispatch, getState)
    .then((res) =>
      getUserAccountBalanceHelper(
        {
          accounts,
          token: res.accessToken,
          userId,
        },
        dispatch,
        getState,
      ),
    )
    .catch((e) => {
      dispatch(failedAC(e.response.status))
    })
}
