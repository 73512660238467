import { useContext } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { Switch } from "react-router-dom"
import { asyncComponent } from "hoc-lib/asyncComponent"
import AppliedRoute from "hoc/AppliedRoute"
import GuestRoute from "hoc/GuestRoute"
import AuthenticatedRoute from "hoc/AuthenticatedRoute"
import UnauthenticatedRoute from "hoc/UnauthenticatedRoute"
import RouterRoute from "hoc/RouterRoute"
import { userType } from "constants-lib/authentication"
import { AuthenticationContext } from "contexts-lib/AuthenticationContext"

const routeTypes = {
  AUTHENTICATED: 0,
  UNAUTHENTICATED: 1,
  APPLIED: 2,
  GUEST: 3,
  ROUTER: 4,
}

/* Helper function to create routes. */
const createRoutes = ({ component, secondaryLayout, routes, routeType }) => {
  const result = []

  routes.forEach((route) => {
    result.push({
      component,
      secondaryLayout,
      routeType,
      ...route,
    })
    /* Add language route. */
    if (route.path !== undefined) {
      result.push({
        component,
        secondaryLayout,
        routeType,
        ...route,
        path: `/:country/:language${route.path}`,
      })
    }
  })

  return result
}

/* Layouts */
const AsyncLayout = asyncComponent(() =>
  import(`layouts/PageLayout/PageLayout`),
)

/* Local Sites */
const localRoutes = createRoutes({
  component: asyncComponent(() => import(`./Local/LocalContainer`)),
  routes: [
    {
      path: `/us/local/:state/:city/:lob/`,
    },
  ],
})

/* Site Map */
const siteMapRoutes = createRoutes({
  component: asyncComponent(() => import(`./SiteMap`)),
  routes: [
    {
      path: `/sitemap`,
    },
  ],
})

/* Safety */
const safetyRoutes = createRoutes({
  component: asyncComponent(() => import(`./Safety`)),
  routes: [
    {
      path: `/safety`,
    },
  ],
})

/* Fee Authorization */
const papRoutes = createRoutes({
  component: asyncComponent(() => import(`./FeeAuthorization`)),
  routes: [
    {
      path: `/pap`,
    },
  ],
})

/* How To Read Invoice */
const howToReadInvoiceRoutes = createRoutes({
  component: asyncComponent(() => import(`./HowToReadInvoice`)),
  routes: [
    {
      path: `/how-to-read-invoice`,
    },
  ],
})

/* My WM */
const AsyncMyWMLayout = asyncComponent(() =>
  import(`./MyWM/layouts/MyWMLayout/MyWMLayout`),
)

const myWMHomeRoutes = createRoutes({
  component: asyncComponent(() =>
    import(`./MyWM/routes/Guest/routes/MyWMHome/components/MyWM/MyWMContainer`),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm`,
    },
  ],
  routeType: routeTypes.GUEST,
})

/* My WM - Locate */
const myWMLocateRoutes = createRoutes({
  component: asyncComponent(() => import(`./MyWM/routes/Guest/routes/Locate`)),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/locate`,
    },
  ],
})

/* My WM - Guest - My Services */
const myWMMyServicesRoutes = [
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/Guest/routes/MyServices/routes/Dashboard/components/Dashboard/DashboardContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/my-services`,
      },
    ],
    routeType: routeTypes.GUEST,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/Guest/routes/MyServices/routes/Verify/components/Verify/Verify`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/my-services/verify`,
      },
    ],
    routeType: routeTypes.GUEST,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/Guest/routes/MyServices/routes/Form/components/Form/FormContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/my-services/form`,
      },
    ],
    routeType: routeTypes.GUEST,
  }),
  /* not sure if the above route is being used, so rather than modify it I am creating a new one */
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/Guest/routes/MyServices/routes/Form/components/Form/FormContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/my-services/rolloff-request`,
      },
    ],
    routeType: routeTypes.GUEST,
  }),
  /* this handles and issue caused by URLs that have encoded "?" */
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/Guest/routes/MyServices/routes/Form/components/Form/FormContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/locate%3Fredirect=/us/en/mywm/my-services/rolloff-request`,
      },
    ],
    routeType: routeTypes.GUEST,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/Guest/routes/MyServices/routes/Dashboard/components/GuidedGuestVariant/GuidedGuestVariantContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/my-services/view-pickup-eta`,
      },
    ],
    routeType: routeTypes.GUEST,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/Guest/routes/MyServices/routes/Dashboard/components/GuidedGuestVariant/GuidedGuestVariantContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/my-services/view-eta`,
      },
    ],
    routeType: routeTypes.GUEST,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/Guest/routes/MyServices/routes/Dashboard/components/GuidedGuestVariant/GuidedGuestVariantContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/my-services/pickup-schedule`,
      },
    ],
    routeType: routeTypes.GUEST,
  }),
]

/* My WM - Guest - Container Issue */
const myWMContainerIssueRoutes = createRoutes({
  component: asyncComponent(() =>
    import(`./MyWM/routes/Guest/routes/ContainerIssue`),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/container-issue`,
    },
  ],
})

/* My WM - My Payment - Verify */
const myWMMyPaymentVerifyRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/Guest/routes/MyPayment/routes/Verify/components/Verify/VerifyContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/my-payment/verify`,
      redirectPath: `/mywm/user/my-payment/billing/overview`,
    },
  ],
  routeType: routeTypes.UNAUTHENTICATED,
})

/* My WM - My Payment - Checkout */
const myWMMyPaymentCheckoutRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/Guest/routes/MyPayment/routes/Checkout/components/Checkout/CheckoutContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/my-payment/checkout`,
    },
  ],
})

/* PAD-Agreement Checkout Guest User */
const myWMMyPaymentPadAgreementRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/Guest/routes/MyPayment/routes/Checkout/components/PadAgreement/PadAgreementContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/my-payment/checkout/pad-agreement`,
    },
  ],
})

/* My WM - My Payment - Review Post */
const myWMMyPaymentReviewPostRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/Guest/routes/MyPayment/routes/Review/components/ReviewPost/ReviewPostContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/my-payment/reviewpost`,
    },
  ],
})

/* My WM - My Payment - Review */
const myWMMyPaymentReviewRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/Guest/routes/MyPayment/routes/Review/components/Review/ReviewContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/my-payment/review`,
    },
  ],
})

/* My WM - My Payment - Confirm */
const myWMMyPaymentConfirmRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/Guest/routes/MyPayment/routes/Confirm/components/Confirm/ConfirmContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/my-payment/confirm`,
    },
  ],
})

/* My WM - My Payment - Confirm */
const myWMMyPaymentCompleteRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/Guest/routes/MyPayment/routes/AutoPayPaperlessComplete/AutoPayPaperlessCompleteContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/user/profile-setup`,
    },
  ],
})

/* Notifications */
const myWMGuestNotificationsRoutes = createRoutes({
  component: asyncComponent(() =>
    import(`./Notifications/NotificationsContainer`),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/notifications`,
      redirectPath: `/mywm/user/notifications`,
      cookiedRedirectPath: `/mywm/locate?redirect=___LANGUAGE_ROUTE___/mywm/user/notifications`,
    },
  ],
  routeType: routeTypes.UNAUTHENTICATED,
})

const myWMMyPaymentRoutes = [
  ...myWMMyPaymentVerifyRoutes,
  ...myWMMyPaymentCheckoutRoutes,
  ...myWMMyPaymentPadAgreementRoutes,
  ...myWMMyPaymentReviewPostRoutes,
  ...myWMMyPaymentReviewRoutes,
  ...myWMMyPaymentConfirmRoutes,
  ...myWMMyPaymentCompleteRoutes,
  ...myWMGuestNotificationsRoutes,
]

/* My WM - User - Dashboard */
const myWMUserDashboardRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/User/routes/Dashboard/components/UserDashboard/UserDashboardContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/dashboard`,
    },
  ],
  routeType: routeTypes.AUTHENTICATED,
})

/* My WM - User - Accounts */
const myWMUserAccountsRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/User/routes/Accounts/components/ManageAccounts/ManageAccountsContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/accounts`,
      requireLinkedAccounts: false,
    },
    {
      path: `/mywm/user/accounts/:action`,
      requireLinkedAccounts: false,
    },
  ],
  routeType: routeTypes.AUTHENTICATED,
})

/* My WM - User - Account */
const myWMUserAccountRoutes = createRoutes({
  component: asyncComponent(() =>
    import(`./MyWM/routes/User/routes/Account/AccountContainer`),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/account`,
      getPath: (reduxState, numAccountsLinked) => {
        if (numAccountsLinked !== 1) return `/mywm/user/dashboard`
        return null
      },
    },
  ],
  routeType: routeTypes.AUTHENTICATED,
})

/* My WM - User - Update Profile */
const myWMUserProfileRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/User/routes/Profile/components/Profile/ProfileContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/profile`,
    },
  ],
  routeType: routeTypes.AUTHENTICATED,
})

/* My WM - User - Extra Pickup */
const myWMUserMyServicesExtraPickup = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/User/routes/MyServices/routes/ExtraPickup/ExtraPickupContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/my-services/extra-pickup`,
    },
  ],
  routeType: routeTypes.AUTHENTICATED,
})

/* My WM - User - Bulk Pickup */
const myWMUserMyServicesBulkPickup = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/User/routes/MyServices/routes/BulkPickup/BulkPickupContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/my-services/bulk-pickup`,
    },
  ],
  routeType: routeTypes.AUTHENTICATED,
})

/* My WM - User - Increase Decrease Services */
const myWMUserMyServicesIncreaseDecrease = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/User/routes/MyServices/routes/IncreaseDecreaseServices/IncreaseDecreaseServicesContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/my-services/service-change`,
    },
  ],
  routeType: routeTypes.AUTHENTICATED,
})

/* My WM - User - View Service Orders */
const myWMUserMyServicesViewOrders = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/User/routes/MyServices/routes/ViewOrders/ViewOrdersContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/my-services/view-orders`,
    },
  ],
  routeType: routeTypes.AUTHENTICATED,
})

/* My WM - User - Add Waste Stream */
const myWMUserMyServicesAddWasteStream = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/User/routes/MyServices/routes/AddWasteStream/AddWasteStreamContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/my-services/add-service`,
    },
  ],
  routeType: routeTypes.AUTHENTICATED,
})

/* My WM - User - Pause Service */
const myWMUserMyServicesPauseService = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/User/routes/MyServices/routes/PauseService/PauseServiceContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/my-services/pause-service`,
    },
  ],
  routeType: routeTypes.AUTHENTICATED,
})

/* My WM - User - View Service History */
const myWMUserMyServicesServiceHistory = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/routes/User/routes/MyServices/routes/ServiceHistory/ServiceHistoryContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/my-services/service-history`,
    },
  ],
  routeType: routeTypes.AUTHENTICATED,
})

/* My WM - User - My Services */
const myWMUserMyServicesRoutes = [
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyServices/components/MyServices/MyServicesContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-services`,
        getPath: (reduxState) => {
          const userAccoutDetails = _.get(
            reduxState.userManageAccount,
            `userAccountDetails`,
            [],
          )
          const invalidAccounts = userAccoutDetails.filter(
            (s) =>
              s.status === `CANCELLED` ||
              s.status === `CUTOFF` ||
              s.status === `INACTVHLD` ||
              s.status === `WRTOFFACTV` ||
              s.status === `WRTOFFCAN` ||
              s.status === `BNKPCYCAN`,
          )
          const activeAccounts = userAccoutDetails.filter(
            (s) =>
              s.status === `ACTIVE` ||
              s.status === `BNKPCYACTV` ||
              s.status === `PNDNGINHLD`,
          )
          const isAccountDisabled =
            invalidAccounts.length > 0 && activeAccounts.length === 0
          if (isAccountDisabled) {
            return `/mywm/user/dashboard`
          }
          return null
        },
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/components/MyServices/MyServicesMainDashboard/components/RecyclingPickupConfirmationContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-services/recycling+`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyServices/routes/Form/components/Form/FormContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-services/rolloff-request`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/components/MyServices/ContainerRepair/ContainerRepairContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-services/container-repair`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyServices/routes/Form/components/Confirm/ConfirmContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-services/rolloff-request/confirm`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
]

/* My WM - User - My Payment */
const myWMUserMyPaymentRoutes = [
  /* Saved Payment Methods */
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyPayment/routes/Method/components/Method/MethodContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/method`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  /* Billing Overview */
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyPayment/routes/Billing/routes/Overview/components/Overview/OverviewContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/billing/overview`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  /* Billing Details */
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyPayment/routes/Billing/routes/Details/components/Details/DetailsContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/billing/details`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  /* Checkout */
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyPayment/routes/Checkout/components/Checkout/CheckoutContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/checkout`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  /* PAD-Agreement Checkout Logged-in */
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/Guest/routes/MyPayment/routes/Checkout/components/PadAgreement/PadAgreementContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/checkout/pad-agreement`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  /* Review */
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyPayment/routes/Review/components/Review/ReviewContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/review`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  /* Confirm */
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyPayment/routes/Confirm/components/Confirm/ConfirmContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/confirm`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  /* Manage Schedule */
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyPayment/routes/ManageSchedule/components/ManageSchedule/ManageScheduleContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/manage-schedule`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),

  /* Auto payment and paperless billing routes */
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyPayment/routes/AutomaticPayment/components/EnrollAutomaticPayment/EnrollAutomaticPaymentContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/enroll-auto-payment`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyPayment/routes/AutomaticPayment/components/EnrollAutomaticPayment/VerifyEnrollAutomaticPayment/VerifyEnrollAutomaticPaymentContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/verify-enroll-auto-payment`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  /* PAD-AGREEMENT AUTOPAY ENROLL LOGGED-IN */
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/Guest/routes/MyPayment/routes/Checkout/components/PadAgreement/PadAgreementContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/enroll-auto-payment/pad-agreement`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyPayment/routes/AutomaticPayment/components/EnrollAutomaticPayment/AutomaticPaymentEnrollConfirmation/AutomaticPaymentEnrollConfirmationContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/confirm-enroll-auto-payment`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyPayment/routes/AutomaticPayment/components/ManageAutomaticPayment/ManageAutomaticPaymentContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/manage-auto-payment`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyPayment/routes/PaperlessBilling/PaperlessBillingContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/enroll-paperless-billing`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/MyPayment/routes/PaperlessBilling/PaperlessBillingContainer`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/my-payment/manage-paperless-billing`,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
]

/* My WM - User - My Payment - Paperless Billing */
const myWMUserMyPaymentPaperlessBillingRoutes = createRoutes({
  routes: [
    {
      path: `/mywm/user/my-payment/paperless-billing`,
      getPath: (reduxState, numAccountsLinked) => {
        if (numAccountsLinked === 1) {
          // Check if paperless is enabled or not for the single account.
          const accountsKeys = _.keys(
            _.get(
              reduxState,
              "customerAutoPayPaperless.attributesDetailsByAccount",
              {},
            ),
          )

          const singleAccount =
            accountsKeys > 0
              ? reduxState.customerAutoPayPaperless.attributesDetailsByAccount[
                  accountsKeys[0]
                ]
              : {}

          const isPaperlessEnabled = _.get(
            singleAccount,
            `paperlessStatus`,
            false,
          )

          return isPaperlessEnabled
            ? `/mywm/user/my-payment/manage-paperless-billing`
            : `/mywm/user/my-payment/enroll-paperless-billing`
        }
        if (numAccountsLinked > 1) {
          return `/mywm/user/my-payment/billing/overview`
        }
        return `/mywm/user/accounts`
      },
    },
  ],
  routeType: routeTypes.ROUTER,
})

/* My WM - User - My Payment - Auto payment */
const myWMUserMyPaymentAutoPaymentRoutes = createRoutes({
  routes: [
    {
      path: `/mywm/user/my-payment/auto-payment`,
      getPath: (reduxState, numAccountsLinked) => {
        if (numAccountsLinked === 1) {
          // Check if auto payment is enabled or not for the single account.
          const accountsKeys = _.keys(
            reduxState?.customerAutoPayPaperless?.attributesDetailsByAccount,
          )

          const singleAccount =
            accountsKeys > 0
              ? reduxState.customerAutoPayPaperless.attributesDetailsByAccount[
                  accountsKeys[0]
                ]
              : {}

          const isAutoPaymentEnabled = _.get(
            singleAccount,
            `autopayStatus`,
            false,
          )

          return isAutoPaymentEnabled
            ? `/mywm/user/my-payment/manage-auto-payment`
            : `/mywm/user/my-payment/enroll-auto-payment`
        }
        if (numAccountsLinked > 1) {
          return `/mywm/user/my-payment/billing/overview`
        }
        return `/mywm/user/accounts`
      },
    },
  ],
  routeType: routeTypes.ROUTER,
})

/* My WM - User - My Payment - Billing */
const myWMUserMyPaymentBillingRoutes = createRoutes({
  routes: [
    {
      path: `/mywm/user/my-payment/billing`,
      getPath: (reduxState, numAccountsLinked) => {
        if (numAccountsLinked === 1) {
          return `/mywm/user/my-payment/billing/details`
        }
        if (numAccountsLinked > 1) {
          return `/mywm/user/my-payment/billing/overview`
        }
        return `/mywm/user/accounts`
      },
    },
  ],
  routeType: routeTypes.ROUTER,
})

/* My WM  - User - Combined Order History */
const myWMUserOrdersRoutes = [
  ...createRoutes({
    component: asyncComponent(() =>
      import(
        `./MyWM/routes/User/routes/Orders/routes/CombinedOrderHistory/CombinedOrderHistoryContainer.js`
      ),
    ),
    secondaryLayout: AsyncMyWMLayout,
    routes: [
      {
        path: `/mywm/user/order-history`,
        requireLinkedAccounts: false,
      },
    ],
    routeType: routeTypes.AUTHENTICATED,
  }),
]

/* Notifications */
const myWMNotificationsRoutes = createRoutes({
  component: asyncComponent(() =>
    import(`./NotificationsLoggedIn/NotificationsLoggedInContainer`),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/notifications`,
    },
  ],
  routeType: routeTypes.AUTHENTICATED,
})

const myWMNotificationsIncidentsRoutes = createRoutes({
  component: asyncComponent(() =>
    import(`./NotificationsIncidents/NotificationsIncidentsContainer`),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/notifications/incidents`,
    },
  ],
  routeType: routeTypes.AUTHENTICATED,
})

/* My Services - Missed Pickup Request Confirmation */
const myWMMissedPickupRequestConfirmation = createRoutes({
  component: asyncComponent(() =>
    import(
      `./MyWM/components/MyServices/DashboardNextPickup/components/MissedPickupRequestConfirmation/MissedPickupRequestConfirmationContainer`
    ),
  ),
  secondaryLayout: AsyncMyWMLayout,
  routes: [
    {
      path: `/mywm/user/my-services/missed-pickup-confirmation`,
    },
  ],
})

const myWMRoutes = [
  ...myWMHomeRoutes,
  ...myWMLocateRoutes,
  ...myWMMyServicesRoutes,
  ...myWMContainerIssueRoutes,
  ...myWMMyPaymentRoutes,
  ...myWMUserDashboardRoutes,
  ...myWMUserAccountsRoutes,
  ...myWMUserAccountRoutes,
  ...myWMUserProfileRoutes,
  ...myWMUserMyServicesExtraPickup,
  ...myWMUserMyServicesBulkPickup,
  ...myWMUserMyServicesIncreaseDecrease,
  ...myWMUserMyServicesViewOrders,
  ...myWMUserMyServicesAddWasteStream,
  ...myWMUserMyServicesPauseService,
  ...myWMUserMyServicesServiceHistory,
  ...myWMUserMyServicesRoutes,
  ...myWMUserMyPaymentRoutes,
  ...myWMUserMyPaymentPaperlessBillingRoutes,
  ...myWMUserMyPaymentAutoPaymentRoutes,
  ...myWMUserMyPaymentBillingRoutes,
  ...myWMUserOrdersRoutes,
  ...myWMNotificationsRoutes,
  ...myWMNotificationsIncidentsRoutes,
  ...myWMMissedPickupRequestConfirmation,
]

/* User Account */
const AsyncUserAccountLayout = asyncComponent(() =>
  import(`./UserAccount/layouts/UserAccountLayout/UserAccountLayout`),
)

/* User Account - AutoPay Setup */
const userAccountAutoPaySetupRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./UserAccount/routes/AutoPaySetup/components/AutoPaySetup/AutoPaySetupContainer`
    ),
  ),
  secondaryLayout: AsyncUserAccountLayout,
  routes: [
    {
      path: `/user/confirm-autopay-enrollment`,
    },
  ],
})

/* User Account - Email Verification */
const userAccountEmailVerificationRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./UserAccount/routes/EmailVerification/components/EmailVerification/EmailVerificationContainer`
    ),
  ),
  secondaryLayout: AsyncUserAccountLayout,
  routes: [
    {
      path: `/user/email-verification`,
    },
  ],
})

/* User Account - Forgot Password */
const userAccountForgotPasswordRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./UserAccount/routes/ForgotPassword/components/ForgotPassword/ForgotPassword`
    ),
  ),
  secondaryLayout: AsyncUserAccountLayout,
  routes: [
    {
      path: `/user/forgot-password`,
    },
  ],
})

/* User Account - Register */
const userAccountRegisterRoutes = createRoutes({
  component: asyncComponent(() =>
    import(`./UserAccount/routes/Register/components/Register/Register`),
  ),
  secondaryLayout: AsyncUserAccountLayout,
  routes: [
    {
      path: `/user/register`,
    },
  ],
  routeType: routeTypes.UNAUTHENTICATED,
})

/* User Account - Pending Registration */
const userAccountRegistrationPending = createRoutes({
  component: asyncComponent(() =>
    import(
      `./UserAccount/routes/RegistrationPending/components/RegistrationPending/RegistrationPendingContainer`
    ),
  ),
  secondaryLayout: AsyncUserAccountLayout,
  routes: [
    {
      path: `/user/register/pending`,
    },
  ],
  routeType: routeTypes.UNAUTHENTICATED,
})

/* User Account - Login */
const userAccountLoginRoutes = createRoutes({
  component: asyncComponent(() =>
    import(`./UserAccount/routes/Login/components/Login/LoginContainer`),
  ),
  secondaryLayout: AsyncUserAccountLayout,
  routes: [
    {
      path: `/user/login`,
    },
  ],
  routeType: routeTypes.UNAUTHENTICATED,
})

/* User Account - Reset Password */
const userAccountResetPasswordRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./UserAccount/routes/ResetPassword/components/ResetPassword/ResetPasswordContainer`
    ),
  ),
  secondaryLayout: AsyncUserAccountLayout,
  routes: [
    {
      path: `/user/reset-password`,
    },
  ],
})

/* User Account - Complete Registration */
const userAccountCompleteRegistrationRoutes = createRoutes({
  component: asyncComponent(() =>
    import(
      `./UserAccount/routes/ResetPassword/components/ResetPassword/ResetPasswordContainer`
    ),
  ),
  secondaryLayout: AsyncUserAccountLayout,
  routes: [
    {
      path: `/user/complete-registration`,
    },
  ],
})

const userAccountRoutes = [
  ...userAccountRegisterRoutes,
  ...userAccountRegistrationPending,
  ...userAccountAutoPaySetupRoutes,
  ...userAccountEmailVerificationRoutes,
  ...userAccountCompleteRegistrationRoutes,
  ...userAccountLoginRoutes,
  ...userAccountForgotPasswordRoutes,
  ...userAccountResetPasswordRoutes,
]

/* Home */
const homeRoutes = createRoutes({
  component: asyncComponent(() => import(`./Home`)),
  routes: [
    {
      path: `/`,
    },
  ],
})

/* No Route */
const notFoundRoute = [
  {
    exact: false,
    component: asyncComponent(() => import(`./NoRoute`)),
    layout: asyncComponent(() =>
      import(`../layouts/NoRouteLayout/NoRouteLayout`),
    ),
  },
]

export const Routes = ({ childProps }) => {
  const children = [
    ...localRoutes,
    ...siteMapRoutes,
    ...safetyRoutes,
    ...papRoutes,
    ...howToReadInvoiceRoutes,
    ...myWMRoutes,
    ...userAccountRoutes,
    ...homeRoutes,
    ...notFoundRoute,
  ]

  const { userState } = useContext(AuthenticationContext)

  return (
    <Switch
      children={children.map((child, index) => {
        const routeProps = { props: childProps, ...child }
        /* Set default layout. */
        if (routeProps.layout === undefined) {
          routeProps.layout = AsyncLayout
        }

        /* Set route matching to be exact by default. */
        if (child.exact === undefined) {
          routeProps.exact = true
        }

        if (
          child.routeType === routeTypes.AUTHENTICATED ||
          userState === userType.LOGGED_IN
        ) {
          return (
            <AuthenticatedRoute
              key={`${child.path}-${index}`}
              {...routeProps}
            />
          )
        }
        if (child.routeType === routeTypes.UNAUTHENTICATED) {
          return (
            <UnauthenticatedRoute
              key={`${child.path}-${index}`}
              {...routeProps}
            />
          )
        }
        if (child.routeType === routeTypes.GUEST) {
          return <GuestRoute key={`${child.path}-${index}`} {...routeProps} />
        }
        if (child.routeType === routeTypes.ROUTER) {
          return <RouterRoute key={`${child.path}-${index}`} {...routeProps} />
        }

        return <AppliedRoute key={index} {...routeProps} />
      })}
    />
  )
}

Routes.propTypes = {
  childProps: PropTypes.object.isRequired,
}

export default Routes
