import { UPDATE_CURRENT_LOCALSITE } from "constants-lib/localSites"

// Must be a pure function that requires the state
// and the action being dispatched.
const initialState = {}
export default (state = initialState, action) => {
  switch (action.type) {
    // case ActionTypes.INIT_STATE:
    //   return initialState

    // case ActionTypes.LOGOUT_FULFILLED:
    //   return initialState

    case UPDATE_CURRENT_LOCALSITE:
      return { ...state, ...action.payload }

    // Initialize the state when the customer selects a new address.
    // case ActionTypes.SET_USER_ADDRESS:
    //   return initialState

    default:
      return state
  }
}
