import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  GET_SERVICE_CHANGE_ELIGIBILITY_PENDING,
  GET_SERVICE_CHANGE_ELIGIBILITY_FAILED,
  GET_SERVICE_CHANGE_ELIGIBILITY_FULFILLED,
} from "constants-lib/actionTypes"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"
import { MOCK_SERVICE_CHANGE_INFORMATION } from "utils-lib/mocks"
import { YES, NO } from "constants-lib/common"

export const pendingAC = () => ({
  type: GET_SERVICE_CHANGE_ELIGIBILITY_PENDING,
})

export const failedAC = () => ({
  type: GET_SERVICE_CHANGE_ELIGIBILITY_FAILED,
})

export const fulfilledAC = (data, isAddWasteStream, isPauseService) => ({
  type: GET_SERVICE_CHANGE_ELIGIBILITY_FULFILLED,
  payload: { data, isAddWasteStream, isPauseService },
})

export const getServiceChangeEligibility =
  ({
    customerId,
    userId,
    lang,
    isAddWasteStream = false,
    isPauseService = false,
  }) =>
  (dispatch, getState) => {
    const api = `SERVICE_CHANGE_ELIGIBILITY`
    const template = URITemplate(urls.url[api])

    const url = MOCK_SERVICE_CHANGE_INFORMATION
      ? `${window.origin}/mock/service-change-eligibility-response.json`
      : template.expand({
          customerId,
          userId,
          lang,
        })

    const config = {
      headers: {},
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

      const fullURL = `${url}&addWasteStream=${
        isAddWasteStream ? YES : NO
      }&pauseService=${isPauseService ? YES : NO}`

      return getServiceChangeEligibilityHelper(
        fullURL,
        config,
        isAddWasteStream,
        isPauseService,
        dispatch,
      )
    })
  }

export const getServiceChangeEligibilityHelper = (
  url,
  config,
  isAddWasteStream,
  isPauseService,
  dispatch,
) => {
  dispatch(pendingAC())

  return axios
    .get(url, config)
    .then((response) => {
      dispatch(fulfilledAC(response.data, isAddWasteStream, isPauseService))
      return response
    })
    .catch((error) => {
      dispatch(failedAC(error))
      return error
    })
}

export const setServiceChangeEligibility =
  (data, isAddWasteStream, isPauseService) => (dispatch) =>
    dispatch(fulfilledAC(data, isAddWasteStream, isPauseService))
