import isEmpty from "lodash/isEmpty"
import { Component } from "react"
import PropTypes from "prop-types"
import { validatePhone } from "utils-lib/validation"
import MaskedTextField from "component-lib/Base/components/MaskedTextField/MaskedTextField"
import { phonePattern } from "utils-lib/strings/maskPatterns"
import { defaultToSpace } from "utils-lib/strings"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export default class PhoneInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldHasFocus: false,
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.hasFocus) {
      this.setState({
        fieldHasFocus: true,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { fieldHasFocus } = this.state
    if (fieldHasFocus !== prevState.fieldHasFocus && fieldHasFocus) {
      window.document.getElementById(`phoneInputField`).focus()
    }
  }

  validatePhone = (value) => {
    const { isRequired } = this.props

    return (isEmpty(value) && isRequired === false) || validatePhone(value)
  }

  handleChange = (value) => {
    const { onChange } = this.props
    const isValid = this.validatePhone(value)
    onChange(value, isValid)
  }

  render() {
    const {
      customErrorMessage,
      hasError,
      handleKeyDown,
      placeholder,
      value,
      onFocus,
      onBlur,
      disabled,
    } = this.props

    const showErrors = hasError
    let className = `PhoneInput`
    if (showErrors) {
      className = `PhoneInput input-error`
    }
    if (disabled) {
      className = `PhoneInput disabled-field`
    }

    return (
      <MaskedTextField
        fullWidth
        variant="outlined"
        helperText={defaultToSpace(customErrorMessage)}
        error={!isEmpty(customErrorMessage)}
        disabled={disabled}
        type="tel"
        name="tel"
        label={placeholder}
        onAccept={this.handleChange}
        onKeyDown={handleKeyDown}
        unmask={false}
        className={className}
        value={value}
        onFocus={onFocus}
        onBlur={() => {
          onBlur(value, this.validatePhone(value))
        }}
        id="phoneInputField"
        mask={phonePattern}
        data-testid="PhoneInput"
      />
    )
  }
}

PhoneInput.propTypes = {
  hasError: PropTypes.bool,
  hasFocus: PropTypes.bool,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  handleKeyDown: PropTypes.func,
  customErrorMessage: PropTypes.string,
}

PhoneInput.defaultProps = {
  hasError: false,
  disabled: false,
  hasFocus: false,
  isRequired: false,
  placeholder: ``,
  onFocus: () => {},
  onBlur: () => {},
  handleKeyDown: () => {},
  customErrorMessage: ``,
}
