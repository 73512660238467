import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  fetchStatus: ``,
  deletePaymentMethodStatus: ``,
  dateValidationMsg: ``,
  dateValid: true,
  isShow: false,
  userSelectedPaymentMethod: {},
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_USER_PAYMENT_METHODS_PENDING:
      return {
        ...state,
        fetchStatus: `pending`,
      }

    case ActionTypes.GET_USER_PAYMENT_METHODS_FAILED:
      return {
        ...state,
        fetchStatus: `failed`,
      }

    case ActionTypes.GET_USER_PAYMENT_METHODS_FULFILLED:
      return {
        ...state,
        fetchStatus: `fulfilled`,
        ...action.payload,
      }

    case ActionTypes.DELETE_PAYMENT_METHOD_PENDING:
      return {
        ...state,
        deletePaymentMethodStatus: `pending`,
      }

    case ActionTypes.DELETE_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        deletePaymentMethodStatus: `failed`,
      }

    case ActionTypes.DELETE_PAYMENT_METHOD_FULFILLED:
      return {
        ...state,
        deletePaymentMethodStatus: `fulfilled`,
        isShow: true,
      }

    case ActionTypes.SET_FIRST_COMPONENT_LOADED:
      return {
        ...state,
        isShow: false,
      }

    case ActionTypes.SET_CUSTOMCALENDAR_INPUT_VALUE:
      return {
        ...state,
        dateValidationMsg: action.payload,
      }

    case ActionTypes.SET_CUSTOMCALENDAR_DATE_VALID:
      return {
        ...state,
        dateValid: action.payload,
      }

    case ActionTypes.SET_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        userSelectedPaymentMethod: action.payload,
      }

    case ActionTypes.RESET_USER_ACCOUNT:
      return initialState

    default:
      return state
  }
}
