import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import { serviceTypes, YES } from "constants-lib/common"
import {
  GET_CITY_BILLED_ELIGIBILITY_URL_PENDING,
  GET_CITY_BILLED_ELIGIBILITY_URL_FAILED,
  GET_CITY_BILLED_ELIGIBILITY_URL_FULFILLED,
} from "constants-lib/actionTypes"
import { userType } from "constants-lib/authentication"
import { CUSTOMER_CATEGORIES } from "constants-lib/increaseDecrease"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export const pendingAC = (productType) => ({
  type: GET_CITY_BILLED_ELIGIBILITY_URL_PENDING,
  productType,
})

export const failedAC = (fetchCode) => ({
  type: GET_CITY_BILLED_ELIGIBILITY_URL_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: GET_CITY_BILLED_ELIGIBILITY_URL_FULFILLED,
  payload: data,
})

/**
 * @typedef {Object} CustomAddress
 * @property {string} street - The street of the custom address.
 * @property {string} city - The city of the custom address.
 * @property {string} state - The state of the custom address.
 * @property {string} country - The country of the custom address.
 * @property {string} zipCode - The zip code of the custom address.
 * @property {string} [customAddress.gPlaceId] - Optional Google Place ID of the custom address.
 */

/**
 * Fetches eligibility for city billing based on the provided or selected customer address and dispatches actions based on the API response.
 * @param {CustomAddress|null} customAddress - Optional. The custom address object for eligibility check. If null, uses selected customer address from state.
 * @param {Function} dispatch - The Redux dispatch function used to dispatch actions.
 * @param {Function} getState - The Redux getState function used to access the current state.
 * @returns {Promise} A promise that resolves to the dispatch of an action based on the eligibility check outcome.
 */
export const getCityBilledEligibility =
  (customAddress = null) =>
  (dispatch, getState) => {
    dispatch(pendingAC(serviceTypes.CITY_BILLED))
    const { customerSelections } = getState()
    const street =
      `${customerSelections.customerSearchedAddress.streetNumber} ${customerSelections.customerSearchedAddress.streetName}` ||
      ``
    if (street.trim() === ``) {
      dispatch(fulfilledAC({ invalidAddress: true }))
      return
    }

    // eslint-disable-next-line consistent-return
    return getEligibility(customAddress, dispatch, getState).then((res) => {
      if (res) {
        if (res.url) {
          dispatch(fulfilledAC(res))
          window.open(res.url, `_self`)
        } else if (res.serviceUnavailable) {
          dispatch(failedAC({ serviceUnavailable: true, response: res }))
        } else if (res.disclaimerText) {
          dispatch(
            failedAC({
              serviceUnavailable: true,
              disclaimerText: res.miscInstructions,
              response: res,
            }),
          )
        } else {
          dispatch(failedAC({ searchFailed: true, response: res }))
        }
      }
    })
  }

/**
 * Fetches eligibility for city billing based on the provided or selected customer address and dispatches actions based on the API response.
 * @param {CustomAddress|null} customAddress - Optional. The custom address object for eligibility check. If null, uses selected customer address from state.
 * @param {Function} dispatch - The Redux dispatch function used to dispatch actions.
 * @param {Function} getState - The Redux getState function used to access the current state.
 * @returns {Promise} A promise that resolves to the dispatch of an action based on the eligibility check outcome.
 */
export const getEligibility = (customAddress, dispatch, getState) => {
  const { customerSelections, siteLanguage } = getState()
  const street = customAddress
    ? customAddress.street
    : `${customerSelections.customerSearchedAddress.streetNumber} ${customerSelections.customerSearchedAddress.streetName}` ||
      ``
  const city = encodeURI(
    customAddress
      ? customAddress.city
      : customerSelections.customerSearchedAddress.city || ``,
  )
  const state = encodeURI(
    customAddress
      ? customAddress.state
      : customerSelections.customerSearchedAddress.state || ``,
  )
  const country = encodeURI(
    customAddress
      ? customAddress.country
      : customerSelections.customerSearchedAddress.country || ``,
  )
  const label = get(customerSelections, `customerAddress.label`, ``)
  const latitude = encodeURI(
    customerSelections.customerSearchedAddress.lat || ``,
  )
  const longitude = encodeURI(
    customerSelections.customerSearchedAddress.lng || ``,
  )
  const zipcode = customAddress
    ? customAddress.zipCode
    : customerSelections.customerSearchedAddress.postalCode || ``
  const locale = encodeURI(siteLanguage.language || ``)
  const googlePlaceId = encodeURI(
    customAddress
      ? customAddress?.gPlaceId
      : customerSelections.customerSearchedAddress?.gPlaceId || ``,
  )
  const api = `GET_CITY_BILLED`
  const apiKey = get(urls, `apiKey.GUEST[${api}]`, ``)
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    zipcode,
    locale,
    street,
    city,
    state,
    latitude,
    longitude,
    country,
    googlePlaceId,
    label,
  })

  const config = {
    url,
    method: `get`,
    headers: {
      apiKey,
      "Content-Type": `application/json`,
    },
  }
  return axios(config)
    .then((response) => {
      const data = get(response, "data.data")
      const miscInstructions = get(data, "miscInstructions", ``)
      if (data) {
        const { isEligible, reason, category } = data

        const { userAccount } = getState()
        const currentUserState = get(userAccount, `userState`, 0)
        const isLoggedIn = currentUserState === userType.LOGGED_IN

        if (isEligible === "Y" && reason === "cityBilledAndFranchise") {
          const token = response.headers.token
          data.token = token
          return {
            ...data,
            status: response.status,
            url: `${window.location.origin}/us/en/home/bulk-trash-pickup/request`,
          }
        }

        if (
          isEligible === YES &&
          reason === "loggedInEligible" &&
          category &&
          (category === CUSTOMER_CATEGORIES.FRANCHISE ||
            category === CUSTOMER_CATEGORIES.OPEN_MARKET)
        ) {
          let url = `${window.location.origin}/us/en/mywm/user/my-services`

          if (isLoggedIn) {
            const { userManageAccount } = getState()
            const linkedAccounts = get(userManageAccount, `linkedAccounts`, {})
            if (linkedAccounts.length === 1) {
              url = `${window.location.origin}/us/en/mywm/user/my-services/bulk-pickup`
            }
          } else {
            url = `${window.location.origin}/us/en/mywm/user/my-services/bulk-pickup`
          }
          return {
            ...data,
            status: response.status,
            url,
          }
        }

        if (isEligible === YES && reason === "loggedInEligible") {
          return {
            ...data,
            status: response.status,
            url: `${window.location.origin}/us/en/mywm/user/dashboard`,
          }
        }
        if (isEligible === "Y" && reason === "oaklandResiCustomer") {
          return {
            ...data,
            status: response.status,
            url: `${window.location.origin}/us/en/oakland-recycles`,
          }
        }
        if (isEligible === "N" && miscInstructions !== "") {
          return {
            ...data,
            status: response.status,
            disclaimerText: true,
            miscInstructions,
          }
        }
        return { ...data, serviceUnavailable: true, status: response.status }
      }
      return {
        searchFailed: true,
        reason: response.status,
        status: response.status,
      }
    })
    .catch((error) => ({
      searchFailed: true,
      reason: error?.response?.status,
      status: error?.response?.status,
    }))
}

/**
 * Fetches eligibility for city billed bulk services based on the provided or selected customer address and dispatches actions based on the API response.
 * @param {CustomAddress|null} customAddress - Optional. The custom address object for eligibility check. If null, uses selected customer address from state.
 * @param {Function} dispatch - The Redux dispatch function used to dispatch actions.
 * @param {Function} getState - The Redux getState function used to access the current state.
 * @returns {Promise} Resolves to dispatching an action indicating pending, success (with URL, service unavailable, or disclaimer), or failure (with error details).
 */
export const getCityBilledBulkEligibility =
  (customAddress = null) =>
  (dispatch, getState) => {
    dispatch(pendingAC(serviceTypes.CITY_BILLED))
    const { customerSelections } = getState()
    const street =
      `${customerSelections.customerSearchedAddress.streetNumber} ${customerSelections.customerSearchedAddress.streetName}` ||
      ``
    if (street.trim() === ``) {
      dispatch(fulfilledAC({ invalidAddress: true }))
      return
    }

    // eslint-disable-next-line consistent-return
    return getBulkEligibility(customAddress, dispatch, getState)
      .then((res) => {
        if (res) {
          if (res.url) {
            dispatch(fulfilledAC(res))
          } else if (res.serviceUnavailable) {
            dispatch(fulfilledAC({ serviceUnavailable: true, response: res }))
          } else if (res.disclaimerText) {
            dispatch(
              fulfilledAC({
                serviceUnavailable: true,
                disclaimerText: res.miscInstructions,
                response: res,
              }),
            )
          } else {
            dispatch(failedAC({ searchFailed: true, response: res }))
          }
        }
      })
      .catch((error) => ({
        searchFailed: true,
        reason: error?.response?.status,
        status: error?.response?.status,
      }))
  }

/**
 * Fetches eligibility for city billed bulk services based on the provided or selected customer address and dispatches actions based on the API response.
 * @param {CustomAddress|null} customAddress - Optional. The custom address object for eligibility check. If null, uses selected customer address from state.
 * @param {Function} dispatch - The Redux dispatch function used to dispatch actions.
 * @param {Function} getState - The Redux getState function used to access the current state.
 * @returns {Promise} Resolves to dispatching an action indicating pending, success (with URL, service unavailable, or disclaimer), or failure (with error details).
 */
export const getBulkEligibility = (customAddress, dispatch, getState) => {
  const { customerSelections, siteLanguage } = getState()
  const street = customAddress
    ? customAddress.street
    : `${customerSelections.customerSearchedAddress.streetNumber} ${customerSelections.customerSearchedAddress.streetName}` ||
      ``
  const city = encodeURI(
    customAddress
      ? customAddress.city
      : customerSelections.customerSearchedAddress.city || ``,
  )
  const state = encodeURI(
    customAddress
      ? customAddress.state
      : customerSelections.customerSearchedAddress.state || ``,
  )
  const country = encodeURI(
    customAddress
      ? customAddress.country
      : customerSelections.customerSearchedAddress.country || ``,
  )
  const latitude = encodeURI(
    customerSelections.customerSearchedAddress.lat || ``,
  )
  const longitude = encodeURI(
    customerSelections.customerSearchedAddress.lng || ``,
  )
  const zipcode = customAddress
    ? customAddress.zipCode
    : customerSelections.customerSearchedAddress.postalCode || ``
  const locale = encodeURI(siteLanguage.language || ``)
  const googlePlaceId = encodeURI(
    customAddress
      ? customAddress?.gPlaceId
      : customerSelections.customerSearchedAddress?.gPlaceId || ``,
  )
  const api = `GET_CITY_BILLED`
  const apiKey = get(urls, `apiKey.GUEST[${api}]`, ``)
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    zipcode,
    locale,
    street,
    city,
    state,
    latitude,
    longitude,
    country,
    googlePlaceId,
  })

  const config = {
    url,
    method: `get`,
    headers: {
      apiKey,
      "Content-Type": `application/json`,
    },
  }
  return axios(config)
    .then((response) => {
      const data = get(response, "data.data")
      const trackingId = get(response, "data.trackingId")
      const miscInstructions = get(data, "miscInstructions", ``)
      if (data) {
        const { isEligible, reason } = data
        const { userAccount } = getState()
        const currentUserState = get(userAccount, `userState`, 0)
        const isLoggedIn = currentUserState === userType.LOGGED_IN

        if (isEligible === "Y" && reason === "cityBilledAndFranchise") {
          const token = response.headers.token
          data.token = token
          return {
            ...data,
            status: response.status,
            trackingId,
            url: `${window.location.origin}/us/en/home/bulk-trash-pickup/request`,
          }
        }

        if (isEligible === YES && reason === "loggedInEligible" && isLoggedIn) {
          const url = `${window.location.origin}/us/en/mywm/user/my-services/bulk-pickup`

          return {
            ...data,
            status: response.status,
            trackingId,
            url,
          }
        }

        if (isEligible === "Y" && reason === "oaklandResiCustomer") {
          return {
            ...data,
            status: response.status,
            trackingId,
            url: `${window.location.origin}/us/en/oakland-recycles`,
          }
        }
        if (isEligible === "N" && miscInstructions !== "") {
          return {
            ...data,
            status: response.status,
            trackingId,
            disclaimerText: true,
            miscInstructions,
          }
        }
        return { ...data, serviceUnavailable: true, status: response.status }
      }
      return {
        searchFailed: true,
        reason: response.status,
        status: response.status,
      }
    })
    .catch((error) => ({
      searchFailed: true,
      reason: error?.response?.status,
      status: error?.response?.status,
    }))
}
