import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import { getTokenAction } from "actions"
import { DEFAULT_ERROR_STATUS_CODE } from "utils-lib/requests"
import { urls } from "utils-lib/builds"

export const pendingAC = (type, data) => ({
  type,
  payload: data,
})

export const failedAC = (type, data) => ({
  type,
  payload: data,
})

export const fulfilledAC = (type, data) => ({
  type,
  payload: data,
})

export const resetSelectedAddress = (type) => ({
  type,
})

export const setUnlinkAccount = (data) => (dispatch, getState) => {
  const api = `USER_ACCOUNTS`
  const state = getState()
  const userId = _.get(state, `userAccount.userDetails.userId`, ``)
  const template = URITemplate(urls.url[api])
  const url = template.expand({ userId })
  dispatch(pendingAC(ActionTypes.SET_UNLINKED_ACCOUNTS_PENDING, {}))

  return getTokenAction(dispatch, getState).then((token) => {
    const state = getState()
    const config = {
      headers: {
        [urls.okta.tokenFieldKey]: token.accessToken,
      },
      params: {
        lang: _.get(state, `siteLanguage.language`, `en_CA`),
      },
    }

    const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
    if (apiKey !== ``) {
      config.headers.apiKey = apiKey
    }

    return axios
      .create(config)
      .delete(url, {
        data,
      })
      .then((response) => {
        dispatch(
          fulfilledAC(ActionTypes.SET_UNLINKED_ACCOUNTS_FULFILLED, {
            linkedAccount: data,
            numLinkedAccounts:
              _.get(state, `userManageAccount.linkedAccounts`, []).length - 1,
          }),
        )
        dispatch(
          resetSelectedAddress(ActionTypes.RESET_SELECTED_ADRRESS_MY_SERVICES),
        )

        const newLinkedAccounts = _.get(
          state,
          `userManageAccount.linkedAccounts`,
          [],
        ).filter((s) => s.custAccountId !== data.custAccountId)

        window.cnxCustomerIds = _.join(
          _.toArray(
            _.mapValues(_.slice(newLinkedAccounts, -5), `custAccountId`),
          ),
        )
        return response
      })
      .catch((e) => {
        const errorMsg = _.get(e, `response.data.errorMsg`, ``)
        const errorData = {
          msg: typeof errorMsg === `string` ? errorMsg : ``,
          customerId: _.get(data, `custAccountId`, ``),
          statusCode: _.get(
            e,
            `response.data.statusCode`,
            DEFAULT_ERROR_STATUS_CODE,
          ),
        }

        dispatch(failedAC(ActionTypes.SET_UNLINKED_ACCOUNTS_FAILED, errorData))
        return errorData
      })
  })
}
