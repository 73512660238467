/* Only allow mocks in local environment. */
const enableMock = process.env.NODE_ENV === `development`

/* Show rolloff request form/status in My Services page. */
export const MOCK_IS_ROLLOFF_ELIGIBILITY_FLAG = false && enableMock
export const MOCK_IS_CONTACT_INFO_FIELD_OPTIONAL = false && enableMock

/* Use mock order summary/detail information from CloudCraze. */
export const MOCK_ORDER_INFORMATION = false && enableMock

/* Use mock ads customer lookup response */
export const MOCK_ADS_CUSTOMER_IDS_MULTI = false && enableMock
export const MOCK_ADS_CUSTOMER_IDS_SINGLE = false && enableMock

/* Use mock facility alerts response */
export const MOCK_FACILITY_ALERTS_RESPONSE = false && enableMock

/* SMS Flows */
export const MOCK_TEXT_FOR_CITY_BILLED_BULK_PICKUP_INFORMATION =
  false && enableMock

/* Mock service change APIs */
export const MOCK_SERVICE_CHANGE_INFORMATION = false && enableMock

/* Mock extra pickup APIs */
export const MOCK_EXTRA_PICKUP_INFORMATION = false && enableMock

/* Mock bulk pickup APIs */
export const MOCK_BULK_PICKUP_INFORMATION = false && enableMock

/* Mock missed pickup APIs */
export const MOCK_MISSED_PICKUP_INFORMATION = false && enableMock

/* Mock incidents API */
export const MOCK_INCIDENTS = false && enableMock

// Mock Service History / Visuals API
export const MOCK_SERVICE_HISTORY = false && enableMock
export const MOCK_SERVICE_VISUALS = false && enableMock

// Mock Preference Center and FTUE Modal API
export const MOCK_COMMUNICATION_PREFERENCES_GET = false && enableMock
export const MOCK_COMMUNICATION_PREFERENCES_PUT = false && enableMock

export const MOCK_MANAGE_CONTACTS_GET = false && enableMock
export const MOCK_MANAGE_CONTACTS_PUT = false && enableMock

/* Mock FTUE on Manage Accounts Page */
export const MOCK_FTUE_MANAGE_ACCOUNTS = false && enableMock

/* Mock Paperless Preference APIs */
export const MOCK_PAPERELESS_PREFERENCE_GET = false && enableMock
export const MOCK_PAPERELESS_PREFERENCE_SET = false && enableMock

/* SMS Flows */
export const MOCK_TEXT_FOR_ROLLOFF_INFORMATION = false && enableMock
export const MOCK_TEXT_FOR_XPU_INFORMATION = false && enableMock

/* Mock Container Repair API */
export const MOCK_CONTAINER_REPAIR_REQUEST = false && enableMock

export const MOCK_TEXT_FOR_CONTAINER_REPAIR_INFORMATION = false && enableMock
export const MOCK_TEXT_FOR_VIEW_ETA_INFORMATION = false && enableMock

/* Mock Bulk Pickup Error Modal Servlet */
export const MOCK_BULK_ERROR_SERVLET = false && enableMock

/* Mock Resi Pickup Error Modal Servlet */
export const MOCK_RESI_ERROR_SERVLET = false && enableMock
