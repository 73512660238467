import get from "lodash/get"
import axios from "axios"
import { urls } from "utils-lib/builds"
import {
  GET_ESB_TOKENS_PENDING,
  GET_ESB_TOKENS_FAILED,
  GET_ESB_TOKENS_FULFILLED,
} from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

const pendingAC = () => ({
  type: GET_ESB_TOKENS_PENDING,
})

const failedAC = (fetchCode) => ({
  type: GET_ESB_TOKENS_FAILED,
  payload: fetchCode,
})

const fulfilledAC = (data) => ({
  type: GET_ESB_TOKENS_FULFILLED,
  payload: data,
})

/**
 * Fetches new ESB tokens using a refresh token and dispatches actions based on the request's outcome.
 * @param {function} cb - Callback function to be called on successful token refresh. Defaults to an empty function.
 * @param {function} errorCb - Callback function to be called on token refresh failure. Defaults to an empty function.
 */
export const getESBTokens =
  (cb = () => {}, errorCb = () => {}) =>
  (dispatch) => {
    const api = `REFRESH_TOKEN`
    const url = urls.url[api]

    dispatch(pendingAC())
    const encodedAuthorization = window.btoa(
      `${urls.cloudCraze.headers.ClientId}:${urls.cloudCraze.headers.ClientSecret}`,
    )
    const config = {
      headers: {
        "content-type": `application/json`,
        authorization: `Basic ${encodedAuthorization}`,
      },
    }

    const apiKey = get(urls, `apiKey.ESB[${api}]`, ``)
    const data = {
      grant_type: `refresh_token`,
      refresh_token: apiKey,
    }

    return axios
      .post(url, data, config)
      .then((response) => {
        dispatch(fulfilledAC(response.data))
        cb()
        return response
      })
      .catch((error) => {
        dispatch(failedAC(get(error, `response.status`, ``)))
        errorCb()
      })
  }

export const getToken = () => getESBTokens
