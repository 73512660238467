import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.DELETE_PAYMENT_METHOD_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.DELETE_PAYMENT_METHOD_FAILED,
  payload: data,
})

export const fulfilledAC = (isAutoPayLinked) => ({
  type: ActionTypes.DELETE_PAYMENT_METHOD_FULFILLED,
  payload: isAutoPayLinked,
})

export const deletePaymentMethod =
  (payMethodId, isAutoPayLinked = false) =>
  (dispatch, getState) => {
    const api = `PAYMENT_METHOD`
    const state = getState()
    const template = URITemplate(urls.url[api])
    const url = template.expand({
      payMethodId,
    })

    dispatch(pendingAC())

    return getTokenAction(dispatch, getState).then((token) => {
      const config = {
        headers: {
          token: token.accessToken,
        },
        params: {
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
          lang: _.get(state, `siteLanguage.language`, `en_CA`),
        },
      }

      if (isAutoPayLinked) {
        config.params.unEnrollAutopay = `Y`
      }

      const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      if (apiKey !== ``) {
        config.headers.apiKey = apiKey
      }

      return axios
        .create(config)
        .delete(url)
        .then((response) => {
          dispatch(fulfilledAC(isAutoPayLinked))
          return response
        })
        .catch((error) => {
          const errorCode = _.get(error, `response.status`, ``)
          const errorMsg = _.get(error, `response.data.errorMsg`, ``)
          const statusCode = _.get(error, `response.data.statusCode`, ``)
          dispatch(failedAC({ errorCode, errorMsg, statusCode }))
        })
    })
  }
