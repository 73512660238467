import { isMyWMApp } from "utils-lib/isMyWMApp"
import { createTheme } from "@mui/material/styles"

/*
  Font styles from WM DSM:
  https://wm.invisionapp.com/dsm/waste-management/wm-dsm-c-progress/asset/fonts/5d38592dfea0acc41a508cbf

  300 Light
  300 Light Italic
  400 Regular
  400 Regular Italic
  500 Medium
  600 Semi-Bold Italic
  700 Bold
  700 Bold Italic
  800 Extra-Bold
  800 Extra-Bold Italic
  600 Semi-Bold
*/

export const wmFonts = {
  maaxRegular: {
    fontFamily: "Maax-Regular, Arial, sans-serif !important",
  },
  maaxBold: {
    fontFamily: "Maax-Bold, Arial, sans-serif !important",
  },
  maaxBlack: {
    fontFamily: "Maax-Black, Arial, sans-serif !important",
  },
  maaxItalic: {
    fontFamily: "Maax-Italic, Arial, sans-serif !important",
  },
  maaxMedium: {
    fontFamily: "Maax-Medium, Arial, sans-serif !important",
  },
}

/*
  Colors from DSM:
  https://wm.invisionapp.com/dsm/waste-management/wm-dsm-c-progress/folder/colors/5d37507f12e0e9ae95afbcd9
*/

export const wmColor = {
  black: `#212121`,
  green1: `#024731`,
  green2: `#1c8200`,
  green3: `#70a116`,
  green4: `#bed600`,
  green5: `#79b943`,
  green6: `#819b46`,
  greenHover: `#006937`,
  gray1: `#fbfbfb`,
  gray2: `#f4f4f4`,
  gray3: `#d6d6d6`,
  gray4: `#767676`,
  gray5: `#4f4f58`,
  gray6: `#fbfbfb`,
  gray7: `#979797`,
  errorRed: `#e63131`,
  errorRedLight: `#9F2424`,
  Black: `#212221`,
  exceptionsYellow: `#ffeeba`,
  alertRed: `#c30000`,
  white: `#ffffff`,
  yellowHover: `#D1DE2E`,
  blueInfo: `#d8ebff`,
  blueLink: `#007bff`,
  dividerLightGray: `#cedad6`,
  shadow: `#afb5ba`,
  primaryYellow: `#e8F733`,
  activeYellow: `#bac629`,
  wmYellow: `#eebe00`, // #ebab00; // #fecb00; // #e6a826; // #ebab00; // #eab546;
  warningOrange: `#c06c0c`,
  infoBlueBackground: `#d8ebff`,
  recyclingBlue: `#0026ff`,
  selectionColor: `#e7f7e8`,
  Sand: `#F8F8F2`,
  /* 2.0 color palette */
  secondaryGreen: `#70A116`,
  secondaryHover: `#ECF8E8`,
  secondaryActive: `#D2E6CC`,
  yellowSecondaryHover: `rgba(232,247,51,0.2)`,
  yellowSecondaryActive: `rgba(232,247,51,0.4)`,
  beige: `#EFEFE4`,
  sand: `#F8F8F2`,
  greenShade: `#023625`,
  greenTint: `#DAE4E1`,
  greenTint1: `#E7F7E8`,
  greenShade1: `rgba(2,71,49,0.8)`,
  grayBorderShade: `rgba(33,33,33,0.1)`,
  pureBlack: `#000000`,
  darkRemodeled: `#1C1C19`,
  beige25: `#F8F8EE`,
  beigeHover: `#E3E3DB`,
  infoYellowBackground: `#FDF7EF`,
  infoDarkYellowText: `#775500`,
  errorLightRedBackground: "#fdf6f5",
}

export const wmThemeOptions = {
  palette: {
    common: {
      black: wmColor.black,
      white: wmColor.white,
      error: wmColor.alertRed,
      errorRedLight: wmColor.errorRedLight,
    },
    primary: {
      main: wmColor.green2,
      main2_0: wmColor.green1,
      contrastText: wmColor.white,
      gray: wmColor.gray2,
      darkGray: wmColor.gray5,
      secondaryGreen: wmColor.secondaryGreen,
      black: wmColor.black,
      hoverGreen: wmColor.greenHover,
      yellowHover: wmColor.yellowHover,
      yellowActive: wmColor.activeYellow,
      pureBlack: wmColor.pureBlack,
    },
    secondary: {
      main: wmColor.green2,
      lightGreen: wmColor.green5,
      borderGray: wmColor.gray3,
      shade: wmColor.greenShade,
      shade1: wmColor.greenShade1,
      secondaryGreen: wmColor.secondaryGreen,
      hoverGreen: wmColor.secondaryHover,
      activeGreen: wmColor.secondaryActive,
      hoverYellow: wmColor.yellowSecondaryHover,
      activeYellow: wmColor.yellowSecondaryActive,
    },
    error: {
      main: wmColor.alertRed,
    },
    text: {
      secondary: wmColor.green1,
      brandingGreen: wmColor.green3,
      buttonLabel: wmColor.green2,
      bodyGray: wmColor.gray4,
      altGray: wmColor.black,
      linkGreen: wmColor.green2,
      selectionColor: wmColor.selectionColor,
      greenHover: wmColor.greenHover,
      primaryDisabled: wmColor.gray3,
      gray3: wmColor.gray3,
      gray4: wmColor.gray4,
      error: wmColor.alertRed,
      lightHeader: wmColor.gray5,
      yellowDisabled: wmColor.gray5,
      black: wmColor.pureBlack,
      darkRemodeled: wmColor.darkRemodeled,
    },
    background: {
      buttonGreen: wmColor.green2,
      buttonGray: wmColor.gray3,
      brandingGreen: wmColor.green3,
      darkGreen: wmColor.green1,
      gray: wmColor.gray2,
      green: wmColor.green1,
      white: wmColor.white,
      dropShadow: wmColor.shadow,
      lightGray: wmColor.gray2,
      errorPopup: wmColor.exceptionsYellow,
      informationBlue: wmColor.infoBlueBackground,
      disabled: wmColor.gray4,
      disabledGray: wmColor.dividerLightGray,
      exceptionsYellow: wmColor.exceptionsYellow,
      info: wmColor.blueInfo,
      /* 2.0 colors */
      yellow: wmColor.primaryYellow,
      yellowHover: wmColor.yellowHover,
      beige: wmColor.beige,
      sand: wmColor.sand,
      Sand: wmColor.sand,
      darkgreen2_0: wmColor.green1,
      lightGreen: wmColor.green4,
      info2_0: wmColor.recyclingBlue,
      beige_25: wmColor.beige25,
      beigeHover: wmColor.beigeHover,
    },
    button: {
      primary: wmColor.green2,
      primaryHover: wmColor.green1,
      primaryDisabled: wmColor.gray4,
      yellowDisabled: wmColor.gray3,
    },
    border: {
      divider: wmColor.gray4,
      dividerLight: wmColor.dividerLightGray,
      dividerAlternate: wmColor.gray3,
      error: wmColor.alertRed,
      green: wmColor.green2,
      gray: wmColor.gray3,
      darkGray: wmColor.gray4,
      lightGray: wmColor.gray1,
      grayboxShadow: wmColor.grayBorderShade,
    },
    carousel: {
      inactive: wmColor.gray4,
      active: wmColor.green2,
      activeHover: wmColor.green1,
    },
    link: {
      green: wmColor.green2,
      green1: wmColor.green1,
      blue: wmColor.blueLink,
    },

    switch: {
      inactiveThumb: wmColor.white,
      inactiveTrack: wmColor.gray4,
      activeThumb: wmColor.green2,
      activeTrack: wmColor.green2,
      disabledThumb: wmColor.gray4,
      disabledTrack: wmColor.gray3,
    },
  },
  typography: {
    fontFamily: [`Maax-Regular`, `Arial Narrow`, `Arial`, `sans-serif`].join(
      `,`,
    ),
    subheading1: {
      color: wmColor.gray5,
      ...wmFonts.maaxBold,
      fontSize: `1.125rem`,
      lineHeight: `1.5rem`,
    },
    subheading2: {
      color: wmColor.gray4,
      ...wmFonts.maaxBold,
      fontSize: `1rem`,
      lineHeight: `1.5rem`,
    },
    subtitle1: {
      // use this for "emphasis" in our DSM
      color: wmColor.gray4,
      ...wmFonts.maaxBold,
      fontSize: `1rem`,
      lineHeight: `1.5rem`,
    },
    subtitle2: {
      color: wmColor.gray5,
      ...wmFonts.maaxBold,
      fontSize: `1.125rem`,
      lineHeight: `1.5rem`,
    },
    body1: {
      ...wmFonts.maaxRegular,
      fontSize: `1rem`,
      lineHeight: `1.5rem`,
      color: wmColor.gray5,

      "& ul": {
        listStyleType: `initial`,
      },
    },
    body2: {
      ...wmFonts.maaxRegular,
      fontSize: `1rem`,
      lineHeight: `1.5rem`,
      color: wmColor.gray5,

      "& ul": {
        listStyleType: `initial`,
      },
    },
    legal: {
      color: wmColor.gray5,
      ...wmFonts.maaxRegular,
      fontSize: `0.75rem`,
      lineHeight: `1rem`,
    },
    caption: {
      color: wmColor.gray5,
      ...wmFonts.maaxRegular,
      fontSize: `0.75rem`,
      lineHeight: `1rem`,
    },
    body3: {
      ...wmFonts.maaxRegular,
      fontSize: `0.875rem`,
      lineHeight: `1rem`,
      color: wmColor.gray5,
    },
    fonts: {
      interRegular: {
        ...wmFonts.interRegular,
      },
      interBold: {
        ...wmFonts.interBold,
      },
      interMedium: {
        ...wmFonts.interMedium,
      },
      interSemiBold: {
        ...wmFonts.interSemiBold,
      },
      maaxRegular: {
        ...wmFonts.maaxRegular,
      },
      maaxMedium: {
        ...wmFonts.maaxMedium,
      },
      maaxBold: {
        ...wmFonts.maaxBold,
      },
      maaxBlack: {
        ...wmFonts.maaxBlack,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576, // Todo: 544px was used for $screen-sm-min, but it's inconsistent with bootstrap.
      md: 768,
      lg: 992,
      ipadPro: 1024,
      xl: 1200,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: !isMyWMApp, // No more ripple, on the whole application 💣!
      },
      styleOverrides: {
        root: {
          "&.MuiRadio-root:hover": {
            backgroundColor: `none !important`,
          },
          "&.MuiCheckbox-root:hover": {
            backgroundColor: `none !important`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontFamily: `Maax-Bold`,
          // this fixes issues with boxShadow after migration to MUIv5
          boxShadow: `none !important`,
          minWidth: `7.8125rem`,
          height: `3.5rem`,
          fontSize: `1rem`,
        },
        root: {
          minWidth: "9.4rem",
          boxShadow: `none !important`,
          "&:hover": {
            boxShadow: `none !important`,
            textDecoration: `none !important`,
          },
        },
        text: {
          padding: 0,
          minWidth: `auto`,
          ...wmFonts.maaxRegular,
          textDecoration: `underline`,
          color: wmColor.green1,
          fontSize: `1rem`,
          lineHeight: `1.5rem`,
          textTransform: `none`,
          "&:hover": {
            backgroundColor: `transparent`,
            color: wmColor.green1,
            textDecoration: `underline`,
          },
        },
        outlined: {
          padding: `.5rem`,
          ...wmFonts.maaxRegular,
          color: wmColor.green1,
          fontSize: `0.875rem`,
          lineHeight: `1.25rem`,
          // minWidth: `8rem`,
          borderColor: `#d2e7ce`,
          "&:hover": {
            backgroundColor: `inherit`,
            boxShadow: `none !important`,
          },
        },
        containedSecondary: {
          border: `2px solid ${wmColor.green2}`,
          backgroundColor: `white`,
          color: wmColor.green2,
          fontFamily: `Maax-Bold`,
          "&:hover": {
            backgroundColor: `white`,
          },
          "&:disabled": {
            color: wmColor.gray4,
            backgroundColor: `white`,
            borderColor: wmColor.gray3,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          ...wmFonts.maaxRegular,
          fontSize: `1rem`,
          lineHeight: `1.5rem`,
          color: wmColor.gray5,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: wmColor.green2,
        },
        colorSecondary: {
          color: wmColor.green1,
          "&.Mui-checked": {
            color: wmColor.green1,
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: wmColor.green2,
        },
        colorSecondary: {
          color: wmColor.green1,
          "&.Mui-checked": {
            color: wmColor.green1,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        label: {
          ...wmFonts.maaxRegular,
          fontSize: `1rem`,
          lineHeight: `1.5rem`,
          textTransform: `none`,
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          color: wmColor.black,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&:focus": {
            color: wmColor.white,
            backgroundColor: wmColor.green2,
          },
          "&:not(.Mui-selected)": {
            border: `none`,
          },
        },
        daySelected: {
          backgroundColor: `${wmColor.green2} !important`,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: 10,
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyItems: "center",
          maxHeight: "initial",
          minHeight: "initial",
          "&:first-child": {
            order: 0,
            paddingRight: "20px",
            paddingLeft: "20px",
          },
        },
        labelContainer: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "-2.15rem",
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        spacer: {
          width: "220px",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: wmColor.white,
          "&.Mui-focused": {
            background: wmColor.white,
          },
          "&:hover": {
            background: wmColor.white,
          },
        },
        input: {
          ...wmFonts.maaxBold,
          color: wmColor.gray4,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          "&.Mui-disabled": {
            color: wmColor.gray4,
          },
          "&:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error)": {
            color: wmColor.gray5,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          ...wmFonts.maaxRegular,
          "&:not(:disabled)": {
            color: wmColor.Black,
          },
          "&:disabled": {
            color: wmColor.gray4,
          },
          // this fixes issues with TextField component height after migration to MUIv5
          height: `auto`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: wmColor.green2,
          },
          borderBottom: `2px solid ${wmColor.gray4}`,
          textTransform: "none",
          minWidth: "90px !important", // Keeps default minWidth on desktop
          ...wmFonts.maaxBold,
          fontSize: `1rem`,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: "5px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: wmColor.white,
          boxShadow: "0px 2px 6px 0px gray",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        "& .MuiSwitch-thumb": {
          color: wmColor.white,
        },
        "& .MuiSwitch-track": {
          color: wmColor.gray4,
        },
        "& .Mui-checked": {
          "& .MuiSwitch-thumb": {
            color: wmColor.green2,
          },
          "& .MuiSwitch-track": {
            color: wmColor.green2,
            opacity: 0.6,
          },
        },
        "& .Mui-disabled": {
          "& .MuiSwitch-thumb": {
            color: wmColor.gray4,
          },
          "& .MuiSwitch-track": {
            color: wmColor.gray3,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: `1.5rem`,
        },
      },
    },
  },
}

export const wmTheme = createTheme(wmThemeOptions)

wmTheme.typography.h1 = {
  color: wmColor.green1,
  fontFamily: `Maax-Black`,
  [wmTheme.breakpoints.down(`md`)]: {
    fontSize: `2.25rem`,
    lineHeight: `3rem`,
  },
  [wmTheme.breakpoints.up(`md`)]: {
    fontSize: `2.75rem`,
    lineHeight: `3.5rem`,
  },
  [wmTheme.breakpoints.up(`lg`)]: {
    fontSize: `3.375rem`,
    lineHeight: `4rem`,
  },
}

wmTheme.typography.h2 = {
  color: wmColor.green1,
  fontFamily: `Maax-Bold`,
  [wmTheme.breakpoints.down(`md`)]: {
    fontSize: `1.5rem`,
    lineHeight: `2rem`,
  },
  [wmTheme.breakpoints.up(`md`)]: {
    fontSize: `2.25rem`,
    lineHeight: `3rem`,
  },
  [wmTheme.breakpoints.up(`lg`)]: {
    fontSize: `2.75rem`,
    lineHeight: `3.5rem`,
  },
}

wmTheme.typography.h3 = {
  color: wmColor.green1,
  fontFamily: `Maax-Bold`,
  [wmTheme.breakpoints.down(`md`)]: {
    fontSize: `1.25rem`,
    lineHeight: `1.5rem`,
  },
  [wmTheme.breakpoints.up(`md`)]: {
    fontSize: `1.5rem`,
    lineHeight: `2rem`,
  },
  [wmTheme.breakpoints.up(`lg`)]: {
    fontSize: `2.25rem`,
    lineHeight: `3rem`,
  },
}

wmTheme.typography.h4 = {
  color: wmColor.green1,
  fontFamily: `Maax-Bold`,
  fontSize: `1.5rem`,
  lineHeight: `2rem`,
}

wmTheme.typography.h5 = {
  color: wmColor.green1,
  fontFamily: `Maax-Bold`,
  fontSize: `1.125rem`,
  lineHeight: `1.5rem`,
}

wmTheme.typography.body2 = {
  ...wmFonts.maaxRegular,
  fontSize: `0.875rem`,
  lineHeight: `1.25rem`,
  color: wmColor.gray4,
  "& ul": {
    listStyleType: `initial`,
  },
}

wmTheme.typography.overline = {
  ...wmFonts.maaxRegular,
  color: wmColor.gray4,
  fontSize: `0.75rem`,
  lineHeight: `1.5rem`,
  [wmTheme.breakpoints.up(`lg`)]: {
    fontSize: `0.875rem`,
    lineHeight: `1.5rem`,
  },
}
