import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { generateUniqueID } from "utils-lib/requests"
import { urls } from "utils-lib/builds"
import {
  GET_COMMERCIAL_SERVLET_PENDING,
  GET_COMMERCIAL_SERVLET_FAILED,
  GET_COMMERCIAL_SERVLET_FULFILLED,
  GET_CLOUDCRAZE_SERVICE_DAYS_PENDING,
  GET_CLOUDCRAZE_SERVICE_DAYS_FAILED,
  GET_CLOUDCRAZE_SERVICE_DAYS_FULFILLED,
  GET_CLOUDCRAZE_DELIVERY_SCHEDULE_PENDING,
  GET_CLOUDCRAZE_DELIVERY_SCHEDULE_FAILED,
  GET_CLOUDCRAZE_DELIVERY_SCHEDULE_FULFILLED,
} from "constants-lib/actionTypes"

export const pendingCommercialServlet = () => ({
  type: GET_COMMERCIAL_SERVLET_PENDING,
})

export const failedCommercialServlet = (data) => ({
  type: GET_COMMERCIAL_SERVLET_FAILED,
  payload: data,
})

export const fulfilledCommercialServlet = (data) => ({
  type: GET_COMMERCIAL_SERVLET_FULFILLED,
  payload: data,
})

export const pendingServiceDays = () => ({
  type: GET_CLOUDCRAZE_SERVICE_DAYS_PENDING,
})

export const fulfilledServiceDays = (data) => ({
  type: GET_CLOUDCRAZE_SERVICE_DAYS_FULFILLED,
  payload: data,
})

export const failedServiceDays = (data) => ({
  type: GET_CLOUDCRAZE_SERVICE_DAYS_FAILED,
  payload: data,
})

export const pendingDeliverySchedule = () => ({
  type: GET_CLOUDCRAZE_DELIVERY_SCHEDULE_PENDING,
})

export const fulfilledDeliverySchedule = (data) => ({
  type: GET_CLOUDCRAZE_DELIVERY_SCHEDULE_FULFILLED,
  payload: data,
})

export const failedDeliverySchedule = (data) => ({
  type: GET_CLOUDCRAZE_DELIVERY_SCHEDULE_FAILED,
  payload: data,
})

/**
 * Fetches service days from CloudCraze based on customer selections and dispatches actions based on the response.
 * @function getCloudCrazeServiceDays
 * @returns {Function} dispatches actions depending on the outcome of the API call.
 */
export const getCloudCrazeServiceDays = () => (dispatch, getState) => {
  const state = getState()
  const { customerSelections } = getState()
  const latitude = encodeURI(
    customerSelections.customerSearchedAddress.lat || ``,
  )
  const longitude = encodeURI(
    customerSelections.customerSearchedAddress.lng || ``,
  )
  const city = encodeURI(customerSelections.customerSearchedAddress.city || ``)
  const region = encodeURI(
    customerSelections.customerSearchedAddress.state || ``,
  )
  const street =
    `${customerSelections.customerSearchedAddress.streetNumber} ${customerSelections.customerSearchedAddress.streetName}` ||
    ``
  const zipcode = customerSelections.customerSearchedAddress.postalCode || ``
  const country = encodeURI(
    customerSelections.customerSearchedAddress.country || ``,
  )
  const siteId =
    get(state, "customerSelections.businessPickupData.catalogs", "").find(
      (item) => item.waste_stream === "Trash",
    )?.site_id ||
    get(state, "customerSelections.businessPickupData.catalogs", "")?.[0]
      ?.site_id

  const wasteStream = get(
    state,
    `customerSelections.businessPickupData.catalogs`,
    [],
  )
    .map((item) => item.waste_stream)
    .join(`,`)

  if (wasteStream === "") {
    return
  }

  const api = `GET_CLOUDCRAZE_SERVICE_DAYS`
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    city,
    region,
    street,
    zipcode,
    country,
    wasteStream,
    siteId,
    latitude,
    longitude,
  })

  const config = {
    url,
    method: `get`,
    headers: {
      "Content-Type": `application/json`,
      ClientId: urls.cloudCraze.headers.ClientId,
      "Request-Tracking-Id": generateUniqueID(),
    },
  }
  // eslint-disable-next-line consistent-return
  return axios(config)
    .then((response) => {
      if (
        response.data.locations.service_days &&
        response.data.locations.service_days.length > 0
      ) {
        dispatch(
          fulfilledServiceDays({
            cloudCrazepickupDays: response.data,
          }),
        )
      }
    })
    .catch((error) => {
      dispatch(failedServiceDays(error?.response?.status))
      return null
    })
}

/**
 * Fetches commercial servlet details from an API based on customer selections and dispatches actions based on the response.
 * @param {string} productType - The type of product.
 * @returns {Function} Dispatches actions depending on the outcome of the API call.
 */
export const getCommercialServletDetails =
  (productType) => (dispatch, getState) => {
    const state = getState()
    const { customerSelections, siteLanguage } = getState()
    const productIds = get(
      state,
      `customerSelections.businessPickupData.catalogs`,
      [],
    )
      .map((item) => item.products.map((item) => item.id))
      .join(`,`)

    if (productIds === "") {
      return
    }

    const country = encodeURI(
      customerSelections.customerSearchedAddress.country || ``,
    )
    const locale = encodeURI(siteLanguage.language || ``)
    const api = `COMMERCIAL_SERVLET_AEM`
    const template = URITemplate(urls.url[api])
    const url = template.expand({
      productIds,
      locale,
      country,
      productType: productType === "curb_side" ? "curbside" : "permanent",
    })

    const config = {
      url,
      method: `get`,
    }
    // eslint-disable-next-line consistent-return
    return axios(config)
      .then((response) => {
        dispatch(
          fulfilledCommercialServlet({
            commercialServletDetails: response.data,
          }),
        )
      })
      .catch((error) => {
        dispatch(failedCommercialServlet(error?.response?.status))
        return null
      })
  }

/**
 * Fetches delivery schedule from CloudCraze based on customer selections and dispatches actions based on the response.
 * @param {string} productType - The type of product.
 * @returns {Function} Dispatches actions depending on the outcome of the API call.
 */
export const getCloudCrazeDeliverySchedule =
  (productType) => (dispatch, getState) => {
    const { customerSelections } = getState()
    const latitude = encodeURI(
      customerSelections.customerSearchedAddress.lat || ``,
    )
    const longitude = encodeURI(
      customerSelections.customerSearchedAddress.lng || ``,
    )
    const city = encodeURI(
      customerSelections.customerSearchedAddress.city || ``,
    )
    const state = encodeURI(
      customerSelections.customerSearchedAddress.state || ``,
    )
    const street =
      `${customerSelections.customerSearchedAddress.streetNumber} ${customerSelections.customerSearchedAddress.streetName}` ||
      ``
    const zipcode = customerSelections.customerSearchedAddress.postalCode || ``
    const country = encodeURI(
      customerSelections.customerSearchedAddress.country || ``,
    )
    const lineOfBusiness =
      productType === "permanent_bin" ? "Commercial" : "Residential"

    const api = `GET_CLOUDCRAZE_DELIVERY_SCHEDULE`
    const template = URITemplate(urls.url[api])
    const url = template.expand({
      latitude,
      longitude,
      city,
      state,
      street,
      zipcode,
      country,
      lineOfBusiness,
    })

    const config = {
      url,
      method: `get`,
      headers: {
        "Content-Type": `application/json`,
        ClientId: urls.cloudCraze.headers.ClientId,
        "Request-Tracking-Id": generateUniqueID(),
      },
    }
    return axios(config)
      .then((response) => {
        if (
          response.data.locations.delivery_schedules &&
          response.data.locations.delivery_schedules.length > 0
        ) {
          dispatch(
            fulfilledDeliverySchedule({
              cloudCrazeDeliveryDays: response.data,
            }),
          )
        }
      })
      .catch((error) => {
        dispatch(failedDeliverySchedule(error?.response?.status))
        return null
      })
  }
