import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.UPDATE_USER_PAYMENT_METHOD_PENDING,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.UPDATE_USER_PAYMENT_METHOD_FULFILLED,
  payload: data,
})

export const failedAC = (data) => ({
  type: ActionTypes.UPDATE_USER_PAYMENT_METHOD_FAILED,
  payload: data,
})

export const updateUserPaymentMethod =
  ({ payMethodId, firstName, lastName, expMonth, expYear, zip, cvv }) =>
  (dispatch, getState) => {
    const api = `PAYMENT_METHOD`
    dispatch(pendingAC())

    return getTokenAction(dispatch, getState)
      .then((token) => {
        const template = URITemplate(urls.url[api])
        const url = template.expand({ payMethodId })
        const state = getState()

        const config = {
          url,
          method: `put`,
          headers: {
            token: token.accessToken,
          },
          params: {
            userId: _.get(state, `userAccount.userDetails.userId`, ``),
            lang: state.siteLanguage.language,
          },
          data: {
            firstName,
            lastName,
            expMonth,
            expYear,
            zip,
            cvv,
          },
        }

        const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }

        return axios(config).then((response) => {
          dispatch(fulfilledAC(response.data))
          return response
        })
      })
      .catch((response) => {
        dispatch(
          failedAC({
            statusCode: _.get(response, `response.status`, ``),
            errorMessage: _.get(response, `response.data.Error`, ``),
          }),
        )
      })
  }
