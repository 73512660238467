import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import { generateUniqueID } from "utils-lib/requests"
import { profileIdForAPIs } from "constants-lib/profileIdForAPIs"
// import _ from 'lodash'
import { urls } from "utils-lib/builds/index"

export const resendConfirmationText =
  (customerId, token, prefOptions, resendObject) => (dispatch, getState) => {
    // call update and update the customper pref options
    const state = getState()
    const template = URITemplate(
      urls.cloudfront.myPreferences.putResendConfirmation.url,
    )
    const lang = state.siteLanguage.language

    const resend = `Y`
    const putCustomerContactsInfo = template.expand({
      customerId,
      resend,
      lang,
    })
    const apiType = `OPTIONS`
    const profileId = profileIdForAPIs().profileId[apiType]
    const putResendOption = {
      headers: {
        "Request-Tracking-Id": generateUniqueID(),
        "Content-Type": `application/json`,
        Authorization: `Bearer ${token}`,
        ProfileId: profileId,
      },
      method: `PUT`,
      data: prefOptions,
    }
    dispatch({
      type: ActionTypes.SEND_CONFIRMATION_TEXT_PENDING,
      payload: resendObject,
    })

    return axios
      .post(putCustomerContactsInfo, putResendOption)
      .then((response) => {
        dispatch({
          type: ActionTypes.SEND_CONFIRMATION_TEXT_FULFILLED,
          payload: resendObject,
        })
        return response
      })
      .catch(() => {
        dispatch({
          type: ActionTypes.SEND_CONFIRMATION_TEXT_FAILED,
          payload: resendObject,
        })
      })
  }
