import toString from "lodash/toString"
import map from "lodash/map"
import PropTypes from "prop-types"
import Checkbox from "@mui/material/Checkbox"
import FormLabel from "@mui/material/FormLabel"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import { makeStyles } from "tss-react/mui"
import { wmColor } from "themes-lib/wmTheme"
import {
  getAdobeLaunchAttribute,
  getAdobeLaunchClassName,
} from "utils-lib/analytics"
import { handleEnterPress } from "utils-lib/handleEnterPress"
import { KEY_CODE_MAPPING } from "constants-lib/keyCodes"

const useStyles = makeStyles()(() => ({
  itemClass: {
    display: `flex`,
    width: "fit-content",
    "& .MuiFormControlLabel-label": {
      display: `flex`,
      color: `${wmColor.green1} !important`,
      "&:hover": {
        color: `${wmColor.green1} !important`,
      },
    },
  },
  inputClass: {
    color: `${wmColor.green1}`,
    "&:hover": {
      background: `none !important`,
    },
    "&.Mui-disabled": {
      color: `${wmColor.gray3} !important`,
    },
  },
  labelClass: {
    margin: `9px 0`,
  },
  top: {
    alignItems: `flex-start`,
  },
  bottom: {
    alignItems: `flex-end`,
  },
  center: {
    alignItems: `center`,
  },
}))

export const CheckboxForm = ({
  items,
  groupClassName,
  groupLabel,
  groupLabelClassName,
  groupLabelStyle,
  itemClassName,
  itemStyle,
  labelAlignment,
  labelPlacement,
  labelClassName,
  inputStyle,
  inputClassName,
  shouldNotTrackInComponent,
  analytics_key,
  isUncontrolled,
  showTabIndex,
}) => {
  const { classes } = useStyles()

  let clickLabel = ``
  if (typeof analytics_key === `string` && analytics_key !== ``) {
    clickLabel = analytics_key
  } else if (groupLabel) {
    clickLabel = groupLabel
  }
  const analyticsClass = getAdobeLaunchClassName({
    disabled: shouldNotTrackInComponent,
  })

  /*
    disabled
    analytics_key
    shouldNotTrackInComponent

    groupClassName
    groupStyle

    groupLabel
    groupLabelClassName
    groupLabelStyle

    itemClassName
    itemStyle
    labelPlacement
    labelClassName

    inputStyle
    inputClassName

    items
  */

  const handleChange = (onChange, checked, indeterminate) => {
    if (indeterminate) {
      onChange(false)
    } else {
      onChange(!checked)
    }
  }

  const handleKeyPress = (e, onChange, checked, indeterminate) => {
    if (
      e.keyCode === KEY_CODE_MAPPING.ENTER ||
      e.keyCode === KEY_CODE_MAPPING.SPACE_BAR
    ) {
      handleEnterPress(e, handleChange(onChange, checked, indeterminate))
    }
  }

  return (
    <div
      data-testid="CheckboxForm"
      className={`CheckboxForm ${groupClassName}`}
    >
      {groupLabel && (
        <FormLabel className={groupLabelClassName} style={groupLabelStyle}>
          {groupLabel}
        </FormLabel>
      )}
      <FormGroup>
        {map(items, (item) => {
          const { label, indeterminate, checked, onChange, disabled } = item
          const customCheckboxProps = {}
          if (!isUncontrolled) {
            customCheckboxProps.checked = checked
          }

          const itemAnalyticsClasses = `${analyticsClass} checkbox-analytics-${
            indeterminate ? `indeterminate` : toString(checked)
          }`
          const itemStylingClass = label
            ? `${classes.itemClass} ${itemClassName} ${classes[labelAlignment]}`
            : `${classes.inputClass} ${inputClassName}`

          return label ? (
            <FormControlLabel
              key={`${groupLabel}-${toString(item.label)}`}
              tabIndex={disabled ? -1 : 0}
              data-testid="CheckboxForm-label"
              label={label}
              labelPlacement={labelPlacement}
              classes={{ label: `${classes.labelClass} ${labelClassName}` }}
              className={`${itemStylingClass} ${itemAnalyticsClasses}`}
              style={{ ...itemStyle }}
              onChange={() => handleChange(onChange, checked, indeterminate)}
              onKeyDown={(e) =>
                handleKeyPress(e, onChange, checked, indeterminate)
              }
              control={
                <Checkbox
                  className={`${classes.inputClass} ${inputClassName} maax-font-weight-regular`}
                  style={inputStyle}
                  disabled={disabled}
                  indeterminate={indeterminate}
                  disableRipple
                  inputProps={{
                    "aria-label": label,
                    tabIndex: showTabIndex ? "0" : "-1",
                  }}
                  {...customCheckboxProps}
                />
              }
              {...getAdobeLaunchAttribute({
                disabled: shouldNotTrackInComponent,
                value: `${clickLabel} ${item.analytics_key || label}`,
              })}
            />
          ) : (
            <Checkbox
              key={toString(item)}
              className={`${itemStylingClass} ${itemAnalyticsClasses}`}
              style={inputStyle}
              onChange={() => handleChange(onChange, checked, indeterminate)}
              onKeyDown={(e) =>
                handleKeyPress(e, onChange, checked, indeterminate)
              }
              disabled={disabled}
              indeterminate={indeterminate}
              inputProps={{
                "aria-label": "checkbox",
                tabIndex: showTabIndex ? "0" : "-1",
              }}
              disableRipple
              {...customCheckboxProps}
              {...getAdobeLaunchAttribute({
                disabled: shouldNotTrackInComponent,
                value: `${clickLabel} ${item.analytics_key || label}`,
              })}
            />
          )
        })}
      </FormGroup>
    </div>
  )
}

CheckboxForm.propTypes = {
  items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  groupClassName: PropTypes.string,
  groupStyle: PropTypes.object,
  groupLabel: PropTypes.string,
  groupLabelClassName: PropTypes.string,
  groupLabelStyle: PropTypes.object,
  itemClassName: PropTypes.string,
  itemStyle: PropTypes.object,
  labelAlignment: PropTypes.string,
  labelPlacement: PropTypes.string,
  labelClassName: PropTypes.string,
  inputStyle: PropTypes.object,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
  shouldNotTrackInComponent: PropTypes.bool,
  analytics_key: PropTypes.string,
  isUncontrolled: PropTypes.bool,
  showTabIndex: PropTypes.bool,
}

CheckboxForm.defaultProps = {
  groupClassName: ``,
  groupStyle: {},
  groupLabel: ``,
  groupLabelClassName: ``,
  groupLabelStyle: {},
  itemClassName: ``,
  itemStyle: {},
  labelAlignment: `top` /* top = flex-start, bottom = flex-end, center = center */,
  labelPlacement: `end`,
  labelClassName: ``,
  inputStyle: {},
  inputClassName: ``,
  disabled: true,
  shouldNotTrackInComponent: false,
  analytics_key: ``,
  isUncontrolled: false,
  showTabIndex: true,
}
