import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import {
  MOCK_ADS_CUSTOMER_IDS_SINGLE,
  MOCK_ADS_CUSTOMER_IDS_MULTI,
} from "utils-lib/mocks"
import {
  GET_ADS_CUSTOMER_ACCOUNTS_PENDING,
  GET_ADS_CUSTOMER_ACCOUNTS_FAILED,
  GET_ADS_CUSTOMER_ACCOUNTS_FULFILLED,
  RESET_ADS_CUSTOMER_ACCOUNTS,
} from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export const pendingAC = () => ({
  type: GET_ADS_CUSTOMER_ACCOUNTS_PENDING,
})

export const failedAC = (data) => ({
  type: GET_ADS_CUSTOMER_ACCOUNTS_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: GET_ADS_CUSTOMER_ACCOUNTS_FULFILLED,
  payload: data,
})

export const resetADSCustomerAccounts = () => ({
  type: RESET_ADS_CUSTOMER_ACCOUNTS,
})

/**
 * Fetches ADS customer accounts based on account ID, site ID, and postal code, then dispatches actions based on the API response.
 * @param {Object} params - The parameters for the API request.
 * @param {string} params.adsAccountId - The ADS account ID.
 * @param {string} params.siteId - The site ID.
 * @param {string} params.postalCode - The postal code.
 * @param {Function} dispatch - The Redux dispatch function used to dispatch actions.
 */
export const getADSCustomerAccounts =
  ({ adsAccountId, siteId, postalCode }) =>
  (dispatch) => {
    const api = `ADS_ACCOUNTS`
    const apiKey = get(urls, `apiKey.GUEST[${api}]`, ``)
    const template = URITemplate(
      urls.url[api],
      // `https://qa3-rest-api.wm.com/account/ads/search?adsAccountId={adsAccountId}&postalCode={postalCode}`
    )
    let url = template.expand({
      adsAccountId,
      siteId,
      postalCode,
    })
    if (MOCK_ADS_CUSTOMER_IDS_SINGLE) {
      url = `${window.origin}/mock/ads-customer-ids-single-response.json`
    } else if (MOCK_ADS_CUSTOMER_IDS_MULTI) {
      url = `${window.origin}/mock/ads-customer-ids-multi-response.json`
    }

    const config = {
      headers: { apiKey },
    }

    dispatch(pendingAC())
    return axios
      .get(url, config)
      .then((response) => {
        // dispatch(fulfilledAC(response.data))
        dispatch(fulfilledAC(response.data.data.customers))
        return response.data
      })
      .catch((error) => {
        // dispatch(failedAC(response.data))
        dispatch(failedAC(error.response.status))
        return error.response.status
      })
  }
