import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import {
  GET_SERVLET_DETAILS_FAILED,
  GET_SERVLET_DETAILS_FULFILLED,
  GET_SERVLET_DETAILS_PENDING,
} from "constants-lib/actionTypes"

export const pendingAC = () => ({
  type: GET_SERVLET_DETAILS_PENDING,
})

export const failedAC = (data) => ({
  type: GET_SERVLET_DETAILS_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: GET_SERVLET_DETAILS_FULFILLED,
  payload: data,
})

/**
 * It takes the product ids from the redux store, and then makes a call to the AEM_SERVLET_DETAILS
 * endpoint to get the details of the products
 * @returns The return value is the result of the last expression in the function body, which is the
 * result of the dispatch call.
 */
export const getServletDetails = () => (dispatch, getState) => {
  const state = getState()
  const { customerSelections, siteLanguage } = getState()
  const productIds = get(
    state,
    `onlineProducts.onlineEcommercePricing.products`,
    [],
  )
    .map((item) => item.id)
    .join(`,`)

  if (productIds === "") {
    return
  }
  const api = `AEM_SERVLET_DETAILS`
  const country = encodeURI(
    customerSelections.customerSearchedAddress.country || ``,
  )
  const locale = encodeURI(siteLanguage.language || ``)
  const template = URITemplate(urls.url[api])
  const url = template.expand({
    productIds,
    locale,
    country,
  })
  dispatch(pendingAC())
  // eslint-disable-next-line consistent-return
  return axios
    .get(url)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      return response.data
    })
    .catch((response) => {
      dispatch(failedAC(response.data))
      // return response
    })
}
