import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"
import { MOCK_PAPERELESS_PREFERENCE_GET } from "utils-lib/mocks"

export const pendingAC = () => ({
  type: ActionTypes.GET_PAPERLESS_PREFERENCES_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.GET_PAPERLESS_PREFERENCES_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_PAPERLESS_PREFERENCES_FULFILLED,
  payload: data,
})

export const getPaperlessPreferences =
  (custId = ``) =>
  (dispatch, getState) => {
    const state = getState()
    const api = `PAPERLESS_PREFERENCES`
    dispatch(pendingAC())

    const template = URITemplate(urls.url[api])
    const customerId =
      custId === ``
        ? _.get(
            state.customerAutoPayPaperless,
            `autoPaymentAndPaperlessAccount`,
            ``,
          )
        : custId
    const userId = _.get(state, `userAccount.userDetails.userId`, ``)
    const lang = _.get(state, `siteLanguage.language`, `en_CA`)
    const url = MOCK_PAPERELESS_PREFERENCE_GET
      ? `${window.origin}/mock/paperless-preferences-get-response.json`
      : template.expand({ userId, customerId })

    return getTokenAction(dispatch, getState)
      .then((token) => {
        const config = {
          headers: {
            oktaToken: token.accessToken,
          },
          params: {
            lang,
          },
        }

        const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }

        return axios
          .create(config)
          .get(url)
          .then((response) => {
            dispatch(fulfilledAC(_.get(response, `data.data`, {})))
            return _.get(response, `data`, {})
          })
      })
      .catch((response) => {
        dispatch(
          failedAC({
            statusCode: _.get(response, `response.status`, ``),
            errorMessage: _.get(response, `response.data.Error`, ``),
          }),
        )
      })
  }

export const clearGetPaperlessPreferences = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_GET_PAPERLESS_PREFERENCES_STATUS,
  })
}
