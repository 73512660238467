/* eslint-disable max-len */
import { getWindow } from "utils-lib/getWindow"

// NOTE: Point straight to the bucket when possible for local & dev environments
// so we don't have to deal with cache issues.

import devUrls from "./dev"
import qa2Urls from "./qa2"
import qa3Urls from "./qa3"

const window = getWindow()

// CHANGE THIS LINE TO ENABLE QA.
const MOCK_QA_ENVIRONMENT = true // Set true to use QA endpoints.

const envUrls = {
  dev: devUrls,
  qa2: qa2Urls,
  qa3: qa3Urls,
}

// DO NOT CHANGE THIS LINE.
const env = MOCK_QA_ENVIRONMENT === true ? `qa2` : `qa3`
const { origin } = window.location
const mockOrigin =
  env === `qa3` ? `https://wmqa3.wm.com` : `https://wmqa2.wm.com`

export default {
  ...envUrls[env],
  aem: {
    AEM_JSON_EN_US: `${mockOrigin}/us/en/datapage.model.json`,
    AEM_JSON_EN_CA: `${mockOrigin}/ca/en/datapage.model.json`,
    AEM_JSON_FR_CA: `${mockOrigin}/ca/fr/datapage.model.json`,
    AEM_JSON_ES_US: `${mockOrigin}/us/es/datapage.model.json`,
    ROOT_AEM_SERVLET_PATH: `${mockOrigin}/content/wm/servlets`,
  },
  okta: {
    ...envUrls[env].okta,
    redirectUri: origin,
  },
  redirectUrls: {
    home: origin,
    register: `${mockOrigin}__LANGROUTE__/user/register`,
    login: `${mockOrigin}__LANGROUTE__/user/login`,
    customerSupport: `${mockOrigin}__LANGROUTE__/support`,
  },
  cloudfront: {
    ...envUrls[env].cloudfront,
  },
  mockOrigin,
  loginRedirect: `http://localhost:3000__LANGROUTE__`,
  shouldMockQA: MOCK_QA_ENVIRONMENT,
}
