import _ from "lodash"
import * as ActionTypes from "constants-lib/actionTypes"

export const clearFirstTimeUser = () => (dispatch, getState) => {
  const state = getState()
  const eligibleDashboardTour = [`dashboard`]
  const linkedAccounts = _.get(state.userManageAccount, `linkedAccounts`, {})
  const accountDetails = _.get(
    state.userManageAccount,
    `userAccountDetails`,
    {},
  )
  const hasAccountDetails = _.size(linkedAccounts) === _.size(accountDetails)
  const isMulti = _.size(linkedAccounts) > 1
  const accountList = hasAccountDetails ? accountDetails : linkedAccounts
  const lobString = hasAccountDetails ? `lineOfBusiness` : `lob`

  const hasResi = !_.isEmpty(
    _.find(
      accountList,
      (item) => _.upperCase(_.get(item, lobString, ``)) === `RESIDENTIAL`,
    ),
  )

  const hasComm = !_.isEmpty(
    _.find(
      accountList,
      (item) => _.upperCase(_.get(item, lobString, ``)) === `COMMERCIAL`,
    ),
  )

  const hasRoll = !_.isEmpty(
    _.find(
      accountList,
      (item) => _.upperCase(_.get(item, lobString, ``)) === `ROLLOFF`,
    ),
  )
  isMulti && eligibleDashboardTour.push(`selector`)
  hasResi && eligibleDashboardTour.push(`residential`)
  hasComm && eligibleDashboardTour.push(`commercial`)
  hasRoll && eligibleDashboardTour.push(`rolloff`)

  dispatch({
    type: ActionTypes.CLEAR_FIRST_TIME_USER,
    payload: {
      eligibleDashboardTour,
      eligibleTours: {
        residential: hasResi,
        commercial: hasComm,
        rolloff: hasRoll,
        selector: isMulti,
      },
    },
  })
}
