import { SET_SELECTED_SERVICE_OBJECT } from "constants-lib/actionTypes"

export function setSelectedServiceObject(selectedServiceObject) {
  return {
    type: SET_SELECTED_SERVICE_OBJECT,
    payload: {
      selectedServiceObject,
    },
  }
}
