import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.ENROLL_PAPERLESS_BILLING_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.ENROLL_PAPERLESS_BILLING_FAILED,
  payload: data,
})

export const fulfilledAC = (data, isBulkRequest = false) => ({
  type: isBulkRequest
    ? ActionTypes.BULK_ENROLL_PAPERLESS_BILLING_FULFILLED
    : ActionTypes.ENROLL_PAPERLESS_BILLING_FULFILLED,
  payload: data,
})

export const clearEnrollPaperlessStatus = () => ({
  type: ActionTypes.CLEAR_ENROLL_PAPERLESS_STATUS,
})

export const bulkEnrollPaperlessBilling =
  (customerIds) => (dispatch, getState) => {
    const state = getState()
    const api = `BULK_PAPERLESS_BILLING`
    const url = urls.url[api]

    dispatch(pendingAC())

    return getTokenAction(dispatch, getState)
      .then((token) => {
        const config = {
          headers: {
            oktaToken: token.accessToken,
            referrerUrl: `${window.location.origin}${window.location.pathname}`,
          },
          params: {
            userId: _.get(state, `userAccount.userDetails.userId`, ``),
            lang: state.siteLanguage.language,
          },
        }

        const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }

        const payload = {
          paperless: `Y`,
          customerIdList: customerIds,
        }

        return axios
          .create(config)
          .post(url, payload)
          .then((response) => {
            dispatch(fulfilledAC(response.data.data, true))
            return response
          })
      })
      .catch((response) => {
        dispatch(
          failedAC({
            statusCode: _.get(response, `response.status`, ``),
            errorMessage: _.get(response, `response.data.Error`, ``),
          }),
        )
      })
  }

export const enrollPaperlessBilling =
  (customerIdToEnroll = ``) =>
  (dispatch, getState) => {
    const state = getState()
    const api = `PAPERLESS_BILLING`
    dispatch(pendingAC())

    const template = URITemplate(urls.url[api])
    const customerId =
      customerIdToEnroll === ``
        ? _.get(
            state.customerAutoPayPaperless,
            `autoPaymentAndPaperlessAccount`,
            ``,
          )
        : customerIdToEnroll
    const url = template.expand({ customerId })

    return getTokenAction(dispatch, getState)
      .then((token) => {
        const config = {
          headers: {
            oktaToken: token.accessToken,
            referrerUrl: `${window.location.origin}${window.location.pathname}`,
          },
          params: {
            userId: _.get(state, `userAccount.userDetails.userId`, ``),
            lang: state.siteLanguage.language,
          },
        }

        const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        if (apiKey !== ``) {
          config.headers.apiKey = apiKey
        }

        return axios
          .create(config)
          .post(url, {
            paperless: `Y`,
          })
          .then((response) => {
            /* Pass the customer ID that paperless was enrolled for. */
            dispatch(fulfilledAC(customerId))
            return response
          })
      })
      .catch((response) => {
        dispatch(
          failedAC({
            statusCode: _.get(response, `response.status`, ``),
            errorMessage: _.get(response, `response.data.Error`, ``),
          }),
        )
      })
  }

export const clearPaperlessBulkEnrollmentStatusMap = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_PAPERLESS_BULK_ENROLL_STATUS_MAP,
  })
}
