import {
  SET_IS_LID_CHECKED,
  SET_IS_CONTAINER_CHECKED,
  SET_IS_WHEELS_CHECKED,
  SET_NOTES_EXIST,
  RESET_CONTAINER_DAMAGE_DATA,
  SET_CONTAINER_REVIEW_PAGE_OPEN,
} from "constants-lib/actionTypes"

export const setLidChecked = (isLidChecked) => (dispatch) => {
  dispatch({
    type: SET_IS_LID_CHECKED,
    payload: isLidChecked,
  })
}

export const setContainerChecked = (isContainerChecked) => (dispatch) => {
  dispatch({
    type: SET_IS_CONTAINER_CHECKED,
    payload: isContainerChecked,
  })
}

export const setWheelsChecked = (isWheelsChecked) => (dispatch) => {
  dispatch({
    type: SET_IS_WHEELS_CHECKED,
    payload: isWheelsChecked,
  })
}

export const setNotesExist = (notes) => (dispatch) => {
  dispatch({
    type: SET_NOTES_EXIST,
    payload: notes,
  })
}

export const setConatinerReviewPageOpen =
  (isConatinerReviewPage) => (dispatch) => {
    dispatch({
      type: SET_CONTAINER_REVIEW_PAGE_OPEN,
      payload: isConatinerReviewPage,
    })
  }

export const resetConatinerDamageData = () => (dispatch) => {
  dispatch({
    type: RESET_CONTAINER_DAMAGE_DATA,
  })
}
