import get from "lodash/get"
import { makeStyles } from "tss-react/mui"
import PropTypes from "prop-types"
import { updateClicksDL } from "utils-lib/analytics/dataLayer"
import {
  getAdobeLaunchAttribute,
  getAdobeLaunchClassName,
} from "utils-lib/analytics/launch"
import { getWindow } from "utils-lib/getWindow"
import { Button } from "../Button/ButtonContainer"

const window = getWindow()

const useStyles = makeStyles()(() => ({
  ButtonTextIcon: {
    "&.btn": {
      fontSize: 16,
      backgroundColor: "transparent",
      border: 0,

      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        backgroundColor: "rgba(17, 255, 238, 0)",

        "& .ButtonTextIcon-container button:first-child": {
          width: 60,
        },
      },

      "& .svg-container": {
        height: "1rem",

        "& svg": {
          height: "1rem",

          "@supports (-ms-ime-align: auto)": {
            width: "100%",
          },

          "@supports not (-ms-ime-align: auto)": {
            width: "auto",
          },
        },
      },
    },
  },
  loginButtonContainer: {
    border: "1px solid transparent !important",
    "&:focus": {
      border: "1px dashed #1c8200 !important",
    },
  },

  cartSize: {
    "& .svg-container": {
      "& svg": {
        height: "23px !important",
      },
    },
  },
}))

export const ButtonTextIcon = ({
  analytics_key,
  children,
  className,
  fontClass,
  onClick,
  style,
  useDiv,
  isLink,
  href,
  rightChildren,
  disabled,
  shouldNotTrackInComponent,
  elementType,
  testid,
}) => {
  let clickLabel = ``
  if (typeof analytics_key === `string` && analytics_key !== ``) {
    clickLabel = analytics_key
  } else if (typeof children === `string`) {
    clickLabel = children
  } else {
    const child = get(children, `[0]`, ``)
    if (typeof child === `string`) {
      clickLabel = child
    }
  }

  const analyticsClass = getAdobeLaunchClassName({
    disabled: shouldNotTrackInComponent,
  })

  const { classes } = useStyles()

  if (useDiv) {
    return (
      <div
        className={`${classes.ButtonTextIconContainer}`}
        data-testid="ButtonTextIcon-container"
      >
        <div
          className={`${classes.ButtonTextIcon} ${analyticsClass} ${classes.btn} d-flex align-items-center p-0 ${className} ${fontClass}`}
          data-testid={testid}
          style={style}
          onClick={(e) => {
            updateClicksDL({
              ui_element: elementType,
              object_name: ``,
              object_content: clickLabel,
            })

            onClick(e)
          }}
          {...getAdobeLaunchAttribute({
            disabled: shouldNotTrackInComponent,
            value: clickLabel,
          })}
        >
          {children}
          {rightChildren}
        </div>
      </div>
    )
  }

  return (
    <>
      {isLink ? (
        <a
          href={href}
          // eslint-disable-next-line react/no-unknown-property
          analytics_key={
            analytics_key !== ``
              ? analytics_key
              : children && children.toString()
          }
          {...getAdobeLaunchAttribute({
            disabled: shouldNotTrackInComponent,
            value: clickLabel,
          })}
          data-testid={testid}
          className={`${classes.ButtonTextIcon} ${analyticsClass} ${classes.btn} d-flex align-items-center p-0 ${className} ${fontClass}`}
          style={style}
          onKeyDown={(e) => {
            if (e.keyCode === 32) {
              window.location = href
            }
          }}
        >
          {children}
        </a>
      ) : (
        <div
          className={`${classes.ButtonTextIcon}`}
          data-testid="ButtonTextIcon-container"
        >
          <Button
            testid={testid}
            analytics_key={
              analytics_key !== ``
                ? analytics_key
                : children && children.toString()
            }
            overrideClass={`${classes.ButtonTextIcon} ${classes.btn} d-flex align-items-center p-0 ${className} ${fontClass} ${classes.loginButtonContainer}`}
            style={style}
            onClick={onClick}
            type="button"
            elementType="ButtonTextIcon"
            disabled={disabled}
          >
            {children}
            {rightChildren}
          </Button>
        </div>
      )}
    </>
  )
}

ButtonTextIcon.propTypes = {
  fontClass: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  analytics_key: PropTypes.string,
  useDiv: PropTypes.bool,
  isLink: PropTypes.bool,
  href: PropTypes.string,
  rightChildren: PropTypes.node,
  shouldNotTrackInComponent: PropTypes.bool,
  disabled: PropTypes.bool,
  elementType: PropTypes.string,
  testid: PropTypes.string,
}

ButtonTextIcon.defaultProps = {
  analytics_key: ``,
  className: ``,
  fontClass: `maax-font-weight-regular`,
  onClick: () => {},
  useDiv: false,
  isLink: false,
  href: ``,
  rightChildren: null,
  shouldNotTrackInComponent: false,
  disabled: false,
  elementType: `ButtonTextIcon`,
  testid: "ButtonTextIcon",
  style: {},
  children: ``,
}
