import toString from "lodash/toString"
import PropTypes from "prop-types"
import { makeStyles } from "tss-react/mui"
import { Grid, Switch as MUISwitch, Typography, Box } from "@mui/material"
import {
  getAdobeLaunchAttribute,
  getAdobeLaunchClassName,
  updateClicksDL,
} from "utils-lib/analytics"
import "./MyWMSwitch.scss"

const useStyles = makeStyles()((theme, { checked, disabled }) => {
  let rootStyles = {
    "& .MuiSwitch-thumb": {
      color: theme.palette.switch.inactiveThumb,
    },
    "& .MuiSwitch-track": {
      color: theme.palette.switch.inactiveTrack,
      backgroundColor: theme.palette.switch.inactiveTrack,
      opacity: 1,
    },
  }
  if (disabled) {
    rootStyles = {
      "& .MuiSwitch-thumb": {
        color: `${theme.palette.switch.disabledThumb}`,
      },
      "& .MuiSwitch-track": {
        color: `${theme.palette.switch.disabledTrack}`,
        backgroundColor: `${theme.palette.switch.disabledTrack}`,
        opacity: 1,
      },
    }
  }
  if (checked) {
    rootStyles = {
      "& .MuiSwitch-thumb": {
        color: theme.palette.switch.activeThumb,
      },
      "& .MuiSwitch-track": {
        color: theme.palette.switch.activeTrack,
        backgroundColor: theme.palette.switch.activeTrack,
        opacity: 0.6,
      },
    }
  }

  return {
    root: {
      ...rootStyles,
    },
    wrapperClass: {
      padding: `4px 0`,
    },
  }
})

const MyWMSwitch = ({
  id,
  analytics_key,
  leftChoice,
  rightChoice,
  checked,
  disabled,
  onToggle,
  className,
  style,
  hide,
  ariaExpanded,
  elementType,
  shouldNotTrackInComponent,
  isSCA,
  scaValues,
  setSCAValues,
  ...rest
}) => {
  const { classes } = useStyles({ checked, disabled })
  const additionalProps = rest
  if (id !== ``) {
    additionalProps.id = id
  }

  if (ariaExpanded !== undefined) {
    additionalProps[`aria-expanded`] = ariaExpanded
  }

  let clickLabel = ``
  if (typeof analytics_key === `string` && analytics_key !== ``) {
    clickLabel = analytics_key
  } else {
    clickLabel = `Switch Component`
  }

  let switchState = ``
  if (checked && leftChoice) {
    switchState = leftChoice
  } else if (!checked && rightChoice) {
    switchState = rightChoice
  } else {
    switchState = toString(checked)
  }

  const analyticsClass = getAdobeLaunchClassName({
    disabled: shouldNotTrackInComponent,
    isSCA,
  })

  const handleClick = () => {
    if (!shouldNotTrackInComponent) {
      updateClicksDL({
        ui_element: elementType,
        object_content: clickLabel,
      })

      if (isSCA) {
        setSCAValues(scaValues)
      }
    }

    /* Call the onClick handler. */
    onToggle(!checked)
  }

  return (
    <Box data-testid="Switch">
      <Grid
        component="label"
        container
        alignItems="center"
        spacing={1}
        wrap="nowrap"
        className="my-0"
      >
        {leftChoice && (
          <Grid item className="pt-0">
            <Typography>{leftChoice}</Typography>
          </Grid>
        )}
        <Grid container spacing={0}>
          <MUISwitch
            className={classes.root}
            classes={{
              input: analyticsClass,
            }}
            inputProps={{
              ...getAdobeLaunchAttribute({
                disabled: shouldNotTrackInComponent,
                value: `${clickLabel},${switchState}`,
              }),
            }}
            disabled={disabled}
            checked={checked}
            onChange={handleClick}
          />
        </Grid>
        {rightChoice && (
          <Grid item className="pt-0">
            <Typography>{rightChoice}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

MyWMSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  analytics_key: PropTypes.string.isRequired,
  ariaExpanded: PropTypes.bool.isRequired,
  shouldNotTrackInComponent: PropTypes.bool.isRequired,
  isSCA: PropTypes.bool.isRequired,
  scaValues: PropTypes.object.isRequired,
  setSCAValues: PropTypes.func.isRequired,
  leftChoice: PropTypes.string,
  rightChoice: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  hide: PropTypes.bool,
  elementType: PropTypes.string,
}

MyWMSwitch.defaultProps = {
  hide: false,
  className: ``,
  style: {},
  disabled: false,
  elementType: `Switch`,
  leftChoice: "",
  rightChoice: "",
}

export default MyWMSwitch
