export {
  updatePageDL,
  updateClicksDL,
  updateVisitorDL,
  updateCustomerListDL,
  updateCartDL,
  updateDataLayer,
  resetDataLayer,
  getDLVarFromParam,
} from "./dataLayer"
export { getAdobeLaunchClassName, getAdobeLaunchAttribute } from "./launch"
export { getUserValues } from "./sca"
export { fakeAnalyticsClick } from "./fakeAnalyticsClick"
