import * as ActionTypes from "constants-lib/actionTypes"
import URITemplate from "urijs/src/URITemplate"
import { getTokenAction } from "actions"
import axios from "axios"
import _ from "lodash"
import { urls } from "utils-lib/builds"
import { MOCK_CONTAINER_REPAIR_REQUEST } from "utils-lib/mocks"

/** POST CONTAINER REPAIR API */
export const pendingRepairRequestAC = () => ({
  type: ActionTypes.GET_REPAIR_REQUEST_PENDING,
})

export const failedRepairRequestAC = (fetchCode) => ({
  type: ActionTypes.GET_REPAIR_REQUEST_FAILED,
  payload: fetchCode,
})

export const fulfilledRepairRequestAC = (data) => ({
  type: ActionTypes.GET_REPAIR_REQUEST_FULFILLED,
  payload: data,
})

/** GET Dates API */
export const pendingRepairAvailableDatesAC = () => ({
  type: ActionTypes.GET_REPAIR_AVAILABLE_DATES_PENDING,
})

export const failedRepairAvailableDatesAC = (fetchCode) => ({
  type: ActionTypes.GET_REPAIR_AVAILABLE_DATES_FAILED,
  payload: fetchCode,
})

export const fulfilledRepairAvailableDatesAC = (data) => ({
  type: ActionTypes.GET_REPAIR_AVAILABLE_DATES_FULFILLED,
  payload: data,
})

/** POST CONTAINER REPAIR PRICING API */
export const pendingRepairPricingAC = () => ({
  type: ActionTypes.GET_REPAIR_PRICING_PENDING,
})

export const failedRepairPricingAC = (fetchCode) => ({
  type: ActionTypes.GET_REPAIR_PRICING_FAILED,
  payload: fetchCode,
})

export const fulfilledRepairPricingAC = (data) => ({
  type: ActionTypes.GET_REPAIR_PRICING_FULFILLED,
  payload: data,
})

export const getRepairContainerAvailableDates =
  (data, customerId, eligibilityId) => (dispatch, getState) => {
    const api = `REPAIR_CONTAINER_DATES`
    dispatch(pendingRepairAvailableDatesAC())
    const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)

    const template = URITemplate(urls.url[api])
    const url = template.expand({
      lang: data.lang,
      serviceId: data.serviceId,
      customerId,
      userId,
      eligibilityId,
    })

    const config = {
      headers: {},
    }

    return getTokenAction(dispatch, getState).then((token) => {
      config.headers[urls.okta.tokenFieldKey] = token.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

      return getAvailableDatesHelper(url, config, dispatch)
    })
  }

export const getAvailableDatesHelper = (url, config, dispatch) =>
  axios
    .get(url, config)
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      dispatch(
        fulfilledRepairAvailableDatesAC({
          dates: response.data.data,
          statusCode,
        }),
      )

      return response
    })
    .catch((error) => {
      dispatch(
        failedRepairAvailableDatesAC(_.get(error, `response.status`, ``)),
      )

      return error
    })

export const setContainerRepairRequest =
  (data, customerId, lang, isAch = false) =>
  (dispatch, getState) => {
    const api = `REQUEST_REPAIR`
    dispatch(pendingRepairRequestAC())
    // const state = getState()
    const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)
    const convenienceFee = _.get(
      getState(),
      `processingFee.processingFeeDetails.convenienceFee`,
      ``,
    )

    const template = URITemplate(urls.url[api])
    const url = MOCK_CONTAINER_REPAIR_REQUEST
      ? `${window.origin}/mock/container-repair-response.json`
      : template.expand({
          lang,
          // serviceId: data.serviceId,
          customerId,
          userId,
        })

    const config = {
      method: MOCK_CONTAINER_REPAIR_REQUEST ? `GET` : `POST`,
      headers: {},
      data: { ...data, ...(!isAch && { convenienceFee }) },
    }

    return getTokenAction(dispatch, getState).then((token) => {
      config.headers[urls.okta.tokenFieldKey] = token.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

      return setContainerRepairRequestHelper(url, data, config, dispatch)
    })
  }

export const setContainerRepairRequestHelper = (url, data, config, dispatch) =>
  axios(url, config)
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      dispatch(
        fulfilledRepairRequestAC({
          data: response.data,
          statusCode,
        }),
      )

      return response
    })
    .catch((error) => {
      dispatch(failedRepairRequestAC(_.get(error, `response.status`, ``)))

      return error.response
    })

export const setContainerRepairPricing =
  (data, customerId, lang) => (dispatch, getState) => {
    const api = `REPAIR_PRICING`
    dispatch(pendingRepairPricingAC())
    const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)

    const template = URITemplate(urls.url[api])
    const url = template.expand({
      lang,
      serviceId: data.serviceId,
      customerId,
      userId,
    })

    const config = {
      headers: {},
    }

    return getTokenAction(dispatch, getState).then((token) => {
      config.headers[urls.okta.tokenFieldKey] = token.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

      return setContainerRepairPricingHelper(url, data, config, dispatch)
    })
  }

export const setContainerRepairPricingHelper = (url, data, config, dispatch) =>
  axios
    .post(url, data, config)
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      dispatch(
        fulfilledRepairPricingAC({
          data: response.data,
          statusCode,
        }),
      )

      return response
    })
    .catch((error) => {
      dispatch(failedRepairPricingAC(_.get(error, `response.status`, ``)))

      return error
    })
