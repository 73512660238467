import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  REPORT_MISSED_PICKUP_PENDING,
  REPORT_MISSED_PICKUP_FAILED,
  REPORT_MISSED_PICKUP_FULFILLED,
} from "constants-lib/actionTypes"
import * as ActionTypes from "constants-lib/actionTypes"
import { MOCK_MISSED_PICKUP_INFORMATION } from "utils-lib/mocks"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: REPORT_MISSED_PICKUP_PENDING,
})

export const failedAC = () => ({
  type: REPORT_MISSED_PICKUP_FAILED,
})

export const fulfilledAC = (data) => ({
  type: REPORT_MISSED_PICKUP_FULFILLED,
  payload: data,
})

export const submitMissedPickupRequestGuest =
  ({ customerId, payload }) =>
  (dispatch, getState) => {
    const api = `REPORT_MISSED_PICKUP_GUEST`
    const template = URITemplate(urls.url[api])
    const state = getState()
    const url = MOCK_MISSED_PICKUP_INFORMATION
      ? `${window.origin}/mock/missed-pickup-response.json`
      : template.expand({
          customerId,
          lang: _.get(state, `siteLanguage.language`, ``),
        })

    const config = {
      method: MOCK_MISSED_PICKUP_INFORMATION ? `GET` : `POST`,
      headers: {
        token: _.get(
          state,
          `myServices.requestGuestMissedPickupToken.payload`,
          ``,
        ),
        apiKey: _.get(urls, `apiKey.GUEST[${api}]`, ``),
      },
      data: payload,
    }

    return axios(url, config)
      .then((response) => {
        dispatch(fulfilledAC(response.data))
        return response.data
      })
      .catch((error) => {
        dispatch(failedAC())
        return error.response.data
      })
  }

export const setRequestMissedPickupGuestOrderID = (data) => ({
  type: ActionTypes.SET_REQUEST_MISSED_PICKUP_ORDER_ID,
  payload: data,
})

export const setRequestMissedPickupGuestTrackingID = (data) => ({
  type: ActionTypes.SET_REQUEST_MISSED_PICKUP_TRACKING_ID,
  payload: data,
})

export const setRequestMissedPickupGuestCustomerID = (data) => ({
  type: ActionTypes.SET_REQUEST_MISSED_PICKUP_CUSTOMER_ID,
  payload: data,
})

export const setRequestMissedPickupGuestSuccess = (data) => ({
  type: ActionTypes.SET_REQUEST_MISSED_PICKUP_SUCCESS,
  payload: data,
})

export const setRequestMissedPickupGuestError = (data) => ({
  type: ActionTypes.SET_REQUEST_MISSED_PICKUP_ERROR,
  payload: data,
})
