import PropTypes from "prop-types"
import joinClassNames from "utils-lib/joinClassNames"
import "./InfoMessage.scss"

const propTypes = {
  children: PropTypes.any.isRequired,
}

export default function InfoMessage({ children }) {
  return (
    <div
      className={joinClassNames([
        `InfoMessage`,
        children[1] ? children[1] : ``,
      ])}
    >
      {/* children[1] ?<ReactSVG wrapper="span"src={errorExclamatoryOrangeBg} /> : null */}
      <span className="errorMsg maax-font-weight-regular">
        {children.length > 0 ? children[0] : children}
      </span>
    </div>
  )
}

InfoMessage.propTypes = propTypes
