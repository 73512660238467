import axios from "axios"
import _ from "lodash"
import { urls } from "utils-lib/builds"
import URITemplate from "urijs/src/URITemplate"
import {
  GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_PENDING,
  GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_FAILED,
  GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_FULFILLED,
  RESET_GUEST_CONTAINER_REPAIR_TICKET,
} from "constants-lib/actionTypes"
import { getDeviceChannel } from "utils-lib/getDeviceChannel"

export const fulfilledAC = (data) => ({
  type: GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_FULFILLED,
  payload: { data },
})

export const pendingAC = () => ({
  type: GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_PENDING,
})

export const failedAC = (data) => ({
  type: GET_GUEST_CONTAINER_REPAIR_CREATE_TICKET_FAILED,
  payload: data,
})

export const resetContainerRepairTicket = () => ({
  type: RESET_GUEST_CONTAINER_REPAIR_TICKET,
})

export const getGuestContainerRepairCreateTicket =
  ({
    customerId,
    token,
    language,
    serviceId,
    serviceDate,
    serviceDescription,
    serviceRangeEndDate,
    requestType,
    orderId,
    payReconcId,
    contactInfo,
    reason,
    address,
    masServiceCode,
    convenienceFee,
    isAch,
  }) =>
  async (dispatch) => {
    const data = {
      device: getDeviceChannel(),
      serviceId,
      serviceDate,
      serviceDescription,
      serviceRangeEndDate,
      requestType,
      orderId,
      payReconcId,
      contactInfo,
      reason,
      address,
      mas_service_code: masServiceCode,
    }
    const updatedData = { ...data, ...(!isAch && { convenienceFee }) }
    const api = `GUEST_CONTAINER_REPAIR_CREATE_TICKET`
    const baseUrl = urls.url[api]
    dispatch(pendingAC())

    const config = {
      headers: {
        token,
      },
    }

    const template = URITemplate(`${baseUrl}`)
    const url = template.expand({
      customerId,
      language,
    })
    const apiKey = _.get(urls, `apiKey.GUEST[${api}]`, ``)
    if (apiKey !== ``) {
      config.headers.apiKey = apiKey
    }

    try {
      const response = await axios.create(config).post(url, updatedData)
      dispatch(fulfilledAC(response.data.data))
      return response.data
    } catch (error) {
      const errorCode = _.get(error, `response.status`, ``)
      const errorMsg = _.get(error, `response.data.errorMsg`, ``)
      const statusCode = _.get(error, `response.data.statusCode`, ``)
      dispatch(failedAC({ errorCode, errorMsg, statusCode }))
      return error
    }
  }
