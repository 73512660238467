import { createContext } from "react"
import { userType } from "constants-lib/authentication"

/**
 * A React context for authentication.
 * @constant {Object} AuthenticationContext
 * @property {string} userState - The current state of the user.
 * @property {function} logOut - A function to log out the user.
 * @property {boolean} isLoggedIn - A boolean indicating whether the user is logged in or not.
 * @property {Object} userDetails - An object containing details about the user.
 */
export const AuthenticationContext = createContext({
  userState: userType.ANONYMOUS,
  logOut: () => {},
  isLoggedIn: false,
  userDetails: {},
})
