import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import _ from "lodash"
import moment from "moment"
import { urls } from "utils-lib/builds"
import { getTokenAction } from "actions"
import { MOCK_BULK_PICKUP_INFORMATION } from "utils-lib/mocks"
import {
  GET_BULK_PICKUP_DATES_PENDING,
  GET_BULK_PICKUP_DATES_FAILED,
  GET_BULK_PICKUP_DATES_FULFILLED,
} from "constants-lib/actionTypes"

export const pendingAC = () => ({
  type: GET_BULK_PICKUP_DATES_PENDING,
})

export const failedAC = () => ({
  type: GET_BULK_PICKUP_DATES_FAILED,
})

export const fulfilledAC = (data) => ({
  type: GET_BULK_PICKUP_DATES_FULFILLED,
  payload: data,
})

export const getBulkPickupDates =
  ({ customerId, serviceId }) =>
  (dispatch, getState) => {
    dispatch(pendingAC())

    const api = `BULK_PICKUP_DATES_ORIG`
    const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

    const template = URITemplate(urls.url[api])

    const state = getState()

    const url = MOCK_BULK_PICKUP_INFORMATION
      ? `${window.origin}/mock/bulk-pickup-dates-response.json`
      : template.expand({
          fromDate: moment().add(0, `days`).format(`YYYY-MM-DD`),
          toDate: moment().add(60, `days`).format(`YYYY-MM-DD`),
          lang: _.get(state, `siteLanguage.language`, ``),
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
          customerId,
          serviceId,
        })

    return getTokenAction(dispatch, getState).then((token) => {
      const config = {
        headers: {
          apiKey,
          oktaToken: token.accessToken,
        },
      }

      return axios
        .create(config)
        .get(url)
        .then((response) => {
          dispatch(fulfilledAC(response.data))
          return response.data
        })
        .catch(() => {
          dispatch(failedAC())
        })
    })
  }
