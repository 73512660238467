import * as ActionTypes from "constants-lib/actionTypes"
import { API_STATUSES } from "constants-lib/common"

export const initialState = {
  optionsCount: 0,
  formSubmissionState: ``,
  supportMethod: null,
  supportOptionIndex: null,
  step: 0,
  steps: [],
  showCallBackModal: false,
  showContactModal: false,
  submitCallbackRequestStatus: "",
  submitCallbackRequestData: {},
  contactBackAttributesStatus: "",
  contactBackAttributesData: {},
  contactBackCreateCase: "",
  contactBackCreateCaseData: {},
}
export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_SERVICE_REQUEST:
      return initialState

    case ActionTypes.RESET_CUSTOMER_SERVICE_TYPE:
      return initialState

    case ActionTypes.RESET_CUSTOMER_SUPPORT_METHOD:
      return initialState

    case ActionTypes.RESET_CUSTOMER_SUPPORT_OPTION:
      return {
        ...state,
        supportOptionIndex: initialState.supportOptionIndex,
        step: 1,
        formSubmissionState: initialState.formSubmissionState,
      }

    case ActionTypes.SET_CUSTOMER_SUPPORT_METHOD:
      return {
        ...state,
        supportMethod: action.payload,
        step: 1,
        formSubmissionState: initialState.formSubmissionState,
      }

    case ActionTypes.SET_CUSTOMER_SUPPORT_OPTION: {
      return {
        ...state,
        supportOptionIndex: action.payload.option,
        step: action.payload.step,
        steps: action.payload.steps,
      }
    }

    case ActionTypes.SUBMIT_SERVICE_REQUEST_FORM_PENDING:
      return {
        ...state,
        formSubmissionState: API_STATUSES.PENDING,
      }

    case ActionTypes.SUBMIT_SERVICE_REQUEST_FORM_FULFILLED:
      return {
        ...state,
        formSubmissionState: API_STATUSES.FULFILLED,
      }

    case ActionTypes.SET_OPTIONS_COUNT:
      return {
        ...state,
        optionsCount: action.payload,
      }

    case ActionTypes.SET_SUPPORT_FORM:
      return {
        ...state,
        optionsCount: 3,
        step: 3,
        steps: [`other_new_service`, `request_new_service`, `services`],
        supportMethod: `services`,
        supportOptionIndex: `other_new_service`,
      }

    case ActionTypes.SET_SHOW_CALL_BACK_MODAL:
      return {
        ...state,
        showCallBackModal: true,
      }

    case ActionTypes.SET_HIDE_CALL_BACK_MODAL:
      return {
        ...state,
        showCallBackModal: false,
      }
    case ActionTypes.SET_SHOW_CONTACT_MODAL:
      return {
        ...state,
        showContactModal: true,
      }

    case ActionTypes.SET_HIDE_CONTACT_MODAL:
      return {
        ...state,
        showContactModal: false,
      }

    case ActionTypes.SUBMIT_CALL_BACK_REQUEST_PENDING:
      return {
        ...state,
        submitCallbackRequestStatus: API_STATUSES.PENDING,
      }

    case ActionTypes.SUBMIT_CALL_BACK_REQUEST_FULFILLED:
      return {
        ...state,
        submitCallbackRequestStatus: API_STATUSES.FULFILLED,
        submitCallbackRequestData: action.payload,
      }

    case ActionTypes.SUBMIT_CALL_BACK_REQUEST_FAILED:
      return {
        ...state,
        submitCallbackRequestStatus: API_STATUSES.FAILED,
      }

    case ActionTypes.CONTACT_BACK_CREATE_CASE_PENDING:
      return {
        ...state,
        contactBackCreateCaseStatus: API_STATUSES.PENDING,
      }

    case ActionTypes.CONTACT_BACK_CREATE_CASE_FULFILLED:
      return {
        ...state,
        contactBackCreateCaseStatus: API_STATUSES.FULFILLED,
        contactBackCreateCaseData: action.payload,
      }

    case ActionTypes.CONTACT_BACK_CREATE_CASE_FAILED:
      return {
        ...state,
        contactBackCreateCaseStatus: API_STATUSES.FAILED,
      }

    default:
      return state
  }
}
