import PropTypes from "prop-types"
import { ReactSVG } from "react-svg"
import { makeStyles } from "tss-react/mui"
import { AuthoringUtils } from "@adobe/aem-spa-page-model-manager"
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import getContentUrl from "utils-lib/getContentUrl"
import { ButtonPrimary } from "../ButtonPrimary/ButtonPrimary"
import { ButtonSecondary } from "../ButtonSecondary/ButtonSecondary"
import { Link } from "../Link/LinkContainer"

const useStyles = makeStyles()(
  (theme, { isInEditor, useNarrowContainer, useFlexibleWidth }) => ({
    root: {
      zIndex: `1600 !important`,
      margin: `0 auto`,
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
      overflow: `auto`,
      borderRadius: `.25rem`,
      boxShadow: theme.shadows[5],
      backgroundColor: theme.palette.common.white,
      height: `fit-content`,
      maxWidth: `fit-content !important`,
      [theme.breakpoints.down(`sm`)]: {
        width: `calc(100vw - 1rem)`,
        height: `fit-content`,
        maxHeight: `calc(100vh)`,
      },
    },
    innerContainer: {
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
      backgroundColor: theme.palette.background.white,
      margin: `1rem`,
      padding: `1rem`,
      overflow: `visible`,
      maxWidth: `calc(100% - 2rem)`,
      ...(useFlexibleWidth
        ? {
            width: `fit-content !important`,
          }
        : useNarrowContainer
        ? {
            width: `23rem`,
            [theme.breakpoints.down(`sm`)]: {
              width: `auto !important`,
            },
          }
        : {
            width: `40.5rem`,
            [theme.breakpoints.down(`md`)]: {
              padding: `.5rem`,
            },
          }),
      ...(isInEditor ? { borderBottom: `dotted 2px black` } : {}),
    },
    customIconText: {
      margin: `0.7rem 0.5rem 0 0 `,
    },
    titleText: {
      fontSize: `1.5rem`,
      textAlign: `left`,
      color: theme.palette.text.altGray,
      ...theme.typography.fonts.maaxRegular,
      [theme.breakpoints.down(`sm`)]: {
        fontSize: `1.25rem`,
      },
    },
    bodyText: {
      textAlign: `left`,
      color: theme.palette.text.altGray,
    },
    icon: {
      "& > svg": {
        width: `3rem`,
        height: `3rem`,
      },
    },
    close: {
      height: 0,
      padding: 0,
      top: `-.5rem`,
      right: `-1rem`,
      alignSelf: `flex-end`,
    },
    buttonContainer: {
      display: `flex`,
      justifyContent: `center`,
      flexWrap: `wrap`,
      flexGrow: 1,
      marginBottom: `1rem`,
      width: `100%`,
      [theme.breakpoints.down(`md`)]: {
        minHeight: `8rem`,
      },
      [theme.breakpoints.up(`lg`)]: {
        flexWrap: `nowrap`,
      },
    },
    button: {
      marginTop: `1rem`,
      "&.Link": {
        marginTop: 0,
        textDecoration: `underline`,
      },
      ...(useNarrowContainer
        ? {
            width: `100%`,
          }
        : {}),
      [theme.breakpoints.down(`sm`)]: {
        width: `100% !important`,
      },
    },
  }),
)

export const GenericModal = ({
  open,
  onClose,
  showCloseButton,
  closeButtonIcon,
  icon,
  title,
  body,
  children,
  primaryCTA,
  primaryOnClick,
  primaryDisabled,
  secondaryCTA,
  secondaryOnClick,
  secondaryDisabled,
  tertiaryCTA,
  tertiaryOnClick,
  tertiaryDisabled,
  errorMsg,
  errorMsgClassName,
  useNarrowContainer,
  useFlexibleWidth,
  modalTestingId,
  primaryTestingId,
  secondaryTestingId,
  customClass,
  customDialogueClass,
  hideButtons,
  hideErrorMessage,
  errorIcon,
  tertiaryTestingId,
  customButtonContainerClass,
  renderAuthorView,
}) => {
  const alertIconSvg = `/content/dam/wm/icons/red-alert-exclamation-circle.svg`
  const isInEditor = AuthoringUtils.isInEditor()
  const hasButtons = primaryCTA || secondaryCTA

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

  const { classes } = useStyles({
    isInEditor,
    useNarrowContainer,
    useFlexibleWidth,
  })

  const renderContent = () => (
    <Box className={`${classes.innerContainer} ${customClass}`}>
      {showCloseButton && (
        <IconButton
          data-testid="GenericModal-Close-Button"
          disableRipple
          aria-label="close"
          onClick={onClose}
          className={classes.close}
          size="large"
        >
          <ReactSVG wrapper="span" src={getContentUrl(closeButtonIcon)} />
        </IconButton>
      )}
      {icon && (
        <ReactSVG
          wrapper="span"
          className={classes.icon}
          src={getContentUrl(icon)}
        />
      )}
      <Box margin=".5rem 0 0 0">
        <Box className="d-flex">
          {errorIcon && (
            <ReactSVG
              wrapper="span"
              className={classes.customIconText}
              src={getContentUrl(alertIconSvg)}
            />
          )}
          {title && (
            <Typography
              variant="h3"
              component="p"
              gutterBottom
              className={classes.titleText}
            >
              {title}
            </Typography>
          )}
        </Box>
        {body && (
          <Typography
            variant="body1"
            component="p"
            gutterBottom
            className={classes.bodyText}
          >
            {body}
          </Typography>
        )}
        {children}
      </Box>
      {!hideButtons && hasButtons && (
        <Box
          display="flex"
          className={`${classes.buttonContainer} ${customButtonContainerClass}`}
        >
          {secondaryCTA && (
            <ButtonSecondary
              className={`${classes.button} mr-md-3`}
              data-testid={secondaryTestingId}
              onClick={(e) => {
                onClose()
                secondaryOnClick(e)
              }}
              disabled={secondaryDisabled}
            >
              {secondaryCTA}
            </ButtonSecondary>
          )}
          {primaryCTA && (
            <ButtonPrimary
              className={classes.button}
              onClick={(e) => {
                onClose()
                primaryOnClick(e)
              }}
              data-testid={primaryTestingId}
              disabled={primaryDisabled}
            >
              {primaryCTA}
            </ButtonPrimary>
          )}
        </Box>
      )}
      <Box className="row">
        {tertiaryCTA && (
          <Link
            className={classes.button}
            onClick={(e) => {
              tertiaryOnClick(e)
            }}
            data-testid={tertiaryTestingId}
            disabled={tertiaryDisabled}
          >
            {tertiaryCTA}
          </Link>
        )}
        {!hideErrorMessage && errorMsg && (
          <Typography
            className={`${classes.errorMsg} ${errorMsgClassName} py-2 col-md-12 maax-font-weight-bold`}
            style={{
              color: `rgb(255,0,0,1)`,
            }}
          >
            {errorMsg}
          </Typography>
        )}
      </Box>
    </Box>
  )

  return isInEditor ? (
    <Box
      data-testid={modalTestingId}
      className={`GenericModal ${classes.root}`}
    >
      {renderAuthorView() || renderContent()}
    </Box>
  ) : (
    <Dialog
      classes={{
        paper: `GenericModal ${classes.root} ${customDialogueClass}`,
      }}
      fullScreen={fullScreen}
      onClose={onClose}
      open={open}
      // disableBackdropClick // This is deprecated now,  Failed prop type: The prop `disableBackdropClick` of `ForwardRef(Modal)` is deprecated. Use the onClose prop with the `reason` argument to filter the `backdropClick` events.
      disableEscapeKeyDown
      data-testid={modalTestingId}
    >
      {renderContent()}
    </Dialog>
  )
}

GenericModal.propTypes = {
  open: PropTypes.bool,
  errorIcon: PropTypes.bool,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  closeButtonIcon: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.any,
  primaryCTA: PropTypes.string,
  primaryOnClick: PropTypes.func,
  primaryDisabled: PropTypes.bool,
  secondaryCTA: PropTypes.string,
  secondaryOnClick: PropTypes.func,
  secondaryDisabled: PropTypes.bool,
  tertiaryCTA: PropTypes.string,
  tertiaryOnClick: PropTypes.func,
  tertiaryDisabled: PropTypes.bool,
  errorMsg: PropTypes.string,
  errorMsgClassName: PropTypes.string,
  useNarrowContainer: PropTypes.bool,
  modalTestingId: PropTypes.string,
  primaryTestingId: PropTypes.string,
  secondaryTestingId: PropTypes.string,
  tertiaryTestingId: PropTypes.string,
  customClass: PropTypes.string,
  customDialogueClass: PropTypes.string,
  hideButtons: PropTypes.bool,
  hideErrorMessage: PropTypes.bool,
  useFlexibleWidth: PropTypes.bool,
  customButtonContainerClass: PropTypes.object,
  renderAuthorView: PropTypes.func,
}

GenericModal.defaultProps = {
  open: true,
  onClose: () => {},
  showCloseButton: false,
  closeButtonIcon: `/content/dam/mywm/src-img/icon-close.svg`,
  icon: ``,
  title: ``,
  body: ``,
  children: null,
  primaryCTA: ``,
  primaryOnClick: () => {},
  primaryDisabled: false,
  secondaryCTA: ``,
  secondaryOnClick: () => {},
  secondaryDisabled: false,
  tertiaryCTA: ``,
  errorIcon: false,
  tertiaryOnClick: () => {},
  tertiaryDisabled: false,
  errorMsg: ``,
  errorMsgClassName: ``,
  useNarrowContainer: false,
  modalTestingId: ``,
  primaryTestingId: ``,
  secondaryTestingId: ``,
  tertiaryTestingId: ``,
  customClass: ``,
  customDialogueClass: ``,
  hideButtons: false,
  hideErrorMessage: false,
  useFlexibleWidth: false,
  customButtonContainerClass: {},
  renderAuthorView: () => {},
}
