import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  getPaymentDetailsStatus: ``,
  totalCurrent: ``,
  totalCurrentWithCredits: ``,
  invoice: {},
  billingDetails: {},
  currency: ``,
  amountBalanceByAccount: {},
  userAccountBalance: {},
  getUserAccountBalanceStatus: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_LINKED_ACCOUNTS_PENDING:
      return initialState

    case ActionTypes.GET_USER_PAYMENT_FAILED:
    case ActionTypes.GET_SINGLE_USER_PAYMENT_FAILED:
      return {
        ...state,
        getPaymentDetailsStatus: `failed`,
      }

    case ActionTypes.GET_USER_PAYMENT_PENDING:
    case ActionTypes.GET_SINGLE_USER_PAYMENT_PENDING:
      return {
        ...state,
        getPaymentDetailsStatus: `pending`,
      }

    case ActionTypes.GET_USER_PAYMENT_FULFILLED:
    case ActionTypes.GET_SINGLE_USER_PAYMENT_FULFILLED:
      return {
        ...state,
        getPaymentDetailsStatus: `fulfilled`,
        ...action.payload,
      }

    case ActionTypes.GET_USER_ACCOUNT_BALANCE_PENDING:
      return {
        ...state,
        getUserAccountBalanceStatus: `pending`,
        userAccountBalance: {},
      }

    case ActionTypes.GET_USER_ACCOUNT_BALANCE_FAILED:
      return {
        ...state,
        getUserAccountBalanceStatus: `failed`,
        userAccountBalance: {},
      }

    case ActionTypes.GET_USER_ACCOUNT_BALANCE_FULFILLED:
      return {
        ...state,
        getUserAccountBalanceStatus: `fulfilled`,
        amountBalanceByAccount: action.payload.amountBalanceByAccount,
      }

    default:
      return state
  }
}
