import noop from "lodash/noop"
import get from "lodash/get"
import { authClient } from "constants-lib/okta"
import {
  REFRESH_TOKEN_PENDING,
  REFRESH_TOKEN_FULFILLED,
  REFRESH_TOKEN_FAILED,
} from "constants-lib/actionTypes"
import { setToken } from "../setToken"
import { revokeToken } from "../revokeToken"

export const pendingAC = () => ({
  type: REFRESH_TOKEN_PENDING,
})

export const fulfilledAC = (data) => ({
  type: REFRESH_TOKEN_FULFILLED,
  payload: data,
})

export const failedAC = (data) => ({
  type: REFRESH_TOKEN_FAILED,
  payload: data,
})

/**
 * @param [successCb] - a callback function that will be called after the token is refreshed.
 * @returns A function that takes a success callback and returns a function that takes dispatch and
 * getState.
 */
export const refreshToken =
  (successCb = noop) =>
  // eslint-disable-next-line consistent-return
  (dispatch, getState) => {
    dispatch(pendingAC())
    const state = getState()
    const tokenToRefresh = get(state, `userAccount.token`, null)

    if (tokenToRefresh !== null) {
      return authClient.token
        .renew(tokenToRefresh)
        .then((refreshedToken) => {
          // this to help with simulating an error in test env
          if (process.env.NODE_ENV === `test` && tokenToRefresh === `error`) {
            throw new Error()
          }

          dispatch(setToken(refreshedToken))
          dispatch(revokeToken(tokenToRefresh))
          successCb()
          dispatch(fulfilledAC())
          return refreshedToken
        })
        .catch((e) => {
          dispatch(failedAC())
          console.warn(`Error refreshing token.`, e)
        })
    }
    console.warn(`Error refreshing token.`)
  }
