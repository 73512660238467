import { useState, useRef } from "react"
import PropTypes from "prop-types"
import getContentUrl from "utils-lib/getContentUrl"
import LazyLoad from "react-lazyload"
import { AuthoringUtils } from "@adobe/aem-spa-page-model-manager"
import isEmpty from "lodash/isEmpty"
import startsWith from "lodash/startsWith"
import { getSrcSet } from "utils-lib/image"
import * as dims from "constants-lib/dimensions"

const renditionValues = {
  xs: dims.VIEWPORT_SIZE_LABEL_XS,
  sm: dims.VIEWPORT_SIZE_LABEL_SM,
  md: dims.VIEWPORT_SIZE_LABEL_MD,
  lg: dims.VIEWPORT_SIZE_LABEL_LG,
  xl: dims.VIEWPORT_SIZE_LABEL_XL,
}

const hasContentUrl = (src) => startsWith(src, "https://")

// eslint-disable-next-line import/prefer-default-export
export const Picture = ({
  src,
  className,
  pictureClassName,
  style,
  alt,
  disableRendition,
  useAltRendition,
  altRenditionPath,
  lazyLoadHeight,
}) => {
  const [isErrored, setIsErrored] = useState(false)
  const xlRef = useRef(null)
  const lgRef = useRef(null)
  const errorRef = useRef(null)
  const getErrorSrcSetAsync = async () => {
    /* Only enter this block if the image hasn't errored out yet to prevent getting stuck in an infinite error loop */
    if (!isErrored) {
      setIsErrored(true)

      const xlSrc = await getSrcSet(src, renditionValues.lg, disableRendition)
      xlRef.current.srcset = hasContentUrl(xlSrc) ? xlSrc : getContentUrl(xlSrc)

      const lgSrc = await getSrcSet(src, renditionValues.lg, disableRendition)
      lgRef.current.srcSet = hasContentUrl(lgSrc) ? lgSrc : getContentUrl(lgSrc)

      if (!isEmpty(errorRef.current)) {
        errorRef.current.style.display = `block`
      }
    }
  }

  const xlSrcSet = hasContentUrl(src)
    ? src
    : getContentUrl(
        getSrcSet(
          src,
          renditionValues.xl,
          disableRendition,
          useAltRendition,
          altRenditionPath,
        ),
      )

  const lgSrcSet = hasContentUrl(src)
    ? src
    : getContentUrl(
        getSrcSet(
          src,
          renditionValues.lg,
          disableRendition,
          useAltRendition,
          altRenditionPath,
        ),
      )

  const mdSrcSet = hasContentUrl(src)
    ? src
    : getContentUrl(
        getSrcSet(
          src,
          renditionValues.md,
          disableRendition,
          useAltRendition,
          altRenditionPath,
        ),
      )

  const smSrcSet = hasContentUrl(src)
    ? src
    : getContentUrl(
        getSrcSet(src, renditionValues.sm, disableRendition, altRenditionPath),
      )

  const xsSrcSet = hasContentUrl(src)
    ? src
    : getContentUrl(
        getSrcSet(src, renditionValues.xs, disableRendition, altRenditionPath),
      )

  const defaultImageSrc = hasContentUrl(src)
    ? src
    : getContentUrl(getSrcSet(src))

  const renderPicture = () => (
    <picture
      onError={getErrorSrcSetAsync}
      className={pictureClassName}
      data-testid="Picture"
    >
      <source ref={xlRef} media={dims.MEDIA_QUERY_XL_MIN} srcSet={xlSrcSet} />
      <source ref={lgRef} media={dims.MEDIA_QUERY_LG_MIN} srcSet={lgSrcSet} />
      <source media={dims.MEDIA_QUERY_MD_MIN} srcSet={mdSrcSet} />
      <source media={dims.MEDIA_QUERY_SM_MIN} srcSet={smSrcSet} />
      <source media={dims.MEDIA_QUERY_XS_MAX} srcSet={xsSrcSet} />
      <img
        {...(className && { className })}
        alt={alt}
        src={defaultImageSrc}
        style={style}
      />
    </picture>
  )
  const getMessage = () => (
    <div>
      {AuthoringUtils.isInEditor() && useAltRendition && (
        <span
          ref={errorRef}
          className="error-red maax-font-weight-bold text-center"
          style={{ display: `none` }}
        >
          This component uses an alternate rendition path and content was not
          found. Please be sure to upload the proper content.
        </span>
      )}
    </div>
  )
  return src ? (
    AuthoringUtils.isInEditor() || process.env.NODE_ENV === `test` ? (
      <>
        {getMessage()}
        {renderPicture()}
      </>
    ) : (
      <LazyLoad height={lazyLoadHeight} offset={100} once>
        {renderPicture()}
      </LazyLoad>
    )
  ) : (
    ``
  )
}

Picture.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  pictureClassName: PropTypes.string,
  onClick: PropTypes.func,
  alt: PropTypes.string,
  style: PropTypes.object,
  disableRendition: PropTypes.bool,
  useAltRendition: PropTypes.bool,
  altRenditionPath: PropTypes.string,
  lazyLoadHeight: PropTypes.number,
}

Picture.defaultProps = {
  disableRendition: false,
  className: "",
  pictureClassName: ``,
  onClick: () => {},
  alt: "",
  style: {},
  useAltRendition: false,
  altRenditionPath: ``,
  lazyLoadHeight: 200,
}
