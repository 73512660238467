import some from "lodash/some"
import toLower from "lodash/toLower"
import get from "lodash/get"
import { CURRENCIES, PAYMETHOD_TYPE } from "constants-lib/common"
import { PAYMENT_METHOD_VALUES } from "constants-lib/paymentMethods"

/* eslint-disable max-len */

/**
/**
 * Takes in payment information and user information, and returns the selected checking account and bank routing number.
 *
 * @function
 * @param {object} myPaymentCheckout - The payment checkout object.
 * @param {object} userPaymentMethods - The user's saved payment methods.
 * @param {string} selectedPayMethodId - The ID of the selected payment method.
 * @param {string} userType - The type of user (either "CheckoutUser" or "Autopay").
 * @returns {object|boolean} An object with the selected checking account and bank routing number, or false if no account was found.
 * an object with the following properties:
 * isSavedCACheckingAcc: Boolean
 * checkingAccNo: String
 * bankRoutingNo: String
 * @see
 * PADAgreement Eligibility Check:
 * 1. Takes myPaymentCheckout, userPaymentMethods, selectedPayMethodId, userType
 * (Flow Name: CheckoutUser & Autopay).
 *  For checkout logged-in flow.
 * 2. If user has selected canadian checking account and payment is in CAD,
 * returns isSavedCACheckingAcc as true for saved checkings account and new.
 * For Autopay logged-in flow.
 * 3. If user has selected canadian checking account and payment is in CAD,
 * returns isSavedCACheckingAcc as true for saved checkings account only.
 * 4. Returns BankRouting Number and Checking account Number for All Saved
 * Checking Accounts in Checkout and Autopay flow.
 */

export const isNewCanadianAch = (myPaymentCheckout) =>
  get(myPaymentCheckout, `selectedAddPaymentMethod`, ``) ===
    PAYMENT_METHOD_VALUES.DD &&
  get(myPaymentCheckout, `selectedCurrency`, ``) === CURRENCIES.CAD

export const getPadCheckingAcc = (
  myPaymentCheckout,
  userPaymentMethods,
  selectedPayMethodId,
  userType,
) => {
  let selectedCheckingAccountNo = ``
  let selectedBankRoutingNo = ``

  if (userType === `CheckoutUser`) {
    /* Checks if selected saved Payment Method is Canadian and Payment in CAD. */
    const isSavedCACheckingAcc =
      // eslint-disable-next-line consistent-return
      some(userPaymentMethods?.paymentMethods, (payMethod) => {
        if (payMethod.payMethodId === selectedPayMethodId) {
          /* Checks if selected saved Payment Method is Canadian and Payment is in CAD. */
          /* Saved Canadian Checking Account with CAD payment amount */
          if (
            toLower(payMethod.methodSubType) === PAYMETHOD_TYPE.CHEQUING &&
            payMethod.currency === CURRENCIES.CAD &&
            get(myPaymentCheckout, `selectedCurrency`, ``) === CURRENCIES.CAD &&
            get(myPaymentCheckout, `selectedAddPaymentMethod`, ``) ===
              PAYMENT_METHOD_VALUES.SAVED
          ) {
            selectedCheckingAccountNo = payMethod.methodAcctNo
            selectedBankRoutingNo = payMethod.bankRoutingNumber
            return selectedCheckingAccountNo && selectedBankRoutingNo
          }
        }
      }) || isNewCanadianAch(myPaymentCheckout)

    return {
      isSavedCACheckingAcc: !!isSavedCACheckingAcc,
      checkingAccNo: selectedCheckingAccountNo,
      bankRoutingNo: selectedBankRoutingNo,
    }
  }
  if (userType === `Autopay`) {
    // eslint-disable-next-line consistent-return
    const isSavedCACheckingAcc = some(
      userPaymentMethods?.paymentMethods,
      // eslint-disable-next-line consistent-return
      (payMethod) => {
        if (
          payMethod.payMethodId === selectedPayMethodId &&
          payMethod.currency === CURRENCIES.CAD &&
          toLower(payMethod.methodSubType) === PAYMETHOD_TYPE.CHEQUING
        ) {
          selectedCheckingAccountNo = payMethod.methodAcctNo
          selectedBankRoutingNo = payMethod.bankRoutingNumber
          return selectedCheckingAccountNo && selectedBankRoutingNo
        }
      },
    )
    return {
      isSavedCACheckingAcc: !!isSavedCACheckingAcc,
      checkingAccNo: selectedCheckingAccountNo,
      bankRoutingNo: selectedBankRoutingNo,
    }
  }
  return false
}
