import axios from "axios"
import _ from "lodash"
import URITemplate from "urijs/src/URITemplate"
import {
  SUBMIT_UPDATE_EXTRA_PICKUP_PENDING,
  SUBMIT_UPDATE_EXTRA_PICKUP_FAILED,
  SUBMIT_UPDATE_EXTRA_PICKUP_FULFILLED,
} from "constants-lib/actionTypes"
import { getTokenAction, clearDataCache } from "actions"
import { urls } from "utils-lib/builds"
import { CACHE_KEYS } from "constants-lib/dataCache"

export const pendingAC = () => ({
  type: SUBMIT_UPDATE_EXTRA_PICKUP_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: SUBMIT_UPDATE_EXTRA_PICKUP_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: SUBMIT_UPDATE_EXTRA_PICKUP_FULFILLED,
  payload: data,
})

export const submitUpdateExtraPickup =
  (requestParams) => (dispatch, getState) => {
    const api = `UPDATE_EXTRA_PICKUP`
    const template = URITemplate(urls.url[api])
    const state = getState()

    const url = template.expand({
      lang: _.get(state, `siteLanguage.language`, ``),
      userId: _.get(state, `userAccount.userDetails.userId`, ``),
      ...requestParams,
    })
    const { additionalNotes } = requestParams
    const config = {
      method: `PUT`,
      headers: {},
      data: requestParams,
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      return updateExtraPickupHelper(url, config, dispatch, additionalNotes)
    })
  }

export const updateExtraPickupHelper = (
  url,
  config,
  dispatch,
  additionalNotes,
) => {
  dispatch(pendingAC())
  dispatch(clearDataCache(CACHE_KEYS.COMBINED_ORDER_HISTORY_DATA))

  return axios(url, config)
    .then((response) => {
      dispatch(fulfilledAC({ ...response.data, additionalNotes }))

      return response.data
    })
    .catch((e) => {
      dispatch(failedAC(e.response.status))
      return e.response.status
    })
}
