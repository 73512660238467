import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  getHolidayScheduleState: ``,
  holidayData: [],
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.SET_CUSTOMER_ADDRESS:
    case ActionTypes.GET_CUSTOMER_SERVICES_PENDING:
      return {
        ...state,
        holidayData: [],
      }

    case ActionTypes.GET_HOLIDAY_SCHEDULE_FAILED:
      return {
        ...state,
        holidayData: [],
        getHolidayScheduleState: `failed`,
      }

    case ActionTypes.GET_HOLIDAY_SCHEDULE_FULFILLED:
      return {
        ...state,
        holidayData: action.payload,
        getHolidayScheduleState: `fulfilled`,
      }

    case ActionTypes.GET_HOLIDAY_SCHEDULE_PENDING:
      return {
        ...state,
        getHolidayScheduleState: `pending`,
      }
    default:
      return state
  }
}
