import { urls } from "utils-lib/builds"

export const URL_CUSTOMER_SUPPORT_CONTACT = urls.url.customerSupportContact.url
export const URL_CUSTOMER_SUPPORT_LIVE_CHAT =
  urls.cloudfront.customerSupportLiveChat.url

export const TYPE_ALL_HOLIDAYS = "all"
export const TYPE_UPCOMING_HOLIDAYS = "upcoming"
// Facility search URL with search radius option.
// 29.752554/-95.37040089999999?distance=100
// export const URL_FACILITY_SEARCH = urls.cloudfront.facilitySearch.url
// URL for getting facility localized content.
// export const URL_FACILITY_LOCALIZED_CONTENT = urls.cloudfront.facilityLocalizedContent.url

export const URL_NAV_JSON_EN_CA = urls.cloudfront.getStartedNavEn.url
export const URL_NAV_JSON_FR_CA = urls.cloudfront.getStartedNavFr.url
export const URL_NAV_JSON_FRONT_END = `/mock/wm-canada-nav.json`
export const URL_NAV_JSON_MOCK_EN_CA = `/mock/wm-canada-nav_en_CA.json`
export const URL_NAV_JSON_MOCK_FR_CA = `/mock/wm-canada-nav_fr_CA.json`

export const URL_PRODUCT_LIST_JSON_EN_CA = urls.cloudfront.productListEn.url
export const URL_PRODUCT_LIST_JSON_FR_CA = urls.cloudfront.productListFr.url
export const URL_PRODUCT_LIST_JSON_MOCK_EN_CA = `/mock/product-list-wmca_en_CA.json`
export const URL_PRODUCT_LIST_JSON_MOCK_FR_CA = `/mock/product-list-wmca_fr_CA.json`
export const URL_SIGN_UP = `/user/register`
export const URL_SIGN_IN = `/mywm`
