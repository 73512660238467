import { combineReducers } from "redux"
import authentication from "reducers-lib/authentication"
import cloudCraze from "reducers-lib/cloudCraze"
import customerSelections from "reducers-lib/customerSelections"
import alert from "reducers-lib/alert"
import ecommerceCart from "reducers-lib/ecommerceCart"
import holidaySchedule from "reducers-lib/holidaySchedule"
import currentNeighborhood from "reducers-lib/currentNeighborhood"
import customerSupport from "reducers-lib/customerSupport"
import customerSupportContact from "reducers-lib/customerSupportContact"
import myPayment from "reducers-lib/myPayment"
import myPaymentCheckout from "reducers-lib/myPaymentCheckout"
import myPaymentConfirm from "reducers-lib/myPaymentConfirm"
import myServices from "reducers-lib/myServices"
import myServicesMainROStatus from "reducers-lib/myServicesMainROStatus"
import myWM from "reducers-lib/myWM"
import oakland from "reducers-lib/oakland"
import notifications from "reducers-lib/notifications"
import siteLanguage from "reducers-lib/siteLanguage"
import userAccount from "reducers-lib/userAccount"
import userAuthenticationRequestStatuses from "reducers-lib/userAuthenticationRequestStatuses"
import userBilling from "reducers-lib/userBilling"
import userManageAccount from "reducers-lib/userManageAccount"
import onlineProducts from "reducers-lib/onlineProducts"
import customerPrefVerification from "reducers-lib/customerPrefVerification"
import cybersourceSignature from "reducers-lib/cybersourceSignature"
import flags from "reducers-lib/flags"
import localsitesCdn from "reducers-lib/localsitesCdn"
import masData from "reducers-lib/masData"
import siteAnalytics from "reducers-lib/siteAnalytics"
import crossPollination from "reducers-lib/crossPollination"
import communicationPreferences from "reducers-lib/communicationPreferences"
import customerContacts from "reducers-lib/customerContacts"
import aemPageData from "reducers-lib/aemPageData"
import aemData from "reducers-lib/aemData"
import setIsRecycleQuizPage from "reducers-lib/recycleQuiz"
import topSupportTopicData from "reducers-lib/topSupportTopicData"
import facilityLocator from "reducers-lib/facilityLocator"
import manageContacts from "reducers-lib/manageContacts"
import authorTools from "reducers-lib/authorTools"
import feedbackQuestions from "reducers-lib/feedbackQuestions"
import requestContainer from "reducers-lib/requestContainer"
import requestContainerBulk from "reducers-lib/requestContainerBulk"
import paymentusSignature from "reducers-lib/paymentusSignature"
import processingFee from "reducers-lib/processingFee"
import containerRepairDamage from "reducers-lib/containerRepairDamage"

export const makeRootReducer = (asyncReducers) =>
  combineReducers({
    // these are global shared reducers
    aemData,
    feedbackQuestions,
    aemPageData,
    alert,
    authentication,
    cloudCraze,
    communicationPreferences,
    crossPollination,
    currentNeighborhood,
    customerContacts,
    customerPrefVerification,
    customerSelections,
    customerSupport,
    customerSupportContact,
    cybersourceSignature,
    ecommerceCart,
    flags,
    holidaySchedule,
    localsitesCdn,
    masData,
    myPayment,
    myPaymentCheckout,
    myPaymentConfirm,
    myServicesMainROStatus,
    myServices,
    myWM,
    notifications,
    onlineProducts,
    siteAnalytics,
    siteLanguage,
    userAccount,
    userAuthenticationRequestStatuses,
    userBilling,
    userManageAccount,
    setIsRecycleQuizPage,
    topSupportTopicData,
    facilityLocator,
    manageContacts,
    oakland,
    authorTools,
    requestContainer,
    requestContainerBulk,
    paymentusSignature,
    processingFee,
    containerRepairDamage,
    // these are coming from the host application (in the future, we can and should port over the rest of the reducers, this allows for an incremental approach without breaking anything)
    ...asyncReducers,
  })

export default makeRootReducer
