/**
 * Transforms the keys of an input object according to a provided transformation function.
 * @param {Object} inObj - The object whose keys are to be transformed.
 * @param {Function} keyTransformFunc - A function that takes a string (the key) and returns a transformed string.
 * @returns {Object} A new object with the same values as the input object, but with keys transformed by `keyTransformFunc`.
 * @example
 * // Transforms keys from snake_case to camelCase
 * const snakeToCamel = s => s.replace(/(_\w)/g, k => k[1].toUpperCase());
 * const newObj = genericPropertyMapper(oldObj, snakeToCamel);
 * // It can be used to build specific mappers like `mapFromSnake`, which turns `prop_name` or `prop-name` into `propName`.
 * `Note: This function cannot handle objects with cyclic references.`
 */
// eslint-disable-next-line import/prefer-default-export
export const genericPropertyMapper = (inObj, keyTransformFunc) => {
  if (inObj === undefined || inObj === null) {
    return inObj
  }

  if (Array.isArray(inObj)) {
    return inObj.map((u) => genericPropertyMapper(u, keyTransformFunc))
  }

  if (typeof inObj === `object`) {
    return Object.keys(inObj).reduce((accum, key) => {
      let value = inObj[key]

      if (!(value === undefined || value === null)) {
        if (Array.isArray(value)) {
          value = value.map((u) => genericPropertyMapper(u, keyTransformFunc))
        } else if (typeof value === `object`) {
          value = genericPropertyMapper(value, keyTransformFunc)
        }
      }

      return {
        ...accum,
        [keyTransformFunc(key)]: value,
      }
    }, {})
  }

  return inObj
}
