import get from "lodash/get"
import * as ActionTypes from "constants-lib/actionTypes"

// Must be a pure function that requires the state
// and the action being dispatched.

const initialState = {
  error: null,
  fetched: false,
  fetching: false,
  hasLoadedLocations: false,
  hasGeosuggestion: true,
  localUrl: ``,
  mapLocations: [],
  hoveredMapMarkerId: null,
  searchText: ``,
  selectedCustomerAction: `drop_off_waste`,
  selectedGeosuggestion: null,
  selectedFacilityId: null,
  listItemSelected: false,
  translatedFacility: null,
  alerts: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_LOCATIONS_PENDING: {
      return {
        ...state,
        fetching: true,
        hasLoadedLocations: false,
      }
    }

    case ActionTypes.GET_FACILITY_ALERTS_FULFILLED: {
      return {
        ...state,
        alerts: action.payload,
      }
    }

    case ActionTypes.GET_LOCATIONS_FULFILLED: {
      const { localizedContent, facilityLocations } = action.payload

      /* Localized content check. */
      const urls = get(localizedContent, `features`, []).filter(
        (u) => u.properties.LocalizeUrl !== ``,
      )
      let url = ``
      if (urls.length > 0) {
        url = urls[0].properties.LocalizeUrl
        if (url[0] === `/`) {
          url = `http://www.wmcanada.com${url}`
        }
      }

      return {
        ...state,
        // Localized content field:
        localUrl: url,
        // Facility search fields:
        selectedGeosuggestion: facilityLocations.features
          ? state.selectedGeosuggestion
          : null,
        fetching: false,
        fetched: true,
        hasLoadedLocations: true,
        mapLocations: facilityLocations.features,
        selectedFacilityId: null,
        hoveredMapMarkerId: null,
        listItemSelected: false,
      }
    }

    case ActionTypes.GET_LOCATIONS_REJECTED:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      }

    case ActionTypes.SET_HAS_GEOSUGGESTION:
      return {
        ...state,
        hasGeosuggestion: action.payload,
      }

    case ActionTypes.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      }

    case ActionTypes.SET_CUSTOMER_ACTION:
      return {
        ...state,
        selectedFacilityId: null,
        selectedCustomerAction: action.payload,
      }

    case ActionTypes.CLEAR_HOVERED_MAP_MARKER_ID:
      return {
        ...state,
        hoveredMapMarkerId: null,
      }

    case ActionTypes.SET_HOVERED_MAP_MARKER_ID:
      return {
        ...state,
        hoveredMapMarkerId: action.payload,
      }

    case ActionTypes.CLEAR_SELECTED_FACILITY_ID:
      return {
        ...state,
        hoveredMapMarkerId: null,
        selectedFacilityId: null,
        listItemSelected: false,
      }

    case ActionTypes.SELECT_FACILITY:
      return {
        ...state,
        hoveredMapMarkerId: action.payload.hoveredMapMarkerId,
        selectedFacilityId: action.payload.selectedFacilityId,
        listItemSelected: action.payload.listItemSelected,
      }

    case ActionTypes.UPDATE_GEOSUGGEST_LOCATION:
      return {
        ...state,
        selectedGeosuggestion: action.payload,
      }

    case ActionTypes.TRANSLATE_FACILITY:
      return {
        ...state,
        translatedFacility: action.payload,
      }

    default:
      return state
  }
}
