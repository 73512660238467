import uniqBy from "lodash/uniqBy"
import toLower from "lodash/toLower"
import isEqual from "lodash/isEqual"
import filter from "lodash/filter"
import join from "lodash/join"
import assign from "lodash/assign"
import each from "lodash/each"
import get from "lodash/get"

export const blankState = {
  // customer specific
  customerId: ``, // current active customer id
  accountType: ``, // SMB, OMR, TRO
  isSMBCust: false,
  isSMBCustActive: false,
  isOMRCust: false,
  isOMRCustActive: false,
  isTROCust: false,
  isTROCustActive: false,
  customerWasteType: ``, // ``, `trash`, `recycle`, `both`
  scaFlowType: ``, // single, multi
  // account wide
  accountTypeCount: {}, // count based on all linked customer ids
  accountTypeObject: {},
}

/**
 * Returns an object with account type and its corresponding properties based on the selected account.
 *
 * @param {Object} selectedAccount - The selected account object.
 * @param {string} selectedAccount.lineOfBusiness - The line of business of the selected account.
 * @param {string} [selectedAccount.status=''] - The status of the selected account. Defaults to an empty string.
 * @returns {Object} An object with account type and its corresponding properties.
 */
export const getAccountType = (selectedAccount) => {
  const accountTypeObject = {}
  const accountType = get(selectedAccount, `lineOfBusiness`)
  const accountStatus = get(selectedAccount, `status`, ``) === `ACTIVE`

  if (accountType === `RESIDENTIAL`) {
    accountTypeObject.isOMRCust = true
    accountTypeObject.isOMRCustActive = accountStatus
  } else if (accountType === `COMMERCIAL`) {
    accountTypeObject.isSMBCust = true
    accountTypeObject.isSMBCustActive = accountStatus
  } else if (accountType === `ROLLOFF`) {
    accountTypeObject.isTROCust = true
    accountTypeObject.isTROCustActive = accountStatus
  }
  return {
    accountType,
    ...accountTypeObject,
  }
}

/**
 * Returns an object containing the count of different account types for a given customer ID and account details.
 *
 * @param {Object} options - The options object.
 * @param {string} options.customerId - The customer ID.
 * @param {Array} options.accountDetails - The account details array.
 * @returns {Object} - An object containing the selected account, account type object, account type count, and linked line of businesses.
 */
const getAccountTypeCount = ({ customerId, accountDetails }) => {
  let OMR = 0 // OMR = RESIDENTIAL
  let SMB = 0 // SMB = COMMERCIAL
  let TRO = 0 // TRO = ROLLOFF
  const linkedLOBs = []
  let selectedAccount = {}
  const accountTypeObject = {
    OMR: [],
    SMB: [],
    TRO: [],
  }
  each(accountDetails, (account) => {
    const accountType = get(account, `lineOfBusiness`, ``)
    if (get(account, `status`, ``) === `ACTIVE`) {
      if (accountType === `RESIDENTIAL`) {
        accountTypeObject.OMR.push(account)
        OMR += 1
      } else if (accountType === `COMMERCIAL`) {
        accountTypeObject.SMB.push(account)
        SMB += 1
      } else if (accountType === `ROLLOFF`) {
        accountTypeObject.TRO.push(account)
        TRO += 1
      }
    }

    if (get(account, `custAccountId`, ``) === customerId) {
      selectedAccount = assign({}, getAccountType(account))
    }
  })

  if (OMR > 0) {
    linkedLOBs.push(`OMR`)
  }
  if (SMB > 0) {
    linkedLOBs.push(`SMB`)
  }
  if (TRO > 0) {
    linkedLOBs.push(`TRO`)
  }

  return {
    ...selectedAccount,
    accountTypeObject,
    accountTypeCount: {
      OMR,
      SMB,
      TRO,
    },
    linkedLOBs: join(linkedLOBs, `/`),
  }
}

/**
 * Returns the waste stream type based on the customer ID and services provided.
 *
 * @param {Object} options - The options object.
 * @param {string} options.customerId - The customer ID.
 * @param {Array} options.services - The services provided.
 * @returns {Object} - The waste stream type object.
 */
export const getWasteStreamTypeFromServices = ({ customerId, services }) => {
  const wasteStreamType = {
    wasteType: [],
    flowType: `single`,
  }

  const servicesById = filter(services, (service) =>
    isEqual(get(service, `ezpayId`, ``), customerId),
  )

  each(servicesById, (serviceById) => {
    const serviceList = get(serviceById, `services`, ``)
    if (serviceList) {
      each(serviceList, (service) => {
        const serviceDescription = toLower(service.serviceDescription)
        if (serviceDescription) {
          if (serviceDescription.indexOf(`recycl`) > -1) {
            wasteStreamType.wasteType.push(`recycling`)
          } else if (serviceDescription.indexOf(`msw`) > -1) {
            wasteStreamType.wasteType.push(`trash`)
          } else {
            wasteStreamType.wasteType.push(`other`)
          }
        }
      })
      if (serviceList.length > 1) {
        wasteStreamType.flowType = `multi`
      }
    }
  })

  return wasteStreamType
}

/**
 * Returns the waste stream type based on the eligibility data and service details.
 * @param {Object} options - The options object.
 * @param {string} options.customerId - The customer ID.
 * @param {Object} options.myServices - The customer's services data.
 * @returns {Object} - The waste stream type and flow type.
 */
export const getWasteStreamTypeFromEligibiility = ({
  customerId,
  myServices,
}) => {
  let wasteStreamType = {
    wasteType: [],
    flowType: `single`,
  }

  const serviceChangeEligibilityData = get(
    myServices,
    `serviceChangeEligibilityData`,
    ``,
  )

  const eligibilityData = get(serviceChangeEligibilityData, `data`, ``)
  const serviceDetails = get(eligibilityData, `serviceDetails`, ``)

  const custIdMatchesEligibility =
    customerId === get(eligibilityData, `customerId`, ``)

  if (custIdMatchesEligibility && serviceDetails) {
    each(serviceDetails, (service) => {
      const wasteStream = get(service, `wasteStream`, ``)
      if (wasteStream === `MSW`) {
        wasteStreamType.wasteType.push(`trash`)
      } else if (wasteStream === `RECYCLABLE`) {
        wasteStreamType.wasteType.push(`recycling`)
      } else {
        wasteStreamType.wasteType.push(`other`)
      }
    })
    if (
      serviceDetails.length > 1 ||
      (get(eligibilityData, `isEligible`, ``) === `Y` &&
        get(eligibilityData, `eligibleForRecommendation`, ``) === `N`)
    ) {
      wasteStreamType.flowType = `multi`
    }
  } else {
    wasteStreamType = getWasteStreamTypeFromServices({
      customerId,
      services: get(myServices, `services`, ``),
    })
  }

  return {
    customerWasteType: join(uniqBy(wasteStreamType.wasteType), `/`),
    scaFlowType: wasteStreamType.flowType,
  }
}

/**
 * Gets user values based on the provided parameters.
 * @function
 * @param {Object} params - An object containing the following properties:
 * @param {string} params.customerId - The customer ID.
 * @param {boolean} [params.isBrokerAccount=false] - A flag indicating whether the account is a broker account.
 * @param {Object} params.userManageAccount - The user manage account object.
 * @param {Object} params.userBilling - The user billing object.
 * @param {Object} params.myServices - The my services object.
 * @returns {Object} An object containing the user values.
 */
export const getUserValues = ({
  customerId,
  isBrokerAccount = false,
  userManageAccount,
  userBilling,
  myServices,
}) => {
  const accountDetails = isBrokerAccount
    ? get(userBilling, `brokerAccountBalancesAndDetails`, [])
    : get(userManageAccount, `userAccountDetails`, [])

  const parsedDetails =
    accountDetails.length > 1
      ? getAccountTypeCount({ customerId, accountDetails })
      : getAccountType(get(accountDetails, `[0]`, []))

  if (customerId) {
    const customerWasteType = getWasteStreamTypeFromEligibiility({
      customerId,
      myServices,
    })

    return assign(
      {},
      blankState,
      { customerId },
      parsedDetails,
      customerWasteType,
    )
  }

  return assign({}, parsedDetails)
}
