import { getWindow } from "utils-lib/getWindow"
import { LINE_OF_BUSINESS } from "./common"

const window = getWindow()

export const ERROR_STATUSES = {
  CUSTOMER_NOT_ACTIVE: `CUSTOMERNOTACTIVE`,
  CUSTOMER_NOT_COMMERCIAL: `CUSTOMERNOTCOMMERCIAL`,
  NOT_A_DUMPSTER: `NOTADUMPSTER`,
  MAX_CONTAINER_EXCEEDED: `MAXCONTAINEREXCEEDED`,
  SERVICE_COUNT_EXCEEDED: `SERVICECOUNTEXCEEDED`,
  CUSTOMER_NOT_OPEN_MARKET: `CUSTOMERNOTOPENMARKET`,
  INVALID_FREQUENCY: `INVALIDFREQUENCY`,
  TOO_MANY_REQUESTS_IN_2_DAYS: `TOOMANYREQUESTINTWODAYS`,
  TOO_MANY_REQUESTS_IN_2_MONTHS: `TOOMANYREQUESTIN2MONTHS`,
  BAD_PAY_CUSTOMER: `BADPAYCUSTOMER`,
}
export const ERROR_CONTENT_MAP = {
  INELIGIBLE: `ineligible`,
  [ERROR_STATUSES.MAX_CONTAINER_EXCEEDED]: `largeNumberOfContainers`,
  [ERROR_STATUSES.SERVICE_COUNT_EXCEEDED]: `largeNumberOfContainers`,
  [ERROR_STATUSES.TOO_MANY_REQUESTS_IN_2_DAYS]: `alreadyInProgress`,
  [ERROR_STATUSES.TOO_MANY_REQUESTS_IN_2_MONTHS]: `maxIn60Days`,
  [ERROR_STATUSES.BAD_PAY_CUSTOMER]: `badPay`,
}

export const IMAGE_BASE_URL = `${window.location.origin}/content/dam/mywm/images/service-change/`
export const FALLBACK_WASTE_IMAGE = `${IMAGE_BASE_URL}MSW_6_FL.jpg`
export const FALLBACK_RECYCLING_IMAGE = `${IMAGE_BASE_URL}RECYCLABLE_6_FL.jpg`
export const FALLBACK_RESIDENTIAL_WASTE_IMAGE = `${IMAGE_BASE_URL}${LINE_OF_BUSINESS.RESIDENTIAL}_MSW_96.jpg`
export const FALLBACK_RESIDENTIAL_RECYCLING_IMAGE = `${IMAGE_BASE_URL}${LINE_OF_BUSINESS.RESIDENTIAL}_RECYCLABLE_96.jpg`

export const ACTIONS = {
  SERVICE_CHANGE: `service-change`,
  ADD_WASTE_STREAM: `add-service`,
  VIEW_RECOMMENDATIONS: `VIEW_RECOMMENDATIONS`,
  REVIEW_ORDER: `REVIEW_ORDER`,
  MANUAL_SELECTION: `MANUAL_SELECTION`,
  EDIT: `EDIT`,
  CONFIRMATION: `CONFIRMATION`,
}

export const NEXT_STEPS = {
  [ACTIONS.REVIEW_ORDER]: `review`,
  [ACTIONS.EDIT]: `edit`,
  [ACTIONS.CONFIRMATION]: `confirmation`,
}

export const WASTE_STREAMS = {
  MSW: `MSW`,
  RECYCLING: `RECYCLING`,
  RECYCLABLE: `RECYCLABLE`,
  SPECIAL_WASTE: `SPECIAL WASTE`,
}

export const CHANGE_TYPES = {
  SERVICE_CHANGE: `SERVICE_CHANGE`,
  NEW_SERVICE: `NEW_SERVICE`,
}
export const CUSTOMER_CATEGORIES = {
  FRANCHISE: `FRAN/MUNI`,
  OPEN_MARKET: `OPEN MARKET`,
}

export const TOGGLE_CODES = {
  PERMANENT_CHANGE: `R`,
  TEMPORARY_CHANGE: `S`,
}

export const MODES = {
  MANUAL: `manual`,
  RECOMMENDATION: `recommendation`,
  EDIT: `edit`,
}

export const MIN_MAX_VALUES = {
  MIN: `MIN`,
  MAX: `MAX`,
}

export const SERVICE_FREQUENCIES = {
  ON_CALL: `On Call`,
  ONE_X_PER_WEEK: `1x Per Week`,
}

export const SERVICE_DAYS = {
  NO_PREFERENCE: `No Preference`,
}

export const strings = {
  en_CA: {
    TEMP_FLOW_DISABLED_MESSAGE: `We are currently not accepting online orders for temporary service changes. For now, please contact us at 1-866-909-4458.`,
  },
  fr_CA: {
    TEMP_FLOW_DISABLED_MESSAGE: `Présentement, nous n’acceptons pas de commande en ligne pour des modifications de service temporaires. Veuillez donc appelez l'équipe de soutien au 1-800-665-1898.`,
  },
}
