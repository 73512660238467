import * as ActionTypes from "constants-lib/actionTypes"

// Must be a pure function that requires the state
// and the action being dispatched.
const initialState = {
  isFirstComponentLoaded: false,
  nextUp: 1 /* Next component in line to load. */,
  lastVisitedLocation: ``,
  isGuidedGuestFlowInitialized: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    // NEVER mutate the state, always return a new state.
    case ActionTypes.SET_FIRST_COMPONENT_LOADED:
      return {
        ...state,
        isFirstComponentLoaded: true,
      }

    case ActionTypes.SET_LAST_VISITED_LOCATION:
      return {
        ...state,
        lastVisitedLocation: action.payload,
      }

    case ActionTypes.RESET_FIRST_COMPONENT_LOADED:
      return initialState
    // return {
    // ...state,
    // isFirstComponentLoaded: false,
    // }

    case ActionTypes.INCREMENT_COMPONENTS_LOADED: {
      const nextUp = state.nextUp + 1
      return {
        ...state,
        nextUp,
      }
    }

    case ActionTypes.SET_GUIDED_GUEST_FLOW_INITIALIZED:
      return {
        ...state,
        isGuidedGuestFlowInitialized: true,
      }

    default:
      return state
  }
}
