import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  displayMethod: 0,
  esbTokens: {},
  orderDetails: [],
  orderSummary: {},
  getEsbTokenState: ``,
  getOrderDetailsState: ``,
  getOrderDetailsStatusCode: 0,
  getOrderSummaryState: ``,
  getOrderSummaryStatusCode: 0,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_ESB_TOKENS_PENDING:
      return {
        ...state,
        getEsbTokenState: `pending`,
      }

    case ActionTypes.GET_ESB_TOKENS_FAILED:
      return {
        ...state,
        getEsbTokenState: `failed`,
      }

    case ActionTypes.GET_ESB_TOKENS_FULFILLED:
      return {
        ...state,
        getEsbTokenState: `fulfilled`,
        esbTokens: action.payload,
      }

    case ActionTypes.GET_ORDER_DETAILS_PENDING:
      return {
        ...state,
        getOrderDetailsState: `pending`,
        getOrderDetailsStatusCode: 0,
      }

    case ActionTypes.GET_ORDER_DETAILS_FAILED:
      return {
        ...state,
        getOrderDetailsState: `failed`,
        getOrderDetailsStatusCode: action.payload,
      }

    case ActionTypes.GET_ORDER_DETAILS_FULFILLED:
      return {
        ...state,
        getOrderDetailsState: `fulfilled`,
        getOrderDetailsStatusCode: 0,
        orderDetails: action.payload,
      }

    case ActionTypes.GET_ORDER_SUMMARY_PENDING:
      return {
        ...state,
        getOrderSummaryState: `pending`,
        getOrderSummaryStatusCode: 0,
      }

    case ActionTypes.GET_ORDER_SUMMARY_FAILED:
      return {
        ...state,
        getOrderSummaryState: `failed`,
        getOrderSummaryStatusCode: action.payload,
      }

    case ActionTypes.GET_ORDER_SUMMARY_FULFILLED:
      return {
        ...state,
        getOrderSummaryState: `fulfilled`,
        getOrderSummaryStatusCode: action.payload.statusCode,
        orderSummary: action.payload.data,
      }

    case ActionTypes.RESET_USER_ACCOUNT:
      return {
        ...initialState,
      }

    case ActionTypes.SET_ORDER_DISPLAY_METHOD:
      return {
        ...state,
        displayMethod: action.payload,
      }

    case ActionTypes.CLEAR_CLOUD_CRAZE_ORDER_HISTORY:
      return {
        ...state,
        getOrderDetailsState: ``,
        getOrderDetailsStatusCode: 0,
        getOrderSummaryState: ``,
        getOrderSummaryStatusCode: 0,
      }
    default:
      return state
  }
}
