import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import get from "lodash/get"
import includes from "lodash/includes"
import { getTokenAction } from "actions-lib/getToken"
import { urls } from "utils-lib/builds"
import dayjs from "utils-lib/date"
import { CURRENCIES } from "constants-lib/common"
import { getCurrency } from "utils-lib/getCurrency/getCurrency"
import { isUSCanadaAccount } from "utils-lib/isUSCanadaAccount"
import { isBrokerAccountSelector } from "selectors-lib/linkedAccounts"

export const pendingSignature = () => ({
  type: ActionTypes.GET_PAYMENTUS_SIGNATURE_PENDING,
})

export const failedSignature = (data) => ({
  type: ActionTypes.GET_PAYMENTUS_SIGNATURE_FAILED,
  payload: data,
})

export const fulfilledSignature = (data) => ({
  type: ActionTypes.GET_PAYMENTUS_SIGNATURE_FULFILLED,
  payload: data,
})

/**
 * Fetches a signature from Paymentus and dispatches actions based on the response.
 * @param {Object} config - Axios configuration object for the request.
 * @param {string} url - The URL to which the post request is sent.
 * @param {Object} data - The payload for the post request.
 */
const fetchPaymentusSignatureHelper = (config, url, data, dispatch) =>
  axios
    .create(config)
    .post(url, data)
    .then((response) => {
      dispatch(fulfilledSignature(response.data.data))
    })
    .catch((error) => {
      const statusCode = get(error, `response.status`, `failed`)
      const errorMessage = get(error, `response.data.Error`, ``)
      dispatch(
        failedSignature({
          statusCode,
          errorMessage,
        }),
      )
    })

/**
 * Retrieves a signature from Paymentus for payment processing, tailored for both logged-in and guest users.
 * @param {Object} params - Parameters for fetching the Paymentus signature.
 * @param {Object} params.compData - Data to be included in the signature request.
 * @param {boolean} [params.isLoggedIn=false] - Indicates user's login status.
 * @returns {Function} Dispatches actions based on the result of the signature fetch.
 */
export const getPaymentusSignature =
  ({ compData, isLoggedIn = false }) =>
  (dispatch, getState) => {
    dispatch(pendingSignature())
    const state = getState()
    const api = `PAYMENTUS_SIGNATURE`
    const url = urls.url[api]

    const config = {
      headers: {},
    }
    const isMultiCountry = isUSCanadaAccount(state.userManageAccount)
    const isBrokerAccount = isBrokerAccountSelector(state)
    const myPaymentCheckout = state.myPaymentCheckout
    const myPayment = state.myPayment
    const userManageAccount = state.userManageAccount
    const routePaths = window?.location?.pathname?.split(`/`)
    const isRoPage = includes(routePaths, `rolloff-request`)
    const currency = isRoPage
      ? get(
          state,
          `myServicesMainROForm.roPricingData.pricingDetails.currency`,
          "",
        )
      : getCurrency({
          isMultiCountry,
          isBrokerAccount,
          myPaymentCheckout,
          myPayment,
          userManageAccount,
        })
    const data = {
      locale: get(state, `siteLanguage.language`, `en_CA`).replace(`_`, `-`),
      signedDateTime: dayjs.utc().format(),
      currency,
      isCanadaAccount: currency === CURRENCIES.CAD,
      ...compData,
    }
    if (isLoggedIn) {
      return getTokenAction(dispatch, getState).then((token) => {
        config.headers.token = token.accessToken
        config.params = {
          userId: get(state, `userAccount.userDetails.userId`, ``),
        }
        config.headers.apiKey = get(urls, `apiKey.USER[${api}]`, ``)
        return fetchPaymentusSignatureHelper(config, url, data, dispatch)
      })
    }

    config.headers.token =
      get(state, `myPaymentVerification.verificationToken`, ``) ||
      get(state, `guestContainerRepair.eligibilityData.token`) ||
      get(state, `customerSelections.cityBilledEligibility.token`, ``)
    config.headers.apiKey = get(urls, `apiKey.GUEST[${api}]`, ``)
    data.customerId =
      get(state, `myPaymentVerification.verificationData.customerId`, ``) ||
      get(state, `myPaymentVerification.verificationDetails.customerId`, ``) ||
      get(state, `guestContainerRepair.eligibilityData.customerId`, ``) ||
      get(state, `customerSelections.cityBilledEligibility.customerId`, ``)
    return fetchPaymentusSignatureHelper(config, url, data, dispatch)
  }
