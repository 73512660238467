/**
 * Validates an email address using a regular expression.
 *
 * @function
 * @param {string} email - The email address to validate.
 * @returns {boolean} A boolean value indicating whether the specified email address is valid.
 */
export const validateLoginEmail = (email) =>
  // Email cannot be undefined
  email !== undefined &&
  // Email cannot be null
  email !== null &&
  // Email must be of type string
  typeof email === `string` &&
  email.match(/^[\w-]+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/) !== null
