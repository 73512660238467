import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import { getTokenAction } from "actions"
import _ from "lodash"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.GET_ROLLOFF_TICKETS_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: ActionTypes.GET_ROLLOFF_TICKETS_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_ROLLOFF_TICKETS_FULFILLED,
  payload: data,
})

export const getRollOffTickets =
  ({ customerId, serviceId, locale, requireLogin = false }) =>
  (dispatch, getState) => {
    const api = `ROLLOFF_REQUESTS`
    const template = URITemplate(urls.url[api])
    let url = template.expand({
      customerId,
      serviceId,
      locale,
    })

    const config = {
      headers: {},
    }

    if (requireLogin) {
      return getTokenAction(dispatch, getState).then((token) => {
        const userId = _.get(getState(), `userAccount.userDetails.userId`, ``)
        url += `&userId=${userId}`
        config.headers[urls.okta.tokenFieldKey] = token.accessToken
        config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
        return getRollOffTicketsHelper(url, config, dispatch)
      })
    }

    config.headers.apiKey = _.get(urls, `apiKey.GUEST[${api}]`, ``)

    return getRollOffTicketsHelper(url, config, dispatch)
  }

export const getRollOffTicketsHelper = (url, config, dispatch) => {
  dispatch(pendingAC())

  // console.warn(`getRollOffTicketsHelper config`, url, config)

  // const mockURL = locale === `en_CA` ? `/mock/ro-status-en_CA.json` : `/mock/ro-status-fr_CA.json`
  return axios
    .get(url, config)
    .then((response) => {
      const statusCode = _.get(response, `status`, ``)
      dispatch(
        fulfilledAC({
          tickets: response.data,
          statusCode,
        }),
      )
      return response
    })
    .catch((error) => {
      dispatch(failedAC(_.get(error, `response.status`, ``)))
    })
}
