import * as ActionTypes from "constants-lib/actionTypes"

// Must be a pure function that requires the state
// and the action being dispatched.
const initialState = {
  lobDropdown: false,
  serviceTypeDropdown: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    // NEVER mutate the state, always return a new state.
    case ActionTypes.SHOW_NATURAL_LANGUAGE_DROPDOWN: {
      // Get the dropdown specified by the action payload.
      const targetDropdownKey = action.payload
      // Create a new state to be returned.
      const newState = {
        ...initialState,
      }
      newState[targetDropdownKey] = true
      // Return the new state.
      return newState
    }

    case ActionTypes.HIDE_NATURAL_LANGUAGE_DROPDOWN: {
      // Get the dropdown specified by the action payload.
      const targetDropdownKey = action.payload
      // Return the initial state with all dropdowns hidden.
      const newState = {
        ...state,
      }
      newState[targetDropdownKey] = false
      return newState
    }

    default:
      return state
  }
}
