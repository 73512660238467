import * as ActionTypes from "constants-lib/actionTypes"
import { INVALIDATE_LANGUAGE } from "constants-lib/actionTypes"

// Must be a pure function that requires the state
// and the action being dispatched.
const initialState = {
  fetchState: ``,
}

export default (state = initialState, action) => {
  switch (action.type) {
    // NEVER mutate the state, always return a new state.
    case ActionTypes.GET_PRODUCT_LIST_JSON_PENDING:
      return {
        fetchState: `pending`,
      }

    case ActionTypes.GET_PRODUCT_LIST_JSON_FULFILLED:
      return {
        fetchState: `fulfilled`,
        ...action.payload,
      }

    case INVALIDATE_LANGUAGE:
      return initialState

    default:
      return state
  }
}
