import get from "lodash/get"
import cpv from "./environments/cpv"
import dev from "./environments/dev"
import dev1 from "./environments/dev1"
import dev3 from "./environments/dev3"
import local from "./environments/local"
import prod from "./environments/prod"
import qa from "./environments/qa2"
import qa1 from "./environments/qa1"
import qa3 from "./environments/qa3"
import uat from "./environments/uat"

// import qa from './qa'
// import dev from './dev'

const environments = {
  cpv,
  dev1,
  dev3,
  dev,
  local,
  qa,
  qa1,
  qa3,
  uat,
  prod,
}

/**
 * If an unknown environment was passed  Default to qa2 environment variables
 * @param environment - The environment you want to get the variables for.
 */
export const getEnvVars = (environment) =>
  get(environments, environment, environments.qa)
