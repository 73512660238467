import getDomainName from "utils-lib/tealium/getDomainName"

// constants
import * as ActionTypes from "constants-lib/actionTypes"

export const setLastWidgetViewed = (
  currentPageName,
  country,
  language,
  widgetName,
) => ({
  type: ActionTypes.SET_LAST_WIDGET_VIEWED,
  payload: `${country}:${language}:${getDomainName()}:${currentPageName}:${widgetName}`,
})

export default setLastWidgetViewed
