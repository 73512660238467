import * as ActionTypes from "constants-lib/actionTypes"
import { userType } from "constants-lib/authentication"

export const initialState = {
  verificationState: ``,
  verificationData: {},
  verificationDetails: {},
  verificationToken: ``,
  errorMessage: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_MY_WM_PAYMENT_VERIFICATION_PENDING:
      // make sure not to clear out SMS ID
      const pendingIsSms = action.payload.isSMSFlow

      let pendingDetails = {}
      if (pendingIsSms) {
        pendingDetails = action.payload
        pendingDetails.id =
          action.payload.id ||
          state.verificationData?.id ||
          state.verificationDetails?.id
      }

      return {
        ...state,
        verificationState: `pending`,
        verificationData: action.payload,
        verificationDetails: pendingDetails,
        verificationToken: ``,
        errorMessage: ``,
      }

    case ActionTypes.GET_MY_WM_PAYMENT_VERIFICATION_FULFILLED:
      // make sure not to clear out SMS ID
      const fulfilledIsSms = action.payload.isSMSFlow
      const fulfilledDetails = action.payload.verificationDetails

      if (fulfilledIsSms) {
        fulfilledDetails.id = state.verificationDetails?.id
      }

      return {
        ...state,
        verificationState: `fulfilled`,
        verificationDetails: fulfilledDetails,
        verificationToken: action.payload.verificationToken,
      }

    case ActionTypes.GET_MY_WM_PAYMENT_VERIFICATION_FAILED:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        verificationDetails: action.payload.verificationDetails,
        verificationState: `failed`,
      }

    case ActionTypes.RESET_MY_WM_PAYMENT_VERIFICATION:
      return {
        ...state,
        verificationState: ``,
        verificationDetails: {},
        verificationToken: ``,
        verificationData: {},
        errorMessage: ``,
      }

    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
      return {
        ...initialState,
      }

    case ActionTypes.SET_USER_STATE: {
      /* Clear state after logging in. */
      if (action.payload === userType.LOGGED_IN) {
        return {
          ...initialState,
        }
      }
      return {
        ...state,
      }
    }

    default:
      return state
  }
}
