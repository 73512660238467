import { SET_CONTAINER_BULK } from "constants-lib/actionTypes"

const initialState = {
  onListContainerBulk: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTAINER_BULK:
      return {
        onListContainerBulk: action.payload,
      }

    default:
      return state
  }
}
