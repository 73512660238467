import * as ActionTypes from "constants-lib/actionTypes"

// Must be a pure function that requires the state
// and the action being dispatched.
/*
{
 "facilityId": 2881,
 "zip": "77002",
 "contactPref": [
   {
     "contactType": "Sales",
     "phone": "1234567890",
     "email": "wmqamail@gmail.com"
   },
   {
     "contactType": "CustomerService",
     "phone": "1234567890",
     "email": "wmqamail@gmail.com"
   }
 ]
}
*/
const initialState = {
  contactSelection: ``,
  phone: {
    fetchState: ``,
    facilityId: ``,
    zip: ``,
    contactPref: [],
  },
  /*
    MonFriHrs:"Mon &minus; Fri 12:01AM to 11:59PM CST"
    SatHrs:"Sat 12:01AM to 11:59PM CST"
    SunHrs:"Sun 12:01AM to 11:59PM CST"
    btnTxt:"<span class="state-one"><h4>Need Help? <span class="bold">Chat Now</span></h4></span>"
    chatStatus:"1"
    fetchState:"fulfilled"
    genesysChat:"Y"
    genesysMA:"NTOA"
    gensysChatUrl:"https://wmchat.wm.com/multimedia/Chat/HtmlChatFrameSet.jsp"
    segment:"COMMERCIAL"
    state:"TX"
    zip:"77002"
  */
  liveChat: {
    chatStatus: `2`, // `0` = not available, `1` = available, `2` = not supported
    fetchState: ``,
  },
}
export default (state = initialState, action) => {
  switch (action.type) {
    // NEVER mutate the state, always return a new state.
    case ActionTypes.GET_CUSTOMER_SUPPORT_CONTACT_FULFILLLED:
      return {
        ...state,
        phone: {
          ...action.payload,
          fetchState: `fulfilled`,
        },
      }

    case ActionTypes.GET_CUSTOMER_SUPPORT_CONTACT_PENDING:
      return {
        ...state,
        phone: {
          fetchState: `pending`,
        },
      }

    case ActionTypes.GET_CUSTOMER_SUPPORT_CONTACT_FAILED:
      return {
        ...state,
        phone: {
          fetchState: `failed`,
        },
      }

    case ActionTypes.CLEAR_CONTACT_SELECTION:
      return { ...initialState, contactSelection: state.contactSelection }

    case ActionTypes.CHANGE_CONTACT_SELECTION:
      return {
        ...state,
        contactSelection: action.payload,
      }

    case ActionTypes.GET_LIVE_CHAT_PENDING:
      return {
        ...state,
        liveChat: {
          fetchState: `pending`,
        },
      }

    case ActionTypes.GET_LIVE_CHAT_FULFILLED:
      return {
        ...state,
        liveChat: {
          ...action.payload,
          fetchState: `fulfilled`,
        },
      }

    case ActionTypes.GET_LIVE_CHAT_FAILED:
      return {
        ...state,
        liveChat: {
          chatStatus: `3`,
          fetchState: `failed`,
        },
      }

    case ActionTypes.SET_MY_WM_CUSTOMER_ID:
    case ActionTypes.GET_CUSTOMER_ACCOUNT_PENDING:
    case ActionTypes.RESET_MYSERVICES_DASHBOARD:
    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
    case ActionTypes.SET_CUSTOMER_ADDRESS: // Set from geosuggest.
      // case ActionTypes.SET_GEOCODE_CUSTOMER_ADDRESS: // Set from getting customer accounts.
      return initialState

    default:
      return state
  }
}
