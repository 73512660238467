import React from "react"
import getRouter from "utils-lib/getRouter"

/**
 * Higher-order component that wraps a component and injects the router into its props.
 * @function
 * @param {React.Component} WrappedComponent - The component to wrap.
 * @returns {React.Component} The wrapped component with the router injected into its props.
 */
// eslint-disable-next-line react/display-name
export default (WrappedComponent) => (props) => {
  const router = getRouter()

  return <WrappedComponent {...props} router={router} />
}
