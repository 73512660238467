import capitalize from "lodash/capitalize"
import join from "lodash/join"
import map from "lodash/map"
import words from "lodash/words"

/**
 * Takes a string and converts it to initial case (every word, including articles, are capitalized)
 *
 * @param {string} val - The string you wish to convert.
 * @returns {string} The initial case version of the string.
 */
export const convertToInitialCase = (val) => {
  /* Split incoming string on spaces into individual words and capitalize each word */
  const capitalizedWords = map(words(val, /[^ ]+/g), (word) => {
    /* Split individual words on hyphen and capitalize each part of the word */
    const capitalizedParts = map(words(word, /[^-]+/g), (substr) =>
      capitalize(substr),
    )
    /* Rejoin with a hyphen to preserve word */
    return join(capitalizedParts, `-`)
  })
  /* Rejoin with spaces to preserve string */
  return join(capitalizedWords, ` `)
}
