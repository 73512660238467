import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.UNENROLL_AUTOPAYMENT_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.UNENROLL_AUTOPAYMENT_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.UNENROLL_AUTOPAYMENT_FULFILLED,
  payload: data,
})

export const deleteAutoPayment = () => (dispatch, getState) => {
  const state = getState()
  const { customerAutoPayPaperless } = state
  const api = `AUTO_PAYMENT`
  dispatch(pendingAC())

  const template = URITemplate(urls.url[api])
  const customerId = _.get(
    customerAutoPayPaperless,
    `autoPaymentAndPaperlessAccount`,
    ``,
  )
  const url = template.expand({ customerId })

  return getTokenAction(dispatch, getState)
    .then((token) => {
      const state = getState()
      const config = {
        headers: {
          oktaToken: token.accessToken,
          referrerUrl: `${window.location.origin}${window.location.pathname}`,
        },
        params: {
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
          lang: state.siteLanguage.language,
        },
      }

      const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      if (apiKey !== ``) {
        config.headers.apiKey = apiKey
      }

      return axios
        .create(config)
        .delete(url)
        .then((response) => {
          dispatch(fulfilledAC(response))
          return response
        })
    })
    .catch((response) => {
      dispatch(
        failedAC({
          statusCode: _.get(response, `response.status`, ``),
          errorMessage: _.get(response, `response.data.Error`, ``),
        }),
      )
      return response
    })
}
