/**
 * Validates a zip code string using a regular expression.
 *
 * @function
 * @param {string} zip - The zip code to validate.
 * @returns {boolean} A boolean value indicating whether the specified zip code is valid.
 */
export const validateZip = (zip) => {
  const re = /^[0-9]{5}$|^[a-zA-Z][0-9][a-zA-Z][-\s]?[0-9][a-zA-Z][0-9]$/
  return re.test(zip) === true
}
