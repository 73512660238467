import { SET_LAST_ACTIVITY_TIME } from "constants-lib/actionTypes"

const initialState = {
  lastActivityTime: ``,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LAST_ACTIVITY_TIME:
      return {
        lastActivityTime: action.payload,
      }

    default:
      return state
  }
}
