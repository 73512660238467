import findIndex from "lodash/findIndex"
import find from "lodash/find"
import remove from "lodash/remove"
import concat from "lodash/concat"
import * as ActionTypes from "constants-lib/actionTypes"
import { NO } from "constants-lib/common"

const initialState = {
  cancelTickets: [],
  cancelRollOffTicketState: ``,
  cancelledRollOffTicket: ``,
  displayMethod: `list`,
  getRollOffTicketDetailsState: ``,
  getRollOffTicketsState: ``,
  rollOffTicketDetails: {},
  rollOffTicketDetailsAction: ``,
  rollOffTickets: {},
  selectedRollOffRequestId: ``,
  selectedStatus: ``,
  selectedViewBy: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.CONFIRM_ROLLOFF_TICKET_CANCEL_FAILED:
      return {
        ...state,
        cancelledRollOffTicket: ``,
        cancelRollOffTicketState: `failed`,
      }

    case ActionTypes.CONFIRM_ROLLOFF_TICKET_CANCEL_FULFILLED:
      return {
        ...state,
        cancelledRollOffTicket: action.payload.rollOffRequestId,
        cancelRollOffTicketState: `fulfilled`,
      }

    case ActionTypes.CONFIRM_ROLLOFF_TICKET_CANCEL_PENDING:
      return {
        ...state,
        cancelRollOffTicketState: `pending`,
      }

    case ActionTypes.GET_ROLLOFF_TICKET_DETAILS_FAILED:
      return {
        ...state,
        rollOffTicketDetails: [],
        rollOffTicketDetailsAction: ``,
        getRollOffTicketDetailsState: `failed`,
      }

    case ActionTypes.GET_ROLLOFF_TICKET_DETAILS_FULFILLED:
      return {
        ...state,
        rollOffTicketDetails: action.payload.ticketDetails,
        getRollOffTicketDetailsState: `fulfilled`,
      }

    case ActionTypes.GET_ROLLOFF_TICKET_DETAILS_PENDING:
      return {
        ...state,
        getRollOffTicketDetailsState: `pending`,
      }

    case ActionTypes.GET_ROLLOFF_TICKETS_FAILED:
      return {
        ...state,
        cancelTickets: [],
        rollOffTickets: {},
        getRollOffTicketsState: `failed`,
      }

    case ActionTypes.GET_ROLLOFF_TICKETS_FULFILLED:
      return {
        ...state,
        cancelTickets: [],
        rollOffTickets: action.payload.tickets,
        getRollOffTicketsState: `fulfilled`,
      }

    case ActionTypes.GET_ROLLOFF_TICKETS_PENDING:
      return {
        ...state,
        getRollOffTicketsState: `pending`,
      }

    case ActionTypes.INIT_ROLLOFF_TICKET_CANCEL:
      return {
        ...state,
        cancelTickets: concat(state.cancelTickets, action.payload),
      }

    case ActionTypes.RESET_ROLLOFF_TICKET_CANCEL:
      const newCancelTicketArray = state.cancelTickets
      remove(newCancelTicketArray, (o) => o.rollOffRequestId === action.payload)

      return {
        ...state,
        cancelTickets: newCancelTicketArray,
      }

    case ActionTypes.RESET_ROLLOFF_TICKET_DETAILS:
      return {
        ...state,
        rollOffTicketDetails: {},
        rollOffTicketDetailsAction: ``,
        getRollOffTicketDetailsState: ``,
      }

    case ActionTypes.SET_ROLLOFF_DISPLAY_METHOD:
      return {
        ...state,
        displayMethod: action.payload,
      }

    case ActionTypes.SET_ROLLOFF_TICKET_DETAILS_INFO:
      return {
        ...state,
        rollOffTicketDetailsAction: action.payload.actionType,
        selectedRollOffRequestId: action.payload.rollOffRequestId,
        starRDY: action.payload.starRDY || NO,
      }

    case ActionTypes.SET_RO_TICKET_CANCEL_FLAG:
      const matchedTicket = find(state.cancelTickets, [
        `rollOffRequestId`,
        action.payload,
      ])

      const cancelledTicket = {
        rollOffRequestId: matchedTicket.rollOffRequestId,
        isCancelled: true,
      }

      const index = findIndex(state.cancelTickets, [
        `rollOffRequestId`,
        action.payload,
      ])

      const updatedTicketArray = state.cancelTickets.map((s) => s)
      updatedTicketArray.splice(index, 1, cancelledTicket)

      return {
        ...state,
        cancelTickets: updatedTicketArray,
      }

    case ActionTypes.SET_RO_STATUS_DROPDOWN_STATUS:
      return {
        ...state,
        selectedStatus: action.payload,
      }

    case ActionTypes.SET_RO_STATUS_DROPDOWN_VIEW_BY:
      return {
        ...state,
        selectedViewBy: action.payload,
      }

    case ActionTypes.RESET_MYSERVICES_DASHBOARD:
      return initialState

    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
      return initialState

    case ActionTypes.CLEAR_CANCEL_ORDER_STATUS:
      return {
        ...state,
        cancelRollOffTicketState: ``,
      }

    default:
      return state
  }
}
