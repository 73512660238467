import * as ActionTypes from "constants-lib/actionTypes"
import { userType } from "constants-lib/authentication"

const initialState = {
  errorMessage: ``,
  reviewParams: {},
  /* Payment signature */
  signatureDetails: {},
  signatureState: ``,
  /* Payment balance */
  balanceInfoState: ``,
  totalAmount: 0,
  currency: ``,
  ivrFee: 0,
  // paymentInfo: {},
  /* Payment email (also updated paymentInfo, paymentEmail, lineOfBusiness) */
  emailInfoState: ``,
  paymentEmail: ``,
  /* Payment services */
  servicesInfoState: ``,
  serviceInfo: {},
  /* TODO check if the following fields are used */
  billingEmail: ``,
  paymentMethod: `creditdebit`,
  paymentMethodSubmitted: false,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.SET_MY_WM_PAYMENT_REVIEW_DETAILS:
      return {
        ...state,
        reviewParams: action.payload,
      }

    // case ActionTypes.UPDATE_MY_WM_PAYMENT_INFO_DETAILS:
    //   return {
    //     ...state,
    //     paymentInfo: action.payload,
    //   }

    /* Verification */

    case ActionTypes.GET_MY_WM_PAYMENT_VERIFICATION_PENDING:
      return {
        ...state,
        errorMessage: ``,
        signatureDetails: {},
        signatureState: ``,
        servicesInfoState: ``,
        totalAmount: 0,
        ivrFee: 0,
        currency: ``,
        balanceInfoState: ``,
        billingEmail: ``,
        reviewParams: {},
        paymentInfo: {},
      }

    case ActionTypes.GET_MY_WM_PAYMENT_CANCEL_TRANSACTION:
      return {
        ...state,
        signatureDetails: {},
        signatureState: ``,
        totalAmount: 0,
        ivrFee: 0,
        currency: ``,
        balanceInfoState: ``,
        billingEmail: ``,
        errorMessage: ``,
        reviewParams: {},
      }

    case ActionTypes.RESET_MY_WM_PAYMENT_INFO:
      return {
        ...state,
        // Enable when Required
        // paymentInfo : {},
        reviewParams: {},
      }
    /* Payment balance */

    case ActionTypes.GET_MY_WM_PAYMENT_BALANCE_PENDING:
      return {
        ...state,
        balanceInfoState: `pending`,
        totalAmount: 0,
        currency: ``,
        ivrFee: 0,
      }

    case ActionTypes.GET_MY_WM_PAYMENT_BALANCE_FULFILLED:
      return {
        ...state,
        totalAmount: action.payload.totalAmount,
        currency: action.payload.currency,
        ivrFee: action.payload.ivrFee,
        balanceInfoState: `fulfilled`,
      }

    case ActionTypes.GET_MY_WM_PAYMENT_BALANCE_FAILED:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        balanceInfoState: `failed`,
      }
    /* Payment email */

    case ActionTypes.GET_MY_WM_PAYMENT_EMAIL_PENDING:
      return {
        ...state,
        paymentEmail: ``,
        lineOfBusiness: ``,
        emailInfoState: `pending`,
      }

    case ActionTypes.GET_MY_WM_PAYMENT_EMAIL_FULFILLED:
      return {
        ...state,
        paymentEmail: action.payload.paymentEmail,
        lineOfBusiness: action.payload.lineOfBusiness,
        emailInfoState: `fulfilled`,
      }

    case ActionTypes.GET_MY_WM_PAYMENT_EMAIL_FAILED:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        emailInfoState: `failed`,
      }
    /* Payment services */

    case ActionTypes.GET_MY_WM_PAYMENT_SERVICES_PENDING:
      return {
        ...state,
        serviceInfo: {},
        servicesInfoState: `pending`,
      }

    case ActionTypes.GET_MY_WM_PAYMENT_SERVICES_FULFILLED:
      return {
        ...state,
        serviceInfo: action.payload.serviceInfo,
        servicesInfoState: `fulfilled`,
      }

    case ActionTypes.GET_MY_WM_PAYMENT_SERVICES_FAILED:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        servicesInfoState: `failed`,
      }
    /* Payment signature */

    case ActionTypes.GET_MY_WM_PAYMENT_SIGNATURE_PENDING:
      return {
        ...state,
        signatureState: `pending`,
      }

    case ActionTypes.GET_MY_WM_PAYMENT_SIGNATURE_FULFILLED:
      return {
        ...state,
        signatureDetails: action.payload.signatureDetails,
        signatureState: `fulfilled`,
      }

    case ActionTypes.GET_MY_WM_PAYMENT_SIGNATURE_FAILED:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        signatureState: `failed`,
      }
    /* Customer support contact */

    case ActionTypes.GET_CUSTOMER_SUPPORT_CONTACT_PENDING:
      return {
        ...state,
        phone: {
          customerSupportFetchStatus: `pending`,
        },
      }

    case ActionTypes.GET_CUSTOMER_SUPPORT_CONTACT_FULFILLLED:
      return {
        ...state,
        phone: {
          ...action.payload,
          customerSupportFetchStatus: `fulfilled`,
        },
      }

    case ActionTypes.GET_CUSTOMER_SUPPORT_CONTACT_FAILED:
      return {
        ...state,
        phone: {
          customerSupportFetchStatus: `failed`,
        },
      }

    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
      return {
        ...initialState,
      }

    case ActionTypes.SET_USER_STATE: {
      /* Clear state after logging in. */
      if (action.payload === userType.LOGGED_IN) {
        return {
          ...initialState,
        }
      }
      return {
        ...state,
      }
    }

    case ActionTypes.SET_GUEST_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: action.payload.paymentMethod,
      }
    }

    case ActionTypes.SET_GUEST_PAYMENT_METHOD_SUBMITTED: {
      return {
        ...state,
        paymentMethodSubmitted: action.payload.paymentMethodSubmitted,
      }
    }

    case ActionTypes.UPDATE_MY_WM_PAYMENT_INFO_DETAILS:
      return {
        ...state,
        paymentEmail: action.payload.billingEmail || state.paymentEmail,
      }

    default:
      return state
  }
}
