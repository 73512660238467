/* eslint-disable no-unsafe-optional-chaining */
import reduce from "lodash/reduce"
import last from "lodash/last"
import find from "lodash/find"
import get from "lodash/get"
import map from "lodash/map"
import isEmpty from "lodash/isEmpty"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import dayjs from "utils-lib/date"
import * as ActionTypes from "constants-lib/actionTypes"
import { urls } from "utils-lib/builds"
import { isValidPaymentAmount } from "utils-lib/validation"
import { isUSCanadaAccount } from "utils-lib/isUSCanadaAccount"
import { getFormattedAddress } from "utils-lib/strings"
import { MockFetchError } from "utils-lib/mocks/errorMessages"
import { getWindow } from "utils-lib/getWindow"
import { CURRENCIES } from "constants-lib/common"
import { setCustomerAddressFromGeocode } from "../setCustomerAddressFromGeocode"
import { setPrimaryAddress } from "../customerSelections"
import { getTokenAction } from "../getToken"

const window = getWindow()

const CITY_BILLED_ROUTE = `/home/large-trash-pickup/request`
const CONTACT_BACK_ROUTE = `/support/cb`

export const getCustomerIds = (accounts) =>
  map(accounts, (item) => item.custAccountId)

// getUserPaymentDetails
const pendingAC = () => ({
  type: ActionTypes.GET_USER_PAYMENT_PENDING,
})
const failedAC = (statusCode) => ({
  type: ActionTypes.GET_USER_PAYMENT_FAILED,
  payload: statusCode,
})
const fulfilledAC = (data) => ({
  type: ActionTypes.GET_USER_PAYMENT_FULFILLED,
  payload: data,
})

// getAllCustomer
const pendingStatusAC = () => ({
  type: ActionTypes.GET_USER_PAYMENT_STATUS_PENDING,
})
const failedStatusAC = (statusCode) => ({
  type: ActionTypes.GET_USER_PAYMENT_STATUS_FAILED,
  payload: statusCode,
})
const fulfilledStatusAC = (data) => ({
  type: ActionTypes.GET_USER_PAYMENT_STATUS_FULFILLED,
  payload: data,
})

/**
 * Translates the responses from the customer details by country and state.
 * @function translateResponses
 * @param {Object} customerDetailsByCountry - The customer details categorized by country.
 * @param {Object} customerDetailsByCountry.USD - The customer details for USD currency.
 * @param {Array} customerDetailsByCountry.USD.customerDetails - An array of customer details for USD currency.
 * @param {Object} customerDetailsByCountry.CAD - The customer details for CAD currency.
 * @param {Array} customerDetailsByCountry.CAD.customerDetails - An array of customer details for CAD currency.
 * @param {Function} getState - The Redux getState function.
 * @returns {Object} An object containing the total current amount, total current amount with credits, invoice details, billing details, currency, and amount balance by account.
 */

const translateResponses = (customerDetailsByCountry, getState) => {
  let totalCurrent = 0
  let totalCurrentWithCredits = 0
  let invoice = {}
  let currency = ``
  const billingDetails = {}
  const amountBalanceByAccount = {}

  const state = getState()

  const isMultiCountry =
    isUSCanadaAccount(state.userManageAccount) ||
    (customerDetailsByCountry.USD?.customerDetails?.length &&
      customerDetailsByCountry.CAD?.customerDetails?.length)

  let selectedCurrency = get(
    state,
    `myPaymentCheckout.selectedCurrency`,
    CURRENCIES.USD,
  )
  if (!isMultiCountry) {
    selectedCurrency = !isEmpty(customerDetailsByCountry.CAD)
      ? CURRENCIES.CAD
      : CURRENCIES.USD
  }

  const customerDetails = isMultiCountry
    ? [
        ...customerDetailsByCountry?.CAD?.customerDetails,
        ...customerDetailsByCountry?.USD?.customerDetails,
      ]
    : customerDetailsByCountry[selectedCurrency]?.customerDetails

  const userAccountDetails = get(
    state,
    `userManageAccount.userAccountDetails`,
    [],
  )
  const invalidAccounts = userAccountDetails
    .filter((s) => s.status === `WRTOFFCAN`)
    .map((s) => s.custAccountId)

  customerDetails?.forEach((customerDetail) => {
    const customerId = customerDetail.ezPayId

    const balances = get(customerDetail, `balances`, [])
    currency = get(customerDetail, `currency`, ``)
    const balanceObj = find(balances, (s) => s.type === `Total`)

    const balance = parseFloat(get(balanceObj, `amount`, `0`))

    const shouldAddBalance =
      !isMultiCountry ||
      (selectedCurrency === CURRENCIES.USD && currency === CURRENCIES.USD) ||
      (selectedCurrency === CURRENCIES.CAD && currency === CURRENCIES.CAD)

    if (shouldAddBalance) {
      totalCurrentWithCredits += balance
    }
    if (
      isValidPaymentAmount(balance) === true &&
      !invalidAccounts.includes(customerId) &&
      shouldAddBalance
    ) {
      totalCurrent += balance
    }

    /* Create key value pairs for amount and due date for each account */
    const invoices = get(customerDetail, `invoices`, [])
    if (invoices.length > 0) {
      const latestInvoice = last(invoices)
      amountBalanceByAccount[customerId] = {
        amount: balance,
        amountAfterDueDate: get(
          latestInvoice,
          `amount_owed_after_due_date`,
          `0`,
        ),
        dueDate: get(latestInvoice, `due_date`, ``),
      }
    } else if (balances.length > 0) {
      amountBalanceByAccount[customerId] = {
        amount: balance,
      }
    }
    billingDetails[customerId] = customerDetail
  })

  /* Single account */
  if (!isEmpty(customerDetails) && customerDetails.length === 1) {
    const invoices = get(customerDetails[0], `invoices`, [])
    invoice = last(invoices)
  }

  return {
    totalCurrent,
    totalCurrentWithCredits,
    invoice,
    billingDetails,
    currency,
    amountBalanceByAccount,
  }
}

const getBulkCustomerDetailsHelper = (token, dispatch, getState) => {
  const api = `BULK_CUSTOMER_SEARCH`
  const template = URITemplate(urls.url[api])

  const state = getState()

  const url = template.expand({
    lang: get(state, `siteLanguage.language`, `en_CA`),
    userId: get(state, `userAccount.userDetails.userId`, ``),
  })

  const config = {
    headers: {
      oktaToken: token,
      apiKey: get(urls, `apiKey.USER[${api}]`, ``),
    },
  }

  // Handle search error, allow subsequent calls to succeed
  return axios
    .create(config)
    .get(url)
    .then((response) => {
      const { accounts } = response.data.data
      accounts?.forEach((account, index) => {
        accounts[index].custAccountId = account.customerId
      })

      dispatch(fulfilledStatusAC({ accounts, actualResponse: response.data }))

      // If cached address is user's address, leave it as-is
      const currentAddress = get(
        state,
        `customerSelections.customerAddress.formattedAddress`,
        ``,
      )
        .toLowerCase()
        .slice(0, 7)

      const isCurrentAddressUserAddress = reduce(
        accounts,
        (result, item) => {
          if (result) {
            return result
          }

          const accountAddress = get(item, `serviceAddress.street`, ``)
            .toLowerCase()
            .slice(0, 7)

          if (accountAddress === currentAddress) {
            // eslint-disable-next-line no-param-reassign
            result = true
          }

          return result
        },
        false,
      )

      const formattedAddress = getFormattedAddress(get(accounts, `[0]`, {}))

      const isCityBilled =
        window.location.pathname.match(CITY_BILLED_ROUTE)?.length
      const isContactBackIntake =
        window.location.pathname.match(CONTACT_BACK_ROUTE)?.length

      // bypass this in the city billed flow
      if (
        !isCityBilled &&
        !isCurrentAddressUserAddress &&
        !isContactBackIntake
      ) {
        dispatch(setCustomerAddressFromGeocode(formattedAddress))
        dispatch(setPrimaryAddress(formattedAddress))
      }

      return accounts
    })
    .catch((e) => {
      dispatch(failedStatusAC(e.response?.status))
      return false
    })
}

/**
 * Initiates a search for available services based on user input and preferences.
 * @param {Object} searchParams - Parameters for the service search.
 * @param {string} searchParams.query - The search query or keywords.
 * @param {string} searchParams.location - The geographical location for the search.
 * @param {string} searchParams.category - The category of services to search within.
 * @param {number} searchParams.radius - The search radius in miles.
 * @param {boolean} searchParams.onlineOnly - Flag to include only online services.
 * @param {Function} onSuccess - Callback function to execute on successful search.
 * @param {Function} onError - Callback function to execute on search error.
 * @returns {Promise<Object>} A promise that resolves with the search results.
 */
const getAllCustomerDetailsHelper = (
  { accounts, token, userId },
  dispatch,
  getState,
) => {
  // TODO: Update to use the bulk API
  const api = `CUSTOMER_SEARCH`
  const template = URITemplate(urls.url[api])

  const state = getState()

  const promises = map(accounts, (account) => {
    const url = template.expand({
      ezpayId: account.custAccountId,
      lang: get(state, `siteLanguage.language`, `en_CA`),
      userId,
    })

    const config = {
      headers: {
        token,
        apiKey: get(urls, `apiKey.USER[${api}]`, ``),
      },
    }

    // Handle search error, allow subsequent calls to succeed
    return axios
      .create(config)
      .get(url)
      .then((r) => r)
      .catch((e) => e)
  })

  return axios
    .all(promises)
    .then(
      axios.spread((...responses) => {
        const res = map(responses, (response, index) => {
          if (
            process.env.NODE_ENV === `test` &&
            [404, 500].includes(response?.response?.status)
          ) {
            throw new MockFetchError(response.response.status)
          }

          return {
            ...get(response, `data.accounts[0]`),
            custAccountId: accounts[index].custAccountId,
          }
        })

        dispatch(fulfilledStatusAC({ accounts: res }))

        // If cached address is user's address, leave it as-is
        const currentAddress = get(
          state,
          `customerSelections.customerAddress.formattedAddress`,
          ``,
        )
          .toLowerCase()
          .slice(0, 7)

        const isCurrentAddressUserAddress = reduce(
          responses,
          (result, item) => {
            if (result) {
              return result
            }

            const accountAddress = get(
              item,
              `data.accounts[0].serviceAddress.street`,
              ``,
            )
              .toLowerCase()
              .slice(0, 7)

            if (accountAddress === currentAddress) {
              // eslint-disable-next-line no-param-reassign
              result = true
            }

            return result
          },
          false,
        )

        const formattedAddress = getFormattedAddress(
          get(responses, `[0].data.accounts[0]`),
        )

        const isCityBilled =
          window.location.pathname.match(CITY_BILLED_ROUTE)?.length
        const isContactBackIntake =
          window.location.pathname.match(CONTACT_BACK_ROUTE)?.length

        // bypass this in the city billed flow
        if (
          !isCityBilled &&
          !isCurrentAddressUserAddress &&
          !isContactBackIntake
        ) {
          dispatch(setCustomerAddressFromGeocode(formattedAddress))
          dispatch(setPrimaryAddress(formattedAddress))
        }

        return responses
      }),
    )
    .catch((e) => {
      dispatch(failedStatusAC(e.response?.status))
      return false
    })
}

/**
 * Initiates the process to fetch customer details for the given accounts.
 * @param {Array<string>} accounts - An array of account numbers for which customer details are to be fetched.
 * @returns {Function} A Redux thunk function that takes in dispatch and getState as arguments to manage the asynchronous operation.
 */
export const getCustomers = (accounts) => (dispatch, getState) => {
  const state = getState()
  const userId = get(state, `userAccount.userDetails.userId`, ``)

  dispatch(pendingStatusAC())
  return getTokenAction(dispatch, getState)
    .then((res) =>
      getAllCustomerDetailsHelper(
        {
          accounts,
          token: res.accessToken,
          userId,
        },
        dispatch,
        getState,
      ),
    )
    .catch((e) => {
      dispatch(failedStatusAC(e.response?.status))
      throw new Error(e)
    })
}

/**
 * Get all customers for the current user.
 * @returns A function that takes dispatch and getState as arguments.
 */
export const getAllCustomer = () => (dispatch, getState) => {
  const state = getState()
  const userId = get(state, `userAccount.userDetails.userId`, ``)

  if (!userId) {
    return Promise.resolve()
  }

  dispatch(pendingStatusAC())
  return getTokenAction(dispatch, getState)
    .then(({ accessToken }) =>
      getBulkCustomerDetailsHelper(accessToken, dispatch, getState),
    )
    .catch((e) => {
      dispatch(failedStatusAC(e.response?.status))
      throw new Error(e)
    })
}

/**
 * Fetches user payment details within a specific date range and processes the response.
 * @param {Object} params - Parameters for fetching user payment details.
 * @param {string} params.token - Authentication token required for the API call.
 * @param {string} params.userId - The user's unique identifier.
 * @param {Function} getState - The Redux getState function used to access the current state.
 * @returns {Promise<Object|boolean>} A promise that resolves with the processed payment details or false in case of an error.
 */
const getUserPaymentDetailsHelper = ({ token, userId }, dispatch, getState) => {
  const api = `BULK_INVOICE`
  const template = URITemplate(urls.url[api])

  const config = {
    headers: {
      oktaToken: token,
      apiKey: get(urls, `apiKey.USER[${api}]`, ``),
    },
  }
  const url = template.expand({
    lang: get(getState(), `siteLanguage.language`, `en_CA`),
    fromDate: dayjs().subtract(1, `years`).format(`YYYY-MM-DD`),
    toDate: dayjs().add(1, `months`).format(`YYYY-MM-DD`),
    userId,
  })

  return axios
    .get(url, config)
    .then((response) => {
      const usAccounts = get(response, `data.body.USD`, {})
      const caAccounts = get(response, `data.body.CAD`, {})

      const translatedResponse = translateResponses(
        { USD: usAccounts, CAD: caAccounts },
        getState,
      )

      dispatch(fulfilledAC(translatedResponse))
      return translatedResponse
    })
    .catch((e) => {
      dispatch(failedAC(e?.response?.status))
      return false
    })
}

/**
 * It gets the user's payment details
 * @returns A function that takes in two arguments, dispatch and getState.
 */
export const getUserPaymentDetails = () => (dispatch, getState) => {
  dispatch(pendingAC())

  const userId = get(getState(), `userAccount.userDetails.userId`, ``)

  return getTokenAction(dispatch, getState)
    .then((res) =>
      getUserPaymentDetailsHelper(
        {
          token: res.accessToken,
          userId,
        },
        dispatch,
        getState,
      ),
    )
    .catch((e) => {
      dispatch(failedAC(e.response?.status))
    })
}
