import get from "lodash/get"
import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  productItems: [],
  selectedProductId: ``,
  selectedProductName: ``,
  userSelection: {
    activeStep: 0,
  },
  locale: ``,
  term: `short`,
  outagesDetails: {},
  servletErrorMessageState: ``,
  servletErrorMessage: [],
}

export const customSort = (payload) =>
  payload?.sort(
    (a, b) =>
      (a?.productName?.includes("Construction")
        ? 1
        : a?.productName?.includes("Recycling")
        ? 2
        : 0) -
      (b?.productName?.includes("Construction")
        ? 1
        : b?.productName?.includes("Recycling")
        ? 2
        : 0),
  )

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ONLINE_PRODUCTS_FULFILLED: {
      return {
        ...state,
        onlineEcommercePricing: action.payload,
        initialEcommercePricing: action.payload,
        onlineProductInformationStatus: `fulfilled`,
        selectedWasteType: get(
          action.payload,
          `products[0].materials[0].id`,
          ``,
        ),
        userSelection: {
          ...state.userSelection,
          selectedWasteType: get(
            action.payload,
            `products[0].materials[0].id`,
            ``,
          ),
          buNumberfield: get(
            action.payload,
            `products[0].business_unit_number`,
          ),
          facility_id: get(action.payload, `products[0].facility_id` || ``),
        },
      }
    }

    case ActionTypes.CHANGE_TERM_PLAN:
      return {
        ...state,
        term: action.payload,
      }

    case ActionTypes.INITIALIZE_PRODUCT_RESULTS: {
      return {
        ...state,
        productItems: [],
        selectedProductId: ``,
        userSelection: {
          ...state.userSelection,
          activeStep: 0,
        },
      }
    }

    case ActionTypes.RESET_ECOM_PRICING: {
      return {
        ...state,
        onlineEcommercePricing: { ...state.initialEcommercePricing },
      }
    }

    case ActionTypes.SET_CUSTOMER_ADDRESS: {
      return {
        ...state,
        userSelection: { ...state.userSelection, activeStep: 0 },
        onlineProductInformationStatus: ``,
        productItems: [],
        selectedProductId: ``,
        selectedProductName: ``,
        selectedWasteType: ``,
        initialEcommercePricing: {},
        servletState: ``,
      }
    }

    case ActionTypes.GET_COMMERCIAL_SERVLET_PENDING: {
      return {
        ...state,
        uniqueProducts: {},
      }
    }

    case ActionTypes.GET_COMMERCIAL_SERVLET_FULFILLED: {
      return {
        ...state,
        uniqueProducts: action.payload,
      }
    }

    case ActionTypes.GET_COMMERCIAL_SERVLET_FAILED: {
      return {
        ...state,
      }
    }

    case ActionTypes.GET_CLOUDCRAZE_SERVICE_DAYS_PENDING: {
      return {
        ...state,
        pickupDays: {},
      }
    }

    case ActionTypes.GET_CLOUDCRAZE_SERVICE_DAYS_FULFILLED: {
      return {
        ...state,
        pickupDays: action.payload,
      }
    }

    case ActionTypes.GET_CLOUDCRAZE_SERVICE_DAYS_FAILED: {
      return {
        ...state,
      }
    }

    case ActionTypes.GET_OUTAGES_PENDING: {
      return {
        ...state,
        outagesDetails: {
          outages: {},
          getOutagesStatus: "pending",
        },
      }
    }

    case ActionTypes.GET_OUTAGES_FULFILLED: {
      return {
        ...state,
        outagesDetails: {
          ...action.payload,
          getOutagesStatus: "fulfilled",
        },
      }
    }

    case ActionTypes.GET_OUTAGES_FAILED: {
      return {
        ...state,
        outagesDetails: {
          getOutagesStatus: "failed",
        },
      }
    }

    case ActionTypes.GET_CLOUDCRAZE_DELIVERY_SCHEDULE_PENDING: {
      return {
        ...state,
        deliverySchedule: {},
      }
    }

    case ActionTypes.GET_CLOUDCRAZE_DELIVERY_SCHEDULE_FULFILLED: {
      return {
        ...state,
        deliverySchedule: action.payload,
      }
    }

    case ActionTypes.GET_CLOUDCRAZE_DELIVERY_SCHEDULE_FAILED: {
      return {
        ...state,
      }
    }

    case ActionTypes.GET_ONLINE_PRODUCTS_PENDING: {
      return {
        ...state,
        userSelection: {
          ...state.userSelection,
        },
        onlineEcommercePricing: {},
        onlineProductInformationStatus: `pending`,
        selectedWasteType: ``,
        locale: action.locale || action.payload,
      }
    }

    case ActionTypes.GET_ONLINE_PRODUCTS_FAILED: {
      return {
        ...state,
        onlineEcommercePricing: {},
        onlineProductInformationStatus: `failed`,
        selectedWasteType: ``,
      }
    }

    case ActionTypes.CLEAR_ONLINE_PRODUCTS_USER_SELECTION: {
      return {
        ...state,
        userSelection: {
          ...state.userSelection,
          ...action.payload,
        },
      }
    }

    case ActionTypes.SET_ONLINE_PRODUCTS_USER_SELECTION: {
      return {
        ...state,
        userSelection: {
          ...state.userSelection,
          ...action.payload,
        },
      }
    }

    case ActionTypes.GET_ECOMMERCE_PRICING_PENDING: {
      return {
        ...state,
        onlineEcommercePricingStatus: `pending`,
        userSelection: {
          ...state.userSelection,
          selectedWasteType: ``,
        },
      }
    }

    case ActionTypes.GET_ECOMMERCE_PRICING_FULFILLED: {
      const newState = {
        ...state,
        onlineEcommercePricingStatus: `fulfilled`,
        userSelection: {
          ...state.userSelection,
        },
      }
      const str = JSON.stringify(state.onlineEcommercePricing)
      newState.onlineEcommercePricing = { ...JSON.parse(str) }
      newState.onlineEcommercePricing.products.forEach((element) => {
        element.attributes.forEach((attribute) => {
          if (
            !action.payload.service_type ||
            attribute.service_type === action.payload.service_type
          ) {
            // eslint-disable-next-line no-param-reassign
            attribute.materials[0].pricing.total_amount =
              action.payload.products.find(
                (s) => s.id === element.id,
              ).attributes.pricing.total_amount
          }
        })
      })
      return newState
    }

    case ActionTypes.GET_ECOMMERCE_PRICING_FAILED: {
      return {
        ...state,
        onlineEcommercePricingStatus: `failed`,
        userSelection: {
          ...state.userSelection,
          selectedWasteType: ``,
        },
      }
    }

    case ActionTypes.CHANGE_WASTE_TYPE: {
      return {
        ...state,
        userSelection: {
          ...state.userSelection,
          selectedWasteType: action.payload,
        },
      }
    }

    case ActionTypes.SET_ONLINE_SELECTED_PRODUCT: {
      return {
        ...state,
        selectedProductId: action.payload.productId || action.payload,
        selectedProductName: action.payload.productName || ``,
      }
    }

    case ActionTypes.GET_SERVLET_DETAILS_FULFILLED:
      return {
        ...state,
        productItems: action.payload,
        selectedProductId: state.selectedProductId
          ? state.selectedProductId
          : customSort(action.payload)[0]?.productId ||
            action.payload[0].productId,
        selectedProductName: state.selectedProductName
          ? state.selectedProductName
          : customSort(action.payload)[0]?.productName ||
            action.payload[0].productName,
        servletState: `fulfilled`,
      }

    case ActionTypes.GET_SERVLET_DETAILS_PENDING:
      return {
        ...state,
        servletState: `pending`,
      }

    case ActionTypes.GET_HOLIDAY_JSON_PENDING: {
      return {
        ...state,
        holidaysList: {},
        holidaysListStatus: `pending`,
      }
    }

    case ActionTypes.GET_HOLIDAY_JSON_FULFILLED: {
      return {
        ...state,
        holidaysList: action.payload,
        holidaysListStatus: `fulfilled`,
      }
    }

    case ActionTypes.GET_HOLIDAY_JSON_FAILED: {
      return {
        ...state,
        holidaysList: {},
        holidaysListStatus: `failed`,
      }
    }

    case ActionTypes.GET_SE_CAPACITY_PLANNER_PENDING: {
      return {
        ...state,
        seCapacityPlanner: {},
        seCapacityPlannerStatus: `pending`,
      }
    }

    case ActionTypes.GET_SE_CAPACITY_PLANNER_FULFILLED: {
      return {
        ...state,
        seCapacityPlanner: action.payload,
        seCapacityPlannerStatus: `fulfilled`,
      }
    }

    case ActionTypes.GET_SE_CAPACITY_PLANNER_FAILED: {
      return {
        ...state,
        seCapacityPlanner: {},
        seCapacityPlannerStatus: `failed`,
      }
    }

    case ActionTypes.GET_ERROR_MESSAGE_SERVLET_FULFILLED:
      return {
        ...state,
        servletErrorMessage: action.payload,
        servletErrorMessageState: `fulfilled`,
      }

    case ActionTypes.GET_ERROR_MESSAGE_SERVLET_PENDING:
      return {
        ...state,
        servletErrorMessageState: `pending`,
        servletErrorMessage: initialState.servletErrorMessage,
      }

    case ActionTypes.GET_ERROR_MESSAGE_SERVLET_FAILED:
      return {
        ...state,
        servletErrorMessageState: `failed`,
      }

    default:
      return state
  }
}
