/* Adobe Launch is used for tagging events for analytics. */

/* Add this class name to any HTML element to be taggable by Adobe Launch. */
export const ADOBE_LAUNCH_TAGGABLE_CSS_CLASS_NAME = `a-taggable`
export const ADOBE_LAUNCH_SCA_TAGGABLE_CSS_CLASS_NAME = `sca-taggable`

/**
 * Returns the Adobe Launch CSS class name based on the provided options.
 * @param {Object} options - The options object.
 * @param {boolean} [options.disabled=false] - Whether Adobe Launch is disabled.
 * @param {boolean} [options.isSCA=false] - Whether the application is in SCA mode.
 * @returns {string} The Adobe Launch CSS class name.
 */
export const getAdobeLaunchClassName = ({
  disabled = false,
  isSCA = false,
}) => {
  if (disabled) {
    return ``
  }
  return isSCA
    ? `${ADOBE_LAUNCH_TAGGABLE_CSS_CLASS_NAME} ${ADOBE_LAUNCH_SCA_TAGGABLE_CSS_CLASS_NAME}`
    : ADOBE_LAUNCH_TAGGABLE_CSS_CLASS_NAME
}

/**
 * Returns an object with the analytics field assigned to the supplied value.
 * @param {Object} options - The options object.
 * @param {boolean} [options.disabled=false] - Indicates if tracking is disabled.
 * @param {string} [options.value=''] - The value to assign to the analytics field.
 * @returns {Object} - The object with the analytics field assigned to the supplied value.
 */
export const getAdobeLaunchAttribute = ({ disabled = false, value = `` }) => {
  /* Do not add the attribute if tracking is disabled or
   if there is no value. */
  if (disabled === true || !value) {
    return {}
  }

  /* Create object with analytics field assigned to the supplied value. */
  return {
    analytics: value,
  }
}
