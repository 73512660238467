import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import _ from "lodash"
import { urls } from "utils-lib/builds"
import { getTokenAction } from "actions"
import { MOCK_EXTRA_PICKUP_INFORMATION } from "utils-lib/mocks"
import {
  GET_EXTRA_PICKUP_ELIGIBILITY_PENDING,
  GET_EXTRA_PICKUP_ELIGIBILITY_FULFILLED,
  GET_EXTRA_PICKUP_ELIGIBILITY_FAILED,
} from "constants-lib/actionTypes"

export const pendingAC = () => ({
  type: GET_EXTRA_PICKUP_ELIGIBILITY_PENDING,
})

export const failedAC = (fetchCode) => ({
  type: GET_EXTRA_PICKUP_ELIGIBILITY_FAILED,
  payload: fetchCode,
})

export const fulfilledAC = (data) => ({
  type: GET_EXTRA_PICKUP_ELIGIBILITY_FULFILLED,
  payload: data,
})

export const getExtraPickupEligibility =
  ({ customerId }) =>
  (dispatch, getState) => {
    const state = getState()
    const api = `EXTRA_PICKUP_ELIGIBILITY`
    const template = URITemplate(urls.url[api])

    const url = MOCK_EXTRA_PICKUP_INFORMATION
      ? `${window.origin}/mock/extra-pickup-eligibility-response.json`
      : template.expand({
          lang: _.get(state, `siteLanguage.language`, ``),
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
          serviceId: _.get(
            state.myServices,
            `selectedServiceObject.serviceId`,
            ``,
          ),
          customerId,
        })

    const config = {
      headers: {},
    }

    return getTokenAction(dispatch, getState).then(async (res) => {
      config.headers.oktaToken = res.accessToken
      config.headers.apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)

      return getExtraPickupEligibilityHelper(url, config, dispatch)
    })
  }

export const getExtraPickupEligibilityHelper = (url, config, dispatch) => {
  dispatch(pendingAC(GET_EXTRA_PICKUP_ELIGIBILITY_PENDING))

  return axios
    .get(url, config)
    .then((response) => {
      dispatch(fulfilledAC(response.data))
      return response
    })
    .catch((error) => {
      dispatch(failedAC(error))
      return error
    })
}
