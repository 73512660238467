import { userType } from "constants-lib/authentication"
import { localStorageKey } from "constants-lib/localStorage"
import { RESET_USER_ACCOUNT } from "constants-lib/actionTypes"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export const resetUserAccount = (userState = userType.ANONYMOUS) => {
  window.localStorage.setItem(localStorageKey.USER_STATE, userState)
  window.localStorage.removeItem(localStorageKey.USER_DETAILS)

  return {
    type: RESET_USER_ACCOUNT,
    payload: userState,
  }
}
