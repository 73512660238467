import * as ActionTypes from "constants-lib/actionTypes"
import map from "lodash/map"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import get from "lodash/get"
import { urls } from "utils-lib/builds"
import { isSingleAccountSelector } from "selectors-lib/linkedAccounts"
import { YES } from "constants-lib/common"
import { getTokenAction } from "../getToken"

export const pendingAC = (data) => ({
  type: ActionTypes.GET_REDESIGNED_DASHBOARD_SERVICES_DETAILS_PENDING,
  payload: data,
})

export const failedAC = (data) => ({
  type: ActionTypes.GET_REDESIGNED_DASHBOARD_SERVICES_DETAILS_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_REDESIGNED_DASHBOARD_SERVICES_DETAILS_FULFILLED,
  payload: data,
})

/**
 * Fetches details for redesigned dashboard services based on customer IDs and updates state accordingly.
 * @param {Array<string>|null} customerIds - An array of customer IDs to fetch services for, or null to fetch for all linked accounts.
 * @param {boolean} callWithoutUpdate - If true, does not update the state with the fetched data.
 * @returns {Function} A function that takes dispatch and getState as arguments and returns a Promise.
 */
export const getRedesignedDashboardServicesDetails =
  (customerIds = null, callWithoutUpdate = false) =>
  (dispatch, getState) => {
    const state = getState()
    const api = `REDESIGNED_DASHBOARD_SERVICES_DETAILS`
    const lang = state.siteLanguage?.language ? state.siteLanguage.language : ``
    const template = URITemplate(urls.url[api])
    const userId = state.userAccount?.userDetails?.userId

    const url = template.expand({
      lang,
      userId,
    })

    const config = {
      headers: {},
    }

    return getTokenAction(dispatch, getState).then((token) => {
      const state = getState()

      const hasCustomerIds = customerIds !== null
      const isSingleAccount = isSingleAccountSelector(state)

      config.headers = {
        token: token.accessToken,
        apiKey: get(urls, `apiKey.USER[${api}]`, ``),
      }

      const data = {}

      if (hasCustomerIds) {
        data.customers = map(customerIds, (customerId) => ({
          customerId,
          serviceChangeEligibility: YES,
        }))
      } else if (isSingleAccount) {
        data.customers = [
          {
            customerId:
              state.userManageAccount?.linkedAccounts[0]?.custAccountId,
            serviceChangeEligibility: YES,
          },
        ]
      } else {
        data.customers = map(
          state.userManageAccount?.linkedAccounts,
          (account) => ({
            customerId: account.custAccountId,
            serviceChangeEligibility: YES,
          }),
        )
      }

      return getRedesignedDashboardServicesDetailsHelper(
        url,
        config,
        data,
        callWithoutUpdate,
        dispatch,
      )
    })
  }

/**
 * Fetches details for redesigned dashboard services and dispatches actions based on the response.
 * @param {string} url - The URL to fetch the service details from.
 * @param {Object} config - Axios configuration object for the request.
 * @param {Object} data - The payload to be sent with the POST request.
 * @param {boolean} callWithoutUpdate - Flag indicating whether to update the state with the fetched data.
 * @returns {Promise<Object>} A promise that resolves with the fetched service details and status code, or rejects with an error.
 */
export const getRedesignedDashboardServicesDetailsHelper = (
  url,
  config,
  data,
  callWithoutUpdate,
  dispatch,
) => {
  dispatch(pendingAC({ callWithoutUpdate }))

  return axios
    .create(config)
    .post(url, data)
    .then((response) => {
      const statusCode = get(response, `status`, ``)

      const redesignedDashboardServicesDetailsData =
        response.data?.data?.serviceDetails?.map((detail) => ({
          ...detail,
          // required in legacy flows
          status: detail.statusCode,
          statusCode,
          requestTrackingId: response?.data?.requestTrackingId,
          errorMsg: response?.data?.errorMsg?.msg,
        }))

      dispatch(
        fulfilledAC({
          redesignedDashboardServicesDetailsData,
          callWithoutUpdate,
        }),
      )

      return {
        ...redesignedDashboardServicesDetailsData,
        statusCode,
      }
    })
    .catch((error) => {
      dispatch(
        failedAC({
          statusCode: get(error, `response.status`, ``),
          callWithoutUpdate,
        }),
      )
    })
}
