import * as ActionTypes from "constants-lib/actionTypes"

const initialState = {
  availableDates: [],
  getAvailableDatesState: ``,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_REPAIR_AVAILABLE_DATES_PENDING:
      return {
        ...state,
        getAvailableDatesState: `pending`,
      }

    case ActionTypes.GET_REPAIR_AVAILABLE_DATES_FAILED:
      return {
        ...state,
        getAvailableDatesState: `failed`,
      }

    case ActionTypes.GET_REPAIR_AVAILABLE_DATES_FULFILLED:
      return {
        ...state,
        availableDates: action.payload.dates.availableDates || [],
        getAvailableDatesState: `fulfilled`,
      }

    default:
      return state
  }
}
