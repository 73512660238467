import toUpper from "lodash/toUpper"
import get from "lodash/get"
import axios from "axios"
import { urls } from "utils-lib/builds"
import { AEM_JSON_FULFILLED, AEM_JSON_FAILED } from "constants-lib/actionTypes"

/**
 * Fetches a JSON representation of an AEM page based on the specified language, then dispatches actions based on the request's outcome.
 * @param {string} lang - The language code to fetch the AEM page for. If not provided, defaults to the site's current language.
 * @param {Function} dispatch - The Redux dispatch function used to dispatch actions.
 * @param {Function} getState - Function to access the current state, used to determine the site's current language if `lang` is not provided.
 */
export const getAEMJSON = (lang) => (dispatch, getState) => {
  const { siteLanguage } = getState()
  const locale =
    lang !== undefined ? lang : get(siteLanguage, `language`, `en_CA`)
  const api = `AEM_JSON_${toUpper(locale)}`
  const url = urls.aem[api]

  return axios
    .get(url)
    .then((response) => {
      dispatch({
        type: AEM_JSON_FULFILLED,
        payload: {
          standardnavigation: get(
            response,
            `data[:items].root[:items].responsivegrid[:items].standardnavigation`,
            {},
          ),
          footer: get(
            response,
            `data[:items].root[:items].responsivegrid[:items].footer`,
            {},
          ),
          chatbot: get(
            response,
            `data[:items].root[:items].responsivegrid[:items].chatbot`,
            {},
          ),
          simplenavigation: get(
            response,
            `data[:items].root[:items].responsivegrid[:items].simplenavigation`,
            {},
          ),
          disableApplePay: get(response, `data.disableApplePay`, false),
          disableGPay: get(response, `data.disableGPay`, false),
          disablePayPal: get(response, `data.disablePayPal`, false),
        },
      })
      return response
    })
    .catch((error) => {
      dispatch({
        type: AEM_JSON_FAILED,
        payload: error.response.status,
      })
    })
}
