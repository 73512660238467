import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import axios from "axios"
import { urls } from "utils-lib/builds"
import URITemplate from "urijs/src/URITemplate"
import {
  GET_CUSTOMER_SUPPORT_CONTACT_FAILED,
  GET_CUSTOMER_SUPPORT_CONTACT_FULFILLLED,
  GET_CUSTOMER_SUPPORT_CONTACT_PENDING,
} from "constants-lib/actionTypes"
import { getTokenAction } from "../getToken"

const getCustomerSupportContactActionCreator = (data) => ({
  type: GET_CUSTOMER_SUPPORT_CONTACT_FULFILLLED,
  payload: data,
})

/**
 * Fetches customer support contact information based on user status and location.
 * @param {Object} params - The parameters for the function.
 * @param {boolean} params.isLoggedIn - Indicates if the user is logged in.
 * @param {string} params.customerId - The ID of the customer.
 * @param {Object} params.customerAddress - The address of the customer.
 * @param {string} params.customerAddress.postalCode - The postal code of the customer's address.
 * @param {string} params.customerAddress.streetName - The street name of the customer's address.
 * @param {string} params.customerAddress.streetNumber - The street number of the customer's address.
 * @param {string} params.customerAddress.city - The city of the customer's address.
 * @param {string} params.customerAddress.state - The state of the customer's address.
 * @param {string} params.customerAddress.country - The country of the customer's address.
 * @param {string} params.locale - The locale of the user.
 * @param {string} params.userId - The ID of the user.
 * @returns {function} A function that takes the Redux dispatch and getState functions as arguments.
 */
export const getCustomerSupportContact =
  ({
    isLoggedIn = false,
    customerId = ``,
    customerAddress = {},
    locale,
    userId,
  }) =>
  (dispatch, getState) => {
    const { customerSelections } = getState()
    const { postalCode, streetName, streetNumber, city, state, country } =
      customerAddress
    const zipcode = postalCode
      ? encodeURI(postalCode)
      : encodeURI(customerSelections.customerAddress.postalCode)

    if (!zipcode || zipcode === "undefined") {
      return ``
    }

    dispatch({
      type: GET_CUSTOMER_SUPPORT_CONTACT_PENDING,
    })

    let searchUrl = ""
    let apiKey = ""
    if (isLoggedIn && !isEmpty(customerId)) {
      const api = `CONTACT_INFO_USER_BY_CUSTOMER_ID`
      apiKey = get(urls, `apiKey.USER[${api}]`, ``)

      const template = URITemplate(urls.url.CONTACT_INFO_USER_BY_CUSTOMER_ID)
      searchUrl = template.expand({ userId, customerId, locale })
    } else if (isLoggedIn && !customerId) {
      const api = `CONTACT_INFO_USER_BY_ADDRESS`
      apiKey = get(urls, `apiKey.USER[${api}]`, ``)

      const template = URITemplate(urls.url.CONTACT_INFO_USER_BY_ADDRESS)
      searchUrl = template.expand({
        userId,
        locale,
        street: `${streetNumber} ${streetName}`,
        city,
        state,
        zipcode,
        country,
      })
    } else if (!isLoggedIn && !isEmpty(customerId)) {
      const api = `CONTACT_INFO_GUEST_BY_CUSTOMER_ID`
      apiKey = get(urls, `apiKey.GUEST[${api}]`, ``)

      const template = URITemplate(urls.url.CONTACT_INFO_GUEST_BY_CUSTOMER_ID)
      searchUrl = template.expand({ customerId, locale })
    } else if (!isLoggedIn && !customerId) {
      const api = `CONTACT_INFO_GUEST_BY_ADDRESS`
      apiKey = get(urls, `apiKey.GUEST[${api}]`, ``)

      const template = URITemplate(urls.url.CONTACT_INFO_GUEST_BY_ADDRESS)
      searchUrl = template.expand({
        locale,
        street: `${streetNumber} ${streetName}`,
        zipcode: postalCode,
        city,
        state,
        country,
      })
    }

    const config = {
      headers: {
        apiKey,
      },
    }

    if (isLoggedIn) {
      return getTokenAction(dispatch, getState).then((token) => {
        config.headers.oktaToken = token.accessToken
        return axios(searchUrl, config)
          .then((response) =>
            dispatch(getCustomerSupportContactActionCreator(response.data)),
          )
          .catch((error) => {
            dispatch({
              type: GET_CUSTOMER_SUPPORT_CONTACT_FAILED,
            })
            console.error(error)
          })
      })
    }

    return axios(searchUrl, config)
      .then((response) =>
        dispatch(getCustomerSupportContactActionCreator(response.data)),
      )
      .catch((error) => {
        dispatch({
          type: GET_CUSTOMER_SUPPORT_CONTACT_FAILED,
        })
        console.error(error)
      })
  }
