import some from "lodash/some"
import findIndex from "lodash/findIndex"
import reduce from "lodash/reduce"
import reverse from "lodash/reverse"
import split from "lodash/split"
import concat from "lodash/concat"
import uniqBy from "lodash/uniqBy"
import isArray from "lodash/isArray"
import get from "lodash/get"
import filter from "lodash/filter"
import isEmpty from "lodash/isEmpty"
import flatMap from "lodash/flatMap"
import forEach from "lodash/forEach"
import * as ActionTypes from "constants-lib/actionTypes"
import { userType } from "constants-lib/authentication"
import { localStorageKey } from "constants-lib/localStorage"
import { CHANGE_TYPES } from "constants-lib/increaseDecrease"
import { getWindow } from "utils-lib/getWindow"

const window = getWindow()

export const initialState = {
  currentMain: `GettingStarted`,
  pendingMain: ``,
  getCustomerServicesState: ``,
  getPickupInfoState: ``,
  getTicketPickupInfoState: ``,
  locateServicesMethodType: ``,
  locateServicesInputValue: ``,
  pickupInfo: {},
  ticketPickupInfo: {},
  selectedAddress: ``,
  selectedCustomerId: [],
  selectedServiceKey: ``,
  selectedServiceObject: {},
  selectedZipCode: ``,
  services: [],
  uniqueAddressList: [],
  uniqueCustIdAddressObject: {},
  showRollOffRequestSuccessMessage: false,
  verifiedCustomerIDs: [],
  skippedVerificationCustomerServiceIDs: [],
  isDefaultCustomerId: false,
  isDefaultServiceKey: false,
  statusCode: {
    confirmRollOffTicketCancel: ``,
    getCustomerServices: ``,
    getCustomerVerification: ``,
    getPickupInfo: ``,
    getRollOffAvailableDates: ``,
    setRollOffService: ``,
    getRollOffTicketDetails: ``,
    getRollOffTickets: ``,
    getTicketPickupInfo: ``,
  },
  cookiedOrReturningUserHasServices: false,
  /* service change */
  serviceChangeEligibilityData: {},
  getServiceChangeEligibilityStatus: ``,
  serviceChangeRecommendationsData: {},
  getServiceChangeRecommendationsStatus: ``,
  serviceChangePriceDetails: {},
  getServiceChangePriceStatus: ``,
  serviceChangeDetails: {},
  submitServiceChangeStatus: ``,
  serviceChangeOrderHistoryData: {},
  getServiceChangeOrderHistoryStatus: ``,
  cancelServiceChangeStatus: ``,
  serviceChangeDatesData: {},
  getServiceChangeDatesStatus: ``,
  /* extra pickup */
  extraPickupEligibilityData: {},
  getExtraPickupEligibilityStatus: ``,
  extraPickupDatesData: {},
  getExtraPickupDatesStatus: ``,
  extraPickupPricingData: {},
  submitExtraPickupPricingStatus: ``,
  extraPickupDetails: {},
  submitExtraPickupStatus: ``,
  serviceHistory: [],
  serviceHistoryStatus: ``,
  serviceVisuals: [],
  serviceVisualsStatus: ``,
  getExtraPickupHistoryStatus: ``,
  extraPickupHistoryData: {},
  cancelExtraPickupStatus: ``,
  /* bulk pickup */
  bulkPickupEligibilityData: {},
  getBulkPickupEligibilityStatus: ``,
  bulkPickupDatesData: {},
  bulkPickupItems: {},
  getBulkPickupDatesStatus: ``,
  bulkPickupGuidelinesData: {},
  getBulkPickupGuidelinesStatus: ``,
  bulkPickupPricingData: {},
  getBulkPickupPricingStatus: ``,
  bulkPickupDetails: ``,
  submitBulkPickupStatus: ``,
  guestBulkPickupIncludedCategories: {},
  guestBulkPickupCreateTicketStatus: ``,
  guestBulkPickupCreateTicket: {},
  cancelBulkPickupState: ``,
  guestBulkPickupPricingStatus: ``,
  bulkPickupActiveStep: 0,
  bulkPickupcustomerSelectedOptions: [],

  /* report missed pickup */
  submitMissedPickupRequestDetails: ``,
  submitMissedPickupRequestStatus: ``,
  requestMissedPickupOrderID: ``,
  requestMissedPickupTrackingID: ``,
  requestMissedPickupCustomerID: ``,
  requestMissedPickupSuccess: ``,
  requestMissedPickupError: ``,
  cancelMissedPickupStatus: ``,
  requestGuestMissedPickupToken: ``,
  /* pause service */
  submitPauseServiceStatus: ``,
  pauseServiceDetails: {},
  getCombinedOrderHistoryStatus: ``,
  combinedOrderHistoryData: [],
  currentServiceChangeData: {},
  currentAddWasteData: {},
  cancelPauseServiceState: ``,
  /* SMS flows */
  textForBulkStatus: ``,
  textForBulkData: {},
  textForRolloffStatus: ``,
  textForRolloffData: {},
  textForXpuStatus: ``,
  textForXpuData: {},
  textForContainerRepairStatus: ``,
  textForContainerRepairData: {},
  textForViewEtaStatus: ``,
  textForViewEtaData: {},
  /* Redesigned Dashboard */
  redesignedDashboardPickupInfo: {},
  getRedesignedDashboardPickupInfoState: ``,
  redesignedDashboardServiceEligibilityData: {},
  redesignedDashboardServicesDetailsData: {},
  redesignedDashboardServiceEligibilityStatus: ``,
  redesignedDashboardServicesDetailsStatus: ``,
  // Container Not Out
  containerNotOutEligibilityData: {},
  containerNotOutEligibilityStatus: ``,
  containerNotOutSubmissionData: {},
  containerNotOutSubmissionStatus: ``,
  eligibilityApiData: {},
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.RESET_USER_ACCOUNT:
      /* filter out empty responses */
      const cookiedOrReturningUserHasServices = !isEmpty(
        filter(state.services, (service) => !isEmpty(service)),
      )

      return {
        ...initialState,
        pickupInfo: state.pickupInfo,
        services: state.services,
        cookiedOrReturningUserHasServices,
        guestBulkPickupIncludedCategories:
          state.guestBulkPickupIncludedCategories,
        guestBulkPickupPricingData: state.guestBulkPickupPricingData,
        guestBulkPickupCreateTicket: state.guestBulkPickupCreateTicket,
        guestBulkPickupCreateTicketStatus:
          state.guestBulkPickupCreateTicketStatus,
      }

    case ActionTypes.SET_CUSTOMER_ADDRESS:
      window.localStorage.setItem(
        localStorageKey.SERVICE_HISTORY_CUSTOMER_ID,
        ``,
      )
      window.localStorage.setItem(
        localStorageKey.SERVICE_HISTORY_SERVICE_ID,
        ``,
      )

      return {
        ...state,
        getCustomerServicesState: ``,
        getPickupInfoState: ``,
        locateServicesMethodType: ``,
        locateServicesInputValue: action.payload,
        pickupInfo: {},
        redesignedDashboardPickupInfo: {},
        containerNotOutEligibilityData: {},
        containerNotOutSubmissionData: {},
        redesignedDashboardServiceEligibilityData: {},
        redesignedDashboardServicesDetailsData: {},
        selectedAddress: ``,
        selectedCustomerId: [],
        selectedServiceKey: ``,
        selectedServiceObject: {},
        selectedZipCode: ``,
        uniqueAddressList: [],
        uniqueCustIdAddressObject: {},
        showRollOffRequestSuccessMessage: false,
        verifiedCustomerIDs: [],
        skippedVerificationCustomerServiceIDs: [],
        isDefaultCustomerId: false,
        isDefaultServiceKey: false,
        statusCode: {
          confirmRollOffTicketCancel: ``,
          getCustomerServices: ``,
          getCustomerVerification: ``,
          getPickupInfo: ``,
          getRollOffAvailableDates: ``,
          setRollOffService: ``,
          getRollOffTicketDetails: ``,
          getRollOffTickets: ``,
          getTicketPickupInfo: ``,
        },
      }

    case ActionTypes.GET_CUSTOMER_ACCOUNT_PENDING: {
      return {
        ...state,
        pickupInfo: {},
        services: [],
      }
    }

    case ActionTypes.GET_CUSTOMER_ACCOUNT_FULFILLED: {
      if (action.payload.isCustomerVerified) {
        const customerId = get(
          action.payload,
          `customerAccounts.accounts[0].ezpayId`,
          ``,
        )
        if (customerId !== ``) {
          return {
            ...state,
            verifiedCustomerIDs: [customerId],
          }
        }
      }
      return state
    }

    case ActionTypes.GET_CUSTOMER_SERVICES_FAILED:
      return {
        ...state,
        services: [],
        getCustomerServicesState: `failed`,
        statusCode: {
          ...state.statusCode,
          getCustomerServices: action.payload,
        },
      }

    // We need this legacy action handling for the guest users getCustomerServices action
    case ActionTypes.GET_CUSTOMER_SERVICES_FULFILLED: {
      const updates = {}
      if (isArray(action.payload.customerServices)) {
        updates.services = action.payload.customerServices
      } else if (action.payload.customerServices.services !== undefined) {
        updates.services = uniqBy(
          concat(state.services, action.payload.customerServices),
          `ezpayId`,
        )
      }
      return {
        ...state,
        ...updates,
        getCustomerServicesState: `fulfilled`,
      }
    }

    case ActionTypes.GET_CUSTOMER_SERVICES_PENDING:
      return {
        ...state,
        pickupInfo: {},
        services: [],
        getCustomerServicesState: `pending`,
      }

    case ActionTypes.CLEAR_CUSTOMER_SERVICES:
      return {
        ...state,
        getCustomerServicesState: ``,
        services: [],
        selectedServiceObject: {},
      }

    case ActionTypes.GET_GUEST_BULK_PICKUP_GUIDELINES_PENDING:
      return {
        ...state,
        getGuestBulkPickupGuidelinesStatus: `pending`,
        guestBulkPickupIncludedCategories: {},
        guestBulkPickupPricingData: {},
      }

    case ActionTypes.GET_GUEST_BULK_PICKUP_GUIDELINES_FULFILLED:
      return {
        ...state,
        guestBulkPickupGuidelinesData: action.payload,
        getGuestBulkPickupGuidelinesStatus: `fulfilled`,
        // clear these items out after the guidelines API is called to prevent stale data
        guestBulkPickupCreateTicketStatus: ``,
        guestBulkPickupCreateTicket: {},
      }

    case ActionTypes.GET_GUEST_BULK_PICKUP_GUIDELINES_FAILED:
      return {
        ...state,
        getGuestBulkPickupGuidelinesStatus: `failed`,
      }

    case ActionTypes.SET_GUEST_BULK_PICKUP_INCLUDED_CATEGORIES:
      return {
        ...state,
        guestBulkPickupIncludedCategories: action.payload,
      }

    case ActionTypes.GUEST_BULK_PICKUP_CREATE_TICKET_FULFILLED:
      return {
        ...state,
        guestBulkPickupCreateTicketStatus: `fulfilled`,
        guestBulkPickupCreateTicket: action.payload,
      }

    case ActionTypes.GUEST_BULK_PICKUP_CREATE_TICKET_PENDING:
      return {
        ...state,
        guestBulkPickupCreateTicketStatus: `pending`,
        guestBulkPickupCreateTicket: {},
        statusCode: {
          ...state.statusCode,
        },
      }

    case ActionTypes.GUEST_BULK_PICKUP_CREATE_TICKET_FAILED:
      return {
        ...state,
        guestBulkPickupCreateTicketStatus: `failed`,
      }

    case ActionTypes.GUEST_BULK_PICKUP_PRICING_FULFILLED:
      return {
        ...state,
        guestBulkPickupPricingData: action.payload,
        guestBulkPickupPricingStatus: `fulfilled`,
      }

    case ActionTypes.GUEST_BULK_PICKUP_PRICING_PENDING:
      return {
        ...state,
        guestBulkPickupPricingStatus: `pending`,
        statusCode: {
          ...state.statusCode,
        },
      }

    case ActionTypes.GUEST_BULK_PICKUP_PRICING_FAILED:
      return {
        ...state,
        guestBulkPickupPricingStatus: `failed`,
      }

    case ActionTypes.GET_GUEST_BULK_PICKUP_DATES_FULFILLED:
      return {
        ...state,
        guestBulkPickupDates: `fulfilled`,
        guestBulkPickupInfo: action.payload.data,
      }

    case ActionTypes.GET_GUEST_BULK_PICKUP_DATES_PENDING:
      return {
        ...state,
        guestBulkPickupDates: `pending`,
        statusCode: {
          ...state.statusCode,
        },
      }

    case ActionTypes.GET_GUEST_BULK_PICKUP_DATES_FAILED:
      return {
        ...state,
        guestBulkPickupDates: `failed`,
      }

    case ActionTypes.GET_PICKUP_INFO_PENDING:
      return {
        ...state,
        getPickupInfoState: `pending`,
      }

    case ActionTypes.GET_PICKUP_INFO_FAILED:
      return {
        ...state,
        getPickupInfoState: `failed`,
        statusCode: {
          ...state.statusCode,
          getPickupInfo: action.payload,
        },
      }

    case ActionTypes.GET_PICKUP_INFO_FULFILLED:
      return {
        ...state,
        pickupInfo: action.payload.pickupInfo,
        getPickupInfoState: `fulfilled`,
      }

    case ActionTypes.SERVICE_DASHBOARD_PICKUP_INFO_FULFILLED:
      return {
        ...state,
        pickupInfo: action.payload,
        getPickupInfoState: `fulfilled`,
      }

    case ActionTypes.GET_REDESIGNED_DASHBOARD_PICKUP_INFO_PENDING:
      return {
        ...state,
        getRedesignedDashboardPickupInfoState: `pending`,
      }

    case ActionTypes.GET_REDESIGNED_DASHBOARD_PICKUP_INFO_FAILED:
      return {
        ...state,
        getRedesignedDashboardPickupInfoState: `failed`,
        statusCode: {
          ...state.statusCode,
        },
      }

    case ActionTypes.GET_REDESIGNED_DASHBOARD_PICKUP_INFO_FULFILLED:
      return {
        ...state,
        redesignedDashboardPickupInfo:
          action.payload.redesignedDashboardPickupInfo,
        getRedesignedDashboardPickupInfoState: `fulfilled`,
      }

    case ActionTypes.GET_REDESIGNED_DASHBOARD_GUEST_PICKUP_INFO_PENDING:
      return {
        ...state,
        getRedesignedDashboardGuestPickupInfoState: `pending`,
      }

    case ActionTypes.GET_REDESIGNED_DASHBOARD_GUEST_PICKUP_INFO_FAILED:
      return {
        ...state,
        getRedesignedDashboardGuestPickupInfoState: `failed`,
        statusCode: {
          ...state.statusCode,
        },
      }

    case ActionTypes.GET_CONTAINER_NOT_OUT_ELIGIBILITY_FULFILLED:
      return {
        ...state,
        containerNotOutEligibilityData:
          action.payload.containerNotOutEligibility,
        containerNotOutEligibilityStatus: `fulfilled`,
      }

    case ActionTypes.GET_CONTAINER_NOT_OUT_ELIGIBILITY_PENDING:
      return {
        ...state,
        containerNotOutEligibilityStatus: `pending`,
      }

    case ActionTypes.GET_CONTAINER_NOT_OUT_ELIGIBILITY_FAILED:
      return {
        ...state,
        containerNotOutEligibilityStatus: `failed`,
        statusCode: {
          ...state.statusCode,
        },
      }

    case ActionTypes.CLEAR_CONTAINER_NOT_OUT_ELIGIBILITY:
      return {
        ...state,
        containerNotOutEligibilityData: {},
        containerNotOutEligibilityStatus: ``,
      }

    case ActionTypes.SUBMIT_CONTAINER_NOT_OUT_REQUEST_PENDING:
      return {
        ...state,
        containerNotOutSubmissionStatus: `pending`,
      }

    case ActionTypes.SUBMIT_CONTAINER_NOT_OUT_REQUEST_FAILED:
      return {
        ...state,
        containerNotOutSubmissionStatus: `failed`,
        statusCode: {
          ...state.statusCode,
        },
      }

    case ActionTypes.SUBMIT_CONTAINER_NOT_OUT_REQUEST_FULFILLED:
      return {
        ...state,
        containerNotOutSubmissionData:
          action.payload.submitContainerNotOutRequest,
        containerNotOutSubmissionStatus: `fulfilled`,
      }

    case ActionTypes.CLEAR_SUBMIT_CONTAINER_NOT_OUT_REQUEST:
      return {
        ...state,
        containerNotOutSubmissionData: {},
        containerNotOutSubmissionStatus: ``,
      }

    case ActionTypes.GET_REDESIGNED_DASHBOARD_GUEST_PICKUP_INFO_FULFILLED:
      return {
        ...state,
        redesignedDashboardGuestPickupInfo: action.payload.data,
        getRedesignedDashboardGuestPickupInfoState: `fulfilled`,
      }

    case ActionTypes.GET_REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY_PENDING:
      return {
        ...state,
        redesignedDashboardServiceEligibilityStatus: `pending`,
      }

    case ActionTypes.GET_REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY_FAILED:
      return {
        ...state,
        redesignedDashboardServiceEligibilityStatus: `failed`,
        statusCode: {
          ...state.statusCode,
        },
      }

    case ActionTypes.GET_REDESIGNED_DASHBOARD_SERVICE_ELIGIBILITY_FULFILLED:
      return {
        ...state,
        redesignedDashboardServiceEligibilityData:
          action.payload.redesignedDashboardServiceEligibilityData,
        eligibilityApiData: get(action.payload, `actualResponse`, {}),
        redesignedDashboardServiceEligibilityStatus: `fulfilled`,
      }

    case ActionTypes.GET_REDESIGNED_DASHBOARD_SERVICES_DETAILS_PENDING:
      const shouldUpdateRedesignedServicesDetailsPending =
        action.payload.callWithoutUpdate

      const redesignedServicesDetailsPendingUpdates = {
        ...state,
        redesignedDashboardServicesDetailsStatus: `pending`,
      }

      // in the case of legacy broker account flows, we want to call the new services API without updating the getCustomerServicesState redux state, this breaks the flow since broker accounts are still calling the legacy API (same that is used for guest users)
      if (!shouldUpdateRedesignedServicesDetailsPending) {
        redesignedServicesDetailsPendingUpdates.getCustomerServicesState = `pending`
      }

      return redesignedServicesDetailsPendingUpdates

    case ActionTypes.GET_REDESIGNED_DASHBOARD_SERVICES_DETAILS_FAILED:
      const shouldUpdateRedesignedServicesDetailsFailed =
        action.payload.callWithoutUpdate

      const redesignedServicesDetailsFailedUpdates = {
        ...state,
        redesignedDashboardServicesDetailsStatus: `failed`,
      }

      // in the case of legacy broker account flows, we want to call the new services API without updating the getCustomerServicesState redux state, this breaks the flow since broker accounts are still calling the legacy API (same that is used for guest users)
      if (!shouldUpdateRedesignedServicesDetailsFailed) {
        redesignedServicesDetailsFailedUpdates.getCustomerServicesState = `failed`
        redesignedServicesDetailsFailedUpdates.statusCode = {
          ...state.statusCode,
          getCustomerServices: action.payload.statusCode,
        }
      }

      return redesignedServicesDetailsFailedUpdates

    case ActionTypes.GET_REDESIGNED_DASHBOARD_SERVICES_DETAILS_FULFILLED:
      const { redesignedDashboardServicesDetailsData, callWithoutUpdate } =
        action.payload

      // support legacy services API structure for use in the application
      const legacyData = {
        services: [...state.services],
      }

      const isSingleUpdate = redesignedDashboardServicesDetailsData.length === 1

      // in the case of legacy broker account flows, we want to call the new services API without updating the services redux state, this breaks the flow since broker accounts are still calling the legacy API (same that is used for guest users)
      if (!callWithoutUpdate) {
        // If the redesignedDashboardServicesDetailsData array is only a length of one, this is a single account or specific customerId request, find and update the index in the services state
        if (isSingleUpdate) {
          const selectedService = redesignedDashboardServicesDetailsData[0]
          const indexToUpdate = findIndex(legacyData.services, {
            ezpayId: selectedService.ezpayId,
          })

          legacyData.services[indexToUpdate] = {
            ...selectedService,
            services: flatMap(selectedService.services),
          }
        } else {
          // Else, this was a request for all services on a multi-account, update the entire services object
          legacyData.services = []
          forEach(redesignedDashboardServicesDetailsData, (service) => {
            legacyData.services.push({
              ...service,
              services: flatMap(service.services),
            })
          })
        }
      }

      const updates = {
        ...state,
        ...legacyData,
        // TODO: We're getting services data from two places in this reducer, this should be phased out once we are switched over to the new services API
        redesignedDashboardServicesDetailsData:
          action.payload.redesignedDashboardServicesDetailsData,
        redesignedDashboardServicesDetailsStatus: `fulfilled`,
      }

      // in the case of legacy broker account flows, we want to call the new services API without updating the getCustomerServicesState redux state, this breaks the flow since broker accounts are still calling the legacy API (same that is used for guest users)
      if (!callWithoutUpdate) {
        updates.getCustomerServicesState = `fulfilled`
      }

      return updates

    case ActionTypes.GET_TICKET_PICKUP_INFO_PENDING:
      return {
        ...state,
        getTicketPickupInfoState: `pending`,
      }

    case ActionTypes.GET_TICKET_PICKUP_INFO_FAILED:
      return {
        ...state,
        getTicketPickupInfoState: `failed`,
        statusCode: {
          ...state.statusCode,
          getTicketPickupInfo: action.payload,
        },
      }

    case ActionTypes.GET_TICKET_PICKUP_INFO_FULFILLED:
      return {
        ...state,
        ticketPickupInfo: action.payload,
        getTicketPickupInfoState: `fulfilled`,
      }

    case ActionTypes.GET_ROLLOFF_TICKETS_FAILED:
      return {
        ...state,
        statusCode: {
          ...state.statusCode,
          getRollOffTickets: action.payload,
        },
      }

    case ActionTypes.CLEAR_TICKET_PICKUP_INFO:
      return {
        ...state,
        ticketPickupInfo: {},
        getTicketPickupInfoState: ``,
      }

    case ActionTypes.SET_DASHBOARD_CUSTOMER_ID:
      return {
        ...state,
        selectedCustomerId: action.payload,
      }

    case ActionTypes.GET_CUSTOMER_VERIFICATION_FULFILLED: {
      const { customerId, isVerified, changeMainAfterVerification } =
        action.payload
      if (isVerified === true) {
        const verifiedCustomerIDs = concat(
          state.verifiedCustomerIDs,
          customerId,
        )
        if (changeMainAfterVerification === true) {
          return {
            ...state,
            currentMain: state.pendingMain,
            pendingMain: initialState.pendingMain,
            verifiedCustomerIDs,
          }
        }
        return {
          ...state,
          verifiedCustomerIDs,
        }
      }
      return state
    }

    case ActionTypes.GET_CUSTOMER_VERIFICATION_FAILED:
      return {
        ...state,
        statusCode: {
          ...state.statusCode,
          getCustomerVerification: action.payload,
        },
      }

    case ActionTypes.SET_CUSTOMER_ID: {
      // const verifiedCustomerIDs = [ action.payload ]
      return {
        ...state,
        // verifiedCustomerIDs,
        locateServicesInputValue: action.payload,
      }
    }

    case ActionTypes.SET_CUSTOMER_EMAIL:
      return {
        ...state,
        locateServicesInputValue: action.payload,
      }

    case ActionTypes.SET_CUSTOMER_PHONE:
      return {
        ...state,
        locateServicesInputValue: action.payload,
      }

    case ActionTypes.SET_RO_REQUEST_SUCCESS_MESSAGE_SHOWN:
      return {
        ...state,
        showRollOffRequestSuccessMessage: false,
      }

    case ActionTypes.SET_DASHBOARD_SERVICE_ADDRESS: {
      window.localStorage.setItem(
        localStorageKey.SERVICE_HISTORY_CUSTOMER_ID,
        ``,
      )
      window.localStorage.setItem(
        localStorageKey.SERVICE_HISTORY_SERVICE_ID,
        ``,
      )

      const updates = {}
      if (action.payload.uniqueAddressList !== undefined) {
        updates.uniqueAddressList = action.payload.uniqueAddressList
      }
      if (action.payload.uniqueCustIdAddressObject !== undefined) {
        updates.uniqueCustIdAddressObject =
          action.payload.uniqueCustIdAddressObject
      }
      if (action.payload.isDefaultCustomerId !== undefined) {
        updates.isDefaultCustomerId = action.payload.isDefaultCustomerId
      } else {
        updates.isDefaultCustomerId = false
      }

      return {
        ...state,
        selectedAddress: action.payload.formattedAddress,
        selectedZipCode: action.payload.zipCode,
        selectedServiceKey:
          action.payload.formattedAddress === state.selectedAddress
            ? state.selectedServiceKey
            : ``,
        getPickupInfoState: ``,
        pickupInfo:
          action.payload.formattedAddress === state.selectedAddress
            ? state.pickupInfo
            : {},
        ...updates,
      }
    }

    case ActionTypes.SET_SELECTED_SERVICE_OBJECT:
      return {
        ...state,
        selectedServiceObject: action.payload.selectedServiceObject,
      }

    case ActionTypes.GET_TEXT_FOR_CITY_BILLED_BULK_PENDING:
      return {
        ...state,
        textForCityBilledBulkStatus: `pending`,
      }

    case ActionTypes.GET_TEXT_FOR_CITY_BILLED_BULK_FAILED:
      return {
        ...state,
        textForCityBilledBulkStatus: `failed`,
      }

    case ActionTypes.GET_TEXT_FOR_CITY_BILLED_BULK_FULFILLED:
      return {
        ...state,
        textForCityBilledBulkData: action.payload,
        textForCityBilledBulkStatus: `fulfilled`,
      }

    case ActionTypes.SET_DASHBOARD_SERVICES: {
      window.localStorage.setItem(
        localStorageKey.SERVICE_HISTORY_CUSTOMER_ID,
        ``,
      )
      window.localStorage.setItem(
        localStorageKey.SERVICE_HISTORY_SERVICE_ID,
        ``,
      )

      const customerIdServiceIdArray = split(action.payload.key, ` `)
      const serviceObject = {
        customerId: customerIdServiceIdArray[0],
        serviceId: customerIdServiceIdArray[1],
        lineOfBusiness: customerIdServiceIdArray[2],
      }
      return {
        ...state,
        selectedServiceKey: action.payload.key,
        isDefaultServiceKey: action.payload.isDefaultServiceKey,
        selectedServiceObject: serviceObject,
      }
    }

    case ActionTypes.SET_LOCATE_SERVICES_METHOD_TYPE:
      return {
        ...state,
        locateServicesMethodType: action.payload,
      }

    case ActionTypes.SET_MY_SERVICES_MAIN: {
      const newMain = action.payload
      if (newMain !== `Dashboard`) {
        const pendingMain =
          state.currentMain === `ROForm` && newMain === `Verification`
            ? `ROForm`
            : ``
        return {
          ...state,
          currentMain: newMain,
          pendingMain,
        }
      }
      return {
        ...state,
        currentMain: newMain,
      }
    }

    case ActionTypes.SET_ROLL_OFF_SERVICE_FULFILLED:
      return {
        ...state,
        showRollOffRequestSuccessMessage: true,
      }

    case ActionTypes.SET_SKIP_VERIFICATION: {
      const skippedVerificationCustomerServiceIDs = concat(
        state.skippedVerificationCustomerServiceIDs,
        action.payload,
      )
      return {
        ...state,
        skippedVerificationCustomerServiceIDs,
      }
    }

    case ActionTypes.UPDATE_ROLLOFF_SERVICE_REQUEST_FULFILLED:
      return {
        ...state,
        showRollOffRequestSuccessMessage: true,
      }

    case ActionTypes.RESET_ROLLOFF_TICKET_DETAILS:
      return {
        ...state,
        currentMain: ``,
        skippedVerificationCustomerServiceIDs: [],
        statusCode: {
          confirmRollOffTicketCancel: ``,
          getCustomerServices: ``,
          getCustomerVerification: ``,
          getPickupInfo: ``,
          getRollOffAvailableDates: ``,
          setRollOffService: ``,
          getRollOffTicketDetails: ``,
          getRollOffTickets: ``,
        },
      }

    case ActionTypes.CONFIRM_ROLLOFF_TICKET_CANCEL_FAILED:
      return {
        ...state,
        statusCode: {
          ...state.statusCode,
          confirmRollOffTicketCancel: action.payload,
        },
      }

    case ActionTypes.GET_ROLLOFF_TICKET_DETAILS_FAILED:
      return {
        ...state,
        statusCode: {
          ...state.statusCode,
          getRollOffTicketDetails: action.payload,
        },
      }

    case ActionTypes.SET_ROLL_OFF_SERVICE_FAILED:
      return {
        ...state,
        statusCode: {
          ...state.statusCode,
          setRollOffService: action.payload,
        },
      }

    case ActionTypes.GET_ROLL_OFF_AVAILABLE_DATES_FAILED:
      return {
        ...state,
        statusCode: {
          ...state.statusCode,
          getRollOffAvailableDates: action.payload,
        },
      }

    case ActionTypes.RESET_MYSERVICES_DASHBOARD:
    case ActionTypes.CLEAR_GUEST_DATA:
      return initialState

    case ActionTypes.RESET_DASHBOARD_FROM_GETTING_STARTED:
      return {
        ...initialState,
        locateServicesInputValue: state.locateServicesInputValue,
        locateServicesMethodType: `serviceAddress`,
      }

    /* Clear state after logging in. */
    case ActionTypes.SET_USER_STATE: {
      if (action.payload === userType.LOGGED_IN) {
        return initialState
      }

      return state
    }

    case ActionTypes.RESET_SELECTED_ADRRESS_MY_SERVICES: {
      return {
        ...state,
        selectedAddress: ``,
      }
    }

    case ActionTypes.SET_LAST_SELECTED_BROKER_ACCOUNT:
      return {
        ...state,
        selectedZipCode: action.payload.zipCode,
      }

    /* START SERVICE CHANGE ACTIONS */
    case ActionTypes.GET_SERVICE_CHANGE_ELIGIBILITY_PENDING:
      return {
        ...state,
        getServiceChangeEligibilityStatus: `pending`,
      }

    case ActionTypes.GET_SERVICE_CHANGE_ELIGIBILITY_FAILED:
      return {
        ...state,
        getServiceChangeEligibilityStatus: `failed`,
      }

    case ActionTypes.GET_SERVICE_CHANGE_ELIGIBILITY_FULFILLED:
      const { data, isAddWasteStream, isPauseService } = action.payload

      let newEligibilityData = {
        ...state.serviceChangeEligibilityData,
      }

      if (isAddWasteStream) {
        newEligibilityData.addWasteStream = data
      } else if (isPauseService) {
        newEligibilityData.pauseService = data
      } else {
        newEligibilityData = data
      }

      return {
        ...state,
        getServiceChangeEligibilityStatus: `fulfilled`,
        serviceChangeEligibilityData: newEligibilityData,
      }

    case ActionTypes.GET_SERVICE_CHANGE_RECOMMENDATIONS_PENDING:
      return {
        ...state,
        getServiceChangeRecommendationsStatus: `pending`,
      }

    case ActionTypes.GET_SERVICE_CHANGE_RECOMMENDATIONS_FAILED:
      return {
        ...state,
        getServiceChangeRecommendationsStatus: `failed`,
      }

    case ActionTypes.GET_SERVICE_CHANGE_RECOMMENDATIONS_FULFILLED:
      return {
        ...state,
        getServiceChangeRecommendationsStatus: `fulfilled`,
        serviceChangeRecommendationsData: action.payload,
      }

    case ActionTypes.GET_SERVICE_CHANGE_PRICE_PENDING:
      return {
        ...state,
        getServiceChangePriceStatus: `pending`,
      }

    case ActionTypes.GET_SERVICE_CHANGE_PRICE_FAILED:
      return {
        ...state,
        getServiceChangePriceStatus: `failed`,
      }

    case ActionTypes.GET_SERVICE_CHANGE_PRICE_FULFILLED:
      return {
        ...state,
        getServiceChangePriceStatus: `fulfilled`,
        serviceChangePriceDetails: action.payload,
      }

    case ActionTypes.SUBMIT_SERVICE_CHANGE_PRICE_PENDING:
      return {
        ...state,
        submitServiceChangeStatus: `pending`,
      }

    case ActionTypes.SUBMIT_SERVICE_CHANGE_PRICE_FAILED:
      return {
        ...state,
        submitServiceChangeStatus: `failed`,
      }

    case ActionTypes.SUBMIT_SERVICE_CHANGE_PRICE_FULFILLED:
      return {
        ...state,
        submitServiceChangeStatus: `fulfilled`,
        serviceChangeDetails: action.payload,
      }

    case ActionTypes.CLEAR_SERVICE_CHANGE_DATA:
      return {
        ...state,
        serviceChangeRecommendationsData: {},
        getServiceChangeRecommendationsStatus: ``,
        serviceChangePriceDetails: {},
        getServiceChangePriceStatus: ``,
        serviceChangeDetails: {},
        submitServiceChangeStatus: ``,
        serviceChangeDatesData: {},
        getServiceChangeDatesStatus: ``,
      }

    case ActionTypes.CLEAR_SERVICE_CHANGE_API_STATUSES:
      return {
        ...state,
        getServiceChangeRecommendationsStatus: ``,
        getServiceChangePriceStatus: ``,
        submitServiceChangeStatus: ``,
        getServiceChangeDatesStatus: ``,
      }

    case ActionTypes.GET_SERVICE_CHANGE_HISTORY_PENDING:
      return {
        ...state,
        getServiceChangeOrderHistoryStatus: `pending`,
      }

    case ActionTypes.GET_SERVICE_CHANGE_HISTORY_FAILED:
      return {
        ...state,
        getServiceChangeOrderHistoryStatus: `failed`,
      }

    case ActionTypes.GET_SERVICE_CHANGE_HISTORY_FULFILLED:
      return {
        ...state,
        getServiceChangeOrderHistoryStatus: `fulfilled`,
        serviceChangeOrderHistoryData: action.payload,
      }

    case ActionTypes.CANCEL_SERVICE_CHANGE_PENDING:
      return {
        ...state,
        cancelServiceChangeStatus: `pending`,
      }

    case ActionTypes.CANCEL_SERVICE_CHANGE_FAILED:
      return {
        ...state,
        cancelServiceChangeStatus: `failed`,
      }

    case ActionTypes.CANCEL_SERVICE_CHANGE_FULFILLED:
      return {
        ...state,
        cancelServiceChangeStatus: `fulfilled`,
      }

    case ActionTypes.GET_SERVICE_CHANGE_DATES_PENDING:
      return {
        ...state,
        getServiceChangeDatesStatus: `pending`,
      }

    case ActionTypes.GET_SERVICE_CHANGE_DATES_FAILED:
      return {
        ...state,
        getServiceChangeDatesStatus: `failed`,
      }

    case ActionTypes.GET_SERVICE_CHANGE_DATES_FULFILLED:
      return {
        ...state,
        getServiceChangeDatesStatus: `fulfilled`,
        serviceChangeDatesData: action.payload,
      }
    /* END SERVICE CHANGE ACTIONS */

    /* EXTRA PICKUP ACTIONS */
    case ActionTypes.GET_EXTRA_PICKUP_ELIGIBILITY_PENDING:
      return {
        ...state,
        getExtraPickupEligibilityStatus: `pending`,
        extraPickupEligibilityData: {},
        submitExtraPickupPricingStatus: ``,
        extraPickupPricingData: {},
        getExtraPickupDatesStatus: ``,
        extraPickupDatesData: {},
      }

    case ActionTypes.GET_EXTRA_PICKUP_ELIGIBILITY_FULFILLED:
      return {
        ...state,
        extraPickupEligibilityData: action.payload,
        getExtraPickupEligibilityStatus: `fulfilled`,
      }

    case ActionTypes.GET_EXTRA_PICKUP_ELIGIBILITY_FAILED:
      return {
        ...state,
        extraPickupEligibilityData: action.payload,
        getExtraPickupEligibilityStatus: `failed`,
      }

    case ActionTypes.GET_EXTRA_PICKUP_DATES_PENDING:
      return {
        ...state,
        getExtraPickupDatesStatus: `pending`,
      }

    case ActionTypes.GET_EXTRA_PICKUP_DATES_FULFILLED:
      return {
        ...state,
        extraPickupDatesData: action.payload,
        getExtraPickupDatesStatus: `fulfilled`,
      }

    case ActionTypes.GET_EXTRA_PICKUP_DATES_FAILED:
      return {
        ...state,
        extraPickupDatesData: action.payload,
        getExtraPickupDatesStatus: `failed`,
      }

    case ActionTypes.GET_EXTRA_PICKUP_PRICING_PENDING:
      return {
        ...state,
        submitExtraPickupPricingStatus: `pending`,
      }

    case ActionTypes.GET_EXTRA_PICKUP_PRICING_FULFILLED:
      return {
        ...state,
        extraPickupPricingData: action.payload,
        submitExtraPickupPricingStatus: `fulfilled`,
      }

    case ActionTypes.GET_EXTRA_PICKUP_PRICING_FAILED:
      return {
        ...state,
        extraPickupPricingData: action.payload,
        submitExtraPickupPricingStatus: `failed`,
      }

    case ActionTypes.SUBMIT_EXTRA_PICKUP_PENDING:
      return {
        ...state,
        submitExtraPickupStatus: `pending`,
      }

    case ActionTypes.SUBMIT_EXTRA_PICKUP_FULFILLED:
      return {
        ...state,
        extraPickupDetails: action.payload,
        submitExtraPickupStatus: `fulfilled`,
      }

    case ActionTypes.SUBMIT_EXTRA_PICKUP_FAILED:
      return {
        ...state,
        extraPickupDetails: action.payload,
        submitExtraPickupStatus: `failed`,
      }

    case ActionTypes.SUBMIT_UPDATE_EXTRA_PICKUP_PENDING:
      return {
        ...state,
        submitExtraPickupStatus: `pending`,
      }

    case ActionTypes.SUBMIT_UPDATE_EXTRA_PICKUP_FULFILLED:
      return {
        ...state,
        extraPickupDetails: action.payload,
        submitExtraPickupStatus: `fulfilled`,
      }

    case ActionTypes.SUBMIT_UPDATE_EXTRA_PICKUP_FAILED:
      return {
        ...state,
        extraPickupDetails: action.payload,
        submitExtraPickupStatus: `failed`,
      }

    case ActionTypes.CLEAR_EXTRA_PICKUP_DATA:
      return {
        ...state,
        extraPickupDatesData: {},
        getExtraPickupDatesStatus: ``,
        extraPickupPricingData: {},
        submitExtraPickupPricingStatus: ``,
        extraPickupDetails: {},
        submitExtraPickupStatus: ``,
      }

    case ActionTypes.CLEAR_CANCEL_ORDER_STATUS:
      return {
        ...state,
        cancelServiceChangeStatus: ``,
        cancelExtraPickupStatus: ``,
        cancelBulkPickupState: ``,
        cancelPauseServiceState: ``,
      }

    case ActionTypes.CANCEL_EXTRA_PICKUP_PENDING:
      return {
        ...state,
        cancelExtraPickupStatus: `pending`,
      }

    case ActionTypes.CANCEL_EXTRA_PICKUP_FAILED:
      return {
        ...state,
        cancelExtraPickupStatus: `failed`,
      }

    case ActionTypes.CANCEL_EXTRA_PICKUP_FULFILLED:
      return {
        ...state,
        cancelExtraPickupStatus: `fulfilled`,
      }

    case ActionTypes.GET_EXTRA_PICKUP_HISTORY_PENDING:
      return {
        ...state,
        getExtraPickupHistoryStatus: `pending`,
      }

    case ActionTypes.CANCEL_REPAIR_REQUEST_FAILED:
      return {
        ...state,
        cancelRepairRequestStatus: `failed`,
      }

    case ActionTypes.CANCEL_REPAIR_REQUEST_PENDING:
      return {
        ...state,
        cancelRepairRequestStatus: `pending`,
      }

    case ActionTypes.CANCEL_REPAIR_REQUEST_FULFILLED:
      return {
        ...state,
        cancelRepairRequestStatus: `fulfilled`,
      }

    case ActionTypes.GET_REPAIR_ELIGIBILITY_FAILED:
      return {
        ...state,
        getContainerRepairEligibilityStatus: `failed`,
      }

    case ActionTypes.GET_REPAIR_ELIGIBILITY_PENDING:
      return {
        ...state,
        getContainerRepairEligibilityStatus: `pending`,
      }

    case ActionTypes.GET_REPAIR_ELIGIBILITY_FULFILLED:
      return {
        ...state,
        getContainerRepairEligibilityStatus: `fulfilled`,
        getContainerRepairEligibility: action.payload,
      }

    case ActionTypes.GET_EXTRA_PICKUP_HISTORY_FAILED:
      return {
        ...state,
        getExtraPickupHistoryStatus: `failed`,
      }

    case ActionTypes.GET_EXTRA_PICKUP_HISTORY_FULFILLED:
      return {
        ...state,
        getExtraPickupHistoryStatus: `fulfilled`,
        extraPickupHistoryData: action.payload,
      }

    /* BULK PICKUP ACTIONS */
    case ActionTypes.GET_BULK_PICKUP_ELIGIBILITY_PENDING:
      return {
        ...state,
        getBulkPickupEligibilityStatus: `pending`,
      }

    case ActionTypes.GET_BULK_PICKUP_ELIGIBILITY_FULFILLED:
      return {
        ...state,
        bulkPickupEligibilityData: action.payload,
        getBulkPickupEligibilityStatus: `fulfilled`,
      }

    case ActionTypes.GET_BULK_PICKUP_ELIGIBILITY_FAILED:
      return {
        ...state,
        getBulkPickupEligibilityStatus: `failed`,
      }

    case ActionTypes.GET_BULK_PICKUP_DATES_PENDING:
      return {
        ...state,
        getBulkPickupDatesStatus: `pending`,
      }

    case ActionTypes.GET_BULK_PICKUP_DATES_FULFILLED:
      return {
        ...state,
        bulkPickupDatesData: action.payload,
        getBulkPickupDatesStatus: `fulfilled`,
      }

    case ActionTypes.SET_BULK_PICKUP_ITEMS:
      return {
        ...state,
        bulkPickupItems: {
          ...state.bulkPickupItems,
          ...action.payload,
        },
      }

    case ActionTypes.GET_BULK_PICKUP_DATES_FAILED:
      return {
        ...state,
        getBulkPickupDatesStatus: `failed`,
      }

    case ActionTypes.GET_BULK_PICKUP_GUIDELINES_PENDING:
      return {
        ...state,
        getBulkPickupGuidelinesStatus: `pending`,
      }

    case ActionTypes.GET_BULK_PICKUP_GUIDELINES_FULFILLED:
      return {
        ...state,
        bulkPickupGuidelinesData: action.payload,
        getBulkPickupGuidelinesStatus: `fulfilled`,
      }

    case ActionTypes.GET_BULK_PICKUP_GUIDELINES_FAILED:
      return {
        ...state,
        getBulkPickupGuidelinesStatus: `failed`,
      }

    case ActionTypes.GET_BULK_PICKUP_PRICING_PENDING:
      return {
        ...state,
        getBulkPickupPricingStatus: `pending`,
      }

    case ActionTypes.GET_BULK_PICKUP_PRICING_FULFILLED:
      return {
        ...state,
        bulkPickupPricingData: action.payload,
        getBulkPickupPricingStatus: `fulfilled`,
      }

    case ActionTypes.GET_BULK_PICKUP_PRICING_FAILED:
      return {
        ...state,
        getBulkPickupPricingStatus: `failed`,
      }

    case ActionTypes.SUBMIT_BULK_PICKUP_PENDING:
      return {
        ...state,
        submitBulkPickupStatus: `pending`,
      }

    case ActionTypes.SUBMIT_BULK_PICKUP_FULFILLED:
      return {
        ...state,
        bulkPickupDetails: action.payload,
        submitBulkPickupStatus: `fulfilled`,
      }

    case ActionTypes.SUBMIT_BULK_PICKUP_FAILED:
      return {
        ...state,
        submitBulkPickupStatus: `failed`,
      }

    case ActionTypes.CLEAR_BULK_PICKUP_DATA:
      return {
        ...state,
        ...action.payload,
      }

    case ActionTypes.CANCEL_BULK_PICKUP_PENDING:
      return {
        ...state,
        cancelBulkPickupState: `pending`,
      }

    case ActionTypes.CANCEL_BULK_PICKUP_FAILED:
      return {
        ...state,
        cancelBulkPickupState: `failed`,
      }

    case ActionTypes.CANCEL_BULK_PICKUP_FULFILLED:
      return {
        ...state,
        cancelBulkPickupState: `fulfilled`,
      }

    case ActionTypes.CANCEL_PAUSE_SERVICE_PENDING:
      return {
        ...state,
        cancelPauseServiceState: `pending`,
      }

    case ActionTypes.CANCEL_PAUSE_SERVICE_FAILED:
      return {
        ...state,
        cancelPauseServiceState: `failed`,
      }

    case ActionTypes.CANCEL_PAUSE_SERVICE_FULFILLED:
      return {
        ...state,
        cancelPauseServiceState: `fulfilled`,
      }
    /* REPORT MISSED PICKUP */
    case ActionTypes.REPORT_MISSED_PICKUP_PENDING:
      return {
        ...state,
        submitMissedPickupRequestStatus: `pending`,
      }
    case ActionTypes.REPORT_MISSED_PICKUP_FULFILLED:
      return {
        ...state,
        submitMissedPickupRequestDetails: action.payload,
        submitMissedPickupRequestStatus: `fulfilled`,
      }
    case ActionTypes.REPORT_MISSED_PICKUP_FAILED:
      return {
        ...state,
        submitMissedPickupRequestStatus: `failed`,
      }
    case ActionTypes.SET_REQUEST_MISSED_PICKUP_ORDER_ID:
      return {
        ...state,
        requestMissedPickupOrderID: action.payload,
      }
    case ActionTypes.SET_REQUEST_MISSED_PICKUP_TRACKING_ID:
      return {
        ...state,
        requestMissedPickupTrackingID: action.payload,
      }
    case ActionTypes.SET_REQUEST_MISSED_PICKUP_CUSTOMER_ID:
      return {
        ...state,
        requestMissedPickupCustomerID: action.payload,
      }
    case ActionTypes.SET_REQUEST_MISSED_PICKUP_SUCCESS:
      return {
        ...state,
        requestMissedPickupSuccess: action.payload,
      }
    case ActionTypes.SET_REQUEST_MISSED_PICKUP_ERROR:
      return {
        ...state,
        requestMissedPickupError: action.payload,
      }
    case ActionTypes.SET_GUEST_MISSED_PICKUP_TOKEN:
      return {
        ...state,
        requestGuestMissedPickupToken: action.payload,
      }
    case ActionTypes.CANCEL_MISSED_PICKUP_PENDING:
      return {
        ...state,
        cancelMissedPickupStatus: `pending`,
      }

    case ActionTypes.CANCEL_MISSED_PICKUP_FAILED:
      return {
        ...state,
        cancelMissedPickupStatus: `failed`,
      }

    case ActionTypes.CANCEL_MISSED_PICKUP_FULFILLED:
      return {
        ...state,
        cancelMissedPickupStatus: `fulfilled`,
      }
    /* ORDER HISTORY */
    case ActionTypes.GET_COMBINED_ORDER_HISTORY_PENDING:
      return {
        ...state,
        getCombinedOrderHistoryStatus: `pending`,
      }

    case ActionTypes.GET_COMBINED_ORDER_HISTORY_FAILED:
      return {
        ...state,
        getCombinedOrderHistoryStatus: `failed`,
      }

    case ActionTypes.GET_COMBINED_ORDER_HISTORY_FULFILLED:
      return {
        ...state,
        getCombinedOrderHistoryStatus: `fulfilled`,
        extraPickupHistoryData: action.payload,
      }

    case ActionTypes.CLEAR_COMBINED_ORDER_HISTORY:
      return {
        ...state,
        getCombinedOrderHistoryStatus: ``,
        combinedOrderHistoryData: [],
      }

    case ActionTypes.GET_SERVICE_HISTORY_PENDING:
      return {
        ...state,
        serviceHistoryStatus: `pending`,
      }
    case ActionTypes.GET_SERVICE_HISTORY_FAILED:
      return {
        ...state,
        serviceHistory: [],
        serviceHistoryStatus: `failed`,
      }

    case ActionTypes.GET_SERVICE_HISTORY_FULFILLED:
      return {
        ...state,
        serviceHistory: reverse(action.payload),
        serviceHistoryStatus: `fulfilled`,
      }

    case ActionTypes.GET_SERVICE_VISUALS_PENDING:
      return {
        ...state,
        serviceVisualsStatus: `pending`,
        serviceVisuals: [],
      }

    case ActionTypes.GET_SERVICE_VISUALS_FAILED:
      return {
        ...state,
        serviceVisualsStatus: `failed`,
        serviceVisuals: [],
      }

    case ActionTypes.GET_SERVICE_VISUALS_FULFILLED:
      return {
        ...state,
        serviceVisualsStatus: `fulfilled`,
        serviceVisuals: reduce(
          action.payload,
          (result, item) => {
            if (
              item.content_type.indexOf("image") !== -1 &&
              !some(
                result,
                (item1) => item1.content_type.indexOf("image") !== -1,
              )
            ) {
              result.push(item)
            }

            if (
              item.content_type.indexOf("video") !== -1 &&
              !some(
                result,
                (item1) => item1.content_type.indexOf("video") !== -1,
              )
            ) {
              result.push(item)
            }

            return result
          },
          [],
        ),
      }

    case ActionTypes.CLEAR_SERVICE_VISUALS:
      return {
        ...state,
        serviceVisualsStatus: ``,
        serviceVisuals: [],
      }

    case ActionTypes.CLEAR_SERVICES_ORDER_HISTORY:
      return {
        ...state,
        serviceChangeOrderHistoryData: {},
        getServiceChangeOrderHistoryStatus: ``,
        getExtraPickupHistoryStatus: ``,
        extraPickupHistoryData: {},
      }

    case ActionTypes.SUBMIT_PAUSE_SERVICE_PENDING:
      return {
        ...state,
        submitPauseServiceStatus: `pending`,
      }

    case ActionTypes.SUBMIT_PAUSE_SERVICE_FAILED:
      return {
        ...state,
        submitPauseServiceStatus: `failed`,
      }

    case ActionTypes.SUBMIT_PAUSE_SERVICE_FULFILLED:
      return {
        ...state,
        submitPauseServiceStatus: `fulfilled`,
        pauseServiceDetails: action.payload,
      }

    case ActionTypes.UPDATE_CURRENT_SERVICE_CHANGE_DATA:
      if (action.payload.type === CHANGE_TYPES.NEW_SERVICE) {
        return {
          ...state,
          currentAddWasteData: action.payload.data,
        }
      }

      return {
        ...state,
        currentServiceChangeData: action.payload.data,
      }

    case ActionTypes.CLEAR_CURRENT_SERVICE_CHANGE_DATA:
      return {
        ...state,
        currentServiceChangeData: {},
        currentAddWasteData: {},
      }

    case ActionTypes.GET_EXTRA_PICKUP_TICKET_DETAILS_PENDING:
      return {
        ...state,
        getExtraPickupState: `pending`,
      }

    case ActionTypes.GET_EXTRA_PICKUP_TICKET_DETAILS_FAILED:
      return {
        ...state,
        getExtraPickupState: `failed`,
      }

    case ActionTypes.GET_EXTRA_PICKUP_TICKET_DETAILS_FULFILLED:
      return {
        ...state,
        extraPickupInfo: action.payload.pickupInfo,
        getExtraPickupState: `fulfilled`,
      }

    case ActionTypes.GET_TEXT_FOR_BULK_PENDING:
      return {
        ...state,
        textForBulkStatus: `pending`,
      }

    case ActionTypes.GET_TEXT_FOR_BULK_FAILED:
      return {
        ...state,
        textForBulkStatus: `failed`,
      }

    case ActionTypes.GET_TEXT_FOR_BULK_FULFILLED:
      return {
        ...state,
        textForBulkData: action.payload,
        textForBulkStatus: `fulfilled`,
      }

    case ActionTypes.GET_TEXT_FOR_ROLLOFF_PENDING:
      return {
        ...state,
        textForRolloffStatus: `pending`,
      }

    case ActionTypes.GET_TEXT_FOR_ROLLOFF_FAILED:
      return {
        ...state,
        textForRolloffStatus: `failed`,
      }

    case ActionTypes.GET_TEXT_FOR_ROLLOFF_FULFILLED:
      return {
        ...state,
        textForRolloffData: action.payload,
        textForRolloffStatus: `fulfilled`,
      }

    case ActionTypes.GET_TEXT_FOR_XPU_PENDING:
      return {
        ...state,
        textForXpuStatus: `pending`,
      }

    case ActionTypes.GET_TEXT_FOR_XPU_FAILED:
      return {
        ...state,
        textForXpuStatus: `failed`,
      }

    case ActionTypes.GET_TEXT_FOR_XPU_FULFILLED:
      return {
        ...state,
        textForXpuData: action.payload,
        textForXpuStatus: `fulfilled`,
      }

    case ActionTypes.GET_TEXT_FOR_CONTAINER_REPAIR_PENDING:
      return {
        ...state,
        textForContainerRepairStatus: `pending`,
      }

    case ActionTypes.GET_TEXT_FOR_CONTAINER_REPAIR_FAILED:
      return {
        ...state,
        textForContainerRepairStatus: `failed`,
      }

    case ActionTypes.GET_TEXT_FOR_CONTAINER_REPAIR_FULFILLED:
      return {
        ...state,
        textForContainerRepairData: action.payload,
        textForContainerRepairStatus: `fulfilled`,
      }

    case ActionTypes.GET_TEXT_FOR_VIEW_ETA_PENDING:
      return {
        ...state,
        textForViewEtaStatus: `pending`,
      }

    case ActionTypes.GET_TEXT_FOR_VIEW_ETA_FAILED:
      return {
        ...state,
        textForViewEtaStatus: `failed`,
      }

    case ActionTypes.GET_TEXT_FOR_VIEW_ETA_FULFILLED:
      return {
        ...state,
        textForViewEtaData: action.payload,
        textForViewEtaStatus: `fulfilled`,
      }
    case ActionTypes.SET_CONTAINER_REPLACE_REPAIR:
      return {
        ...state,
        containerReplaceRepair: action.payload,
      }

    case ActionTypes.INCREMENT_BULK_PICKUP_ACTIVE_STEP:
      return {
        ...state,
        bulkPickupActiveStep: action.payload,
      }

    case ActionTypes.DECREMENT_BULK_PICKUP_ACTIVE_STEP:
      return {
        ...state,
        bulkPickupActiveStep: action.payload,
      }

    case ActionTypes.RESET_BULK_PICKUP_ACTIVE_STEP:
      return {
        ...state,
        bulkPickupActiveStep: 0,
      }

    case ActionTypes.SET_BULK_CUSTOMER_SELECTED_OPTIONS:
      return {
        ...state,
        bulkPickupcustomerSelectedOptions: action.payload,
      }

    default:
      return state
  }
}
