import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { getTokenAction } from "actions"
import {
  getFormattedPaymentAccountNumber,
  getFormattedSavedPaymentMethodLabel,
} from "utils-lib/strings"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.GET_SCHEDULED_PAYMENT_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.GET_SCHEDULED_PAYMENT_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_SCHEDULED_PAYMENT_FULFILLED,
  payload: data,
})

export const translateResponse = (response) => {
  const scheduledPaymentsDetailsArray = _.get(
    response,
    `data.data.schedulePaymentDetails`,
    [],
  )

  const scheduledPayments = _.map(scheduledPaymentsDetailsArray, (payment) => {
    const { methodType } = payment
    const { methodAcctNo } = payment
    const formattedAccountNumber =
      getFormattedPaymentAccountNumber(methodAcctNo)
    const subType = payment.methodSubType

    let paymentMethod = ``

    /* TODO: Format for other method types as well. */
    if (methodType === `CREDIT` || methodType === `ACH`) {
      paymentMethod = getFormattedSavedPaymentMethodLabel(
        subType,
        formattedAccountNumber,
      )
    }

    return {
      digitalPaymentType: payment.digitalPaymentType,
      paymentDate: payment.paymentDate,
      paymentAmount: payment.amount,
      paymentMethod,
      paymentStatus: payment.status,
      confirmationNumber: payment.confirmationNumber,
    }
  })

  return {
    scheduledPayments,
  }
}

export const getUserScheduledPayments = () => (dispatch, getState) => {
  const api = `SCHEDULE_PAYMENT`
  const template = URITemplate(urls.url[api])
  dispatch(pendingAC())

  return getTokenAction(dispatch, getState)
    .then((res) => {
      const state = getState()
      const customerId = _.get(state, `userBilling.billingDetailsAccount`, ``)
      const url = template.expand({ customerId })
      const config = {
        headers: {
          token: res.accessToken,
        },
        params: {
          userId: _.get(state, `userAccount.userDetails.userId`, ``),
          lang: state.siteLanguage.language,
        },
      }

      const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
      if (apiKey !== ``) {
        config.headers.apiKey = apiKey
      }

      return axios
        .create(config)
        .get(url)
        .then((response) => {
          const translatedResponse = translateResponse(response)
          dispatch(fulfilledAC(translatedResponse))
          return translatedResponse
        })
    })
    .catch((response) => {
      dispatch(
        failedAC({
          statusCode: _.get(response, `response.status`, ``),
          errorMessage: _.get(response, `response.data.Error`, ``),
        }),
      )
    })
}
