import values from "lodash/values"

export const lob = {
  BUSINESS: `business`,
  HOME: `home`,
  ROLLOFF: `roll-off`,
}

export const contactType = {
  BILLING: `billing`,
  SERVICE: `service`,
}
export const popupStatus = {
  ANALYTICS_FOR_POP_UP_MYWM: "ib-bulk-pickup-mywm-dashboard-exception-message",
  ANALYTICS_FOR_POP_UP_MY_SERVICES:
    "ib-bulk-pickup-services-dashboard-exception-message_",
  CONTACT_CITY: "Contact City",
  CONTACT_AGENT: "Contact Agent",
  SERVICES_NOT_OFFERED: "Services Not Offered",
  LOST_ZONE: "Lost/Turned Off Zone Contract",
  CANCELLED_CUTOFF_CUSTOMER: "Cancelled/Cutoff Customer",
  NOT_RESIDENTIAL: "Commercial/Rolloff Account Not Eligible",
  KNOWLEDGE_ONLY: "Knowledge Only",
  API_FAILURE: "Data/API Failure",
}

export const accountStatuses = {
  ACTIVE: `ACTIVE`,
  CUTOFF: `CUTOFF`,
  CANCELLED: `CANCELLED`,
  INACTVHLD: `INACTVHLD`,
  WRTOFFACTV: `WRTOFFACTV`,
  WRTOFFCAN: `WRTOFFCAN`,
  BNKPCYCAN: `BNKPCYCAN`,
  INACTIVE: `INACTIVE`,
  BNKPCYACTV: `BNKPCYACTV`,
  PNDNGINHLD: `PNDNGINHLD`,
  PNDNGCAN: `PNDNGCAN`,
}

export const serviceTypes = {
  ALL_PRODUCTS: `all_products`,
  CURB_SIDE: `curb_side`,
  PERMANENT_BIN: `permanent_bin`,
  CITY_BILLED: `city_billed`,
}

export const PRODUCT_LOBS = {
  RESIDENTIAL: `RESIDENTIAL`,
  COMMERCIAL: `COMMERCIAL`,
  ROLLOFF: `ROLLOFF`,
}

export const OCS_CODES = {
  ComputedETA: `ComputedETA`,
  AlreadyServiced: `AlreadyServiced`,
  UnabletoComputeETA: `UnabletoComputeETA`,
  RouteNotStarted: `RouteNotStarted`,
}

export const RESI_CNO_OCS_CODE = `NOTOUT_R`

export const CNO_MPU_MESSAGE = `We were unable to service you because your container was not out.`

export const lobType = {
  COMMERCIAL: `Commercial`,
  RESIDENTIAL: `Residential`,
  NONE: `none`,
}
export const lobFranchiseType = {
  COMMERCIAL: `franchiseDirectSmb`,
  RESIDENTIAL: `franchiseDirectOmr`,
  NONE: `none`,
}

export const LINE_OF_BUSINESS = {
  COMMERCIAL: `COMMERCIAL`,
  RESIDENTIAL: `RESIDENTIAL`,
  ROLLOFF: `ROLLOFF`,
  LANDFILL: `LANDFILL`,
  commercial: `commercial`,
  residential: `residential`,
  rolloff: `rolloff`,
  Commercial: `Commercial`,
  Residential: `Residential`,
  Rolloff: `Rolloff`,
}

export const tabbedContentStyleTypes = {
  COLUMN: `column`,
  CHART: `chart`,
  TEXT: `text`,
}

export const RIGHT = `right`
export const LEFT = `left`
export const ASC = `ASC`
export const DESC = `DESC`
export const A_TO_Z = `A -> Z`
export const Z_TO_A = `Z -> A`
export const DEFAULT_SORT_DIRECTION = `DESC`
export const DEFAULT_SORT_FIELD = `Nickname`
export const SECONDARY_SORT_FIELD = `Service Location`
export const CREDIT_CARD = `Credit Card`

export const sortFields = {
  SERVICE_LOCATION: `Service Location`,
  AMOUNT: `Amount`,
  CUSTOMER_ID: `Customer ID`,
  NICKNAME: `Nickname`,
}

export const pickerSortFields = {
  SERVICE_LOCATION: `Location`,
  AMOUNT: `Amount`,
  CUSTOMER_ID: `Cust. ID`,
  NICKNAME: `Nickname`,
}

export const LANG_OPTIONS_MAP = {
  EN_US: `en_US`,
  EN_CA: `en_CA`,
  FR_CA: `fr_CA`,
  ES_US: `es_US`,
}

export const PATH_MAP = {
  ROOT_PATH: `/`,
  MICROSITE_HOME_PATH: `/home`,
  HOME_PATH: `/myhome`,
  BUSINESS_PATH: `/mybusiness`,
  NO_LOB_PATH: `/getstarted`,
}

export const MAS_LOB_ROUTE = {
  RESIDENTIAL_ROUTE: `/us/residential`,
  BUSINESS_ROUTE: `/us/business`,
}

export const LOCALE_PATH_MAP = {
  [LANG_OPTIONS_MAP.EN_US]: `/us/en`,
  [LANG_OPTIONS_MAP.EN_CA]: `/ca/en`,
  [LANG_OPTIONS_MAP.FR_CA]: `/ca/fr`,
  [LANG_OPTIONS_MAP.ES_US]: `/us/es`,
}

export const VALID_ROOT_PATHS = [...values(LOCALE_PATH_MAP), PATH_MAP.ROOT_PATH]

export const ACTIVE_ACCOUNT_TYPES = [
  `ACTIVE`,
  `WRTOFFACTV`,
  `BNKPCYACTV`,
  `PNDNGINHLD`,
  `PNDNGCAN`,
]

export const WASTE_STREAMS = {
  RECYCLING: `RECYCLING`,
  WASTE: `WASTE`,
  ORGANIC: `ORGANIC`,
  MSW: `MSW`,
  RECYCLABLE: `RECYCLABLE`,
  YARD_WASTE: `YARD WASTE`,
}

export const YES = `Y`
export const NO = `N`
export const LANGUAGE_PATH = `LANGUAGE_PATH`

export const INPUT_TYPES = {
  text: `text`,
  email: `email`,
  phone: `phone`,
  postalCode: `postalCode`,
  address: `address`,
  customerId: `customerId`,
  checkbox: "checkbox",
}

export const MASTER_CONSUMPTION_FLAG = {
  YES: `Y`,
  WM: `WM.com Only`,
  NO: `N`,
  ARCHIVED: `Archived`,
  LOST_ZONE: `Lost Zone`,
  KNOWLEDGE_ONLY: `Knowledge Only`,
}

export const FEATURE_FLAG = {
  YES: `Y`,
  NO: `N`,
}

export const HANDLED_BY = {
  INTERNAL: `Internal`,
  EXTERNAL: `External`,
  EMPTY: ``,
}
export const MISCINSTRUCTIONS = {
  EMPTY: ``,
}

export const API_STATUSES = {
  PENDING: `pending`,
  FAILED: `failed`,
  FULFILLED: `fulfilled`,
}

export const COUNTRIES = {
  USA: `USA`,
}

export const COUNTRY_ABBREVIATIONS = {
  CA: `CA`,
  US: `US`,
}

export const PREPAYMENT_NO = `No`
export const PREPAYMENT_YES = `Yes`
export const PREPAYMENT_ANALYTICS_KEY = `Prepay`
export const NON_PREPAYMENT_ANALYTICS_KEY = `Non-Prepay`
export const NO_CHARGE_ANALYTICS_KEY = `Free`

export const DEVICES = {
  DESKTOP: `DESKTOP`,
  TABLET: `TABLET`,
  MOBILE: `MOBILE`,
}

export const CURRENCIES = {
  USD: `USD`,
  CAD: `CAD`,
}

export const CATEGORY = {
  OPENMARKET: `OPEN MARKET`,
  OPENMARKETHOA: `OPEN MARKET-HOA`,
}

export const API_RESPONSE_MESSAGES = {
  SUCCESS: `SUCCESS`,
}

export const BILLING_FREQUENCY = {
  MONTHLY: `Monthly`,
}

export const DAYS_OF_THE_WEEK = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

export const ELIGIBILITY_STATUSES = {
  ELIGIBLE: `eligible`,
}

export const USER_MODE = {
  USER_PAY: `USER_PAY`,
  USER_PAY_CA: `USER_PAY_CA`,
  GUEST_PAY: `GUEST_PAY`,
  USER_SAVEPM: `USER_SAVEPM`,
  USER_SAVEPM_CA: `USER_SAVEPM_CA`,
}
export const PAYMETHOD_TYPE = {
  CHEQUING: `chequing`, // For Canadian customers.
  CHECKING: `checking`, // For US customers.
}

export const SERVICE_STATUS = {
  CON_POS: `Confirmed Positive`,
}
export const MASTER_BILL_TYPE = `MASTR BILL ALL`

export const CONTACT_BACK_VALUES = {
  Other: `Other`,
  other: `other`,
  Miscellaneous: `Miscellaneous`,
  miscellaneous: `miscellaneous`,
}
export const MAX_INPUT_CHARACTERS = 63
export const MAX_FEEDBACK_INPUT_CHARACTERS = 160
export const SCREEN_SM_MAX = 767

/* measurements in rem */
export const CHAT_WINDOW_HEIGHT = 24
export const CHAT_HEADER_HEIGHT = 4
export const CHAT_INPUT_HEIGHT = 3
export const CHAT_WINDOW_WIDTH = 22

export const SLOT_TYPES = {
  INPUT: "input",
  BUTTON: "button",
  DATE_PICKER: "datePicker",
}

export const INPUT_SLOT_TYPES = {
  dumpsterLoc: SLOT_TYPES.INPUT,
  firstName: SLOT_TYPES.INPUT,
  lastName: SLOT_TYPES.INPUT,
  phoneNumber: SLOT_TYPES.INPUT,
  contactEmail: SLOT_TYPES.INPUT,
  pickupNotes: SLOT_TYPES.INPUT,
  poNumber: SLOT_TYPES.INPUT,
  starRDY: SLOT_TYPES.BUTTON,
  updateContact: SLOT_TYPES.BUTTON,
  rolloffSubmitOptions: SLOT_TYPES.BUTTON,
  rolloffSubmitOptionsA: SLOT_TYPES.BUTTON,
  rolloffSubmitOptionsB: SLOT_TYPES.BUTTON,
  rolloffSubmitOptionsC: SLOT_TYPES.BUTTON,
  isDuplicateRequest: SLOT_TYPES.BUTTON,
  serviceDate: SLOT_TYPES.DATE_PICKER,
  additionalDetails: SLOT_TYPES.INPUT,
  datePicker: SLOT_TYPES.BUTTON,
}

export const SLOTS_TO_ILLICIT = {
  dumpsterLoc: `dumpsterLoc`,
  firstName: `firstName`,
  lastName: `lastName`,
  phoneNumber: `phoneNumber`,
  contactEmail: `contactEmail`,
  pickupNotes: `pickupNotes`,
  poNumber: `poNumber`,
  starRDY: `starRDY`,
  updateContact: `updateContact`,
  rolloffSubmitOptions: `rolloffSubmitOptions`,
  rolloffSubmitOptionsA: `rolloffSubmitOptionsA`,
  rolloffSubmitOptionsB: `rolloffSubmitOptionsB`,
  rolloffSubmitOptionsC: `rolloffSubmitOptionsC`,
  isDuplicateRequest: `isDuplicateRequest`,
  serviceDate: `serviceDate`,
  serviceInfo: `serviceInfo`,
  materialType: `materialType`,
  repairReason: `repairReason`,
  CRSubmitOptions: `CRSubmitOptions`,
  CRSubmitOptionsA: `CRSubmitOptionsA`,
  additionalDetails: `additionalDetails`,
  extraPickupSubmitOptions: `extraPickupSubmitOptions`,
  extraPickupSubmitOptionsA: `extraPickupSubmitOptionsA`,
  datePicker: `datePicker`,
  inputOptions: `inputOptions`,
  connectToAgent: `connectToAgent`,
}

export const SLOTS_TO_RENDER_INLINE = [
  SLOTS_TO_ILLICIT.dumpsterLoc,
  SLOTS_TO_ILLICIT.firstName,
  SLOTS_TO_ILLICIT.lastName,
  SLOTS_TO_ILLICIT.phoneNumber,
  SLOTS_TO_ILLICIT.contactEmail,
  SLOTS_TO_ILLICIT.pickupNotes,
  SLOTS_TO_ILLICIT.poNumber,
  SLOTS_TO_ILLICIT.serviceDate,
  SLOTS_TO_ILLICIT.additionalDetails,
]

export const SLOT_BUTTON_TYPES = {
  PRIMARY: `PRIMARY`,
  SECONDARY: `SECONDARY`,
}

export const RESPONSE_FORMATS = {
  PLAIN_TEXT: `PlainText`,
  CUSTOM_PAYLOAD: `CustomPayload`,
}

export const LOCALE_MAP = {
  EN_US: `en_US`,
  EN_CA: `en_CA`,
  EN_FR: `en_FR`,
}

export const SLOTS_WITHOUT_MESSAGES = [
  SLOTS_TO_ILLICIT.pickupNotes,
  SLOTS_TO_ILLICIT.poNumber,
  SLOTS_TO_ILLICIT.rolloffSubmitOptionsA,
  SLOTS_TO_ILLICIT.rolloffSubmitOptionsB,
  SLOTS_TO_ILLICIT.rolloffSubmitOptionsC,
  SLOTS_TO_ILLICIT.CRSubmitOptionsA,
  SLOTS_TO_ILLICIT.additionalDetails,
  SLOTS_TO_ILLICIT.extraPickupSubmitOptionsA,
]

export const CHAT_CONTEXES = {
  LEX: `lex`,
  GENESYS: `genesys`,
}

export const ACTION_STATUSES = {
  PENDING: `pending`,
  FAILED: `failed`,
  FULFILLED: `fulfilled`,
}

export const SEGMENTS = {
  BUSINESS: `BUSINESS`,
  RESIDENTIAL: `RESIDENTIAL`,
}

export const TICKET_TYPES = {
  MPU: `MPU`,
  ETA: `ETA`,
  MPU_48: `MPU_48`,
  MPU_72: `MPU_72`,
}

export const NOT_WM_OWNED_TEXT = `notWMOwnedContainer`

// TODO: Please check again this const
// export const ACTIVE_ACCOUNT_TYPES = [
//   accountStatuses.ACTIVE,
//   accountStatuses.WRTOFFACTV,
//   accountStatuses.BNKPCYACTV,
//   accountStatuses.PNDNGINHLD,
//   accountStatuses.PNDNGCAN,
// ]

export const REASON = [
  `categoryNotFoundFromSOS`,
  `loggedInEligible`,
  `category not available from scopeOfService`,
]

export const DUPLICATE_REQUEST_ERROR_MESSAGE = `Duplicate Request!`

export const CONVENIENCE_FEE = `Convenience Fee`

export const CAN_NOT_OUT_SCENARIOS = {
  // Customer can start the flow and submit CNO ticket.
  ELIGIBLE: `ELIGIBLE`,
  // Customer is not able to submit CNO ticket.
  INELIGIBLE: `INELIGIBLE`,
  // Customer exceeded the free dispute limit.
  EXCEEDED: `EXCEEDED`,
  // There is a ticket already placed.
  DUPLICATE: `DUPLICATE`,
  // A ticket was created.
  SUBMITTED: `SUBMITTED`,
}
