// https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html
import React, { Component } from "react"

/**
 * Higher-order component that wraps a component and asynchronously imports it.
 * @function
 * @param {function} importComponent - The function to import the component.
 * @returns {React.Component} The wrapped component, asynchronously imported.
 */
export default (importComponent) =>
  // eslint-disable-next-line react/display-name
  class extends Component {
    constructor(props) {
      super(props)

      this.state = {
        component: null,
      }
    }

    async componentDidMount() {
      const { default: component } = await importComponent()

      this.setState({
        component,
      })
    }

    render() {
      const C = this.state.component

      return C ? <C {...this.props} /> : null
    }
  }
