import PropTypes from "prop-types"
import getContentUrl from "utils-lib/getContentUrl"
import { ReactSVG } from "react-svg"
import { makeStyles } from "tss-react/mui"
import { wmFonts } from "themes-lib/wmTheme"
import "./ButtonPrimary.scss"
import { isMyWMApp } from "utils-lib/isMyWMApp"
import { ButtonMUI } from "../ButtonMUI/ButtonMUI"

const useStyles = makeStyles()((theme, { isYellowBg }) => ({
  root: {
    textTransform: `none !important`,
    padding: isMyWMApp && `0.375rem 2rem`,
    background: `${
      isYellowBg ? theme.palette.background.yellow : theme.palette.primary.main
    } !important`,
    color: `${
      isYellowBg ? theme.palette.primary.main2_0 : theme.palette.common.white
    } !important`,
    "&:hover": {
      boxShadow: isMyWMApp && `0 3px 6px 0 rgba(33,33,33,0.2) !important`,
      backgroundColor: `${
        isYellowBg
          ? theme.palette.primary.yellowHover
          : theme.palette.primary.hoverGreen
      } !important`,
      color: `${
        isYellowBg ? theme.palette.primary.main2_0 : theme.palette.common.white
      } !important`,
    },
    "&:active": {
      backgroundColor: `${
        isYellowBg
          ? theme.palette.primary.yellowActive
          : theme.palette.primary.main2_0
      } !important`,
    },
    "&:focus": {
      outline:
        !isMyWMApp &&
        `1px dashed ${
          isYellowBg
            ? theme.palette.background.yellow
            : theme.palette.primary.main
        }`,
      outlineOffset: 4,
    },
    "&:disabled": {
      background: `${
        isYellowBg
          ? theme.palette.button.yellowDisabled
          : theme.palette.button.primaryDisabled
      } !important`,
      color: `${
        isYellowBg
          ? theme.palette.text.yellowDisabled
          : theme.palette.text.primaryDisabled
      } !important`,
    },
  },
  beigeVariant: {
    textTransform: `none !important`,
    background: `${theme.palette.background.beige} !important`,
    color: `${theme.palette.text.darkRemodeled} !important`,
    "&:hover": {
      backgroundColor: `${theme.palette.background.beigeHover} !important`,
    },
    "&:active": {
      backgroundColor: `${theme.palette.background.beige_25} !important`,
    },
    "&:focus": {
      outline: `1px ${theme.palette.background.beige_25}`,
      outlineOffset: 4,
    },
    ...wmFonts.maaxMedium,
  },
}))

// eslint-disable-next-line import/prefer-default-export
export const ButtonPrimary = ({
  beigeVariant,
  analytics_key,
  ariaLabel,
  analyticsKey,
  children,
  className,
  onClick,
  padding,
  shouldNotTrackInComponent,
  hasLinkWrapper,
  isIconLeft,
  isIconButton,
  isIconRight,
  isSCA,
  elementType,
  shouldPropagate,
  isYellowBg,
  invertColor,
  buttonRef,
  // eslint-disable-next-line react/prop-types
  component,
  disabled,
  testId,
  // eslint-disable-next-line react/jsx-props-no-spreading
  ...rest
}) => {
  const { classes } = useStyles({
    isYellowBg,
  })

  const analytics = analytics_key || analyticsKey || ``
  const isIconButtonRight = isIconButton || isIconRight

  const finalClassNames = `ButtonPrimary ${
    !beigeVariant && `maax-font-weight-bold`
  } ${padding} ${className} 
  ${beigeVariant ? classes.beigeVariant : classes.root}  ${
    invertColor && !isYellowBg && `invert-color-greenbg`
  } ${invertColor && isYellowBg && `invert-color-yellowbg`}`

  const shouldArrowGreen =
    (!invertColor && isYellowBg) || (invertColor && !isYellowBg)
  const whiteFwdArrowPath =
    "/content/dam/wm/icons/controls/20x20/white/arrow-forward.svg"
  const whiteBackArrowPath =
    "/content/dam/wm/icons/controls/20x20/white/arrow-back.svg"
  const greenFwdArrowPath =
    "/content/dam/wm/icons/controls/20x20/green/arrow-forward.svg"
  const greenBackArrowPath =
    "/content/dam/wm/icons/controls/20x20/green/arrow-back.svg"
  return (
    <ButtonMUI
      data-testid={testId}
      color="primary"
      analytics_key={analytics}
      className={finalClassNames}
      aria-label={ariaLabel}
      disabled={disabled}
      onClick={(e) => {
        if (!shouldPropagate && !hasLinkWrapper) {
          e.preventDefault()
        }
        onClick(e)
      }}
      shouldNotTrackInComponent={shouldNotTrackInComponent}
      type="submit"
      isSCA={isSCA}
      elementType={elementType}
      buttonRef={buttonRef}
      component={component}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...rest}
    >
      {isIconLeft && (
        <ReactSVG
          wrapper="span"
          src={getContentUrl(
            shouldArrowGreen ? greenBackArrowPath : whiteBackArrowPath,
          )}
          className="pr-3 d-flex"
        />
      )}
      {children}
      {isIconButtonRight &&
        (isMyWMApp ? (
          <ReactSVG
            wrapper="span"
            src={getContentUrl("/content/dam/wm/icons/button-arrow.svg")}
            className="pl-3"
          />
        ) : (
          <ReactSVG
            wrapper="span"
            src={getContentUrl(
              shouldArrowGreen ? greenFwdArrowPath : whiteFwdArrowPath,
            )}
            className="pl-3 d-flex"
          />
        ))}
    </ButtonMUI>
  )
}

ButtonPrimary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  padding: PropTypes.string,
  analytics_key: PropTypes.string,
  analyticsKey: PropTypes.string,
  shouldNotTrackInComponent: PropTypes.bool,
  hasLinkWrapper: PropTypes.bool,
  isIconButton: PropTypes.bool,
  isIconRight: PropTypes.bool,
  isIconLeft: PropTypes.bool,
  isYellowBg: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.string.isRequired,
  ]),
  isSCA: PropTypes.bool,
  invertColor: PropTypes.bool,
  elementType: PropTypes.string,
  shouldPropagate: PropTypes.bool,
  buttonRef: PropTypes.node,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  ariaLabel: PropTypes.string,
  beigeVariant: PropTypes.bool,
}

ButtonPrimary.defaultProps = {
  children: "",
  className: ``,
  onClick: () => {},
  style: {},
  padding: ``,
  shouldNotTrackInComponent: false,
  hasLinkWrapper: false,
  isIconButton: false,
  isIconRight: false,
  isIconLeft: false,
  isYellowBg: false,
  analytics_key: ``,
  analyticsKey: ``,
  isSCA: false,
  elementType: `ButtonPrimary`,
  shouldPropagate: false,
  invertColor: false,
  buttonRef: null,
  disabled: false,
  testId: `ButtonPrimary`,
  ariaLabel: "",
  beigeVariant: false,
}
