import { IMaskInput } from "react-imask"
import TextField from "@mui/material/TextField"
import PropTypes from "prop-types"

const MaskedTextField = ({
  mask,
  inputProps,
  InputProps,
  onAccept,
  prepare,
  commit,
  unmask,
  /* mask={Number} specific props START */
  radix,
  padFractionalZeros,
  normalizeZeros,
  isPadAgreementFlow,
  ariaLabel,
  /* mask={Number} specific props END */
  ...otherProps
}) => {
  const inputPropsMask = {
    mask,
    onAccept,
    prepare,
    commit,
    unmask,
    radix,
    padFractionalZeros,
    normalizeZeros,
    ...inputProps,
    "aria-label": ariaLabel,
  }
  const InputPropsMask = {
    inputComponent: IMaskInput,
    ...InputProps,
  }

  return (
    <TextField
      data-testid="MaskedTextField"
      inputProps={inputPropsMask}
      /* eslint-disable-next-line react/jsx-no-duplicate-props */
      InputProps={InputPropsMask}
      helperText=" "
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...otherProps}
      disabled={isPadAgreementFlow}
    />
  )
}

MaskedTextField.propTypes = {
  mask: PropTypes.string.isRequired,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  onAccept: PropTypes.func,
  prepare: PropTypes.func,
  commit: PropTypes.func,
  unmask: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  radix: PropTypes.string,
  padFractionalZeros: PropTypes.bool,
  normalizeZeros: PropTypes.bool,
  isPadAgreementFlow: PropTypes.bool,
  ariaLabel: PropTypes.string,
}

MaskedTextField.defaultProps = {
  inputProps: {},
  InputProps: {},
  onAccept: () => {},
  prepare: (u) => u,
  commit: () => {},
  unmask: true,
  radix: `.`,
  padFractionalZeros: false,
  normalizeZeros: true,
  isPadAgreementFlow: false,
  ariaLabel: "",
}

export default MaskedTextField
