import { SET_DASHBOARD_SERVICE_ADDRESS } from "constants-lib/actionTypes"
import { setCustomerAddressFromGeocode } from "../setCustomerAddressFromGeocode"

export const myActionCreator = (data) => ({
  type: SET_DASHBOARD_SERVICE_ADDRESS,
  payload: data,
})

/**
 * Set the customer address and myServices selected address information.
 * @param data - address object
 * @returns The return value is null.
 */
export const setDashboardServiceAddress = (data) => (dispatch) => {
  /* Get a formatted geocode from google maps api and cookie the address by setting the
  customerSelections reducer's customer address without reinitializing customerAccounts or renderDashboard. */
  dispatch(setCustomerAddressFromGeocode(data.formattedAddress))

  /* Set myServices selected address information. */
  dispatch(myActionCreator(data))
  return null
}
