import * as ActionTypes from "constants-lib/actionTypes"
import { setLanguage } from "actions-lib/setLanguage"

export const setCustomerSelectedLanguage = (language) => ({
  type: ActionTypes.SET_CUSTOMER_SELECTED_LANGUAGE,
  payload: language,
})

export const switchLanguage =
  (language, shouldSetCustomerSelectedLanguage) => (dispatch) => {
    dispatch(setLanguage(language))
    if (shouldSetCustomerSelectedLanguage === true) {
      dispatch(setCustomerSelectedLanguage(language))
    }
  }
