import {
  SET_SHOW_CONTACT_MODAL,
  SET_HIDE_CONTACT_MODAL,
} from "constants-lib/actionTypes"

export const setShowContactModal = () => (dispatch) =>
  dispatch({
    type: SET_SHOW_CONTACT_MODAL,
  })

export const setHideContactModal = () => (dispatch) =>
  dispatch({
    type: SET_HIDE_CONTACT_MODAL,
  })
