import * as ActionTypes from "constants-lib/actionTypes"

export { setLastWidgetViewed } from "actions/setLastWidgetViewed"

export {
  getCustomerAccountByCustomerId,
  getCustomerAccountByEmail,
  getCustomerAccountByPhone,
  getCustomerByAddress,
} from "actions-lib/getCustomerAccountJson/"

export {
  setCustomerSelectedLanguage,
  switchLanguage,
} from "actions/international"
export { myActionCreator as hideNaturalLanguageDropdown } from "./hideNaturalLanguageDropdown"
export { myActionCreator as showNaturalLanguageDropdown } from "./showNaturalLanguageDropdown"
export { getCustomerSupportContact } from "actions-lib/getCustomerSupportContact"
export { getLiveChat } from "actions-lib/getLiveChat"
export { myActionCreator as initServiceRequest } from "./initServiceRequest"
export { getHolidaySchedule } from "actions-lib/getHolidaySchedule"
export { myActionCreator as setOptionsCount } from "actions/setOptionsCount"

export { clearCustomerServices } from "actions/clearCustomerServices"
export { getCustomerServices } from "actions-lib/getCustomerServices"
export { getRedesignedDashboardServicesDetails as getAllCustomerServices } from "actions-lib/getRedesignedDashboardServicesDetails"
export { resetMyServicesDashboard } from "actions/resetMyServicesDashboard"
export { resetDashboardFromGettingStarted } from "actions/resetDashboardFromGettingStarted"
export { setCustomerAddressFromGeocode } from "actions-lib/setCustomerAddressFromGeocode"
export { setDashboardCustomerId } from "actions-lib/setDashboardCustomerId"
export { setDashboardServiceAddress } from "actions-lib/setDashboardServiceAddress"
export { setDashboardServices } from "actions/setDashboardServices"
export { getRollOffTickets } from "actions/getRollOffTickets"
export { setMyServicesMain } from "actions/setMyServicesMain"
export { setFirstComponentLoaded } from "actions/setFirstComponentLoaded"
export { resetFirstComponentLoaded } from "actions/resetFirstComponentLoaded"
export { incrementComponentsLoaded } from "actions/incrementComponentsLoaded"
export { setApplePayConfirm } from "actions-lib/setApplePayConfirm"
export { getApplePay } from "actions-lib/getApplePay"
export { processApplePay } from "actions-lib/processApplePay"
export {
  setRollOffServiceRequest,
  getAvailableDates,
  cancelDuplicateRollOffRequest,
} from "actions/setRollOffRequest"
export {
  getRepairContainerAvailableDates,
  setContainerRepairRequest,
  setContainerRepairPricing,
} from "actions/setRepairContainerRequest"
export { cancelRepairRequest } from "actions/cancelRepairRequest"
export { cancelRecyclingPickup } from "actions/cancelRecyclingPickup"
export { getContainerRepairEligibility } from "actions/getContainerRepairEligibility"
export { updateRollOffServiceRequest } from "actions/updateRollOffServiceRequest"
export { initRollOffTicketCancel } from "actions/initRollOffTicketCancel"
export { resetRollOffTicketCancel } from "actions/resetRollOffTicketCancel"
export { confirmRollOffTicketCancel } from "actions/confirmRollOffTicketCancel"
export { setRollOffTicketCancelFlag } from "actions/setRollOffTicketCancelFlag"
export { getRollOffTicketDetails } from "actions/getRollOffTicketDetails"
export { getExtraPickupTicketDetails } from "actions/getExtraPickupTicketDetails"
export { resetRollOffTicketDetails } from "actions/resetRollOffTicketDetails"
export { setRollOffDisplayMethod } from "actions/setRollOffDisplayMethod"
export { setRollOffTicketDetailsInfo } from "actions/setRollOffTicketDetailsInfo"
export {
  getTicketPickupInfo,
  clearPickupInfoState,
} from "actions/getTicketPickupInfo"
export { setMyWMDropdownItem } from "actions/setMyWMDropdownItem"
export { setRenderMyWMOptions } from "actions/setRenderMyWMOptions"
export { setUseMaskedCustomerID } from "actions/setUseMaskedCustomerID"
export { setCustomerAccountSource } from "actions/setCustomerAccountSource"
export { setUsePaymentCustomerID } from "actions/setUsePaymentCustomerID"
export { setCustomerInfoEditableState } from "actions/setCustomerInfoEditableState"
export { setMyWMCustomerID } from "actions/setMyWMCustomerID"
export { setEligibilityFlags } from "actions/setEligibilityFlags"
export { setEditContactType } from "actions/setEditContactType"
export {
  getRecyclingPickupEligibility,
  getRecyclingPickupDate,
  setRecyclingPickupSubmit,
} from "actions/getRecylingPickupEligibility"

export { setPadData } from "actions/setPadData"
export const setSupportForm = () => ({
  type: ActionTypes.SET_SUPPORT_FORM,
})

export { setSkipVerification } from "actions/setSkipVerification"
export { getCustomerVerification } from "actions/getCustomerVerification"
export { setLocateServicesMethodType } from "actions/setLocateServicesMethodType"
export { setRORequestSuccessMessageShown } from "actions/setRORequestSuccessMessageShown"

export { setSubMenuShown } from "actions/setSubMenuShown"
export {
  getCustomerPrefVerification,
  resetCustomerContacts,
} from "actions-lib/getCustomerPrefVerification"
export { getCustomerContacts } from "actions-lib/getCustomerContacts"
export { resendConfirmationText } from "actions/resendConfirmationText"

export { setUserState } from "actions-lib/setUserState"
export { setSessionIntervalId } from "actions-lib/setSessionIntervalId"
export { createUserWithCredentials } from "actions-lib/createUserWithCredentials"
export { getEmailCleanse, clearEmailCleanse } from "actions-lib/getEmailCleanse"
export { resendEmail } from "actions-lib/resendEmail"
export { activateUser } from "actions/activateUser"
export { forgotPassword } from "actions/forgotPassword"
export { resetPassword } from "actions/resetPassword"
export { setIsPasswordUpdated } from "actions/setIsPasswordUpdated"
export { setUserDetails, setUserSessionToken } from "actions-lib/setUserDetails"
export { getUserDetails } from "actions-lib/getUserDetails"
export { clearFirstTimeUser } from "actions/clearFirstTimeUser"
export { setToken } from "actions-lib/setToken"
export { getTokenAction, getToken } from "actions-lib/getToken"
export {
  getLinkedAccounts,
  updateLinkAcount,
  getCustomerUnLinkedAccountByEmail,
  setLinkAccount,
  hideManageAccountMessage,
} from "actions/getMatchingAccounts"
export { setUnlinkAccount } from "actions/setUnlinkAccount"
export { updateUserProfile } from "actions-lib/updateUserProfile"
export { resetUserAccount } from "actions-lib/resetUserAccount"
export { setSelectedCurrency } from "actions-lib/setSelectedCurrency"
export {
  getUserPaymentDetails,
  getAllCustomer,
} from "actions-lib/getUserPaymentDetails"
export { setBillingDetailsAccount } from "actions-lib/setBillingDetailsAccount"
export { setBillingDetailsAddress } from "actions/setBillingDetailsAddress"
export { setBillingDetailsDisplayContent } from "actions/setBillingDetailsDisplayContent"
export { setViewAgedBalance } from "actions/setViewAgedBalance"
export { setViewMore } from "actions/setViewMore"
export { setLastActivityTime } from "actions-lib/setLastActivityTime"
export { setContactUpdate } from "actions/setContactUpdate"
export { clearDashboardTour } from "actions/clearDashboardTour"

/* My Payment */
export { getPaymentEmailInfo } from "actions/getPaymentEmailInfo"
export { generateCyberSignature } from "actions-lib/generateCyberSignature"

export {
  getPaymentInfoVerification,
  clearPaymentInfoState,
} from "actions/getPaymentVerification"

export { cancelPaymentDetails } from "actions-lib/cancelPaymentDetails"

export {
  getPaymentBalanceInfo,
  setPaymentReviewDetails,
  updatePaymentInfo,
  clearupdatePaymentInfo,
  selectAllUpdatePaymentInfo,
} from "actions/getPaymentBalance"

export { getPaymentServicesInfo } from "actions/getPaymentServicesInfo"

export {
  setCustomCalendarInputValue,
  setCustomCalendarDateValid,
} from "actions/setCustomCalendarInputValue"

export {
  postProcessSaleRequest,
  changeDuplicatePayment,
  clearDeleteScheduledPaymentStatus,
  clearCreateScheduledPaymentStatus,
} from "actions-lib/processSaleRequest"

export { getUserPaymentMethods } from "actions/getUserPaymentMethods"
export {
  getUserPaymentHistory,
  clearPaymentHistory,
  getUserSelectedSchedulePayments,
} from "actions/getUserPaymentHistory"
export { setOverviewPaymentAmount } from "actions/setOverviewPaymentAmount"
export { setViewport } from "actions/setViewport"
export { getCustomer } from "actions/getCustomer"

export { deletePaymentMethod } from "actions/deletePaymentMethod"

export { updateUserPaymentMethod } from "actions/updateUserPaymentMethod"
export { savePaymentMethod } from "actions/savePaymentMethod"
export { setGuestPaymentMethod } from "actions/setGuestPaymentMethod"
export { setGuestPaymentMethodSubmitted } from "actions/setGuestPaymentMethodSubmitted"
export { setUserAddPaymentMethod } from "actions/setUserAddPaymentMethod"
export { setGuestEmail } from "actions/setGuestEmail"

export { getESBTokens } from "actions-lib/getESBTokens"
export { getOrderSummary } from "actions/getOrderSummary"
export { getOrderDetails } from "actions/getOrderDetails"
export { setOrderDisplayMethod } from "actions/setOrderDisplayMethod"

export { getUserScheduledPayments } from "actions/getUserScheduledPayments"
export { getAllUserScheduledPayments } from "actions/getAllUserScheduledPayments"
export { createSchedulePayment } from "actions/createSchedulePayment"
export { deleteScheduledPayment } from "actions/deleteScheduledPayment"

/* Common actionsfor  Paperless and autopay */
export { setAutopayAndPaperlessAccount } from "actions/setAutopayAndPaperlessAccount"
export { setAutopayPaperlessAccountAddress } from "actions/setAutopayPaperlessAccountAddress"
export { updateAutoPayEnrollmentByAccount } from "actions/updateAutoPayEnrollmentByAccount"

/* Paperless actions */
export {
  enrollPaperlessBilling,
  bulkEnrollPaperlessBilling,
  clearEnrollPaperlessStatus,
  clearPaperlessBulkEnrollmentStatusMap,
} from "actions/enrollPaperlessBilling"

export {
  unEnrollPaperlessBilling,
  clearUnenrollPaperlessStatus,
} from "actions/unEnrollPaperlessBilling"
export { fetchAutopayAndPaperlessAttributes } from "actions/fetchAutopayPaperlessAttributes"
export { fetchAttributesForSingleAccount } from "actions/fetchAutopayPaperlessAttributes"
export {
  getPaperlessPreferences,
  clearGetPaperlessPreferences,
} from "actions/paperlessPreferences/getPaperlessPreferences"
export {
  setPaperlessPreferences,
  clearSetPaperlessPreferences,
} from "actions/paperlessPreferences/setPaperlessPreferences"

export { intializeProductResults } from "actions-lib/getOnlineProducts"

/* Automatic payment actions */
export {
  enrollForAutoPayment,
  bulkEnrollForAutoPayment,
  updateEnrollmentDetailsByAccount,
  updateAutopayEnroll,
  clearAutopayEnrollDetails,
  clearAutopayEnrollAndUnenrollStatus,
  clearAutopayUnenrollStatus,
  clearAutopayBulkEnrollmentStatusMap,
} from "actions/enrollAutopayment"

export {
  fetchAutoPayment,
  manageAutopaymentDetailsMethod,
  manageAutopaymentDetailsByAccount,
} from "actions/fetchAutopayment"
export { updateAutoPayment } from "actions/updateAutopayment"
export { deleteAutoPayment } from "actions/deleteAutopayment"

/* Broker Account (50+ linked accounts) */
export { setBrokerAccountSortingPreference } from "actions/setBrokerAccountSortingPreference"
export { setLastSelectedBrokerAccount } from "actions/setLastSelectedBrokerAccount"
export { getUserAccountBalance } from "actions-lib/getUserAccountBalance"
export {
  getBrokerAccountBalancesAndDetails,
  updateBrokerAccountNickname,
} from "actions/getBrokerAccountBalancesAndDetails"
export { getSingleUserPaymentDetails } from "actions/getSingleUserPaymentDetails"
export { processBrokerSaleRequest } from "actions/processBrokerSaleRequest"
export { setTotalUserBalance } from "actions/setTotalUserBalance"

export {
  makeMinorAlertVisible,
  minorAlertNotVisible,
} from "actions-lib/getAlerts"

export { getFacilityAlerts } from "actions-lib/getFacilityAlerts"

export { setLastVisitedLocation } from "actions/setLastVisitedLocation"
export { setGuidedGuestFlowInitialized } from "actions/setGuidedGuestFlowInitialized"
export { getAEMPageJSON } from "actions-lib/getAEMPageJSON"
export {
  getServiceChangeEligibility,
  setServiceChangeEligibility,
} from "actions/getServiceChangeEligibility"
export { getServiceChangeRecommendations } from "actions/getServiceChangeRecommendations"
export { getServiceChangePrice } from "actions/getServiceChangePrice"
export { submitServiceChange } from "actions/submitServiceChange"
export { clearServiceChangeData } from "actions/clearServiceChangeData"
export { clearServiceChangeAPIStatuses } from "actions/clearServiceChangeAPIStatuses"
export { getServiceChangeHistory } from "actions/getServiceChangeOrderHistory"
export { cancelServiceChange } from "actions/cancelServiceChange"
export { updateCurrentServiceChangeData } from "actions/updateCurrentServiceChangeData"
export { clearCurrentServiceChangeData } from "actions/clearCurrentServiceChangeData"
export { getServiceChangeDates } from "actions/getServiceChangeDates"
/* extra pickup */
export { getExtraPickupEligibility } from "actions/getExtraPickupEligibility"
export { submitExtraPickupPricing } from "actions/submitExtraPickupPricing"
export { getExtraPickupDates } from "actions/getExtraPickupDates"
export { submitExtraPickup } from "actions/submitExtraPickup"
export { submitUpdateExtraPickup } from "actions/submitUpdateExtraPickup"
export { clearExtraPickupData } from "actions/clearExtraPickupData"
export { getExtraPickupHistory } from "actions/getAllExtraPickupOrders"
export { cancelExtraPickup } from "actions/cancelExtraPickup"
// export { cancelExtraPickup } from "actions/cancelExtraPickup"

/* bulk pickup */
export { getBulkPickupEligibility } from "actions/getBulkPickupEligibility"
export { getBulkPickupDates } from "actions/getBulkPickupDates"
export { getBulkPickupGuidelines } from "actions/getBulkPickupGuidelines"
export { getBulkPickupPricing } from "actions/getBulkPickupPricing"
export { submitBulkPickup } from "actions/submitBulkPickup"
export { clearBulkPickupData } from "actions/clearBulkPickupData"
export { clearPricingData } from "actions/clearPricingData"
export { cancelBulkPickup } from "actions/cancelBulkPickup"
export { cancelPauseService } from "actions/cancelPauseService"
export { setBulkPickupItems } from "actions/setBulkPickupItems/setBulkPickupItems"

/* missed pickup */
export {
  submitMissedPickupRequest,
  setRequestMissedPickupOrderID,
  setRequestMissedPickupTrackingID,
  setRequestMissedPickupCustomerID,
  setRequestMissedPickupSuccess,
  setRequestMissedPickupError,
} from "actions/submitMissedPickupRequest"
export { cancelMissedPickup } from "actions/cancelMissedPickupRequest"

/* missed pickup Guest */
export {
  submitMissedPickupRequestGuest,
  setRequestMissedPickupGuestOrderID,
  setRequestMissedPickupGuestTrackingID,
  setRequestMissedPickupGuestCustomerID,
  setRequestMissedPickupGuestSuccess,
  setRequestMissedPickupGuestError,
} from "actions/submitMissedPickupRequestGuest"
export { setGuestMissedPickupToken } from "actions/setGuestMissedPickupToken"

/* service visuals && service history */
export { getServiceHistory } from "actions/getServiceHistory"
export { getServiceVisuals } from "actions/getServiceVisuals"
export { clearServiceVisuals } from "actions-lib/clearServiceVisuals"
/* pause service */
export { submitPauseService } from "actions/submitPauseService"

/* Service change automation tagging metrics */
export { resetSCAValues, setSCAValues } from "actions-lib/scaValues"

/* Global error handling component state */
export { setErrorHandlingPopupData } from "actions/setErrorHandlingPopupData"

export { getCombinedProductsUrl } from "actions-lib/getCombinedProductUrl"

export { clearServiceOrderHistory } from "actions/clearServiceOrderHistory"
export { clearCancelOrderStatus } from "actions/clearCancelOrderStatus"

/* for use with webworker fetching and session caching of API responses */
export { setDataCache } from "actions/setDataCache"
export { clearDataCache } from "actions/clearDataCache"
export { updateOrderHistoryCache } from "actions/updateOrderHistoryCache"

export {
  getCombinedOrderHistoryByCustomer,
  clearCombinedOrderHistory,
} from "actions/getCombinedOrderHistory"

export { clearCloudCrazeOrderHistory } from "actions/clearCloudCrazeOrderHistory"

/* RO Pricing */
export { getROPricing } from "actions/getROPricing"
export { setRollOffPriceData } from "actions/setRollOffPriceData"

/* pending charges */
export { getPendingCharges } from "actions/getPendingCharges"

/* survey questions */
export {
  startSurvey,
  processSurvey,
  updateRating,
  setSurveyExperience,
  isSurveyHidden,
  clearSurveyData,
} from "actions/surveyQuestions"

export { getBillingDetails } from "actions/getBillingDetails"
export {
  getIncidentsByCustomerId,
  getIncidentsByUserId,
} from "actions-lib/getIncidents"
export {
  getNotificationsByCustomerId,
  getNotificationsByUserId,
} from "actions-lib/getNotifications"
export { setAlertsSelectedAccount } from "./setAlertsSelectedAccount"

// SMS route actions
export { getTextForBulkDetails } from "./getTextForBulkDetails"
export { getTextToPayDetails } from "./getTextToPayDetails"
export { getTextForRolloffDetails } from "./getTextForRolloffDetails"
export { getTextForXPUDetails } from "./getTextForXPUDetails"
export { getTextForContainerRepairDetails } from "./getTextForContainerRepairDetails"
export { getTextForViewETADetails } from "./getTextForViewETADetails"

// Container Repair Replacement
export { setContainerReplaceRepair } from "./setContainerReplaceRepair"

// Guest Container issue/Repair
export {
  getGuestContainerRepairDates,
  resetContainerRepairDate,
} from "./getGuestContainerRepairDates"
export { getGuestContainerRepairServices } from "./getGuestContainerRepairServices"
export {
  getGuestContainerRepairEligibility,
  resetContainerRepair,
  setContainerRepairSelection,
} from "./getGuestContainerRepairEligibility"
export {
  getGuestContainerRepairPricing,
  resetContainerRepairPricing,
} from "./getGuestContainerRepairPricing"
export {
  getGuestContainerRepairCreateTicket,
  resetContainerRepairTicket,
} from "./getGuestContainerRepairCreateTicket"

export {
  setShowContactModal,
  setHideContactModal,
} from "actions-lib/showContactModal"

export { getPickupInfo } from "actions/setNextPickupDetails"
