import axios from "axios"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { urls } from "utils-lib/builds"

export const pendingAC = () => ({
  type: ActionTypes.ACTIVATE_USER_PENDING,
})

export const failedAC = (errorCode) => ({
  type: ActionTypes.ACTIVATE_USER_FAILED,
  payload: errorCode,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.ACTIVATE_USER_FULFILLED,
  payload: data,
})

export const activateUser =
  (verificationToken, data, updateType) => (dispatch /* ,  getState */) => {
    const api =
      updateType === `newUser` ? `ACTIVATE_USER` : `ACTIVATE_NEW_EMAIL`

    const url = urls.url[api]
    dispatch(pendingAC())

    const config = {
      headers: { token: verificationToken },
    }

    const apiKey = _.get(urls, `apiKey.USER[${api}]`, ``)
    if (apiKey !== ``) {
      config.headers.apiKey = apiKey
    }

    return axios
      .create(config)
      .post(url, data)
      .then((response) => {
        dispatch(fulfilledAC(response))
        return response
      })
      .catch((error) => {
        dispatch(failedAC(_.get(error, `response.status`, ``)))
      })
  }
