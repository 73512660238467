import PropTypes from "prop-types"
import "./Body.scss"

export const Body = ({ children, className }) => (
  <div
    className={`Body maax-font-weight-regular ${className}`}
    data-testid="Body"
  >
    {children}
  </div>
)

Body.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
Body.defaultProps = {
  children: null,
  className: "",
}
