import { logRocketTrack } from "../logRocket"
import { loginStatusCode } from "../userAccount/login"

/**
 * Checks if the login status indicates a registered login.
 * @param {string} status - The login status.
 * @returns {boolean} - Returns true if the login status indicates a registered login, otherwise returns false.
 */
export const isRegisteredLogin = (status) =>
  loginStatusCode.SUCCESS === status &&
  loginStatusCode.UNCONFIRMED_EMAIL === status &&
  loginStatusCode.LOCKED_OUT === status

/**
 * It logs the event "getWithoutPrompt-fulfilled" to LogRocket.
 * @param status - The HTTP status code of the response.
 * @param response - The response object from the API call
 */
export const tagLoginSuccess = () => {
  logRocketTrack(`getWithoutPrompt-fulfilled`)
}

/**
 * Tags the login failure based on the provided status.
 * @param {string} status - The status of the login failure.
 */
export const tagLoginFailure = (status) => {
  if (loginStatusCode.GET_WITHOUT_PROMPT_FAIL === status) {
    logRocketTrack(`getWithoutPrompt-failed`)
  }
}
