import * as ActionTypes from "constants-lib/actionTypes"

// Must be a pure function that requires the state
// and the action being dispatched.
const initialState = {
  lastWidgetViewed: ``,
}

export default (state = initialState, action) => {
  switch (action.type) {
    // NEVER mutate the state, always return a new state.
    case ActionTypes.SET_LAST_WIDGET_VIEWED:
      return {
        lastWidgetViewed: action.payload,
      }

    default:
      return state
  }
}
