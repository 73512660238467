import map from "lodash/map"
import find from "lodash/find"
import each from "lodash/each"
import get from "lodash/get"
import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import { urls } from "utils-lib/builds"
import { YES } from "constants-lib/common"
import { getDeviceChannel } from "utils-lib/getDeviceChannel"
import { getTokenAction } from "../getToken"

function getAutopayData(state) {
  return {
    ezpayId: get(
      state,
      `customerAutoPayPaperless.autoPaymentAndPaperlessAccount`,
      ``,
    ),
    userId: get(state, `userAccount.userDetails.userId`, ``),
    lang: get(state, `siteLanguage.language`, ``),
  }
}

/**
 * Retrieves schedule data based on the user's login status and selected customer ID.
 * @param {Object} state - The current state object from which data is extracted.
 * @param {boolean} isLoggedIn - Flag indicating whether the user is logged in or not.
 * @returns {Object} An object containing ezpayId, userId, and lang properties based on the user's state and login status.
 */
function getScheduleData(state, isLoggedIn) {
  let selectedCustomerId = ``
  const loggedInCustomerIds = get(state, `myPaymentCheckout.customerIDs`, {})
  each(loggedInCustomerIds, (value, customerId) => {
    if (value) {
      selectedCustomerId = customerId
    }
  })

  return {
    ezpayId: isLoggedIn
      ? selectedCustomerId
      : get(state, `customerSelections.customerId`, ``),
    userId: get(state, `userAccount.userDetails.userId`, ``) || `Guest`,
    lang: get(state, `siteLanguage.language`, ``),
  }
}

/**
 * @function getData
 * @param {Object} state - The state object containing various nested properties.
 * @param {Object} billingContactInfo - The billing contact information object.
 * @param {string} billingContactInfo.addressLines - The address lines for the billing contact.
 * @param {string} billingContactInfo.administrativeArea - The administrative area (state/region) for the billing contact.
 * @param {string} billingContactInfo.countryCode - The country code for the billing contact.
 * @param {string} billingContactInfo.locality - The locality (city) for the billing contact.
 * @param {string} billingContactInfo.postalCode - The postal code for the billing contact.
 * @param {string} billingContactInfo.givenName - The given name (first name) of the billing contact.
 * @param {string} billingContactInfo.familyName - The family name (last name) of the billing contact.
 * @param {boolean} isAutoPay - A boolean indicating whether the payment is set to auto-pay.
 * @param {number} autopayPaymentAmount - The amount to be paid if auto-pay is enabled.
 * @param {Object} customPayload - An optional custom payload object. If present, it should contain a `transaction` property.
 * @returns {Object} Returns an object containing transaction details, payment submission date, locale, billing email, billing contact, and user ID.
 */
function getData(
  state,
  billingContactInfo,
  isAutoPay,
  autopayPaymentAmount,
  customPayload,
) {
  const myPaymentCheckout = get(state, `myPaymentCheckout`, {})
  const userId = get(state, `userAccount.userDetails.userId`, ``)
  const payOnInvoice = get(myPaymentCheckout, `invoiceNo`, ``)
  const payAmount = get(myPaymentCheckout, `paymentAmount`, ``)
  const paySubmitDate = get(myPaymentCheckout, `paymentDate`, ``)
  const locale = get(state, `siteLanguage.language`, ``)

  const bulkFormSelections = get(
    state,
    `myServices.guestBulkPickupIncludedCategories.data.bulkFormSelections`,
    ``,
  )

  const billingEmail = userId
    ? get(state, `userAccount.userDetails.login`, ``)
    : get(bulkFormSelections, `Email`, ``)

  const accounts = []

  if (userId) {
    const userAccountDetails = get(
      state,
      `userManageAccount.userAccountDetails`,
      [],
    )

    each(
      get(state, `myPaymentCheckout.customerIDs`, {}),
      (value, customerId) => {
        if (value) {
          const account = find(
            userAccountDetails,
            (item) => item.custAccountId === customerId,
          )

          if (account) {
            accounts.push(account)
          }
        }
      },
    )
  }

  let transaction = []

  if (customPayload) {
    transaction.push(customPayload.transaction)
  } else if (isAutoPay) {
    const currentAccount = get(
      state,
      `customerAutoPayPaperless.autoPaymentAndPaperlessAccount`,
      ``,
    )

    transaction = [
      {
        payOnAccount: currentAccount,
        payAmount: autopayPaymentAmount,
        payChannel: `O`,
        payType: `A`,
        payFee: `0.00`,
      },
    ]
  } else if (userId) {
    transaction = map(accounts, (account) => {
      const customerId = account.custAccountId
      const accountPayAmount = String(
        get(
          myPaymentCheckout,
          `amountBalanceByAccount[${customerId}].amount`,
          ``,
        ),
      )

      return {
        payOnInvoice,
        payOnAccount: customerId,
        payAmount: accounts.length === 1 ? payAmount : accountPayAmount,
        payFee: `0.00`,
        payChannel: `O`,
        payType: payOnInvoice ? `I` : `A`,
      }
    })
  } else {
    transaction = [
      {
        payOnInvoice,
        payOnAccount: get(state, `customerSelections.customerId`, ``),
        payAmount,
        payFee: `0.00`,
        payChannel: `O`,
        payType: payOnInvoice ? `I` : `A`,
      },
    ]
  }

  const data = {
    transaction,
    paySubmitDate,
    locale,
    billingEmail,
    billingContact: {
      addressLines: get(billingContactInfo, `addressLines`, ``),
      state: get(billingContactInfo, `administrativeArea`, ``),
      countryCode: get(billingContactInfo, `countryCode`, ``),
      city: get(billingContactInfo, `locality`, ``),
      postalCode: get(billingContactInfo, `postalCode`, ``),
      firstName: get(billingContactInfo, `givenName`, ``),
      lastName: get(billingContactInfo, `familyName`, ``),
      phoneNumber: get(state, `customerSelections.customerPhone`, ``),
    },
    userId: userId || `Guest`,
  }

  if (!userId) {
    data.guestToken = get(
      state,
      `customerSelections.cityBilledEligibility.token`,
      ``,
    )
  }

  return data
}

/**
 * Action for processing Apple Pay payments.
 * @function processApplePay
 * @param {Object} billingContactInfo - The billing contact information.
 * @param {Object} paymentMethod - The payment method.
 * @param {string} base64PaymentToken - The base64 encoded payment token.
 * @param {boolean} isAutoPay - Whether the payment is an auto-pay payment.
 * @param {number} paymentAmount - The amount of the payment.
 * @param {boolean} isPayToday - Whether the payment is to be made today.
 * @param {boolean} isUpdateAutopay - Whether to update auto-pay information.
 * @param {Object} [customPayload=null] - Optional custom payload to include in the API call.
 * @returns {function} dispatches actions to update the store with the results of the API call.
 */
export const processApplePay =
  (
    billingContactInfo,
    paymentMethod,
    base64PaymentToken,
    isAutoPay,
    paymentAmount,
    isPayToday,
    isUpdateAutopay,
    customPayload = null,
  ) =>
  (dispatch, getState) => {
    const state = getState()

    const {
      transaction,
      paySubmitDate,
      locale,
      billingContact,
      billingEmail,
      userId,
      guestToken,
    } = getData(
      state,
      billingContactInfo,
      isAutoPay,
      paymentAmount,
      customPayload,
    )

    const isLoggedIn = userId !== `Guest`

    let api = `APPLE_PAY_PROCESS`

    if (isAutoPay) {
      api = `APPLE_PAY_AUTOPAY`
    } else if (!isPayToday) {
      api = `APPLE_PAY_SCHEDULE`
    }

    let url = urls.url[api]

    if (isAutoPay) {
      const { ezpayId, userId, lang } = getAutopayData(state)

      const template = URITemplate(urls.url[api])
      url = template.expand({
        ezpayId,
        userId,
        lang,
      })
    } else if (!isPayToday) {
      const { ezpayId, userId, lang } = getScheduleData(state, isLoggedIn)

      const template = URITemplate(urls.url[api])
      url = template.expand({
        ezpayId,
        userId,
        lang,
      })
    } else {
      const template = URITemplate(urls.url[api])
      url = template.expand({
        userId,
        lang: locale,
      })
    }

    let apiKey
    if (isAutoPay) {
      apiKey = get(urls, `apiKey.USER.APPLE_PAY_AUTOPAY`, ``)
    } else if (isLoggedIn) {
      if (isPayToday) {
        apiKey = get(urls, `apiKey.USER.APPLE_PAY_PROCESS`, ``)
      } else {
        apiKey = get(urls, `apiKey.USER.APPLE_PAY_SCHEDULED`, ``)
      }
    } else if (isPayToday) {
      apiKey = get(urls, `apiKey.GUEST.APPLE_PAY_PROCESS`, ``)
    } else {
      apiKey = get(urls, `apiKey.GUEST.APPLE_PAY_SCHEDULED`, ``)
    }

    const payload = {
      locale,
      billingEmail,
      deviceChannel: getDeviceChannel(),
      billingContact,
      base64PaymenToken: base64PaymentToken,
      digitalPayType: `ApplePay`,
    }

    if (isPayToday || isAutoPay) {
      payload.transaction = transaction
      payload.paymentMethod = paymentMethod
    }

    if (isAutoPay) {
      payload.invStatus = YES

      if (paymentAmount !== `0`) {
        payload.paySubmitDate = paySubmitDate
      }
    }

    if (!isAutoPay) {
      payload.paySubmitDate = paySubmitDate
      payload.paySubmittedBy = ``
      payload.paySubmittedFor = ``
    }

    if (isPayToday) {
      payload.payMemo = ``
    }

    if (!isPayToday) {
      payload.payOnAccount = get(transaction, `[0].payOnAccount`, ``)
      payload.payAmount = get(transaction, `[0].payAmount`, ``)
      payload.payFee = get(transaction, `[0].payFee`, ``)
      payload.payOnInvoice = get(transaction, `[0].payOnInvoice`, ``)
      payload.payChannel = get(transaction, `[0].payChannel`, ``)
      payload.payType = get(transaction, `[0].payType`, ``)
      payload.duplicateCheck = YES // prevent duplicate payment check
      payload.payMethodId = ``
      payload.digitalPaymentMethod = paymentMethod
    }

    if (isLoggedIn) {
      return getTokenAction(dispatch, getState).then((token) => {
        const postOptions = {
          method: isUpdateAutopay ? `put` : `post`,
          url,
          data: payload,
        }

        postOptions.headers = {
          apiKey,
          oktaToken: token.accessToken,
        }

        return axios(postOptions).then((response) => response)
      })
    }
    const postOptions = {
      method: `post`,
      url,
      data: payload,
    }

    postOptions.headers = {
      apiKey,
      token: guestToken,
    }

    return axios(postOptions).then((response) => response)
  }
