import axios from "axios"
import URITemplate from "urijs/src/URITemplate"
import * as ActionTypes from "constants-lib/actionTypes"
import _ from "lodash"
import { urls } from "utils-lib/builds"
import moment from "moment"
import { MAKE_ANOTHER_PAYMENT } from "constants-lib/actionTypes"

// Action creators
export const pendingAC = () => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_BALANCE_PENDING,
})

export const failedAC = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_BALANCE_FAILED,
  payload: data,
})

export const fulfilledAC = (data) => ({
  type: ActionTypes.GET_MY_WM_PAYMENT_BALANCE_FULFILLED,
  payload: data,
})

export const makeAnotherPayment = () => ({
  type: MAKE_ANOTHER_PAYMENT,
})

export const getPaymentBalanceInfo = () => (dispatch, getState) => {
  const api = `BALANCE`
  const template = URITemplate(urls.url[api])
  const state = getState()
  const token = _.get(state, `myPaymentVerification.verificationToken`, ``)
  const customerId =
    _.get(state, `myPaymentVerification.verificationData.customerId`, ``) ||
    _.get(state, `myPaymentVerification.verificationDetails.customerId`, ``)

  const url = template.expand({
    customerId,
  })

  const params = {
    userId: `Guest`,
    // lang: _.get(state, `siteLanguage.language`, `en_CA`),
  }

  dispatch(pendingAC())

  return axios
    .create({
      headers: {
        token,
        apiKey: _.get(urls, `apiKey.GUEST[${api}]`, ``),
      },
      params,
    })
    .get(url)
    .then((response) => {
      const dataBalance = _.get(response, `data.body.balance`, [])
      const totalIndex = _.findIndex(dataBalance, [`type`, `total`])

      const processedResponse = {
        totalAmount: _.get(dataBalance, `[${totalIndex}].amount`, 0),
        currency: _.get(response, `data.body.currency`, ``),
        ivrFee: _.get(response, `data.body.ivr_fee`, ``),
        statusCode: _.get(response, `status`, ``),
      }

      dispatch(fulfilledAC(processedResponse))

      return processedResponse
    })
    .catch((response) => {
      const statusCode = _.get(response, `response.status`, ``)
      const errorMessage = _.get(response, `response.data.Error`, ``)
      dispatch(
        failedAC({
          statusCode,
          errorMessage,
        }),
      )
    })
}

export const setPaymentReviewDetails = (params) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MY_WM_PAYMENT_REVIEW_DETAILS,
    payload: params,
  })
}

export const updatePaymentInfo = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_MY_WM_PAYMENT_INFO_DETAILS,
    payload: data,
  })
}

export const clearupdatePaymentInfo = () => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_MY_WM_PAYMENT_INFO_DETAILS,
    payload: {
      paymentAmount: `0.00`,
      paymentDate: moment().format(),
    },
  })
}

export const selectAllUpdatePaymentInfo = (data) => (dispatch) => {
  const { customerIDs } = data
  _.keys(customerIDs).forEach((key) => {
    customerIDs[key] = true
  })
  const newData = { customerIDs }

  dispatch({
    type: ActionTypes.UPDATE_MY_WM_PAYMENT_INFO_DETAILS,
    payload: newData,
  })
}
